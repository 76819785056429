<h2 class="mat-title section-title">
  <fa-icon [icon]="['far', 'chart-bar']" size="lg" class="muted"></fa-icon> Process Totals
</h2>
<div class="totals-container" [ngClass]="class">
  <div class="total" *ngFor="let total of processTotals" (click)="onTotalClick(total)">
    <mat-icon *ngIf="!total.icon.type" color="muted">{{ total.icon.name }}</mat-icon>
    <div class="value-container">
      <label>{{ total.label }}</label>
      <span class="value">{{ total.value | numbers }}</span>
    </div>
  </div>
</div>
