import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { GetMyTenantsDataQuery } from '../graphql/types';
import { getMyTenantsDataQuery } from '../graphql/queries';
import { Tenant } from '../shared/model/tenant';
import { User } from '../shared/model/user';
import { RequisitionService } from '../services/requisition.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'mm-add-requisition-dialog',
  templateUrl: './add-requisition-dialog.component.html',
})
export class AddRequisitionDialogComponent implements OnInit, OnDestroy {
  createReqForm: FormGroup;
  nameFormControl = new FormControl('', Validators.required);
  descriptionFormControl = new FormControl('', Validators.required);
  tenantFormControl = new FormControl('', Validators.required);
  ownerFormControl = new FormControl('', Validators.required);
  assigneeFormControl = new FormControl();

  tenants: Tenant[];
  owners: User[];
  assignees: User[];

  filteredTenants: Observable<Tenant[]>;
  filteredOwners: Observable<User[]>;

  // userInfoSub: Subscription;
  currentUserInfo: any;
  userTenant: any; // This is any but should be Teant type - But... throwing ts error when treated as form control input
  isMMUserOrAdmin: boolean = true;

  private reqQuerySubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AddRequisitionDialogComponent>,
    private reqService: RequisitionService,
    private userService: UserService,
    private apollo: Apollo,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    console.log('AddRequisitionDialogComponent data', this.data);

    this.createReqForm = this.formBuilder.group({
      name: this.nameFormControl,
      description: this.descriptionFormControl,
      tenant: this.tenantFormControl,
      owner: this.ownerFormControl,
      assignee: this.assigneeFormControl,
      line1: ['', null],
      line2: ['', null],
      city: ['', null],
      state: ['', null],
      zip: ['', null],
    });

    this.getAuthenticatedUserInfo();
  }

  private getAuthenticatedUserInfo(): void {
    this.userService.getAuthenticatedUserInfo().subscribe(
      (userInfo: any) => {
        this.currentUserInfo = userInfo;
        this.setMMUserOrAdmin();

        // now that we have the authenticatedUserInfo and know the user's group, make request for their tenants he can access
        this.getTenants();

        // Disable tenantFormControl if not mmUser or mmAdmin
        if (!this.isMMUserOrAdmin) this.tenantFormControl.disable();
      },
      (error: unknown) => {
        console.log('AddTodoComponent: Error getting authenticated user groups -> ' + error);
      }
    );
  }

  private setMMUserOrAdmin() {
    if (!(this.currentUserInfo?.groups?.includes('mmUser') || this.currentUserInfo?.groups?.includes('mmAdmin'))) {
      this.isMMUserOrAdmin = false;
    } else {
      this.isMMUserOrAdmin = true;
    }
  }

  private getTenants(): void {
    this.reqQuerySubscription = this.apollo
      .watchQuery<GetMyTenantsDataQuery>({ query: gql(getMyTenantsDataQuery) })
      .valueChanges.subscribe(
        (res) => {
          let result = structuredClone(res);
          console.log('AddRequisitionComponent: req form data ', result.data.getMyTenants);

          result.data.getMyTenants.forEach((item) => {
            item.user = item.user.filter((user) => user['active'] == true);
          });

          this.tenants = result.data.getMyTenants;

          if (!this.isMMUserOrAdmin) {
            this.userTenant = this.tenants[0];
            this.tenantSelected(this.userTenant);
            this.tenantFormControl.setValue(this.userTenant);
          } else {
            this.userTenant = this.tenants[0];
            this.tenantSelected(this.data?.tenant ? this.data.tenant : this.userTenant);
            this.tenantFormControl.setValue(this.data?.tenant ? this.data.tenant : this.userTenant);
          }

          this.filteredTenants = this.tenantFormControl.valueChanges.pipe(
            startWith<string | Tenant>(''),
            map((value) => (typeof value === 'string' ? value : value.title)),
            map((name) => (name ? this._filterTenant(this.tenants, name) : this.tenants))
          );
        },
        (error: unknown) => {
          console.log('AddRequisitionComponent: There was an error loading req data ', error);
        }
      );
  }

  private _filterTenant(data: any, name: string): Tenant[] {
    const filterValue = name.toLowerCase();
    return data.filter((tenant) => tenant.title.toLowerCase().includes(filterValue));
  }

  private _filterOwner(data: any, name: string): User[] {
    const filterValue = name.toLowerCase();
    return data.filter((user) => (user.firstName + ' ' + user.lastName).toLowerCase().includes(filterValue));
  }

  displayTenant(tenant?: Tenant): string | undefined {
    return tenant ? tenant.title : undefined;
  }

  displayOwner(user?: User): string | undefined {
    return user ? user.firstName + ' ' + user.lastName : undefined;
  }

  tenantSelected(tenant) {
    if (this.isMMUserOrAdmin) {
      let mmTenant = this.tenants.filter((t) => t.id == this.currentUserInfo.attributes['custom:tenantId']);
      let mmInternalUsers = [];
      if (mmTenant.length > 0) {
        if (tenant.id != mmTenant[0].id) {
          mmInternalUsers = mmTenant[0].user;
        }
      }
      this.owners = this.assignees = [].concat(mmInternalUsers, tenant.user);
    } else {
      this.owners = this.assignees = tenant.user;
    }

    console.log('this.owners ', this.owners);

    this.filteredOwners = this.ownerFormControl.valueChanges.pipe(
      startWith<string | User>(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filterOwner(this.owners, name) : this.owners))
    );
    //new tenant selected so clear out owner and assignee
    this.createReqForm.controls['owner'].setValue('');
    this.createReqForm.controls['assignee'].setValue('');
  }

  verifyTenantSelected() {
    //verify selection is a valid selection from list
    if (!this.tenants.includes(this.createReqForm.getRawValue().tenant)) {
      this.createReqForm.controls['tenant'].setValue('');
    }
  }

  verifyOwnerSelected() {
    //verify selection is a valid selection from list
    if (!this.owners || !this.owners.includes(this.createReqForm.value.owner)) {
      this.createReqForm.controls['owner'].setValue('');
    }
  }

  createReq() {
    console.log('this.registerForm', this.createReqForm);

    this.verifyOwnerSelected();
    this.verifyTenantSelected();

    // stop here if form is invalid
    if (this.createReqForm.invalid) {
      console.log('AddRequisitionComponent: form is invalid', this.createReqForm.invalid);

      //mark each field as touched so required fields display error state
      for (let i in this.createReqForm.controls) {
        this.createReqForm.controls[i].markAsTouched();
      }
      return;
    }

    let form = this.createReqForm.getRawValue();

    // Remove address from add req per MMW-1448
    let address = {
      line1: '', //form.line1,
      line2: '', //form.line2,
      city: '', //form.city,
      state: '', //form.state,
      zip: '', //form.zip,
    };
    let assignees = form.assignee ? form.assignee.map((item) => item['id']) : [];

    let reqInfo = {
      title: form.name,
      summary: form.description,
      tenantId: form.tenant.id,
      ownerId: form.owner.id,
      ownerFirst: form.owner.firstName,
      ownerLast: form.owner.lastName,
      address: JSON.stringify(address),
      assignedUsers: assignees,
    };
    console.log('AddRequisitionComponent: adding req', reqInfo);

    this.reqService.createRequisition(reqInfo).subscribe(
      (result) => {
        console.log('AddRequisitionDialogComponent::createReq successful with result ', result);

        // if null is returned, assume the creator is neither the owner of nor assigned to the new req
        if (result.data.addReq !== null && !result.data.addReq.mmAdminOnlyReqId && this.data?.redirect !== false) {
          this.router.navigate(['requisitions', result.data.addReq.reqId]);
        }

        // Pass back new user to assign todos data
        if (
          result.data.addReq &&
          (result.data.addReq.reqId || result.data.addReq.mmAdminOnlyReqId) &&
          result.data.addReq.title
        ) {
          let data = {
            reqId: result.data.addReq?.reqId || result.data.addReq?.mmAdminOnlyReqId,
            title: result.data.addReq.title,
          };

          this.dialogRef.close({ data: data });
        } else {
          this.dialogRef.close();
        }
      },
      (error: unknown) => {
        console.log('AddRequisitionDialogComponent::createReq encountered an error ', error);
        this.dialogRef.close();
      }
    );
  }

  onKeydown(event) {
    if (event.key === '/') {
      event.stopPropagation();
      // console.log(event);
    }
  }

  ngOnDestroy() {
    if (this.reqQuerySubscription) {
      this.reqQuerySubscription.unsubscribe();
    }
  }
}
