<div style="width: 450px">
  <!-- <mat-chip-list>
    <mat-chip *ngFor="let contact of contactsWithAssignee">
      {{ contact.firstName + ' ' + contact.lastName }}
    </mat-chip>
  </mat-chip-list> -->

  <mat-chip-list>
    <mat-chip *ngFor="let contact of contactsWithAssignee.slice(0, 20)">
      {{ contact.firstName + ' ' + contact.lastName }}
    </mat-chip>
    <span *ngIf="contactsWithAssignee.length > 20">...{{ contactsWithAssignee.length - 20 }} more</span>
  </mat-chip-list>
  <div *ngIf="!message" style="color: #747678; margin-top: 1em">
    {{
      contactsWithAssignee.length > 1
        ? 'These contacts are currently assigned to other users'
        : 'This contact is currently assigned to another user'
    }}, do you want to reassign them to <mat-chip>{{ assignee.firstName }} {{ assignee.lastName }}</mat-chip> ?
  </div>
  <div *ngIf="message" style="color: #747678; margin-top: 1em">
    {{ message }}
  </div>
  <mat-dialog-actions>
    <button mat-button color="warn" mat-dialog-close>Cancel</button>
    <button mat-button color="primary" (click)="onConfirmUpdate()">Confirm</button>
  </mat-dialog-actions>
</div>
