<h1 mat-dialog-title *ngIf="data.title">{{ data.title }}</h1>
<mat-dialog-content *ngIf="data.message">
  {{ data.message }}
  <p>{{ data.extraMessage }}</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data.cancelButton?.value || false" color="{{ data.cancelButton?.color }}">
    {{ data.cancelButton?.label || 'Cancel' }}
  </button>
  <button
    mat-raised-button
    [mat-dialog-close]="data.confirmButton?.value || true"
    color="{{ data.confirmButton?.color || 'primary' }}"
    data-testid="alert-dialog-confirm"
  >
    {{ data.confirmButton?.label || 'Ok' }}
  </button>
</mat-dialog-actions>
