import { Component, Input } from '@angular/core';

@Component({
  selector: 'mm-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinner {
  @Input() type: any = 'default';
  @Input() size: String = 'normal';
  @Input() position: String = '';
  ngOnInit() {}
}
