import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  User,
  AddUserInput,
  ResendUserCodeInput,
  UpdateUserInfoInput,
  UserSetting,
  UserData,
} from 'app/shared/model/user';
import { CognitoService } from './cognito.service';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { Apollo, gql } from 'apollo-angular';
import { map, takeUntil, tap } from 'rxjs/operators';
import { notifyStepQueueMessageProcessed } from '../graphql/subscriptions';
import { PermissionsService } from './permissions.service';

import {
  addUser,
  addToNewUserStepQueue,
  resendUserCode,
  setUserSettingKey,
  deleteUserSettingKey,
  setFeatureByNameForUser,
  addUserToGroup,
  removeUserFromGroup,
  enableDeviceRememberingForOldUser,
  notifyUsernameBeforeLogOut,
  setUserDeviceInfo,
  deleteUserDeviceInfo,
  setUserSettingKeyForUser,
  addToAllReqs,
} from '../graphql/mutations';
import { Router } from '@angular/router';
import {
  getUserInfo,
  readUserSettingKey,
  getUserAvatarUrl,
  isZapAuthenticated,
  trainingGetUserInfo,
  trainingGetUserMoreInfo,
  getUserImportHistory,
  getUserMetricsQuery,
  getFeatureByNameForUser,
  getTenantInfo,
  listGroupsForUser,
  getUsersActivatedStatus,
  getUserDevicesInfo,
  getListRunningTasksFromLogs,
  userHasPermissionToContact,
  readUserSettingKeyForUser,
} from '../graphql/queries';
import {
  updateUserInfo,
  disableUser,
  enableUser,
  uploadUserAvatar,
  disconnectZapier,
  setUserNotificationsKey,
  deleteUserNotificationsKey,
  isUserNotificationsKeySet,
  updateUserMetricMutation,
  notifyUserDeviceBeforeLogOut,
  forceUserLogout,
} from '../graphql/mutations';
import {
  GetUserInfo,
  UpdateUserInfo,
  UploadUserAvatar,
  GetUserAvatarUrl,
  IsZapAuthenticated,
  DisconnectZapier,
  GetUserImportHistory,
} from '../graphql/types';
import { updatedUsernameLogIn, updatedUserDeviceLogIn } from '../graphql/subscriptions';
import { ImageCachingService } from './imagecaching.service';
import { AppsyncService } from 'app/graphql/graphql.appsync.service';
import { take } from 'rxjs/operators';
import { UsersToCheck } from '../../../../app/graphql.types';
import { NavigationService } from './navigation.service';
import { getAgentsAndOriginatorsReqs } from 'app/__individual/pulse/pulse.queries';
import { WatchQueryFetchPolicy } from 'apollo-client';

type UserDataT = {
  Username: string;
  Pool: CognitoUserPool;
};

const USER_STATE_STORAGE_KEY = 'user';
const TENANT_ID_ATTRIBUTE_NAME = 'custom:tenantId';

/**
 * Service to manage the authenticated user and his/her data.
 *
 * Data is currently stored in LocalStorage, because it's easy. This has downsides though:
 * - 10 MB limit
 * - Have to stringify/parse objects as strings
 *
 * Should this become limiting, can:
 * - Split into multiple objects, only update what changes.
 * - Switch to IndexedDB solution.
 */
@Injectable()
export class UserService {
  /**
   * @deprecated
   * **Use userInfo$ instead.**
   * All about the current user, including local state.
   * An updated value pushed onto here automatically saves to local storage.
   * - It does NOT persist to server.
   * - It does NOT sync to other devices.
   */
  readonly user$ = new BehaviorSubject<User>(null);
  readonly userInfo$ = new BehaviorSubject<User>(null);
  readonly authenticated$ = new BehaviorSubject<boolean>(false);
  readonly cognitoUserInfo$ = new BehaviorSubject<any>(null);
  readonly tenant$ = new BehaviorSubject<any>(null);
  readonly authenticated = this.authenticated$.asObservable();

  cognitoUserInfo = {
    groups: [],
    userId: '',
  };

  public userId: string = null;
  public tenantId: string = null;
  public userGroups: any[] = [];
  public email: string = null;
  public family_name: string = null;
  public given_name: string = null;
  public firstName: string = null;
  public lastName: string = null;
  public userInfo: User = null;

  public test_code: string = '123456';

  // Could still use a look up for these for Pendo - only on initial login
  public userPhone: string = null;
  public tenantName: string = null;

  // Stores requested url when user is not logged in
  urlPath: string;
  loginLast: string = '';
  /**
   * Construct
   */
  constructor(
    @Inject(CognitoService) public cognitoService: CognitoService,
    private apollo: Apollo,
    private router: Router,
    private httpClient: HttpClient,
    private imageCachingService: ImageCachingService,
    private appsync: AppsyncService,
    private permissionsService: PermissionsService,
    private navigationService: NavigationService
  ) {
    console.log('🚀 User Service constructor!');
    // Initialize user$ from storage
    const str = localStorage.getItem(USER_STATE_STORAGE_KEY);
    if (str !== null) {
      let user = null;
      try {
        user = JSON.parse(str);
      } catch (e) {}

      this.user$.next(user);
    }

    // Auto-store any changes to user$
    this.user$.subscribe((user) => {
      if (user) {
        localStorage.setItem(USER_STATE_STORAGE_KEY, JSON.stringify(user));
      } else {
        localStorage.removeItem(USER_STATE_STORAGE_KEY);
      }
    });

    // this.userInfo$.subscribe(userInfo => {
    //   this.navigationService.calculateUserRoutePreference(userInfo)
    // })
  }

  client = this.appsync.nonHydratedClient();

  cognitoUserFactory(userData: UserDataT): CognitoUser {
    return new CognitoUser(userData);
  }

  authenticate(username: string, password: string) {
    // console.log('UserService: starting the authentication');
    let authenticationData = {
      Username: username,
      Password: password,
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);
    let userData = {
      Username: username,
      Pool: this.cognitoService.getUserPool(),
    };

    // console.log('authenticate authenticationDetails', authenticationDetails);

    // console.log('UserService: Params set...Authenticating the user');
    //let cognitoUser = new CognitoUser(userData);
    const cognitoUser = this.cognitoUserFactory(userData);

    // console.log('authenticate cognitoUser', cognitoUser);

    return new Observable((observer: Observer<{ idToken: string }>) => {
      // Pass username and password on to Cognito based on .env setting which cognito instance to query
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (session) => {
          // console.log('session returned from cognito onSuccess', session, this.test_code);

          // Store basic user information for direct access via user service
          // Don't use these direct if you truly want observable
          // Probably good just store payload object, but the direct values are easier to find when coding
          let payload = session.getIdToken().payload;

          // console.log('user service authenticate payload', JSON.parse(JSON.stringify(payload)));

          this.userId = payload['custom:userId'];
          this.tenantId = payload['custom:tenantId'];
          this.userGroups = payload['cognito:groups'];
          this.email = payload['email'];
          this.family_name = payload['family_name'];
          this.given_name = payload['given_name'];

          let getUserInfoResult = await this.getUserInfo(this.userId, true)
            .pipe(take(1))
            .toPromise()
            .catch((err) => {
              console.log(err);
            });
          this.userInfo$.next(getUserInfoResult.data.getUserInfo);
          this.userInfo = structuredClone(getUserInfoResult.data.getUserInfo);

          // Get tenant info?

          // ***TODO check multi session here instead of login page - do not set authenticated until this is set

          // console.log('about to set next on authenticated$ = true' );

          // This is a newly authenticated user - go get all of their permissions
          await this.permissionsService.initPermissions(this.tenantId);
          // Initialize default route too.
          await this.navigationService.initialize();

          // NOTE: We are initializing the permissions and navigationService before we emit to the observer because we need that data before we continue.

          // Tell the observer this is the current users JWT Token
          observer.next({ idToken: session.getIdToken().getJwtToken() });

          // Set the behavior subject as true - meaning we ARE currently logged in
          this.authenticated$.next(true);

          // this.setUserNMLSID(this.userId)

          // Tells whoever is observing that the observable is done and you can now use the current laue safely.
          observer.complete();
        },
        onFailure: (error) => {
          if (error.code == 'UserNotConfirmedException') {
            // Not confirmed
            observer.error(error);
            this.authenticated$.next(false);
            this.userInfo$.next(null);
          } else if (error.code == 'PasswordResetRequiredException') {
            // Reset Password Required

            // Fake password reset since not implemented yet
            // Needed to authenticate newly imported cognito user
            this.confirmNewPassword(username, '384710', 'password');
          } else if (error.code == 'NotAuthorizedException') {
            // Not Authorised (Incorrect Password)
            observer.error(error);
            this.authenticated$.next(false);
            this.userInfo$.next(null);
          } else if (error.code == 'ResourceNotFoundException') {
            // User Not found
            observer.error(error);
            this.authenticated$.next(false);
            this.userInfo$.next(null);
          } else {
            observer.error(error);
            this.authenticated$.next(false);
            this.userInfo$.next(null);
          }
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          let error = {
            code: 'PasswordResetRequiredException',
            message: 'User password must be reset',
          };

          observer.error(error);
          this.authenticated$.next(false);
          this.userInfo$.next(null);
        },
      });
    });
  }

  public uploadUserAvatar(file: File, fileName: string, fileType: string, userId: string): Observable<any> {
    return this.apollo.mutate<UploadUserAvatar>({
      mutation: gql(uploadUserAvatar),
      variables: { fileName: fileName, fileType: fileType, userId: userId },
      update: (store, { data: { uploadUserAvatar } }) => {
        if (!uploadUserAvatar) {
          return;
        }
        let headers: HttpHeaders = new HttpHeaders();
        // console.log(headers, uploadUserAvatar.uploadUrl);
        headers.set('Content-Type', file.type);
        this.httpClient
          .put(uploadUserAvatar.uploadUrl, file, {
            headers: headers,
          })
          .subscribe(
            (event: any) => {
              this.imageCachingService.checkAndCacheImage(userId, file);
              // console.log('UserService :: uploadUserAvatar - Event == %s', JSON.stringify(event));
            },
            (error: any) => {
              console.error('UserService :: uploadUserDocument - Error == %s', JSON.stringify(error));
            }
          );
      },
    });
  }

  public getUserAvatarUrl(userId: string): Observable<any> {
    const imageInCache = this.imageCachingService.checkImageInCache(userId);
    if (imageInCache) return this.imageCachingService.getImage(userId);
    return this.apollo.watchQuery<GetUserAvatarUrl>({
      query: gql(getUserAvatarUrl),
      variables: { userId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  resendConfirmationCode(username: string): Observable<any> {
    let input: ResendUserCodeInput = { email: username };
    return this.apollo.mutate({
      mutation: gql(resendUserCode),
      variables: { userInput: input },
    });
  }

  public completeNewPasswordChallenge(username, existingPassword, newPassword): Observable<any> {
    let authenticationData = {
      Username: username,
      Password: existingPassword,
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);
    let userData = {
      Username: username,
      Pool: this.cognitoService.getUserPool(),
    };
    // console.log('UserService: Params set...Authenticating the user');
    //let cognitoUser = new CognitoUser(userData);
    const cognitoUser = this.cognitoUserFactory(userData);

    return new Observable((observer: Observer<{ idToken: string }>) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          observer.next({ idToken: session.getIdToken().getJwtToken() });
          this.authenticated$.next(true);

          this.getUserInfo(this.userId, true)
            .pipe(take(1))
            .toPromise()
            .then((result) => {
              this.userInfo$.next(result.data.getUserInfo);
              this.userInfo = structuredClone(result.data.getUserInfo);
              this.firstName = this.userInfo.firstName;
              this.lastName = this.userInfo.lastName;
            })
            .catch((err) => {
              console.log(err);
            });

          observer.complete();
        },
        onFailure: (error) => {
          if (error.code == 'UserNotConfirmedException') {
            // Not confirmed
            observer.error(error);
            this.authenticated$.next(false);
            this.userInfo$.next(null);
          } else if (error.code == 'PasswordResetRequiredException') {
            // Reset Password Required

            // Fake password reset since not implemented yet
            // Needed to authenticate newly imported cognito user
            this.confirmNewPassword(username, '384710', 'password');
          } else if (error.code == 'NotAuthorizedException') {
            // Not Authorised (Incorrect Password)
            observer.error(error);
            this.authenticated$.next(false);
            this.userInfo$.next(null);
          } else if (error.code == 'ResourceNotFoundException') {
            // User Not found
            observer.error(error);
            this.authenticated$.next(false);
            this.userInfo$.next(null);
          } else {
            observer.error(error);
            this.authenticated$.next(false);
            this.userInfo$.next(null);
          }
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          cognitoUser.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: (user) => {
                // console.log('Completed New Password Challenge', user);
                observer.next({ idToken: user.getIdToken().getJwtToken() });
                this.authenticated$.next(true);

                this.getUserInfo(this.userId, true)
                  .pipe(take(1))
                  .toPromise()
                  .then((result) => {
                    this.userInfo$.next(result.data.getUserInfo);
                    this.userInfo = structuredClone(result.data.getUserInfo);
                  })
                  .catch((err) => {
                    console.log(err);
                  });

                observer.complete();
              },
              onFailure: (error) => {
                // console.log(error);
                observer.error(error);
                this.authenticated$.next(false);
                this.userInfo$.next(null);
              },
            }
          );
        },
      });
    });
  }

  forgotPassword(username: string): Observable<any> {
    let userData = {
      Username: username,
      Pool: this.cognitoService.getUserPool(),
    };
    // console.log('UserService::forgotPassword Params set... the user');
    const cognitoUser = this.cognitoUserFactory(userData);

    return new Observable((observer: Observer<boolean>) => {
      cognitoUser.forgotPassword({
        onSuccess: function () {
          // console.log('UserService::forgotPassword reset password request successful');
          observer.next(true);
        },
        onFailure: function (err) {
          // console.log('UserService::forgotPassword reset password failed with error ', err);
          observer.error(err);
        },
        inputVerificationCode() {
          // I don't think this is a valid case with Admin Created users.
          observer.next(false);
        },
      });
    });
  }

  async logout() {
    // let cognitoUser = this.cognitoService.getCurrentUser();
    await this.deleteDeviceFromDB();
    await this.forgetDeviceBeforeLogOut();
    this.cognitoService.getCurrentUser().signOut();
    let navigateURL = '';

    // Don't redirect if the user is on this page
    if (this.router.url.includes('/warning/logout')) {
      navigateURL = '/warning/logout';
    }

    await this.apollo
      .getClient()
      .resetStore()
      .then((result) => {
        // console.log('Apollo Cache reset ...');
      })
      .catch((e) => {
        // ignore this error for now. Seems to be a bug and havn't noticed a side impact yet.
        // // console.log("UserService:: Logout - error clearing cache ... ", e);
      });
    this.authenticated$.next(false);
    this.userInfo$.next(null);
    this.userInfo = null;
    this.permissionsService.clearLocalPermissions();

    this.userId = null;
    this.tenantId = null;
    this.userGroups = [];
    this.email = null;
    this.family_name = null;
    this.given_name = null;
    this.firstName = null;
    this.lastName = null;

    window.location.href = navigateURL;
  }

  async deleteDeviceFromDB() {
    let payload = await this.cognitoService.getIdTokenPayload().toPromise();

    let accessToken = await this.cognitoService.getAccessToken().toPromise();

    let userID = payload['custom:userId'];

    let deviceKey = JSON.parse(atob(accessToken.accessToken.split('.')[1]))?.device_key;

    return new Promise((resolve, reject) => {
      if (deviceKey) {
        this.deleteUserDeviceInfo([deviceKey], userID)
          .pipe(take(1))
          .subscribe(
            (result) => {
              // console.log('Deleted device from DB.');
              resolve(result);
            },
            (error) => {
              // console.log('Error deleting device from DB: ', error);
              reject(error);
            }
          );
      } else {
        resolve(true);
      }
    });
  }

  forgetDeviceBeforeLogOut() {
    return new Promise((resolve, reject) => {
      let cognitoUser = this.cognitoService.getCurrentUser();

      if (cognitoUser != null) {
        cognitoUser.getSession(async (error, session) => {
          if (error) {
            // console.log('CognitoService: User not authenticated (session -> ' + error + ')', error.stack);
            reject(error);
            return;
          }

          let accessToken = await this.cognitoService.getAccessToken().toPromise();
          let deviceKey = JSON.parse(atob(accessToken.accessToken.split('.')[1]))?.device_key;

          this.cognitoService
            .listDevices()
            .pipe(take(1))
            .subscribe(
              (result) => {
                let arrayOfDevices = result.map((item) => item.deviceKey);
                // console.log('Successful in listing devices: ' + arrayOfDevices);
                if (arrayOfDevices.includes(deviceKey)) {
                  cognitoUser.forgetSpecificDevice(deviceKey, {
                    onSuccess: (result) => {
                      // console.log('Successful in forgetting device: ' + result);
                      resolve(true);
                    },
                    onFailure: (error) => {
                      // console.log('Error forgetting device: ', error);
                    },
                  });
                } else {
                  resolve(true);
                }
              },
              (error) => {
                // console.log('Error listing devices: ', error);
                reject(error);
              }
            );
        });
      }
    });
  }

  isAuthenticated(): Observable<boolean> {
    let _this = this;
    let cognitoUser = this.cognitoService.getCurrentUser();

    // console.log('**** isAuthenticated', cognitoUser);

    return new Observable((observer: Observer<boolean>) => {
      if (cognitoUser != null) {
        cognitoUser.getSession(async function (err, session) {
          if (err) {
            console.error('❌ UserService: User not authenticated (session -> ' + err + ')', err);
            observer.error(err);
            _this.authenticated$.next(false);
            _this.userInfo$.next(null);
            _this.logout();
            return;
          }
          // console.log('UserService: User authenticated (session -> ' + session.isValid() + ')');

          // Check if current session still going - if not reload permissions
          // let currentUser = _this.userId;

          let payload = session.getIdToken().payload;
          _this.userId = payload['custom:userId'];
          _this.tenantId = payload['custom:tenantId'];
          _this.userGroups = payload['cognito:groups'];
          _this.email = payload['email'];
          _this.family_name = payload['family_name'];
          _this.given_name = payload['given_name'];

          let userInfoResponse = await _this
            .getUserInfo(_this.userId, true)
            .pipe(take(1))
            .toPromise()
            .catch((err) => {
              console.log(err);
            });
          console.log('userInfoResponse', userInfoResponse);
          _this.userInfo$.next(userInfoResponse.data.getUserInfo);
          _this.userInfo = structuredClone(userInfoResponse.data.getUserInfo);

          // Why set this to true if session may or may not be valid
          // Why not session.isValid() instead of hard code true.
          _this.authenticated$.next(true);

          // I think this is where cognito double checks if session is still valid
          observer.next(session.isValid());

          observer.complete();
        });
      } else {
        // console.log('UserService: can\'t retrieve the current user');
        observer.next(false);
        _this.authenticated$.next(false);
        _this.userInfo$.next(null);
        observer.complete();
        // This might need to be a full window.location refresh.
        // _this.router.navigateByUrl('/login');
      }
    });
  }

  // Feature not implemented yet, this code allows authentication of imported users until feature is implemented
  confirmNewPassword(alias: string, verificationCode: string, newPassword: string): Observable<boolean> {
    const userData = {
      Username: alias.trim(),
      Pool: this.cognitoService.getUserPool(),
    };

    const cognitoUser = this.cognitoUserFactory(userData);

    return new Observable((observer: Observer<boolean>) => {
      cognitoUser.confirmPassword(verificationCode.trim(), newPassword.trim(), {
        onSuccess: () => {
          // console.log('UserService::confirmNewPassword - success');
          observer.next(true);
        },
        onFailure: (err) => {
          // console.log('UserService::confirmNewPassword - fail');
          observer.error(err);
        },
      });
    });
  }

  getAuthenticatedUserId(): Observable<string> {
    let cognitoUser = this.cognitoService.getCurrentUser();
    return new Observable((observer: Observer<string>) => {
      if (cognitoUser != null) {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            // console.log('UserService: User not authenticated (session -> ' + err + ')', err.stack);
            observer.error(err);
            return;
          }
          cognitoUser.getUserAttributes((error, result) => {
            if (result) {
              const userIdAttribute = result.filter((attr) => attr.getName() == 'custom:userId')[0];
              // console.log('UserService: userIdAttribute', userIdAttribute);
              observer.next(userIdAttribute.getValue());
              observer.complete();
            } else {
              console.error('error', error);
              observer.error(error);
              return;
            }
          });
        });
      } else {
        // console.log('UserService: Can\'t retrieve the authenticated user');
        observer.next('');
        observer.complete();
      }
    });
  }

  getAuthenticatedUserInfo(): Observable<any> {
    let cognitoUser = this.cognitoService.getCurrentUser();

    return new Observable((observer: Observer<any>) => {
      if (cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            // console.log('UserService: User not authenticated (session -> ' + err + ')', err.stack);
            observer.error(err);
            return;
          }

          const cognitoUserGroups = session.getIdToken().payload['cognito:groups'];
          let cognitoUserInfo = {};
          cognitoUserInfo['groups'] = cognitoUserGroups;

          cognitoUser.getUserAttributes((error, cognitoUserAttributes) => {
            if (cognitoUserAttributes) {
              let userAttributes = cognitoUserAttributes.reduce((obj, item: any) => {
                obj[item.Name] = item.Value;
                return obj;
              }, {});

              cognitoUserInfo['attributes'] = userAttributes;

              this.cognitoUserInfo$.next(cognitoUserInfo);
              // console.log('UserService: cognitoUserInfo', cognitoUserInfo);
              observer.next(cognitoUserInfo);
              observer.complete();
            } else {
              console.error('error', error);

              // If tokens are revoked and not on warning page from subscription,
              // log out and clear the session
              if (error.message === 'Access Token has been revoked' && this.router.url !== '/warning/logout') {
                this.logout();
                return;
              }
              observer.error(error);
              return;
            }
          });
        });
      } else {
        // console.log('UserService: Can\'t retrieve the authenticated user');
        observer.next({});
        observer.complete();
      }
    });
  }

  getAuthenticatedUserGroups(): Observable<[string]> {
    let cognitoUser = this.cognitoService.getCurrentUser();
    return new Observable((observer: Observer<any>) => {
      if (cognitoUser != null) {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            // console.log('UserService: User not authenticated (session -> ' + err + ')', err.stack);
            observer.error(err);
            observer.complete();
          }
          const cognitoUserGroups = session.getIdToken().payload['cognito:groups'];
          // console.log('UserService: cognitoUserGroups', cognitoUserGroups);
          observer.next(cognitoUserGroups);
          observer.complete();
        });
      } else {
        // console.log('UserService: Can\'t retrieve the authenticated user');
        observer.next('');
        observer.complete();
      }
    });
  }

  isAdmin(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getAuthenticatedUserGroups().subscribe(
        (result) => {
          let isAdmin = result.includes('mmAdmin');
          resolve(isAdmin);
        },
        (error) => {
          console.error(`Admin Guard error getting user groups ${error}`);
          reject(error);
        }
      );
    });
  }

  isTenantOrMMAdmin(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getAuthenticatedUserGroups().subscribe(
        (result) => {
          let isAdmin = result.includes('mmAdmin') || result.includes('tenantAdmin');
          resolve(isAdmin);
        },
        (error) => {
          console.error(`Admin Guard error getting user groups ${error}`);
          reject(error);
        }
      );
    });
  }

  isInternalTenant(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getAuthenticatedUserInfo().subscribe(
        (result) => {
          let internal = false;
          if (result.attributes['custom:tenantId'] == 'TENANT$MODELMATCH-INTERNAL') {
            internal = true;
          }
          resolve(internal);
        },
        (error) => {
          console.error(`Internal Tenant Guard error getting user info ${error}`);
          reject(error);
        }
      );
    });
  }

  // Special class of user...
  isInternalTenantUserNotAdmin() {
    return new Promise((resolve, reject) => {
      this.getAuthenticatedUserGroups().subscribe(
        (result) => {
          this.isInternalTenant().then((internal) => {
            resolve(!result.includes('mmAdmin') && internal);
          });
        },
        (error) => {
          console.error(`Admin Guard error getting user groups ${error}`);
          reject(error);
        }
      );
    });
  }

  isTenantLeader(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getAuthenticatedUserGroups().subscribe(
        (result) => {
          let isLeader = result.includes('tenantLeader');
          resolve(isLeader);
        },
        (error) => {
          console.error(`Admin Guard error getting user groups ${error}`);
          reject(error);
        }
      );
    });
  }

  isBillingUser(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getAuthenticatedUserGroups().subscribe(
        (result) => {
          let isBillingUser = result.includes('billing');
          resolve(isBillingUser);
        },
        (error) => {
          console.error(`Admin Guard error getting user groups ${error}`);
          reject(error);
        }
      );
    });
  }

  addUser(userInput: AddUserInput): Observable<any> {
    userInput.email = userInput.email.toLowerCase();

    return this.apollo.mutate({
      mutation: gql(addUser),
      variables: { userInput: userInput },
    });
  }

  addToNewUserStepQueue(userInput: AddUserInput): Observable<any> {
    userInput.email = userInput.email.toLowerCase();

    return this.apollo.mutate({
      mutation: gql(addToNewUserStepQueue),
      variables: { userInput: userInput },
    });
  }

  notifyStepQueueMessageProcessed(userId: string) {
    return this.client.subscribe({
      query: gql(notifyStepQueueMessageProcessed),
      variables: { userId: userId },
    });
  }

  public getUserInfo(userId: String, noCacheFlag: boolean = false): Observable<any> {
    return this.apollo.watchQuery<GetUserInfo>({
      query: gql(getUserInfo),
      variables: { userId: userId },
      ...(noCacheFlag === true && { fetchPolicy: 'network-only' }),
    }).valueChanges;
  }

  public updateUserInfo(userId: String, userInput: UpdateUserInfoInput): Observable<any> {
    return this.apollo.mutate<UpdateUserInfo>({
      mutation: gql(updateUserInfo),
      variables: { userId: userId, userInput: userInput },
      update: (store, { data }) => {
        // Update the information stored in the user service
        this.userInfo$.next(data.updateUserInfo);

        // Update the cache
        let update: User = data.updateUserInfo;
        try {
          const userInfo: GetUserInfo = store.readQuery({
            query: gql(getUserInfo),
            variables: { userId: userId },
          });

          let userInfoLocal = structuredClone(userInfo);

          userInfoLocal.getUserInfo.firstName = update.firstName;
          userInfoLocal.getUserInfo.lastName = update.lastName;
          userInfoLocal.getUserInfo.emails = update.emails;
          userInfoLocal.getUserInfo.notifications = update.notifications;
          userInfoLocal.getUserInfo.agentPhone = update.agentPhone;
          userInfoLocal.getUserInfo.smsPhone = update.smsPhone;
          userInfoLocal.getUserInfo.smsNotify = update.smsNotify;
          userInfoLocal.getUserInfo.nmlsId = update.nmlsId;

          store.writeQuery({
            query: gql(getUserInfo),
            variables: { userId: userId },
            data: userInfoLocal,
          });

          console.log('updating user cache', userInfo);
        } catch (e) {
          console.log('error updating user cache', e);
        }
      },
    });
  }

  public disableUser(tenantId: string, userId: string, userName: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(disableUser),
      variables: { tenantId: tenantId, userId: userId, userName: userName },
      fetchPolicy: 'no-cache',
      update: (store, { data: { disableUser } }: any) => {},
    });
  }

  public enableUser(tenantId: string, userId: string, userName: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(enableUser),
      variables: { tenantId: tenantId, userId: userId, userName: userName },
      fetchPolicy: 'no-cache',
      update: (store, { data: { disableUser } }: any) => {},
    });
  }

  // CREATE - SET
  public setUserSettingKey(iName: string, settingValue: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(setUserSettingKey),
      variables: {
        iName: iName,
        settingValue: settingValue,
      },
      update: (store) => {
        const data = store.readQuery({
          query: gql(readUserSettingKey),
          variables: { iName },
        }) as any;

        const extractedCache = this.apollo.client.cache.extract();
        Object.keys(extractedCache?.ROOT_QUERY)
          .filter((key) => key.includes('getAgentAndOriginatorReqs'))
          .forEach((key) => {
            delete extractedCache.ROOT_QUERY[key];

            this.apollo.client.cache.restore(extractedCache);
          });

        let clonedCache = structuredClone(data);

        if (!clonedCache) {
          clonedCache = {
            readUserSettingKey: {
              __typename: 'UserSetting',
              iName,
              settingValue,
            },
          };
        }

        clonedCache.readUserSettingKey = {
          ...clonedCache.readUserSettingKey,
          settingValue,
        };

        store.writeQuery({
          query: gql(readUserSettingKey),
          variables: { iName },
          data: clonedCache,
        });
      },
    });
  }

  // CREATE - SET
  public setUserSettingKeyForUser(iName: string, userId: string, settingValue: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(setUserSettingKeyForUser),
      variables: {
        iName: iName,
        settingValue: settingValue,
        userId: userId,
      },
      update: (store) => {
        const data = store.readQuery({
          query: gql(readUserSettingKeyForUser),
          variables: { iName, userId },
        }) as any;

        const extractedCache = this.apollo.client.cache.extract();
        Object.keys(extractedCache?.ROOT_QUERY)
          .filter((key) => key.includes('getAgentAndOriginatorReqs'))
          .forEach((key) => {
            delete extractedCache.ROOT_QUERY[key];

            this.apollo.client.cache.restore(extractedCache);
          });

        let clonedCache = structuredClone(data);

        if (!clonedCache) {
          clonedCache = {
            readUserSettingKeyForUser: {
              __typename: 'UserSetting',
              iName,
              settingValue,
            },
          };
        }

        clonedCache.readUserSettingKeyForUser = {
          ...clonedCache.readUserSettingKeyForUser,
          settingValue,
        };

        store.writeQuery({
          query: gql(readUserSettingKeyForUser),
          variables: { iName, userId },
          data: clonedCache,
        });
      },
    });
  }

  // READ
  readUserSettingKey(iName: string, fetchPolicy?: WatchQueryFetchPolicy): Observable<any> {
    return this.apollo.watchQuery<UserSetting>({
      query: gql(readUserSettingKey),
      variables: { iName: iName },
      fetchPolicy: fetchPolicy ?? 'network-only',
    }).valueChanges;
  }

  readUserSettingKeyForUser(iName: string, userId: string): Observable<any> {
    return this.apollo.watchQuery<UserSetting>({
      query: gql(readUserSettingKeyForUser),
      variables: { iName, userId },
    }).valueChanges;
  }

  // DELETE - PHYSICAL
  public deleteUserSettingKey(iName: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(deleteUserSettingKey),
      variables: {
        iName: iName,
      },
      update: (store, { data: { deleteUserSetting } }: any) => {
        // Add a new history item.
      },
    });
  }

  public isZapAuthenticated(): Observable<any> {
    return this.apollo.watchQuery<IsZapAuthenticated>({
      query: gql(isZapAuthenticated),
      variables: {},
    }).valueChanges;
  }

  public disconnectZapier(): Observable<any> {
    return this.apollo.mutate<DisconnectZapier>({
      mutation: gql(disconnectZapier),
      variables: {},
      update: (store, { data: { disconnectZapier } }) => {
        if (!disconnectZapier) {
          return;
        }
      },
    });
  }

  public trainingGetUserInfo(placeHolder: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(trainingGetUserMoreInfo),
      variables: { placeHolder: placeHolder },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getUserImportHistory(userId): Observable<any> {
    return this.apollo.watchQuery<GetUserImportHistory>({
      query: gql(getUserImportHistory),
      fetchPolicy: 'network-only',
      variables: { userId },
    }).valueChanges;
  }

  // CREATE - SET
  public setUserNotificationsKey(subscription: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(setUserNotificationsKey),
      variables: {
        subscription: subscription,
      },
      update: (store, { data: { setUserNotificationsKey } }: any) => {
        // Add a new history item.
      },
    });
  }

  // DELETE - UN-SET
  public deleteUserNotificationsKey(subscription: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(deleteUserNotificationsKey),
      variables: {
        subscription: subscription,
      },
      update: (store, { data: { deleteUserNotificationsKey } }: any) => {
        // Add a new history item.
      },
    });
  }

  // Check - If-Set
  public isUserNotificationsKeySet(subscription: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(isUserNotificationsKeySet),
      variables: {
        subscription: subscription,
      },
      update: (store, { data: { isUserNotificationsKeySet } }: any) => {
        // Add a new history item.
      },
    });
  }

  public updateUserMetric(type, count = 1): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(updateUserMetricMutation),
      variables: { type, count },
    });
  }

  public getUserMetrics(userId): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getUserMetricsQuery),
      variables: { userId },
    }).valueChanges;
  }

  // Get Feature Status For A Specific User
  /**@deprecated */
  getFeatureByNameForUser(
    feature: string,
    userId: string = '',
    tenantId: string = '',
    companySettingsFeature?: boolean
  ): Observable<any> {
    // // console.log('getFeatureByNameForUser client service :: params ', feature, userId, tenantId);

    if (this.permissionsService.getCurrentPermissions().debug.permissions) {
      console.group('⚠️ Deprecated Function: user.service.ts::getFeatureByNameForUser still being used!');
      console.log(`
      Feature: ${feature}
      userId: ${userId}
      tenantId: ${tenantId}
      Company Settings Feature: ${companySettingsFeature}
      `);
      console.trace();
      console.groupEnd();
    }

    return this.apollo.watchQuery<boolean>({
      query: gql(getFeatureByNameForUser),
      variables: {
        feature,
        userId,
        tenantId,
        companySettingsFeature,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // Set Feature Status For A Specific User
  /**@deprecated */
  public setFeatureByNameForUser(feature: string, userId: string, setVal: boolean): Observable<any> {
    if (this.permissionsService.getCurrentPermissions().debug.permissions) {
      console.group('⚠️ Deprecated Function: user.service.ts::setFeatureByNameForUser still being used!');
      console.log(`
      Feature: ${feature}
      userId: ${userId}
      setVal: ${setVal}
      `);
      console.trace();
      console.groupEnd();
    }
    return this.apollo.mutate<any>({
      mutation: gql(setFeatureByNameForUser),
      variables: {
        feature,
        userId,
        setVal,
      },
    });
  }
  public addToAllReqs(userId: string, value: boolean): Observable<any> {
    if (this.permissionsService.getCurrentPermissions().debug.permissions) {
      console.group('⚠️ Deprecated Function: user.service.ts::setFeatureByNameForUser still being used!');
      console.log(`
      userId: ${userId}
      setVal: ${value}
      `);
      console.trace();
      console.groupEnd();
    }
    return this.apollo.mutate<any>({
      mutation: gql(addToAllReqs),
      variables: {
        userId,
        value,
      },
    });
  }

  public getTenantInfo(tenantId: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql(getTenantInfo),
      variables: { tenantId },
    }).valueChanges;
  }

  public listGroupsForUser(userNames: string[]): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(listGroupsForUser),
      variables: { userNames },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public addUserToGroup(userName: String, groupName: String): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(addUserToGroup),
      variables: { userName, groupName },
    });
  }

  public removeUserFromGroup(userName: String, groupName: String): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(removeUserFromGroup),
      variables: { userName, groupName },
    });
  }

  // SUBSCRIPTION
  updatedUsernameLogIn(userName: String) {
    // console.log('updatedUsernameLogIn enabled for user: ', userName);

    return this.client.subscribe({
      query: gql(updatedUsernameLogIn),
      variables: { userName: userName },
    });
  }

  // SUBSCRIPTION
  updatedUserDeviceLogIn(deviceKey: String) {
    // console.log('updatedUserDeviceLogIn enabled for deviceKey: ', deviceKey);

    return this.client.subscribe({
      query: gql(updatedUserDeviceLogIn),
      variables: { deviceKey: deviceKey },
    });
  }

  // MUTATION
  public enableDeviceRememberingForOldUser(userName: String): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(enableDeviceRememberingForOldUser),
      variables: { userName: userName },
    });
  }

  // MUTATION
  public notifyUsernameBeforeLogOut(userName: String, userID: String): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(notifyUsernameBeforeLogOut),
      variables: { userName: userName, userID: userID },
    });
  }

  // MUTATION
  public notifyUserDeviceBeforeLogOut(deviceKey: String): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(notifyUserDeviceBeforeLogOut),
      variables: { deviceKey: deviceKey },
    });
  }

  // MUTATION
  public setUserDeviceInfo(deviceInfo: any): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(setUserDeviceInfo),
      variables: { deviceInfo: deviceInfo },
    });
  }

  // QUERY
  public getUserDevicesInfo(userID: String): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getUserDevicesInfo),
      variables: { userID: userID },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // MUTATION
  public deleteUserDeviceInfo(deviceKey: String[], userID: String): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(deleteUserDeviceInfo),
      variables: { deviceKey: deviceKey, userID: userID },
    });
  }

  public async userHasPermissionToContact(reqId: string, contactId: string): Promise<boolean> {
    return this.userHasPermissionToContact$(reqId, contactId).toPromise();
  }

  public userHasPermissionToContact$(reqId: string, contactId: string): Observable<boolean> {
    return this.apollo
      .query<{ userHasPermissionToContact: boolean }>({
        query: gql(userHasPermissionToContact),
        variables: { reqId, contactId },
        fetchPolicy: 'network-only',
      })
      .pipe(
        take(1),
        map((query) => {
          console.log('userHasPermissionToContact$', query);
          return query.data.userHasPermissionToContact;
        })
      );
  }

  public getUsersActivatedStatus(usersToCheck: UsersToCheck[]): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(getUsersActivatedStatus),
      variables: { usersToCheck },
    });
  }

  public getListRunningTasksFromLogs(): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(getListRunningTasksFromLogs),
      variables: { input: '' },
    });
  }

  public forceUserLogout(username) {
    return this.apollo.mutate({
      mutation: gql(forceUserLogout),
      variables: { username },
    });
  }
}
