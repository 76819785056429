<div *ngIf="!loading" class="page">
  <div class="container">
    <div class="header">
      <div class="wrapper">
        <img class="header-logo" src="assets/images/model-match-icon-and-text.png" alt="Model Match Logo" />
      </div>
    </div>
    <hr class="hr-style" />
    <div class="inner-container">
      <div class="logout-info">
        <div [innerHTML]="pageData.headline"></div>
        <div [innerHTML]="pageData.sub_headline"></div>
        <br />
        <p *ngIf="pageData.contactSupport">
          <b class="black-text">Not you?</b> Click
          <a class="blue-link" href="https://help.modelmatch.com/help/how-do-i-secure-my-model-match-account">here</a>
          to learn more and contact
          <a href="mailto:support@modelmatch.com" class="blue-link">support@modelmatch.com.</a>
        </p>
        <br *ngIf="pageData.contactSupport" />
      </div>
      <div class="logo-container">
        <img class="mm-logo" src="assets/logos/modelmatch_logo.svg" alt="Model Match Logo" />
      </div>
    </div>
  </div>
</div>
