import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'relativeDate' })
export class RelativeDatePipe implements PipeTransform {
  day = 1000 * 60 * 60 * 24;
  week = this.day * 7;
  daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  transform(dateString: string, ...args: any[]) {
    if (!dateString) return '';
    const now = Date.now();
    const date = new Date(dateString);
    // const date = new Date('2021-11-10');
    const dateValue = date.valueOf();

    // console.log();

    // Today - Time
    // If the date is after midnight today, return the time.
    if (this.isToday(date)) {
      return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    }
    // Yesterday - 'Yesterday'
    // If the date is older than last night at midnight, return "Yesterday"
    else if (this.isYesterday(date)) {
      return 'Yesterday';
    }
    // Within a week - Day of the week
    else if (dateValue > now - this.week) {
      return this.daysOfWeek[date.getDay()];
    }
    // Older than a week - Month Day, Year
    else {
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    }
  }

  isToday(date: Date) {
    const today = new Date();
    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  }

  isYesterday(date: Date) {
    const today = new Date();
    return (
      date.getDate() == today.getDate() - 1 &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  }
}
