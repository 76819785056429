<div mat-dialog-title>
  <button mat-icon-button class="button-close" (click)="onCloseClick()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="flex-container header-container" fxLayoutGap="1em" fxLayout="row" fxLayout.lt-lg="column">
    <div fxFlex="25%">
      <header class="dialog-header">
        <h3>Choose a Sequence</h3>
      </header>
    </div>

    <div fxFlex fxLayoutAlign="start center" class="req-options">
      <mat-form-field appearance="outline" style="width: 100%; margin-right: 2em">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" #searchObjListInput [formControl]="searchSeqsControl" />
      </mat-form-field>
    </div>
  </div>
</div>

<section>
  <div class="container">
    <div class="flex-container" fxLayoutGap="1em" fxLayout="row" fxLayout.lt-lg="column">
      <div class="child-1" fxFlex="25%" fxFlex.gt-md="25%" fxFlexOrder="1" fxFlexOrder.lt-lg="1" style="flex-grow: 1">
        <mat-action-list>
          <button mat-list-item (click)="showSystemTemplates()">System Sequences</button>
          <!-- <button mat-list-item (click)="showTenantTemplates()"> Company Sequences </button> -->
          <button mat-list-item (click)="showUserTemplates()">User Sequences</button>
          <button mat-list-item (click)="showAllTemplates()">Show All</button>
        </mat-action-list>
      </div>

      <div
        class="child-2 integrations-description"
        fxFlex="75%"
        fxFlex.gt-md="75%"
        fxFlexOrder="1"
        fxFlexOrder.lt-lg="1"
        style="flex-grow: 5; height: 60vh; overflow: auto"
      >
        <mat-grid-list cols="2" rowHeight="2:1" gutterSize="1.2em">
          <mat-grid-tile *ngIf="!dataLoaded">
            <mat-progress-bar mode="buffer" style="margin: 1.2em auto"></mat-progress-bar>
          </mat-grid-tile>

          <mat-grid-tile *ngFor="let mItem of filteredMenuItems | async; let index = index" class="sequenceObjTile">
            <mat-card (click)="onClickAdd(mItem)" class="sequenceObj">
              <!-- <button
                *ngIf="permissionSaveSystem(mItem) || permissionDelete(mItem)"
                mat-icon-button
                class="template-options"
                [matMenuTriggerFor]="menuSequenceOptions"
                (click)="onOptions($event)"
              >
                <mat-icon>build</mat-icon>
              </button>

              <mat-menu #menuSequenceOptions="matMenu" class="options-menu">
                <button *ngIf="permissionSaveSystem(mItem)" mat-menu-item (click)="saveSys($event, mItem)">
                  <mat-icon>save</mat-icon>Save as System Sequence
                </button>

                <button
                  *ngIf="permissionSaveTenant(mItem) || permissionSaveSystem(mItem)"
                  mat-menu-item
                  (click)="saveTenant($event, mItem)"
                >
                  <mat-icon>save</mat-icon>Save as Tenant Sequence
                </button>

                <button *ngIf="permissionDelete(mItem)" mat-menu-item (click)="onDeleteSequence($event, mItem)">
                  <mat-icon>delete_forever</mat-icon>Delete
                </button>
              </mat-menu> -->

              <mat-card-title class="cardCenter">{{ mItem.title }}</mat-card-title>
              <mat-card-content class="cardCenter">
                <i>
                  {{ mItem.desc }}
                </i>
              </mat-card-content>

              <div style="position: absolute; bottom: 0.5em; left: 1em">
                <mat-icon *ngIf="mItem.pKey.includes('USER$')">perm_identity</mat-icon>
                <mat-icon *ngIf="mItem.pKey.includes('SYSTEM')">business</mat-icon>
                <mat-icon *ngIf="mItem.pKey.includes('TENANT$')">users</mat-icon>
              </div>

              <mat-card-footer style="position: absolute; bottom: 1em; right: 2em">
                <p class="mat-caption">Last Updated {{ mItem.lastUpdated | date: 'MM/dd/yy' }}</p>
              </mat-card-footer>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </div>
</section>
