import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mm-logo',
  templateUrl: 'logo.component.html',
})
export class LogoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
