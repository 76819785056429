import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModelMatchComponent } from 'app/market-insight/ModelMatchComponent';
import { ContactService } from 'app/services/contact.service';
import { ImageCachingService } from 'app/services/imagecaching.service';
import { TenantService } from 'app/services/tenant.service';
import { UserService } from 'app/services/user.service';
import { Contact, UserContact } from 'app/shared/model/contacts';
import { Email, User } from 'app/shared/model/user';
import { forkJoin, fromEvent } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'mm-contact-share',
  template: `
    <div class="flex flex-col h-48 overflow-hidden">
      <div class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd"
          />
        </svg>
        <input #search class="apperance-none focus_outline-none px-2 py-1 text-sm" placeholder="Search" />
      </div>
      <ul class="flex-grow overflow-scroll no-scrollbar">
        <div *ngIf="!touched && loading" class="w-full h-full flex items-center justify-center">
          <mm-spinner></mm-spinner>
        </div>
        <li *ngFor="let user of tenantUsers">
          <mm-user-row
            [user]="user"
            [checked]="selectedUsers.includes(user.data.id)"
            (onSelectUser)="selectUser($event)"
          ></mm-user-row>
        </li>
      </ul>
      <div class="flex space-x-1 w-full">
        <div class="flex-grow">
          <mm-button
            [success]="success"
            [canRetry]="true"
            [loading]="loading && touched"
            [disabled]="!selectedUsers.length && !success"
            [options]="{
              style: 'secondary',
              size: 'sm'
            }"
            (onClick)="shareContacts()"
          >
            Share
          </mm-button>
        </div>
        <mm-button
          [options]="{
            style: 'link',
            size: 'sm'
          }"
          (onClick)="closePopover.emit()"
        >
          Cancel
        </mm-button>
      </div>
    </div>
  `,
  styleUrls: ['../../nick_styles/nick.css'],
})
export class ContactSharePopoverComponent extends ModelMatchComponent implements OnInit, AfterViewInit {
  @ViewChild('search') search: ElementRef;
  @Input() contact: UserContact;
  @Output() closePopover: EventEmitter<any> = new EventEmitter();
  public touched: boolean = false;
  public loading: boolean = false;
  public error: string;
  public success: string | boolean = false;
  public tenantUsers: {
    display: string;
    keys: string[];
    data: { id: string; firstName: string; lastName: string; emails: Email[] };
  }[];
  private _tenantUsers: {
    display: string;
    keys: string[];
    data: { id: string; firstName: string; lastName: string; emails: Email[] };
  }[];
  public selectedUsers: string[] = [];
  constructor(
    private tenantService: TenantService,
    private contactService: ContactService
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.tenantService
      .getTenantWithUsers({ tenantId: this.contact.tenantId })
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.tenantUsers = result.data.getTenant.user.map(({ id, firstName, lastName, emails }) => {
            return {
              display: firstName + ' ' + lastName,
              keys: [firstName, lastName, ...emails.map((email) => email.address)],
              data: { id, firstName, lastName, emails },
            };
          });
          this._tenantUsers = this.tenantUsers;
          this.loading = false;
          this.touched = true;
        },
        (error) => {
          console.log('SingleContactComponent :: error loading tenant information', error);
        }
      );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.search.nativeElement.focus();
    }, 100);
    this.subscribeToInputChanges();
  }

  subscribeToInputChanges() {
    this.subscribe(
      fromEvent(this.search.nativeElement, 'keyup').pipe(
        // tap((event: KeyboardEvent) => this.checkForHotKeys(event)),
        tap((event: KeyboardEvent) => {
          if (!(event.target instanceof HTMLInputElement)) return;
          const searchValue = event.target.value;
          this.tenantUsers = this._tenantUsers.filter((user) => {
            return user.keys.some((key) => key.toLowerCase().includes(searchValue.toLowerCase()));
          });
        })
      ),
      () => {},
      (error) => {
        console.log('ShareContactToUserComponent :: error subscribing to input changes', error);
      }
    );
  }

  selectUser(userId) {
    if (!this.selectedUsers.includes(userId)) {
      this.selectedUsers.push(userId);
    } else {
      this.selectedUsers = this.selectedUsers.filter((id) => id !== userId);
    }
  }

  shareContacts() {
    if (this.success) this.closePopover.emit();
    this.loading = true;
    forkJoin(
      this.selectedUsers.map((userId) => {
        return this.contactService.shareContactToUser(this.contact.contactId, userId);
      })
    )
      .pipe(take(1))
      .subscribe(
        (result: any[]) => {
          this.loading = false;
          this.success = 'Done';
          this.selectedUsers = [];
          console.log('ShareContactToUserComponent :: shared contact to user result ', result);
        },
        (error) => {
          this.loading = false;
          console.log('ShareContactToUserComponent :: error sharing contact to user.', error);
        }
      );
  }
}

@Component({
  selector: 'mm-user-row',
  template: `
    <div (click)="selectUser(user.data.id)" class="flex space-x-2 items-center">
      <mm-checkbox [value]="checked ? 1 : 0"> </mm-checkbox>
      <img *ngIf="userAvatar" class="h-4 w-4 rounded-full" src="{{ userAvatar }}" />
      <div
        *ngIf="!userAvatar"
        class="h-4 w-4 flex items-center justify-center rounded-full bg-blue-500 text-white font-semibold text-xs"
      >
        {{ user.data.firstName[0] }}
      </div>
      <span class="text-sm">
        {{ user.display }}
      </span>
    </div>
  `,
  styleUrls: ['../../nick_styles/nick.css'],
})
export class UserRowComponent implements OnInit {
  @Input() checked: boolean;
  @Input() user: {
    display: string;
    keys: string[];
    data: { id: string; firstName: string; lastName: string; emails: Email[] };
  };
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelectUser = new EventEmitter<string>();
  public userAvatar: string;
  constructor(
    private userService: UserService,
    private imageCachingService: ImageCachingService
  ) {}

  ngOnInit() {
    this.getUserAvatarUrl(this.user.data.id);
  }

  selectUser(userId) {
    setTimeout(() => {
      this.onSelectUser.emit(userId);
    }, 1);
  }

  getUserAvatarUrl(id) {
    this.userService
      .getUserAvatarUrl(id)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this.userAvatar = data.getUserAvatarUrl?.url || data.getContactAvatarUrl?.url;
          if (data.getUserAvatarUrl?.url) this.imageCachingService.cacheImageFromUrl(data.getUserAvatarUrl?.url, id);
        },
        (error) => {
          console.log(error);
          this.userAvatar = null;
        }
      );
  }
}
