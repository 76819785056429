<mat-card class="contact-card">
  <mat-card-header>
    <div mat-card-avatar class="contact-card-header-image">
      <mat-icon class="muted">screen_share</mat-icon>
    </div>
    <mat-card-title> Here is your link </mat-card-title>

    <div fxFlex fxLayoutAlign="end center">
      <button mat-icon-button (click)="onXClick()" color="gray-light" class="closeButton">
        <mat-icon matSuffix class="muted" size="sm">close</mat-icon>
      </button>
    </div>
  </mat-card-header>

  <mat-card-content>
    <p>{{ onCopy(data.objId) }}</p>
    <button mat-button [cdkCopyToClipboard]="onCopy(data.objId)">Copy to clipboard</button>
  </mat-card-content>
</mat-card>
