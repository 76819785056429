<!-- <div>
    <button *ngIf='false' (click)='togglePane()'>Integrated Toggler {{activePane}}</button>
</div> -->
<div class="panes" [@slide]="activePane">
  <div>
    <ng-content select="[leftPane]"></ng-content>
  </div>
  <div>
    <!-- <button (click)="activePane='left'">close me</button> -->
    <ng-content select="[rightPane]"></ng-content>
  </div>
</div>
