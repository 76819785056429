<h1 mat-dialog-title style="margin-bottom: 0 !important" *ngIf="data.title">{{ data.title }}</h1>
<i>{{ data.message }}</i>
<h1></h1>
<mat-dialog-content>
  <div>{{ data.user.lastName }}, {{ data.user.firstName }}</div>
  <h1 mat-dialog-title>{{ data.user.username }}</h1>

  <h1></h1>

  <mat-form-field style="width: 100%">
    <mat-label>Target to Assign ({{ openTodosSingleUserCount }} open) Todos</mat-label>
    <mat-select [(value)]="targetUserId">
      <mat-option *ngFor="let targetUser of data.tenant.user" [value]="targetUser.id">
        {{ targetUser.lastName }}, {{ targetUser.firstName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" (click)="cancelDisableUser()" color="{{ data.cancelButton?.color }}">
    {{ data.cancelButton?.label || 'Cancel' }}
  </button>
  <button
    mat-raised-button
    [mat-dialog-close]="true"
    (click)="disableUser()"
    color="{{ data.confirmButton?.color || 'primary' }}"
  >
    {{ data.confirmButton?.label || 'Ok' }}
  </button>
</mat-dialog-actions>
