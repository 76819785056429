import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { createNotificationMutation } from '../graphql/mutations';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MMSnackBarComponent } from 'app/shared/snackbar/mm-snackbar.component';
import { SnackbarWithButtonsComponent } from 'app/shared/snackbarWithButtons/snackbar-with-buttons.component';

@Injectable()
export class NotificationsService {
  constructor(
    private apollo: Apollo,
    private snackBar: MatSnackBar
  ) {}
  /**Opens a custom Model Match Snackbar with a message.
   * @param {MatSnackBarConfig} config A config that will allow you to override a default config.
   */
  openSnackBar(message: string);
  openSnackBar(message: string, config: MatSnackBarConfig);
  openSnackBar(message: string, config?: MatSnackBarConfig) {
    const defaultConfig: MatSnackBarConfig = {
      data: message,
      panelClass: 'snackbar',
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    };

    //Combine the two configs to use any overrides that were passed in.
    const snackBarConfig = Object.assign(defaultConfig, config);

    this.snackBar.openFromComponent(MMSnackBarComponent, snackBarConfig);
  }

  /**
   * Incomplete, do not use.
   */
  openSnackBarWithButtons(data: SnackBarData, config?: MatSnackBarConfig) {
    data['dismissCallback'] = data.dismissCallback ? data.dismissCallback : this.dismissSnackBar;
    const defaultConfig: MatSnackBarConfig = {
      data: data,
      panelClass: 'snackbar',
      duration: 0,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    };

    //Combine the two configs to use any overrides that were passed in.
    const snackBarConfig = Object.assign(defaultConfig, config);

    this.snackBar.openFromComponent(SnackbarWithButtonsComponent, snackBarConfig);
    // this.snackBar.dismiss();
  }

  dismissSnackBar() {
    this.snackBar.dismiss();
  }

  public createNotification(userId: string, type: string, data: string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(createNotificationMutation),
      variables: {
        userId,
        type,
        data,
      },
    });
  }
}

export type SnackBarData = {
  message: string;
  actionLabel: string;
  actionCallback: Function;
  dismissCallback?: Function;
};
