<h4>What was the result?</h4>
<mat-form-field>
  <mat-select (selectionChange)="handleSelect($event.value)" placeholder="Meeting Disposition" style="margin-top: 5px">
    <mat-option *ngFor="let disp of dispositions" [value]="disp">
      {{ disp }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-dialog-actions>
  <button mat-button color="primary" [mat-dialog-close]="false">Discard</button>
  <button (click)="onComplete()" mat-button color="primary" [disabled]="!this.data.disposition">Complete</button>
</mat-dialog-actions>
