import { Component, OnInit, OnDestroy, Inject, Output, EventEmitter, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'mm-finalize-import-dialog',
  templateUrl: './finalize-import-dialog.component.html',
  styleUrls: ['./finalize-import-dialog.component.scss'],
})
export class FinalizeImportDialog implements OnInit, OnDestroy {
  importOptions: any;

  constructor(
    public dialogRef: MatDialogRef<FinalizeImportDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log(this.data);
    this.importOptions = this.data;
  }

  removeContactTag(tag) {
    this.importOptions.tags = this.importOptions.tags.filter((a) => a.tagId !== tag.tagId);
  }

  startImport() {
    this.dialogRef.close(this.importOptions);
  }

  ngOnDestroy() {}
}
