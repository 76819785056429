import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProcessTotalsDialogComponent } from './process-totals-dialog.component';
import { ProcessTotal } from '../model/process-total';
import { Router } from '@angular/router';
import { ContactsService } from 'app/shared-contacts-components/contacts.service';

@Component({
  selector: 'mm-process-totals',
  templateUrl: './process-totals.component.html',
  styleUrls: ['./process-totals.component.scss'],
})
export class ProcessTotalsComponent implements OnInit {
  @Input() processTotals: ProcessTotal[];
  @Input() class: string;
  @Input() reqId: string;
  @Input() reqStatus: string;
  @Output() processTotalClick = new EventEmitter<ProcessTotal>();
  constructor(
    public dialog: MatDialog,
    public router: Router
  ) {}

  ngOnInit() {}

  onTotalClick(total: ProcessTotal): void {
    this.processTotalClick.emit(total);
    console.log(total);
    this.reqStatus = this.reqStatus == '' ? 'all' : this.reqStatus;
    if (!this.reqId) this.router.navigateByUrl(`/contacts?stage=${total.label}&reqStatus=${this.reqStatus}`);
    console.log(this.processTotals);
    // Commented out per issue MMW-880
    /*let dialogData;

        switch (total.label) {
            case 'prospects':
                dialogData = {
                    tableClass: 'prospects-table',
                    tableColumns: ['name', 'nmls', 'score', 'source', 'requisition'],
                    ...total
                };
                break;
            case 'candidates':
                dialogData = {
                    tableClass: 'candidates-table',
                    tableColumns: ['name', 'nmls', 'score', 'source', 'requisition'],
                    ...total
                };
                break;
            case 'meetings':
                dialogData = {
                    tableClass: 'meetings-table',
                    tableColumns: ['name', 'nmls', 'score', 'source', 'requisition'],
                    ...total
                };
                break;
            case 'offers':
                dialogData = {
                    tableClass: 'offers-table',
                    tableColumns: ['name', 'nmls', 'score', 'source', 'requisition'],
                    ...total
                };
                break;
            case 'hires':
                dialogData = {
                    tableClass: 'hires-table',
                    tableColumns: ['name', 'nmls', 'score', 'requisition', 'date-hired'],
                    ...total
                };
                break;
            case 'meetings scheduled':
                dialogData = {
                    tableClass: 'meetings-table',
                    tableColumns: ['name', 'nmls', 'score', 'source', 'requisition'],
                    ...total
                };
                break;
            case 'meetings completed':
                dialogData = {
                    tableClass: 'meetings-table',
                    tableColumns: ['name', 'nmls', 'score', 'source', 'requisition'],
                    ...total
                };
                break;
            case 'calls scheduled':
                dialogData = {
                    tableClass: 'calls-table',
                    tableColumns: ['name', 'nmls', 'score', 'source', 'requisition'],
                    ...total
                };
                break;
            case 'calls made':
                dialogData = {
                    tableClass: 'calls-table',
                    tableColumns: ['name', 'nmls', 'score', 'source', 'requisition'],
                    ...total
                };
                break;
            default:
                console.error(`Total clicked, but cannot display dialog. Process total type does not exist or is undefined.`);
                break;
        }

        const dialogRef = this.dialog.open(ProcessTotalsDialogComponent, {
            panelClass: 'process-totals-dialog',
            width: '80vw',
            data: dialogData
        });*/
  }
}
