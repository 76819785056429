<div
  style="z-index: 150"
  class="fixed relative z-50 bottom-12 right-14 bg-blue-600 shadow-lg cursor-pointer rounded-full w-20 h-20 flex items-center justify-center"
>
  <div
    (click)="maximizeMessages()"
    class="hover_scale-105 active_scale-95 transition-transform transform duration-150 ease-in-out cursor-pointer"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-8 w-8 text-white"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
      />
    </svg>
  </div>
  <div
    appDnd
    (fireFileOver)="onFireFileOver($event)"
    (fileDropped)="onFileDropped($event)"
    [ngClass]="maximize ? '' : 'hidden'"
    class="h-[600px] w-[450px] flex flex-col bg-white border-2 border-gray-800 shadow-lg rounded-md absolute right-0"
    style="top: -610px; z-index: 150"
  >
    <div class="w-full p-2 flex justify-between">
      <div>
        <div class="flex space-x-2 py-2 items-baseline">
          <div class="h-8 w-8 bg-green-600 text-white flex items-center justify-center rounded-full font-semibold">
            TH
          </div>
          <p class="font-semibold">{{ moObj?.contact.firstName + ' ' + moObj?.contact.lastName }}</p>
        </div>
        <div class="flex text-sm">
          <span class="font-semibold pr-2">To: </span>
          <span>{{ moObj?.contactNumber | phone }}</span>
        </div>
        <div class="flex text-sm">
          <span class="font-semibold pr-2">From: </span>
          <span>{{ assignedNumber?.friendlyName }}</span>
        </div>
      </div>
      <div class="flex items-baseline space-x-3 place-self-start">
        <div
          (click)="maximizeMessages()"
          class="flex items-center justify-center p-1 rounded-md hover_bg-gray-300 cursor-pointer"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
          </svg>
        </div>
        <div
          (click)="closeQuickSMS()"
          class="flex items-center justify-center p-1 rounded-md hover_bg-gray-300 cursor-pointer"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      </div>
    </div>
    <div #scrollframe class="flex-grow w-full overflow-scroll">
      <div
        class="w-full h-full flex items-center justify-center"
        *ngIf="isLoadingOlderMessages && !messageHistory.length"
      >
        <svg
          class="animate-spin h-6 w-6 text-gray-800"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
      <div *ngIf="!isLoadingOlderMessages || messageHistory.length" class="flex flex-col space-y-8 w-full relative">
        <div #item *ngFor="let message of messageHistory; let i = index">
          <div *ngIf="message.displayTimeDate" class="w-full flex items-center justify-center relative text-sm py-4">
            <div class="bg-white px-8" style="z-index: 50">
              {{ message.displayTimeDate | date: 'medium' }}
            </div>
            <div class="w-11/12 border-t border-gray-800 z-10 bg-gray-800 absolute"></div>
          </div>
          <div [ngClass]="message.mType === 2 ? 'justify-start' : 'justify-end'" class="flex w-full px-2">
            <div class="w-2/3 flex" [ngClass]="message.mType === 2 ? 'justify-start' : 'justify-end'">
              <div
                *ngIf="message.message"
                class="p-2 rounded-md tri-right relative break-all"
                [ngClass]="message.mType === 2 ? 'btm-left-in bg-gray-300' : 'btm-right-in bg-blue-600 text-white'"
                [innerHTML]="twemojify(message.message)"
              ></div>
            </div>
          </div>
          <div
            *ngFor="let mmsMedia of message.mediaUrl"
            [ngClass]="message.mType === 2 ? 'justify-start' : 'justify-end'"
            class="flex w-full px-2 space-y-8 pt-8"
          >
            <div class="w-2/3 flex" [ngClass]="message.mType === 2 ? 'justify-start' : 'justify-end'">
              <div
                class="p-2 rounded-md tri-right relative break-all"
                [ngClass]="message.mType === 2 ? 'btm-left-in bg-gray-300' : 'btm-right-in bg-blue-600 text-white'"
              >
                <img
                  *ngIf="
                    ['image/jpeg', 'image/jpg', 'image/gif', 'image/tiff', 'image/webp', 'image/png'].indexOf(
                      mmsMedia.fileType.toLowerCase()
                    ) >= 0
                  "
                  src="{{ mmsMedia.thumbUrl }}"
                  (onerror)="this.src = 'mmsMedia.url'"
                  alt="..."
                  class="object-cover h-16 w-16 p-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full space-x-2 px-4 pb-4 relative bg-white pt-2 items-center">
      <div
        (click)="showEmojiPicker = !showEmojiPicker"
        class="text-3xl transform hover_scale-105 active_95 transition-transform duration-150 ease-in-out cursor-pointer"
        [innerHTML]="twemojify('😃')"
      ></div>
      <emoji-mart
        style="top: -400px; right: 500px"
        class="emoji-mart absolute z-50"
        set="{{ set }}"
        *ngIf="showEmojiPicker"
        (emojiSelect)="addEmoji($event)"
        title="Pick your emoji…"
      ></emoji-mart>
      <div
        class="flex-grow flex p-2 ring-2 rounded-md focus-within_outline-none focus-within_ring-blue-600 ring-gray-800 transition-colors duration-150"
      >
        <div *ngFor="let mmsMedia of mmsMediaToSend; let mmsIndex = index" class="relative">
          <div
            (click)="removeMedia(mmsMedia.id)"
            class="bg-gray-800 h-4 w-4 cursor-pointer rounded-full top-1 right-1 absolute flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-3 w-3 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
          <img
            *ngIf="
              ['image/jpeg', 'image/jpg', 'image/gif', 'image/tiff', 'image/webp', 'image/png'].indexOf(
                mmsMedia.fileType.toLowerCase()
              ) >= 0
            "
            src="{{ mmsMedia.thumbUrl }}"
            (onerror)="this.src = 'mmsMedia.url'"
            alt="..."
            class="object-cover h-16 w-16 p-2"
          />
        </div>
        <textarea
          class="flex-grow resize-none focus_outline-none"
          (keyup)="autoGrow()"
          (change)="updateMessage($event)"
          #textArea
          rows="1"
          [disabled]="disabled"
          placeholder="Enter your message here..."
          [value]="messageToSend"
        ></textarea>
      </div>
      <div class="flex self-end">
        <div
          (click)="sendSMS()"
          class="rounded-md bg-blue-600 text-white flex px-4 py-2 font-semibold items-center cursor-pointer"
        >
          <div *ngIf="!sending" class="space-x-2 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-white"
              fill="currentColor"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              focusable="false"
              width="1em"
              height="1em"
              style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg)"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 512 512"
            >
              <path
                d="M440 6.5L24 246.4c-34.4 19.9-31.1 70.8 5.7 85.9L144 379.6V464c0 46.4 59.2 65.5 86.6 28.6l43.8-59.1l111.9 46.2c5.9 2.4 12.1 3.6 18.3 3.6c8.2 0 16.3-2.1 23.6-6.2c12.8-7.2 21.6-20 23.9-34.5l59.4-387.2c6.1-40.1-36.9-68.8-71.5-48.9zM192 464v-64.6l36.6 15.1L192 464zm212.6-28.7l-153.8-63.5L391 169.5c10.7-15.5-9.5-33.5-23.7-21.2L155.8 332.6L48 288L464 48l-59.4 387.3z"
              />
            </svg>
            <span>Send</span>
          </div>
          <svg
            *ngIf="sending"
            class="animate-spin h-6 w-6 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
