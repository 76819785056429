import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';

export interface ConfirmationDialogButton {
  label: string;
  color?: ThemePalette;
}

export interface ConfirmationDialogConfig {
  title?: string;
  message?: string;
  confirmButtons?: ConfirmationDialogButton[];
  cancelButton?: ConfirmationDialogButton;
}

@Component({
  selector: 'mm-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  data: ConfirmationDialogConfig;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: ConfirmationDialogConfig
  ) {
    this.data = data;
  }

  close(confirmButton: ConfirmationDialogButton) {
    this.dialogRef.close(confirmButton.label);
  }
}
