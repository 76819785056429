export const updatedLiveCounter = `
subscription UpdatedLiveCounter {
  updatedLiveCounter
}`;

export const updatedMessageLog = `
subscription UpdatedMessageLog ($userId: String!, $contactNumber: String, $agentNumber: String) {
  updatedMessageLog (userId: $userId, contactNumber: $contactNumber, agentNumber: $agentNumber)
  {
    userId
    contactNumber
    agentNumber
    data
  }
}`;

export const updatedAgentMessageLog = `
subscription updatedAgentMessageLog ($userId: String!, $agentNumber: String) {
  updatedAgentMessageLog (userId: $userId, agentNumber: $agentNumber)
  {
    userId
    contactNumber
    agentNumber
    data
  }
}`;

export const callForwardingNumber = `
subscription callForwardingNumber($smsNumber: String!, $userNumber: String!) {
  callForwardingNumber(smsNumber: $smsNumber, userNumber: $userNumber)
}`;

export const updatedUsernameLogIn = `
subscription updatedUsernameLogIn($userName: String!) {
  updatedUsernameLogIn (userName: $userName)
  {
    userName
    data
  }
}`;

export const updatedUserDeviceLogIn = `
subscription updatedUserDeviceLogIn($deviceKey: String!) {
  updatedUserDeviceLogIn (deviceKey: $deviceKey)
  {
    deviceKey
    data
  }
}`;

export const notifyStepQueueMessageProcessed = `
subscription notifyStepQueueMessageProcessed ($userId: String!) {
  notifyStepQueueMessageProcessed (userId: $userId)
  {
    userId
    message
    data
  }
}`;

export const getPermissionsSubscription = `
  subscription getPermissionsSubscription ($tenantId: String!) {
    getPermissionsSubscription (tenantId: $tenantId) {
      tenantId
    }
}`;

export const getTailLogsSubscription = `
  subscription getTailLogsSubscription ($tenantId: String!, $userId: String!) {
    getTailLogsSubscription (tenantId: $tenantId, userId: $userId) {
      tenantId
      userId
      data
    }
}`;
