import { OnInit, SimpleChange, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { ClickOutsideDirectiveModule } from './../market-insight/map/clickOutside.directive';
import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { PopperModule } from 'app/market-insight/map/components/tooltip.directive';
/**
 * Modelmatch Popover Component
 * a wrapper around the appPopper
 * directive. Accepts are target and
 * trigger slot to show inside of a
 * popover. Alos accepts the following:
 * hasArrow: default true - should there be an arrow
 * color: default dark - else white
 * placement: default 'auto' popperjs placement
 * show: default false - boolean value used with showProgramtically
 * showProgramatically: defalut false, meaning that
 * the popover is shown on hover. else true means
 * it is controlled by show, not click
 */
@Component({
  selector: 'mm-popover',
  template: `
    <div
      (clickOutsite)="show = false"
      [show]="show"
      [showProgrammatically]="showProgrammatically"
      [appPopper]
      [offset]="offset"
      [target]="target"
      [arrow]="arrow"
      [placement]="placement"
      class="relative"
    >
      <div>
        <ng-content select="[trigger]" class="relative z-10"></ng-content>
      </div>
      <div #target class="popper group">
        <div
          [ngClass]="color === 'white' ? 'bg-white text-slate-800' : 'bg-slate-800 text-white'"
          class="relative rounded-md p-1 border border-slate-200"
        >
          <div class="relative z-50 w-full flex">
            <ng-content select="[target]"></ng-content>
          </div>
        </div>
        <span #arrow [ngClass]="arrowClassName"></span>
      </div>
    </div>
  `,
  styleUrls: ['../nick_styles/nick.css'],
})
export class PopoverComponent implements OnInit {
  @Input() hasArrow: boolean = true;
  @Input() color: 'dark' | 'white' = 'dark';
  @Input() placement: string = 'auto';
  @Input() offset: string;
  @Input() show: boolean = false;
  @Input() showProgrammatically: boolean = false;
  @ViewChild('target') target: ElementRef;
  public arrowClassName: string;

  ngOnInit() {
    this.arrowClassName = this.hasArrow
      ? `
      ${this.color === 'white' ? 'bg-white' : 'bg-slate-800'} popper__arrow 
      w-3 h-3 z-10
      popper-top_-bottom-1.5 
      popper-top_border-r 
      popper-top_border-b 
      popper-top_rounded-br
      popper-bottom_-top-1.5 
      popper-bottom_border-l 
      popper-bottom_border-t 
      popper-bottom_rounded-tl
      popper-bottom_ml-3
      popper-left_border-r
      popper-left_border-t
      popper-left_rounded-tr 
      popper-left_-right-1.5 
      popper-right_-left-1.5
      popper-right_border-l 
      popper-right_border-b
      popper-right_rounded-bl
      border-slate-200 
    `
      : '';
  }
}

@NgModule({
  declarations: [PopoverComponent],
  exports: [PopoverComponent],
  imports: [CommonModule, PopperModule, ClickOutsideDirectiveModule],
})
export class PopoverModule {}
