import { Directive } from '@angular/core';

export type ModelMatchColors = 'blue' | 'red' | 'indigo' | 'white';

export type ModelMatchSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
export type ModelMatchRatios = 'wide' | 'square' | 'wide-start' | false;
export type ModelMatchStates = 'loading' | 'disabled' | 'active';
export type ModelMatchAnimations = 'hover-scale' | 'active-scale' | 'scale' | 'hover-translate-y' | false;

export type ModelMatchButtonOptions = {
  size?: ModelMatchSizes;
  width?: number;
  height?: number;
  ratio?: ModelMatchRatios;
  color?: ModelMatchColors;
  animation?: ModelMatchAnimations;
  shadow?: boolean;
  border?: boolean;
  state?: ModelMatchStates;
  style?: 'primary' | 'secondary' | 'link';
  fontColor?: 'white' | 'dark' | 'blue' | 'indigo' | 'red';
  highlight?: boolean;
  cursor?: string;
};

@Directive()
export abstract class ModelMatchComponentFactory {
  #options: ModelMatchButtonOptions;
  constructor() {}

  /**
   * Cursor is always pointer unless state
   * is disabled or loading. If state is
   * loading, it is wait and if it is disabled
   * and not loading it is not-allowed.
   */
  get #cursor() {
    if (this.#options.state === 'loading') {
      return `disabled_cursor-wait ${this.#options.cursor}`;
    } else {
      return `disabled_cursor-not-allowed ${this.#options.cursor}`;
    }
  }

  /**
   * Color pattern is to as following:
   * Primary: 500/400 hover/text white
   * Secondary: 50/100 hover/text 500
   * Link: 0/50 hover/text 500
   * Disabled state takes bg-opacity or
   * text-opacity to 0.5. Colors are:
   * blue, red (danger), indigo, white.
   */
  get #colors() {
    switch (this.#options.color) {
      case 'blue':
        switch (this.#options.style) {
          case 'primary':
            return `
        shadow
        border
        border-transparent
        hover_bg-blue-400 
        ${this.#options.highlight ? 'bg-blue-400' : ''}
        bg-blue-500 
        text-white
        disabled_hover_bg-blue-500 
        disabled_hover_bg-opacity-50
        disabled_bg-opacity-50
      `;
          case 'secondary':
            return `
          shadow
          border
          border-transparent
          hover_bg-blue-100 
          bg-blue-50 
          ${this.#options.highlight ? 'bg-blue-100' : ''}
          text-blue-500
          disabled_hover_bg-blue-100
          disabled_hover_text-opacity-50
          disabled_text-opacity-50
        `;
          case 'link':
            return `
          border
          border-transparent
          hover_bg-blue-50 
          ${this.#options.highlight ? 'bg-blue-50' : ''}
          text-blue-500
          disabled_hover_bg-transparent
          disabled_hover_text-opacity-50
          disabled_text-opacity-50
        `;
          default:
            return `
          shadow
          border
          border-transparent
          hover_bg-blue-400 
          bg-blue-500 
          ${this.#options.highlight ? 'bg-blue-400' : ''}
          text-white
          disabled_hover_bg-blue-500 
          disabled_hover_bg-opacity-50
          disabled_bg-opacity-50
        `;
        }
      case 'indigo':
        switch (this.#options.style) {
          case 'primary':
            return `
          shadow
          border
          border-transparent
          hover_bg-indigo-400 
          bg-indigo-500 
          ${this.#options.highlight ? 'bg-indigo-500' : ''}
          text-white
          disabled_hover_bg-indigo-500 
          disabled_hover_bg-opacity-50
          disabled_bg-opacity-50
        `;
          case 'secondary':
            return `
          shadow
          border
          border-transparent
          hover_bg-indigo-100 
          bg-indigo-50 
          ${this.#options.highlight ? 'bg-indigo-50' : ''}
          text-indigo-500
          disabled_hover_bg-indigo-100
          disabled_hover_text-opacity-50
          disabled_text-opacity-50
        `;
          case 'link':
            return `
          border
          border-transparent
          hover_bg-indigo-50 
          ${this.#options.highlight ? 'bg-indigo-50' : ''}
          text-indigo-500
          disabled_hover_bg-transparent
          disabled_hover_text-opacity-50
          disabled_text-opacity-50
        `;
          default:
            return `
          shadow
          border
          border-transparent
          hover_bg-indigo-400 
          ${this.#options.highlight ? 'bg-indigo-400' : ''}
          bg-indigo-500 
          text-white
          disabled_hover_bg-indigo-500 
          disabled_hover_bg-opacity-50
          disabled_bg-opacity-50
        `;
        }
      case 'red':
        switch (this.#options.style) {
          case 'primary':
            return `
          shadow
          border
          border-transparent
          hover_bg-red-400 
          bg-red-500 
          ${this.#options.highlight ? 'bg-red-500' : ''}
          text-white
          disabled_hover_bg-red-500 
          disabled_hover_bg-opacity-50
          disabled_bg-opacity-50
        `;
          case 'secondary':
            return `
          shadow
          border
          border-transparent
          hover_bg-red-100 
          ${this.#options.highlight ? 'bg-red-100' : ''}
          bg-red-50 
          text-red-500
          disabled_hover_bg-red-100
          disabled_hover_text-opacity-50
          disabled_text-opacity-50
        `;
          case 'link':
            return `
          border
          border-transparent
          hover_bg-red-50 
          ${this.#options.highlight ? 'bg-red-50' : ''}
          text-red-500
          disabled_hover_bg-transparent
          disabled_hover_text-opacity-50
          disabled_text-opacity-50
        `;
          default:
            return `
          shadow
          border
          border-transparent
          hover_bg-red-400 
          ${this.#options.highlight ? 'bg-red-400' : ''}
          bg-red-500 
          text-white
          disabled_hover_bg-indigo-500 
          disabled_hover_bg-opacity-50
          disabled_bg-opacity-50
        `;
        }
      case 'white':
        switch (this.#options.style) {
          case 'primary':
            return `
          shadow
          border 
          border-slate-200 
          hover_bg-slate-100 
          ${this.#options.highlight ? 'bg-slate-50' : ''}
          bg-white 
          text-slate-600 
          disabled_hover_bg-transparent
          disabled_bg-opacity-50
        `;
          case 'link':
            return `
          border
          border-transparent
          hover_bg-slate-50 
          ${this.#options.highlight ? 'bg-slate-50' : ''}
          text-slate-600
          disabled_hover_bg-transparent
          disabled_hover_text-opacity-50
          disabled_text-opacity-50
        `;
          default:
            return `
          shadow
          border 
          border-slate-200 
          hover_bg-slate-100 
          ${this.#options.highlight ? 'bg-slate-100' : ''}
          bg-white 
          text-slate-600 
          disabled_hover_bg-slate-100 
          disabled_hover_bg-slate-100
          disabled_bg-opacity-50
        `;
        }
    }
  }

  /**
   * Padding by default is 2x wide and 1x high.
   * Ration square is equal padding
   * Width is always full by flex to force the button to
   * take up the full width of it's parent. This takes
   * away the difficulty of nesting buttons in groups.
   */
  get #padding() {
    if (!this.#options.ratio) {
      return '';
    }

    let width;

    switch (this.#options.size) {
      case 'xs':
        width = 'w-4 h-4';
        break;
      case 'sm':
        width = 'w-6 h-6';
        break;
      case 'md':
        width = 'w-8 h-8';
        break;
      case 'lg':
        width = 'w-10 h-10';
        break;
      case 'xl':
        width = 'w-12 h-12';
        break;
    }

    if (this.#options.ratio === 'square') {
      return `flex-grow ${width} aspect-square flex items-center justify-center`;
    }
    if (this.#options.ratio === 'wide-start') {
      return 'flex-grow px-4 py-1.5 flex items-center justify-start';
    }
    return 'flex-grow px-4 py-1.5 flex items-center justify-center';
  }

  /**
   * Size refers to text size and font weight
   */
  get #size() {
    if (!this.#options.size) return '';

    switch (this.#options.size) {
      case 'xs':
        return 'text-xs';
      case 'sm':
        return 'text-sm font-semibold';
      case 'md':
        return 'text-base font-semibold';
      case 'lg':
        return 'text-lg font-bold';
      case 'xl':
        return 'text-xl font-bold';
      default:
        return 'text-base font-semibold';
    }
  }

  // get #border() {
  //   if (!this.#options.border) return '';

  //   let radius = 'rounded';
  //   switch(this.#options.color) {
  //   case 'primary':
  //     return `
  //       ${radius} focus_ring focus_ring-offset-2 focus_ring-blue-600
  //     `;
  //   case 'blue':
  //     return `
  //     ${radius} focus_ring focus_ring-offset-2 focus_ring-blue-600
  //     `;
  //   case 'success':
  //     return `
  //     ${radius} focus_ring focus_ring-offset-2 focus_ring-green-600
  //     `;
  //   case 'green':
  //     return `
  //     ${radius} focus_ring focus_ring-offset-2 focus_ring-green-600
  //     `;
  //   case 'error':
  //     return `
  //     ${radius} focus_ring focus_ring-offset-2 focus_ring-red-600
  //     `;
  //   case 'red':
  //     return `
  //     ${radius} focus_ring focus_ring-offset-2 focus_ring-red-600
  //     `;
  //   case 'warning':
  //     return `
  //     ${radius} focus_ring focus_ring-offset-2 focus_ring-yellow-600
  //     `;
  //   case 'yellow':
  //     return `
  //     ${radius} focsu_ring focus_ring-offset-2 focus_ring-yellow-600
  //     `;
  //   case 'secondary':
  //     return `
  //     ${radius} focus_ring focus_ring-offset-2 focus_ring-indigo-600
  //     `;
  //   case 'indigo':
  //     return `
  //     ${radius} focus_ring focus_ring-offset-2 focus_ring-indigo-600
  //     `;
  //   case 'black':
  //     return `
  //     ${radius} focus_ring focus_ring-offset-2 focus_ring-slate-800
  //     `;
  //   case 'white':
  //     return `
  //     ${radius} focus_ring focus_ring-offset-2 focus_ring-slate-200
  //       border border-slate-200
  //     `;
  //   default:
  //     return `
  //     ${radius} focus_ring focus_ring-offset-2 focus_ring-blue-600
  //     `;
  //   }
  // }

  /**
   * Animation firstly takes into account
   * the users preference for reduced motion.
   * After that there are two options for active
   * buttons. By default it is active-scale. So
   * scale down on active. The other option is hover-scale
   * which does a scale up on hover. You can also do both.
   * You can also do hover-translate-y
   */
  get #animation() {
    if (!this.#options.animation || this.#options.state === 'disabled' || this.#options.state === 'loading') return '';
    let animationString = 'motion-reduce_transition-none duration-150 ease-in-out transition-transform transform ';

    if (this.#options.animation === 'active-scale') {
      animationString += ` 
      active_scale-95
      motion-reduce_active_scale-100
    `;
    } else if (this.#options.animation === 'hover-scale') {
      animationString += `
      hover_scale-105
      motion-reduce_hover_scale-100
    `;
    } else if (this.#options.animation === 'scale') {
      animationString += `
      active_scale-95
      motion-reduce_active_scale-100
      hover_scale-105
      motion-reduce_hover_scale-100
    `;
    } else if (this.#options.animation === 'hover-translate-y') {
      animationString += `
      hover_translate-y-1
      motion-reduce_hover_translate-y-0
    `;
    }

    return animationString;
  }

  generateClassName(options: ModelMatchButtonOptions) {
    this.#options = options;
    return `
      rounded w-full
      ${this.#cursor}
      ${this.#colors}
      ${this.#padding}
      ${this.#size}
      ${this.#animation}
    `;
  }
}
