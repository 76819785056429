import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface Column {
  colName: string;
  value: string;
  isDisplayed: boolean;
  filterKey: string;
}

@Component({
  selector: 'mm-display-columns-dialog',
  templateUrl: './display-columns-dialog.component.html',
})
export class DisplayColumnsDialogComponent implements OnInit {
  columns: Column[] = [];
  constructor(
    public dialogRef: MatDialogRef<DisplayColumnsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.columns = this.data.columns;
  }

  toggleColumnSelection(column: Column) {
    column.isDisplayed = !column.isDisplayed;
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  onApplyClick() {
    const result = this.columns;
    this.dialogRef.close(result);
  }
}
