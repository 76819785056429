import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TenantService } from '../../../services/tenant.service';
import { UserService } from '../../../services/user.service';
import { map, startWith, take } from 'rxjs/operators';
import { Tenant } from '../../../shared/model/tenant';
import { User } from '../../../shared/model/user';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';

export interface UsersPickerDialogButton {
  label: string;
  color?: ThemePalette;
}

interface UsersObjMenuItem {
  title: string;
  desc: string;
  rType: string;
}

interface UsersObjMenuItem {
  title: string;
  desc: string;
  rType: string;
  cols: number;
  rows: number;
  ribbon?: string;
  icon?: string;
  data?: any;
}

@Component({
  selector: 'mm-users-picker-dialog',
  templateUrl: './users-picker-dialog.component.html',
  styleUrls: ['./users-picker-dialog.component.scss'],
})
export class UsersPickerDialogComponent {
  dataLoaded: boolean = false;
  menuItems: UsersObjMenuItem[] = [];
  tenants: Tenant[];
  userTenant: Tenant;
  selectedUsers: User[] = [];
  filteredTenants: Observable<Tenant[]>;
  tenantFormControl = new FormControl();
  authenticatedUserInfo: any = null;
  usersPickerForm: FormGroup;
  currentUserInfo: any;
  tenant: Tenant = null;
  filteredUsers: Observable<User[]>;
  searchUsersControl = new FormControl();
  allSelected: boolean = false;
  reqFiltersUsersGlobal: User[] = [];
  @ViewChild('searchUsersInput') searchUsersInput: ElementRef<HTMLInputElement>;
  @ViewChild('userSelect', { static: false }) private userSelect: MatSelectionList;

  constructor(
    private dialogRef: MatDialogRef<UsersPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private tenantService: TenantService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    // Preload from parent for quicker UX
    this.tenants = this.data.tenants;

    if (this.data.reqFiltersUsersGlobal) this.reqFiltersUsersGlobal = this.data.reqFiltersUsersGlobal;

    this.getAuthenticatedUserInfo();

    this.usersPickerForm = this.formBuilder.group({
      tenant: this.tenantFormControl,
    });

    this.filteredUsers = this.searchUsersControl.valueChanges.pipe(
      startWith<string | User>(''),
      map((value) => {
        // console.log("filteredUsers map value ", value);
        return value;
      }),
      // If name is "" or undefined - then return shallow copy of entire array
      map((name: string) => {
        let retVal = name ? this._filterUsers(name) : this.tenant.user.slice();
        // console.log("retVal map value ", retVal);
        return retVal;
      })
    );
  }

  clearAll() {
    let data = { UsersObjMenuItem: null, clear: true };

    this.dialogRef.close({ data: data });
  }

  onClickAdd(UsersObjMenuItem: UsersObjMenuItem) {
    // Pass back new user to assign todos data
    let data = { UsersObjMenuItem: UsersObjMenuItem };

    this.dialogRef.close({ data: data });
  }

  onCloseClick() {
    // Pass back new user to assign todos data
    let data = { UsersObjMenuItem: null };

    this.dialogRef.close({ data: data });
  }

  showFeatured() {}

  showAll() {}

  private getAuthenticatedUserInfo(): void {
    this.userService
      .getAuthenticatedUserInfo()
      .pipe(take(1))
      .subscribe(
        (userInfo: any) => {
          this.authenticatedUserInfo = userInfo;
          this.currentUserInfo = userInfo;

          console.log('UsersPickerDialogComponent: authenticatedUserInfo ', userInfo);

          // now that we have the authenticatedUserInfo and know the user's group, make request for his tenants he can access

          this.filteredTenants = this.tenantFormControl.valueChanges.pipe(
            startWith<string | Tenant>(''),
            map((value) => (typeof value === 'string' ? value : value.title)),
            map((name) => (name ? this._filterTenant(this.tenants, name) : this.tenants))
          );

          console.log('this.filteredTenants', this.filteredTenants);
          // if (this.reqId) {
          //   this.addReqUsersToAllAssignees();
          // }
          // this.assignCurrentUser();

          let tenantId = this.authenticatedUserInfo.attributes['custom:tenantId'];

          this.userTenant = this.tenants.find((tenant) => {
            return tenant.id === tenantId;
          });

          // Load default tenant users
          this.tenantSelectionChanged(this.userTenant.id);
        },
        (error) => {
          console.log('UsersPickerDialogComponent: Error getting authenticated user groups -> ' + error.message);
        }
      );
  }

  private _filterTenant(data: any, name: string): Tenant[] {
    const filterValue = name.toLowerCase();
    return data.filter((tenant) => tenant.title.toLowerCase().includes(filterValue));
  }

  verifyTenantSelected() {
    //verify selection is a valid selection from list
    if (!this.tenants.includes(this.usersPickerForm.value.tenant)) {
      this.usersPickerForm.controls['tenant'].setValue('');
    }
  }

  tenantSelected(event) {
    if (this.currentUserInfo.groups.includes('mmAdmin') || this.currentUserInfo.groups.includes('mmUser')) {
      let mmTenant = this.tenants.filter((t) => t.id == this.currentUserInfo.attributes['custom:tenantId']);
      let mmInternalUsers = [];
      if (mmTenant.length > 0) {
        if (event.option.value.id != mmTenant[0].id) {
          mmInternalUsers = mmTenant[0].user;
        }
      }

      // this.allAssignees = [].concat(mmInternalUsers, event.option.value.user);
    } else {
      // this.allAssignees = event.option.value.user;
    }

    // this.allAssignees.sort((userA, userB) => userA.firstName.localeCompare(userB.firstName));
    // console.log("AddTodoComponent: allAssignees data ", this.allAssignees);

    //new tenant selected so clear out selections

    this.tenantSelectionChanged(event.option.value.id);
  }

  tenantSelectionChanged(selectTenantId) {
    this.dataLoaded = false;

    this.tenantService
      .getTenantWithUsers({ tenantId: selectTenantId })
      .pipe(
        take(1)
        // map(({ data }) => data.getTenant.user.toggleActive = )
      )
      .subscribe(
        (result) => {
          this.tenant = result.data.getTenant;
          // Let UI know data loading is complete
          this.dataLoaded = true;

          this.tenant.user.sort(this.compareUsernames);

          console.log('this.tenant.user', this.tenant.user);

          this.reqFiltersUsersGlobal.forEach((eachUser) => {
            // console.log("getReqFiltersUsers getReqUsers results", eachUser);
            // console.log("getReqFiltersUsers userRecDocs.findIndex(ele => ele.id === eachUser.id)", userRecDocs.findIndex(ele => ele.id === eachUser.id));

            if (this.tenant.user.findIndex((ele) => ele.id === eachUser.id) === -1) {
              eachUser['altTenant'] = true;
              this.tenant.user.push(eachUser);
            }
          });

          // Wait for tenant users to be loaded to set previous selections
          this.selectedUsers = this.tenant.user.filter((user) => this.data.selectedUserIds.includes(user.id));
        },
        (error) => {
          console.log('TenantStageSelectComponent: There was an error loading tenants ', error);

          // Even though error still let ui data is finished loading
          this.dataLoaded = true;
        }
      );
  }

  compareUsernames(a, b) {
    // Use toUpperCase() to ignore character casing
    const usernameA = a.username.toUpperCase();
    const usernameB = b.username.toUpperCase();

    let comparison = 0;
    if (usernameA > usernameB) {
      comparison = 1;
    } else if (usernameA < usernameB) {
      comparison = -1;
    }
    return comparison;
  }

  // Method to filter selectedUsers list based on what has been typed so far in search box
  private _filterUsers(name: string): User[] {
    const filterValue = name.toLowerCase();

    // console.log("_filterUsers", name);
    // console.log(
    //   "_filterUsers returns ",
    //   this.tenant.user.filter((currUser) => currUser.username.toLowerCase().indexOf(filterValue) === 0)
    // );

    return this.tenant.user.filter(
      (currUser) =>
        currUser.username.toLowerCase().indexOf(filterValue) === 0 ||
        currUser.firstName.toLowerCase().indexOf(filterValue) === 0 ||
        currUser.lastName.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayTenant(tenant?: Tenant): string | undefined {
    return tenant ? tenant.title : undefined;
  }

  onFilterSelected() {
    // Pass back new user to assign todos data
    let data = { selectedUsers: this.selectedUsers };

    this.dialogRef.close({ data: data });
  }

  selectAll(event: any) {
    if (this.allSelected) {
      this.userSelect.deselectAll();
      this.allSelected = false;
    } else {
      this.userSelect.selectAll();
      this.allSelected = true;
    }
  }

  onSelection(event: MatSelectionListChange) {
    if (!event.option.selected) {
      this.allSelected = false;
    }
  }

  someChecked() {
    if (this.selectedUsers.length === 0) {
      return false;
    }

    return this.selectedUsers.length > 0 && !this.allSelected;
  }

  ngOnDestroy() {}
}
