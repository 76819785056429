import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, from, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { loadStripe, Stripe, StripeElements, PaymentIntent, ConfirmCardPaymentData } from '@stripe/stripe-js';

import { environment } from '../../environments/environment';
import {
  AddBankAccount,
  AddPaymentMethod,
  CancelStripePaymentIntent,
  CheckTenantStripeSubStatus,
  DeletePaymentMethod,
  GetPlaidLinkToken,
  GetProratedSubscriptionChange,
  GetUnpaidInvoiceUrl,
  GetUpdatedTenantStripeSub,
  UpdateSubscriptionInterval,
} from 'app/graphql/types';
import {
  addTenantStripeSubDoc,
  cancelStripePaymentIntent,
  updateDefaultPaymentMethod,
  addPaymentMethod,
  deletePaymentMethod,
  getProratedSubscriptionChange,
  updateSubscriptionInterval,
  addBankAccount,
} from 'app/graphql/mutations';

import {
  checkTenantStripeSubStatus,
  getPlaidLinkToken,
  getUnpaidInvoiceUrl,
  getUpdatedTenantStripeSub,
  stripeTest,
} from 'app/graphql/queries';
import { Card, CardInput } from 'graphql.types';

@Injectable()
export class StripeService {
  isAuthenticated: Subject<boolean> = new Subject<boolean>();
  elements: StripeElements;
  private stripe: Stripe;

  constructor(private apollo: Apollo) {}

  static appInitializerFactory(stripeService: StripeService) {
    console.log('Stripe Service initialize!');
    return () => stripeService.initialize();
  }

  cancelPaymentIntent(id: string, cancellation_reason?: PaymentIntent.CancellationReason): Observable<PaymentIntent> {
    return this.apollo
      .mutate<CancelStripePaymentIntent, { id: string; cancellation_reason?: PaymentIntent.CancellationReason }>({
        mutation: gql(cancelStripePaymentIntent),
        variables: { id, cancellation_reason },
      })
      .pipe(map(({ data }) => data.cancelStripePaymentIntent));
  }

  confirmCardPayment(client_secret: string, params: ConfirmCardPaymentData) {
    return from(this.stripe.confirmCardPayment(client_secret, params));
  }

  private initialize() {
    loadStripe(environment.stripePublishableKey).then((stripe) => {
      this.stripe = stripe;
      this.elements = this.stripe.elements();
    });
  }

  testStripe(): Observable<any> {
    return this.apollo.query({
      query: gql(stripeTest),
      variables: {
        test: true,
      },
    });
  }

  getUpdatedTenantStripeSub(tenantId: string) {
    return this.apollo.watchQuery<GetUpdatedTenantStripeSub>({
      query: gql(getUpdatedTenantStripeSub),
      variables: { tenantId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  addTenantStripeSubDoc(tenantId: string, subscriptionId: string, tenantTitle?: string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(addTenantStripeSubDoc),
      variables: {
        tenantId,
        subscriptionId,
        tenantTitle,
      },
    });
  }

  updateDefaultPaymentMethod(tenantId: string, customerId: string, cardId: string) {
    return this.apollo.mutate<any>({
      mutation: gql(updateDefaultPaymentMethod),
      variables: {
        tenantId,
        customerId,
        cardId,
      },
    });
  }

  checkTenantStripeSubStatus(tenantId: string) {
    return this.apollo.watchQuery<CheckTenantStripeSubStatus>({
      query: gql(checkTenantStripeSubStatus),
      variables: { tenantId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  addPaymentMethod(card: CardInput, customerId: string, subscriptionId: string) {
    return this.apollo.mutate<AddPaymentMethod>({
      mutation: gql(addPaymentMethod),
      variables: {
        card,
        customerId,
        subscriptionId,
      },
    });
  }

  deletePaymentMethod(cardId: string, customerId: string, subscriptionId: string) {
    return this.apollo.mutate<DeletePaymentMethod>({
      mutation: gql(deletePaymentMethod),
      variables: {
        cardId,
        customerId,
        subscriptionId,
      },
    });
  }

  getProratedSubscriptionChange(subscriptionId: string) {
    return this.apollo.mutate<GetProratedSubscriptionChange>({
      mutation: gql(getProratedSubscriptionChange),
      variables: {
        subscriptionId,
      },
    });
  }

  updateSubscriptionInterval(subscriptionId: string, tenantId: string) {
    return this.apollo.mutate<UpdateSubscriptionInterval>({
      mutation: gql(updateSubscriptionInterval),
      variables: {
        subscriptionId,
        tenantId,
      },
    });
  }

  getPlaidLinkToken() {
    return this.apollo.watchQuery<GetPlaidLinkToken>({
      query: gql(getPlaidLinkToken),
      variables: {},
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  addBankAccount(publicToken: string, accountIds: [string], subscriptionId: string, customerId: string) {
    return this.apollo.mutate<AddBankAccount>({
      mutation: gql(addBankAccount),
      variables: {
        publicToken,
        accountIds,
        subscriptionId,
        customerId,
      },
    });
  }

  getUnpaidInvoiceUrl(subscriptionId: string) {
    return this.apollo.watchQuery<GetUnpaidInvoiceUrl>({
      query: gql(getUnpaidInvoiceUrl),
      variables: {
        subscriptionId,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
}
