import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TenantService } from '../../../services/tenant.service';
import { UserService } from '../../../services/user.service';
import { map, startWith, take } from 'rxjs/operators';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { RequisitionService } from '../../../services/requisition.service';
import { UserReq } from '../../../shared/model/requisitions';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
@Component({
  selector: 'mm-reqs-picker-dialog',
  templateUrl: './reqs-picker-dialog.component.html',
  styleUrls: ['./reqs-picker-dialog.component.scss'],
})
export class ReqsPickerDialogComponent {
  dataLoaded: boolean = false;
  reqs: UserReq[];
  filteredReqs: Observable<UserReq[]>;
  selectedReqs: UserReq[] = [];
  searchReqsControl = new FormControl();
  allSelected: boolean = false;
  reqTags: string[]
  selectedTag: string = 'All Pipelines'
  @ViewChild('searchReqsInput') searchReqsInput: ElementRef<HTMLInputElement>;
  @ViewChild('reqSelect', { static: false }) private reqSelect: MatSelectionList;

  constructor(
    private dialogRef: MatDialogRef<ReqsPickerDialogComponent>,
    //    @Inject(MAT_DIALOG_DATA) data: any,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private tenantService: TenantService,
    private formBuilder: FormBuilder,
    private reqService: RequisitionService
  ) {}

  ngOnInit() {
    this.dataLoaded = false;
    this.reqs = this.data.reqs;
    this.selectedReqs = this.reqs.filter((req) => this.data.selectedReqIds.includes(req.reqId));
    this.dataLoaded = true;

    this.filteredReqs = this.searchReqsControl.valueChanges.pipe(
      startWith<string | UserReq>(''),
      map((value) => {
        // console.log("filteredReqs map value ", value);
        return value;
      }),
      // If name is "" or undefined - then return shallow copy of entire array
      map((title: string) => {
        let retVal = title ? this._filterReqs(title) : this.reqs.slice();
        // console.log("retVal map value ", title, retVal, this.reqs);
        return retVal;
      })
    );
    this.reqTags = this.getAssignedReqTags(this.reqs)
  }

  reLoadReqs() {
    this.reqService
      .getMyReqs()
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.reqs = result.data.getMyReqs;
        },
        (error) => {
          console.log('PreLoadReqs: error loading reqs == ', error);
        }
      );
  }

  clearAll() {
    let data = { ReqsObjMenuItem: null, clear: true };

    this.dialogRef.close({ data: data });
  }

  onCloseClick() {
    // Pass back new user to assign todos data
    let data = { ReqsObjMenuItem: null };

    this.dialogRef.close({ data: data });
  }

  showFeatured() { }
  
  updateTypeFilter(selectedTag: string) {
  this.reqSelect.deselectAll();
  this.allSelected = false;
  this.selectedTag = selectedTag
  this.selectedReqs = this.reqs.filter(req => req.tags?.includes(selectedTag))
  }

  getAssignedReqTags(requisitions: UserReq[]) {
    const tags = []
    requisitions.forEach(req => {
      req.tags && tags.push(...req.tags)
    })
    
    tags.unshift('All Pipelines')

    return [...new Set(tags)]
  }

  showAll() {}

  // Method to filter users list based on what has been typed so far in search box
  private _filterReqs(title: string): UserReq[] {
    const filterValue = title.toLowerCase();

    let reqsBuff = JSON.parse(JSON.stringify(this.reqs));

    return reqsBuff.filter((currReq) => currReq.title.toLowerCase().indexOf(filterValue) >= 0);
  }

  onFilterSelected() {
    // Pass back list of reqs to calling component
    let retReqs = this.selectedReqs.filter((req) => req.reqId);

    let data = { reqs: retReqs };

    this.dialogRef.close({ data: data });
  }

  selectAll(event: any) {
    if (this.allSelected) {
      this.reqSelect.deselectAll();
      this.allSelected = false;
    } else {
      this.reqSelect.selectAll();
      this.allSelected = true;
    }
  }

  onSelection(event: MatSelectionListChange) {
    if (!event.option.selected) {
      this.allSelected = false;
    }
  }

  someChecked() {
    if (this.selectedReqs.length === 0) {
      return false;
    }

    return this.selectedReqs.length > 0 && !this.allSelected;
  }

  ngOnDestroy() {}
}
