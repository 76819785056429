import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'mm-create-task-picker',
  templateUrl: './create-task-picker.component.html',
  styleUrls: ['./create-task-picker.component.scss'],
})
export class CreateTaskPickerComponent implements OnInit {
  @Output() closeTaskPicker: EventEmitter<boolean> = new EventEmitter();
  @Output() openCreateTodo: EventEmitter<boolean> = new EventEmitter();

  contactId: string;
  reqId: string;
  routeId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.contactId = params['contactId'];
      this.reqId = params['reqId'];
    });
  }

  openTodo() {
    this.openCreateTodo.emit(true);
    this.closeTaskPicker.emit(false);
  }

  openMeeting() {
    if (this.reqId && this.contactId) {
      this.router.navigate(['contacts', this.contactId, this.reqId, 'meeting']);
    } else if (this.reqId) {
      this.router.navigate(['requisitions', this.reqId, 'meeting']);
    } else {
      this.router.navigate(['contacts', this.contactId, 'meeting']);
    }
  }
}
