<div mat-dialog-title>
  <button mat-icon-button class="button-close" (click)="onCloseClick()">
    <mat-icon>close</mat-icon>
  </button>

  <header class="dialog-header">
    <h3>Dashboard Widget</h3>
  </header>
</div>

<section>
  <mat-dialog-content class="container">
    <div class="flex-container" fxLayoutGap="1em" fxLayout="row" fxLayout.lt-lg="column">
      <div class="child-1" fxFlex="66%" fxFlex.gt-md="66%" fxFlexOrder="1" fxFlexOrder.lt-lg="1" style="flex-grow: 1">
        <h4>Widget Preview</h4>

        <mm-report-obj-two
          *ngIf="dialogData.objRef.rType === 'mm-report-obj-two'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="
            selectedUserIds?.length
              ? selectedUserIds
              : dialogData.objRef.userIds?.length
              ? dialogData.objRef.userIds
              : []
          "
          [tenants]="tenants"
          [reqs]="dialogData.objRef.reqs"
          [reqIds]="
            selectedReqIds?.length ? selectedReqIds : dialogData.objRef.reqIds?.length ? dialogData.objRef.reqIds : []
          "
          (objDataChanged)="objDataChanged($event, index)"
          [data]="dialogData.objRef.data"
          [esFilters]="dialogData.objRef.esFilters"
          (delete)="objDeleted($event, index)"
          (loaded)="objLoaded($event, index)"
          [reportingTheme]="dialogData.objRef.reportingTheme"
          [preview]="true"
          [triggerChange]="triggerChange"
        >
        </mm-report-obj-two>

        <mm-report-obj-three
          *ngIf="dialogData.objRef.rType === 'mm-report-obj-three'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="
            selectedUserIds?.length
              ? selectedUserIds
              : dialogData.objRef.userIds?.length
              ? dialogData.objRef.userIds
              : []
          "
          [tenants]="tenants"
          [reqs]="dialogData.objRef.reqs"
          [reqIds]="
            selectedReqIds?.length ? selectedReqIds : dialogData.objRef.reqIds?.length ? dialogData.objRef.reqIds : []
          "
          (objDataChanged)="objDataChanged($event, index)"
          [data]="dialogData.objRef.data"
          [esFilters]="dialogData.objRef.esFilters"
          (delete)="objDeleted($event, index)"
          (loaded)="objLoaded($event, index)"
          [reportingTheme]="dialogData.objRef.reportingTheme"
          [preview]="true"
          [triggerChange]="triggerChange"
        >
        </mm-report-obj-three>

        <mm-report-obj-four
          *ngIf="dialogData.objRef.rType === 'mm-report-obj-four'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="
            selectedUserIds?.length
              ? selectedUserIds
              : dialogData.objRef.userIds?.length
              ? dialogData.objRef.userIds
              : []
          "
          [tenants]="tenants"
          [reqs]="dialogData.objRef.reqs"
          [reqIds]="
            selectedReqIds?.length ? selectedReqIds : dialogData.objRef.reqIds?.length ? dialogData.objRef.reqIds : []
          "
          (objDataChanged)="objDataChanged($event, index)"
          [data]="dialogData.objRef.data"
          [esFilters]="dialogData.objRef.esFilters"
          (delete)="objDeleted($event, index)"
          (loaded)="objLoaded($event, index)"
          [reportingTheme]="dialogData.objRef.reportingTheme"
          [preview]="true"
          [triggerChange]="triggerChange"
        >
        </mm-report-obj-four>

        <mm-report-obj-five
          *ngIf="dialogData.objRef.rType === 'mm-report-obj-five'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="
            selectedUserIds?.length
              ? selectedUserIds
              : dialogData.objRef.userIds?.length
              ? dialogData.objRef.userIds
              : []
          "
          [tenants]="tenants"
          [reqs]="dialogData.objRef.reqs"
          [reqIds]="
            selectedReqIds?.length ? selectedReqIds : dialogData.objRef.reqIds?.length ? dialogData.objRef.reqIds : []
          "
          (objDataChanged)="objDataChanged($event, index)"
          [data]="dialogData.objRef.data"
          [esFilters]="dialogData.objRef.esFilters"
          (delete)="objDeleted($event, index)"
          (loaded)="objLoaded($event, index)"
          [reportingTheme]="dialogData.objRef.reportingTheme"
          [preview]="true"
          [triggerChange]="triggerChange"
        >
        </mm-report-obj-five>

        <mm-report-obj-six
          *ngIf="dialogData.objRef.rType === 'mm-report-obj-six'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="
            selectedUserIds?.length
              ? selectedUserIds
              : dialogData.objRef.userIds?.length
              ? dialogData.objRef.userIds
              : []
          "
          [tenants]="tenants"
          [reqs]="dialogData.objRef.reqs"
          [reqIds]="
            selectedReqIds?.length ? selectedReqIds : dialogData.objRef.reqIds?.length ? dialogData.objRef.reqIds : []
          "
          (objDataChanged)="objDataChanged($event, index)"
          [data]="dialogData.objRef.data"
          [esFilters]="dialogData.objRef.esFilters"
          (delete)="objDeleted($event, index)"
          (loaded)="objLoaded($event, index)"
          [reportingTheme]="dialogData.objRef.reportingTheme"
          [preview]="true"
          [triggerChange]="triggerChange"
        >
        </mm-report-obj-six>

        <mm-report-obj-seven
          *ngIf="dialogData.objRef.rType === 'mm-report-obj-seven'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="
            selectedUserIds?.length
              ? selectedUserIds
              : dialogData.objRef.userIds?.length
              ? dialogData.objRef.userIds
              : []
          "
          [tenants]="tenants"
          [reqs]="dialogData.objRef.reqs"
          [reqIds]="
            selectedReqIds?.length ? selectedReqIds : dialogData.objRef.reqIds?.length ? dialogData.objRef.reqIds : []
          "
          (objDataChanged)="objDataChanged($event, index)"
          [data]="dialogData.objRef.data"
          [esFilters]="dialogData.objRef.esFilters"
          (delete)="objDeleted($event, index)"
          (loaded)="objLoaded($event, index)"
          [reportingTheme]="dialogData.objRef.reportingTheme"
          [preview]="true"
          [triggerChange]="triggerChange"
        >
        </mm-report-obj-seven>

        <mm-report-obj-eight
          *ngIf="dialogData.objRef.rType === 'mm-report-obj-eight'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="
            selectedUserIds?.length
              ? selectedUserIds
              : dialogData.objRef.userIds?.length
              ? dialogData.objRef.userIds
              : []
          "
          [tenants]="tenants"
          [reqs]="dialogData.objRef.reqs"
          [reqIds]="
            selectedReqIds?.length ? selectedReqIds : dialogData.objRef.reqIds?.length ? dialogData.objRef.reqIds : []
          "
          (objDataChanged)="objDataChanged($event, index)"
          [data]="dialogData.objRef.data"
          [esFilters]="dialogData.objRef.esFilters"
          (delete)="objDeleted($event, index)"
          (loaded)="objLoaded($event, index)"
          [reportingTheme]="dialogData.objRef.reportingTheme"
          [preview]="true"
          [triggerChange]="triggerChange"
        >
        </mm-report-obj-eight>
      </div>

      <div class="child-2" fxFlex="33%" fxFlex.gt-md="33%" fxFlexOrder="2" fxFlexOrder.lt-lg="2">
        <div *ngIf="!previewLoading" [ngClass]="[reportingTheme]">
          <h4>Settings</h4>

          <mat-form-field class="date-range-picker">
            <input
              matInput
              placeholder="Date range"
              [satDatepicker]="resultPicker"
              (dateChange)="onDateRangeChange($event.value)"
              [(ngModel)]="dateRangeFilter"
            />
            <sat-datepicker
              #resultPicker
              [rangeMode]="true"
              [calendarFooterComponent]="rangesFooter"
              panelClass="range-datepicker"
            ></sat-datepicker>
            <sat-datepicker-toggle matSuffix [for]="resultPicker"></sat-datepicker-toggle>
          </mat-form-field>

          <button mat-menu-item class="button-with-badge" (click)="onFilterByReqs()" onclick="this.blur()">
            <mat-icon>business</mat-icon>({{ dialogData.objRef.data.selectedReqIds?.length }}) Pipelines
          </button>

          <button mat-menu-item class="button-with-badge" (click)="onFilterByUsers()" onclick="this.blur()">
            <mat-icon>supervised_user_circle</mat-icon>({{ dialogData.objRef.data.selectedUserIds?.length }}) Team
            Members
          </button>

          <!-- <button mat-menu-item>
           
            <span>Color</span>
          </button> -->

          <div
            *ngIf="
              dialogData.objRef.rType === 'mm-report-obj-two' ||
              dialogData.objRef.rType === 'mm-report-obj-four' ||
              dialogData.objRef.rType === 'mm-report-obj-eight'
            "
            class="chart-type-toggle"
          >
            <h5 class="pill-toggle-label">Graph Type</h5>
            <mat-button-toggle-group
              name="chartTypeToggle"
              aria-label="Graph Type Toggle"
              class="pill-toggle"
              [(ngModel)]="dialogData.objRef.data.chartType"
            >
              <mat-button-toggle value="line" (click)="toggleChart('line')">
                <mat-icon
                  ><svg style="height: 32px; width: 32px" version="1.1" viewBox="-30 -210 2570 2248">
                    <g transform="matrix(1 0 0 -1 0 1238)">
                      <path
                        fill="#ddd"
                        d="M233 -251h2083q21 0 35.5 -14t14.5 -35q0 -20 -14.5 -34.5t-35.5 -14.5h-2132q-21 0 -35.5 14.5t-14.5 34.5v2118q0 20 14.5 34.5t35.5 14.5q20 0 34.5 -14.5t14.5 -34.5v-1642l235 241q21 21 48 21q25 0 45 -17l211 -176l435 1316q15 46 59 46q45 0 65 -35l310 -560 l179 517q8 21 26 34l391 273q18 12 40 12q28 0 48 -19.5t20 -48.5q0 -37 -29 -57l-373 -261l-224 -640q-16 -47 -59 -47q-46 0 -66 36l-306 557l-416 -1263q-7 -20 -24.5 -34t-41.5 -14q-14 0 -24 4.5t-20 12.5l-240 201l-289 -294v-232z"
                      />
                    </g>
                  </svg>
                </mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="bar" (click)="toggleChart('bar')">
                <mat-icon
                  ><svg
                    style="height: 32px; width: 32px"
                    viewBox="0 0 512 512"
                    id="svg2"
                    version="1.1"
                    inkscape:version="0.48.4 r9939"
                    width="100%"
                    height="100%"
                    sodipodi:docname="histogram.svg"
                  >
                    <defs id="defs12" />
                    <path d="M0 0h512v512H0z" fill="#000000" fill-opacity="0" id="path4" />
                    <g class="" style="touch-action: none" transform="translate(0,-115)" id="g6">
                      <path
                        d="M23 23v466h466v-18h-40.893V256h-48v215h-31.675V159.33h-48V471h-31.227V320.242h-48V471H207.2V80.418h-48V471H128V192H80v279H41V23H23z"
                        fill="#ddd"
                        fill-opacity="1"
                        id="path8"
                      />
                    </g>
                  </svg>
                </mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div *ngIf="dialogData.objRef.rType === 'mm-report-obj-five'" class="chart-type-toggle">
            <h5 class="pill-toggle-label">Graph Type</h5>
            <mat-button-toggle-group
              name="chartTypeToggle"
              aria-label="Graph Type Toggle"
              class="pill-toggle"
              [(ngModel)]="dialogData.objRef.data.chartType"
            >
              <mat-button-toggle value="pie" (click)="toggleChart('pie')">
                <mat-icon
                  ><svg
                    style="height: 32px; width: 32px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    id="Layer_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <g>
                      <g>
                        <g>
                          <path
                            d="M277.333,224H480c5.896,0,10.667-4.771,10.667-10.667C490.667,95.698,394.969,0,277.333,0     c-5.896,0-10.667,4.771-10.667,10.667v202.667C266.667,219.229,271.438,224,277.333,224z M288,21.625     c97.396,5.365,175.677,83.646,181.042,181.042H288V21.625z"
                          />
                          <path
                            d="M501.333,245.333H298.667c-4.313,0-8.208,2.594-9.854,6.583c-1.656,3.99-0.74,8.573,2.313,11.625l143.313,143.313     c2,2,4.708,3.125,7.542,3.125s5.542-1.125,7.542-3.125C489.813,366.552,512,312.979,512,256     C512,250.104,507.229,245.333,501.333,245.333z M441.771,384.021L324.417,266.667h165.958     C488,310.406,471.021,351.406,441.771,384.021z"
                          />
                          <path
                            d="M245.333,251.583V53.333c0-5.896-4.771-10.667-10.667-10.667C105.271,42.667,0,147.938,0,277.333     S105.271,512,234.667,512c67.531,0,131.896-29.313,176.583-80.427c3.698-4.229,3.479-10.594-0.49-14.563L245.333,251.583z      M234.667,490.667c-117.635,0-213.333-95.698-213.333-213.333c0-114.052,89.969-207.5,202.667-213.073V256     c0,2.833,1.125,5.542,3.125,7.542l161.333,161.333C348.24,466.802,292.74,490.667,234.667,490.667z"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="doughnut" (click)="toggleChart('doughnut')">
                <mat-icon
                  ><svg
                    style="height: 32px; width: 32px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    id="Layer_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 510.948 510.948"
                    style="enable-background: new 0 0 510.948 510.948"
                    xml:space="preserve"
                  >
                    <g>
                      <g>
                        <g>
                          <path
                            d="M500.261,265.614H371.313c-5.146,0-9.563,3.677-10.49,8.74c-2.74,14.865-8.438,28.667-16.948,41.021     c-2.927,4.24-2.396,9.958,1.24,13.594l91.198,91.198c2,2.01,4.719,3.125,7.542,3.125c0.156,0,0.313,0,0.458-0.01     c2.99-0.125,5.792-1.51,7.708-3.792c33.969-40.448,54.333-89.656,58.865-142.292c0.26-2.979-0.75-5.927-2.76-8.125     C506.104,266.864,503.25,265.614,500.261,265.614z M442.969,396.656l-76.719-76.719c6.094-10.302,10.656-21.344,13.635-32.99     h108.458C482.917,326.968,467.386,364.5,442.969,396.656z"
                          />
                          <path
                            d="M330.032,344.052c-3.646-3.635-9.365-4.177-13.594-1.24c-17.865,12.302-38.76,18.802-60.427,18.802     c-58.813,0-106.667-47.854-106.667-106.667c0-51.438,36.698-95.51,87.26-104.813c5.063-0.927,8.74-5.344,8.74-10.49V10.697     c0-2.99-1.25-5.844-3.458-7.865c-2.198-2.031-5.177-3.135-8.125-2.76C102.688,11.375,0.011,123.333,0.011,254.947     c0,141.156,114.844,256,256,256c60.052,0,118.49-21.302,164.542-59.99c2.281-1.927,3.667-4.719,3.792-7.708     c0.125-2.979-1-5.885-3.115-8L330.032,344.052z M256.011,489.614c-129.396,0-234.667-105.271-234.667-234.667     c0-116.74,88.115-216.604,202.667-232.333v108.458c-56.135,14.406-96,65.146-96,123.875c0,70.583,57.417,128,128,128     c23.021,0,45.313-6.115,64.99-17.75l76.708,76.708C357.042,472.75,307.167,489.614,256.011,489.614z"
                          />
                          <path
                            d="M275.417,150.135c43.146,7.938,77.469,42.26,85.406,85.406c0.927,5.063,5.344,8.74,10.49,8.74h128.958     c2.99,0,5.844-1.25,7.865-3.458c2.021-2.198,3.021-5.146,2.76-8.125C500.177,108.614,402.344,10.781,278.261,0.072     c-2.896-0.271-5.927,0.74-8.125,2.76c-2.208,2.021-3.458,4.875-3.458,7.865v128.948     C266.677,144.791,270.354,149.208,275.417,150.135z M288.011,22.614c104.354,14.167,186.167,95.969,200.344,200.333H379.875     c-11.583-44.771-47.094-80.281-91.865-91.865V22.614z"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div style="margin: 0.8em; height: 6.5em">
            <h5 class="color-label">Theme Color</h5>

            <div>
              <div class="color-box box-color-1" (click)="onClickColorChange('box-color-1')"></div>
              <div class="color-box box-color-2" (click)="onClickColorChange('box-color-2')"></div>
              <div class="color-box box-color-3" (click)="onClickColorChange('box-color-3')"></div>
              <div class="color-box box-color-4" (click)="onClickColorChange('box-color-4')"></div>
              <div class="color-box box-color-5" (click)="onClickColorChange('box-color-5')"></div>
            </div>

            <div style="clear: both">
              <div class="color-box box-color-6" (click)="onClickColorChange('box-color-6')"></div>
              <div class="color-box box-color-7" (click)="onClickColorChange('box-color-7')"></div>
              <div class="color-box box-color-8" (click)="onClickColorChange('box-color-8')"></div>
              <div class="color-box box-color-9" (click)="onClickColorChange('box-color-9')"></div>
              <div class="color-box box-color-10" (click)="onClickColorChange('box-color-10')"></div>
            </div>
          </div>

          <div style="margin: 0.8em; clear: both" *ngIf="dialogData.objRef.data.showReqStageOptions">
            <h5 class="settings-label">Req Contact Stage</h5>
            <mat-form-field class="form-stage-class" style="margin-top: 0">
              <mat-select [(ngModel)]="dialogData.objRef.data.reqStage" (selectionChange)="stageChanged()">
                <mat-option [value]=""> All </mat-option>
                <mat-option *ngFor="let option of stageOptions" [value]="option.value">
                  {{ option.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div style="margin: 0.8em; clear: both" *ngIf="dialogData.objRef.data.showTagFilter">
            <h5 class="settings-label">Tags Filter</h5>

            <mat-form-field class="example-full-width" appearance="standard">
              <!-- <mat-label>Tags Filter</mat-label> -->
              <input
                type="text"
                placeholder="Pick one"
                aria-label="Tags Filter"
                matInput
                (change)="tagsFilterstageChanged()"
                [matAutocomplete]="autoContactTags"
                #contactTagInput
                name="contactTagInput"
                [formControl]="contactTagsControl"
              />
              <mat-autocomplete
                #autoContactTags="matAutocomplete"
                [displayWith]="displayContactTagFn"
                (optionSelected)="selectedContactTag($event)"
              >
                <mat-option *ngFor="let contactTag of filteredContactTags | async" [value]="contactTag">
                  {{ contactTag.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div *ngIf="dialogData.objRef.data.showSequenceFilter">
            <mat-form-field appearance="standard">
              <mat-label>Sequence Filter</mat-label>
              <mat-select
                [(value)]="selectedSequence"
                (selectionChange)="onSequenceFilterChanged($event)"
                [compareWith]="sequenceObjectComparisonFunction"
              >
                <mat-option value="">None</mat-option>
                <mat-option *ngFor="let sequenceItem of sequenceItems" [value]="sequenceItem">
                  {{ sequenceItem.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="dialogData.objRef.data.showSequenceFilter">
            <mat-form-field appearance="standard">
              <mat-label>Sequence Step</mat-label>
              <mat-select [(value)]="selectedStep" (selectionChange)="onSequenceStepChanged($event)">
                <mat-option value="">Any</mat-option>
                <mat-option value="-1">Start</mat-option>
                <mat-option *ngFor="let step of steps" [value]="step">
                  {{ step }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</section>

<div mat-dialog-actions class="picker-actions-container">
  <div class="picker-actions">
    <button mat-button (click)="onCloseClick()">Cancel</button>
    <button mat-button (click)="onApplySelected()" color="primary">Apply</button>
  </div>
</div>
