import { Directive, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

@Directive()
export abstract class ModelMatchComponent implements OnDestroy {
  private count: number = 0;
  set subs(subscription: Subscription) {
    this.subscriptions.add(subscription);
  }

  private subscriptions = new Subscription();

  constructor() {}

  subscribe<T = any>(service: Observable<T>, successFn: (value: T) => void, errorHandler?: (value: any) => void) {
    this.subs = service.subscribe(successFn, errorHandler || this.errorHandler);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private errorHandler(error) {
    // TODO: generic error handling
  }
}
