import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewActivityDialogComponent } from '../dialogs/new-activity-dialog.component';
import { MeetingDispositionDialog } from '../dialogs/meeting-disp-dialog.component';
import { Activity, ActivityType } from '../model/activity';
import { Contact, ContactStage } from '../model/contacts';
import { Requisition, UserReq } from '../model/requisitions';
import { ActivityReqLabel } from '../pipes/activity.pipe';
import { RequisitionRoutePipe } from '../pipes/requisition.pipe';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { DisplayActivityDialogComponent } from '../dialogs/display-activity-dialog.component';
import { ActivityCriteria, FilterData, Option } from '../model/filter';
import { Page, FilterResult, SortOption } from 'app/shared/model/filter';
import { ActivityFilterDialogComponent } from '../dialogs/activity-filter-dialog.component';
import { SatDatepickerRangeValue } from 'saturn-datepicker-ext';
import * as moment from 'moment';
import { DateRange } from '../../shared/model/filter';
import { UserService } from '../../services/user.service';
import { ContactService } from '../../services/contact.service';
import { ImageCachingService } from '../../services/imagecaching.service';
import { TodoService } from 'app/services/todo.service';
import { map, take } from 'rxjs/operators';
import { ZapierService } from 'app/services/zapier.service';
import { ZapierTriggers } from '../model/zapier';
import { AlertDialogComponent, AlertDialogConfig } from '../dialogs/alert-dialog.component';

@Component({
  selector: 'mm-todo-tab',
  templateUrl: './todo-tab.component.html',
  styleUrls: ['./todo-tab.component.scss'],
})
export class TodoTabComponent implements OnInit {
  private _todos = new BehaviorSubject<Activity[]>([]);
  private todoSub: Subscription;
  private _pageTodo = new BehaviorSubject<Page>({});
  @Output() pageChanged: EventEmitter<Page> = new EventEmitter();

  onChange($event: Page) {
    this._pageTodo.next($event);
    this.pageChanged.emit(this._pageTodo.getValue());
  }

  @Input()
  set todos(value) {
    this._todos.next(value);
  }

  get todos() {
    return this._todos.getValue();
  }

  @Input()
  set pageTodo(value) {
    this._pageTodo.next(value);
  }

  get pageTodo() {
    return this._pageTodo.getValue();
  }

  @Input() todoFilters: ActivityCriteria;
  @Output() filterApplied: EventEmitter<ActivityCriteria> = new EventEmitter();

  @Input() show: boolean;
  @Input() isFromRequisition: boolean;
  @Input() isAddTodoAvailable: boolean;
  @Input() requisition: Requisition;
  @Input() tableClass: string;
  @Input() mainHeaderHidden: boolean;
  @Input() sectionTitle: string;
  @Input() sortOptionTodo: SortOption;

  private _showTodo: boolean;

  @Input() set showTodo(value: boolean) {
    this._showTodo = value;
    this.showTodoAfterRouting(this._showTodo);
  }

  startDate = new Date();
  reqOptions: Option[] = [{ value: '', title: 'View All Activity' }];
  private _requisitions: Requisition[] = [];
  @Input()
  set requisitions(value) {
    this._requisitions = value;
    this.requisitions.forEach((req) => {
      if (req.id != null && req.id != undefined && req.title != null && req.title != undefined) {
        this.reqOptions.push({ value: req.reqId, title: req.title });
      }
    });
  }

  get requisitions() {
    return this._requisitions;
  }
  @Input() displayedTodoColumns: string[] = [
    'type',
    'title',
    'participants',
    'requisition',
    'stage',
    'start-date',
    'complete',
  ];
  @Input() displayedFilterColumns: string[] = [
    'type-filter',
    'title-filter',
    'participants-filter',
    'requisition-filter',
    'stage-filter',
    'start-date-filter',
    'complete-filter',
  ];
  todoTableSource: MatTableDataSource<Activity>;
  readonly typeDropOptions = [
    {
      label: '- None -',
      value: '',
    },
    {
      label: ActivityType.Call,
      value: ActivityType.Call,
    },
    {
      label: ActivityType.Meeting,
      value: ActivityType.Meeting,
    },
    {
      label: ActivityType.Task,
      value: ActivityType.Task,
    },
    {
      label: ActivityType.Other,
      value: ActivityType.Other,
    },
  ];
  readonly stageOptions = [
    {
      label: '- None -',
      value: '',
    },
    {
      label: ContactStage.PROSPECTS,
      value: ContactStage.PROSPECTS,
    },
    {
      label: ContactStage.CANDIDATES,
      value: ContactStage.CANDIDATES,
    },
    {
      label: ContactStage.MEETINGS,
      value: ContactStage.MEETINGS,
    },
    {
      label: ContactStage.OFFERS,
      value: ContactStage.OFFERS,
    },
    {
      label: ContactStage.HIRES,
      value: ContactStage.HIRES,
    },
  ];
  typeFilterValue: string;
  readonly completeDropOptions = [
    { label: '- None -', value: '' },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  completeFilterValue: string;
  // startDate = new Date();
  activeFilters: ActivityCriteria = {};
  completedTodos = {};
  avatarUrls: any = {};
  taskPickerView: boolean = false;
  createTodoView: boolean = false;
  editTodoView: boolean = false;
  private snackBarRef: MatSnackBarRef<any> = null;

  contactId: string;
  reqId: string;
  queryParams;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  private dateRange$: BehaviorSubject<DateRange> = new BehaviorSubject({
    startDate: null,
    endDate: null,
  });

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private contactService: ContactService,
    private imageCachingService: ImageCachingService,
    private todoService: TodoService,
    private zapierService: ZapierService
  ) {}

  showTodoAfterRouting(showTodo) {
    // Hack to show the todo only once, because there are 3 todo tabs.
    if (showTodo && this.sectionTitle == 'Due Today') this.editTodoView = true;
  }

  ngOnInit() {
    if (this.isFromRequisition) {
      let displayIndex = this.displayedTodoColumns.findIndex((str) => str === 'requisition');
      this.displayedTodoColumns.splice(displayIndex, 1);
      let filterIndex = this.displayedFilterColumns.findIndex((str) => str === 'requisition-filter');
      this.displayedFilterColumns.splice(filterIndex, 1);
    }
    this.todoTableSource = new MatTableDataSource<Activity>([]);
    this.todoTableSource.sort = this.sort;
    this.todoTableSource.filterPredicate = (data: Activity, filter: string) => this.doFilter(data);

    // custom sorting
    this.todoTableSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'complete':
          return '';
        case 'start-date':
          return new Date(item.dueOn);
        case 'participants':
          return '';
        case 'stage':
          return this.getStage(item);
        case 'requisition':
          return new ActivityReqLabel().transform(item.reqId, this.requisitions);
        default:
          return item[property];
      }
    };

    this.todoSub = this._todos.subscribe((arrActivities) => {
      this.todoTableSource.data = arrActivities;
      this.todoTableSource.filter = JSON.stringify(this.activeFilters);
      this.getAvatarUrls(arrActivities);
      console.log('Todo-tab.component :: Got todos:', arrActivities);
      combineLatest([this.route.params, this.route.queryParams])
        .pipe(map((results) => ({ params: results[0], queryParams: results[1] })))
        .subscribe(({ params, queryParams }) => {
          this.contactId = params['contactId'];
          this.reqId = params['reqId'];
          this.queryParams = { queryParams };
          let todoId = this.queryParams.queryParams.displayTodo;
          let todo = this.todoTableSource.data.find((todo) => todo.id === todoId);

          if (todoId && todo) {
            console.log('Todo-tab.component :: Got todo with todoId from queryParams:', todo);
            this.onTodoRowClick(todo);
          }
        });
    });
  }

  onTodoRowClick(todo: any): void {
    console.log('Todo: ', todo);
    this.todoService.todo = todo;
    if (todo.type === ActivityType.Meeting || todo.type === ActivityType.Call) {
      // Route to meeting
      this.openMeeting(todo.id);
    } else if (
      todo.type === ActivityType.Task ||
      todo.type === ActivityType.Note ||
      todo.type === ActivityType.Email ||
      todo.type === ActivityType.SMS
    ) {
      this.editTodoView = true;
    }
  }

  openMeeting(todoId) {
    if (this.reqId && this.contactId) {
      this.router.navigate(['contacts', this.contactId, this.reqId, 'meeting', todoId]);
    } else if (this.reqId) {
      this.router.navigate(['requisitions', this.reqId, 'meeting', todoId]);
    } else if (this.contactId) {
      this.router.navigate(['contacts', this.contactId, 'meeting', todoId]);
    } else {
      this.router.navigate(['/activity/meeting', todoId]);
    }
  }

  onContactClick(event, { id }: Contact, { reqId }: Activity): void {
    event.stopPropagation();
    event.preventDefault();
    var link = window.location.origin + '/#/';
    if (reqId) {
      link = link + `contacts/${id}/${reqId}?filters=${JSON.stringify(this.activeFilters)}`;
      let encodedLink = encodeURI(link);
      window.open(encodedLink, '_blank');
    } else {
      link = link + `contacts/${id}`;
      window.open(link, '_blank');
    }
  }

  onRequisitionClick(todo: Activity): void {
    const { reqId } = todo;
    const reqTitle = new ActivityReqLabel().transform(reqId, this.requisitions);
    const route = new RequisitionRoutePipe().transform(reqTitle);
    this.router.navigate(['requisitions', reqId]);
  }

  // async applyFilter(field: string, filterValue: string) {
  //     if (!filterValue) {
  //         this.clearFilter(field);
  //     }

  //     this.activeFilters[field] = filterValue.trim().toLocaleLowerCase();
  //     this.todoTableSource.filter = JSON.stringify(this.activeFilters);
  // }

  getAvatarUrls(activities) {
    activities.forEach((activity) => {
      activity.users.forEach((user) => {
        if (!this.avatarUrls[user.id]) {
          this.userService
            .getUserAvatarUrl(user.id)
            .pipe(take(1))
            .subscribe(
              ({ data }) => {
                const url = data.getUserAvatarUrl?.url || data.getContactAvatarUrl?.url;
                this.avatarUrls[user.id] = url;
                if (url) this.imageCachingService.cacheImageFromUrl(url, user.id);
              },
              (error) => console.log(error)
            );
        }
      });

      activity.contacts.forEach((contact) => {
        if (!this.avatarUrls[contact.id]) {
          this.contactService.getContactAvatarUrl(contact.id).subscribe(
            ({ data }) => {
              const url = data.getContactAvatarUrl.url;
              this.avatarUrls[contact.id] = data.getContactAvatarUrl?.url || '';
              if (url) this.imageCachingService.cacheImageFromUrl(url, contact.id);
            },
            (error) => console.log(error)
          );
        }
      });
    });
  }

  async applyFilter(field: string, filterValue: any) {
    if (!filterValue) {
      this.clearFilter(field);
    }

    if (field === 'dueDateStart') {
      this.activeFilters.dueDateStart = filterValue.toISOString();
      if (!this.activeFilters.dueDateEnd) {
        const endDate = new Date(filterValue);
        endDate.setDate(endDate.getDate() + 1);
        this.activeFilters.dueDateEnd = endDate.toISOString();
      }
    } else if (field === 'dueDateEnd') {
      this.activeFilters.dueDateEnd = filterValue.toISOString();
      if (!this.activeFilters.dueDateStart) {
        const startDate = new Date(filterValue);
        startDate.setDate(startDate.getDate() + 1);
        this.activeFilters.dueDateStart = startDate.toISOString();
      }
    } else if (field === 'compeletedDateStart') {
      this.activeFilters.compeletedDateStart = filterValue.toISOString();
      const endDate = new Date(filterValue);
      endDate.setDate(endDate.getDate() + 1);
      this.activeFilters.compeletedDateEnd = endDate.toISOString();
    } else {
      this.activeFilters[field] = filterValue.trim();
    }
    // this.historyTableSource.filter = JSON.stringify(this.activeFilters);
    this.activeFilters.todoSection = this.todoFilters.todoSection;
    console.log('filterResults:', this.activeFilters);
    this.filterApplied.emit(this.activeFilters);
  }

  clearDate(field: string) {
    if (field === 'dueDateStart') {
      this.clearFilter('dueDateEnd');
      this.clearFilter('dueDateStart');
    } else if (field === 'dueDateEnd') {
      this.clearFilter('dueDateEnd');
      this.clearFilter('dueDateStart');
    }
    this.activeFilters.todoSection = this.todoFilters.todoSection;
    this.filterApplied.emit(this.activeFilters);
  }

  sortData($event) {
    this.sortOptionTodo.field = this.sort.active;
    this.sortOptionTodo.direction = this.sort.direction;
    this.filterApplied.emit(this.activeFilters);
  }

  doFilter(data: Activity): boolean {
    // && this.matchReq(data)
    return this.matchType(data) && this.matchTitle(data) && this.matchParticipants(data) && this.matchDate(data);
  }

  matchType(data: Activity): boolean {
    if (!this.activeFilters['type']) {
      return true;
    }

    return this.activeFilters['type'].toLowerCase() == data.type.toLowerCase();
  }

  matchTitle(data: Activity): boolean {
    if (!this.activeFilters['title']) {
      return true;
    }

    return data.title.trim().toLowerCase().includes(this.activeFilters['title'].trim().toLowerCase());
  }

  matchParticipants(data: Activity): boolean {
    if (!this.activeFilters['participants']) {
      return true;
    }

    let names = [];
    if (data.users) {
      data.users.forEach((user) => {
        names.push(user.firstName);
        names.push(user.lastName);
      });
    }

    if (data.contacts) {
      data.contacts.forEach((contact) => {
        names.push(contact.firstName);
        names.push(contact.lastName);
      });
    }

    let namesString = names.join(' ').toLowerCase();
    return namesString.includes(this.activeFilters['participants'].toLowerCase());
  }

  matchReq(data: Activity): boolean {
    if (!this.activeFilters['requisition']) {
      return true;
    }

    let requistionName = new ActivityReqLabel().transform(data.reqId, this.requisitions);

    return requistionName.toLowerCase().includes(this.activeFilters['requisition'].toLowerCase());
  }

  matchDate(data: Activity): boolean {
    if (!this.activeFilters['date']) {
      return true;
    }

    let dueDateObj = new Date(data.dueOn);
    let dueDateString = dueDateObj
      .toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .split(' ')
      .join('/');

    return dueDateString.startsWith(this.activeFilters['date'].toLowerCase());
  }

  clearFilter(field: string) {
    delete this.activeFilters[field];
  }

  selectDisposition(data) {
    const dialogRef = this.dialog.open(MeetingDispositionDialog, {
      panelClass: 'new-activity-dialog',
      data,
    });
    dialogRef.afterClosed().subscribe((dispSelected) => {
      if (dispSelected) this.doComplete(data);
    });
  }

  // Only open the coninue or complete dialog if the todo is recurring
  openCompleteOrContinueDialog(todo) {
    if (todo.recurring?.frequency == 'Does not repeat' || !todo.recurring?.frequency) {
      this.doComplete(todo);
    } else {
      let tempRecurringDate = this.todoService.calculateNextRecurringTodoDate(todo);
      let dialogConfig = this.todoService.getCompleteOrContinueDialogConfig(tempRecurringDate);

      let dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, string>(AlertDialogComponent, {
        panelClass: 'alert-dialog-component',
        data: dialogConfig,
        autoFocus: false,
        width: '35%',
      });

      return dialogRef.afterClosed().subscribe((confirmation) => {
        if (confirmation == 'continue') {
          // Create a new recurring todo
          this.doComplete(todo, true);
        } else if (confirmation == 'complete') {
          this.doComplete(todo);
        }
      });
    }
  }

  async createTodo(todo, nextRecurringDate) {
    let authenticatedUserInfo = await this.todoService.getAuthenticatedUserInfo();
    let assignees = todo.users.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
    }));
    let contacts = todo.contacts.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
    }));
    let dueOn = todo.dueTime
      ? new Date(todo.dueOn.setHours(todo.dueTime[0], todo.dueTime[1])).toISOString()
      : todo.dueOn;
    dueOn = nextRecurringDate ? nextRecurringDate : dueOn;
    dueOn = dueOn.toISOString();
    let todoInfo = {
      title: todo.title,
      type: ActivityType.Task,
      description: todo.description,
      users: assignees,
      contacts: contacts,
      reqId: todo.reqId,
      location: todo.location,
      phone: todo.phone,
      duration: todo.duration,
      createdBy: authenticatedUserInfo.attributes['custom:userId'],
      dueOn: dueOn,
      recurring: {
        frequency: todo.recurring.frequency,
        customRecurringNum: todo.recurring.frequency == 'Custom' ? todo.recurring.customRecurringNum : null,
        customRecurringFrequency: todo.recurringFrequency == 'Custom' ? todo.recurring.customRecurringFrequency : null,
      },
    };
    console.log('Todo-tab: adding todo', todoInfo);

    this.todoService.createTodo(todoInfo).subscribe(
      (result) => {
        console.log('Todo-tab: todo created ', result);
        // this.closePopover();
        this.snackBar.open('Added To-Do', null, {
          duration: 4000,
          panelClass: 'success-snack-bar',
        });
      },
      (error) => {
        console.log('Todo-tab: Error creating todo ', error);
        // this.closePopover();
        this.snackBar.open('Error adding To-Do', null, {
          duration: 4000,
          panelClass: 'error-snack-bar',
        });
      }
    );
  }

  doComplete(todo: Activity, createNextAction: boolean = false) {
    let todoType = todo.type.charAt(0) + todo.type.slice(1).toLowerCase();
    this.completedTodos[todo.id] = todo.id;
    this.todoService.completeTodo(todo, createNextAction).subscribe(
      async (result) => {
        console.log('TodoService: Complete todo -> result', result);
        this.editTodoView = false;
        if (todo.disposition === 'delete') {
          this.snackBar.open(`${todoType} Deleted`, null, {
            duration: 4000,
            panelClass: 'success-snack-bar',
          });
        } else {
          this.snackBar.open(`${todoType} Completed`, null, {
            duration: 4000,
            panelClass: 'success-snack-bar',
          });
        }

        // Zapier todo completed
        let user = await this.getAuthenticatedUserInfo();
        let completedDate = new Date();
        let zapierTriggerBody = {
          todoTitle: todo.title,
          completedDate: completedDate.toISOString().substring(0, 10),
          // What happens if we have multiple contacts?
          contactId: todo.contacts[0]?.id,
          userId: user.attributes['custom:userId'],
          checkValidity: {
            todoTitle: todo.title,
          },
        };
        this.zapierService.addToZapierTriggerQueue(ZapierTriggers.todoCompleted, zapierTriggerBody).subscribe((res) => {
          console.log(res);
        });
      },
      (error) => {
        console.log('TodoService: There was an error completing todo', error);
        this.snackBar.open(`Error completing ${todoType}`, null, {
          duration: 4000,
          panelClass: 'error-snack-bar',
        });
      }
    );
  }

  getStage(todo: Activity) {
    if (todo.contacts.length > 0) {
      if (this.activeFilters['stage']) {
        let filtered = todo.contacts.filter((contact) => contact.stage === this.activeFilters['stage']);
        return filtered[0] != null ? filtered[0].stage : '';
      } else {
        return todo.contacts[0].stage;
      }
    } else {
      return '';
    }
  }

  addTodo() {
    // update input that im being open
    this.taskPickerView = !this.taskPickerView;
  }

  closeTaskPicker(closed) {
    this.taskPickerView = closed;
  }

  openCreateTodo(open) {
    this.createTodoView = open;
  }

  closeCreateTodo(closed) {
    this.createTodoView = closed;
  }

  closeEditTodo(closed) {
    this.editTodoView = closed;
  }

  onFilterClick() {
    const activityTypes = [];
    this.typeDropOptions.forEach((type) => activityTypes.push({ value: type.value, title: type.label }));
    const stageTypes = [];
    this.stageOptions.forEach((type) => stageTypes.push({ value: type.value, title: type.label }));

    const data: FilterData = {
      requisitions: this.requisitions,
      activityTypes: activityTypes,
      stageTypes: stageTypes,
      filterCriteria: this.todoFilters,
      isHistory: false,
      isSideBar: false,
      isFromRequisition: this.isFromRequisition,
    };
    const dialogRef = this.dialog.open(ActivityFilterDialogComponent, {
      panelClass: 'filter-dialog',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result: FilterResult) => {
      console.log('filterResults:', result);
      if (
        result != null &&
        result.filterAction != null &&
        result.filterAction != undefined &&
        result.filterAction.toLocaleLowerCase() === 'apply'
      ) {
        this.activeFilters = {};
        this.todoFilters = result.filterCriteria;
        if (
          result.filterCriteria.dueDateStart !== null &&
          result.filterCriteria.dueDateEnd !== null &&
          result.filterCriteria.dueDateStart !== undefined &&
          result.filterCriteria.dueDateEnd !== undefined &&
          result.filterCriteria.dueDateStart === result.filterCriteria.dueDateEnd
        ) {
          const endDate = new Date(result.filterCriteria.dueDateStart);
          endDate.setDate(endDate.getDate() + 1);
          result.filterCriteria.dueDateEnd = endDate.toISOString();
        }
        this.activeFilters = result.filterCriteria;
        this.filterApplied.emit(result.filterCriteria);
      }
    });
  }

  onDateChange(dateRange: SatDatepickerRangeValue<Date>) {
    const startDate = dateRange ? moment(dateRange.begin).startOf('day').toISOString() : null;

    const endDate = dateRange ? moment(dateRange.end).endOf('day').toISOString() : null;

    this.activeFilters.dueDateEnd = endDate;
    this.activeFilters.dueDateStart = startDate;

    if (!endDate) this.clearFilter('dueDateEnd');
    if (!startDate) this.clearFilter('dueDateStart');

    this.activeFilters.todoSection = this.todoFilters.todoSection;
    console.log('filterResults:', this.activeFilters);
    this.filterApplied.emit(this.activeFilters);

    this.dateRange$.next({ startDate, endDate });
  }

  async getAuthenticatedUserInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.getAuthenticatedUserInfo().subscribe(
        (userInfo: any) => {
          console.log('TodoService: authenticatedUserInfo ', userInfo);
          resolve(userInfo);
        },
        (error) => {
          console.error('TodoService: Error getting authenticated user groups -> ' + error.message);
          reject(null);
        }
      );
    });
  }

  ngOnDestroy() {
    this.todoSub.unsubscribe();
  }
}
