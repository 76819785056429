<h2 style="color: #505050">Ready to import?</h2>

<h4 style="color: #505050; font-weight: 400">
  You are about to import approximately <strong>{{ importOptions.count }}</strong> contacts to your account
</h4>
<h4 *ngIf="importOptions.dupCount" style="color: #505050; font-weight: 400">
  We found <strong>{{ importOptions.dupCount }}</strong> duplicate records based on Names, NMLSIDs, or Emails. They will
  not be imported.
</h4>
<h4 style="color: #505050; font-weight: 400" *ngIf="importOptions.tenantName">
  Company - <strong>{{ importOptions.tenantName }}</strong>
</h4>
<h4 style="color: #505050; font-weight: 400" *ngIf="importOptions.reqName">
  Pipeline - <strong>{{ importOptions.reqName }}</strong>
</h4>
<h4 style="color: #505050; font-weight: 400" *ngIf="importOptions.assignee">
  Assigned To - <strong>{{ importOptions.assignee.firstName }} {{ importOptions.assignee.lastName }}</strong>
</h4>
<div *ngIf="importOptions?.tags?.length">
  <h4 style="color: #505050">Tags</h4>
  <mat-chip-list #contactTagsChipList aria-label="Contact Tags">
    <mat-chip
      *ngFor="let contactTag of importOptions.tags"
      removable
      selectable
      (removed)="removeContactTag(contactTag)"
    >
      {{ contactTag.name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</div>

<mat-dialog-actions style="justify-content: space-between; margin-top: 2em">
  <button color="warn" mat-button class="mat-elevation-z3" mat-dialog-close>Not Yet</button>
  <button style="background-color: #4985a5; color: white" mat-button class="mat-elevation-z3" (click)="startImport()">
    Start Import
  </button>
</mat-dialog-actions>
