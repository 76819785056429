declare let pendo: any;

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { LoginDialogComponentComponent } from 'app/shared/dialogs/login-dialog-component.component';
import { UserService } from '../services/user.service';
import { StripeService } from 'app/services/stripe.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StripeSubscription } from 'graphql.types';

const BILLING_USER_GROUP = 'billing';
import { switchMap, take } from 'rxjs/operators';
import { CognitoService } from 'app/services/cognito.service';
import { PermissionsService } from 'app/services/permissions.service';
import { NavigationService } from 'app/services/navigation.service';
import { Subscription } from 'rxjs';
import { SelfSignupService } from 'app/services/self-signup.service';

@Component({
  selector: 'mm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  dialogRef: MatDialogRef<LoginDialogComponentComponent>;
  subscriptionStatusActiveOrPastDue: boolean;
  stripeSubscriptionId: string;
  deviceKeys: any = [];
  numOfDevices: number = 0;
  userName: string = '';

  @Input() timeStamp: string;

  isAuthenticatedSubscription: Subscription;
  tempPW: string;
  username: string;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private stripeService: StripeService,
    private snackbar: MatSnackBar,
    private cognitoService: CognitoService,
    private permissionsService: PermissionsService,
    private navigationService: NavigationService,
    private selfSignupService: SelfSignupService
  ) {}

  login(username = '', password = '') {
    const dialogComponent = this.dialogRef.componentInstance;
    dialogComponent.loggingIn = true;

    this.userService
      .authenticate(username.trim(), password.trim())
      .pipe(take(1))
      .subscribe(
        async (authenticateResults: any) => {
          // ***TODO - this code assumes login successful - dont continue if not
          // ***TODO We just authenticated why reload user info?
          // Just get user info from behavior subject
          // Or even direct variable from Service?
          this.initPendoAndStripeCheck().then(async (result) => {
            // console.log('login initPendoAndStripeCheck result', result);

            if (result) {
              // await stripe checks

              // console.log('LoginComponent: login authenticate result: ' + JSON.stringify(result));
              let deviceRememberedResponse = await this.isDeviceRemembered(username, password);
              let multipleSessions = false;
              try {
                multipleSessions = this.permissionsService.getCurrentPermissions().session.multiple_sessions === true;
              } catch (e) {
                console.error('Error getting multiple sessions permission during login. Defaulting to false.');
              }

              // TODO: Migrate all user login things to the user service and realtime check the permissions to log the user out if they no longer have multiple sessions.
              this.userService
                .getUserDevicesInfo(this.userService.userId)
                .pipe(take(1))
                .subscribe(
                  async (result) => {
                    // console.log('getUserDevicesInfo result',result);

                    // List devices for user from DB
                    this.deviceKeys = result.data.getUserDevicesInfo.map((item) => item.deviceKey);
                    this.numOfDevices = this.deviceKeys.length;

                    // console.log('User device count', this.numOfDevices);
                    // console.log('Mult', multipleSessionsResponse);
                    // If multiple sessions disabled, make sure cognito is synced with db
                    if (multipleSessions === false) {
                      // console.log('Checking device list for multiple logins');

                      let deviceKey = await this.getCurrentDeviceKey();

                      // console.log('deviceKey', deviceKey);

                      this.cognitoService
                        .listDevices()
                        .pipe(take(1))
                        .subscribe(
                          (result) => {
                            console.log('🧭 devices', deviceKey, result);

                            if (result.length > 0) {
                              // Filter out the current device and the device in the DB.
                              // If more than 1 after filter, need to sync cognito with DB.
                              let cognitoDevices = result
                                .map((item) => item.deviceKey)
                                .filter((item) => item !== deviceKey && item !== this.deviceKeys[0]);

                              if (cognitoDevices.length > 0) {
                                this.cognitoService
                                  .forgetDevices(cognitoDevices)
                                  .pipe(take(1))
                                  .subscribe(
                                    (result) => {
                                      // console.log('Forget device result: ', result);
                                    },
                                    (error) => {
                                      // console.log('There was an error forgetting devices. ', error);
                                    }
                                  );
                              }
                            }
                          },
                          (error) => {
                            // console.log('Error in listing devices ', error);
                          }
                        );
                    }

                    // If multiple sessions is disabled for this user, open dialog
                    if (multipleSessions === false && this.numOfDevices > 0) {
                      // Keep track if user responds
                      // If they leave the page, log them out
                      // console.log('Multiple sessions disabled for this user', this.numOfDevices);
                      localStorage.setItem('mmBlockedAuth$', 'true');

                      // console.log('Warn Multi Login dialog', dialogComponent);

                      // console.log('isNotifyMultipleSessionsEnabled before = ', dialogComponent.isNotifyMultipleSessionsEnabled);

                      dialogComponent.enableNotifyingMultipleSessions();

                      // console.log('isNotifyMultipleSessionsEnabled after = ', dialogComponent.isNotifyMultipleSessionsEnabled);
                    } else {
                      // On successful login, redirect to saved query params if valid
                      // If not, redirect to req page
                      // We use navigateByUrl to be able to get to url containing query params if any
                      // If last route was /warning/logout page, do not redirect back to page after login
                      this.setDeviceInfoForUser().then(() => {
                        this.dialogRef.close();

                        // Now logged in ok - do not redirect to warning this time
                        // Either go to saved path OR go to Requisitions by default
                        let defaultRoute = this.navigationService.getDefaultRoute();
                        // console.log('🪵 ~ LoginComponent ~ this.setDeviceInfoForUser ~ defaultRoute:', defaultRoute);
                        if (this.userService.urlPath === '/warning/logout') {
                          this.userService.urlPath = defaultRoute;
                        }

                        this.router
                          .navigateByUrl(this.userService.urlPath ?? defaultRoute)
                          .then(() => {
                            this.userService.urlPath = undefined;
                            console.log('Clearing redirect URL.');
                          })
                          .catch((e) => console.error(e));
                      });
                    }
                  },
                  (error) => {
                    // console.log('There was an error retrieving user devices ', error);
                  }
                );
            }
          });
        },
        (error) => {
          // console.log('LoginComponent: authenticate result: ' + error.message);
          if (error.code && error.code == 'PasswordResetRequiredException') {
            dialogComponent.isResetRequired = true;
            dialogComponent.loggingIn = false;
            dialogComponent.setErrorMessage('Password must be reset');
            return;
          }

          dialogComponent.loggingIn = false;
          dialogComponent.setErrorMessage(error?.message || 'Incorrect username or password');
          console.error('Login Error:', error);
        }
      );
  }

  ngOnInit() {
    this.userService.loginLast = this.router.url;

    this.isAuthenticatedSubscription = this.userService.isAuthenticated().subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.router.navigateByUrl(this.userService.urlPath ?? this.navigationService.getDefaultRoute()).then(() => {
          this.userService.urlPath = undefined;
          console.log('Clearing redirect URL.');
        });
      } else {
        this.checkURLParams().then((_) => {
          this.openDialog();
        });
      }
    });
  }

  async checkURLParams() {
    const { tempPW, username } = await this.route.queryParams.pipe(take(1)).toPromise();
    if (tempPW && username) {
      this.tempPW = tempPW;
      this.username = username;
    }
    return;
  }

  openDialog() {
    // console.log('openDialog login.component ');

    this.dialogRef = this.dialog.open(LoginDialogComponentComponent, {
      autoFocus: true,
      disableClose: true,
      backdropClass: 'backdropBackground',
      minWidth: '300px',
      maxWidth: '500px',
      width: '50%',
      ...(this.tempPW && { data: { tempPW: this.tempPW, username: this.username } }),
    });

    const subs = [
      this.dialogRef.componentInstance.loginSubmitted.subscribe(({ username, password }) => {
        this.login(username.trim(), password.trim());
      }),

      this.dialogRef.componentInstance.passwordResetSubmitted.subscribe(({ username, oldPassword, newPassword }) => {
        this.resetPassword(username.trim(), oldPassword.trim(), newPassword.trim());
      }),

      this.dialogRef.componentInstance.forgotPasswordSubmitted.subscribe(({ email }) => {
        this.forgotPassword(email.trim());
        this.dialogRef.componentInstance.isForgotPasswordEnabled = false;
      }),

      // Dialog for logging in user and kicking out other sessions
      this.dialogRef.componentInstance.enableSessionResponded.subscribe(async (result) => {
        localStorage.removeItem('mmBlockedAuth$');

        // User can only have one session at a time
        // Alert sub with deviceKey
        if (result && this.numOfDevices > 0) {
          this.cognitoService
            .forgetDevices(this.deviceKeys)
            .pipe(take(1))
            .subscribe(
              (result) => {
                // console.log('Forget device result: ', result);
              },
              (error) => {
                // console.log('There was an error forgetting devices. ', error);
              }
            );

          this.userService
            .notifyUserDeviceBeforeLogOut(this.deviceKeys[0])
            .pipe(take(1))
            .subscribe(
              (result) => {
                // console.log('Notify users with subscription and device key result: ', result);
              },
              (error) => {
                // console.log('Error notifying users with subscription and device key: ', error);
              }
            );

          // User said cancel, don't allow sign in and clear session
        } else {
          // console.log('User said cancel, don\'t allow sign in and clear session');
          this.dialogRef.componentInstance.isNotifyMultipleSessionsEnabled = false;
          this.userService.logout();
        }

        if (result) {
          this.setDeviceInfoForUser().then(() => {
            // console.log('****1 not the issue setDeviceInfoForUser returned', this.userService.urlPath || '/requisitions');

            this.dialogRef.componentInstance.isNotifyMultipleSessionsEnabled = false;
            this.dialogRef.close();
            if (this.userService.urlPath === '/warning/logout') {
              this.userService.urlPath = this.navigationService.getDefaultRoute();
            }
            this.router
              .navigateByUrl(this.userService.urlPath ?? this.navigationService.getDefaultRoute())
              .then(() => {
                this.userService.urlPath = undefined;
                console.log('Clearing redirect URL.');
              })
              .catch((e) => console.error(e));
          });
        }
      }),
    ];

    this.dialogRef.afterClosed().subscribe(() => {
      subs.forEach((sub) => sub.unsubscribe());
    });
  }

  resetPassword(username = '', oldPassword = '', newPassword = '') {
    this.userService.completeNewPasswordChallenge(username.trim(), oldPassword.trim(), newPassword.trim()).subscribe(
      (result: any) => {
        // console.log('LoginComponent: resetPassword authenticate result: ' + JSON.stringify(result));
        this.dialogRef.componentInstance.isResetRequired = false;
        this.login(username.trim(), newPassword.trim());
        // this.dialogRef.close();
        // this.router.navigate(['requisitions']).catch((e) => console.error(e));
        this.updateUserIsActivatedStatus(username.trim());
      },
      (error) => {
        // console.log('LoginComponent: resetPassword authenticate result: ' + error.message);
        this.dialogRef.componentInstance.setErrorMessage('Unable to reset password');
      }
    );
  }

  async updateUserIsActivatedStatus(username: string) {
    // after first time pw change/setup, getUsersActivated will update user db record to isActivated: true
    const { attributes } = await this.userService.getAuthenticatedUserInfo().pipe(take(1)).toPromise();
    const userId = attributes['custom:userId'];
    if (userId) {
      await this.userService
        .getUsersActivatedStatus([{ username: username, userId: userId }])
        .pipe(take(1))
        .toPromise();
    }
  }

  forgotPassword(email = '') {
    this.userService.forgotPassword(email.trim().toLowerCase()).subscribe(
      (result: any) => {
        // console.log('LoginComponent: forgotPassword authenticate result: ' + JSON.stringify(result));
      },
      (error) => {
        // console.log('LoginComponent: forgotPassword authenticate result: ' + error.message);
        this.dialogRef.componentInstance.setErrorMessage('Unable to trigger forgot password');
      }
    );
  }

  initPendoAndStripeCheck() {
    return new Promise((resolve, reject) => {
      console.log('initPendoAndStripeCheck this.userService.tenantId', this.userService.tenantId);

      this.stripeService
        .getUpdatedTenantStripeSub(this.userService.tenantId)
        .pipe(take(1))
        .subscribe(
          (sub) => {
            if (sub?.data?.getUpdatedTenantStripeSub) {
              this.stripeSubscriptionId = sub.data.getUpdatedTenantStripeSub.subscriptionId;
              let tempStripeSub = sub.data.getUpdatedTenantStripeSub;
              if (
                tempStripeSub.status == 'active' ||
                tempStripeSub.status == 'past_due' ||
                tempStripeSub.status == 'trialing'
              ) {
                this.subscriptionStatusActiveOrPastDue = true;
              } else {
                this.subscriptionStatusActiveOrPastDue = false;
              }
            }

            // If strip is OK - keep moving on....
            if (!sub?.data?.getUpdatedTenantStripeSub || this.subscriptionStatusActiveOrPastDue) {
              this.stripeService.isAuthenticated.next(true);

              this.userService
                .getTenantInfo(this.userService.tenantId)
                .pipe(take(1))
                .subscribe(
                  async (tenantResult) => {
                    if (typeof pendo !== 'undefined') {
                      const selfSignup = tenantResult.data.getTenant.selfSignup ?? false;
                      const { tier } = await this.permissionsService
                        .getUserPermissionsRecord(this.userService.tenantId, this.userService.userId)
                        .pipe(take(1))
                        .toPromise();
                      const { accountStatus, monthlySubscriptionSum } = await this.selfSignupService
                        .getTenantSelfServeRecord(this.userService.tenantId)
                        .pipe(take(1))
                        .toPromise();

                      let linkedIn = '';

                      if (this.userService.userInfo?.links)
                        for (let x = 0; x < this.userService.userInfo?.links.length; x++) {
                          // console.log('this.userService.userInfo?.links[x]', this.userService.userInfo?.links[x]);

                          if (this.userService.userInfo.links[x].type === 'LinkedIn') {
                            linkedIn = this.userService.userInfo?.links[x].url;
                          }
                        }

                      // Set user info for pendo
                      pendo.initialize({
                        visitor: {
                          id: this.userService.userId, //'VISITOR-UNIQUE-ID', // Required if user is logged in
                          email: this.userService.userInfo?.username ? this.userService.userInfo?.username : 'NA', // Recommended if using Pendo Feedback, or NPS Email
                          full_name: `${this.userService.userInfo?.firstName} ${this.userService.userInfo?.lastName}`, // Recommended if using Pendo Feedback
                          firstName: this.userService.userInfo?.firstName,
                          lastName: this.userService.userInfo?.lastName,
                          userGroups: this.userService.userGroups,
                          nmlsId: this.userService.userInfo?.nmlsId,
                          title: this.userService.userInfo?.title,
                          address: `${this.userService.userInfo?.address?.street} ${this.userService.userInfo?.address?.city} ${this.userService.userInfo?.address?.state} ${this.userService.userInfo?.address?.zipCode}`,
                          agentPhone: this.userService.userInfo?.phones[0]?.number,
                          linkedinURL: linkedIn,
                          userRoles: this.userService.userGroups?.toString(),
                          // You can add any additional visitor level key-values here,
                          // as long as it's not one of the above reserved names.
                          ...(selfSignup && { permissionTier: tier }),
                        },
                        account: {
                          id: this.userService.tenantId, // 'ACCOUNT-UNIQUE-ID', // Highly recommended
                          // name:         // Optional
                          // is_paying:    // Recommended if using Pendo Feedback
                          // monthly_value:// Recommended if using Pendo Feedback
                          // planLevel:    // Optional
                          // planPrice:    // Optional
                          // creationDate: // Optional
                          // You can add any additional account level key-values here,
                          // as long as it's not one of the above reserved names.
                          selfSignup,
                          ...(selfSignup && { accountStatus, monthlySubscriptionSum }),
                          tenantId: this.userService.tenantId,
                          tenant: tenantResult.data.getTenant.title,
                        },
                      });
                    }

                    resolve(true);
                  },
                  (error) => {
                    resolve(true);
                  }
                );
            } else {
              this.subscriptionStatusActiveOrPastDue = false;
              if (this.userService.userGroups.includes(BILLING_USER_GROUP)) {
                this.stripeService
                  .getUnpaidInvoiceUrl(this.stripeSubscriptionId)
                  .pipe(take(1))
                  .subscribe(
                    (res) => {
                      let unapidSubscriptionUrl = res.data.getUnpaidInvoiceUrl;
                      if (unapidSubscriptionUrl) {
                        this.dialogRef.componentInstance.showUnpaidSubscriptionDialog(unapidSubscriptionUrl);
                      }
                    },
                    (err) => {
                      // console.log(err);
                      this.userService.logout();
                      reject(false);
                    }
                  );
              }
              this.userService.logout();
              this.snackbar.open('Unable to access account due to past due balance', null, {
                duration: 4000,
                panelClass: 'error-snack-bar',
              });
              reject(false);
            }
          },
          (error) => {
            this.userService.logout();
            // this.openDialog();
            this.snackbar.open("Model Match subscription does not exist for this users' tenant", null, {
              duration: 4000,
              panelClass: 'error-snack-bar',
            });
            reject(false);
          }
        );
    });
  }

  /**
   * Get user's device key.
   * Not avail if user has not been enabled with deviceRemembering.
   * @returns deviceKey
   */
  getCurrentDeviceKey() {
    return new Promise((resolve, reject) => {
      this.cognitoService
        .getAccessToken()
        .pipe(take(1))
        .subscribe(
          (result) => {
            let accessToken = result.accessToken;
            let token = JSON.parse(atob(accessToken.split('.')[1]));

            if (token.device_key) {
              resolve(token.device_key);
            } else {
              resolve(false);
            }
          },
          (error) => {
            // console.log('Error retrieving access token.', error);
            reject(error);
          }
        );
    });
  }

  /**
   * Retrieves user's refresh token from session
   * @returns refreshToken string
   */
  getRefreshToken() {
    return new Promise((resolve, reject) => {
      this.cognitoService
        .getRefreshToken()
        .pipe(take(1))
        .subscribe(
          (result: any) => {
            if (result.refreshToken.token) {
              resolve(result.refreshToken.token);
            } else {
              resolve(false);
            }
          },
          (error) => {
            // console.log('There was an error in retrieving the refresh token ', error);
            reject(error);
          }
        );
    });
  }

  /**
   * Sets the device info for user in DB after authenticating
   */
  async setDeviceInfoForUser() {
    // console.log('Setting device info for user');
    let deviceKey = await this.getCurrentDeviceKey();
    let refreshToken = await this.getRefreshToken();
    let accessTokenResponse = await this.cognitoService.getAccessToken().toPromise();
    let accessToken = accessTokenResponse.accessToken;

    this.cognitoService
      .listDevices()
      .pipe(take(1))
      .subscribe(
        (result) => {
          let myDevice = result.find((item) => item.deviceKey === deviceKey);

          if (myDevice) {
            myDevice['refreshToken'] = refreshToken;
            myDevice['accessToken'] = accessToken;
            // console.log('Device doc for authenticated user ', myDevice);

            this.userService
              .setUserDeviceInfo(myDevice)
              .pipe(take(1))
              .subscribe(
                (result) => {},
                (error) => {
                  // console.log('Error in setting user device info.', error);
                }
              );
          }
        },
        (error) => {
          // console.log('There was an error listing devices for the user. ', error);
        }
      );
  }

  /**
   * Sends notification to users subscribed with userName in updatedUsernameLogIn subs.
   * Used to migrate existing users to new cognito system.
   * Occurs only once for users to invalidate all their sessions and displays warning page.
   * Adds to user doc deviceRemembered field.
   * Code can be removed in a couple months as users will be fully integrated.
   * @param username
   * @param password
   * @returns true
   */
  isDeviceRemembered(username, password) {
    return new Promise((resolve, reject) => {
      this.userService
        .getUserInfo(this.userService.userId)
        .pipe(take(1))
        .subscribe(
          (result) => {
            if (!result.data.getUserInfo.deviceRemembered || result.data.getUserInfo.deviceRemembered === false) {
              // console.log('Device not remembered.');

              this.cognitoService
                .listDevices()
                .pipe(take(1))
                .subscribe(
                  (result) => {
                    // List devices and forget devices, if any
                    let deviceKeys = result.map((item) => item.deviceKey);

                    this.cognitoService
                      .forgetDevices(deviceKeys)
                      .pipe(take(1))
                      .subscribe(
                        (result) => {
                          // console.log('Forget device result: ', result);
                          this.userService
                            .getAuthenticatedUserInfo()
                            .pipe(
                              switchMap((result) => {
                                return this.userService.enableDeviceRememberingForOldUser(result.attributes.email);
                              })
                            )
                            .subscribe(
                              (result) => {
                                // After globally signing out all users for first migration to new default sessions
                                // Log in with first deviceKey in token
                                // console.log('enableDeviceRememberingForOldUser result: ', result);
                                this.login(username, password);
                                resolve(true);
                              },
                              (error) => {
                                // console.log('enableDeviceRememberingForOldUser error: ', error);
                                reject(error);
                              }
                            );
                        },
                        (error) => {
                          // console.log('There was an error forgetting devices. ', error);
                        }
                      );
                  },
                  (error) => {
                    // console.log('There was an error retrieving devices for this user.', error);
                  }
                );
            } else {
              // console.log('Device is remembered.');
              resolve(true);
            }
          },
          (error) => {
            // console.log('Error in isDeviceRemembered', error);
            reject(error);
          }
        );
    });
  }

  ngOnDestroy() {
    // console.log('destroy login component');
    if (this.isAuthenticatedSubscription) {
      this.isAuthenticatedSubscription.unsubscribe();
    }
  }
}
