/* eslint-disable rxjs/no-nested-subscribe */
import { Component, Inject, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ReportingService } from '../../../services/reporting.service';
import { UserService } from '../../../services/user.service';
import { IntegrationsService } from '../../../services/integrations.service';
import { TodoService } from '../../../services/todo.service';
import { HistoryInput, ActivityType } from '../../../shared/model/activity';
import { differenceInSeconds, parseJSON } from 'date-fns';
import { ContactService } from 'app/services/contact.service';
import { AlertDialogComponent, AlertDialogConfig } from 'app/shared/dialogs/alert-dialog.component';
import { Permissions, PermissionsService } from 'app/services/permissions.service';
import { UpdateReqContactWithProductionGQL } from 'graphql.types';
@Component({
  selector: 'mm-export-te-dialog',
  templateUrl: './export-te-dialog.component.html',
  styleUrls: ['./export-te-dialog.component.scss'],
})
export class ExportTotalExpertDialogComponent implements OnInit, OnDestroy {
  userIA: Permissions;
  exportLog: string[] = [];
  private unsubscribe$ = new Subject<void>();
  selectedCount: number = 0;
  processedCounter: number = 0;
  percentProcessed: number = 0;
  duplicateCounter: number = 0;
  errorCounter: number = 0;
  cancelRequest: boolean = false;
  actionInProgress: string = '';
  completed: boolean = false;
  iAPIOAuthKeyStored: any;
  loadingExportContacts: boolean = false;
  integrationVerified: boolean = true;

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ExportTotalExpertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private reportingService: ReportingService,
    private integrationsService: IntegrationsService,
    private todoService: TodoService,
    private contactService: ContactService,
    private permissionsService: PermissionsService,
    private updateReqContactWithProductionGQL: UpdateReqContactWithProductionGQL
  ) {}

  ngOnInit() {
    this.logMessage('Started');

    this.getPermissions();
    this.selectedCount = this.data.selectedRows.length;

    this.loadToken(
      'TOTALEXPERT',
      function () {
        this.loadFromArray();
      }.bind(this)
    );
  }

  initAfterPermissions() {
    let doNada = 0;
  }

  onCloseClick() {
    if (this.selectedCount > this.processedCounter) {
      let dialogConfig: AlertDialogConfig = {
        title: 'There are still contact exports processing?',
        message: 'This will stop importing any that are left to process',
        extraMessage: 'Are you sure you want to stop now?',
        confirmButton: {
          label: 'Yes',
          color: 'primary',
        },
        cancelButton: {
          label: "Don't Stop me Now",
          color: 'accent',
        },
      };

      let dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, string>(AlertDialogComponent, {
        panelClass: 'alert-dialog-component',
        data: dialogConfig,
        autoFocus: false,
        width: '35%',
      });

      dialogRef.afterClosed().subscribe((confirmation) => {
        if (confirmation) {
          this.cancelRequest = true;
          this.dialogRef.close({ data: this.exportLog });
        }
      });
    } else {
      this.cancelRequest = true;
      this.dialogRef.close({ data: this.exportLog });
    }
  }

  getPermissions() {
    this.permissionsService.permissions$.pipe(takeUntil(this.unsubscribe$)).subscribe((userIA) => {
      // console.log('permissions updated', userIA);
      this.userIA = userIA;
      this.initAfterPermissions();
    });
  }

  loadFromArray() {
    this.loadingExportContacts = true;

    this.logMessage('Retrieving Selected Model Match Contact Data');

    console.log('selectedRows', this.data.selectedRows);

    this.contactService
      .getMyContactsWithReqs(this.data.selectedRows.map((row) => row?.split('|')[1]))
      .pipe(
        take(1),
        map((results) => results.data.getMyContacts)
      )
      .subscribe(
        (contactsWithReqs) => {
          // console.log(contactsWithReqs);
          // this.exportLoop([...contactsWithReqs]);

          this.selectedCount = contactsWithReqs.length;

          this.exportLoop([...contactsWithReqs]);

          this.loadingExportContacts = false;
        },
        (error: unknown) => {
          this.loadingExportContacts = false;
          console.error('ContactsComponent: error getting contacts with reqs for export: ', error);
        }
      );
  }

  async exportLoop(contactsWithReqs) {
    this.logMessage('Sending to Total Expert Now!');

    this.cancelRequest = false;
    this.completed = false;
    this.actionInProgress = 'processing';

    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    if (contactsWithReqs)
      for (let x = 0; x < contactsWithReqs.length; x++) {
        console.log(contactsWithReqs[x]);

        this.actionInProgress = 'Getting next contact info';

        if (contactsWithReqs.length > 0) this.percentProcessed = ((x + 1) / contactsWithReqs.length) * 100;

        this.actionInProgress = 'Getting latest Production';

        this.exportLog.unshift(
          `${contactsWithReqs[x].firstName} ${contactsWithReqs[x].lastName} Getting latest Production data`
        );

        // For TE - force get latest volume on each send
        let updatedContact = await this.updateReqContactWithProductionGQL
          .mutate({
            reqId: contactsWithReqs[x].reqs[0]?.id,
            contactId: contactsWithReqs[x].id,
          })
          .pipe(take(1))
          .toPromise()
          .then(
            (res) => {
              console.log('updateReqContactWithProduction', res);

              const updatedReqContact = structuredClone(res.data.updateReqContactWithProduction);

              return updatedReqContact;
            },
            (err) => {
              console.log('updateReqContactWithProduction error -> ', err);
            }
          );

        this.actionInProgress = 'sending to Total Expert';

        await this.sendContactToTotalExpertOauth(updatedContact);

        this.processedCounter++;

        if (this.cancelRequest) break;
        await sleep(400);
      }

    this.logMessage('Complete!');

    this.completed = true;
  }

  async sendContactToTotalExpertOauth(contact) {
    // if (!this.iAPIOAuthKeyStored)
    //   return;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.iAPIOAuthKeyStored?.access_token}`,
    };

    const url = 'https://public.totalexpert.net/v1/contact-recruits';

    // {
    //     "id": "CONTACT$a1aa1140-cc12-48f0-aee8-fea04fba8b8a",
    //     "createdAt": "2022-05-31T14:14:58.364Z",
    //     "lastUpdatedAt": "2022-05-31T14:14:58.364Z",
    //     "firstName": "Gregory",
    //     "lastName": "Parker",
    //     "company": "Prosperity Home Mortgage, LLC",
    //     "address": {
    //         "street": "1299 Prospect St Suite 201",
    //         "street2": null,
    //         "city": "La Jolla",
    //         "state": "CA",
    //         "zipCode": "92037",
    //         "__typename": "Address"
    //     },
    //     "nmlsId": "662132",
    //     "emails": [],
    //     "reqs": [
    //         {
    //             "id": "REQ$9e458b4d-bcbb-44b4-8d14-b5b642743d38",
    //             "title": "CI - CD Pipeline Needs",
    //             "stage": "prospects",
    //             "__typename": "Requisition"
    //         }
    //     ],
    //     "phones": [
    //         {
    //             "type": "mobile",
    //             "number": "858-353-0449",
    //             "__typename": "Phone"
    //         },
    //         {
    //             "type": "office",
    //             "number": "858-551-3326",
    //             "__typename": "Phone"
    //         }
    //     ],
    //     "links": [
    //         {
    //             "type": "Company",
    //             "url": "https://gregparker.phmloans.com/",
    //             "__typename": "Link"
    //         },
    //         {
    //             "type": "Facebook",
    //             "url": "https://www.facebook.com/greg-parker-prosperity-home-mortgage-llc-nmlsr-id-662132-107370260688402/",
    //             "__typename": "Link"
    //         },
    //         {
    //             "type": "LinkedIn",
    //             "url": "https://www.linkedin.com/in/greg-parker-5b901b69/",
    //             "__typename": "Link"
    //         }
    //     ],
    //     "__typename": "UserContact"
    // }

    const requestBody: any = {
      source: 'ModelMatch',
      custom: [
        {
          field_name: '12_month_volume',
          value: contact.trailing12Volume,
        },
        {
          field_name: '12_month_units',
          value: contact.trailing12Units,
        },
        {
          field_name: 'product_mix_conventional',
          value: contact.product_mix_conventional ? contact.product_mix_conventional + '%' : null,
        },
        {
          field_name: 'product_mix_fha',
          value: contact.product_mix_fha ? contact.product_mix_fha + '%' : null,
        },
        {
          field_name: 'product_mix_government',
          value: contact.product_mix_government ? contact.product_mix_government + '%' : null,
        },
        {
          field_name: 'product_mix_jumbo',
          value: contact.product_mix_jumbo ? contact.product_mix_jumbo + '%' : null,
        },
        {
          field_name: 'product_mix_usda',
          value: contact.product_mix_usda ? contact.product_mix_usda + '%' : null,
        },
        {
          field_name: 'product_mix_va',
          value: contact.product_mix_va ? contact.product_mix_va + '%' : null,
        },
        {
          field_name: 'purpose_mix_purchase',
          value: contact.purpose_mix_purchase ? contact.purpose_mix_purchase + '%' : null,
        },
        {
          field_name: 'purpose_mix_refinance',
          value: contact.purpose_mix_refinance ? contact.purpose_mix_refinance + '%' : null,
        },
      ],
    };

    if (contact.title) {
      requestBody.title = contact.title;
    }

    if (contact.firstName) {
      requestBody.first_name = contact.firstName;
    }

    if (contact.lastName) {
      requestBody.last_name = contact.lastName;
    }

    if (contact.phones?.[0]?.number) {
      requestBody.phone_cell = contact.phones?.[0]?.number;
    }

    if (contact.phones?.[1]?.number) {
      requestBody.phone_office = contact.phones?.[1]?.number;
    }

    if (contact.emails?.[0]?.address) {
      requestBody.email = contact.emails?.[0]?.address;
    }

    if (contact.emails?.[1]?.address) {
      requestBody.email_work = contact.emails?.[1]?.address;
    }

    if (contact.address?.street) {
      requestBody.address = contact.address?.street;
    }

    if (contact.address?.street2) {
      requestBody.address_2 = contact.address?.street2;
    }

    if (contact.address?.city) {
      requestBody.city = contact.address?.city;
    }

    if (contact.address?.state) {
      requestBody.state = contact.address?.state;
    }

    if (contact.address?.zipCode) {
      requestBody.zip_code = contact.address?.zipCode;
    }

    if (contact.nmlsId) {
      requestBody.license_number = contact.nmlsId;

      requestBody.custom.push({
        field_name: 'modelmatch_contact_url',
        value: `${window.location.protocol}//${window.location.hostname}/#/contacts/${contact.contactId}/${contact.reqId}`,
      });
    }

    if (contact.company) {
      requestBody.employer_name = contact.company;
    }

    if (contact.agentId) {
      requestBody.external_id = contact.agentId;

      requestBody.custom.push({
        field_name: 'modelmatch_contact_url',
        value: `${window.location.protocol}//${window.location.hostname}/#/contacts/${contact.contactId}/${contact.reqId}`,
      });
    } else {
      requestBody.external_id = contact.id;
    }

    // 12_month_volume
    // 12_month_units
    // product_mix_conventional
    // product_mix_fha
    // product_mix_government
    // product_mix_jumbo
    // product_mix_usda
    // product_mix_va
    // purpose_mix_purchase
    // purpose_mix_refinance

    console.log('Total Expert requestBody', requestBody, this.data.reqId);

    // return;

    await this.integrationsService
      .proxyPost(url, JSON.stringify(headers), JSON.stringify(requestBody), true)
      .pipe(take(1))
      .toPromise()
      .then((result) => {
        console.log(result);
        let parsedResult = JSON.parse(result.data.proxyPost);
        console.log(parsedResult);

        if (parsedResult.error) {
          this.errorCounter++;
          this.exportLog.unshift(
            `${contact.firstName} ${contact.lastName} Skipped - There was an error sending contact to TotalExpert ${parsedResult.error}`
          );

          this.snackBar.open(`Error while exporting Contact. ${parsedResult.error}`, null, {
            duration: 3000,
          });
        } else {
          if (result.data.proxyPost.includes('duplicate')) {
            this.duplicateCounter++;

            this.exportLog.unshift(
              `${contact.firstName} ${contact.lastName} Updated - Contact found already on Total Expert`
            );
          } else {
            this.exportLog.unshift(`${contact.firstName} ${contact.lastName} processed`);

            // Why does analytics not like dataBack as raw array instead of in object?
            // Entry fails without error if pass array and not object
            this.dispoTotalExpertEvent(requestBody, { dataBack: parsedResult }, 'Total Expert Contact Added', contact);
          }
        }
      })
      .catch((err) => {
        this.errorCounter++;
        this.exportLog.unshift(
          `${contact.firstName} ${contact.lastName} Skipped - There was an error sending contact to TotalExpert ${err}`
        );

        console.log('connectTotalExpert: There was an error sending contact to TotalExpert', err);
      });
  }

  loadToken(appName, callback) {
    this.logMessage('Validating Total Expert Integration Keys');

    this.integrationsService
      .getCurrentOAuthToken(appName, true)
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log(result);
          this.iAPIOAuthKeyStored = {
            access_token: result.data.getCurrentOAuthToken,
          };

          this.integrationVerified = true;

          if (callback) callback();
        },
        (error) => {
          this.integrationVerified = false;

          this.logMessage('error');

          if (callback) callback();

          this.snackBar.open(error, null, {
            duration: 6000,
            panelClass: 'error-snack-bar',
          });

          console.log('System: There was an error reading token ', error);
        }
      );
  }

  getCurrentOAuthToken(iName, basicAuth = false) {
    let token = '';

    if (!iName) {
      throw new Error('getCurrentOAuthToken requires a non-null `iName` parameter');
    }

    // Get client info - fail if no client info found
    this.integrationsService
      .readSystemKey(`OAUTH|${iName}`)
      .pipe(take(1))
      .subscribe(
        (result) => {
          const resClient = result.data.readSystemKey;

          // Fail if client info not found
          if (!resClient.sysKey) {
            console.log('No sysKey');
            return;
          }

          const sysKey = JSON.parse(resClient.sysKey);

          console.log('sysKey', sysKey);

          // Fail if credentials not found
          if (!sysKey.clientId || !sysKey.clientSecret) {
            console.log(`${iName} does not have a valid client credentials associated with it`);
            return;
          }

          // Look for existing token
          this.integrationsService
            .readIntegrationKey(`${iName}_OAUTH_TOKEN`)
            .pipe(take(1))
            .subscribe(
              (result) => {
                const resToken = result.data.readIntegrationKey;

                // Fail if token info not found
                if (!resToken.apiKey) {
                  console.log(`${iName} does not have a valid token associated with it`);
                  return;
                }

                const apiKey = JSON.parse(resToken.apiKey);

                console.log('apiKey', apiKey);

                const now = new Date();
                const diff = differenceInSeconds(parseJSON(apiKey.expires), now);

                console.log('diff', diff);

                // Check for expiration
                if (isNaN(diff)) {
                  console.log(`${iName} does not have a valid token expiration associated with it`);
                  return;
                }

                // Check key to see if needs refresh
                if (diff < 10000) {
                  // If token is older than expiration date get refresh
                  // Need to store token URL in resClient

                  const tokenRequest: any = {
                    grant_type: 'refresh_token',
                    refresh_token: apiKey.refresh_token,
                  };

                  console.log('tokenRequest', tokenRequest);

                  const headers: any = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  };

                  if (basicAuth) {
                    headers.Authorization =
                      'Basic ' + Buffer.from(sysKey.clientId + ':' + sysKey.clientSecret).toString('base64');
                  } else {
                    tokenRequest.client_id = sysKey.clientId;
                    tokenRequest.client_secret = sysKey.clientSecret;
                  }

                  console.log('tokenRequest', tokenRequest);
                  console.log('headers', headers);

                  const url = sysKey.authURL;

                  this.integrationsService
                    .proxyPost(url, JSON.stringify(headers), JSON.stringify(tokenRequest), true)
                    .pipe(take(1))
                    .subscribe(
                      (result) => {
                        console.log(result);

                        const refreshRes = JSON.parse(result.data.proxyPost);

                        console.log('refreshRes', refreshRes);

                        let parsedResult = refreshRes ? refreshRes : {};

                        // Fail if token info not found
                        if (!parsedResult.refresh_token) {
                          console.log(`${iName} refresh token failed`);
                          return;
                        }

                        let now = new Date();

                        const oAuthToken = {
                          created: now,
                          expires: new Date(now.getTime() + (parsedResult.expires_in * 1000) / 2),
                          token_type: parsedResult.token_type,
                          expires_in: parsedResult.expires_in,
                          access_token: parsedResult.access_token,
                          refresh_token: parsedResult.refresh_token,
                          grant_type: 'authorization_code',
                          client_id: sysKey.clientId,
                          client_secret: sysKey.clientSecret,
                          redirect_uri: resToken.redirect_uri,
                        };

                        this.integrationsService
                          .setIntegrationKey(`${iName}_OAUTH_TOKEN`, JSON.stringify(oAuthToken))
                          .pipe(take(1))
                          .subscribe(
                            (result) => {
                              console.log('updated refreshed token', result);
                            },
                            (error) => {
                              console.log('System: There was an error reading token ', error);
                            }
                          );

                        // Check if setRes fails...

                        token = oAuthToken.access_token;

                        console.log('token refreshed', token);
                      },
                      (error) => {
                        console.log('System: There was an error reading token ', error);
                      }
                    );
                } else {
                  token = apiKey.access_token;

                  console.log('token exists and is not expired', token);
                }
              },
              (error) => {
                console.log('readSystemKey: There was an error reading key ', error);
              }
            );
        },
        (error) => {
          console.log('readSystemKey: There was an error reading key ', error);
        }
      );
  }

  tokenLoadComplete() {
    console.log('token loaded');
  }

  async dispoTotalExpertEvent(dataIn, dataBack, note, contact) {
    let input: HistoryInput = {
      type: ActivityType.TotalExpert,
      note: note,
      contactId: contact.contactId,
      reqId: this.data.reqId,
      isPublic: true,
      analytics: JSON.stringify({
        dataIn: dataIn,
        created: dataBack.dataBack.created ? dataBack.dataBack.created : 'error',
        teid: dataBack.dataBack.id ? dataBack.dataBack.id : 'error',
        contactName: `${contact.firstName} ${contact.lastName}`,
        sourceLink: window.location.href,
      }),
    };

    await this.todoService
      .addHistory(input)
      .pipe(take(1))
      .toPromise()
      .then((result) => {
        // Add TotalExpert history and keep contact's lastUpdatedAt field in sync with history
        // this.contactService
        //   .updateContactLastUpdatedAtField(contact.id, contact.reqId, 'true')
        //   .pipe(take(1))
        //   .subscribe(
        //     (result) => {},
        //     (error) => {
        //       console.log(
        //         'An error occurred updated contact fields after sending sending TotalExpert.',
        //         error
        //       );
        //     }
        //   );
      })
      .catch((err) => {
        this.snackBar.open('An error occurred adding the TotalExpert history.', null, {
          duration: 3000,
          panelClass: 'error-snack-bar',
        });
      });
  }

  logMessage(message) {
    this.exportLog.unshift(' ');
    this.exportLog.unshift('***');
    this.exportLog.unshift(message);
    this.exportLog.unshift('***');
    this.exportLog.unshift(' ');
  }

  ngOnDestroy() {
    this.cancelRequest = true;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
