/* eslint-disable rxjs/no-nested-subscribe */
import { Component, Inject, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ReportingService } from '../../../services/reporting.service';
import { UserService } from '../../../services/user.service';
import { IntegrationsService } from '../../../services/integrations.service';
import { TodoService } from '../../../services/todo.service';
import { HistoryInput, ActivityType } from '../../../shared/model/activity';
import { differenceInSeconds, parseJSON } from 'date-fns';
import { ContactService } from 'app/services/contact.service';
import { RequisitionService } from '../../../services/requisition.service';
import { AlertDialogComponent, AlertDialogConfig } from 'app/shared/dialogs/alert-dialog.component';
import { Permissions, PermissionsService } from 'app/services/permissions.service';

@Component({
  selector: 'mm-update-agent-dialog',
  templateUrl: './update-agent-dialog.component.html',
  styleUrls: ['./update-agent-dialog.component.scss'],
})
export class UpdateAgentDialogComponent implements OnInit, OnDestroy {
  userIA: Permissions;
  private unsubscribe$ = new Subject<void>();
  exportLog: any = {};

  updateAgentForm: FormGroup;

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<UpdateAgentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contactService: ContactService,
    private reqService: RequisitionService,
    private permissionsService: PermissionsService,
    private formBuilder: FormBuilder,
    private integrationsService: IntegrationsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getPermissions();

    this.updateAgentForm = this.formBuilder.group({
      agt_name: [this.data.agent.name, null],
      agt_email: [this.data.agent.agt_email, null],
      agt_phone: [this.data.agent.agt_phone, null],
      off_phone: [this.data.agent.off_phone, null],
      title: [this.data.agent.title, null],
      address: [this.data.agent.address, null],
      company: [this.data.agent.company, null],
    });
  }

  initAfterPermissions() {
    let doNada = 0;
  }

  onSave() {
    // compare info from existing data - Are you Sure?!?

    let agentInput: any = {
      id: this.data.agent.id,
      company: this.updateAgentForm.get('company')?.value,
      agt_name: this.updateAgentForm.get('agt_name')?.value,
      agt_email: this.updateAgentForm.get('agt_email')?.value,
      agt_phone: this.updateAgentForm.get('agt_phone')?.value,
      off_phone: this.updateAgentForm.get('off_phone')?.value,
      address: this.updateAgentForm.get('address')?.value,
      title: this.updateAgentForm.get('title')?.value,
    };

    console.log(agentInput);

    this.integrationsService
      .updateAgent(agentInput)
      .pipe(take(1))
      .subscribe(
        (res) => {
          console.log(res.data.updateAgent);

          this.snackBar.open('Agent info Saved', '', {
            duration: 3000,
          });

          this.dialogRef.close({ agent: agentInput, updated: true });
        },
        (error) => {
          console.log('updateAgent error: ', error);

          this.snackBar.open('ERROR - Agent info not saved', JSON.stringify(error), {
            duration: 3000,
          });
        }
      );
  }

  onCloseClick() {
    this.dialogRef.close({ agent: {}, updated: false });
  }

  getPermissions() {
    this.permissionsService.permissions$.pipe(takeUntil(this.unsubscribe$)).subscribe((userIA) => {
      // console.log('permissions updated', userIA);
      this.userIA = userIA;
      this.initAfterPermissions();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
