import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mm-confirm-update-contacts-dialog',
  templateUrl: './confirm-update-contacts-dialog.component.html',
})
export class ConfirmUpdateDialog {
  contactsWithAssignee: any = [];
  assignee: any;
  message: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmUpdateDialog>
  ) {}

  ngOnInit() {
    this.contactsWithAssignee = this.data.contactsWithAssignee;
    this.assignee = this.data.assignee;
    this.message = this.data.message;
  }

  onConfirmUpdate() {
    this.dialogRef.close(true);
  }

  ngOnDestroy() {}
}
