import { Component, Input, NgModule } from '@angular/core';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule, NgIf } from '@angular/common';
import { ModelMatchButtonModule } from 'app/components/betterButton.component';
import { ModelMatchIconModule } from 'app/components/betterIcon.component';
// import { ModelMatchButtonModule } from 'app/components/betterButton.component';

@Component({
  selector: 'mm-context-menu',
  template: `
    <button mm-button *ngIf="!unstyled; else Unstyled" [size]="buttonSize" [color]="buttonColor"
      hasBackDrop="true"
      [cdkMenuTriggerFor]="context"
      [cdkMenuPosition]="getMenuPosition()"
    >
    
    <div class="flex items-center justify-start w-full space-x-2 text-sm">
        <mm-icons-two *ngIf="prefixIconName" [name]="prefixIconName"></mm-icons-two>
        <p class="whitespace-nowrap " *ngIf="label">{{label}}</p>
        <mm-icons-two *ngIf="iconName" [name]="iconName"></mm-icons-two>
    </div>
    </button>

    <ng-template #Unstyled>
      <p
      class="whitespace-nowrap " *ngIf="label"
      [cdkMenuTriggerFor]="context"
      [cdkMenuPosition]="getMenuPosition()">{{label}}</p>
 
      <mm-icons-two *ngIf="iconName" [name]="iconName"
      size="20"
      strokeWidth="2"
      [cdkMenuTriggerFor]="context"
      [cdkMenuPosition]="getMenuPosition()">
      </mm-icons-two>

    </ng-template>

    <ng-template #context>
      <div cdkMenu>
        <div cdkMenuItem class="menu no-scrollbar overflow-hidden bg-white p-2 rounded-md border border-gray-200 w-auto">
          <ng-content></ng-content>
        </div>
      </div>
    </ng-template>
  `,
  styleUrls: ['../nick_styles/nick.css', './context-menu.component.css'],
})
export class ContextMenuComponent {
  @Input() prefixIconName?: string;
  @Input() iconName?: string;
  @Input() label?: string;
  @Input() horizontalPosition: 'left' | 'right' = 'right';
  @Input() verticalPosition: 'top' | 'bottom' = 'bottom';
  @Input() shiftUp?: number;
  @Input() shiftDown?: number;
  @Input() shiftLeft?: number;
  @Input() shiftRight?: number;
  @Input() buttonSize?: string = 'sm'
  @Input() buttonColor?: string = 'light'
  @Input() unstyled?: boolean = false


  private verticalOffset = 0;
  private horizontalOffset = 0;

  public getMenuPosition(): any[] {
    this.setOffsetValues();
    
    const positionMap = {
      'left': 'end',
      'right': 'start',
      'top': 'bottom',
      'bottom': 'top'
    };

    return [{
      originX: positionMap[this.horizontalPosition],
      originY: positionMap[this.verticalPosition],
      overlayX: positionMap[this.horizontalPosition],
      overlayY: positionMap[this.verticalPosition],
      offsetY: this.verticalOffset,
      offsetX: this.horizontalOffset
    }];
  }

  private setOffsetValues(): void {
    this.verticalOffset = this.shiftUp ? -this.shiftUp : this.shiftDown ?? 0;
    this.horizontalOffset = this.shiftLeft ? -this.shiftLeft : this.shiftRight ?? 0;
  }
}

@NgModule({
  declarations: [ContextMenuComponent],
  imports: [CdkMenuModule, CommonModule, ModelMatchButtonModule, ModelMatchIconModule],
  exports: [ContextMenuComponent, CdkMenuModule],
})
export class ContextMenuModule {}