import { permissionDetails } from './permissionDetails';
import userPermissionDefaults from './userPermissionDefaults/userPermissionDefaults';
import tenantAdminPermissionOverrides from './userPermissionDefaults/tenantAdminPermissionDefaults';
import baseTenantAccessDefaults from './tenantAccessDefaults/baseTenantAccessDefaults';
import mortgageTenantAccessDefaults from './tenantAccessDefaults/mortgageTenantAccessDefaults';
import healthcareTenantAccessDefaults from './tenantAccessDefaults/healthcareTenantAccessDefaults';
import { TenantType } from '../tenantTypes';
import { LookUpKey, PrismLookUpKey, getTierTemplates } from './userPermissionDefaults/pricingTierTemplates';

/* ------------ PERMISSION TYPES --------------- */
/**The root permissions that include all of the major features of Model Match.
 * > ### Moving Permissions!
 * > Moving permissions after they are used in the database will cause desynchronization with the app and the existing permission values will no longer be used.
 * > To move the existing permission values in the database, check `migrations/mm-permissions-move`.
 */
export type Permissions = {
  session: SessionsPermissions;
  cms: CMSPermissions;
  data: DataPermissions;
  integrations: IntegrationsPermissions;
  market_insights: MarketInsightsPermission;
  pulse: PulsePermissions;
  compass: CompassPermissions;
  reporting_analytics: ReportingPermissions;
  sms_chat: SMSPermissions;
  playbooks: PlaybooksPermissions;
  team_chat: TeamChatPermissions;
  debug: DebugPermissions;
  trust_hub: TrustHubPermissions;
};

/*  ---------- Feature Permissions Declarations ---------- */
type SessionsPermissions = PermissionsSetting & {
  multiple_sessions: boolean;
};

type CMSPermissions = PermissionsSetting & {
  create_requisitions: boolean;
  allow_imports: boolean;
  add_to_all_reqs: boolean;
  white_label: boolean;
  marketing_export: boolean;
  todos: boolean;
  contacts: PermissionsSetting & {
    allow_exports: boolean;
    create: boolean;
    delete: boolean;
    limit_contacts: boolean;
    nmls_id: boolean;
    premium_contacts: PermissionsSetting & {
      export: PermissionsSetting & {
        less: boolean;
      };
    };
    click_to_call: boolean;
  };
};

type DataPermissions = PermissionsSetting & {
  pdl_enrichment: boolean;
  loan_originator: PermissionsSetting & {
    raw_loan_transactions: boolean;
  };
  real_estate_agent: PermissionsSetting;
  branch_raw_transactions: boolean;
  company_raw_transactions: boolean;
};

type MarketInsightsPermission = PermissionsSetting & {
  ai_filter: boolean;
  allow_imports_at_stage: boolean;
  allow_bulk_duplicate_imports: boolean;
  view_on_zillow: boolean;
  export_transactions: boolean;
  debug_actions: boolean;
  full_date_ranges: boolean;
};

type IntegrationsPermissions = PermissionsSetting & {
  bomb_bomb: boolean;
  bonzo: boolean;
  microsoft: boolean;
  google: boolean;
  zapier: boolean;
  hubspot: boolean;
  salesforce: boolean;
  total_expert: PermissionsSetting & {
    export: boolean;
  };
};

type ReportingPermissions = PermissionsSetting;

type SMSPermissions = PermissionsSetting & {
  sequence: boolean;
};

type PlaybooksPermissions = PermissionsSetting;

type PulsePermissions = PermissionsSetting & {
  title_company: boolean;
};

type CompassPermissions = PermissionsSetting & {
  title_company: boolean;
};

type TeamChatPermissions = PermissionsSetting;

type DebugPermissions = PermissionsSetting & {
  permissions: boolean;
  tail_logs: boolean;
};

type TrustHubPermissions = PermissionsSetting & {
  sms: boolean
}

/* ------------ PERMISSION SYSTEM TYPES --------------- */
/**The type to use when you are overriding some permissions. Intended for when you only want to specify a few permission keys. */
export type PermissionsOverride = DeepPartial<Permissions>;

export type PermissionsRole = {
  name: string;
  priority: number;
  tenantId: string;
  roleId: string;
  permissions: PermissionsOverride;
};

/**Used in outlining predefined templates that can be used to create new roles for a tenant. */
export type PermissionsRoleTemplate = {
  /**The display name of the template during selection. */
  templateName: string;
  /**The name of the role after it is created. */
  roleName: string;
  permissions: PermissionsOverride;
};

export type TenantPermissionsBreakdown = {
  roles: PermissionsRole[];
  tenantPermissions: TenantPermissionsRecord;
  everyoneRole: PermissionsRole;
  tenantAdmin: PermissionsRole;
};

export type TenantPermissionsRecord = {
  pKey: string;
  rKey: string;
  permissions: PermissionsOverride;
  type: TenantType;
  selfSignup?: boolean;
};

export type UserPermissionsRecord = {
  userId: string;
  permissions: PermissionsOverride;
  roles: string[];
  tier?: string;
};

/** Provides a structure of settings for a permissions page to be shown. See below for docs.
 * @property canEdit: true if this is a user-defined role, false otherwise. - allows user to edit the name displayed at the top of the permission page
 * @property permissionsFallback: The parent to the Permissions type we are passing in. This is what value we should show if we have no explicit value. Example: If we are passing in a user Permission,
 * the fallback should be a merged permission record (using mergePermissionsWithPriority) of any permissions this user is inheriting (roles etc)
 * @property permissionsLock: The merged access permissions originating from the tenant access and code defaults.
 * This locks the permissions that the user should not have access to based on higher priority cascading
 * @property permissionsOutput: a function that accepts a new Permissions object when permissions are changed
 * @property onToggle: a function that uses a key reducer and a value to let you know what key has just changed in the Permissions object
 * */
export type PermissionsPage = {
  name: string;
  rKey: string;
  permissions: Permissions | PermissionsOverride;
  canEdit: boolean;
  permissionsFallback?: Permissions;
  permissionsLock?: PermissionsOverride;
  hideCompanySettingsIcon?: boolean;
  permissionDetails?: DeepPermissionsDetails<Permissions>;
  permissionsOutput?: (permissions: PermissionsOverride) => void;
  onToggle?: (keyReducer: string[], value: boolean) => void;
};

export type PermissionsRoleExport = {
  name: string;
  permissions: PermissionsOverride;
  exported: string;
};

/* ---------- DEFAULT TENANT PERMISSIONS ---------- */
export function getTenantAccessDefaults(): Permissions {
  return JSON.parse(JSON.stringify(baseTenantAccessDefaults));
}

/** Gets the code defaults that we set for specific industries. These are the same types at the tenant types. */
export function getIndustryTenantAccessDefaults(tenantType: TenantType = 'mortgage') {
  const industries: { [K in TenantType]: PermissionsOverride } = {
    mortgage: mortgageTenantAccessDefaults,
    healthcare: healthcareTenantAccessDefaults,
  };

  let selectedIndustryProfile = industries[tenantType];

  if (selectedIndustryProfile === undefined) {
    console.error('Permissions: Can not find industry profile! Using empty profile instead.', tenantType);
    return {};
  }

  // Make sure to sever the tie to the original object so we don't get any weird behavior if we modify it.
  return JSON.parse(JSON.stringify(selectedIndustryProfile));
}

export function getSelfServeAccessDefaults(selfSignup: boolean = false, tierKey?: string) {
  if (!selfSignup || !tierKey) return {};

  return getTierTemplates(tierKey as LookUpKey | PrismLookUpKey);
}

/* ----------- DEFAULT USER PERMISSIONS ----------- */
export function getUserPermissionDefaults() {
  return JSON.parse(JSON.stringify(userPermissionDefaults));
}

/** Specific, code defined, overrides that the tenant admin should have in the permissions system. These are commonly merged with the tenant admin record permissions. */
export function getTenantAdminOverrides() {
  return JSON.parse(JSON.stringify(tenantAdminPermissionOverrides));
}

export function getPermissionDetails() {
  return JSON.parse(JSON.stringify(permissionDetails));
}

/** The base permissions key that every permissions feature inherits from. If the permission doesn't have children, don't inherit from this type. */
type PermissionsSetting = {
  enabled: boolean;
};

/** A helper type to require some information about a specific permission. */
type PermissionDetails = {
  display_name: string;
  description?: string;
  /** Defines whether or not this setting is shown on the company settings page for the tenant admin to control. */
  isCompanySetting?: boolean;
  /** Defines whether or not this setting is for tenant admins specifically. These settings are for controlling the access that the tenant admin has, not controlling what is in their control. */
  isTenantAdminSetting?: boolean;
  /** Flags this setting as experimental. */
  experimental?: boolean;
  icon?: string;
};

/* -------------- RECURSIVE HELPERS -------------- */
/** Helps in making all nested types optional */
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

/**A type to traverse the permissions and resolve a details object for anything that is a boolean. */
export type DeepPermissionsDetails<T> = T extends boolean
  ? PermissionDetails
  : T extends object
  ? { [Key in keyof T]: DeepPermissionsDetails<T[Key]> }
  : T;

/* ------------ PERMISSION FUNCTIONS --------------- */
export function getPermissionValue<T extends Permissions | PermissionsOverride>(
  keyReducer: string[],
  permissions: T
): boolean | undefined {
  const [currentKey, ...remainingKeys] = keyReducer;

  if (keyReducer.length === 1) {
    // @ts-ignore
    return permissions[currentKey]; //Get the value from the last key if it is there or not.
  }

  if (permissions.hasOwnProperty(currentKey)) {
    // @ts-ignore
    return getPermissionValue(remainingKeys, permissions[currentKey]);
  }

  return undefined; // Return undefined if the path doesn't exist
}

export function getPermissionLock(keyReducer: string[], permissions: PermissionsOverride): boolean {
  const [currentKey, ...remainingKeys] = keyReducer;

  // @ts-ignore
  if (permissions.hasOwnProperty('enabled') && permissions['enabled'] === false) {
    return true;
  }

  if (permissions.hasOwnProperty(currentKey)) {
    // @ts-ignore
    if (typeof permissions[currentKey] == 'boolean') {
      // Reverse the value (if the lock should be on, the value will actually be false is the permissions param.)
      // @ts-ignore
      return !permissions[currentKey];
    }
    // @ts-ignore
    return getPermissionLock(remainingKeys, permissions[currentKey]);
  }

  return false;
}

export function setPermissionValue<T extends Permissions | PermissionsOverride>(
  keyReducer: string[],
  permissions: T,
  value: boolean | undefined
): T {
  if (keyReducer.length === 0) {
    throw new Error('setPermissionValue: No keys were given!');
  }

  let permissionsClone = JSON.parse(JSON.stringify(permissions));

  // Grabs the current key from all the keys we pass in. This layer is only responsible for the current key.
  const [currentKey, ...remainingKeys] = keyReducer;

  // We are at the lowest key, so go ahead and set or unset the value.
  if (keyReducer.length === 1) {
    if (value === undefined) {
      // @ts-ignore
      delete permissionsClone[currentKey];
    } else {
      // @ts-ignore
      permissionsClone[currentKey] = value;
    }
    // After setting return, to the higher up layer, your changes.
    return permissionsClone;
  } else {
    // If we still have more nesting to go, then make sure we can go a layer deeper by making a new object.
    // @ts-ignore
    if (!Object.keys(permissionsClone).includes(currentKey)) {
      // @ts-ignore
      permissionsClone[currentKey] = {};
    }
  }

  // Spread existing permissions with the recursive values we get from a layer deeper.
  permissionsClone = {
    ...permissionsClone,
    // @ts-ignore
    [currentKey]: setPermissionValue(remainingKeys, permissionsClone[currentKey], value),
  };

  // Cleanup for the parent layer to check the work of a child layer. Clean up any keys that have no values.
  // @ts-ignore
  if (Object.keys(permissionsClone[currentKey]).length === 0) {
    // @ts-ignore
    delete permissionsClone[currentKey];
  }

  // Return the whole permissions object, recursively.
  return permissionsClone;
}

/**Combines multiple permissions objects with a per key combination strategy.
 * **Permissions later in the list are considered higher priority!**
 */
export function mergePermissionsWithPriority<T extends Permissions | PermissionsOverride>(
  permissionsInPriority: T[]
): T {
  const maxMergeDepth = 10;
  function merge(permissions1: T, permissions2: T, currentMergeDepth: number): void {
    // Check our merge depth before continuing in the case of a runaway function.
    if (currentMergeDepth > maxMergeDepth) {
      console.log(`Max merge depth errored`, permissions1, permissions2);
      throw new Error(`Max merge depth of ${currentMergeDepth} reached on mergePermissionsWithPriority!`);
    }

    for (let key in permissions2) {
      if (typeof permissions2[key] === 'boolean') {
        // @ts-ignore
        permissions1[key] = permissions2[key] as boolean;
      } else if (
        typeof permissions2[key] === 'object' &&
        permissions2[key] !== null &&
        permissions2[key] !== undefined
      ) {
        if (!permissions1[key]) {
          // @ts-ignore
          permissions1[key] = {};
        }
        let nextMergeDepth = currentMergeDepth + 1;
        merge(permissions1[key] as T, permissions2[key] as T, nextMergeDepth);
      } else {
        throw new Error(`Permission value is not of type object or boolean! ${key} (depth ${currentMergeDepth})`);
      }
    }
  }

  let finalObj = {} as T;
  let permissionsInPriorityCopy = JSON.parse(JSON.stringify(permissionsInPriority));
  permissionsInPriorityCopy.forEach((permission) => {
    if (Object.keys(permission).length === 0) return;
    merge(finalObj, permission, 0);
  });
  return finalObj;
}

/** Combines the tenant access permissions with the compiled user permissions to stop the user from accessing something that is off at the tenant level. */
export function allowPermissions(tenantPermissions: Permissions, userPermissions: Permissions): Permissions {
  const maxMergeDepth = 10;
  function merge(
    userPermissions: PermissionsOverride,
    tenantPermissions: PermissionsOverride,
    currentMergeDepth: number
  ): void {
    // Check our merge depth before continuing in the case of a runaway function.
    if (currentMergeDepth > maxMergeDepth)
      throw new Error(`Max merge depth of ${currentMergeDepth} reached on allowPermissions!`);

    for (let key in tenantPermissions) {
      // @ts-ignore
      if (typeof tenantPermissions[key] === 'boolean') {
        // @ts-ignore
        if (tenantPermissions[key] === false) {
          // @ts-ignore
          userPermissions[key] = false;
        }
      } else if (
        // @ts-ignore
        typeof tenantPermissions[key] === 'object' &&
        // @ts-ignore
        tenantPermissions[key] !== null &&
        // @ts-ignore
        tenantPermissions[key] !== undefined
      ) {
        // @ts-ignore
        if (!userPermissions[key]) {
          // @ts-ignore
          userPermissions[key] = {};
        }

        // @ts-ignore
        if (tenantPermissions[key].hasOwnProperty('enabled') && tenantPermissions[key]['enabled'] === false) {
          // @ts-ignore
          tenantPermissions[key] = setAllPropertiesToFalse(tenantPermissions[key]);
        }

        // @ts-ignore
        if (userPermissions[key].hasOwnProperty('enabled') && userPermissions[key]['enabled'] === false) {
          // @ts-ignore
          userPermissions[key] = setAllPropertiesToFalse(userPermissions[key]);
        }

        // @ts-ignore
        merge(userPermissions[key] as PermissionsOverride, tenantPermissions[key] as PermissionsOverride, 0);
      }
    }
  }

  let finalPermissions: Permissions = userPermissions;
  merge(finalPermissions, tenantPermissions, 0);
  return finalPermissions;
}

// Loops through all keys on the permissions object and sets them to a value.
export function setAllPropertiesToValue<T extends Permissions | PermissionsOverride>(
  permissions: T,
  value: boolean
): T {
  const updatedObj = {} as T;

  for (let key in permissions) {
    if (typeof permissions[key] === 'object' && permissions[key] !== null) {
      //@ts-ignore
      updatedObj[key] = setAllPropertiesToValue(permissions[key] as T, value);
    } else {
      //@ts-ignore
      updatedObj[key] = value;
    }
  }

  return updatedObj;
}

export function setAllPropertiesToFalse<T extends Permissions | PermissionsOverride>(permissions: T): T {
  return setAllPropertiesToValue(permissions, false);
}

type ExtractDetailsOptions = {
  includedKeys?: string[];
  excludedKeys?: string[];
};
export function extractDetailsWithKey<T>(detailsObject: T, options: ExtractDetailsOptions): T {
  let result = {} as T;

  for (let key in detailsObject) {
    // Check if the current key should be excluded or its parent object contains an excluded key
    if (
      //@ts-ignore
      options.excludedKeys &&
      // @ts-ignore
      (options.excludedKeys.includes(key) || Object.keys(detailsObject).some((k) => options.excludedKeys.includes(k)))
    ) {
      continue;
    }

    // If the current value is an object, apply the same function recursively
    if (detailsObject[key] && typeof detailsObject[key] === 'object') {
      const value = extractDetailsWithKey(detailsObject[key], options);
      if (value !== null) {
        result[key] = value;
      }
    }
    // If the current key should be included or its parent object contains an included key
    else if (
      //@ts-ignore
      !options.includedKeys ||
      // @ts-ignore
      options.includedKeys.includes(key) ||
      // @ts-ignore
      Object.keys(detailsObject).some((k) => options.includedKeys.includes(k))
    ) {
      result[key] = detailsObject[key];
    }
  }

  // If the resulting object is empty and it is not an array, return null
  if (Object.keys(result).length === 0 && !Array.isArray(result)) {
    return null;
  }

  return result;
}

export function removeEmptyObjectsFromPermissions<T extends Permissions | PermissionsOverride>(permissions: T): T {
  for (let key in permissions) {
    //@ts-ignore
    if (typeof permissions[key] === 'object') {
      //@ts-ignore
      if (Object.keys(permissions[key]).length !== 0) {
        //@ts-ignore
        removeEmptyObjectsFromPermissions(permissions[key]);
      }

      //@ts-ignore
      if (Object.keys(permissions[key]).length === 0) {
        //@ts-ignore
        delete permissions[key];
      }
    }

    //@ts-ignore
    if (permissions[key] === null || permissions[key] === undefined) {
      //@ts-ignore
      delete permissions[key];
    }
  }
  return permissions;
}

/** Takes in a permissions object that needs to be checked. Every part of the permissions object is verified against the shape of the code defaults object. If object key does not exist in the reference, we return false. */
export function validatePermissions<T extends Permissions | PermissionsOverride>(permissions: T): boolean {
  function validateLayer(permissions: T, keyReducer?: string[]) {
    if (keyReducer === undefined) keyReducer = [];
    for (let key in permissions) {
      // @ts-ignore
      if (typeof permissions[key] === 'object' && permissions[key] !== null) {
        // @ts-ignore
        return validateLayer(permissions[key] as T, [...keyReducer, key]);
      } else {
        if (getPermissionValue(keyReducer, getUserPermissionDefaults()) === undefined) {
          console.error('validatePermissions: Invalid permissions at ', keyReducer);
          return false;
        }
      }
    }

    return true;
  }

  return validateLayer(permissions);
}

export function permissionsToKeyReducer<T extends Permissions | PermissionsOverride>(permissions: T): string[][] {
  return [];
}
