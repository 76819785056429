import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MSAL_INSTANCE } from '@azure/msal-angular';
import { InteractionRequiredAuthError, InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import { IntegrationsService } from './integrations.service';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import {
  Client,
  LargeFileUploadSession,
  LargeFileUploadTask,
  LargeFileUploadTaskOptions,
  Range,
  FileUpload,
  UploadEventHandlers,
  UploadResult,
} from '@microsoft/microsoft-graph-client';

const TEAMS_FOR_BUSINESS = 'teamsForBusiness';

@Injectable()
export class OutlookService {
  client;
  scopes: string[] = [
    'Calendars.ReadWrite',
    'Mail.ReadWrite',
    'Mail.Send',
    'offline_access',
    'OnlineMeetings.ReadWrite',
    'openid',
    'profile',
    'User.Read',
    'User.ReadWrite',
  ];
  loginRequest = {
    scopes: this.scopes,
    state: 'outlook',
  };
  postSendMailUrl = 'https://graph.microsoft.com/v1.0/me/sendMail';
  postCreateEventUrl = 'https://graph.microsoft.com/v1.0/me/events';
  getOutlookEventsUrl = 'https://graph.microsoft.com/v1.0/me/calendar/events';
  postCreateMessage = 'https://graph.microsoft.com/v1.0/me/messages';
  getUserCalendarInfo = 'https://graph.microsoft.com/v1.0/me/calendar';
  postCreateMeetingEvent = 'https://graph.microsoft.com/v1.0/me/events';
  postCreateOnlineMeeting = 'https://graph.microsoft.com/v1.0/me/onlineMeetings';
  getAccountCalendarInfo = 'https://graph.microsoft.com/v1.0/me/calendar';
  //getOutlookEventsStartEndUrl = `https://graph.microsoft.com/v1.0/me/calendar/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}`;

  constructor(
    private snackBar: MatSnackBar,
    private integrationsService: IntegrationsService,
    private userService: UserService,
    private http: HttpClient,
    @Inject(MSAL_INSTANCE) private msalInstance: IPublicClientApplication
  ) {}

  //Handles the result of handleRedirectPromise
  handleResponse(response) {
    if (response !== null && this.msalInstance.getActiveAccount() == null) {
      //you are coming back from a successful authentication redirect.
      console.log('handleResponse successful', response);
      this.msalInstance.setActiveAccount(response.account);
      this.setOutlookKey(response.account);

      this.snackBar.open('Successfully authorized Outlook!', null, {
        duration: 4000,
        panelClass: 'success-snack-bar',
      });
    } else {
      //you are not coming back from an authentication redirect.
      console.log(
        'handleResponse: Not coming back from authentication redirect. User probably already authenticated or hasnt authenticated yet'
      );
      this.getRefreshToken();
    }
    // window.location.href = window.location.origin + '/#/settings/emailsettings'
    window.location.href = window.location.origin + '/#/settings/integrations/microsoft';
  }

  OutlookLogin() {
    this.msalInstance.loginRedirect(this.loginRequest);
  }

  OutlookLogout() {
    const logoutRequest = {
      account: this.msalInstance.getActiveAccount(),
    };

    this.msalInstance.setActiveAccount(null);
    this.setOutlookKey('');

    this.msalInstance.logoutRedirect(logoutRequest);
  }

  getRefreshToken() {
    this.readOutlookKey();

    if (this.msalInstance.getActiveAccount()) {
      var silentRequest = {
        scopes: this.scopes,
        account: this.msalInstance.getActiveAccount(),
        forceRefresh: false,
      };

      return this.msalInstance
        .acquireTokenSilent(silentRequest)
        .then(async (tokenResponse) => {
          console.log('getRefreshToken: tokenResponse', tokenResponse);
          this.msalInstance.setActiveAccount(tokenResponse.account);
          // Initialize Graph client
          this.client = Client.init({
            authProvider: (done) => {
              done(null, tokenResponse.accessToken);
            },
          });

          return tokenResponse;
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails'
            console.log('getRefreshToken: acquireTokenSilent failed. Executing acquireTokenRedirect...');
            return this.msalInstance.acquireTokenRedirect(this.loginRequest);
          } else {
            console.log('getRefreshToken: error', error);
            return null;
          }
        });
    } else {
      console.log('getRefreshToken: No active account. Cannot get refresh token');
      // originall null
      // updated to resoled promise because sometimes this returns a promise
      // at the end of the day though, back to null because we have to relay on the old code
      // that could potentially cause a problem
      return null
    }
  }

  async upload(att, messageId): Promise<boolean> {
    const progress = (range?: Range, extraCallbackParam?: unknown) => {
      // Implement the progress callback here

      console.log('uploading range: ', range);
      console.log(extraCallbackParam);
    };

    const uploadEventHandlers: UploadEventHandlers = {
      progress,
      extraCallbackParam: 'any parameter needed by the callback implementation',
    };

    const options: LargeFileUploadTaskOptions = {
      rangeSize: 1024 * 1024,
      uploadEventHandlers,
    };

    // Create upload session for Outlook API
    const uploadSessionURL = `https://graph.microsoft.com/v1.0/me/messages/${messageId}/attachments/createUploadSession`;
    const payload = {
      AttachmentItem: {
        attachmentType: 'file',
        name: att.name,
        size: att.size,
      },
    };
    const uploadSession: LargeFileUploadSession = await LargeFileUploadTask.createUploadSession(
      this.client,
      uploadSessionURL,
      payload
    );

    // You can also create an object from a custom implementation of FileObject
    let fileObject = new FileUpload(att, att.name, att.size);
    const task = new LargeFileUploadTask(this.client, fileObject, uploadSession, options);
    const uploadResult = await task.upload();

    // If upload succeeded, return true
    if (uploadResult)
      return new Promise((resolve) => {
        resolve(true);
      });
  }

  async uploadSmallAttachment(messageId, att, accessToken): Promise<any> {
    let attJson = await this.generateAttachmentsJson(att);
    let postUploadSmallAttachment = `https://graph.microsoft.com/v1.0/me/messages/${messageId}/attachments`;

    return new Promise<any>(async (resolve, reject) => {
      this.http
        .post(postUploadSmallAttachment, attJson, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
        })
        .subscribe(
          (result: any) => {
            console.log('Result from uploading small attachment ', result);
            resolve(true);
          },
          (error) => {
            console.log('There was an error in uploadSmallAttachment ', error);
            reject(error);
          }
        );
    });
  }

  sendMail(messageId: string, accessToken: string) {
    return new Promise((resolve, reject) => {
      // Send email
      let postSendMail = `https://graph.microsoft.com/v1.0/me/messages/${messageId}/send`;
      this.http
        .post(postSendMail, null, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
        })
        .subscribe(
          (result: any) => {
            resolve(true);
          },
          (error) => {
            console.log('Error sending outlook email', error);
            reject(error);
          }
        );
    });
  }

  async sendOutlookEmail(emailContents): Promise<any> {
    let body = await this.generateBodyJson(emailContents);

    return new Promise(async (resolve, reject) => {
      return this.getRefreshToken().then((response) => {
        const accessToken = (<any>response).accessToken;
        if (accessToken) {
          this.http
            .post(this.postCreateMessage, JSON.stringify(body), {
              headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
            })
            .subscribe(async (result: any) => {
              console.log('Result from createOutlookDraft ', result);
              let messageId = result.id;
              let uploadSuccess = true;

              // Loop through attachments array, if att.size < 3mb do the post request for attaching normally
              // Else if its bigger, do upload session
              for (let i = 0; i < emailContents.attachments.length; i++) {
                if (emailContents.attachments[i].size <= 3000000) {
                  // Attachment is smaller than 3MB
                  uploadSuccess = await this.uploadSmallAttachment(
                    messageId,
                    emailContents.attachments[i],
                    accessToken
                  );
                } else {
                  // Attachment is larger than 3 MB, create upload session
                  // uploadSuccess = await this.uploadLargeAttachment(messageId, att[i], accessToken);
                  uploadSuccess = await this.upload(emailContents.attachments[i], messageId);
                }
              }

              // After successful upload, send draft mail with att. If there's no file upload, it will still send email
              if (uploadSuccess) {
                await this.sendMail(messageId, accessToken).then((result) => {
                  if (result) {
                    resolve(true);
                  }
                });
              }
            });
        } else {
          console.error('Cannot create outlook draft because the user is not authorized.');
          resolve(false);
        }
        (error) => console.error('There was an error inside createOutlookDraft ', error);
      });
    });
  }

  async generateBodyJson(emailContents) {
    var message = new Object();

    //message builder
    if (emailContents.subject) message['subject'] = emailContents.subject;
    if (emailContents.to) message['toRecipients'] = this.generateEmailsJson(emailContents.to);
    if (emailContents.cc) message['ccRecipients'] = this.generateEmailsJson(emailContents.cc);
    // We are not generating the attachments like this anymore. it will be attached to the email when draft is made
    // if (emailContents.attachments) {
    //   var attachmentsList = []
    //   for (let att = 0; att < emailContents.attachments.length; ++att) {
    //     var attBuilder = await this.generateAttachmentsJson(emailContents.attachments[att])
    //     attachmentsList.push(attBuilder)
    //   }
    //   for (let i = 0; i < emailContents.embeddedImgs.length; ++i) {
    //     attachmentsList.push(emailContents.embeddedImgs[i])
    //   }
    //   messageBuilder['attachments'] = attachmentsList
    // }
    if (emailContents.body) {
      //message body builder (the body of the email)
      message['body'] = {
        contentType: 'HTML',
        content: emailContents.body,
      };
    }

    return new Promise((resolve) => {
      resolve(message);
    });
  }

  generateEmailsJson(emails) {
    var emailList = [];
    var emailsArray = emails.split(/[\s,;]+/);

    emailsArray.map((email) => {
      var emailListBuilder = {
        emailAddress: {
          address: email,
        },
      };
      emailList.push(emailListBuilder);
    });

    return emailList;
  }

  generateAttachmentsJson(att) {
    //Encoding file to base64
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(att);
      reader.onload = () => {
        var startIndex = reader.result.toString().indexOf(',') + 1;
        var endIndex = reader.result.toString().length;
        var contentBytes = reader.result.slice(startIndex, endIndex);

        var attBuilder = {
          '@odata.type': '#microsoft.graph.fileAttachment',
          name: att.name,
          // contentType: att.type,
          contentBytes: contentBytes,
        };

        resolve(attBuilder);
      };
      reader.onerror = (error) => {
        console.error('generateattachment reader error', reader, error);
        reject(error);
      };
    });
  }

  generateEmbeddedImagesJson(img, cidNum) {
    var cid = 'img' + cidNum;
    let imgAtt = {
      '@odata.type': '#microsoft.graph.fileAttachment',
      contentBytes: img.src.split(';')[1].split('base64,')[1],
      contentId: cid,
      name: cid,
      isInline: true,
    };

    // Replaces the img src in the email body with the cid
    img.setAttribute('src', 'cid:' + cid);

    return imgAtt;
  }

  getUserInfo() {
    return this.msalInstance.getActiveAccount();
  }

  getOutlookEvents(): Promise<any> {
    //Return the getRefreshToken Promise
    return (
      this.getRefreshToken()
        //Mutate the promise to run some logic and then return integrationsService Observable
        .then(
          async (response) => {
            const accessToken = (<any>response).accessToken;
            if (accessToken) {
              const headers = {
                Authorization: `Bearer ${accessToken}`,
              };

              //Getting...
              return this.integrationsService.proxyGet(this.getOutlookEventsUrl, JSON.stringify(headers));
            } else {
              console.error('Cannot get Outlook events because access token doesnt exist. User is not authorized');
              return of(false);
            }
          },
          (error) => console.error('Error inside getOutlookEvents', error)
        )
    );
  }

  getOutlookEventsOnDate(date, timezone): Promise<any> {
    //Return the getRefreshToken Promise
    return (
      this.getRefreshToken()
        //Mutate the promise to run some logic and then return integrationsService Observable
        .then(
          async (response) => {
            const accessToken = (<any>response).accessToken;
            if (accessToken) {
              const headers = {
                Authorization: `Bearer ${accessToken}`,
                Prefer: `outlook.timezone="${timezone}"`,
              };

              //Format start and end dates. Microsoft uses ISO format
              let start_datetime = new Date(date);
              let end_datetime = new Date(start_datetime);
              end_datetime.setDate(end_datetime.getDate() + 1);
              let getOutlookEventsOnDateUrl = `https://graph.microsoft.com/v1.0/me/calendar/calendarView?startDateTime=${start_datetime.toISOString()}&endDateTime=${end_datetime.toISOString()}`;
              //Getting...
              return this.integrationsService.proxyGet(getOutlookEventsOnDateUrl, JSON.stringify(headers));
            } else {
              console.error('Cannot get Outlook events because access token doesnt exist. User is not authorized');
              return of(false);
            }
          },
          (error) => console.error('Error inside getOutlookEvents', error)
        )
    );
  }

  createOutlookEvent(eventForm, startEndDates, participants): Promise<any> {
    //Return the getRefreshToken Promise
    return (
      this.getRefreshToken()
        //Mutate the promise to run some logic and then return integrationsService Observable
        .then(
          async (response) => {
            const accessToken = (<any>response).accessToken;
            if (accessToken) {
              const headers = {
                Authorization: `Bearer ${accessToken}`,
                Prefer: `outlook.timezone="${Intl.DateTimeFormat().resolvedOptions().timeZone}"`,
                contentType: 'application/json',
              };

              const body = this.generateEventJson(eventForm, startEndDates, participants);

              //Posting...
              return this.integrationsService.proxyPost(
                this.postCreateEventUrl,
                JSON.stringify(headers),
                JSON.stringify(body)
              );
            } else {
              console.error('Cannot get Outlook events because access token doesnt exist. User is not authorized');
              return of(false);
            }
          },
          (error) => console.error('Error inside getOutlookEvents', error)
        )
    );
  }

  generateEventJson(eventForm, startEndDates, participants) {
    var event = new Object();

    //message builder
    event['subject'] = eventForm.title;
    event['body'] = {
      contentType: 'HTML',
      content: eventForm.description,
    };
    event['start'] = {
      dateTime: startEndDates[0],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    event['end'] = {
      dateTime: startEndDates[1],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (eventForm.locaion) {
      event['location'] = {
        displayName: eventForm.location,
        locationType: 'Default',
      };
    }
    event['attendees'] = this.generateParticipantsJson(participants);
    event['allowNewTimeProposals'] = false;
    event['isOnlineMeeting'] = eventForm.isTeamsMeeting;
    if (eventForm.isTeamsMeeting) {
      event['onlineMeetingProvider'] = 'teamsForBusiness';
    }

    return event;
  }

  generateParticipantsJson(participants) {
    var participantsObj = [];

    for (let a = 0; a < participants.length; ++a) {
      participantsObj.push({
        emailAddress: {
          address: participants[a],
          // name: `${p.firstName} ${p.lastName}`
        },
        type: 'Required',
      });
    }
    return participantsObj;

    // participants.map(p => {
    //   this.userService
    //     .getUserInfo(p.id)
    //     .pipe(take(1))
    //     .subscribe((result) => {
    //       participantsObj.push({
    //         emailAddress: {
    //           address: result.data.getUserInfo.emails[0].address,
    //           name: `${p.firstName} ${p.lastName}`
    //         },
    //         type: 'Required'
    //       });
    //     });
    // });
  }

  async hasTeamsForBusiness(): Promise<boolean> {
    return new Promise((resolve) => {
      return this.getRefreshToken()
        .then(async (response) => {
          const accessToken = (<any>response).accessToken;
          if (accessToken) {
            this.http
              .get(this.getAccountCalendarInfo, {
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
              })
              .subscribe((account: any) => {
                if (account.allowedOnlineMeetingProviders?.includes('teamsForBusiness')) {
                  resolve(true);
                } else {
                  resolve(false);
                }
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  setOutlookKey(key) {
    this.integrationsService
      .setIntegrationKey('OUTLOOK_OAUTH_TOKEN', JSON.stringify(key))
      .pipe(take(1))
      .subscribe(
        (result) => {},
        (error: unknown) => {
          console.log('OutlookService: There was an error setting key ', error);
        }
      );
  }

  readOutlookKey() {
    this.integrationsService
      .readIntegrationKey('OUTLOOK_OAUTH_TOKEN')
      .pipe(take(1))
      .subscribe(
        (result) => {
          let account = result.data.readIntegrationKey.apiKey ? JSON.parse(result.data.readIntegrationKey.apiKey) : '';
          if (account) {
            this.msalInstance.setActiveAccount(account);
          }
        },
        (error: unknown) => {
          console.log('OutlookService: There was an error reading key in getRefreshToken', error);
        }
      );
  }

  isOutlookAuthorized() {
    return this.getRefreshToken() ? true : false;
  }
}
