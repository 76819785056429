// Playbook Data Structures

export const PlaybookTaskStatusesArr = ['open', 'working', 'complete'];
export type PlaybookTaskStatuses = 'open' | 'working' | 'complete';

export const PlaybookTaskStagesArr = ['prospect', 'candidate', 'meeting', 'offer', 'hire'];
export type PlaybookTaskStages = 'Prospect' | 'Candidate' | 'Meeting' | 'Offer' | 'Hire';

export const PlaybookTaskDSEArr = ['', '1 Week', '2 Weeks', '1 Month', '3 Months', '6 months'];

// actions?: []; // future think
// reminders?: []; // future think
// notifications?: []; // future think
export interface PlaybookTask {
  id: string;
  color: string;
  title: string;
  desc: string;
  notes?: PlaybookNoteType[];
  status: string;
  dueDSE: number; // days since event
  weight: number; // 0- 100
  assignedTo?: string[];
  __typename?: string;
}

/*
dueDate -  days from stage entry. (DSE)
object {“type”:”DSE”, “value”: 12}
Due 12 days from the date the contact entered the stage
*/

export interface PlaybookStage {
  id: string;
  tasks: PlaybookTask[];
  __typename?: string;
}

export interface PlaybookCategory {
  id: string;
  title: string;
  desc?: string;
  stages: PlaybookStage[];
  __typename?: string;
}

// changed?: string; // Changed date since last published
// lastPublished?: string; // Lat date published
// adminUser ?: string; // Link to tenant / user / admin
// version?: []; // Array of last published dates
export interface PlaybookTemplate {
  id: string;
  title: string;
  desc?: string;
  categories: PlaybookCategory[];
  order?: number;
  __typename?: string;
}

// Link Playbook to Req and Contact
export interface PlaybooksReqContact {
  id: string; // "";
  pKey: string; // "CONTACT$<contactId>";
  rKey: string; // "REQ$<reqId>|PLAYBOOKITEM|<contactStage>|<playbookTemplateId>|<playbookItemId>";
  playbookTemplateId: string; // Source PLaybook Id or
  playbookObj: PlaybookTemplate; // Deep copy or just link
  active: boolean;
}

export interface PlaybookNoteType {
  id: string;
  content: string;
  by?: string;
  createdAt?: string;
  modifiedAt?: string;
  __typename?: string;
}

// Link Playbook to Requisition
export interface PlaybookTemplateReqLink {
  pKey?: 'REQ$<reqId>';
  rKEy?: 'PLAYBOOK';
  id?: string;
  playbookTemplateId?: string;
  reqId?: string;
}

/*

  Contact Stages

  Prospects, Candidates, Meetings, Offers, Hired 


*/
/**************************************************
 * Blank starter objects
 *
 *
 **************************************************/

export const newPlaybookTemplate: PlaybookTemplate = {
  id: '',
  title: 'New Playbook',
  categories: [],
  order: 0,
};

export const newCategoryTemplate: PlaybookCategory = {
  id: '',
  title: 'New Category',
  stages: [],
};

export const newStageTemplate: PlaybookStage = {
  id: 'prospect',
  tasks: [],
};

export const newTaskTemplate: PlaybookTask = {
  id: '',
  title: 'New Task',
  desc: '',
  status: 'open',
  // stage: "",
  weight: 50,
  color: '#6497b3',
  dueDSE: 0,
  assignedTo: [],
  notes: [],
};

export const newPlaybooksReqContactTemplate: PlaybooksReqContact = {
  id: '',
  pKey: '',
  rKey: '',
  playbookTemplateId: '',
  playbookObj: null,
  active: true,
};

export const newPlaybookNoteTemplate: PlaybookNoteType = {
  id: '',
  content: 'New Comment',
  by: '',
  createdAt: new Date().toISOString(),
  modifiedAt: new Date().toISOString(),
};

export const newPlaybookTemplateReqLinkTemplate: PlaybookTemplateReqLink = {
  id: '',
  playbookTemplateId: '',
  reqId: '',
};
