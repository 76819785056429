import { ChangeDetectorRef } from '@angular/core';
import { Component, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { createPopper } from '@popperjs/core';

@Component({
  selector: 'mm-tooltip',
  templateUrl: './tooltips.component.html',
  styleUrls: ['../../nick_styles/nick_op.css'],
})
export class ToolTipComponent implements AfterViewInit {
  @ViewChild('anchor', { static: false }) anchor: ElementRef;
  @ViewChild('tooltip', { static: false })
  tooltip: ElementRef;

  @Input() message: string;
  @Input() showing: boolean = false;
  @Input() placement:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end' = 'top';
  @Input() carrot: 'start' | 'end' | 'center' | false = 'center';
  @Input() trigger: 'hover' | 'click' | 'focus' | 'manual' = 'hover';
  @Input() offset: number[] = [0, 20];
  @Input() class: string = null;
  popper;
  carrotPosition;

  constructor(private cd: ChangeDetectorRef) {}
  ngAfterViewInit() {
    this.popper = createPopper(this.anchor.nativeElement, this.tooltip.nativeElement, {
      placement: this.placement,
      modifiers: [
        {
          name: 'computeStyles',
          options: {
            gpuAcceleration: false,
          },
        },
        {
          name: 'offset',
          options: {
            offset: this.offset,
          },
        },
      ],
    });

    if (this.carrot == 'center') {
      this.carrotPosition = 'left-1/2';
    } else if (this.carrot == 'start') {
      this.carrotPosition = 'left-8';
    } else if (this.carrot == 'end') {
      this.carrotPosition = 'right-8';
    }

    this.cd.detectChanges();
  }

  toggleDropdown(event, trigger) {
    if (trigger !== this.trigger) return;
    event.preventDefault();
    this.showing = !this.showing;
  }
}
