import { gql } from 'apollo-angular';

export const addReqMutation = gql`
  mutation addReq(
    $title: String!
    $summary: String
    $tenantId: ID!
    $ownerId: String!
    $ownerFirst: String!
    $ownerLast: String!
    $address: String
    $assignedUsers: [String]
  ) {
    addReq(
      title: $title
      summary: $summary
      tenantId: $tenantId
      ownerId: $ownerId
      ownerFirst: $ownerFirst
      ownerLast: $ownerLast
      address: $address
      assignedUsers: $assignedUsers
    ) {
      id
      created
      title
      ownerId
      ownerFirst
      ownerLast
      targetHires
      status
      tenant
      reqId
      mmAdminOnlyReqId
      statistics {
        prospects
        candidates
        meetings
        offers
        hires
      }
      todos {
        id
        type
        title
        dueOn
        reqId
        users {
          firstName
          lastName
        }
        contacts {
          firstName
          lastName
        }
      }
    }
  }
`;

export const addTodoMutation = `
    mutation addTodo($input: AddTodoInput!) {
        addTodo(input: $input) {
            id
            reqId
            title
            type
            description
            created
            dueOn
            createdBy
            duration
            lastUpdated
            location
            phone
            duration
            users {
                id
                firstName
                lastName
            }
            contacts {
                id
                firstName
                lastName
            }
        }
    }
`;

export const completeTodoMutation = `
    mutation completeTodo($todoId: String!, $disposition: String, $createNextAction: Boolean) {
        completeTodo(todoId: $todoId, disposition: $disposition, createNextAction: $createNextAction) {
            id
            title
            description
            contacts {
                id
                firstName
                lastName
            }
            users {
                id
                firstName
                lastName
            }
            completedBy {
                id
                firstName
                lastName
            }
            completedDate
            type
            dueOn
            reqId
            disposition
        }
    }`;

export const addContact = `
    mutation addContact($contact: ContactInput!) {
        addContact(contact: $contact) {
            id
            firstName
            lastName
            nmlsId
            createdAt
            active
            company
            emails {
              type
              address
            }
            phones {
              type
              number
            }
            links {
                type
                url
            }
            address {
                street
                street2
                city
                state
                zipCode
            }
            sourceId
            source
            lastUpdatedAt
        }
    }`;
export const disableContact = `
    mutation disableContact($contactId: String!, $enableContact: Boolean) {
        disableContact(contactId: $contactId, enableContact: $enableContact) {
            active
            disabledAt
        }
    }`;

export const addUserContactSearchFilter = `
    mutation addUserContactSearchFilter($searchFilter: UserContactSearchFilterInput!) {
        addUserContactSearchFilter(searchFilter: $searchFilter) {
            id
            createdAt
            name
            filterCriteria {
                displayName
                type
                value
                key
            }
        }
    }`;

export const updateUserContactSearchFilter = `
    mutation updateUserContactSearchFilter($id: String!, $filterCriteria: [QueryStringRawContactFilterInput]!) {
        updateUserContactSearchFilter(id: $id, filterCriteria: $filterCriteria) {
            id
            createdAt
            name
            filterCriteria {
                displayName
                type
                value
                key
            }
        }
    }`;

export const deleteUserContactSearchFilter = `
    mutation deleteUserContactSearchFilter($id: String!) {
        deleteUserContactSearchFilter(id: $id) 
    }`;

export const shareUserContactSearchFilter = `
    mutation shareUserContactSearchFilter($userIds: [String], $searchFilter: UserContactSearchFilterInput!) {
        shareUserContactSearchFilter(userIds: $userIds, searchFilter: $searchFilter) {
            id
            createdAt
            name
            filterCriteria {
                displayName
                type
                value
                key
            }
        }
    }`;

export const uploadContactDocument = `
    mutation uploadContactDocument($fileName: String!, $fileType: String!, $contactId: String!, $reqId: String, $documentId: String) {
        uploadContactDocument(fileName: $fileName, fileType: $fileType, contactId: $contactId, reqId: $reqId, documentId: $documentId) {
            id
            documentName
            createdAt
            lastUpdatedAt
            contactId
            uploadUrl
            ownerId
        }
    }`;

export const uploadContactAvatar = `
    mutation uploadContactAvatar($fileName: String!, $fileType: String!, $contactId: String!) {
        uploadContactAvatar(fileName: $fileName, fileType: $fileType, contactId: $contactId) {
            uploadUrl
        }
    }`;

export const uploadUserAvatar = `
    mutation uploadUserAvatar($fileName: String!, $fileType: String!, $userId: String!) {
        uploadUserAvatar(fileName: $fileName, fileType: $fileType, userId: $userId) {
            uploadUrl
        }
    }`;

export const deleteContactDocument = `
    mutation deleteContactDocument($contactId: String!, $documentId: String!, $reqId: String) {
        deleteContactDocument(contactId: $contactId, documentId: $documentId, reqId: $reqId) 
    }`;

export const disconnectZapier = `
    mutation disconnectZapier() {
        disconnectZapier() 
    }`;

export const addReqContactSearchFilter = `
    mutation addReqContactSearchFilter($searchFilter: ReqContactSearchFilterInput!) {
        addReqContactSearchFilter(searchFilter: $searchFilter) {
            id
            createdAt
            name
            filterCriteria {
                displayName
                type
                value
                key
            }
        }
    }`;

export const updateReqContactSearchFilter = `
    mutation updateReqContactSearchFilter($id: String!, $reqId: String!, $filterCriteria: [QueryStringRawContactFilterInput]!) {
        updateReqContactSearchFilter(id: $id, reqId: $reqId, filterCriteria: $filterCriteria) {
            id
            createdAt
            name
            filterCriteria {
                displayName
                type
                value
                key
            }
        }
    }`;

export const shareReqContactSearchFilter = `
    mutation shareReqContactSearchFilter($userIds: [String], $searchFilter: ReqContactSearchFilterInput!) {
        shareReqContactSearchFilter(userIds: $userIds, searchFilter: $searchFilter) {
            id
            createdAt
            name
            filterCriteria
        }
    }`;

export const deleteReqContactSearchFilter = `
    mutation deleteReqContactSearchFilter($id: String!, $reqId: String!) {
        deleteReqContactSearchFilter(id: $id, reqId: $reqId) 
    }`;

export const updateContact = `
    mutation updateContact($contactId: String!, $input: ContactInput!, $contactReqId: String) {
        updateContact(contactId: $contactId, input: $input, contactReqId: $contactReqId) {
            id
            createdAt
            lastUpdatedAt
            tenantId
            nmlsId
            title
            company
            emailDripEnabled
            phones {
                type
                number
            }
            emails {
                type
                address
            }
            links {
                type
                url
            }
            address {
                street
                street2
                state
                city
                zipCode
            }
            avatarId
            firstName
            lastName
            ownerId
            linkedIn
            facebook
            website
            twilioLookedUp
            customFields {
                fieldId
                value
            }
        }
    }`;

export const updateContactBusiness = `
    mutation updateContactBusiness($contactId: String!, $input: ContactBusinessInput!, $isMerge: Boolean!) {
        updateContactBusiness(contactId: $contactId, input: $input, isMerge: $isMerge) {
            volume
            avgPerUnit
            totalUnits
            purchasePercentage
            period
            productMix {
                conventional
                government
                nonConforming
                other
            }
            endMonth
            endYear
            isUserInput
            isUserImport
        }
    }`;

export const addContactsToReqs = `
    mutation addContactsToReqs($contactIds: [String], $reqIds: [String]) {
        addContactsToReqs(contactIds: $contactIds, reqIds: $reqIds) {
            contactId
            stage
            score
            assignedUser {
                id
            }
            requisition {
                id
                title
                status
                tenantId
            }
        }
    }
`;

export const removeUserFromReq = `
    mutation removeUserFromReq($userId: String, $reqId: String) {
        removeUserFromReq(userId: $userId, reqId: $reqId) 
    }
`;

export const addUserToReq = `
    mutation addUserToReq($userId: String!, $reqId: String!) {
        addUserToReq(userId: $userId, reqId: $reqId) {
            id
            firstName
            lastName
            created
            active
            username
            tenantId
        }
    }
`;

export const updatePersonalInfo = `
    mutation updateContactPersonalInfo($contactId: String!, $info: PersonalInfoInput!) {
        updateContactPersonalInfo(contactId: $contactId, info: $info) {
            details
            spouse
            birthdate
            kids
            anniversary
            education
            hobbies
        }
    }
`;

export const updateContactReq = `
    mutation updateReqContact($input: ReqContactInput!, $sendMail: Boolean) {
        updateReqContact(input: $input, sendMail: $sendMail) {
            id
            firstName
            lastName
            stage
            stageEnteredDate
            source
            company
            contacted
            lastUpdatedAt
            contactId
        }
    }`;

export const initContactImport = `
    mutation initContactImport($options: ContactImportOptionsInput!, $fileType: String!) {
        initContactImport(options: $options, fileType: $fileType) {
            uploadId
            presignedUrl
            method
        }
    }`;

export const initUserImport = `
    mutation initUserImport($options: UserImportOptions!, $fileType: String!) {
        initUserImport(options: $options, fileType: $fileType) {
            uploadId
            presignedUrl
            method
        }
    }`;

export const initContactExport = `
    mutation initContactExport($contactIds: [String]) {
        initContactExport(contactIds: $contactIds)
    }`;

export const shareContactToUser = `
    mutation shareContactToUser($contactId: String, $userId: String) {
        shareContactToUser(contactId: $contactId, userId: $userId) {
            id
        }
    }
`;

export const addNote = `
    mutation addNote($noteInput: NoteInput!) {
        addNote(input: $noteInput) {
            id
            type
            title
            description
            reqId
            dueOn
            completedDate
            completedBy {
                id
                firstName
                lastName
            }
            users {
                id
                firstName
                lastName
            }
             contacts {
                id
                firstName
                lastName
            }
            isPublic
        }
    }`;

export const editNote = `
mutation editNote($editNoteInput: EditNoteInput!) {
    editNote(input: $editNoteInput) {
        id
        type
        title
        description
        reqId
        dueOn
        completedDate
        previousEdit
        completedBy {
            id
            firstName
            lastName
        }
        users {
            id
            firstName
            lastName
        }
         contacts {
            id
            firstName
            lastName
        }
        isPublic
    }
}`;

export const addTenant = `
    mutation addTenant($tenantInput: TenantInput!) {
        addTenant(input: $tenantInput) {
            id
            title
            type
            user {
                id
                firstName
                lastName
            }
        }
    }
`;

export const addUser = `
    mutation addUser($userInput: AddUserInput!) {
        addUser(input: $userInput) {
            id
            firstName
            lastName
            username
            tenantId
        }
    }
`;

export const addToNewUserStepQueue = `
    mutation addToNewUserStepQueue($userInput: AddUserInput!) {
        addToNewUserStepQueue(input: $userInput) {
            id
            firstName
            lastName
            username
            tenantId
        }
    }
`;

export const resendUserCode = `
    mutation resendUserCode($userInput: ResendUserCodeInput!) {
        resendUserCode(input: $userInput)
    }
`;

export const updateTodoMutation = `
    mutation updateTodo($updateInput: UpdateTodoInput!) {
        updateTodo(input: $updateInput) {
            id
            type
            title
            dueOn
            reqId
            description
            location
            phone
            duration
            users {
                id
                firstName
                lastName
            }
            contacts {
                id
                firstName
                lastName
            }
        }
    }
`;

export const createLeadSearch = `
    mutation createLeadsSearch($leadsCriteria: LeadsCriteria!) {
        createLeadsSearch(leadsCriteria: $leadsCriteria) {
            executionId
        }
    }`;

export const updateLead = `
    mutation updateLeadMetadata($nmlsId: String!, $lead: UpdateLeadInput!) {
        updateLeadMetadata(nmlsId: $nmlsId, lead: $lead) {
            nmlsId
            firstName
            lastName
            cellPhone
            city
            company
            county
            email
            title
            workPhone
            website
            state
            totalLoanAmount
            totalLoans
            totalPurchase
            totalRefinance
            totalConforming
            totalGovernment
            totalConventional
            totalOther
            percentPurchase
            volume
        }
    }`;

export const importLeads = `
    mutation importLeads($import: ImportLeadInput!) {
        importLeads(import: $import)
    }`;

export const createStripePaymentIntentForLead = `
    mutation createStripePaymentIntentForLead($metadata: StripePaymentIntentLeadMetadata!) {
        createStripePaymentIntentForLead(metadata: $metadata) {
            id
            amount
            client_secret
            card {
                brand
                exp_month
                exp_year
                last4
            }
        }
    }
`;

export const createNmlsValidation = `
    mutation createNmlsValidation($nmlsId: String!) {
        createNmlsValidation(nmlsId: $nmlsId) {
            executionId
            status
        }
    }
`;

export const importLeadInsight = `
    mutation importLeadInsight($nmlsId: String!, $contactId: String!) {
        importLeadInsight(nmlsId: $nmlsId, contactId: $contactId)
    }
`;

export const cancelStripePaymentIntent = `
    mutation cancelStripePaymentIntent($id: ID!, $cancellation_reason: StripePaymentIntentCancellationReason) {
        cancelStripePaymentIntent(id: $id, cancellation_reason: $cancellation_reason) {
            id
        }
    }
`;

export const generateVolumeReport = `
    mutation generateVolumeReport ($contactId: String!, $nmlsId: String!) {
        generateVolumeReport (contactId: $contactId, nmlsId: $nmlsId) {
            volume
            avgPerUnit
            totalUnits
            purchasePercentage
            period
            productMix {
                conventional
                government
                nonConforming
                other
            }
            transactionMix {
                purchase
                refinance
                construction
                equity
                 REO
            }
            loanTypes {
                key
                volume
                avgPerUnit
                totalUnits
            }
            topCities {
                name
                count
                volume
            }
            topLenders {
                name
                count
                volume
            }
            endMonth
            endYear
            startMonth
            startYear
        }
    }
`;

export const generateFullVolumeReport = `
    mutation generateFullVolumeReport ($contactId: String!, $nmlsId: String!, $updateContactRecord: Boolean) {
        generateFullVolumeReport (contactId: $contactId, nmlsId: $nmlsId, updateContactRecord: $updateContactRecord) {
            lastUpdatedAt
            createdAt
            nmlsId
            report {
                volume
                avgPerUnit
                totalUnits
                purchasePercentage
                period
                productMix {
                    conventional
                    government
                    nonConforming
                    other
                }
                transactionMix {
                    purchase
                    refinance
                    construction
                    equity
                    REO
                }
                loanTypes {
                    conventional
                    HE
                    FHA
                    VA
                    USDA
                    HELOC
                    building
                    balloon
                    commercial
                    reverse
                    other
                    unknown
                }
                topCities {
                    name
                    count
                    volume
                }
                topLenders {
                    name
                    count
                    volume
                }
                monthly {
                    date_range
                    production {
                        count
                        min
                        max
                        avg
                        sum
                    }
                }
                endMonth
                endYear
            }
        }
    }`;

export const updateUserInfo = `
        mutation updateUserInfo($userId: String!, $userInput: UpdateUserInfoInput!) {
            updateUserInfo(userId: $userId, userInput: $userInput) {                       
            created
            lastUpdated
            nmlsId
            title
            company
            phones {
                type
                number
            }
            emails {
                type
                address
            }
            links {
                type
                url
            }
            address {
                street
                street2
                state
                city
                zipCode
            }
            avatarId
            firstName
            lastName    
            notifications
            agentPhone
            smsPhone
            smsNotify       
        }
    }`;

export const updateReq = `
        mutation updateReq($reqId: String!, $input: UpdateReqInput!) {
            updateReq(reqId: $reqId, input: $input){
            title
            summary
            }
        }
    `;

export const updateReqOwner = `
        mutation updateReqOwner($reqId: String!, $ownerId: String!, $firstName: String!, $lastName: String!) {
            updateReqOwner(reqId: $reqId, ownerId: $ownerId, firstName: $firstName, lastName: $lastName)
        }
    `;
export const updateReqTags = `
        mutation updateReqTags($reqId: String!, $tags: [String]) {
            updateReqTags(reqId: $reqId, tags: $tags)
        }
    `;

export const addPlaybookTemplate = `
    mutation addPlaybookTemplate($tenantId: String!, $playbookTemplateData: PlaybookTemplateInput!) {
        addPlaybookTemplate(tenantId: $tenantId, playbookTemplateData: $playbookTemplateData) {
            id
            title
            desc
            categories 
            {
                id
                title
                desc
                stages{
                    id
                    tasks{
                        id
                        color
                        title
                        desc
                        notes
                        {
                            id
                            content  
                            by
                            createdAt
                            modifiedAt                
                        }
                        status
                        dueDSE
                        weight  
                        assignedTo                                             
                    }
                }
            }
            order
        }
    }`;

export const updatePlaybookTemplate = `
    mutation updatePlaybookTemplate($tenantId: String!, $playbookTemplateId: String!, $playbookTemplateData: PlaybookTemplateInput!) {
        updatePlaybookTemplate(tenantId: $tenantId, playbookTemplateId: $playbookTemplateId, playbookTemplateData: $playbookTemplateData) {
            id
            title
            desc
            categories 
            {
                id
                title
                desc
                stages{
                    id
                    tasks{
                        id
                        color
                        title
                        desc
                        notes
                        {
                            id
                            content  
                            by
                            createdAt
                            modifiedAt               
                        }
                        status
                        dueDSE
                        weight  
                        assignedTo                                             
                    }
                }
            }
            order
        }
    }`;

export const logicalDeletePlaybookTemplate = `
    mutation logicalDeletePlaybookTemplate($tenantId: String!, $playbookTemplateId: String!) {
        logicalDeletePlaybookTemplate(tenantId: $tenantId, playbookTemplateId: $playbookTemplateId) 
    }`;

export const createPlaybookReqContact = `
    mutation createPlaybookReqContact($reqId: String!, $contactId: String!, $playbookTemplateId: String!) {
        createPlaybookReqContact(reqId: $reqId, contactId: $contactId, playbookTemplateId: $playbookTemplateId) {
            id
            playbookTemplateId
            playbookObj
            {
                id
                title
                desc
                categories 
                {
                    id
                    title
                    desc
                    stages{
                        id
                        tasks{
                            id
                            color
                            title
                            desc
                            notes
                            {
                                id
                                content  
                                by
                                createdAt
                                modifiedAt               
                            }
                            status
                            dueDSE
                            weight  
                            assignedTo                                             
                        }
                    }
                }
                order
            }
        }
    }`;

export const updatePlaybookReqContact = `
    mutation updatePlaybookReqContact($reqId: String!, $contactId: String!, $playbookTemplateId: String!, $playbookReqContactData: PlaybookReqContactInput!) {
        updatePlaybookReqContact(reqId: $reqId, contactId: $contactId, playbookTemplateId: $playbookTemplateId, playbookReqContactData: $playbookReqContactData) {
            id
            playbookTemplateId
            playbookObj
            {
                id
                title
                desc
                categories 
                {
                    id
                    title
                    desc
                    stages{
                        id
                        tasks{
                            id
                            color
                            title
                            desc
                            notes
                            {
                                id
                                content  
                                by
                                createdAt
                                modifiedAt     
                            }
                            status
                            dueDSE
                            weight  
                            assignedTo                                             
                        }
                    }
                }
                order
            }
        }
    }`;

export const createPlaybookTemplateReqLink = `
    mutation createPlaybookTemplateReqLink($tenantId: String, $reqId: String!, $playbookTemplateId: String!) {
        createPlaybookTemplateReqLink(tenantId: $tenantId, reqId: $reqId, playbookTemplateId: $playbookTemplateId) {
            id
            reqId
            playbookTemplateId           
        }
    }`;

export const updatePlaybookTemplateReqLink = `
    mutation updatePlaybookTemplateReqLink($reqId: String!, $playbookTemplateId: String!, $playbookTemplateReqLinkData: PlaybookTemplateReqLinkInput!) {
        updatePlaybookTemplateReqLink(reqId: $reqId, playbookTemplateId: $playbookTemplateId, playbookTemplateReqLinkData: $playbookTemplateReqLinkData) {
            id
            reqId
            playbookTemplateId    
        }
    }`;

export const deletePlaybookTemplateReqLink = `
    mutation deletePlaybookTemplateReqLink($reqId: String!) {
        deletePlaybookTemplateReqLink(reqId: $reqId) 
    }`;

export const createContactTag = `
    mutation createContactTag($tenantId: String, $contactTagData: ContactTagInput!, $reqId: String) {
        createContactTag(tenantId: $tenantId, contactTagData: $contactTagData, reqId: $reqId) {
            tagId
            name
            order
        }
    }`;

export const updateContactTag = `
    mutation updateContactTag($tenantId: String, $contactTagId: String!, $contactTagData: ContactTagInput!, $reqId: String) {
        updateContactTag(tenantId: $tenantId, contactTagId: $contactTagId, contactTagData: $contactTagData, reqId: $reqId) {
            tagId
            name
            order
        }
    }`;

export const deleteContactTag = `
    mutation deleteContactTag($tenantId: String, $contactTagId: String!, $reqId: String) {
        deleteContactTag(tenantId: $tenantId, contactTagId: $contactTagId, reqId: $reqId) 
    }`;

export const disableUser = `
    mutation disableUser($tenantId: String!, $userId: String!, $userName: String!) {
        disableUser(tenantId: $tenantId, userId: $userId, userName: $userName) {
            id
            firstName
            lastName
            username
            active
        }
    }`;

export const enableUser = `
    mutation enableUser($tenantId: String!, $userId: String!, $userName: String!) {
        enableUser(tenantId: $tenantId, userId: $userId, userName: $userName) {
            id
            firstName
            lastName
            username
            active
        }
    }`;

export const reAssignTodos = `
    mutation reAssignTodos($oldUserId: String!, $newUserId: String!) {
        reAssignTodos(oldUserId: $oldUserId, newUserId: $newUserId) 
    }`;

export const updateReqContactTags = `
    mutation updateReqContactTags($reqId: String, $contactId: String!, $contactTagData: [ContactTagInput]!) {
        updateReqContactTags(reqId: $reqId, contactId: $contactId, contactTagData: $contactTagData) 
    }`;

export const deleteContactFromReq = `
    mutation deleteContactFromReq($contactId: String!, $reqId: String!) {
        deleteContactFromReq(contactId: $contactId, reqId: $reqId) 
         }`;

export const setIntegrationKey = `
    mutation setIntegrationKey($iName: String!, $apiKey: String!) {
        setIntegrationKey(iName: $iName, apiKey: $apiKey)
        {
            iName
            apiKey
        }
    }`;

export const deleteIntegrationKey = `
    mutation deleteIntegrationKey($iName: String!) {
        deleteIntegrationKey(iName: $iName) 
    }`;

export const startClick2Call = `
    mutation startClick2Call($agentNumber: String!, $contactNumber: String!) {
        startClick2Call(agentNumber: $agentNumber, contactNumber: $contactNumber)
        {
            msg
            sid
            parentSid
            dateCreated
            dateUpdated
            to
            from
            contactNumber        
        }
    }
`;

export const setFeatureClick2Call = `
    mutation setFeatureClick2Call($tenantId: String!, $setVal: Boolean!) {
        setFeatureClick2Call(tenantId: $tenantId, setVal: $setVal) 
    }`;

export const addHistory = `
    mutation addHistory($historyInput: HistoryInput!) {
        addHistory(input: $historyInput) {
            id
            type
            title
            description
            reqId
            requisition
            userId
            dueOn
            completedDate
            completedBy {
                id
                firstName
                lastName
            }
            users {
                id
                firstName
                lastName
            }
            contacts {
                id
                firstName
                lastName
            }
            isPublic
        }
    }`;

export const setMarketInsightsFeatureByName = `
    mutation setMarketInsightsFeatureByName($feature: String!, $tenantId: String!, $setVal: String!) {
        setMarketInsightsFeatureByName(feature: $feature, tenantId: $tenantId, setVal: $setVal) 
    }`;

export const setFeatureByName = `
    mutation setFeatureByName($feature: String!, $tenantId: String!, $setVal: Boolean!) {
        setFeatureByName(feature: $feature, tenantId: $tenantId, setVal: $setVal) 
    }`;

export const setFeatureByNameForUser = `
    mutation setFeatureByNameForUser($feature: String!, $userId: String!, $setVal: Boolean!) {
        setFeatureByNameForUser(feature: $feature, userId: $userId, setVal: $setVal) 
    }`;

export const addToAllReqs = `
    mutation addToAllReqs($userId: String!, $value: Boolean!) {
        addToAllReqs(userId: $userId, value: $value) 
    }`;

export const createReportingTemplate = `
    mutation createReportingTemplate($reportingTemplateData: String!, $tenantId: String, $systemTarget: String) {
        createReportingTemplate(reportingTemplateData: $reportingTemplateData, tenantId: $tenantId, systemTarget: $systemTarget) 
    }`;

export const updateReportingTemplate = `
    mutation updateReportingTemplate($reportingTemplateData: String!, $tenantId: String, $systemTarget: String) {
        updateReportingTemplate(reportingTemplateData: $reportingTemplateData, tenantId: $tenantId, systemTarget: $systemTarget)          
    }`;

export const logicalDeleteReportingTemplate = `
    mutation logicalDeleteReportingTemplate($reportingTemplateId: String!, $tenantId: String, $systemTarget: String) {
        logicalDeleteReportingTemplate(reportingTemplateId: $reportingTemplateId, tenantId: $tenantId, systemTarget: $systemTarget) 
    }`;

export const setUserSettingKey = `
    mutation setUserSettingKey($iName: String!, $settingValue: String!) {
        setUserSettingKey(iName: $iName, settingValue: $settingValue)
        {
            iName
            settingValue
        }
    }`;

export const setUserSettingKeyForUser = `
    mutation setUserSettingKeyForUser($iName: String!, $userId: String!, $settingValue: String!) {
        setUserSettingKeyForUser(iName: $iName, userId: $userId, settingValue: $settingValue)
        {
            iName
            settingValue
        }
    }`;

export const deleteUserSettingKey = `
    mutation deleteUserSettingKey($iName: String!) {
        deleteUserSettingKey(iName: $iName) 
    }`;

export const terminateCall = `
    mutation terminateCall($callServiceId: String!) {
        terminateCall(callServiceId: $callServiceId) 
    }`;

export const transferCall = `
mutation transferCall($callServiceId: String!, $transferPhone: String!) {
    transferCall(callServiceId: $callServiceId, transferPhone: $transferPhone) 
}`;

export const createReportObject = `
mutation createReportObject($ReportObjectData: String!, $tenantId: String, $systemTarget: String) {
    createReportObject(ReportObjectData: $ReportObjectData, tenantId: $tenantId, systemTarget: $systemTarget) 
}`;

export const updateReportObject = `
mutation updateReportObject($ReportObjectData: String!, $tenantId: String, $systemTarget: String) {
    updateReportObject(ReportObjectData: $ReportObjectData, tenantId: $tenantId, systemTarget: $systemTarget)          
}`;

export const logicalDeleteReportObject = `
mutation logicalDeleteReportObject($ReportObjectId: String!, $tenantId: String, $systemTarget: String) {
    logicalDeleteReportObject(ReportObjectId: $ReportObjectId, tenantId: $tenantId, systemTarget: $systemTarget) 
}`;

export const createReportingSnapshot = `
    mutation createReportingSnapshot($input: CreateReportingSnapshotInput!) {
        createReportingSnapshot(input: $input) {
            id
            sharedWithPublic {
                id
                path
            }
            uploadUrl
        }
    }
`;

export const addProspectBonzoCampaign = `
mutation addProspectBonzoCampaign($iAPIKeyBONZO: String!, $bonzoCampaignId: String, $bonzoContactData: String) {
    addProspectBonzoCampaign(iAPIKeyBONZO: $iAPIKeyBONZO, bonzoCampaignId: $bonzoCampaignId, bonzoContactData: $bonzoContactData) 
}`;

export const proxyPost = `
mutation proxyPost($url: String!, $headers: String, $body: String, $parseBody: Boolean) {
    proxyPost(url: $url, headers: $headers, body: $body, parseBody: $parseBody) 
}`;

export const setSystemKey = `
    mutation setSystemKey($iName: String!, $sysKey: String!) {
        setSystemKey(iName: $iName, sysKey: $sysKey)
        {
            iName
            sysKey
        }
    }`;

export const deleteSystemKey = `
    mutation deleteSystemKey($iName: String!) {
        deleteSystemKey(iName: $iName) 
    }`;

export const sendEmail = `
    mutation sendEmail($emailObj: EmailInputTwo!) {
        sendEmail(emailObj: $emailObj) 
    }`;

export const provisionNumber = `
    mutation provisionNumber($number: String!) {
        provisionNumber(number: $number) 
    }`;

export const removeNumber = `
    mutation removeNumber($number: String!, $numberSid: String!) {
        removeNumber(number:$number, numberSid: $numberSid) 
    }`;
export const bulkUpdateReqContactsMutation = `
    mutation bulkUpdateReqContacts($reqId: String!, $filters: ContactFilter, $tags: [ContactTagInput], $assignedUser:UserInput ){
        bulkUpdateReqContacts(reqId: $reqId, filters: $filters, tags: $tags, assignedUser: $assignedUser)
        {
            contactsUpdated
        }
    }
`;

export const sendSMS = `
    mutation sendSMS($to: String!, $from: String!, $body: String, $contact: ContactInput, $controlPoint: String, $position: String, $sessionId: String, $arrMMSMedia: [MMSMediaInput], $reqId: String, $tenantId: String, $sequenceId: String ) {
        sendSMS(to: $to, from: $from, body: $body, contact: $contact, controlPoint: $controlPoint, position: $position, sessionId: $sessionId, arrMMSMedia: $arrMMSMedia, reqId: $reqId, tenantId: $tenantId, sequenceId: $sequenceId) 
    }`;

export const checkUserMentionMutation = `
    mutation checkUserMention($historyId: String!){
        checkUserMention(historyId: $historyId)
    }
`;

export const buildMMSMediaStorageLocation = `
    mutation buildMMSMediaStorageLocation($fileName: String!, $fileType: String!, $mmsMediaDocId: String) {
        buildMMSMediaStorageLocation(fileName: $fileName, fileType: $fileType, mmsMediaDocId: $mmsMediaDocId) {
            id
            documentName
            createdAt
            lastUpdatedAt
            uploadUrl
            url
            ownerId
            thumbUrl
            fileType
        }
    }`;

export const deleteMMSMedia = `
    mutation deleteMMSMedia($mmsMediaDocId: String!) {
        deleteMMSMedia(mmsMediaDocId: $mmsMediaDocId) 
    }`;

export const setLiveCounter = `
    mutation setLiveCounter($newValue: String!) {
        setLiveCounter(newValue: $newValue) 
    }`;

export const resizeMMSMediaImages = `
    mutation resizeMMSMediaImages($mmsMediaDoc: MMSMediaInput!) {
        resizeMMSMediaImages(mmsMediaDoc: $mmsMediaDoc) 
    }`;

export const deactivateConversation = `
    mutation deactivateConversation($number: String!) {
        deactivateConversation(number: $number) 
    }`;

export const setConversationActive = `
    mutation setConversationActive($number: String!, $contact: ContactInput) {
        setConversationActive(number: $number, contact: $contact) 
    }`;
export const createBonzoWebhookMutation = `
    mutation createBonzoWebhook($apiKey: String!, $webhookCode: String!){
        createBonzoWebhook(apiKey: $apiKey, webhookCode: $webhookCode)
    }
`;

export const updatePipeflowSettingsMutation = `
    mutation updatePipeflowSettings($webhookCode: String!, $pipeflowSettings: PipeflowSettingsInput!){
        updatePipeflowSettings(webhookCode: $webhookCode,pipeflowSettings: $pipeflowSettings)
    }
`;

export const changeContactDripCampaignMutation = `
    mutation changeContactDripCampaign($apiKey: String!, $webhookCode: String!, $campaignID: String!, $contactData: String!){
        changeContactDripCampaign(apiKey: $apiKey, webhookCode: $webhookCode, campaignID: $campaignID, contactData: $contactData)
        {
            success
            createdNew
            message
        }
    }
`;

export const updateUserMetricMutation = `
    mutation updateUserMetric($type: String!, $count: Int!){
        updateUserMetric(type: $type, count: $count)
    }
`;

export const updateMessageRead = `
    mutation updateMessageRead($agentNumber: String!, $contactNumber: String!) {
        updateMessageRead(agentNumber: $agentNumber, contactNumber: $contactNumber) 
    }`;

export const updateMessageChainDeleted = `
    mutation updateMessageChainDeleted($agentNumber: String!, $contactNumber: String!) {
        updateMessageChainDeleted(agentNumber: $agentNumber, contactNumber: $contactNumber) 
    }`;

export const setUserNotificationsKey = `
    mutation setUserNotificationsKey($subscription: String!) {
        setUserNotificationsKey(subscription: $subscription) 
    }`;

export const deleteUserNotificationsKey = `
    mutation deleteUserNotificationsKey($subscription: String!) {
        deleteUserNotificationsKey(subscription: $subscription) 
    }`;

export const isUserNotificationsKeySet = `
    mutation isUserNotificationsKeySet($subscription: String!) {
        isUserNotificationsKeySet(subscription: $subscription) 
    }`;
export const updateReqContactsMutation = `
    mutation updateReqContacts($reqContacts: String!, $assignedUser: UserInput, $reqId: String!){
        updateReqContacts(reqContacts: $reqContacts, assignedUser:$assignedUser, reqId: $reqId)
    }
`;

export const createSequence = `
    mutation createSequence($sequenceData: String!, $tenantId: String, $systemTarget: String) {
        createSequence(sequenceData: $sequenceData, tenantId: $tenantId, systemTarget: $systemTarget) 
    }`;

export const updateSequence = `
    mutation updateSequence($sequenceData: String!, $tenantId: String, $systemTarget: String) {
        updateSequence(sequenceData: $sequenceData, tenantId: $tenantId, systemTarget: $systemTarget)          
    }`;

export const logicalDeleteSequence = `
    mutation logicalDeleteSequence($sequenceId: String!, $tenantId: String, $systemTarget: String) {
        logicalDeleteSequence(sequenceId: $sequenceId, tenantId: $tenantId, systemTarget: $systemTarget) 
    }`;
export const createNotificationMutation = `
    mutation createNotification($userId: String!, $type: String!, $data: String!){
        createNotification(userId: $userId, type: $type, data: $data)
    }
`;

export const addContactsToPipelineMutation = `
    mutation addContactsToPipeline($contacts: [ContactInput]!, $assignee: AssigneeInput, $reqId: String, $tenantId: String, $isAgents: Boolean){
        addContactsToPipeline(contacts: $contacts, assignee: $assignee, reqId: $reqId, tenantId: $tenantId, isAgents: $isAgents)){
            success
            message
        }
    }
`;

export const createSequenceQueue = `
    mutation createSequenceQueue($sequenceQueueData: SequenceQueueInput!) {
        createSequenceQueue(sequenceQueueData: $sequenceQueueData)
         {
            createdAt
            scheduledAt
            eventId
            queueId
            target
            source
            channel            
            sequenceId
            system
            step
            contact {
                id
                firstName
                lastName
            }
            req {
                id
                name
            }
            user
            {
                id
                firstName
                lastName
                tenantId
            }
        }
    }`;

export const updateSequenceQueue = `
    mutation updateSequenceQueue($sequenceQueueData: SequenceQueueInput!) {
        updateSequenceQueue(sequenceQueueData: $sequenceQueueData)
         {
            createdAt
            scheduledAt
            eventId
            queueId
            target
            source
            channel            
            sequenceId
            system
            step
            contact {
                id
                firstName
                lastName
            }
            req {
                id
                name
            }
            user
            {
                id
                firstName
                lastName
                tenantId
            }
        }  
    }`;

export const deleteSequenceQueue = `
    mutation deleteSequenceQueue($sequenceQueueKey: String!) {
        deleteSequenceQueue(sequenceQueueKey: $sequenceQueueKey) 
    }`;

export const createCreditProfileMutation = `
    mutation createCreditProfile($tenantId: String!){
        createCreditProfile(tenantId: $tenantId) {
            dateCreated
            createdBy
            notificationEmail
            lastUpdated
            totalCredits
            creditMax
            notificationThreshold
            enabled
        }
    }
`;

export const updateCreditProfileMutation = `
    mutation updateCreditProfile($tenantId: String!, $fields: String!){
        updateCreditProfile(tenantId: $tenantId, fields: $fields)
    }
`;

export const addEmailTemplate = `
    mutation addEmailTemplate($templateTitle: String!, $templateBody: String!, $templateSubject: String) {
        addEmailTemplate(templateTitle: $templateTitle, templateBody: $templateBody, templateSubject: $templateSubject) {
            templateId
            templateTitle
            templateBody
            templateSubject
        }
    }`;

export const updateEmailTemplate = `
    mutation updateEmailTemplate($templateId: String!, $templateTitle: String!, $templateBody: String!, $templateSubject: String) {
        updateEmailTemplate(templateId: $templateId, templateTitle: $templateTitle, templateBody: $templateBody, templateSubject: $templateSubject)
    }`;

export const deleteEmailTemplate = `
    mutation deleteEmailTemplate($templateId: String!) {
        deleteEmailTemplate(templateId: $templateId)
    }`;

export const addEmailSignature = `
    mutation addEmailSignature($signature: String!) {
        addEmailSignature(signature: $signature)
    }`;

export const createSMSTemplate = `
    mutation createSMSTemplate($smsTemplateData: String!, $tenantId: String, $systemTarget: String) {
        createSMSTemplate(smsTemplateData: $smsTemplateData, tenantId: $tenantId, systemTarget: $systemTarget) 
    }`;

export const updateSMSTemplate = `
    mutation updateSMSTemplate($smsTemplateData: String!, $tenantId: String, $systemTarget: String) {
        updateSMSTemplate(smsTemplateData: $smsTemplateData, tenantId: $tenantId, systemTarget: $systemTarget)          
    }`;

export const logicalDeleteSMSTemplate = `
    mutation logicalDeleteSMSTemplate($smsTemplateId: String!, $tenantId: String, $systemTarget: String) {
        logicalDeleteSMSTemplate(smsTemplateId: $smsTemplateId, tenantId: $tenantId, systemTarget: $systemTarget) 
    }`;

export const createSequenceQueueTestData = `
    mutation createSequenceQueueTestData($sequenceQueueData: SequenceQueueInput!, $howMany: Int!, $target: String!) {
        createSequenceQueueTestData(sequenceQueueData: $sequenceQueueData, howMany: $howMany, target: $target)        
    }`;

export const addContactMCE = `
    mutation addContactMCE($contact: ContactInput!) {
        addContactMCE(contact: $contact)
        {
            id
            firstName
            lastName
            nmlsId
            createdAt
            active
            company
            emails {
                type
                address
            }
            phones {
                type
              number
            }
            links {
                type
                url
            }
            address {
                street
                street2
                city
                state
                zipCode
            }
            sourceId
            source
            lastUpdatedAt
          }
        
    }`;

export const addMCEToTenant = `
    mutation addMCEToTenant($contact: ContactInput!, $tenantId: String, $mceId: String!) {
        addMCEToTenant(contact: $contact, tenantId: $tenantId, mceId: $mceId)
    }`;

export const addMCEToUser = `
    mutation addMCEToUser($contact: ContactInput!, $userId: String, $mceId: String!) {
        addMCEToUser(contact: $contact, userId: $userId, mceId: $mceId)     
    }`;

export const addMCEToReq = `
    mutation addMCEToReq($contact: ContactInput!, $reqId: String!, $mceId: String!) {
        addMCEToReq(contact: $contact, reqId: $reqId, mceId: $mceId)
    }`;

export const weeklyTWGProcessing = `
    mutation weeklyTWGProcessing($numberToProcess: Int, $sort: String) {
        weeklyTWGProcessing(numberToProcess: $numberToProcess, sort: $sort) 
    }`;

export const leadsBuilder = `
    mutation leadsBuilder($numberToProcess: Int, $sort: String) {
        leadsBuilder(numberToProcess: $numberToProcess, sort: $sort) 
    }`;
export const setCallForwardingNumber = `
    mutation setCallForwardingNumber($smsNumber: String!, $userNumber: String!) {
        setCallForwardingNumber(smsNumber: $smsNumber, userNumber: $userNumber)
    }`;

export const updateContactLastUpdatedAtField = `
    mutation updateContactLastUpdatedAtField($contactId: String!, $reqId: String, $isPublic: String!) {
        updateContactLastUpdatedAtField(contactId: $contactId, reqId: $reqId, isPublic: $isPublic)
    }
`;
export const addUserToGroup = `
    mutation addUserToGroup($userName: String!, $groupName: String!) {
        addUserToGroup(userName: $userName, groupName: $groupName)
    }`;

export const removeUserFromGroup = `
    mutation removeUserFromGroup($userName: String!, $groupName: String!) {
        removeUserFromGroup(userName: $userName, groupName: $groupName)
    }`;

export const addTenantStripeSubDoc = `
    mutation addTenantStripeSubDoc($tenantId: String!, $subscriptionId: String!, $tenantTitle: String) {
        addTenantStripeSubDoc(tenantId: $tenantId, subscriptionId: $subscriptionId, tenantTitle: $tenantTitle)
    }`;

export const updateDefaultPaymentMethod = `
    mutation updateDefaultPaymentMethod($tenantId: String!, $customerId: String!, $cardId: String!) {
        updateDefaultPaymentMethod(tenantId: $tenantId, customerId: $customerId, cardId: $cardId)
    }`;

export const addPaymentMethod = `
    mutation addPaymentMethod($card: CardInput!, $customerId: String!, $subscriptionId: String!) {
        addPaymentMethod(card: $card, customerId: $customerId, subscriptionId: $subscriptionId) {
            success
            errorType
            errorMessage
        }
    }`;

export const deletePaymentMethod = `
    mutation deletePaymentMethod($cardId: String!, $customerId: String!, $subscriptionId: String!) {
        deletePaymentMethod(cardId: $cardId, customerId: $customerId, subscriptionId: $subscriptionId) {
            pmId
            name
            type
            brand
            last4
            exp_month
            exp_year
            billingDetails {
                line1
                line2
                city
                state
                postalCode
                country
            }
        }
    }
`;

export const enableDeviceRememberingForOldUser = `
    mutation enableDeviceRememberingForOldUser($userName: String!) {
        enableDeviceRememberingForOldUser(userName: $userName) 
    }`;

export const autoLogOutUser = `
    mutation autoLogOutUser($userName: String!, $data: String!) {
        autoLogOutUser(userName: $userName, data: $data) {
        userName
        data
        }
    }
`;

export const getProratedSubscriptionChange = `
    mutation getProratedSubscriptionChange($subscriptionId: String!) {
        getProratedSubscriptionChange(subscriptionId: $subscriptionId) {
            invoiceId
            dateSeconds
            date
            periodStart
            periodEnd
            items {
                invoiceItemId
                description
                amountStr
                priceId
                productId
                interval
            }
            itemsStr
            amountStr
            status
            pdfLink
        }
    }
`;

export const updateSubscriptionInterval = `
    mutation updateSubscriptionInterval($subscriptionId: String!, $tenantId: String!) {
        updateSubscriptionInterval(subscriptionId: $subscriptionId, tenantId: $tenantId)
    }
`;

export const addBankAccount = `
    mutation addBankAccount($publicToken: String!, $accountIds: [String]!, $subscriptionId: String!, $customerId: String!) {
        addBankAccount(publicToken: $publicToken, accountIds: $accountIds, subscriptionId: $subscriptionId, customerId: $customerId) {
            pmId
            name
            type
            brand
            last4
            exp_month
            exp_year
            billingDetails {
                line1
                line2
                city
                state
                postalCode
                country
            }
        }
    }
`;

export const notifyUsernameBeforeLogOut = `
    mutation notifyUsernameBeforeLogOut($userName: String!, $userID: String!) {
        notifyUsernameBeforeLogOut(userName: $userName, userID: $userID)
    }
`;

export const notifyUserDeviceBeforeLogOut = `
    mutation notifyUserDeviceBeforeLogOut($deviceKey: String!) {
        notifyUserDeviceBeforeLogOut(deviceKey: $deviceKey)
    }
`;

export const setUserDeviceInfo = `
    mutation setUserDeviceInfo($deviceInfo: DeviceInfoInput!, $userID: String, $email: String) {
        setUserDeviceInfo(deviceInfo: $deviceInfo, userID: $userID, email: $email)        
    }`;

export const deleteUserDeviceInfo = `
    mutation deleteUserDeviceInfo($deviceKey: [String!], $userID: String) {
        deleteUserDeviceInfo(deviceKey: $deviceKey, userID: $userID)        
    }`;

export const shareReportTemplateToUser = `
    mutation shareReportTemplateToUser($reportTemplateId: String, $userId: String) {
        shareReportTemplateToUser(reportTemplateId: $reportTemplateId, userId: $userId)
    }
`;

export const addToZapierTriggerQueue = `
    mutation addToZapierTriggerQueue($triggerName: String!, $zapierTriggerBody: ZapierTriggerBody!) {
        addToZapierTriggerQueue(triggerName: $triggerName, zapierTriggerBody: $zapierTriggerBody)
    }
`;

export const addRole = `
mutation addRole($tenantId: String!, $nameInput: String!, $permissions: String, $overwrite: Boolean) {
    addRole(tenantId: $tenantId, nameInput: $nameInput, permissions: $permissions, overwrite: $overwrite) {
        tenantId
        data
    }
}`;

export const duplicateRole = `
mutation duplicateRole($tenantId: String!, $roleIdToDuplicate: String!, $newRoleName: String!) {
    duplicateRole(tenantId: $tenantId, roleIdToDuplicate: $roleIdToDuplicate, newRoleName: $newRoleName) {
        tenantId
        data
    }
}`;

export const updateRolePriorities = `
mutation updateRolePriorities($tenantId: String!, $input: [RolePrioritiesInput]!) {    
    updateRolePriorities(tenantId: $tenantId, input: $input) {
        tenantId
        data
    }
}`;

export const updateRoleName = `
mutation updateRoleName($tenantId: String!, $text: String!, $roleId: String!) {    
    updateRoleName(tenantId: $tenantId, text: $text, roleId: $roleId ) {
        tenantId
        data
    }
}`;
export const deleteRole = `
mutation deleteRole($tenantId: String!, $roleId: String!) {    
    deleteRole(tenantId: $tenantId, roleId: $roleId ) {
        tenantId
        data
    }
}`;

export const savePermissions = `
mutation savePermissions($input: DynamoKey!, $permissions: String!) {    
    savePermissions(input: $input, permissions: $permissions) {
        tenantId
        data
    }
}`;

export const updateUserRoleIds = `
mutation updateUserRoleIds($tenantId: String!, $userId: String!, $roleIds: [String]!) {    
    updateUserRoleIds(tenantId: $tenantId, userId: $userId, roleIds: $roleIds) {
        tenantId
        data
    }
}`;

export const forceUserLogout = `
mutation forceUserLogout($username: String!) {    
    forceUserLogout(username: $username) 
}`;

export const setUserDefaultRoutePreferenceMutation = `
mutation setUserDefaultRoutePreference($value: String!) {
    setUserDefaultRoutePreference(value: $value)
}
`;

export const uploadTailLog = `
mutation uploadTailLog($tenantId: String!, $userId: String!, $data: String) {    
    uploadTailLog(tenantId: $tenantId, userId: $userId, data: $data) {
        tenantId
        userId
        data
    }
}`;

export const setAlertSubscription = `
    mutation setAlertSubscription($alertSubscriptionInput: alertSubscriptionInput!) {
        setAlertSubscription(alertSubscriptionInput: $alertSubscriptionInput) 
        {
            id
            desc
            zipCode
            distance
            method
            userId
            firstName
            lastName
            userName
            alertLocation
            {
                lat
                lon
                desc
            }
            frequency
            agents            
            {
                id
                firstName
                lastName               
                agt_name
            }
            filters
            pulseFilters
            nmlsId
            lastRan
            lastUpdatedBy
            lastUpdated
            lastChecked
        }
    }`;

export const deleteAlertSubscription = `
    mutation deleteAlertSubscription($id: String!) {
        deleteAlertSubscription(id: $id)         
    }`;

export const setExclusion = `
    mutation setExclusion($exclusionInput: exclusionInput!) {
        setExclusion(exclusionInput: $exclusionInput) 
        {
            id           
            userId
            firstName
            lastName
            type
            reason           
            lastUpdatedBy
            lastUpdated     
        }
    }`;

export const deleteExclusion = `
    mutation deleteExclusion($id: String!) {
        deleteExclusion(id: $id)         
    }`;

export const setHubSpotIntegration = `
    mutation setHubSpotIntegration($code: String!) {
        setHubSpotIntegration(code: $code)         
    }`;

export const sendContactsToHubSpot = `
    mutation sendContactsToHubSpot($contacts: String!) {
        sendContactsToHubSpot(contacts: $contacts)         
    }`;

export const setSalesForceIntegration = `
    mutation setSalesForceIntegration($code: String!) {
        setSalesForceIntegration(code: $code)         
    }`;

export const sendContactsToSalesForce = `
    mutation sendContactsToSalesForce($contacts: String!) {
        sendContactsToSalesForce(contacts: $contacts)         
    }`;

export const setSalesForceExportDefault = `
    mutation setSalesForceExportDefault($selection: String!) {
        setSalesForceExportDefault(selection: $selection)         
    }`;

export const sendContactToTotalExpert = `
    mutation sendContactToTotalExpert($contact: String!) {
        sendContactToTotalExpert(contact: $contact)         
    }`;

export const logActivity = `
    mutation logActivity($activityInput: String!) {
        logActivity(activityInput: $activityInput)         
    }`;

export const updateAgent = `
    mutation updateAgent($agent: agentUpdatedInput!) {
        updateAgent(agent: $agent) 
        {
            id
            agt_name
            agt_email
            agt_phone
            off_phone
            title
            address
            company
        }
    }`;


export const tenantAdminSubscriptionChange = `
    mutation tenantAdminSubscriptionChange($data: String!) {
        tenantAdminSubscriptionChange(data: $data)         
    }`;

export const setProductTiers = `
    mutation setProductTiers($data: String!) {
        setProductTiers(data: $data)         
    }`;

export const creditTierForDisabledUser = `
    mutation creditTierForDisabledUser($args: String!) {
        creditTierForDisabledUser(args: $args)         
    }`;
    
export const updateSelfServeTenantEmail = `
    mutation updateSelfServeTenantEmail($tenantId: String!, $username: String!) {
        updateSelfServeTenantEmail(tenantId: $tenantId, username: $username)         
    }`;

export const getSubscriptionSummary = `
    mutation getSubscriptionSummary($tenantId: String!) {
        getSubscriptionSummary(tenantId: $tenantId)         
    }`;

export const getAffiliateCouponCode = `
    mutation getAffiliateCouponCode($tenantId: String) {
        getAffiliateCouponCode(tenantId: $tenantId)         
    }`;

export const inviteWithAffiliateCode = `
    mutation inviteWithAffiliateCode($code: String!, $email: String!) {
        inviteWithAffiliateCode(code: $code, email: $email)         
    }`;

export const changeUserPermissionTier = `
    mutation changeUserPermissionTier($tenantId: String!, $userId: String!, $lookup_key: String, $remove: Boolean) {
        changeUserPermissionTier(tenantId: $tenantId, userId: $userId, lookup_key: $lookup_key, remove: $remove)         
    }`;

