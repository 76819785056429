// https://docs.aws.amazon.com/appsync/latest/devguide/aws-appsync-real-time-data.html

// Make service injectable so all components can use the service
import { Injectable } from '@angular/core';

// gql Translate raw queries so graphQL can use them
// import { gql } from 'apollo-angular-boost';
import gql from 'graphql-tag';

import { Observable } from 'rxjs';
// import { Apollo, gql, QueryRef, ApolloQueryResult } from 'apollo-angular-boost';

// Using appSync to have a public endpoint that graphQL can hit
// Cognito baked into it
// AUTH_TYPE is pulled in for list of possible constants - we use AUTH_TYPE.AMAZON_COGNITO_USER_POOLS
import AppSync, { AUTH_TYPE } from 'aws-appsync';

// Need cognito for authenticating calls
import { CognitoService } from './cognito.service';

import { ContactInput } from '../shared/model/contacts';

// Import environment variables for service
import { environment } from '../../environments/environment';

import { BehaviorSubject } from 'rxjs';

import {
  setLiveCounter,
  deactivateConversation,
  setConversationActive,
  removeNumber,
  updateMessageRead,
  updateMessageChainDeleted,
  createSequence,
  updateSequence,
  logicalDeleteSequence,
  createSequenceQueue,
  updateSequenceQueue,
  deleteSequenceQueue,
  sendSMS,
  logicalDeleteSMSTemplate,
  updateSMSTemplate,
  createSMSTemplate,
  createSequenceQueueTestData,
} from '../graphql/mutations';
import {
  getLiveCounter,
  listConversations,
  searchMessageConversationalHistory,
  getMMSMediaUrl,
  readNumber,
  getContactFromUserContacts,
  readSequence,
  listSequences,
  readSequenceQueue,
  listSequenceQueue,
  searchSequenceQueue,
  listSMSTemplates,
  readSMSTemplate,
  readSequenceLockTable,
  getTotalSentSequenceSummary,
  getOptOutSequenceSummary,
  getSMSDeliveryTotals,
} from '../graphql/queries';
import { updatedLiveCounter, updatedMessageLog, updatedAgentMessageLog } from '../graphql/subscriptions';
const phone = require('phone');
import { MMSMedia } from '../shared/model/user';
import { AppsyncService } from '../graphql/graphql.appsync.service';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { SequenceSummary, SmsDeliveryTotal } from 'graphql.types';

@Injectable()
export class SMSServiceNeo {
  constructor(
    private appsync: AppsyncService,
    private cognitoService: CognitoService
  ) {}

  // Use RXJs BehaviorSubject to pass whether new messages are available
  private newMessagesSource = new BehaviorSubject(false);
  newMessages = this.newMessagesSource.asObservable();

  // Client - AppSync<NormalizedCacheObject>

  // test() {
  //   // console.log('NOTIFICATIONS INITIALIZED!');
  //   this.client
  //     .subscribe({
  //       query: gql(updatedLiveCounter),
  //     })
  //     .subscribe((data) => alert('Your Count - ' + data));
  // }
  // url - get from our environment
  // region - hard code for now but move back to environment
  // jwtToken -in theory this is the token we store and use until it expires
  // async() => await(await this.cognitoService.getIdToken().toPromise()).idToken

  changeNewMessages(isMessages: boolean) {
    this.newMessagesSource.next(isMessages);
  }

  client = this.appsync.nonHydratedClient();

  // // Client just contains all the apollo stuff we were using Apollo-Boost before
  // client = new AppSync({
  //   url: environment.graphQLUri,
  //   region: 'us-west-2',
  //   auth: {
  //     type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  //     jwtToken: async () => await (await this.cognitoService.getIdToken().toPromise()).idToken,
  //   },
  // });

  getLiveCounter(placeholder: string) {
    return this.client.query<{ getLiveCounter: string }>({
      query: gql(getLiveCounter),
      variables: { placeholder: placeholder },
      fetchPolicy: 'network-only',
    });
  }

  setLiveCounter(newValue: string) {
    return this.client.mutate<{ setLiveCounter: string }>({
      mutation: gql(setLiveCounter),
      variables: {
        newValue: newValue,
      },
    });
  }

  updatedLiveCounter() {
    return this.client.subscribe<{ updatedLiveCounter: string }>({
      query: gql(updatedLiveCounter),
    });
  }

  updatedMessageLog(userId: string, contactNumber: string, agentNumber: string) {
    let cleanPhoneContact = contactNumber;
    console.log('newMessages - update Message Log', userId, contactNumber, agentNumber);
    if (contactNumber) {
      // Normalized phone number in E.164 format
      cleanPhoneContact = phone(contactNumber);
      if (cleanPhoneContact.length > 0) cleanPhoneContact = cleanPhoneContact[0];
      else cleanPhoneContact = contactNumber.replace(/\D/g, '');
    }

    let cleanPhoneAgent = phone(agentNumber);
    if (cleanPhoneAgent.length > 0) cleanPhoneAgent = cleanPhoneAgent[0];
    else cleanPhoneAgent = agentNumber.replace(/\D/g, '');

    return this.client.subscribe({
      query: gql(updatedMessageLog),
      variables: { userId: userId, contactNumber: cleanPhoneContact, agentNumber: cleanPhoneAgent },
    });
  }

  updatedAgentMessageLog(userId: string, agentNumber: string) {
    let cleanPhoneAgent = phone(agentNumber);
    if (cleanPhoneAgent.length > 0) cleanPhoneAgent = cleanPhoneAgent[0];
    else cleanPhoneAgent = agentNumber.replace(/\D/g, '');

    console.log('newMessages - update Message Log', userId, cleanPhoneAgent);

    return this.client.subscribe({
      query: gql(updatedAgentMessageLog),
      variables: { userId: userId, agentNumber: cleanPhoneAgent },
    });
  }

  listConversations(
    assignedNumber: string,
    size: string,
    start: string,
    direction: boolean,
    active: boolean,
    filter: string
  ) {
    return this.client.query({
      query: gql(listConversations),
      variables: {
        assignedNumber: assignedNumber,
        size: size,
        start: start,
        direction: direction,
        active: active,
        filter: filter,
      },
      fetchPolicy: 'network-only',
    });
  }

  deactivateConversation(assignedNumber: string) {
    return this.client.mutate({
      mutation: gql(deactivateConversation),
      variables: {
        number: assignedNumber,
      },
    });
  }

  setConversationActive(assignedNumber: string, contact: ContactInput) {
    delete contact?.__typename;

    return this.client.mutate({
      mutation: gql(setConversationActive),
      variables: {
        number: assignedNumber,
        contact: contact,
      },
    });
  }

  searchMessageConversationalHistory(
    searchPhrase: string,
    businessAddress: string,
    conversationAddress: string,
    size: string,
    start: string,
    direction: string
  ) {
    return this.client.query({
      query: gql(searchMessageConversationalHistory),
      variables: {
        searchPhrase: searchPhrase,
        businessAddress: businessAddress,
        conversationAddress: conversationAddress,
        size: size,
        start: start,
        direction: direction,
      },
      fetchPolicy: 'network-only',
    });
  }

  public GetMMSMediaUrl(mmsMediaDocId: string, thumb: string = '') {
    return this.client.query({
      query: gql(getMMSMediaUrl),
      variables: { mmsMediaDocId: mmsMediaDocId, thumb: thumb },
      fetchPolicy: 'network-only',
    });
  }

  public removeNumber(number: string, numberSid: string) {
    return this.client.mutate({
      mutation: gql(removeNumber),
      variables: {
        number: number,
        numberSid: numberSid,
      },
    });
  }

  readNumber(number: string) {
    return this.client.query({
      query: gql(readNumber),
      variables: { number: number },
      fetchPolicy: 'network-only',
    });
  }

  getContactFromUserContacts(phoneNumber: string) {
    return this.client.query({
      query: gql(getContactFromUserContacts),
      variables: {
        phoneNumber: phoneNumber,
      },
      fetchPolicy: 'network-only',
    });
  }

  updateMessageRead(agentNumber: string, contactNumber: string) {
    return this.client.mutate({
      mutation: gql(updateMessageRead),
      variables: { agentNumber: agentNumber, contactNumber: contactNumber },
    });
  }

  updateMessageChainDeleted(agentNumber: string, contactNumber: string) {
    return this.client.mutate({
      mutation: gql(updateMessageChainDeleted),
      variables: { agentNumber: agentNumber, contactNumber: contactNumber },
    });
  }

  // CREATE
  public createSequence(sequenceData: any, tenantId: string, systemTarget: string = 'USER') {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete sequenceData.__typename;

    // createSequence is obj passed in but then convert to JSON string for now to pass up and down
    let sequenceDataJSON = JSON.stringify(sequenceData);

    return this.client.mutate<{ createSequence: string }>({
      mutation: gql(createSequence),
      variables: {
        sequenceData: sequenceDataJSON,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
    });
  }

  // READ
  readSequence(sequenceId: string, networkOnly: boolean = true, tenantId: string, systemTarget: string = 'USER') {
    return this.client.query({
      query: gql(readSequence),
      variables: {
        sequenceId: sequenceId,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
      fetchPolicy: 'network-only',
    });
  }

  //  // fetchPolicy: networkOnly ? "network-only" : "cache-first",

  // LIST
  listSequences(sequenceFilter: string, tenantId: string) {
    return this.client.query({
      query: gql(listSequences),
      variables: {
        sequenceFilter: sequenceFilter,
        tenantId: tenantId,
      },
      fetchPolicy: 'network-only',
    });
  }

  // UPDATE
  updateSequence(sequenceData: any, tenantId: string, systemTarget: string = 'USER') {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete sequenceData.__typename;

    let sequenceDataJSON = JSON.stringify(sequenceData);

    return this.client.mutate({
      mutation: gql(updateSequence),
      variables: {
        sequenceData: sequenceDataJSON,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
      update: (store, { data: { updateSequence } }: any) => {
        // Add a new history item.
      },
    });
  }

  // DELETE - LOGICAL only for now
  logicalDeleteSequence(sequenceId: string, tenantId: string, systemTarget: string = 'USER') {
    return this.client.mutate({
      mutation: gql(logicalDeleteSequence),
      variables: {
        sequenceId: sequenceId,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
      update: (store, { data: { updateSequence } }: any) => {
        // Add a new history item.
      },
    });
  }

  // CREATE
  public createSequenceQueue(sequenceQueueData: any) {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete sequenceQueueData.__typename;

    return this.client.mutate({
      mutation: gql(createSequenceQueue),
      variables: {
        sequenceQueueData: sequenceQueueData,
      },
    });
  }

  // READ
  readSequenceQueue(sequenceQueueKey: string) {
    return this.client.query({
      query: gql(readSequenceQueue),
      variables: {
        sequenceQueueKey: sequenceQueueKey,
      },
      fetchPolicy: 'network-only',
    });
  }

  // SEARCH
  public searchSequenceQueue(sequenceQueueKey: string) {
    return this.client.query({
      query: gql(searchSequenceQueue),
      variables: {
        sequenceQueueKey: sequenceQueueKey,
      },
      fetchPolicy: 'network-only',
    });
  }

  // LIST
  public listSequenceQueue(sequenceFilter: string) {
    return this.client.query({
      query: gql(listSequenceQueue),
      variables: {
        sequenceFilter: sequenceFilter,
      },
      fetchPolicy: 'network-only',
    });
  }

  // UPDATE
  updateSequenceQueue(sequenceQueueData: any) {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete sequenceQueueData.__typename;

    let sequenceDataJSON = JSON.stringify(sequenceQueueData);

    return this.client.mutate({
      mutation: gql(updateSequenceQueue),
      variables: {
        sequenceQueueData: sequenceQueueData,
      },
      update: (store, { data: { updateSequence } }: any) => {
        // Add a new history item.
      },
    });
  }

  // DELETE - Physical
  deleteSequenceQueue(sequenceQueueKey: string) {
    return this.client.mutate({
      mutation: gql(deleteSequenceQueue),
      variables: {
        sequenceQueueKey: sequenceQueueKey,
      },
      update: (store, { data: { updateSequence } }: any) => {
        // Add a new history item.
      },
    });
  }

  sendSMS(
    to: string,
    from: string,
    body: string,
    contact: ContactInput,
    controlPoint: string,
    position: string,
    sessionId: string,
    arrMMSMedia: MMSMedia[],
    reqId: string,
    tenantId: string,
    sequenceId: string
  ) {
    delete contact?.__typename;

    return this.client.mutate({
      mutation: gql(sendSMS),
      variables: {
        to: to,
        from: from,
        body: body,
        contact: contact,
        controlPoint: controlPoint,
        position: position,
        sessionId: sessionId,
        arrMMSMedia: arrMMSMedia,
        reqId: reqId,
        tenantId: tenantId,
        sequenceId: sequenceId,
      },
    });
  }

  // CREATE
  public createSMSTemplate(smsTemplateData: any, tenantId: string, systemTarget: string = 'USER') {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete smsTemplateData.__typename;

    // createSMSTemplate is obj passed in but then convert to JSON string for now to pass up and down
    let smsTemplateDataJSON = JSON.stringify(smsTemplateData);

    return this.client.mutate({
      mutation: gql(createSMSTemplate),
      variables: {
        smsTemplateData: smsTemplateDataJSON,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
    });
  }

  // READ
  readSMSTemplate(smsTemplateId: string, networkOnly: boolean = true, tenantId: string, systemTarget: string = 'USER') {
    return this.client.query({
      query: gql(readSMSTemplate),
      variables: {
        smsTemplateId: smsTemplateId,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
      fetchPolicy: 'network-only',
    });
  }

  //  // fetchPolicy: networkOnly ? "network-only" : "cache-first",

  // LIST
  listSMSTemplates(smsTemplateFilter: string, tenantId: string) {
    return this.client.query({
      query: gql(listSMSTemplates),
      variables: {
        smsTemplateFilter: smsTemplateFilter,
        tenantId: tenantId,
      },
      fetchPolicy: 'network-only',
    });
  }

  // UPDATE
  updateSMSTemplate(smsTemplateData: any, tenantId: string, systemTarget: string = 'USER') {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete smsTemplateData.__typename;

    let smsTemplateDataJSON = JSON.stringify(smsTemplateData);

    return this.client.mutate({
      mutation: gql(updateSMSTemplate),
      variables: {
        smsTemplateData: smsTemplateDataJSON,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
      update: (store, { data: { updateSMSTemplate } }: any) => {
        // Add a new history item.
      },
    });
  }

  // DELETE - LOGICAL only for now
  logicalDeleteSMSTemplate(smsTemplateId: string, tenantId: string, systemTarget: string = 'USER') {
    return this.client.mutate({
      mutation: gql(logicalDeleteSMSTemplate),
      variables: {
        smsTemplateId: smsTemplateId,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
      update: (store, { data: { updateSMSTemplate } }: any) => {
        // Add a new history item.
      },
    });
  }

  // CREATE
  public createSequenceQueueTestData(sequenceQueueData: any, howMany: number, target: string) {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete sequenceQueueData.__typename;

    return this.client.mutate({
      mutation: gql(createSequenceQueueTestData),
      variables: {
        sequenceQueueData: sequenceQueueData,
        howMany: howMany,
        target: target,
      },
    });
  }

  readSequenceLockTable(threadId: string = '0') {
    return this.client.query({
      query: gql(readSequenceLockTable),
      variables: {
        threadId: threadId,
      },
      fetchPolicy: 'network-only',
    });
  }

  // LIST
  getTotalSentSequenceSummary(sequenceID: string) {
    return this.client.query<{ getTotalSentSequenceSummary: SequenceSummary[] }>({
      query: gql(getTotalSentSequenceSummary),
      variables: { sequenceID: sequenceID },
      fetchPolicy: 'network-only',
    });
  }

  // LIST
  getOptOutSequenceSummary(sequenceID: string) {
    return this.client.query<{ getOptOutSequenceSummary: SequenceSummary[] }>({
      query: gql(getOptOutSequenceSummary),
      variables: { sequenceID: sequenceID },
      fetchPolicy: 'network-only',
    });
  }

  // LIST
  getSMSDeliveryTotals(userID: string, sequenceID: string) {
    return this.client.query<{ getSMSDeliveryTotals: SmsDeliveryTotal }>({
      query: gql(getSMSDeliveryTotals),
      variables: { userID: userID, sequenceID: sequenceID },
      fetchPolicy: 'network-only',
    });
  }
}
