import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { take, map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { v4 as uuid } from 'uuid';
import { ReqContact, ContactTag, newContactTag } from '../../../shared/model/contacts';
import { ContactService } from '../../../services/contact.service';
import { RequisitionService } from '../../../services/requisition.service';
import { IntegrationsService } from '../../../services/integrations.service';
import { TodoService } from '../../../services/todo.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ConfirmUpdateDialog } from './confirm-update-contacts-dialog.component';

@Component({
  selector: 'mm-edit-contacts-dialog',
  templateUrl: './edit-contacts-dialog.component.html',
  styleUrls: ['./edit-contacts-dialog.component.scss'],
})
export class EditContactsDialog implements OnInit {
  reqUsers: any[];
  reqContacts: ReqContact[];
  reqName: string;
  reqId: string;
  tenantId: string;
  assignedUser: any;
  noChange: boolean = true;
  skipNotification: boolean = false;
  selectedContactTags: ContactTag[] = [];
  totalSize: number;
  allSelectedBulk: boolean;
  activeFilters: any;
  contactTagsControl: FormControl = new FormControl();
  contactTags: ContactTag[] = [];
  tenantIdTags: string = null;
  filteredContactTags: Observable<ContactTag[]>;
  selectableContactTag: boolean = true;
  removableContactTag: boolean = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('contactTagInput') contactTagInput: ElementRef<HTMLInputElement>;
  @ViewChild('assignUserSelect') assignUserSelect: MatSelect;

  constructor(
    private contactService: ContactService,
    private reqService: RequisitionService,
    private integrationsService: IntegrationsService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<EditContactsDialog>
  ) {}

  ngOnInit() {
    this.reqContacts = this.data.selectedContacts;
    this.reqId = this.data.reqId;
    this.tenantId = this.data.tenantId;
    this.reqName = this.data.reqName;
    this.totalSize = this.data.totalSize;
    this.activeFilters = this.data.activeFilters;
    this.allSelectedBulk = this.data.allSelectedBulk;
    this.getReqUsersForDropdown();
    this.getContactTags();
  }

  getReqUsersForDropdown() {
    const reqUserSub = this.reqService.getReqUsers(this.reqId).subscribe(({ data: { getReqUsers } }) => {
      this.reqUsers = getReqUsers;
      reqUserSub.unsubscribe();
    });
  }

  getContactTags() {
    const tagListSub = this.contactService
      .listContactTags(this.tenantId, '{}', this.reqId)
      .pipe(take(1))
      .subscribe(
        ({ data: { listContactTags } }) => {
          this.contactTags = listContactTags.sort((a, b) => a.order - b.order);
          tagListSub.unsubscribe();
          this.filteredContactTags = this.contactTagsControl.valueChanges.pipe(
            startWith<string | ContactTag>(''),
            map((value) => {
              return typeof value === 'string' ? value : value?.name;
            }),
            map((name) => (name ? this._filterContactTags(name) : this.contactTags.slice()))
          );
        },
        (error) => {
          console.log('ContactTagStageSelectComponent: There was an error loading contactTags ', error);
        }
      );
  }

  // Method to filter contact tags autocomplete list based on what has been typed so far
  private _filterContactTags(name: string): ContactTag[] {
    const filterValue = name.toLowerCase();

    return this.contactTags.filter((contactTag) => contactTag.name.toLowerCase().indexOf(filterValue) === 0);
  }

  onAssignmentChange(event: any): void {
    if (event.value === 'No Change') {
      this.noChange = true;
      this.assignedUser = '';
    } else {
      this.assignedUser = this.reqUsers.filter((ele) => ele.id === event.value)[0];
      this.noChange = false;
    }
  }

  //contact tags
  selectedContactTag(event: MatAutocompleteSelectedEvent): void {
    // Only push to list if value exists AND is not already on list

    // Search for value in main list first
    const found = this.contactTags.findIndex((element) => {
      return element.tagId === event.option.value.tagId;
    });

    // console.log("selected found", found);
    // console.log("selectedContactTag event.option.value)", event.option.value);
    // console.log("selectedContactTag filteredContactTags", this.filteredContactTags);

    if (found > -1) {
      // Now Search for value in selected list
      const alreadySelected = this.selectedContactTags.findIndex(
        (element) => element.tagId === event.option.value.tagId
      );

      if (alreadySelected < 0) {
        this.selectedContactTags.push(event.option.value);
      }
    }

    this.contactTagInput.nativeElement.value = '';
    this.contactTagsControl.setValue(null);
  }

  addContactTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    console.log('contact tag picker add event on input', event);

    // push to list if value (exists OR not) AND is not already on list

    if ((value || '').trim()) {
      // Search for value in main list first
      const selectedItem: ContactTag[] = this.contactTags.filter((option) =>
        option.name.toLowerCase().includes(value.toLowerCase())
      );

      // Now Search for value in selected list - if enter on only one match
      if (selectedItem.length === 1) {
        const alreadySelected = this.selectedContactTags.findIndex(
          (element) => element.tagId === selectedItem[0].tagId
        );

        if (alreadySelected < 0) {
          const newContactTagItemToAdd: ContactTag = JSON.parse(JSON.stringify(newContactTag));
          newContactTagItemToAdd.tagId = selectedItem[0].tagId;
          newContactTagItemToAdd.name = selectedItem[0].name;
          this.selectedContactTags.push(newContactTagItemToAdd);
        }
      } else {
        // Add new tag
        const newContactTagItem: ContactTag = {
          tagId: uuid(),
          name: value,
          order: this.contactTags.length + 1,
        };

        const alreadySelected = this.selectedContactTags.findIndex(
          (element) => element.tagId === newContactTagItem.tagId
        );

        this.contactService
          .createContactTag(this.tenantId, newContactTagItem, this.reqId)
          .pipe(take(1))
          .subscribe(
            (result) => {
              this.contactTags.push(result.data.createContactTag);
              this.snackBar.open(`Tag ${result.data.createContactTag.name} created!`, null, {
                duration: 3000,
              });
              if (alreadySelected < 0) {
                this.selectedContactTags.push(newContactTagItem);
              }
            },
            (error) => {
              console.log('contactTagsComponent :: createContactTag error ', error);
              this.snackBar.open('An error occurred adding the Contact Tag.', null, {
                duration: 3000,
              });
            }
          );
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.contactTagsControl.setValue(null);
  }

  removeContactTag(item: ContactTag): void {
    console.log('Remove ContactTag', item);
    console.log('Remove ContactTag - this.selectedReqs', this.selectedContactTags);

    // ***TODO MAYBE Add a warning before removal
    const index = this.selectedContactTags.indexOf(item);

    if (index >= 0) {
      this.selectedContactTags.splice(index, 1);
    }
  }

  // Build expected inputs for Contact so Tags array can be updated/replaced with current
  dedupeContactTags(tags) {
    // removing duplicate tags before update
    const dedupedTags = tags.reduce((a, b) => {
      delete b['__typename'];
      if (!a.some((tag) => tag.tagId === b.tagId)) a.push(b);
      return a;
    }, []);
    return dedupedTags;
  }

  onSubmitChanges() {
    const contactsWithAssignee = this.reqContacts.filter(
      (a) => a.assignedUser?.id && a.assignedUser.id != this.assignedUser?.id
    );
    const contactsToUpdateAssignee = this.noChange
      ? []
      : this.reqContacts.filter((a) => a.assignedUser?.id != this.assignedUser?.id);
    if (!this.assignedUser && contactsToUpdateAssignee.length && !this.noChange) {
      //logic to handle unassign all contacts
      const dialogRef = this.dialog.open(ConfirmUpdateDialog, {
        data: {
          contactsWithAssignee: contactsToUpdateAssignee,
          assignee: this.assignedUser,
          message: 'These contacts are currently assigned to other users, do you want to un-assign them all?',
        },
        panelClass: 'alert-dialog-component',
      });
      dialogRef.afterClosed().subscribe((data) => {
        if (!!data) {
          this.updateTagsAndAssignee();
        }
      });
    } else if (this.assignedUser?.id && contactsWithAssignee.length) {
      const dialogRef = this.dialog.open(ConfirmUpdateDialog, {
        data: { contactsWithAssignee, assignee: this.assignedUser },
        panelClass: 'alert-dialog-component',
      });
      dialogRef.afterClosed().subscribe((data) => {
        if (!!data) {
          this.updateTagsAndAssignee();
        }
      });
    } else if (this.selectedContactTags.length || contactsToUpdateAssignee.length) {
      this.updateTagsAndAssignee();
    }
  }

  updateTagsAndAssignee() {
    if (this.totalSize > 1000 && this.allSelectedBulk) {
      this.contactService
        .bulkUpdateReqContacts(
          this.reqId,
          this.activeFilters,
          this.selectedContactTags.map((tag) => (delete tag.__typename, tag)),
          this.assignedUser?.id || ''
        )
        .subscribe(
          (data) => console.log(data),
          (err) => console.log('CONTACT MULTI EDIT ERROR - ', err)
        );
    } else {
      const now = new Date();
      const reqContacts: ReqContact[] = this.reqContacts.map((a) => {
        a.tags = this.dedupeContactTags([...a.tags, ...this.selectedContactTags]);
        delete a.id;
        return { ...a, pKey: a.reqId, rKey: a.contactId, lastUpdatedAt: now };
      });
      console.log(reqContacts);
      let assignedUserEMail;
      if (!this.noChange) {
        assignedUserEMail =
          this.assignedUser?.emails.find((a) => a.type === 'notifications') ||
          this.assignedUser?.emails.find((a) => a.type === 'primary');
        const assignedUser = this.assignedUser
          ? {
              id: this.assignedUser?.id,
              firstName: this.assignedUser?.firstName,
              lastName: this.assignedUser?.lastName || '',
            }
          : null;
        //update assignee for contacts
        reqContacts.forEach((_, i) => {
          reqContacts[i].assignedUser = assignedUser;
        });
      }
      this.contactService
        .updateReqContacts(
          JSON.stringify(reqContacts),
          this.assignedUser && {
            firstName: this.assignedUser.firstName,
            lastName: this.assignedUser.lastName,
            id: this.assignedUser.id,
          },
          this.reqId
        )
        .subscribe(({ data: { updateReqContacts } }) => {
          if (this.assignedUser) {
            if (assignedUserEMail && !this.skipNotification) {
              const url = window.location.href;
              // Assignment Changed
              const emailObj = {
                to: assignedUserEMail.address,
                subject: `You have been assigned ${updateReqContacts} req-contacts`,
                html: `
              <p>You have been assigned a new req-contact</p>
              <p><b>Requisition:</b> ${this.reqName}</p>
              <p></p>
              <p><a href="${url}" >View in Model Match</a></p>
              `,
              };

              this.integrationsService
                .sendEmail(emailObj)
                .pipe(take(1))
                .subscribe(
                  () => {},
                  (error) => {
                    console.log('onAssignmentChange : Error sending notification email ', error);
                  }
                );
            }
          }
          this.dialogRef.close(updateReqContacts);
        });
    }
  }

  removeReqContact(id) {
    this.reqContacts = this.reqContacts.filter((a) => a.id !== id);
  }

  onAssignmentToggle(): void {
    this.assignUserSelect.toggle();
  }

  ngOnDestroy() {}
}
