import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, Observer } from 'rxjs';

import {
  createReportingTemplate,
  updateReportingTemplate,
  logicalDeleteReportingTemplate,
  createReportObject,
  updateReportObject,
  logicalDeleteReportObject,
  createReportingSnapshot,
  shareReportTemplateToUser,
} from '../graphql/mutations';
import {
  CreateReportingSnapshot,
  GetReqUsers,
  ReadReportingSnapshot,
  ShareReportTemplateToUser,
} from '../graphql/types';
import {
  getReportingCounts,
  readReportingTemplate,
  listReportingTemplates,
  readReportObject,
  listReportObjects,
  getReqFiltersUsers,
  readReportingSnapshot,
  getESQueryResults,
} from '../graphql/queries';
import { RequisitionService } from './requisition.service';
import { ActivityCriteria, Page, SortOption } from 'app/shared/model/filter';
import {} from './../graphql/queries';
import {
  CreateReportingSnapshotInput,
  esFilter,
  ReadReportingSnapshotInput,
  ReportingSnapshot,
  ReportingSnapshotPublicLink,
  ReportTemplate,
} from '../shared/model/reporting';
import { ReportObj } from '../shared/model/reporting';
import { map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ReportingService {
  todoFilters: ActivityCriteria;
  historyFilters: ActivityCriteria;
  private createReportingObservers: Observer<any>[] = [];
  private completeReportingObservers: Observer<any>[] = [];

  constructor(
    private apollo: Apollo,
    private requisitionService: RequisitionService,
    private http: HttpClient
  ) {}

  public getReportingCounts(
    index: string[],
    page: Page,
    aggregation: string,
    aggs: string,
    sort: string,
    size: string,
    dateTarget: string,
    startDate: string,
    endDate: string,
    reqIds: string[],
    userIds: string[],
    esFilters: esFilter[],
    filters: string,
    endPoint: string = ''
  ): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getReportingCounts),
      variables: {
        index: index,
        page: page,
        aggregation: aggregation,
        aggs: aggs,
        sort: sort,
        size: size,
        dateTarget: dateTarget,
        startDate: startDate,
        endDate: endDate,
        reqIds: reqIds,
        userIds: userIds,
        esFilters: esFilters,
        filters: filters,
        endPoint: endPoint,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // CREATE
  public createReportingTemplate(
    reportingTemplateData: any,
    tenantId: string,
    systemTarget: string = 'USER'
  ): Observable<any> {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete reportingTemplateData.__typename;

    // createReportingTemplate is obj passed in but then convert to JSON string for now to pass up and down
    let reportingTemplateDataJSON = JSON.stringify(reportingTemplateData);

    return this.apollo.mutate<any>({
      mutation: gql(createReportingTemplate),
      variables: {
        reportingTemplateData: reportingTemplateDataJSON,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
    });
  }

  getESQueryResults(index: string, esQuery: string, endPoint: string = ''): Observable<any> {
    return this.apollo.watchQuery<string>({
      query: gql(getESQueryResults),
      variables: {
        index: index,
        esQuery: esQuery,
        endPoint: endPoint,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // READ
  readReportingTemplate(
    reportingTemplateId: string,
    networkOnly: boolean = true,
    tenantId: string,
    systemTarget: string = 'USER'
  ): Observable<any> {
    return this.apollo.watchQuery<string>({
      query: gql(readReportingTemplate),
      variables: {
        reportingTemplateId: reportingTemplateId,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  //  // fetchPolicy: networkOnly ? "network-only" : "cache-first",

  // LIST
  public listReportingTemplates(reportingTemplateFilter: string, tenantId: string): Observable<any> {
    return this.apollo.watchQuery<string>({
      query: gql(listReportingTemplates),
      variables: {
        reportingTemplateFilter: reportingTemplateFilter,
        tenantId: tenantId,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // UPDATE
  updateReportingTemplate(
    reportingTemplateData: any,
    tenantId: string,
    systemTarget: string = 'USER'
  ): Observable<any> {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete reportingTemplateData.__typename;

    return this.apollo.mutate({
      mutation: gql(updateReportingTemplate),
      variables: {
        reportingTemplateData: reportingTemplateData,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
      update: (store, { data: { updateReportingTemplate } }: any) => {
        // Add a new history item.
      },
    });
  }

  // DELETE - LOGICAL only for now
  logicalDeleteReportingTemplate(
    reportingTemplateId: string,
    tenantId: string,
    systemTarget: string = 'USER'
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(logicalDeleteReportingTemplate),
      variables: {
        reportingTemplateId: reportingTemplateId,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
      update: (store, { data: { updateReportingTemplate } }: any) => {
        // Add a new history item.
      },
    });
  }

  // CREATE
  public createReportObject(ReportObjectData: any, tenantId: string, systemTarget: string = 'USER'): Observable<any> {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete ReportObjectData.__typename;

    // createReportObject is obj passed in but then convert to JSON string for now to pass up and down
    let ReportObjectDataJSON = JSON.stringify(ReportObjectData);

    return this.apollo.mutate<any>({
      mutation: gql(createReportObject),
      variables: {
        ReportObjectData: ReportObjectDataJSON,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
    });
  }

  // READ
  readReportObject(
    ReportObjectId: string,
    networkOnly: boolean = true,
    tenantId: string,
    systemTarget: string = 'USER'
  ): Observable<any> {
    return this.apollo.watchQuery<string>({
      query: gql(readReportObject),
      variables: {
        ReportObjectId: ReportObjectId,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  //  // fetchPolicy: networkOnly ? "network-only" : "cache-first",

  // LIST
  public listReportObjects(ReportObjectFilter: string, tenantId: string): Observable<any> {
    return this.apollo.watchQuery<string>({
      query: gql(listReportObjects),
      variables: {
        ReportObjectFilter: ReportObjectFilter,
        tenantId: tenantId,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // UPDATE
  updateReportObject(ReportObjectData: any, tenantId: string, systemTarget: string = 'USER'): Observable<any> {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete ReportObjectData.__typename;

    return this.apollo.mutate({
      mutation: gql(updateReportObject),
      variables: {
        ReportObjectData: ReportObjectData,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
      update: (store, { data: { updateReportObject } }: any) => {
        // Add a new history item.
      },
    });
  }

  // DELETE - LOGICAL only for now
  logicalDeleteReportObject(ReportObjectId: string, tenantId: string, systemTarget: string = 'USER'): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(logicalDeleteReportObject),
      variables: {
        ReportObjectId: ReportObjectId,
        tenantId: tenantId,
        systemTarget: systemTarget,
      },
      update: (store, { data: { updateReportObject } }: any) => {
        // Add a new history item.
      },
    });
  }

  reorderOnDropContactTags(reportObjects: ReportObj[], tenantId: string, systemTarget: string = 'USER') {
    // loop over templates - use plain for loop to keep access to playbook service scope
    for (let i = 0; i < reportObjects.length; i++) {
      reportObjects[i].order = i;
      // contactTags[i].order = i;
      this.updateReportObject(JSON.stringify(reportObjects[i]), tenantId, systemTarget).pipe(take(1)).subscribe();
    }
  }

  public getReqFiltersUsers(reqIds: string[]): Observable<any> {
    let vars = {
      reqIds: reqIds,
    };

    return this.apollo.watchQuery<GetReqUsers>({
      query: gql(getReqFiltersUsers),
      variables: vars,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  createReportingSnapshot(reportTemplate: ReportTemplate): Observable<ReportingSnapshot> {
    return new Observable<ReportingSnapshot>((subscriber) => {
      this.apollo
        .mutate<CreateReportingSnapshot, CreateReportingSnapshotInput>({
          mutation: gql(createReportingSnapshot),
          variables: {
            input: { name: reportTemplate.title, shareWithPublic: true },
          },
        })
        .subscribe(({ data: { createReportingSnapshot: reportingSnapshot } }) => {
          const file = new File([JSON.stringify(reportTemplate)], `${reportingSnapshot.id}.json`, {
            type: 'application/json',
          });

          this.http
            .put(reportingSnapshot.uploadUrl, file, {
              headers: { 'Content-Type': file.type },
            })
            .subscribe(() => {
              subscriber.next({ ...reportingSnapshot, reportTemplate });
              subscriber.complete();
            });
        });
    });
  }

  readReportingSnapshot(linkId: string): Observable<ReportingSnapshot> {
    return new Observable<ReportingSnapshot>((subscriber) => {
      this.apollo
        .query<ReadReportingSnapshot, ReadReportingSnapshotInput>({
          query: gql(readReportingSnapshot),
          variables: { input: { linkId } },
        })
        .subscribe(({ data: { readReportingSnapshot: reportingSnapshot } }) => {
          this.http.get<ReportTemplate>(reportingSnapshot.downloadUrl).subscribe((reportTemplate) => {
            subscriber.next({ ...reportingSnapshot, reportTemplate });
            subscriber.complete();
          });
        });
    });
  }

  public shareReportTemplateToUser(reportTemplateId: string, userId: string): Observable<any> {
    return this.apollo.mutate<ShareReportTemplateToUser>({
      mutation: gql(shareReportTemplateToUser),
      variables: { reportTemplateId: reportTemplateId, userId: userId },
    });
  }
}
