import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numbers',
})
export class NumbersPipe implements PipeTransform {
  transform(val: any): any {
    if (Number(val) >= 1000000000) {
      return Math.round(val / 10000000) / 100 + 'B';
    }
    if (Number(val) >= 1000000) {
      return Math.round(val / 100000) / 10 + 'M';
    }
    if (Number(val) >= 1000) {
      return Math.round(val / 100) / 10 + 'k';
    }
    return val;
  }
}
