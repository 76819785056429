import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { CognitoService } from 'app/services/cognito.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IntegrationsService } from 'app/services/integrations.service';

@Component({
  selector: 'mm-logout-warning-component',
  templateUrl: './mm-logout-warning-component.html',
  styleUrls: ['./mm-logout-warning-component.scss'],
})
export class LogOutWarningComponent implements OnInit {
  public loading: boolean = true;
  public deviceKey: string = '';
  public field: string = '';
  public userID: string = '';
  public pageData: any = {
    field: '',
    headline: '',
    sub_headline: '',
  };

  constructor(
    private userService: UserService,
    private cognitoService: CognitoService,
    private router: Router,
    private integrationsService: IntegrationsService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { field: string; userID: string };
    this.field = state?.field;
    this.userID = state?.userID;
  }

  ngOnInit(): void {
    console.log(this.field);
    console.log(this.userID);

    this.displayPageContents(this.field).then(() => {
      console.log('last one');
      this.userService.logout();
    });
  }

  // Display dynamic page content based on the scenario
  async displayPageContents(field) {
    let IP, city, state;

    if (field === 'notifyForSingleSessionLogOut') {
      // Make the api call for list device
      let deviceData: any = await this.displayDeviceData();

      // Defaults
      IP = deviceData.IP;
      city = deviceData.city;
      state = deviceData.state;

      // If IP lookup is unavailable for this address
      if (!city && !state) {
        city = '';
        state = 'NA';
      }
    }

    // notifyForOneTimeLogOut: One time log out for users to migrate to new cognito single session system
    // notifyForSingleSessionLogOut: Auto log out for users that have MULTIPLESESSIONS disabled
    // notifyDisableMultipleSession: Auto log out for users that go from enabled to disabled in MULTIPLESESSIONS

    let pageData = [
      {
        field: 'notifyForOneTimeLogOut',
        headline: "<h1>We've securely logged you out.</h1>",
        sub_headline: '<p>There has been an update to the system, which will require you to log in again.</p>',
        contactSupport: false,
      },
      {
        field: 'notifyForSingleSessionLogOut',
        headline: "<h1>We've securely logged you out.</h1>",
        sub_headline: `<p><b>New Device:</b><br><br><b>IP Address:</b> ${IP}, <b>Location:</b> ${city} ${state}.</p>`,
        contactSupport: true,
      },
      {
        field: 'notifyDisableMultipleSessions',
        headline: "<h1>We've securely logged you out.</h1>",
        sub_headline: '<p>Your access to multiple sessions has been disabled. Please log in again.</p>',
        contactSupport: false,
      },
      {
        field: 'default',
        headline: "<h1>We've securely logged you out.</h1>",
        sub_headline: '<p>Your session has ended.</p>',
        contactSupport: false,
      },
    ];

    switch (field) {
      case 'notifyForOneTimeLogOut':
        this.pageData = pageData[0];
        break;
      case 'notifyForSingleSessionLogOut':
        this.pageData = pageData[1];
        break;
      case 'notifyDisableMultipleSessions':
        this.pageData = pageData[2];
        break;
      default:
        this.pageData = pageData[3];
    }

    this.loading = false;
  }

  // List devices from cognito and return device data object
  displayDeviceData() {
    return new Promise((resolve, reject) => {
      this.userService
        .getUserDevicesInfo(this.userID)
        .pipe(take(1))
        .subscribe(
          async (result) => {
            console.log('List devices response. ', result);
            let devices = result.data.getUserDevicesInfo.sort(
              (a, b) => b.deviceInfo.lastAuthenticated - a.deviceInfo.lastAuthenticated
            );

            let ipInfo: any = await this.getIPLocationInfo(devices[0].deviceInfo.IP.Value);

            let lastDevice = {
              IP: devices[0].deviceInfo.IP?.Value,
              city: ipInfo?.geoplugin_city,
              state: ipInfo?.geoplugin_region,
            };
            resolve(lastDevice);
          },
          (error) => {
            console.log('There was an error listing devices for the user. ', error);
            reject(error);
          }
        );
    });
  }

  // Uses geoplugin api to return ip's location data
  getIPLocationInfo(IP) {
    return new Promise((resolve, reject) => {
      let headers = {};

      let geoLocationUrl = `http://www.geoplugin.net/json.gp?ip=${IP}`;

      this.integrationsService
        .proxyGet(geoLocationUrl, JSON.stringify(headers))
        .pipe(take(1))
        .subscribe(
          (result) => {
            console.log(result);
            let parsedResult = JSON.parse(result.data.proxyGet);
            resolve(parsedResult);
          },
          (error) => {
            console.log('There was an in error in retrieving ip info.', error);
            reject(error);
          }
        );
    });
  }
}
