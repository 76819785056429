<h1 mat-dialog-title>Add Tenant</h1>
<mat-dialog-content>
  <form [formGroup]="createTenantForm">
    <mat-form-field>
      <input matInput placeholder="Tenant Name" data-testid="add-tenant-title" required formControlName="title" />
    </mat-form-field>

    <mat-form-field>
      <textarea matInput placeholder="Description" formControlName="description"></textarea>
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Address Line 1" formControlName="line1" />
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Address Line 2" formControlName="line2" />
    </mat-form-field>

    <mat-form-field class="half-width">
      <input matInput placeholder="City" formControlName="city" />
    </mat-form-field>

    <mat-form-field class="half-width">
      <input matInput placeholder="State" formControlName="state" />
    </mat-form-field>

    <mat-form-field class="half-width">
      <input matInput placeholder="Zip" formControlName="zip" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="Stripe Subscription Id" formControlName="stripeSubId" />
    </mat-form-field>
    <mat-form-field class="half-width">
      <mat-label>Tenant Type</mat-label>
      <mat-select required placeholder="Choose an option..." formControlName="type">
        <mat-option *ngFor="let type of tenantTypes" [value]="type">{{ type | titlecase }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="warn" [mat-dialog-close]="true">Cancel</button>
  <button
    mat-button
    color="primary"
    data-testid="add-tenant-submit"
    (click)="createTenant()"
    [disabled]="!createTenantForm.valid"
  >
    Add
  </button>
</mat-dialog-actions>
