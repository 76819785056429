import { Component, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { Apollo, gql } from 'apollo-angular';

import {
  setIntegrationKey,
  deleteIntegrationKey,
  addProspectBonzoCampaign,
  proxyPost,
  setSystemKey,
  deleteSystemKey,
  sendEmail,
  createBonzoWebhookMutation,
  updatePipeflowSettingsMutation,
  changeContactDripCampaignMutation,
  setAlertSubscription,
  deleteAlertSubscription,
  setExclusion,
  deleteExclusion,
  setHubSpotIntegration,
  sendContactsToHubSpot,
  setSalesForceIntegration,
  sendContactsToSalesForce,
  sendContactToTotalExpert,
  updateAgent,
  setSalesForceExportDefault,
  logActivity,
} from '../graphql/mutations';
import {
  readIntegrationKey,
  listBonzoCampaigns,
  proxyGet,
  readSystemKey,
  getCurrentOAuthToken,
  getBonzoWebhookDocQuery,
  getAlertSubscriptions,
  getAlertSubscription,
  getAlertLogs,
  getExclusion,
  getExclusions,
  getAlertDataRun,
  hubSpotUserAuthorized,
  hubSpotGet,
  salesForceGet,
} from '../graphql/queries';
import { pipeFromArray } from 'rxjs/internal/util/pipe';
import { AlertSubscription, Agents, AlertSubscriptionInput } from '../../../graphql.types';
import { Exclusion, ExclusionInput } from '../../../graphql.types';
import { map } from 'rxjs/operators';

export interface IntegrationKey {
  apiKey: string;
  iName: string;
  createdBy?: string;
  createdDate?: string;
  modifiedDate?: string;
}

export interface SystemKey {
  sysKey: string;
  iName: string;
  createdBy?: string;
  createdDate?: string;
  modifiedDate?: string;
}
export interface EmailInputTwo {
  to: string;
  subject: string;
  html: string;
  from?: string;
  cc?: string;
  bcc?: string;
  text?: string;
}

export interface createBonzoWebhookInput {
  apiKey: string;
  webhookCode: string;
}

export interface PipeflowSettings {
  candidates: String;
  meetings: String;
  offers: String;
  hires: String;
}
export interface BonzoWebhookDoc {
  getBonzoWebhookDoc: {
    pipeflowSettings: PipeflowSettings;
  };
}
// reqId: string, activity: string, contactId: string, timeISO: string
export type LogActivityInput = {
  reqId: string
  activity: string
  contactId: string
  timeISO: string
  title: string
  description: string
}

@Injectable()
export class IntegrationsService {
  constructor(private apollo: Apollo) {}

  // CREATE - SET
  public setIntegrationKey(iName: string, apiKey: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(setIntegrationKey),
      variables: {
        iName: iName,
        apiKey: apiKey,
      },
      update: (store, { data: { setIntegrationKey } }: any) => {
        // Add a new history item.
      },
    });
  }

  // READ
  readIntegrationKey(iName: string): Observable<any> {
    return this.apollo.watchQuery<IntegrationKey>({
      query: gql(readIntegrationKey),
      variables: { iName: iName },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // DELETE - PHYSICAL
  public deleteIntegrationKey(iName: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(deleteIntegrationKey),
      variables: {
        iName: iName,
      },
      update: (store, { data: { deleteIntegrationKey } }: any) => {
        // Add a new history item.
      },
    });
  }

  listBonzoCampaigns(iAPIKeyBONZO: string): Observable<any> {
    return this.apollo.watchQuery<string>({
      query: gql(listBonzoCampaigns),
      variables: { iAPIKeyBONZO: iAPIKeyBONZO },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // addProspectBonzoCampaign(iAPIKeyBONZO: String!, bonzoCampaignId: String!, bonzoContactData: String): String!
  public addProspectBonzoCampaign(
    iAPIKeyBONZO: string,
    bonzoCampaignId: string,
    bonzoContactData: string
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(addProspectBonzoCampaign),
      variables: {
        iAPIKeyBONZO: iAPIKeyBONZO,
        bonzoCampaignId: bonzoCampaignId,
        bonzoContactData: bonzoContactData,
      },
    });
  }

  public proxyPost(url: string, headers: string, body: string, parseBody: boolean = true): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(proxyPost),
      variables: {
        url: url,
        headers: headers,
        body: body,
        parseBody: parseBody,
      },
    });
  }

  proxyGet(url: string, headers: string): Observable<any> {
    return this.apollo.watchQuery<string>({
      query: gql(proxyGet),
      variables: { url: url, headers: headers },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // CREATE - SET
  public setSystemKey(iName: string, sysKey: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(setSystemKey),
      variables: {
        iName: iName,
        sysKey: sysKey,
      },
      update: (store, { data: { setSystemKey } }: any) => {
        // Add a new history item.
      },
    });
  }

  // READ
  readSystemKey(iName: string): Observable<any> {
    return this.apollo.watchQuery<SystemKey>({
      query: gql(readSystemKey),
      variables: { iName: iName },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // DELETE - PHYSICAL
  public deleteSystemKey(iName: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(deleteSystemKey),
      variables: {
        iName: iName,
      },
      update: (store, { data: { deleteSystemKey } }: any) => {
        // Add a new history item.
      },
    });
  }

  // READ Token
  getCurrentOAuthToken(iName: string, basicAuth: boolean = false): Observable<any> {
    return this.apollo.watchQuery<string>({
      query: gql(getCurrentOAuthToken),
      variables: {
        iName: iName,
        basicAuth: basicAuth,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public sendEmail(emailObj: EmailInputTwo): Observable<any> {
    return this.apollo.mutate<boolean>({
      mutation: gql(sendEmail),
      variables: { emailObj: emailObj },
    });
  }

  public createBonzoWebhookDoc(inputData: createBonzoWebhookInput): Observable<any> {
    return this.apollo.mutate<boolean>({
      mutation: gql(createBonzoWebhookMutation),
      variables: { ...inputData },
    });
  }

  public getBonzoWebhookDoc(webhookCode) {
    return this.apollo.watchQuery<BonzoWebhookDoc>({
      query: gql(getBonzoWebhookDocQuery),
      variables: { webhookCode },
      fetchPolicy: 'cache-first',
    }).valueChanges;
  }

  public changeContactDripCampaign(apiKey: String, webhookCode: String, campaignID: String, contactData: String) {
    return this.apollo.mutate<any>({
      mutation: gql(changeContactDripCampaignMutation),
      variables: { apiKey, webhookCode, campaignID, contactData },
    });
  }

  public updatePipeflowSettings(webhookCode, pipeflowSettings: PipeflowSettings) {
    return this.apollo.mutate<{ updatePipeflowSettings: Boolean }>({
      mutation: gql(updatePipeflowSettingsMutation),
      variables: { webhookCode, pipeflowSettings },
      update: (store) => {
        const query = gql(getBonzoWebhookDocQuery);
        const variables = { webhookCode };
        let data: any;
        try {
          data = store.readQuery({ query, variables });
        } catch (err) {
          data = {
            getBonzoWebhookDoc: {
              pipeflowSettings,
            },
          };
        }
        Object.entries(pipeflowSettings).forEach(([key, val]) => {
          if (val) {
            data.getBonzoWebhookDoc.pipeflowSettings[key] = val;
          }
        });
        store.writeQuery({ query, variables, data });
      },
    });
  }

  // CREATE and UPDATE - SET
  public setAlertSubscription(alertSubscriptionInput: AlertSubscriptionInput): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(setAlertSubscription),
      variables: {
        alertSubscriptionInput: alertSubscriptionInput,
      },
    });
  }

  getAlertLogs(
    startKey: string,
    size: string,
    filter: string = '',
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getAlertLogs),
      variables: {
        startKey: startKey,
        size: size,
        filter: filter,
        startDate,
        endDate,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
  // LIST
  getAlertSubscriptions(opt: string = ''): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getAlertSubscriptions),
      variables: { opt: opt },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // READ
  getAlertSubscription(alertId: string = ''): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getAlertSubscription),
      variables: { id: alertId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // DELETE - PHYSICAL
  public deleteAlertSubscription(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(deleteAlertSubscription),
      variables: {
        id: id,
      },
      update: (store, { data: { deleteAlertSubscription } }: any) => {
        // Add a new history item.
      },
    });
  }

  // CREATE and UPDATE - SET
  public setExclusion(exclusionInput: ExclusionInput): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(setExclusion),
      variables: {
        exclusionInput: exclusionInput,
      },
    });
  }

  // LIST
  getExclusions(opt: string = ''): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getExclusions),
      variables: { opt: opt },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // READ
  getExclusion(alertId: string = ''): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getExclusion),
      variables: { id: alertId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // DELETE - PHYSICAL
  public deleteExclusion(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(deleteExclusion),
      variables: {
        id: id,
      },
      update: (store, { data: { deleteExclusion } }: any) => {
        // Add a new history item.
      },
    });
  }

  getAlertDataRun(alertData: string = ''): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getAlertDataRun),
      variables: { alertData: alertData },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  setHubSpotIntegration(code: string): Observable<string> {
    return this.apollo
      .mutate<{ setHubSpotIntegration: string }>({
        mutation: gql(setHubSpotIntegration),
        variables: { code },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => JSON.parse(result.data.setHubSpotIntegration)));
  }

  hubSpotUserAuthorized(): Observable<boolean> {
    return this.apollo
      .watchQuery<{ hubSpotUserAuthorized: boolean }>({
        query: gql(hubSpotUserAuthorized),
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data.hubSpotUserAuthorized));
  }

  hubSpotGet(callName): Observable<string> {
    return this.apollo
      .watchQuery<{ hubSpotGet: string }>({
        query: gql(hubSpotGet),
        variables: { callName },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => JSON.parse(result.data.hubSpotGet)));
  }
  /** takes a stringified contacts array of objects resembling reqContact[] with extra fields
   *  will filter out any props that aren't part of the hubspot target format
   * @returns an object with properties confirming success results for bulk imports/ history items
   */
  sendContactsToHubSpot(contacts: string): Observable<any> {
    return this.apollo
      .mutate<{ sendContactsToHubSpot: string }>({
        mutation: gql(sendContactsToHubSpot),
        variables: { contacts },
      })
      .pipe(map((result) => JSON.parse(result.data.sendContactsToHubSpot)));
  }

  setSalesForceIntegration(code: string): Observable<string> {
    return this.apollo
      .mutate<{ setSalesForceIntegration: string }>({
        mutation: gql(setSalesForceIntegration),
        variables: { code },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => JSON.parse(result.data.setSalesForceIntegration)));
  }
  
  setSalesForceExportDefault(selection: string): Observable<string> {
    return this.apollo
      .mutate<{ setSalesForceExportDefault: string }>({
        mutation: gql(setSalesForceExportDefault),
        variables: { selection },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data.setSalesForceExportDefault));
  }

  sendContactsToSalesForce(contactsToSend: any[], type: 'Contact' | 'Lead' = 'Contact'): Observable<any> {
    const contacts = JSON.stringify({ contacts: contactsToSend, type })
    return this.apollo
      .mutate<{ sendContactsToSalesForce: string }>({
        mutation: gql(sendContactsToSalesForce),
        variables: { contacts },
      })
      .pipe(map((result) => JSON.parse(result.data.sendContactsToSalesForce)));
  }

  salesForceGet(callName): Observable<string> {
    return this.apollo
      .watchQuery<{ salesForceGet: string }>({
        query: gql(salesForceGet),
        variables: { callName },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => JSON.parse(result.data.salesForceGet)));
  }

  sendContactToTotalExpert(contact: string): Observable<any> {
    return this.apollo
      .mutate<{ sendContactToTotalExpert: string }>({
        mutation: gql(sendContactToTotalExpert),
        variables: { contact },
      })
      .pipe(map((result) => JSON.parse(result.data.sendContactToTotalExpert)));
  }

  logActivity(logActivityInput: LogActivityInput): Observable<any> {
    const {reqId, activity, contactId, timeISO, title, description} = logActivityInput
    if (!reqId || !activity || !contactId || !timeISO) throw new Error('missing required param for log history activity')
    
    const activityInput = JSON.stringify(logActivityInput)

    return this.apollo
      .mutate<{ logActivity: string }>({
        mutation: gql(logActivity),
        variables: { activityInput },
      })
      .pipe(map((result) => JSON.parse(result.data.logActivity)));
  }

  // agentUpdatedInput
  public updateAgent(agent: any): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(updateAgent),
      variables: {
        agent: agent,
      },
    });
  }
}
