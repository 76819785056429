import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ContactService } from 'app/services/contact.service';
import { ReqContact } from 'graphql.types';
import { of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ActivityType } from '../model/activity';
import { Requisition } from '../model/requisitions';

@Pipe({ name: 'activityIconColor' })
export class ActivityIconColorPipe implements PipeTransform {
  transform(date: string): string {
    // Return style based on if date is past due
    const today = new Date().toISOString().substring(0, 10);
    const pastDue = date.substring(0, 10) < today;
    return pastDue ? 'warn' : 'muted-light';
  }
}

@Pipe({ name: 'activityTypeLabel' })
export class ActivityTypeLabel implements PipeTransform {
  transform(value: string): string {
    return Object.keys(ActivityType).find((k) => ActivityType[k] === value);
  }
}

@Pipe({ name: 'todoReqName' })
export class ActivityReqLabel implements PipeTransform {
  constructor() {}

  transform(value: string, reqs: Requisition[]) {
    let reqList = reqs.filter((r) => r.id.indexOf(value) != -1);
    if (reqList && reqList.length > 0) {
      return reqList[0].title ? reqList[0].title : value;
    } else {
      return value;
    }
  }
}

@Pipe({ name: 'opportunityValue' })
export class OpportunityValue implements PipeTransform {
  constructor(private contactService: ContactService) {}

  transform(value: any, reqId: string) {
    console.log(value, reqId);
    if (!value || !value.length || !reqId) {
      return of('');
    }
    let contactId = value[0].id;
    return this.contactService.getReqContact(contactId, reqId).pipe(
      take(1),
      map((data) => {
        console.log(data);
        return data?.data?.getReqContact?.opportunityValue || '';
      })
    );
  }
}

@Pipe({ name: 'todoTimeLabel' })
export class TodoTimeLabel implements PipeTransform {
  constructor() {}

  transform(value: string, todoType: string) {
    if (!value) {
      return '';
    }

    let todoDate = new Date(value);
    let dueDateString = todoDate
      .toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .split(' ')
      .join('/');

    switch (todoType.toUpperCase()) {
      case ActivityType.Task:
      case ActivityType.Other:
        return dueDateString;
      case ActivityType.Call:
      case ActivityType.Meeting:
        return dueDateString + ' ' + todoDate.toLocaleTimeString('en-US');
      default:
        return '';
    }
  }
}
