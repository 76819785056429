<div class="mainDialogContainer">
  <h3>Edit Contacts</h3>
  <h5>Selected Contacts</h5>
  <mat-chip-list *ngIf="reqContacts.length <= 20">
    <mat-chip
      *ngFor="let contact of reqContacts"
      [selectable]="selectable"
      [removable]="true"
      (removed)="removeReqContact(contact.id)"
    >
      {{ contact.firstName + ' ' + contact.lastName }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
  <mat-chip-list *ngIf="reqContacts.length > 20">
    <mat-chip
      *ngFor="let contact of reqContacts.slice(0, 20)"
      [selectable]="selectable"
      [removable]="true"
      (removed)="removeReqContact(contact.id)"
    >
      {{ contact.firstName + ' ' + contact.lastName }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <span>...{{ reqContacts.length - 20 }} more</span>
  </mat-chip-list>
  <h5>Add Assignee</h5>
  <div class="mat-body-1 assign-user">
    <div fxFlex>
      <img src="assets/images/icons/person_add-black-18dp.svg" class="imgAssign" (click)="onAssignmentToggle()" />

      <mat-form-field
        class="assign-user-select"
        style="width: 100%; max-width: 180px; display: inline-block !important; height: 1em; margin: -1em 0 0 2em"
        appearance="none"
      >
        <!-- <mat-label>Assigned To: </mat-label> -->
        <mat-select #assignUserSelect (selectionChange)="onAssignmentChange($event)" placeholder="No Change">
          <mat-option value="No Change">No Change</mat-option>
          <mat-option value="Not Assigned">Not Assigned</mat-option>
          <mat-option *ngFor="let reqUser of reqUsers" [value]="reqUser.id">
            {{ reqUser.lastName }}, {{ reqUser.firstName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox
        style="margin-left: 20px"
        (change)="skipNotification = !skipNotification"
        [checked]="skipNotification"
        [hidden]="!assignedUser"
        >Skip Email Notification</mat-checkbox
      >
    </div>
  </div>
  <h5>Add Tags</h5>
  <mat-chip-list #contactTagsChipList aria-label="Contact Tag selection">
    <mat-chip
      *ngFor="let contactTag of selectedContactTags"
      [selectable]="selectableContactTag"
      [removable]="removableContactTag"
      (removed)="removeContactTag(contactTag)"
    >
      {{ contactTag.name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>

    <input
      placeholder="Type here to add a tag or create a new tag..."
      #contactTagInput
      [formControl]="contactTagsControl"
      [matAutocomplete]="autoContactTags"
      [matChipInputFor]="contactTagsChipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addContactTag($event)"
      style="min-width: 300px"
    />
    <mat-autocomplete #autoContactTags="matAutocomplete" (optionSelected)="selectedContactTag($event)">
      <mat-option *ngFor="let contactTag2 of filteredContactTags | async" [value]="contactTag2">
        {{ contactTag2.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-chip-list>
  <mat-dialog-actions>
    <button mat-button color="warn" mat-dialog-close>Cancel</button>
    <button
      mat-button
      [disabled]="noChange && selectedContactTags.length === 0"
      color="primary"
      (click)="onSubmitChanges()"
    >
      Update Contacts
    </button>
  </mat-dialog-actions>
</div>
