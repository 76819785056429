import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';

/**
 * This is the Integrations section for BombBomb
 */
@Component({
  selector: 'mm-report-obj-one',
  templateUrl: './report-one.component.html',
  styleUrls: ['./report-one.component.scss'],
})
export class ReportOneComponent {
  @Output() allDone: EventEmitter<any> = new EventEmitter();
  @Input() contactId: string;
  @Input() reqId: string;
  @Input() message: string = 'Dynamic';

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      // console.log("changes propName", propName);
      if (changes.hasOwnProperty(propName) && Object.keys(changes).length === 1) {
        switch (propName) {
          case 'contactPhone': {
            break;
          }
        }
      }
    }

    console.log('ReportOneComponent ngOnChanges');
  }

  ngOnDestroy() {}
}
