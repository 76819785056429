import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { TodoService } from '../../../services/todo.service';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Tenant } from '../../../shared/model/tenant';
import { User } from '../../../shared/model/user';

export interface DisableUserDialogButton {
  label: string;
  color?: ThemePalette;
}

export interface DisableUserDialogConfig {
  title?: string;
  message?: string;
  confirmButton?: DisableUserDialogButton;
  cancelButton?: DisableUserDialogButton;
  user: User;
  tenant: Tenant;
}

@Component({
  selector: 'mm-disable-user-dialog',
  templateUrl: './disable-user-dialog.component.html',
  styleUrls: ['./disable-user-dialog.component.scss'],
})
export class DisableUserDialogComponent {
  data: DisableUserDialogConfig;

  todosLoaded: boolean = false;
  targetUserId: string = null;

  openTodosCount = 0;
  openTodosSingleUserCount = 0;

  constructor(
    private dialogRef: MatDialogRef<DisableUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: DisableUserDialogConfig,
    private snackBar: MatSnackBar,
    private todoService: TodoService
  ) {
    this.data = data;
  }

  ngOnInit() {
    this.todoService
      .getUserTodos(this.data.user.id)
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log('onGetTodos User :: result ', result);
          this.todosLoaded = true;

          this.openTodosCount = result.data.getUserTodos.length;

          const openTodosSingleUserCount = result.data.getUserTodos.filter((item) => {
            return item.users.length === 1;
          });

          this.openTodosSingleUserCount = openTodosSingleUserCount.length;
        },
        (error) => {
          console.log('onGetTodos User :: Error Getting User Todo Info. ', error);
          this.snackBar.open('Error Getting User Todo Info', '', {
            duration: 3000,
          });
        }
      );
  }

  disableUser() {
    // Pass back new user to assign todos data
    let data = { confirm: true, targetUserId: this.targetUserId };

    this.dialogRef.close({ data: data });
  }

  cancelDisableUser() {
    // Pass back new user to assign todos data
    let data = { confirm: false, targetUserId: this.targetUserId };

    this.dialogRef.close({ data: data });
  }
}
