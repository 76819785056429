<h1 mat-dialog-title>New Contact</h1>
<mat-dialog-content>
  <form [formGroup]="createContactFormGroup">
    <mat-form-field>
      <input matInput placeholder="First Name" data-testid="add-contact-fn" formControlName="firstName" required />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Last Name" data-testid="add-contact-ln" formControlName="lastName" required />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Company" formControlName="company" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Job Title" formControlName="title" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="NMLS ID" formControlName="nmlsId" />
    </mat-form-field>
    <mat-form-field>
      <mat-chip [hidden]="!showBadge">{{ phoneType }}</mat-chip>
      <input (input)="checkPhoneNumberType($event)" matInput placeholder="Phone" formControlName="phone" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Email" formControlName="email" />
    </mat-form-field>
    <mat-form-field>
      <mat-select data-testid="add-contact-req-select" placeholder="Pipeline" formControlName="reqId">
        <mat-option *ngFor="let requisition of requisitions" [value]="requisition.reqId" [id]="requisition.reqId">
          {{ requisition.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <mat-button-toggle-group
        name="contactTypeToggle"
        aria-label="Contact Type Toggle"
        class="pill-toggle private-toggle"
        (change)="onChangeContactTypeClick($event)"
        [value]="isCompanyContact ? 'company' : 'personal'"
      >
        <mat-button-toggle value="company" matTooltip="Company Contact" [matTooltipShowDelay]="700">
          <mat-icon>group</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="personal" matTooltip="Personal Contact" [matTooltipShowDelay]="700">
          <mat-icon>person</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="warn" [mat-dialog-close]="true">Cancel</button>
  <button
    mat-button
    color="primary"
    data-testid="add-contact-submit"
    [mat-dialog-close]="false"
    [disabled]="!createContactFormGroup.valid"
    (click)="onSave($event)"
  >
    Save
  </button>
</mat-dialog-actions>
