import { CdkListbox, CdkListboxModule, CdkOption } from '@angular/cdk/listbox';
import { CdkConnectedOverlay, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgModule,
  OnDestroy,
  Output,
  Pipe,
  PipeTransform,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, from, fromEvent, Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { delay, mergeMap, retryWhen, switchMap, takeUntil } from 'rxjs/operators';
import { ModelMatchInputDirective, ModelMatchInputModule } from './betterInput.component';
import { nanoid } from 'nanoid';

@Directive({
  selector: '[mm-select-button]',
})
export class ModelMatchSelectButtonDirective {
  constructor(public hostEl: ElementRef<HTMLInputElement>) {}
}

@Directive({
  selector: '[mm-search-select]',
})
export class ModelMatchSearchSelectDirective {
  constructor(public hostEl: ElementRef<HTMLInputElement>) {}
}

@Component({
  selector: 'mm-option',
  template: `
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ['../nick_styles/nick.css'],
})
export class ModelMatchSelectOptionComponent {
  @ViewChild('content') content: TemplateRef<any>;
  @Input() value: any;
  @HostBinding('class') get classes(): string {
    return 'block px-4 py-2 text-sm hover_bg-slate-100 focus_bg-slate-100 focus_outline-none cursor-pointer disabled_cursor-not-allowed';
  }
}

@Component({
  selector: 'mm-select',
  template: `
    <div #trigger="cdkOverlayOrigin" cdkOverlayOrigin>
      <!-- If there is a search select, that is the trigger -->
      <div #wrapper>
        <div #search>
          <ng-content select="[mm-search-select]"></ng-content>
        </div>
        <div *ngIf="search.children.length < 1">
          <ng-content select="label[mm-label]"></ng-content>
          <!-- If no search select was provided, use the button as the trigger -->
          <button
            type="button"
            (click)="change(true)"
            class="w-full py-2 space-x-2 shadow-sm px-3 flex items-center border rounded-md border-slate-300 focus-within_border-blue-500 focus-within_ring-2 focus-within_ring-blue-300 text-slate-800 bg-white focus-within_text-slate-900"
          >
            <!-- #selected -->
            <div class="flex-grow text-left">
              <p *ngIf="this.value?.[0] === null || this.value?.[0] === undefined">{{ this.placeholder }}</p>
              <div>
                <ng-container *ngTemplateOutlet="selectedOptionTemplate"></ng-container>
              </div>
            </div>
            <span class="pointer-events-none flex items-center">
              <svg
                class="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
    <!-- Template for the overlay -->
    <ng-template
      #select
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayHasBackdrop]="true"
      cdkConnectedOverlayBackdropClass="no-style-class-to-hide-backdrop"
      [cdkConnectedOverlayOffsetY]="8"
      (detach)="onDetach()"
    >
      <!-- The actual listbox -->
      <div
        #listWrapper
        style="max-height: 584px;"
        [ngClass]="options.length > 0 || emptyState ? 'opacity-100' : 'opacity-0'"
        class="w-full relative rounded-md bg-white shadow-sm ring-1 ring-black ring-opacity-5 focus_outline-none flex {{
          sideBySidePreContent ? 'flex-row' : 'flex-col'
        }} overflow-hidden no-scrollbar"
      >
        <ng-content select="[mm-select-pre-content]"></ng-content>
        <ul
          #list
          cdkListbox
          (cdkListboxValueChange)="selectValue($event)"
          [cdkListboxMultiple]="multipleSelect"
          class="w-full {{
            sideBySidePreContent ? 'rounded-r-md' : 'rounded-md'
          }} bg-white shadow-sm relative ring-1 ring-black ring-opacity-5 focus_outline-none flex-grow overflow-scroll no-scrollbar"
          [ngClass]="options.length > 0 || emptyState ? 'opacity-100' : 'opacity-0'"
          (scroll)="scroll.emit($event)"
        >
          <!-- Each list item as mm-option -->
          <ng-container *ngIf="!options.length">
            <ng-container *ngTemplateOutlet="emptyState"></ng-container>
          </ng-container>
          <ng-container *ngFor="let option of options">
            <ng-container *ngIf="option.value !== value?.[0]">
              <li
                [cdkOption]="option"
                class="block px-4 py-2 text-sm hover_bg-slate-100 focus_bg-slate-100 focus_outline-none cursor-pointer disabled_cursor-not-allowed"
              >
                <ng-container *ngTemplateOutlet="option.content"></ng-container>
              </li>
            </ng-container>
          </ng-container>
          <div *ngIf="banner" style="width: 100%" class="right-0 sticky bottom-0">
            <ng-container *ngTemplateOutlet="banner"></ng-container>
          </div>
        </ul>
      </div>
    </ng-template>
  `,
  styleUrls: ['../nick_styles/nick.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .cdk-option-active {
        @apply bg-slate-100;
      }
      .cdk-option-psuedo-focused {
        @apply bg-slate-100;
      }
      ::ng-deep .no-style-class-to-hide-backdrop {
        display: none !important;
      }
    `,
  ],
})
export class ModelMatchSelectComponent implements OnDestroy, AfterViewInit {
  /**An array of one value */
  @Input() ignoreSpace: boolean = false;
  @Input() banner: TemplateRef<any>;
  @Input() sideBySidePreContent: boolean = false;
  @Input() value: [any];
  @Output() valueChange = new EventEmitter<any>();
  @Input() multipleSelect: boolean = false;
  @Input() isOpen: boolean = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() selected = new EventEmitter<any>();
  @Input() placeholder: string = '';
  @Input() emptyState: TemplateRef<any>;
  @ViewChild('search') search: ElementRef;
  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('listWrapper') listWrapper: ElementRef;
  @ViewChildren('list') list: QueryList<ElementRef>;
  @ViewChild(CdkListbox) listBox: CdkListbox<any>;
  @ViewChildren(CdkOption) cdkOptions: QueryList<CdkOption<any>>;
  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;
  @ContentChildren(ModelMatchSelectOptionComponent)
  options: QueryList<ModelMatchSelectOptionComponent>;
  @ContentChild(ModelMatchSearchSelectDirective)
  searchSelectDirective: ModelMatchSearchSelectDirective;
  @ContentChild(ModelMatchInputDirective)
  inputDirective: ModelMatchInputDirective;
  selectedOptionTemplate: TemplateRef<HTMLElement>;
  @Output() beforeDetach = new EventEmitter<void>();
  @Input() beforeDetachCallback = () => true;
  @Output() focusMe = new EventEmitter<boolean>();
  @Input() fullWidth: boolean = true;

  @Output() scroll = new EventEmitter<MouseEvent>();

  private pseudoActive: boolean = false;

  private readonly _listFocus$ = new BehaviorSubject(false);
  public readonly listFocus$: Observable<boolean> = this._listFocus$.asObservable();

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private detached$: ReplaySubject<boolean> = new ReplaySubject(1);

  private instanceId = nanoid();

  constructor(
    private hostEl: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  // window listener for clicks
  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.isOpen) return;
    this.onBackdropClick(event);
  }

  ngAfterViewInit(): void {
    const isSearchSelect = this.search.nativeElement.children.length;
    // If the search input is present, then we need to listen for focus, blur, keydown events

    if (this.inputDirective?.el?.nativeElement) {
      from(['focus', 'blur', 'keydown'])
        .pipe(
          mergeMap((event) => fromEvent(this.inputDirective.el.nativeElement, event)),
          takeUntil(this.destroyed$)
        )
        .subscribe((event) => {
          switch (event.type) {
            case 'focus':
              if (this.options.length || (!this.options.length && this.emptyState)) {
                this.isOpen = true;
                this.isOpenChange.emit(this.isOpen);
              }
              break;
            case 'keydown':
              if (!(event instanceof KeyboardEvent)) return;
              if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                event.preventDefault();
                event.stopPropagation();
                this._listFocus$.next(true);
                this.listBox?.focus();
                // We no longer need the pseudo active, because we are now
                // actually active on the listbox. Automatically select the first
                // option if we are arrowing down from the input
                // Otherwise, select the last option if we are arrowing up
                if (this.pseudoActive) {
                  if (event.key === 'ArrowDown') {
                    this.listBox?._setActiveOption(this.cdkOptions.get(1));
                  } else {
                    this.listBox?._setActiveOption(this.cdkOptions.last);
                  }
                }
                // We are no longer pseudo active
                this.pseudoActive = false;
              } else if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                this.selected.emit(this.cdkOptions.first.value);
              }
              break;
            case 'blur':
              if (
                event instanceof FocusEvent &&
                !this._listFocus$.getValue() &&
                event.relatedTarget instanceof HTMLElement &&
                event.relatedTarget.closest('ul') !== this.list?.first?.nativeElement
              ) {
                this.isOpen = false;
                this.isOpenChange.emit(this.isOpen);
              }
          }
        });
    }

    // Listen for changes in the options
    this.cdkOptions.changes.pipe(takeUntil(this.destroyed$)).subscribe((options) => {
      if (options.length !== 0) {
        // If we have options, we should make the first one pseudo active
        this.pseudoActive = true;
        // Remove the pseudo active class from all options that are not the first
        options.forEach((option) => {
          const newClass = option.element
            .getAttribute('class')
            .split(' ')
            .filter((c: string) => c !== 'cdk-option-psuedo-focused')
            .join(' ');
          option.element.setAttribute('class', newClass);
        });
        const prevClass = options.first.element.getAttribute('class');
        options.first.element.setAttribute('class', prevClass + ' cdk-option-psuedo-focused');
      }
    });

    // Listen for changes in the listbox itself
    // And from there, the focus and keydown events
    this.list.changes
      .pipe(
        switchMap((element) => {
          return from(['focus', 'keydown']).pipe(
            mergeMap((event) => {
              if (element.first?.nativeElement) {
                return fromEvent(element.first.nativeElement, event);
              } else {
                return of({});
              }
            })
          );
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (e: KeyboardEvent | FocusEvent) => {
          if ('key' in e) {
            if (
              e.key !== 'ArrowDown' &&
              e.key !== 'ArrowUp' &&
              e.key !== 'Enter' &&
              e.key !== 'Escape' &&
              e.key !== 'Space'
            ) {
              e.preventDefault();
              e.stopPropagation();
              this.inputDirective.el.nativeElement.focus();
              const keyIsAlphaNumericAndSingleCharacter = e.key.length === 1 && e.key.match(/[a-z0-9]/i);
              if (keyIsAlphaNumericAndSingleCharacter) this.inputDirective.el.nativeElement.value += e.key;
            }
          } else {
            setTimeout(() => {
              if (this.cdkOptions?.first?.element) {
                const newClass = this.cdkOptions.first.element
                  .getAttribute('class')
                  .split(' ')
                  .filter((c) => c !== 'cdk-option-psuedo-focused')
                  .join(' ');
                this.cdkOptions.first.element.setAttribute('class', newClass);
              }
            });
          }
        },
        (err) => console.log('the list', err)
      );

    this.cdkConnectedOverlay.attach.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (this.fullWidth)
        this.cdkConnectedOverlay.overlayRef.updateSize({
          minWidth: this.hostEl.nativeElement.getBoundingClientRect().width,
        });
    });

    // Render the initial value to take template
    this.options.forEach((option) => {
      if (option.value === this.value?.[0]) {
        this.selectedOptionTemplate = option.content;
        return;
      }
    });
  }

  selectValue(option: any): void {
    this.isOpen = false;
    this.isOpenChange.emit(this.isOpen);

    // this.value = [option.option.value.value];
    // this.valueChange.emit([option.option.value.value]);
    this.selected.emit([option.option.value.value]);

    this.selectedOptionTemplate = option.option.value.content;
    this.cdr.detectChanges();
  }

  onBackdropClick(event): void {
    const wrapperRect = this.wrapper?.nativeElement?.getBoundingClientRect();
    const overLayRect = this.listWrapper?.nativeElement?.getBoundingClientRect();
    if (!wrapperRect || !overLayRect) return this.change(false);

    // where was the click and where is the search bar
    const clickX = event.clientX;
    const clickY = event.clientY;
    const wrapperX = wrapperRect.x;
    const wrapperY = wrapperRect.y;
    const wrapperW = wrapperRect.width;
    const wrapperH = wrapperRect.height;
    const overlayX = overLayRect.x;
    const overlayY = overLayRect.y;
    const overlayW = overLayRect.width;
    const overlayH = overLayRect.height;

    // if the click was outside the search bar, and outside the overlay, close the overlay
    if (
      (clickX < wrapperX || clickX > wrapperX + wrapperW || clickY < wrapperY || clickY > wrapperY + wrapperH) &&
      (clickX < overlayX || clickX > overlayX + overlayW || clickY < overlayY || clickY > overlayY + overlayH)
    ) {
      this.change(false);
    }
    //this.focusMe.emit(true);

    // if the click was inside the search bar, do nothing
  }

  change(status?: boolean) {
    this.isOpen = status || !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
    setTimeout(() => {
      if (this.isOpen) {
        this.listBox.focus();
      }
    }, 0);
    // console.log("DEBUG NEXT", this.isOpen);
  }

  focus() {
    setTimeout(() => {
      this.isOpen = true;
      this.isOpenChange.emit(this.isOpen);
    }, 0);
  }

  onDetach(): void {
    // console.log("DETACHED");
    this.beforeDetach.emit();
    if (this.beforeDetachCallback() === false) return;
    this.detached$.next(true);
    this._listFocus$.next(false);
  }

  scrollToTop(): void {
    if (!this.list?.first?.nativeElement) return;
    this.list.first.nativeElement.scrollTop = 0;
  }

  scrollDown(y: number) {
    if (!this.list?.first?.nativeElement) return;
    // smooth
    this.list.first.nativeElement.scrollTo({
      top: y,
      behavior: 'smooth',
    });
  }

  currentOption(): any {
    // return active option
    return this.cdkOptions?.forEach((option: CdkOption) => {
      return true;
    });
  }

  ngOnDestroy(): void {
    // console.log("DESTORYED");
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.detached$.next(true);
    this.detached$.complete();
  }
}

@NgModule({
  declarations: [ModelMatchSelectComponent, ModelMatchSelectOptionComponent, ModelMatchSearchSelectDirective],
  exports: [ModelMatchSelectComponent, ModelMatchSelectOptionComponent, ModelMatchSearchSelectDirective],
  imports: [CommonModule, CdkListboxModule, OverlayModule, ModelMatchInputModule],
})
export class ModelMatchSelectModule {}
