import { gql } from 'apollo-angular';

export const getCustomFields = gql`
  query getCustomFields($reqId: String, $filterOutInactive: Boolean) {
    getCustomFields(reqId: $reqId, filterOutInactive: $filterOutInactive) {
      tenantId
      customFields {
        id
        name
        active
        type
        options {
          id
          active
          name
          order
        }
        value
      }
    }
  }
`;
