import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Tenant } from '../model/tenant';
import { UserService } from '../../services/user.service';
import { Address } from '../model/contacts';
import { AddUserInput } from '../model/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mm-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss'],
})
export class AddUserDialogComponent implements OnInit {
  addUserForm: FormGroup;
  tenants: Tenant[];
  addUserSub: Subscription;

  constructor(
    private dialogRef: MatDialogRef<AddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.addUserForm = this.formBuilder.group({
      tenantId: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      cellPhone: ['', null],
      workPhone: ['', null],
      title: ['', null],
      line1: ['', null],
      line2: ['', null],
      city: ['', null],
      state: ['', null],
      zip: ['', null],
    });

    this.tenants = this.data.tenants;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addUser() {
    let addUserInput: AddUserInput = this.getUserInput();

    this.addUserSub = this.userService.addUser(addUserInput).subscribe(
      (result) => {
        console.log('AddUserDialogComponent :: addUser - successfully added user with result ', result);
        this.snackBar.open('User successfully added.', null, {
          duration: 3000,
        });

        this.closeDialog();
      },
      (error) => {
        console.log('AddUserDialogComponent :: addUser - encounterd an error ', error);
        this.snackBar.open('Error - Failed to add user', null, {
          duration: 3000,
        });

        this.closeDialog();
      }
    );
  }

  getUserInput(): AddUserInput {
    let addUserInput: AddUserInput = {
      tenantId: this.addUserForm.get('tenantId').value,
      firstName: this.addUserForm.get('firstName').value,
      lastName: this.addUserForm.get('lastName').value,
      email: this.addUserForm.get('email').value,
    };

    if (
      this.addUserForm.get('line1').value ||
      this.addUserForm.get('line2').value ||
      this.addUserForm.get('city').value ||
      this.addUserForm.get('state').value ||
      this.addUserForm.get('zip').value
    ) {
      let address: Address = {};

      if (this.addUserForm.get('line1').value) {
        address['street'] = this.addUserForm.get('line1').value;
      }

      if (this.addUserForm.get('line2').value) {
        address['street2'] = this.addUserForm.get('line2').value;
      }

      if (this.addUserForm.get('city').value) {
        address['city'] = this.addUserForm.get('city').value;
      }

      if (this.addUserForm.get('state').value) {
        address['state'] = this.addUserForm.get('state').value;
      }

      if (this.addUserForm.get('zip').value) {
        address['zipCode'] = this.addUserForm.get('zip').value;
      }

      addUserInput['workAddress'] = address;
    }

    if (this.addUserForm.get('title').value) {
      addUserInput['title'] = this.addUserForm.get('title').value;
    }

    if (this.addUserForm.get('cellPhone').value) {
      addUserInput['cellPhone'] = this.addUserForm.get('cellPhone').value;
    }

    if (this.addUserForm.get('workPhone').value) {
      addUserInput['workPhone'] = this.addUserForm.get('workPhone').value;
    }

    return addUserInput;
  }
}
