<h1 mat-dialog-title>Save Filters</h1>
<mat-dialog-content>
  <form [formGroup]="filtersForm">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" [value]="name" />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="warn" [mat-dialog-close]>Cancel</button>
  <button mat-button color="primary" [mat-dialog-close]="filtersForm.value" [disabled]="!filtersForm.valid">
    Save
  </button>
</mat-dialog-actions>
