import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'requisitionRoute' })
export class RequisitionRoutePipe implements PipeTransform {
  transform(value: string): string {
    // Replace white spaces with dashes, and convert to lowercase
    return value.replace(/\s+/g, '-').toLowerCase();
  }
}

@Pipe({ name: 'requisitionName' })
export class RequisitionNamePipe implements PipeTransform {
  transform(label: string, tenantId: string): string {
    return `${tenantId === 'TENANT$MODELMATCH-INTERNAL' ? 'Model Match: ' : ''}${label}`;
  }
}

@Pipe({ name: 'statusName' })
export class StatusNamePipe implements PipeTransform {
  transform(label: string): string {
    // Uppercase first letter to create status display label
    return label.replace(/^\w/, (c) => c.toUpperCase()).replace(/_/g, ' ');
  }
}
