export type HeaderMap = {
  variations: string[];
  matched: string;
  concatKey?: HeaderMap['variations'][number];
  labelType?: string;
};

export const importHeaderMap: HeaderMap[] = [
  {
    variations: ['first name', 'first_name', 'first', 'name_first', 'fn'],
    matched: 'First Name',
  },
  {
    variations: ['last name', 'last_name', 'last', 'name_last', 'ln', 'family_name'],
    matched: 'Last Name',
  },
  {
    variations: ['street', 'address_street', 'street_address', 'address'],
    matched: 'Address',
  },
  {
    variations: ['city', 'address_city'],
    matched: 'City',
  },
  {
    variations: ['state', 'address_state'],
    matched: 'State',
  },
  {
    variations: ['zip', 'address_zip_code', 'zip_code'],
    matched: 'Zip Code',
  },
  {
    variations: ['title', 'job_title', 'position'],
    matched: 'Title',
  },
  {
    variations: ['company', 'company_name', 'employer', 'employment', 'job_company_name'],
    matched: 'Company',
  },
  // Phones
  {
    variations: [
      'phone',
      'number',
      'primary_phone',
      'phone_2',
      'phone_secondary',
      'phone_other',
      'secondary_phone',
      'phone_numbers',
    ],
    matched: 'Phones',
    concatKey: 'Phones',
  },

  {
    // all the variation of cell phone that don't include 'mobile'
    variations: [
      'cell_phone',
      'phone_cell',
      'cell',
      'cell_number',
      'cell_number_2',
      'cell_number_secondary',
      'cell_number_other',
      'secondary_cell_number',
      'cell_numbers',
    ],
    matched: 'Cell Phone',
    concatKey: 'Phones',
    labelType: 'Cell',
  },
  {
    // all the variations of work phone that don't include 'mobile' or 'cell'
    variations: [
      'work_phone',
      'phone_work',
      'work',
      'work_number',
      'work_number_2',
      'work_number_secondary',
      'work_number_other',
      'secondary_work_number',
      'work_numbers',
    ],
    matched: 'Work Phone',
    concatKey: 'Phones',
    labelType: 'Work',
  },
  {
    // // all the variations of mobile phone that don't include 'cell'
    variations: [
      'mobile_phone',
      'phone_mobile',
      'mobile',
      'mobile_number',
      'mobile_number_2',
      'mobile_number_secondary',
      'mobile_number_other',
      'secondary_mobile_number',
      'mobile_numbers',
    ],
    matched: 'Mobile Phone',
    concatKey: 'Phones',
    labelType: 'Mobile',
  },
  // Emails
  {
    variations: [
      'email_address',
      'email',
      'email_primary',
      'primary_email',
      'email_address_2',
      'email_2',
      'email_secondary',
      'secondary_email',
      'email_other',
    ],
    matched: 'Emails',
    concatKey: 'Emails',
  },
  {
    variations: ['Work Emails', 'work_email'],
    matched: 'Work Emails',
    concatKey: 'Emails',
    labelType: 'Work',
  },
  {
    variations: ['personal_email', 'personal_emails'],
    matched: 'Personal Emails',
    concatKey: 'Emails',
    labelType: 'Personal',
  },
  // Links
  {
    variations: ['link', 'links', 'social_links', 'link_social', 'website'],
    matched: 'Links',
    concatKey: 'Links',
  },
  {
    variations: ['facebook', 'facebook_social', 'social_facebook', 'facebook_url'],
    matched: 'Facebook',
    concatKey: 'Links',
    labelType: 'Facebook',
  },
  {
    variations: ['linkedin', 'linkedin_social', 'social_linkedin', 'linkedin_url'],
    matched: 'LinkedIn',
    concatKey: 'Links',
    labelType: 'LinkedIn',
  },
  {
    variations: ['job_company_linkedin_url'],
    matched: 'Company LinkedIn',
    concatKey: 'Links',
    labelType: 'Company LinkedIn',
  },
  {
    variations: ['job_company_twitter_url'],
    matched: 'Company Twitter',
    concatKey: 'Links',
    labelType: 'Company Twitter',
  },
  {
    variations: ['website'],
    matched: 'Website',
    concatKey: 'Links',
    labelType: 'Website',
  },
  {
    variations: ['twitter', 'twitter_social', 'social_twitter', 'twitter_url'],
    matched: 'Twitter',
    concatKey: 'Links',
    labelType: 'Twitter',
  },
  {
    variations: ['nmls_id'],
    matched: 'NMLSID',
  },
  {
    variations: ['stage'],
    matched: 'Stage',
  },
  {
    variations: ['notes'],
    matched: 'Notes',
  },
  {
    variations: ['personal_details', 'personal_information'],
    matched: 'Personal Details',
  },
  {
    variations: ['birthday', 'birthdate'],
    matched: 'Birthdate',
  },
  {
    variations: ['education', 'degree'],
    matched: 'Education',
  },
  {
    variations: ['hobby', 'hobbies'],
    matched: 'Hobbies',
  },
  {
    variations: ['spouse_name'],
    matched: 'Spouse Name',
  },
  {
    variations: ['kids_name', 'child_name', 'children'],
    matched: 'Kid Name',
  },
  {
    variations: ['anniversary'],
    matched: 'Anniversary',
  },
  {
    variations: ['production_total_volume'],
    matched: 'Production Total Volume',
  },
  {
    variations: ['production_average_per_unit'],
    matched: 'Production Average Per Unit',
  },
  {
    variations: ['production_total_units'],
    matched: 'Production Total Units',
  },
  {
    variations: ['production_purchase_percentage'],
    matched: 'Production Purchase Percentage',
  },
  {
    variations: ['production_refinance_percentage'],
    matched: 'Production Refinance Percentage',
  },
  {
    variations: ['product_mix_conventional'],
    matched: 'Product Mix Conventional',
  },
  {
    variations: ['product_mix_government'],
    matched: 'Product Mix Government',
  },
  {
    variations: ['product_mix_nonconforming'],
    matched: 'Product Mix Nonconforming',
  },
  {
    variations: ['product_mix_other_loans'],
    matched: 'Product Mix Other Loans',
  },
];

export const userHeaderValues = [
  'First Name',
  'Last Name',
  'Email',
  'City',
  'Address',
  'State',
  'Zip Code',
  'Title',
  'Cell Phone',
  'Work Phone',
  'Role ID',
];

export const headerValues = importHeaderMap.map((headerObj) => headerObj.matched);

export function getHeaderObject(headerName: string, headerMap: HeaderMap[]): HeaderMap | undefined {
  return headerMap.find((headerObj) => headerObj.matched === headerName);
}
