import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

import { GetMyTenantsDataQuery, GetTenantWithUsers, AddTenant } from '../graphql/types';
import {
  getMyTenantsDataQuery,
  getTenantWithUsers,
  getFeatureByName,
  getTenantInfo,
  getMarketInsightsFeatureByName,
  adminListTenantsQuery,
} from '../graphql/queries';
import { addTenant, setFeatureByName, setMarketInsightsFeatureByName } from '../graphql/mutations';
import { TenantInput } from '../shared/model/tenant';
import { ApolloQueryResult } from 'apollo-client';
import { PermissionsService } from './permissions.service';

@Injectable()
export class TenantService {
  constructor(
    private apollo: Apollo,
    private permissionsService: PermissionsService
  ) {}

  public getMyTenants(): Observable<any> {
    return this.apollo.watchQuery<GetMyTenantsDataQuery>({
      query: gql(getMyTenantsDataQuery),
    }).valueChanges;
  }

  public adminListTenants(titleFilter: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'network-only',
      query: gql(adminListTenantsQuery),
      variables: { titleFilter },
    }).valueChanges;
  }

  public getTenantWithUsers({
    tenantId,
    prodStartDate,
    prodEndDate,
  }: {
    tenantId: string;
    prodStartDate?: string;
    prodEndDate?: string;
  }): Observable<ApolloQueryResult<GetTenantWithUsers>> {
    // @ts-ignore
    return this.apollo.watchQuery<GetTenantWithUsers>({
      fetchPolicy: 'no-cache',
      query: gql(getTenantWithUsers),
      variables: { tenantId, prodStartDate, prodEndDate },
    }).valueChanges;
  }

  public getTenantInfo({ tenantId }: { tenantId: string }): Observable<ApolloQueryResult<any>> {
    // @ts-ignore
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql(getTenantInfo),
      variables: { tenantId },
    }).valueChanges;
  }

  public addTenant(tenantInput: TenantInput): Observable<any> {
    return this.apollo.mutate<AddTenant>({
      mutation: gql(addTenant),
      variables: { tenantInput: tenantInput },
      update: (store, { data: { addTenant } }) => {
        // update tenant caches
        try {
          const myTenants: GetMyTenantsDataQuery = store.readQuery({
            query: gql(getMyTenantsDataQuery),
          });
          myTenants.getMyTenants.push(addTenant);
          store.writeQuery({
            query: gql(getMyTenantsDataQuery),
            data: myTenants,
          });
        } catch (e) {}
      },
    });
  }

  // Get Feature Status
  /**@deprecated */
  getFeatureByName(feature: string, tenantId: string): Observable<any> {
    if (this.permissionsService.getCurrentPermissions().debug.permissions) {
      console.group('⚠️ Deprecated Function: tenant.service.ts::getFeatureByName still being used!');
      console.log(`
      Feature: ${feature}
      tenantId: ${tenantId}
      `);
      console.trace();
      console.groupEnd();
    }
    return this.apollo.watchQuery<boolean>({
      query: gql(getFeatureByName),
      variables: { feature: feature, tenantId: tenantId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // Get Feature Status
  /**@deprecated */
  getMarketInsightsFeatureByNameFeatureByName(feature: string, tenantId: string): Observable<any> {
    if (this.permissionsService.getCurrentPermissions().debug.permissions) {
      console.group(
        '⚠️ Deprecated Function: tenant.service.ts::getMarketInsightsFeatureByNameFeatureByName still being used!'
      );
      console.log(`
      Feature: ${feature}
      tenantId: ${tenantId}
      `);
      console.trace();
      console.groupEnd();
    }
    return this.apollo.watchQuery<boolean>({
      query: gql(getMarketInsightsFeatureByName),
      variables: { feature: feature, tenantId: tenantId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  // Set Feature Status
  /**@deprecated */
  public setFeatureByName(feature: string, tenantId: string, setVal: boolean): Observable<any> {
    if (this.permissionsService.getCurrentPermissions().debug.permissions) {
      console.group('⚠️ Deprecated Function: tenant.service.ts::setFeatureByName still being used!');
      console.log(`
      Feature: ${feature}
      tenantId: ${tenantId}
      setVal: ${setVal}
      `);
      console.trace();
      console.groupEnd();
    }
    return this.apollo.mutate<any>({
      mutation: gql(setFeatureByName),
      variables: {
        feature: feature,
        tenantId: tenantId,
        setVal: setVal,
      },
    });
  }

  // Set Feature Status
  public setMarketInsightsFeatureByName(feature: string, tenantId: string, setVal: string): Observable<any> {
    if (this.permissionsService.getCurrentPermissions().debug.permissions) {
      console.group('⚠️ Deprecated Function: tenant.service.ts::setMarketInsightsFeatureByName still being used!');
      console.log(`
      Feature: ${feature}
      tenantId: ${tenantId}
      setVal: ${setVal}
      `);
      console.trace();
      console.groupEnd();
    }
    return this.apollo.mutate<any>({
      mutation: gql(setMarketInsightsFeatureByName),
      variables: {
        feature: feature,
        tenantId: tenantId,
        setVal: setVal,
      },
      update: (store, { data: { setMarketInsightsFeatureByName } }) => {
        // update caches
        try {
        } catch (e) {}
      },
    });
  }
}
