import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Email, Phone, Address, ContactBusiness } from '../model/contacts';

const MONTHS = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

@Pipe({ name: 'contactPrimaryEmail' })
export class ContactPrimaryEmailPipe implements PipeTransform {
  transform(emails: Email[]): string {
    if (!emails) {
      return '';
    }

    let primaryEmails = emails.filter((email) => email.type == 'primary');
    if (primaryEmails.length < 1) {
      return '';
    }

    return primaryEmails[0].address || '';
  }
}

@Pipe({ name: 'contactSecondaryEmail' })
export class ContactSecondaryEmailPipe implements PipeTransform {
  transform(emails: Email[]): string {
    if (!emails) {
      return '';
    }

    let secondaryEmails = emails.filter((email) => email.type == 'secondary');
    if (secondaryEmails.length < 1) {
      return '';
    }

    return secondaryEmails[0].address || '';
  }
}

@Pipe({ name: 'contactWorkAltEmail' })
export class ContactWorkAltEmailPipe implements PipeTransform {
  transform(emails: Email[]): string {
    if (!emails) {
      return '';
    }

    let workEmails = emails.filter((email) => email.type == 'work');
    if (workEmails.length < 1) {
      return '';
    }

    return workEmails[0].address || '';
  }
}

@Pipe({ name: 'contactPrimaryPhone' })
export class ContactPrimaryPhonePipe implements PipeTransform {
  transform(phones: Phone[]): string {
    if (!phones) {
      return '';
    }

    let primaryPhones = phones.filter((phone) => phone.type == 'primary');
    if (primaryPhones.length < 1) {
      return '';
    }

    return primaryPhones[0].number || '';
  }
}

@Pipe({ name: 'contactSecondaryPhone' })
export class ContactSecondaryPhonePipe implements PipeTransform {
  transform(phones: Phone[]): string {
    if (!phones) {
      return '';
    }

    let secondaryPhones = phones.filter((phone) => phone.type == 'secondary');
    if (secondaryPhones.length < 1) {
      return '';
    }

    return secondaryPhones[0].number || '';
  }
}

@Pipe({ name: 'contactHomePhone' })
export class ContactHomePhonePipe implements PipeTransform {
  transform(phones: Phone[]): string {
    if (!phones) {
      return '';
    }

    let homePhones = phones.filter((phone) => phone.type == 'home');
    if (homePhones.length < 1) {
      return '';
    }

    return homePhones[0].number || '';
  }
}

@Pipe({ name: 'contactAddress' })
export class ContactAddressPipe implements PipeTransform {
  constructor(private _domSanitizer: DomSanitizer) {}

  transform(address: Address): any {
    return this._domSanitizer.bypassSecurityTrustHtml(this._buildAddress(address));
  }

  _buildAddress(address: Address): string {
    if (!address) {
      return '';
    }

    return (
      (address.street ? address.street + '</br>' : '') +
      (address.street2 ? address.street2 + '</br>' : '') +
      (address.city ? address.city + ', ' : '') +
      (address.state ? address.state + ', ' : '') +
      (address.zipCode ? address.zipCode : '')
    );
  }
}

@Pipe({ name: 'statsTimePeroid' })
export class StatsTimePeroid implements PipeTransform {
  transform(timePeroid: string, month?: number, year?: number): string {
    if (timePeroid == 'TRAILING_12') {
      return 'Trailing 12 Mo.';
    } else if (timePeroid == 'YEAR') {
      return 'Fiscal Year - ' + year;
    } else if (timePeroid == 'MONTH') {
      return MONTHS[month] + ' - ' + year;
    } else {
      return timePeroid;
    }
  }
}

@Pipe({ name: 'statConventional' })
export class StatConventionalPipe implements PipeTransform {
  transform(stat: ContactBusiness): string {
    if (!stat.productMix || stat.productMix.conventional === null || !stat.totalUnits) {
      return '';
    }

    if (stat.isUserImport || stat.isUserInput) {
      return `${stat.productMix.conventional.toFixed(2)}%`;
    }

    return `${((stat.productMix.conventional / stat.totalUnits) * 100).toFixed(2)}%`;
  }
}

@Pipe({ name: 'statGovernment' })
export class StatGovernmentPipe implements PipeTransform {
  transform(stat: ContactBusiness): string {
    if (!stat.productMix || stat.productMix.government === null || !stat.totalUnits) {
      return '';
    }

    if (stat.isUserImport || stat.isUserInput) {
      return `${stat.productMix.government.toFixed(2)}%`;
    }

    return `${((stat.productMix.government / stat.totalUnits) * 100).toFixed(2)}%`;
  }
}

@Pipe({ name: 'statNonConforming' })
export class StatNonConformingPipe implements PipeTransform {
  transform(stat: ContactBusiness): string {
    if (!stat.productMix || stat.productMix.nonConforming === null || !stat.totalUnits) {
      return '';
    }

    if (stat.isUserImport || stat.isUserInput) {
      return `${stat.productMix.nonConforming.toFixed(2)}%`;
    }

    return `${((stat.productMix.nonConforming / stat.totalUnits) * 100).toFixed(2)}%`;
  }
}

@Pipe({ name: 'statOther' })
export class StatOtherPipe implements PipeTransform {
  transform(stat: ContactBusiness): string {
    if (!stat.productMix || stat.productMix.other === null || !stat.totalUnits) {
      return '';
    }

    if (stat.isUserImport || stat.isUserInput) {
      return `${stat.productMix.other.toFixed(2)}%`;
    }

    return `${((stat.productMix.other / stat.totalUnits) * 100).toFixed(2)}%`;
  }
}
