import { Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ProcessTotal, ProcessTotalData } from '../model/process-total';
import { Contact } from '../model/contacts';
import { IntegrationsService, LogActivityInput } from 'app/services/integrations.service';
import { FormControl } from '@angular/forms';
import { take } from 'rxjs/operators';




@Component({
  selector: 'mm-contacts-added-dialog',
  templateUrl: './contacts-added-dialog.component.html',
})
export class ContactsAddedDialogComponent implements OnInit {
  contactsTotalTableSource: MatTableDataSource<Contact>;
  displayedContactsColumns = ['name', 'score', 'company', 'city', 'zip-code', 'nmls'];

  constructor(
    public dialogRef: MatDialogRef<ContactsAddedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.contactsTotalTableSource = new MatTableDataSource<Contact>(this.data.contacts);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'mm-log-activity-dialog',
  template: `
  <div class="flex flex-col space-y-4 p-6 bg-white rounded-lg shadow-md mx-auto w-80">
  
  <ng-container *ngIf="activityKey === 'TASK' || activityKey === 'MEETING'">

    <label for="title-input" class="text-sm font-medium text-gray-700">Title:</label>
    <input [(ngModel)]="title" type="text" id="title-input" placeholder="" class="p-2 border rounded-md shadow-sm focus_ring-blue-500 focus_border-blue-500 w-full">

    <label for="description-input" class="text-sm font-medium text-gray-700">Description:</label>
    <textarea [(ngModel)]="description"  id="description-input"  placeholder="" rows="3" class="p-2 border rounded-md shadow-sm focus_ring-blue-500 focus_border-blue-500 w-full"></textarea>
  
  </ng-container>

  <ng-container *ngIf="activityKey === 'CALL'">
    <label for="disposition-select" class="text-sm font-medium text-gray-700">Disposition:</label>
    <mm-select
      [value]="disposition"
      (selected)="disposition = $event?.[0]"
    >
    
      <ng-container >
        <mm-option *ngFor="let option of dispositionOptions" [value]="option">
          <span>{{ option }}</span>
        </mm-option>
      </ng-container>
      </mm-select>
  </ng-container>

  <label for="date-time-input" class="text-sm font-medium text-gray-700">When was this activity completed?:</label>
  <input (input)="setTime($event)" type="datetime-local" id="date-time-input"  class="p-2 border rounded-md shadow-sm focus_ring-blue-500 focus_border-blue-500 w-full">

  <button (click)="submitActivity()" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
    Submit
  </button>
</div>
   `,
  styleUrls: ['../../nick_styles/nick.css'],
  styles: [`
    .custom-dropdown {
      background-color: #f0f0f0; /* Light background */
      color: #333; /* Dark text */
    }
  `]
})
export class ConfirmLogActivityDialogComponent implements OnInit {
  
  contactId: string = this.data.contactId
  reqId: string = this.data.reqId
  activityKey: string = this.data.activityKey
  integrationsService = inject(IntegrationsService)
  // dateTimeControl = new FormControl(new Date());
  isoDateTime: string;
  title = ''
  description = ''
  disposition = ''
  dispositionOptions = ['Answered', 'Left Voicemail', 'Unanswered - No Voicemail Left', 'Meeting Set', 'Do Not Call']

  
  constructor(
    public dialogRef: MatDialogRef<ContactsAddedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
  console.log(this.contactId, this.reqId, this.activityKey)
  }

  setTime(event: Event): void {
    let inputValue = (event.target as HTMLInputElement).value;
    if (!inputValue.includes(':')) {
      inputValue += ':00'; // Append ":00" if seconds are missing
    }
  
    // Convert the adjusted input to a Date object and then to ISO string
    const dateWithSeconds = new Date(inputValue);
    const isoDateString = dateWithSeconds.toISOString();
  
    this.isoDateTime = this.isNotFutureDate(isoDateString) ? isoDateString : ''
    console.log('👽 ~ time set', this.isoDateTime);
  }

  submitActivity(): void {
    console.log('Submitted Date and Time:', this.isoDateTime, this.title, this.description, this.disposition)
    if (this.activityKey === 'CALL' && !this.disposition) return
    if (!this.isoDateTime) return

    const getActivityTitle = (activity: string) => {
      let title = ''
      switch (activity) {
        case 'SMS':
          title = 'SMS sent'
          break;
        case 'CALL':
          title = 'Call Started'
          break;
       
        default:
          title = `TYPE ${activity}`
          break;
      }
      return title
    }

    const input: LogActivityInput = {
      reqId: this.reqId,
      activity: this.activityKey,
      contactId: this.contactId,
      timeISO: this.isoDateTime,
      title: this.title || getActivityTitle(this.activityKey),
      description: this.description,
      ...(this.disposition && { disposition: this.disposition }),
      ...(this.description && { description: this.description })
    }

    this.integrationsService.logActivity(input).pipe(take(1)).subscribe(res => {
      console.log(res)
      })
    this.dialogRef.close('updated')
  }

   isNotFutureDate(isoDateStr) {
    // Parse ISO date string to a Date object
    const inputDate = new Date(isoDateStr);
    
    const today = new Date();
    
    // Remove time components from today's date
    today.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0,)

    // Compare the input date with today's date
    if (inputDate > today) {
        console.log("The date is in the future.");
        return false;
    } else {
        console.log("The date is today or in the past.");
        return true;
    }
}


  closeDialog(): void {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
