import { PopoverModule } from './popover.component';
import { PopperModule } from './../market-insight/map/components/tooltip.directive';
import { SpinnerModule } from './spinner.component';
import {
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from 'tailwind.config.js';
import {
  ModelMatchButtonOptions,
  ModelMatchComponentFactory,
  ModelMatchColors,
  ModelMatchAnimations,
} from './ModelMatchComponentFactory';
import { trigger, transition, style, animate, keyframes, stagger } from '@angular/animations';
const fullConfig = resolveConfig(tailwindConfig);

const defaultButtonOptions: ModelMatchButtonOptions = {
  size: 'md',
  ratio: 'wide',
  animation: 'active-scale',
  shadow: true,
  border: true,
  style: 'primary',
  color: 'blue',
  fontColor: 'white',
  highlight: false,
  cursor: 'cursor-pointer',
};

@Component({
  selector: 'mm-button',
  animations: [
    trigger('fadeUpIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.5)' }),
        animate('150ms ease-in', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
    ]),
    trigger('rotateOut', [
      transition(':leave', [
        animate(
          '600ms ease-out',
          keyframes([
            style({ transform: 'rotate(0deg)', opacity: 1 }),
            style({ transform: 'rotate(360deg)', opacity: 0 }),
          ])
        ),
      ]),
    ]),
    trigger('rotateIn', [
      transition(':enter', [
        animate(
          '600ms ease-in',
          keyframes([
            style({ transform: 'rotate(360deg)', opacity: 0 }),
            style({ transform: 'rotate(0deg)', opacity: 1 }),
          ])
        ),
      ]),
    ]),
    trigger('shake', [
      transition('false => *', [
        animate(
          '300ms 150ms',
          keyframes([
            style({ transform: 'translate(1px, 0px) rotate(0deg)' }),
            style({ transform: 'translate(0px, -1px) rotate(-1deg)' }),
            style({ transform: 'translate(-2px, 0px) rotate(1deg)' }),
            style({ transform: 'translate(0px, 1px) rotate(0deg)' }),
            style({ transform: 'translate(0px, 1px) rotate(-1deg)' }),
            style({ transform: 'translate(-2px, 0px) rotate(0deg)' }),
            style({ transform: 'translate(1px, 0px) rotate(-1deg)' }),
            style({ transform: 'translate(1px, 1px) rotate(0deg)' }),
            style({ transform: 'translate(0px, -1px) rotate(-1deg)' }),
          ])
        ),
      ]),
    ]),
  ],
  template: `
    <mm-popover [showProgrammatically]="true" [show]="error">
      <span target class="text-center p-2 w-36">{{ errorMessage }}</span>
      <button
        trigger
        placement="top"
        [@shake]="error"
        [disabled]="disabled || loading || (error && !retry)"
        [ngClass]="buttonClasses"
        [ngStyle]="options?.width ? { width: options?.width } : {}"
        (click)="retry && canRetry ? runRetry() : onClick.emit($event)"
      >
        <div
          class="transition-opacity duration-150 w-full"
          [ngStyle]="{ opacity: loading || error || success ? 0 : 1 }"
        >
          <ng-content></ng-content>
        </div>
        <div [@fadeUpIn] class="absolute" *ngIf="loading">
          <mm-spinner [size]="options.size" [class]="spinnerColor" stroke="2.5"></mm-spinner>
        </div>
        <div [@fadeUpIn] class="absolute flex items-center space-x-2" *ngIf="success">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            class="h-5 w-5"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
              <path d="M22 4L12 14.01l-3-3" />
            </g>
          </svg>
          <p *ngIf="this.typeof(success) === 'string'" class="text-sm font-medium">{{ success }}</p>
        </div>
        <div [@fadeUpIn] class="absolute space-x-2 flex items-center" *ngIf="error">
          <div class="relative flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 flex-shrink-0 opacity-0"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <svg
              [@rotateOut]="retry"
              *ngIf="!retry"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 flex-shrink-0 absolute"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <svg
              [@rotateIn]="retry"
              *ngIf="retry"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              class="h-4 w-4 flex-shrink-0 absolute"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5">
                <path
                  d="M21.888 13.5C21.164 18.311 17.013 22 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c4.1 0 7.625 2.468 9.168 6"
                />
                <path d="M17 8h4.4a.6.6 0 0 0 .6-.6V3" />
              </g>
            </svg>
          </div>
          <span>{{ retry ? 'Retry' : 'Error!' }}</span>
        </div>
      </button>
    </mm-popover>
  `,
  styleUrls: ['../nick_styles/nick.css'],
})
export class ModelMatchButtonBlueComponent extends ModelMatchComponentFactory implements OnInit, OnChanges {
  @Input() public fill: boolean = false;
  @Input() public success: boolean | string = false;
  @Input() public canRetry: boolean = true;
  @Input() public error: boolean = false;
  @Input() public loading: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public options: ModelMatchButtonOptions = defaultButtonOptions;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onClick = new EventEmitter<MouseEvent>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onRetry = new EventEmitter<number>();
  public buttonClasses: string;
  public retry: boolean = false;
  public errorTimmer: any;
  private retryCount: number = 0;
  public errorMessage: string;
  constructor() {
    super();
  }

  ngOnInit() {
    this.setClasses();
  }

  runRetry() {
    clearTimeout(this.errorTimmer);
    this.retryCount = this.retryCount + 1;
    this.onRetry.emit(this.retryCount);
    this.retry = false;
  }

  setClasses(color?: 'red' | 'blue' | 'white' | 'indigo' /* Used to change error */) {
    this.options = {
      ...defaultButtonOptions,
      ...this.options,
      color: color || this.options?.color || defaultButtonOptions.color,
      state: this.disabled ? 'disabled' : this.loading ? 'loading' : 'active',
    };

    this.buttonClasses = this.generateClassName(this.options);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.error && this.error) {
      this.errorMessage = changes.error.currentValue;
      this.setClasses('red');
      clearTimeout(this.errorTimmer);
      if (this.canRetry)
        this.errorTimmer = setTimeout(() => {
          this.retry = true;
        }, 1000);
    } else if (changes.error && !this.error) {
      this.setClasses('blue');
    } else if (changes.success) {
      this.setClasses(this.options?.color || 'blue');
    }

    if (changes.options) {
      this.setClasses();
    }
  }

  typeof(obj: any) {
    return typeof obj;
  }
}

@NgModule({
  declarations: [ModelMatchButtonBlueComponent],
  imports: [CommonModule, SpinnerModule, PopperModule, PopoverModule],
  exports: [ModelMatchButtonBlueComponent],
})
export class ModelMatchButtonModule {}
