import { Permissions } from '../permissions';

/**The default permissions used when there are no other set permissions for a user in the database. */
export default {
  session: {
    enabled: true,
    multiple_sessions: true,
  },
  cms: {
    enabled: true,
    create_requisitions: true,
    allow_imports: true,
    add_to_all_reqs: false,
    white_label: true,
    marketing_export: false,
    todos: true,
    contacts: {
      enabled: true,
      allow_exports: true,
      create: true,
      delete: true,
      limit_contacts: true,
      click_to_call: true,
      nmls_id: true,
      premium_contacts: {
        enabled: false,
        export: {
          enabled: false,
          less: false,
        },
      },
    },
  },
  data: {
    enabled: true,
    pdl_enrichment: true,
    loan_originator: {
      enabled: true,
      raw_loan_transactions: true,
    },
    real_estate_agent: {
      enabled: true,
    },
    branch_raw_transactions: false,
    company_raw_transactions: false,
  },
  market_insights: {
    enabled: true,
    ai_filter: false,
    allow_imports_at_stage: false,
    allow_bulk_duplicate_imports: false,
    view_on_zillow: true,
    export_transactions: true,
    debug_actions: false,
    full_date_ranges: true,
  },
  reporting_analytics: {
    enabled: true,
  },
  sms_chat: {
    enabled: true,
    sequence: true,
  },
  integrations: {
    enabled: true,
    bomb_bomb: true,
    bonzo: false,
    google: true,
    microsoft: true,
    total_expert: {
      enabled: true,
      export: true,
    },
    zapier: true,
    hubspot: true,
    salesforce: true,
  },
  playbooks: {
    enabled: true,
  },
  pulse: {
    enabled: true,
    title_company: false,
  },
  compass: {
    enabled: true,
    title_company: false,
  },
  team_chat: {
    enabled: true,
  },
  trust_hub: {
    enabled: false,
    sms: true
  },
  debug: {
    enabled: false,
    permissions: false,
    tail_logs: false,
  },
} as Permissions;
