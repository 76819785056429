<div class="reporting-page-container" fxFlex style="padding: 1em">
  <header class="reporting-header">
    <h2 class="mat-title section-title">
      <div *ngIf="!linkShareMode" class="with-icon top-nav-icon">
        <a mat-button routerLink="/settings" class="top-nav-icon">
          <mat-icon class="nav-icon main-icon muted fa-icon" size="lg">analytics</mat-icon>
          <span>Home</span>
        </a>
        <mat-icon class="nav-icon">chevron_right</mat-icon>
        <span>Reporting and Analytics</span>
      </div>

      <div *ngIf="linkShareMode" class="with-icon top-nav-icon">
        <a class="branding-logo-wrapper" routerLink="/requisitions">
          <img
            class="branding-logo"
            src="assets/images/model-match-logo.png"
            alt="Model Match Logo"
            srcset="
              assets/images/model-match-logo.png    1x,
              assets/images/model-match-logo@2x.png 2x,
              assets/images/model-match-logo@3x.png 3x
            "
          />
        </a>
        <!-- <mat-icon class="nav-icon main-icon muted fa-icon" size="lg">analytics</mat-icon> -->
        <span style="margin-left: 1em">Model Match Reporting and Analytics</span>
      </div>
    </h2>

    <button *ngIf="!linkShareMode" mat-button mat-mini-fab color="accent" class="add-button" (click)="onAddStage()">
      <mat-icon class="muted">add</mat-icon>
    </button>

    <!-- Eject Button: Future think and testing path back to live reporting -->
    <!-- <button *ngIf="linkShareMode" mat-button mat-mini-fab color="accent" class="add-button" (click)="toggleLinkShare()">
      <mat-icon class="muted">eject</mat-icon>
    </button> -->

    <div fxFlex fxLayoutAlign="end start" class="more-menu">
      <button *ngIf="!linkShareMode" mat-icon-button [matMenuTriggerFor]="menu" aria-label="more svg for a menu">
        <mat-icon
          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
            />
          </svg>
        </mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <div *ngIf="!linkShareMode">
          <button mat-menu-item (click)="clearAll()">
            <mat-icon>open_in_new</mat-icon>
            <span>New Template</span>
          </button>

          <button mat-menu-item (click)="onChooseReportTemplate()">
            <mat-icon>grid_on</mat-icon>
            <span>Templates</span>
          </button>

          <mat-divider></mat-divider>

          <button mat-menu-item (click)="saveReportingTemplate(false)">
            <mat-icon>system_update_alt</mat-icon>
            <span>Save</span>
          </button>

          <button mat-menu-item (click)="saveAsNewReportingTemplate(false)">
            <mat-icon>texture</mat-icon>
            <span>Save as New Template</span>
          </button>

          <mat-divider></mat-divider>

          <button mat-menu-item (click)="refreshTemplate()">
            <mat-icon>refresh</mat-icon>
            <span>Refresh</span>
          </button>

          <button mat-menu-item (click)="shareLink()">
            <mat-icon>link</mat-icon>
            <span>Share Link</span>
          </button>

          <button mat-menu-item (click)="shareReport()">
            <mat-icon>share</mat-icon>
            <span>Share Report</span>
          </button>

          <mat-divider></mat-divider>

          <button mat-menu-item [matMenuTriggerFor]="download">
            <mat-icon>cloud_download</mat-icon>
            <span>Download</span>
          </button>

          <button for="files" mat-menu-item (click)="files.click()">
            <mat-icon>backup</mat-icon>
            <span>Import</span>
          </button>

          <mat-divider></mat-divider>

          <button mat-menu-item [matMenuTriggerFor]="reportingThemesMenu">
            <mat-icon>color_lens</mat-icon>
            <span>Themes</span>
          </button>

          <mat-divider></mat-divider>

          <button mat-menu-item [matMenuTriggerFor]="recent">
            <mat-icon>playlist_play</mat-icon>
            <span>Recent</span>
          </button>
        </div>
      </mat-menu>

      <mat-menu #recent="matMenu" xPosition="before">
        <button mat-menu-item *ngFor="let recent of templateHistory" (click)="loadRecent($event, recent)">
          <span>{{ recent.title }}</span>
        </button>
      </mat-menu>

      <mat-menu #download="matMenu" xPosition="before">
        <button mat-menu-item (click)="downloadStage($event, false)">
          <mat-icon>cloud_download</mat-icon>
          <span>Template</span>
        </button>

        <button mat-menu-item (click)="downloadStage($event, true)">
          <mat-icon>archive</mat-icon>
          <span>Export With Data</span>
        </button>
      </mat-menu>

      <mat-menu #reportingThemesMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="changeReportingTheme($event, 'theme-news')">
          <mat-icon
            ><svg viewBox="0 0 40 40" width="20" height="20" style="overflow: visible">
              <circle cx="20" cy="25" r="12" fill="rgb(255, 34, 34, 0.8)" />
              <circle cx="10" cy="15" r="12" fill="rgb(33, 150, 243, 0.8)" />
              <circle cx="30" cy="15" r="12" fill="rgb(34, 255, 34, 0.8)" />
            </svg>
          </mat-icon>
          <span>News</span>
        </button>

        <!-- margin-right: 16px;
                vertical-align: middle;
                 -->
        <button mat-menu-item (click)="changeReportingTheme($event, 'theme-blues')">
          <mat-icon
            ><svg viewBox="0 0 40 40" width="20" height="20" style="overflow: visible">
              <circle cx="20" cy="25" r="12" fill="rgb(82, 180, 255, 0.5)" />
              <circle cx="10" cy="15" r="12" fill="rgb(33, 150, 243, 0.5)" />
              <circle cx="30" cy="15" r="12" fill="rgb(184, 224, 255, 0.5)" />
            </svg>
          </mat-icon>
          <span>Blues</span>
        </button>
      </mat-menu>
    </div>
  </header>

  <div class="filter-buttons">
    <div class="flex-container" fxLayoutGap="0" fxLayout="row" fxLayout.lt-lg="column">
      <div fxFlex fxLayoutAlign="start center" class="template-title-container" fxFlexOrder="1" fxFlexOrder.lt-lg="2">
        <mat-form-field *ngIf="!linkShareMode" appearance="standard" class="title-input" style="width: 100%">
          <input
            matInput
            id="reportTemplateTitle"
            name="reportTemplateTitle"
            required
            [(ngModel)]="reportTemplate.title"
            (change)="onModelChange($event)"
          />
          <!-- <mat-icon matSuffix class="muted" size="sm">mode_edit</mat-icon> -->
        </mat-form-field>

        <div *ngIf="linkShareMode">{{ reportTemplate.title }}</div>
      </div>

      <div
        *ngIf="!linkShareMode"
        fxLayoutAlign="end center"
        class="filter-container"
        fxFlexOrder="2"
        fxFlexOrder.lt-lg="1"
      >
        <button
          mat-button
          matBadge="{{ selectedReqIds.length }}"
          matBadgePosition="after"
          matBadgeColor="primary"
          matBadgeSize="small"
          color="primary"
          class="reqs-button filter-button"
          (click)="onFilterByReqs()"
          onclick="this.blur()"
        >
          Reqs
        </button>

        <button
          mat-button
          matBadge="{{ selectedUserIds.length }}"
          matBadgePosition="after"
          matBadgeColor="primary"
          matBadgeSize="small"
          color="primary"
          class="users-button"
          (click)="onFilterByUsers()"
          onclick="this.blur()"
        >
          Team Members
        </button>

        <mat-form-field class="date-range-picker">
          <input
            matInput
            placeholder="Date range"
            [satDatepicker]="resultPicker"
            (dateChange)="onDateRangeChange($event.value)"
            [(ngModel)]="dateRangeFilter"
          />
          <sat-datepicker
            #resultPicker
            [rangeMode]="true"
            [calendarFooterComponent]="rangesFooter"
            panelClass="range-datepicker"
          >
          </sat-datepicker>
          <sat-datepicker-toggle matSuffix [for]="resultPicker"></sat-datepicker-toggle>
        </mat-form-field>
      </div>

      <div *ngIf="linkShareMode" fxLayoutAlign="end center" class="filter-container" fxFlexOrder="2">
        <button
          mat-button
          matBadge="{{ selectedReqIds.length }}"
          matBadgePosition="after"
          matBadgeColor="primary"
          matBadgeSize="small"
          color="primary"
          class="reqs-button filter-button"
          [matMenuTriggerFor]="reqFilters"
          *ngIf="selectedReqIds.length"
        >
          Reqs
        </button>

        <mat-menu #reqFilters="matMenu" xPosition="before">
          <button mat-menu-item *ngFor="let req of selectedReqs">
            <span>{{ req.title }}</span>
          </button>
        </mat-menu>

        <button
          mat-button
          matBadge="{{ selectedUserIds.length }}"
          matBadgePosition="after"
          matBadgeColor="primary"
          matBadgeSize="small"
          color="primary"
          class="users-button"
          [matMenuTriggerFor]="userFilters"
          *ngIf="selectedUserIds.length"
        >
          Team Members
        </button>

        <mat-menu #userFilters="matMenu" xPosition="before">
          <button mat-menu-item *ngFor="let user of selectedUsers">
            <span>{{ user.firstName }} {{ user.lastName }}</span>
          </button>
        </mat-menu>

        <mat-form-field class="date-range-picker">
          <input
            matInput
            placeholder="Date range"
            [satDatepicker]="resultPicker"
            (dateChange)="onDateRangeChange($event.value)"
            [(ngModel)]="dateRangeFilter"
            [readonly]="true"
          />
          <sat-datepicker
            #resultPicker
            [rangeMode]="true"
            [calendarFooterComponent]="rangesFooter"
            panelClass="range-datepicker"
          >
          </sat-datepicker>
          <!-- <sat-datepicker-toggle matSuffix [for]="resultPicker"></sat-datepicker-toggle> -->
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- <div style="min-height: 400px;">
        <h3>QQQ</h3>
        <mm-widget-obj-playbook2>
        </mm-widget-obj-playbook2>
    </div> -->

  <section class="reporting-page-section">
    <div *ngIf="stageLoading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <!-- PLaceholder with on click to add report -->
    <div
      *ngIf="!reportTemplate.reportObjects.length && !stageLoading && !linkShareMode"
      style="position: relative"
      (click)="onAddStage()"
    >
      <div fxLayout=" row">
        <div
          fxFlex="50"
          fxlayoutalign="center center"
          class="select-to-add-box"
          style="border-right: 1px solid #c2c2c2; border-bottom: 1px solid #c2c2c2"
        >
          <p class="select-to-add-icon">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 489.2 489.2"
              style="enable-background: new 0 0 489.2 489.2"
              xml:space="preserve"
            >
              <g>
                <g>
                  <g>
                    <polygon style="fill: #556080" points="61.3,427.9 61.3,0 0,0 0,489.2 489.2,489.2 489.2,427.9 			" />
                    <path
                      style="fill: #556080"
                      d="M477.6,68.3c-2.7-4.7-8.2-6.2-12.8-3.5l-46.6,26c-6.6-9.3-17.5-15.1-29.9-15.1c-20.6,0-36.9,16.7-36.9,36.9
                                   c0,1.2,0,2.7,0.4,3.9l-59,19.4c-6.6-9.3-17.5-15.1-29.9-15.1c-20.6,0-36.9,16.7-36.9,36.9c0,12,5.8,22.9,14.8,29.5l-22.2,70.2
                                   c-20.2,0.4-36.1,16.7-36.1,36.9c0,5.4,1.2,10.5,3.5,15.1l-94.9,71.7c-4,3.1-4.9,8.8-2,12.9l0,0c3,4.2,9,5.2,13.2,2l95.7-71.9
                                   c6.2,4.7,13.6,7,21.7,7c20.6,0,36.9-16.7,36.9-36.9c0-14-7.8-26.4-19.4-32.6l21.4-67.6c1.6,0.4,3.1,0.4,4.7,0.4
                                   c20.6,0,36.9-16.7,36.9-36.9c0-1.6,0-3.1-0.4-4.7l58.6-19c6.6,9.3,17.9,15.5,30.3,15.5c20.6,0,36.9-16.7,36.9-36.9
                                   c0-1.6,0-2.7-0.4-3.9l48.9-27.6C478.7,78.4,480.3,73,477.6,68.3z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </p>
        </div>
        <div
          fxFlex="50"
          fxlayoutalign="center center"
          class="select-to-add-box"
          style="border-bottom: 1px solid #c2c2c2"
        >
          <p class="select-to-add-icon">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 57.924 57.924"
              style="enable-background: new 0 0 57.924 57.924"
              xml:space="preserve"
            >
              <g>
                <path style="fill: #556080" d="M31,26.924h26.924C56.94,12.503,45.421,0.983,31,0V26.924z" />
                <path
                  style="fill: #556080"
                  d="M50.309,48.577c4.343-4.71,7.151-10.858,7.614-17.653H32.656L50.309,48.577z"
                />
                <path
                  style="fill: #556080"
                  d="M27,30.924V0C11.918,1.028,0,13.58,0,28.924c0,16.016,12.984,29,29,29
                        c6.99,0,13.396-2.479,18.401-6.599L27,30.924z"
                />
              </g>
            </svg>
          </p>
        </div>
      </div>

      <div fxLayout="row">
        <div
          fxFlex="50"
          fxlayoutalign="center center"
          class="select-to-add-box"
          style="border-right: 1px solid #c2c2c2"
        >
          <p class="select-to-add-icon">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 473.965 473.965"
              style="enable-background: new 0 0 473.965 473.965"
              xml:space="preserve"
            >
              <path
                style="fill: #556080"
                d="M0.006,236.973C0.006,106.09,106.103,0,236.979,0c130.883,0,236.981,106.094,236.981,236.973
                       c0,130.887-106.098,236.988-236.981,236.988C106.107,473.961,0.006,367.864,0.006,236.973z"
              />
              <path
                style="fill: #556080"
                d="M420.243,148.383c-28.501,30.278-63.977,67.954-132.492,74.218
                       c-22.226,2.032-65.63,13.283-91.565,20.011c-3.727,0.965-7.072,1.833-9.916,2.559c-62.701,16-92.096,29.463-130.92,99.333
                       c-5.302,9.545-10.761,17.092-16.344,22.683c42.379,64.31,115.201,106.779,197.973,106.779
                       c130.883,0,236.981-106.098,236.981-236.988c0-40.931-10.38-79.438-28.647-113.035C437.406,130.183,429.358,138.703,420.243,148.383
                       z"
              />
              <g>
                <circle style="fill: #fdf2f7" cx="85.756" cy="293.118" r="29.309" />
                <circle style="fill: #fdf2f7" cx="235.426" cy="229.172" r="29.309" />
                <circle style="fill: #fdf2f7" cx="393.515" cy="170.561" r="29.309" />
              </g>
              <path
                style="fill: #fdf7fa"
                d="M443.146,120.167c-0.528-0.928-1.055-1.852-1.594-2.773c-8.733,6.75-17.332,15.846-26.757,25.859
                       c-27.637,29.358-62.035,65.892-127.725,71.894c-22.836,2.088-66.611,13.437-92.762,20.217c-3.716,0.965-7.053,1.83-9.889,2.556
                       c-63.52,16.209-95.546,30.843-135.609,102.951c-4.629,8.333-9.276,14.952-13.916,19.895c0.662,1.078,1.366,2.122,2.047,3.192
                       c0.685,1.078,1.366,2.159,2.065,3.225c5.583-5.586,11.042-13.137,16.344-22.683c38.825-69.87,68.22-83.329,130.92-99.332
                       c2.844-0.722,6.189-1.594,9.916-2.559c25.934-6.728,69.339-17.979,91.565-20.011c68.519-6.264,103.991-43.939,132.492-74.218
                       c9.115-9.68,17.167-18.2,25.074-24.445C444.62,122.662,443.864,121.428,443.146,120.167z"
              />
            </svg>
          </p>
        </div>
        <div fxFlex="50" fxlayoutalign="center center" class="select-to-add-box">
          <p class="select-to-add-icon">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 60 60"
              style="enable-background: new 0 0 60 60"
              xml:space="preserve"
            >
              <g>
                <g>
                  <rect style="fill: #556080" x="44" y="31" width="2" height="3" />
                  <path
                    style="fill: #556080"
                    d="M2,14h11c0.6,0,1-0.4,1-1V2h30v3h2V1c0-0.6-0.4-1-1-1H13l0,0c-0.1,0-0.2,0-0.3,0.1h-0.1c-0.1,0.1-0.2,0.1-0.3,0.2l-12,12
                               c-0.1,0.1-0.1,0.2-0.2,0.3v0.1C0.1,12.8,0,12.9,0,13l0,0v46c0,0.6,0.4,1,1,1h36v-2H2V14z M12,12H3.4l4.3-4.3L12,3.4L12,12L12,12z"
                  />
                  <path
                    style="fill: #556080"
                    d="M49,29c6.1,0,11-4.9,11-11S55.1,7,49,7s-11,4.9-11,11S42.9,29,49,29z M49,9c5,0,9,4,9,9s-4,9-9,9s-9-4-9-9S44,9,49,9z"
                  />
                  <path
                    style="fill: #556080"
                    d="M47,23h1v2h2v-2h1c1.2,0,2-0.8,2-2v-2c0-1.2-0.8-2-2-2h-4v-2h4v1h2v-1c0-1.2-0.8-2-2-2h-1v-2h-2v2h-1c-1.2,0-2,0.8-2,2v2
                               c0,1.2,0.8,2,2,2h4v2h-4v-1h-2v1C45,22.2,45.8,23,47,23z"
                  />
                  <path
                    style="fill: #556080"
                    d="M59,36H41c-0.6,0-1,0.4-1,1v22c0,0.6,0.4,1,1,1h18c0.6,0,1-0.4,1-1V37C60,36.4,59.6,36,59,36z M58,38v4H42v-4H58z M42,58
                               V44h16v14H42z"
                  />
                  <rect style="fill: #556080" x="45" y="46" width="2" height="2" />
                  <rect style="fill: #556080" x="49" y="46" width="2" height="2" />
                  <rect style="fill: #556080" x="53" y="46" width="2" height="2" />
                  <rect style="fill: #556080" x="45" y="50" width="2" height="2" />
                  <rect style="fill: #556080" x="49" y="50" width="2" height="2" />
                  <rect style="fill: #556080" x="53" y="50" width="2" height="2" />
                  <rect style="fill: #556080" x="45" y="54" width="2" height="2" />
                  <rect style="fill: #556080" x="49" y="54" width="2" height="2" />
                  <rect style="fill: #556080" x="53" y="54" width="2" height="2" />
                  <rect style="fill: #556080" x="43" y="39" width="2" height="2" />
                  <rect style="fill: #556080" x="46" y="39" width="2" height="2" />
                  <rect style="fill: #556080" x="49" y="39" width="2" height="2" />
                  <rect style="fill: #556080" x="52" y="39" width="2" height="2" />
                  <rect style="fill: #556080" x="55" y="39" width="2" height="2" />
                  <rect style="fill: #556080" x="19" y="5" width="6" height="2" />
                  <rect style="fill: #556080" x="19" y="10" width="15" height="2" />
                  <path
                    style="fill: #556080"
                    d="M9.3,18.3l-3,3l1.4,1.4L9,21.4V35h2V21.4l1.3,1.3l1.4-1.4l-3-3C10.3,17.9,9.7,17.9,9.3,18.3z"
                  />
                  <path
                    style="fill: #556080"
                    d="M17.7,26.7l1.3-1.3V35h2v-9.6l1.3,1.3l1.4-1.4l-3-3c-0.4-0.4-1-0.4-1.4,0l-3,3L17.7,26.7z"
                  />
                  <path
                    style="fill: #556080"
                    d="M31,19.4l1.3,1.3l1.4-1.4l-3-3c-0.4-0.4-1-0.4-1.4,0l-3,3l1.4,1.4l1.3-1.3V35h2L31,19.4L31,19.4z"
                  />
                  <rect style="fill: #556080" x="9" y="40" width="15" height="2" />
                  <rect style="fill: #556080" x="9" y="45" width="26" height="2" />
                  <rect style="fill: #556080" x="9" y="50" width="26" height="2" />
                  <rect style="fill: #556080" x="26" y="40" width="4" height="2" />
                </g>
              </g>
            </svg>
          </p>
        </div>
      </div>

      <div class="select-to-add-container">
        <h3 class="select-to-add">Select to Add</h3>
      </div>
    </div>

    <gridster
      *ngIf="reportTemplate.reportObjects.length && !stageLoading"
      [options]="options"
      style="min-height: 1800px"
    >
      <!-- <div *ngIf="!reportTemplate.reportObjects.length">
                <gridster-item class="report-page-obj" [item]="reportDefault"
                    *ngFor="let reportDefault of reportObjectsDefault;">
                    <mm-report-obj-one message="Sample{{reportDefault.id}}" [startDate]="startDate" [endDate]="endDate">
                    </mm-report-obj-one>
                </gridster-item>
            </div> -->

      <gridster-item
        class="report-page-obj"
        [item]="report"
        *ngFor="let report of reportTemplate.reportObjects; let index = index"
      >
        <!-- {{report.cols}} -->

        <mm-report-obj-one
          *ngIf="report.rType === 'mm-report-obj-one'"
          [startDate]="startDate"
          [endDate]="endDate"
          [reportingTheme]="reportingTheme"
        >
        </mm-report-obj-one>

        <mm-report-obj-two
          *ngIf="report.rType === 'mm-report-obj-two'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="selectedUserIds"
          [tenants]="tenants"
          [reqs]="reqs"
          [reqIds]="selectedReqIds"
          (objDataChanged)="objDataChanged($event, index)"
          [data]="report.data"
          [esFilters]="report.esFilters"
          (delete)="objDeleted($event, index)"
          (clone)="objCloned($event, index)"
          (loaded)="objLoaded($event, index)"
          [reportingTheme]="reportingTheme"
          [useStoredData]="report.useStoredData"
          [reqFiltersUsersGlobal]="reqFiltersUsersGlobal"
          [contactTags]="contactTags"
          [sequenceItems]="sequenceItems"
        >
        </mm-report-obj-two>

        <mm-report-obj-three
          *ngIf="report.rType === 'mm-report-obj-three'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="selectedUserIds"
          [tenants]="tenants"
          [reqs]="reqs"
          [reqIds]="selectedReqIds"
          (objDataChanged)="objDataChanged($event, index)"
          [data]="report.data"
          [esFilters]="report.esFilters"
          (delete)="objDeleted($event, index)"
          (loaded)="objLoaded($event, index)"
          (clone)="objCloned($event, index)"
          [reportingTheme]="reportingTheme"
          [useStoredData]="report.useStoredData"
          [reqFiltersUsersGlobal]="reqFiltersUsersGlobal"
          [contactTags]="contactTags"
          [sequenceItems]="sequenceItems"
        >
        </mm-report-obj-three>

        <mm-report-obj-four
          *ngIf="report.rType === 'mm-report-obj-four'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="selectedUserIds"
          [tenants]="tenants"
          [reqs]="reqs"
          [reqIds]="selectedReqIds"
          (objDataChanged)="objDataChanged($event, index)"
          [data]="report.data"
          [esFilters]="report.esFilters"
          (delete)="objDeleted($event, index)"
          (loaded)="objLoaded($event, index)"
          (clone)="objCloned($event, index)"
          [reportingTheme]="reportingTheme"
          [useStoredData]="report.useStoredData"
          [reqFiltersUsersGlobal]="reqFiltersUsersGlobal"
          [contactTags]="contactTags"
          [sequenceItems]="sequenceItems"
        >
        </mm-report-obj-four>

        <mm-report-obj-five
          *ngIf="report.rType === 'mm-report-obj-five'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="selectedUserIds"
          [tenants]="tenants"
          [reqs]="reqs"
          [reqIds]="selectedReqIds"
          (objDataChanged)="objDataChanged($event, index)"
          [data]="report.data"
          [esFilters]="report.esFilters"
          (delete)="objDeleted($event, index)"
          (loaded)="objLoaded($event, index)"
          (clone)="objCloned($event, index)"
          [reportingTheme]="reportingTheme"
          [useStoredData]="report.useStoredData"
          [reqFiltersUsersGlobal]="reqFiltersUsersGlobal"
          [contactTags]="contactTags"
          [sequenceItems]="sequenceItems"
        >
        </mm-report-obj-five>

        <mm-report-obj-six
          *ngIf="report.rType === 'mm-report-obj-six'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="selectedUserIds"
          [tenants]="tenants"
          [reqs]="reqs"
          [reqIds]="selectedReqIds"
          (objDataChanged)="objDataChanged($event, index)"
          [data]="report.data"
          [esFilters]="report.esFilters"
          (delete)="objDeleted($event, index)"
          (loaded)="objLoaded($event, index)"
          (clone)="objCloned($event, index)"
          [reportingTheme]="reportingTheme"
          [useStoredData]="report.useStoredData"
          [reqFiltersUsersGlobal]="reqFiltersUsersGlobal"
          [contactTags]="contactTags"
          [sequenceItems]="sequenceItems"
        >
        </mm-report-obj-six>

        <mm-report-obj-seven
          *ngIf="report.rType === 'mm-report-obj-seven'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="selectedUserIds"
          [tenants]="tenants"
          [reqs]="reqs"
          [reqIds]="selectedReqIds"
          (objDataChanged)="objDataChanged($event, index)"
          [data]="report.data"
          [esFilters]="report.esFilters"
          (delete)="objDeleted($event, index)"
          (loaded)="objLoaded($event, index)"
          (clone)="objCloned($event, index)"
          [reportingTheme]="reportingTheme"
          [useStoredData]="report.useStoredData"
          [reqFiltersUsersGlobal]="reqFiltersUsersGlobal"
          [contactTags]="contactTags"
          [sequenceItems]="sequenceItems"
        >
        </mm-report-obj-seven>

        <mm-report-obj-eight
          *ngIf="report.rType === 'mm-report-obj-eight'"
          [startDate]="startDate"
          [endDate]="endDate"
          [userIds]="selectedUserIds"
          [tenants]="tenants"
          [reqs]="reqs"
          [reqIds]="selectedReqIds"
          (objDataChanged)="objDataChanged($event, index)"
          [data]="report.data"
          [esFilters]="report.esFilters"
          (delete)="objDeleted($event, index)"
          (clone)="objCloned($event, index)"
          (loaded)="objLoaded($event, index)"
          [reportingTheme]="reportingTheme"
          [useStoredData]="report.useStoredData"
          [reqFiltersUsersGlobal]="reqFiltersUsersGlobal"
          [contactTags]="contactTags"
          [sequenceItems]="sequenceItems"
        >
        </mm-report-obj-eight>

        <!-- 
                <mm-widget-obj-playbook2 *ngIf="report.rType === 'mm-widget-obj-playbook2'"
                    message="testing...{{report.id}}" [startDate]="startDate" [endDate]="endDate"
                    [userIds]="selectedUserIds" [tenants]="tenants" [reqs]="reqs" [reqIds]="selectedReqIds"
                    (objDataChanged)="objDataChanged($event, index)" [data]="report.data" [esFilters]="report.esFilters"
                    (delete)="objDeleted($event, index)" (loaded)="objLoaded($event, index)"  [reportingTheme]="reportingTheme">
                </mm-widget-obj-playbook2> -->
      </gridster-item>
    </gridster>
  </section>

  <footer class="reporting-page-footer">
    <div>
      <!-- Hidden file picker for importing - Select Template from file -->
      <input id="files" #files type="file" (change)="importFromFile($event)" style="display: none" />
    </div>

    <!-- <mm-report-training [startDate]="startDate" (isClickedChanged)="myClickLocal($event)"></mm-report-training> -->
  </footer>
</div>
