import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProcessTotalsComponent } from './process-totals/process-totals.component';
import { ProcessTotalsDialogComponent } from './process-totals/process-totals-dialog.component';
import { FilterDialogComponent } from './dialogs/filter-dialog.component';
import { AddNoteDialogComponent } from './dialogs/add-note-dialog.component';
import { FilterHeaderComponent } from './headers/filter-header.component';
import { NewActivityDialogComponent } from './dialogs/new-activity-dialog.component';
import { ActivityFilterDialogComponent } from './dialogs/activity-filter-dialog.component';
import { AddDialogComponent } from './dialogs/add-dialog.component';
import { ProductivityTabComponent } from './tabs/productivity-tab.component';
import { TodoTabComponent } from './tabs/todo-tab.component';
import { HistoryTabComponent } from './tabs/history-tab.component';
import { SearchFilterComponent } from './headers/search-filter.component';
import { NewContactDialogComponent } from './dialogs/new-contact-dialog.component';
import { HistoryTableTabComponent } from './tabs/history-table-tab.component';
import { ConfirmLogActivityDialogComponent, ContactsAddedDialogComponent } from './dialogs/contacts-added-dialog.component';
import { MobileHeaderComponent } from './headers/mobile-header.component';
import { CompleteTodoDirective } from './directive/complete-todo.directive';
import { AddTenantDialogComponent } from './dialogs/add-tenant-dialog.component';
import { AddUserDialogComponent } from './dialogs/add-user-dialog.component';
import { ResendUserCodeComponent } from './dialogs/resend-user-code-dialog.component';
import { DisplayActivityDialogComponent } from './dialogs/display-activity-dialog.component';
import { EditStatisticDialogComponent } from './dialogs/edit-statistic-dialog.component';
import { DisplayHistoryDialogComponent } from './dialogs/display-history-dialog.component';
import { EditRequisitionDialogComponent } from './dialogs/edit-requisition-dialog.component';
import { AlertDialogComponent } from './dialogs/alert-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PlaybookContactTaskEditKanbanComponent } from '../playbooks/playbook-editors/tasks/task-edit-kanban.component';
import { PlaybookCatSelectTabsComponent } from '../playbooks/playbook-editors/categories/cat-select-tabs.component';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker-ext';
import { ShowFiltersDialogComponent } from './dialogs/show-filters-dialog.component';
import { ShareFiltersDialogComponent } from './dialogs/share-filters-dialog.component';
import { SearchDialogComponent } from './dialogs/search-dialog.component';
import { ImageCropperComponent } from './dialogs/imagecropper/image-cropper.component';
import { DisplayMentionDialog } from './dialogs/display-mention-dialog.component';
import { AutocompleteFilterExample } from '../experimental/autocomplete-filter-example.component';
import { HeaderEditDialog } from '../importing/header-edit-dialog.component';
import { ImportAlertDialog } from '../importing/import-alert-dialog.component';
import { FinalizeImportDialog } from '../importing/finalize-import-dialog.component';
import { MMSnackBarComponent } from './snackbar/mm-snackbar.component';
import { LogoComponent } from './logo/logo.component';
// import { SmsTemplateEditorComponent } from '../integrations/sms/sms-templates/sms-template-editor.component';
import { SmsTemplatePickerComponent } from '../integrations/sms/sms-templates/sms-template-picker.component';

//
// External Modules
//
import { MentionModule } from 'angular-mentions';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatBadgeModule } from '@angular/material/badge';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { EditableComponent } from './editable/editable.component';
import { ViewModeDirective } from './editable/view-mode.directive';
import { EditModeDirective } from './editable/edit-mode.directive';
import { FocusableDirective } from './editable/focusable.directive';
import { EditableOnEnterDirective } from './editable/editable-on-enter.directive';

import { PhoneMaskDirective } from '../shared/directive/phone-mask.directive';
import { OptionsScrollDirective } from '../shared/directive/options-scroll.directive';
import { GridsterModule } from 'angular-gridster2';
// Used for req scoring graph
import { ChartModule } from 'primeng/chart';
// For use with chart.js in angular
import { ChartsModule } from 'ng2-charts';
import { ImageCropperModule } from 'ngx-image-cropper';
//Text Editor (TinyMCE)
import { QuillModule } from 'ngx-quill';

// import { MfaComponentModule } from '../integrations/sms/mfa/mfa.module';
import { ObjectDownloadService } from '../reporting/object-download.service';

//FullCalendar
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import listPlugin from '@fullcalendar/list';

// FullCalendarModule.registerPlugins([
//   // register FullCalendar plugins
//   listPlugin,
// ]);

const EXTERNAL_MODULES = [
  // Material Design
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatTabsModule,
  MatToolbarModule,
  MatIconModule,
  MatSidenavModule,
  MatListModule,
  MatDividerModule,
  MatBadgeModule,
  MatGridListModule,
  MatChipsModule,
  MatTableModule,
  MatTooltipModule,
  MatCardModule,
  MatButtonToggleModule,
  MatSortModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatExpansionModule,
  MatRadioModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatProgressBarModule,
  MatMenuModule,
  ClipboardModule,
  ChartModule,
  // Font Awesome
  FontAwesomeModule,

  // @angular/flex-layout module
  FlexLayoutModule,

  // Drag and Drop
  DragDropModule,
  // Date range picker
  SatDatepickerModule,
  SatNativeDateModule,
  GridsterModule,
  ChartsModule,
  MentionModule,
  ContextMenuModule,
  ModelMatchSelectModule,

  //Image Cropper Module
  ImageCropperModule,
];

//
// Pipes
//
import {
  ActivityIconColorPipe,
  ActivityTypeLabel,
  ActivityReqLabel,
  TodoTimeLabel,
  OpportunityValue,
} from './pipes/activity.pipe';
import { RequisitionRoutePipe, RequisitionNamePipe, StatusNamePipe } from './pipes/requisition.pipe';
import {
  ContactPrimaryPhonePipe,
  ContactSecondaryPhonePipe,
  ContactPrimaryEmailPipe,
  ContactHomePhonePipe,
  ContactSecondaryEmailPipe,
  ContactWorkAltEmailPipe,
  ContactAddressPipe,
  StatsTimePeroid,
  StatConventionalPipe,
  StatGovernmentPipe,
  StatOtherPipe,
  StatNonConformingPipe,
} from './pipes/contact.pipe';
import { ShortHistoryPipe } from './pipes/history.pipe';
import { BytesPipe } from './pipes/bytes.pipe';
import { PaginatorComponent } from './paginator/paginator.component';
import { DisplayColumnsDialogComponent } from './dialogs/display-columns-dialog.component';
import { PlayBookTitleStr, PlayBookCategoryTitleStr, PlayBookTaskTitleStr } from './pipes/playbook.pipe';
import { SaveFiltersDialogComponent } from './dialogs/save-filters-dialog.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog.component';
import { DisableUserDialogComponent } from '../admin/system-admin/tenant-user-manager/disable-user-dialog.component';
import { KeyMask } from './pipes/integrations.pipe';
import { Phone, jsonPrettyPipe } from './pipes/phone.pipe';
import { FormatTitlePipe } from './pipes/formatTitle.pipe';
import { RelativeDatePipe } from './pipes/relativeDate.pipe';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

// Main Stage(s) here
import { AStageComponent } from '../reporting/analytics-stage/a-stage.component';
import { ReportPickerDialogComponent } from '../reporting/pickers/report-objects/report-picker-dialog.component';
import { ExportTotalExpertDialogComponent } from './dialogs/export-to-total-expert-objects/export-te-dialog.component';
import { ExportIntegrationDialogComponent } from './dialogs/export-integration-dialog/export-integration-dialog.component';
import { ExportReqContactsDialogComponent } from './dialogs/export-req-contacts/export-req-contacts-dialog.component';
import { UsersPickerDialogComponent } from '../reporting/pickers/users/users-picker-dialog.component';
import { ReqsPickerDialogComponent } from '../reporting/pickers/requisitions/reqs-picker-dialog.component';
import { ReportTemplatePickerDialogComponent } from '../reporting/pickers/report-templates/report-template-picker-dialog.component';
import { ReportObjectSettingsDialogComponent } from '../reporting/pickers/report-object-settings/report-object-settings-dialog.component';
import { TrainingComponent } from '../reporting/training/training.component';
import { UpdateAgentDialogComponent } from 'app/shared/dialogs/update-agent-dialog/update-agent-dialog.component';

// Put all the reporting components here
import { ReportOneComponent } from '../reporting/reports/one/report-one.component';
import { ReportTwoComponent } from '../reporting/reports/two/report-two.component';
import { ReportThreeComponent } from '../reporting/reports/three/report-three.component';
import { ReportFourComponent } from '../reporting/reports/four/report-four.component';
import { ReportFiveComponent } from '../reporting/reports/five/report-five.component';
import { ReportSixComponent } from '../reporting/reports/six/report-six.component';
import { ReportSevenComponent } from '../reporting/reports/seven/report-seven.component';
import { ReportEightComponent } from '../reporting/reports/eight/report-eight.component';
import { WidgetPlaybookComponent } from '../reporting/reports/playbook/widget-playbook.component';
import { RangesFooter } from '../reporting/analytics-stage/ranges-footer.component';
import { ReportSlidePanelComponent } from '../reporting/analytics-stage/slide-panel.component';
import { ReportLinkShareComponent } from '../reporting/link-share/link-share.component';
import { NumbersPipe } from './pipes/numbers.pipe';
import { LeadsPipe } from './pipes/leads.pipe';
import { ContactsTabComponent } from 'app/requisitions/single-requisition/tabs/contacts-tab.component';

import { ConversationComponent } from '../integrations/sms/conversation.component';
import { Click2CallTwilioComponent } from '../integrations/twilio/click2call-twilio.component';
import { PlaybookTabComponent } from '../playbooks/tab-component/playbook-tab.component';
import { PlaybookOverallScoreComponent } from '../playbooks/playbook-reporting/playbook-overall-score.component';
// import { PlaybookDownloadService } from './playbooks/playbook-template-editors/playbook-templates/playbook-download.service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SendEmailComponent } from 'app/integrations/send-email.component';
import { MmsMediaManagerComponent } from '../integrations/sms/mms-media-manager.component';
import { LoadingSpinner } from '../shared/loading-spinner/loading-spinner.component';
import { SimpleTimePipe } from './pipes/simpletime.pipe';
import { ToolTipComponent } from './tooltips/tooltips.component';
import { ClickOutsideDirective } from './directive/clickOutside.directive';
import { ChatComponent } from './chat/chat.component';
import { ChatBubble } from './chat/chatbubble/chatbubble.component';
import { DndDirective } from './chat/dnd.directive';
import { CreateActivityPopoverComponent } from './create-activity/create-activity-popover.component';
import { ChipListAutocompleteFixDirective } from './directive/chip-list-autocomplete-fix.directive';
import { SnackbarWithButtonsComponent } from './snackbarWithButtons/snackbar-with-buttons.component';
import { CreateTaskPickerComponent } from './create-activity/create-task-picker.component';
import { CreateMeetingComponent } from './create-activity/create-meeting.component';
import { SequencePickerComponent } from 'app/integrations/sms/sequence/sequence-picker.component';

import { LogOutWarningComponent } from './dialogs/logout-warning-dialog.component';

// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
// import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { faChartBar, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import {
  faUsers,
  faCalendarAlt,
  faFileAlt,
  faSuitcase,
  faBriefcase,
  faFilter,
  fas,
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faFacebook, faMonero } from '@fortawesome/free-brands-svg-icons';
import { ReportSharePickerDialogComponent } from 'app/reporting/pickers/report-share/report-share-picker-dialog.component';
import { EditNoteDialogComponent } from './dialogs/edit-note-dialog.component';
import { ContactActionsModule } from 'app/shared-contacts-components/actions/actions.module';
import { ContextMenuModule } from 'app/context-menu/context-menu.component';
import { ModelMatchSelectModule } from 'app/components/betterSelect.component';
// import { LinkViewerComponent } from './links/link-viewer.component';

const REPORT_ENTRIES = [];

const SHARED_REPORTS = [
  AStageComponent,
  ReportOneComponent,
  ReportTwoComponent,
  ReportThreeComponent,
  ReportFourComponent,
  ReportFiveComponent,
  ReportSixComponent,
  ReportSevenComponent,
  ReportEightComponent,
  WidgetPlaybookComponent,
  RangesFooter,
  ReportSlidePanelComponent,
  ReportLinkShareComponent,
  TrainingComponent,
];

/** Pipes to declare and export */
const SHARED_PIPES = [
  RequisitionRoutePipe,
  RequisitionNamePipe,
  StatusNamePipe,
  ContactPrimaryEmailPipe,
  ContactSecondaryEmailPipe,
  ContactWorkAltEmailPipe,
  ContactPrimaryPhonePipe,
  ContactSecondaryPhonePipe,
  ContactHomePhonePipe,
  ActivityIconColorPipe,
  ActivityTypeLabel,
  ActivityReqLabel,
  OpportunityValue,
  TodoTimeLabel,
  ShortHistoryPipe,
  ContactAddressPipe,
  BytesPipe,
  StatsTimePeroid,
  StatConventionalPipe,
  StatGovernmentPipe,
  StatOtherPipe,
  StatNonConformingPipe,
  PlayBookTitleStr,
  PlayBookCategoryTitleStr,
  PlayBookTaskTitleStr,
  KeyMask,
  NumbersPipe,
  Phone,
  jsonPrettyPipe,
  SimpleTimePipe,
  RelativeDatePipe,
  FormatTitlePipe,
];

//
// Components
//

/** Components & Directives to declare and export */
const SHARED_COMPONENTS = [
  ProcessTotalsComponent,
  ChatComponent,
  ChatBubble,
  ProcessTotalsDialogComponent,
  AddNoteDialogComponent,
  NewActivityDialogComponent,
  FilterHeaderComponent,
  FilterDialogComponent,
  ActivityFilterDialogComponent,
  AddDialogComponent,
  SearchDialogComponent,
  ProductivityTabComponent,
  TodoTabComponent,
  HistoryTabComponent,
  ContactsTabComponent,
  SearchFilterComponent,
  NewContactDialogComponent,
  HistoryTableTabComponent,
  ContactsAddedDialogComponent,
  ConfirmLogActivityDialogComponent,
  MobileHeaderComponent,
  CompleteTodoDirective,
  AddTenantDialogComponent,
  AddUserDialogComponent,
  ResendUserCodeComponent,
  DisplayActivityDialogComponent,
  DisplayHistoryDialogComponent,
  EditRequisitionDialogComponent,
  EditStatisticDialogComponent,
  AlertDialogComponent,
  PaginatorComponent,
  DisplayColumnsDialogComponent,
  PlaybookCatSelectTabsComponent,
  PlaybookContactTaskEditKanbanComponent,
  EditableComponent,
  ViewModeDirective,
  EditModeDirective,
  FocusableDirective,
  ClickOutsideDirective,
  EditableOnEnterDirective,
  ChipListAutocompleteFixDirective,
  ShowFiltersDialogComponent,
  SaveFiltersDialogComponent,
  ShareFiltersDialogComponent,
  ConfirmationDialogComponent,
  DisableUserDialogComponent,
  PhoneMaskDirective,
  ReportPickerDialogComponent,
  ExportTotalExpertDialogComponent,
  ExportIntegrationDialogComponent,
  ExportReqContactsDialogComponent,
  UpdateAgentDialogComponent,
  UsersPickerDialogComponent,
  ReqsPickerDialogComponent,
  ReportTemplatePickerDialogComponent,
  ReportSharePickerDialogComponent,
  ReportObjectSettingsDialogComponent,
  DisplayMentionDialog,
  OptionsScrollDirective,
  AutocompleteFilterExample,
  HeaderEditDialog,
  ImportAlertDialog,
  FinalizeImportDialog,
  MMSnackBarComponent,
  LogoComponent,
  ConversationComponent,
  Click2CallTwilioComponent,
  PlaybookTabComponent,
  PlaybookOverallScoreComponent,
  SendEmailComponent,
  MmsMediaManagerComponent,
  LoadingSpinner,
  ToolTipComponent,
  // MfaComponentModule,
  // SmsTemplateEditorComponent,
  SmsTemplatePickerComponent,
  CreateActivityPopoverComponent,
  CreateTaskPickerComponent,
  CreateMeetingComponent,
  SequencePickerComponent,
  LogOutWarningComponent,
];

//
// Dialogs
//
const SHARED_DIALOGS = [
  ProcessTotalsDialogComponent,
  AddNoteDialogComponent,
  NewActivityDialogComponent,
  FilterDialogComponent,
  ActivityFilterDialogComponent,
  AddDialogComponent,
  SearchDialogComponent,
  NewContactDialogComponent,
  ContactsAddedDialogComponent,
  AddTenantDialogComponent,
  AddUserDialogComponent,
  ResendUserCodeComponent,
  DisplayActivityDialogComponent,
  DisplayHistoryDialogComponent,
  EditRequisitionDialogComponent,
  EditStatisticDialogComponent,
  AlertDialogComponent,
  DisplayColumnsDialogComponent,
  ShowFiltersDialogComponent,
  SaveFiltersDialogComponent,
  ShareFiltersDialogComponent,
  ConfirmationDialogComponent,
  DisableUserDialogComponent,
  ReportPickerDialogComponent,
  ExportTotalExpertDialogComponent,
  ExportReqContactsDialogComponent,
  UpdateAgentDialogComponent,
  UsersPickerDialogComponent,
  ReqsPickerDialogComponent,
  ReportTemplatePickerDialogComponent,
  ReportSharePickerDialogComponent,
  ReportObjectSettingsDialogComponent,
  ImageCropperComponent,
  DisplayMentionDialog,
  HeaderEditDialog,
  ImportAlertDialog,
  FinalizeImportDialog,
];

const quillToolbar = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  // ['link']
  ['link', 'image'],
];

/**
 * The Shared module holds the common components, directives, and pipes and shares them with the modules that need them.
 *
 * Notes:
 * - Services and related models go in the Services module, not here.
 * - If a component, directive, or pipe is only needed by a single feature, it belongs within that feature's module.
 *
 * @see https://angular.io/guide/sharing-ngmodules
 */
@NgModule({
  imports: [
    // Angular stuff
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    // Specify ng-circle-progress as an import
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
      showSubtitle: false,
    }),
    QuillModule.forRoot({
      modules: {
        toolbar: quillToolbar,
      },
    }),
    FullCalendarModule,
    ContactActionsModule,
    PickerModule,
    ...EXTERNAL_MODULES,
  ],
  declarations: [
    ...SHARED_COMPONENTS,
    ...SHARED_REPORTS,
    ...SHARED_PIPES,
    ...SHARED_DIALOGS,
    LeadsPipe,
    DndDirective,
    SnackbarWithButtonsComponent,
    EditNoteDialogComponent,
  ],
  exports: [
    // Angular stuff
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ...EXTERNAL_MODULES,
    // Shared module stuff;
    ...SHARED_COMPONENTS,
    ...SHARED_REPORTS,
    ...SHARED_PIPES,
  ],
  providers: [RequisitionRoutePipe, ObjectDownloadService],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    //Font Awesome Icon Declarations
    // library.add(faChartBar, faUsers, faCalendarAlt, faFileAlt, faSuitcase, faBriefcase, faFilter, faLinkedin, faFacebook, faCalendarCheck);
    library.addIcons(
      faChartBar,
      faUsers,
      faCalendarAlt,
      faFileAlt,
      faSuitcase,
      faBriefcase,
      faFilter,
      faLinkedin,
      faFacebook,
      faCalendarCheck,
      faMonero
    );
  }
}
