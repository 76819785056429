<mat-dialog-content>
  <div class="logo-container">
    <img class="mm-logo" src="assets/images/modelmatch_transparent.png" alt="Model Match Logo" />
    <span style="font-weight: 700; line-height: 0.9em">MODEL<br />MATCH</span>
  </div>
  <form
    *ngIf="!isResetRequired && !isForgotPasswordEnabled && !isNotifyMultipleSessionsEnabled"
    [formGroup]="loginForm"
    (ngSubmit)="onLogin()"
  >
    <mat-form-field class="full-width-field">
      <input matInput placeholder="Username" formControlName="username" data-testid="username" />
    </mat-form-field>
    <mat-form-field class="full-width-field">
      <input matInput type="password" placeholder="Password" formControlName="password" data-testid="password" />
    </mat-form-field>
    <div class="button-row">
      <button
        mat-raised-button
        class="submit-button"
        [disabled]="loggingIn"
        type="submit"
        color="primary"
        data-testid="submit"
      >
        Login
      </button>
      <button mat-raised-button class="submit-button" (click)="enableForgotPassword()">Forgot Password</button>
    </div>
  </form>
  <form *ngIf="isResetRequired" [formGroup]="resetPasswordForm" (ngSubmit)="onResetPassword()">
    <mat-form-field class="full-width-field">
      <input matInput type="password" placeholder="New password" formControlName="newPassword" />
    </mat-form-field>
    <mat-form-field class="full-width-field">
      <input matInput type="password" placeholder="Confirm password" formControlName="confirmPassword" />
    </mat-form-field>
    <button mat-raised-button class="submit-button" type="submit" color="primary">Reset Password</button>
  </form>
  <form *ngIf="isForgotPasswordEnabled" [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPassword()">
    <mat-form-field class="full-width-field">
      <input matInput placeholder="Email" formControlName="email" />
    </mat-form-field>
    <div class="button-row">
      <button mat-raised-button class="submit-button" type="submit" color="primary">Reset Password</button>
      <button mat-raised-button class="submit-button" (click)="disableForgotPassword()">Login</button>
    </div>
  </form>
  <form *ngIf="isNotifyMultipleSessionsEnabled">
    <h4>
      You are currently logged in on another device. Would you like to end that session and log in on this device?
    </h4>
    <div class="button-row-center">
      <button (click)="enableSession(true)" mat-raised-button class="submit-button" type="submit" color="primary">
        Yes
      </button>
      <button (click)="enableSession(false)" mat-raised-button class="submit-button">Cancel</button>
    </div>
  </form>
</mat-dialog-content>
