import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { SMSServiceNeo } from '../../../services/sms.appsync.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ReportingService } from '../../../services/reporting.service';
import { UserService } from '../../../services/user.service';
import { MatSelectionListChange } from '@angular/material/list';
// import { FormControl } from '@angular/forms';
@Component({
  selector: 'mm-sms-template-picker',
  templateUrl: './sms-template-picker.component.html',
  styleUrls: ['./sms-template-picker.component.scss'],
})
export class SmsTemplatePickerComponent implements OnInit {
  constructor(
    private smsServiceNeo: SMSServiceNeo,
    private router: Router,
    private reportingService: ReportingService,
    private userService: UserService
  ) {}

  // smsTemplatesControl = new FormControl();
  @Output() templateSelected: EventEmitter<any> = new EventEmitter();
  @Input() showAdd: false;
  @Input() triggerChange: false;

  smsTemplates: any;
  userId: string = '';
  selectedOptions: any;

  ngOnInit(): void {
    this.smsServiceNeo
      .listSMSTemplates(JSON.stringify({ active: true }), '')
      .then((result: any) => {
        // console.log(' listSMSTemplates result', result);

        this.smsTemplates = JSON.parse(result.data.listSMSTemplates);

        this.smsTemplates.map((ele) => {
          if (ele.objects.length === 0)
            ele.objects.push({ type: 'message', text: '', display: 'Send Message', icon: 'sms' });
        });

        console.log(' listSMSTemplates result', this.smsTemplates);
      })
      .catch((error) => {});
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName) && Object.keys(changes).length === 1) {
        switch (propName) {
          case 'triggerChange': {
            this.smsServiceNeo
              .listSMSTemplates(JSON.stringify({ active: true }), '')
              .then((result: any) => {
                // console.log(' listSMSTemplates result', result);

                this.smsTemplates = JSON.parse(result.data.listSMSTemplates);

                this.smsTemplates.map((ele) => {
                  if (ele.objects.length === 0)
                    ele.objects.push({ type: 'message', text: '', display: 'Send Message', icon: 'sms' });
                });

                console.log('Reload listSMSTemplates result', this.smsTemplates);
              })
              .catch((error) => {});

            break;
          }
        }
      }
    }
  }

  onSelection(event: MatSelectionListChange) {
    console.log('picker selection', event);

    if (event[0]) {
      console.log('event.option.selected', event[0]);

      this.templateSelected.emit(event[0]);

      // this.smsTemplate = event.options[0].value;
      // this.smsTemplateTitle = this.smsTemplate.title;
    }
  }

  onClickAdd(item: any) {
    let newSMSTemplate = {
      title: 'New SMSTemplate',
      objects: [{ type: 'message', text: '', display: 'Send Message', icon: 'sms' }],
    };

    this.smsServiceNeo
      .createSMSTemplate(newSMSTemplate, '', 'USER')
      .then((result: any) => {
        console.log(' createSMSTemplate result', result);

        let parseResult = JSON.parse(result.data.createSMSTemplate);

        this.smsTemplates.push(parseResult);

        console.log(parseResult);

        this.selectedOptions = [];
        this.selectedOptions.push(parseResult);

        this.templateSelected.emit(parseResult);

        // cheesy that this did not honor my value updates, { emitEvent: true }
        // this.smsTemplatesControl.setValue(this.smsTemplates[this.smsTemplates.length - 1]);
      })
      .catch((error) => {});
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
}
