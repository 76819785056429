<div fxLayout="row">
  <h1 mat-dialog-title>{{ data.task.title }}</h1>

  <div fxFlex fxLayoutAlign="end start">
    <button mat-mini-fab color="primary" (click)="onNoClick()">
      <mat-icon matSuffix class="muted" size="sm">close</mat-icon>
    </button>
  </div>
</div>

<div fxLayout="row" style="margin-bottom: 1em">
  <div fxFlex fxLayoutAlign="start center" style="padding-top: 1em">
    <mat-radio-group aria-label="Select an option" class="task-status">
      <mat-radio-button
        class="task-status-button"
        value="open"
        color="warn"
        (change)="radioChange($event)"
        [checked]="data.taskRC.status === 'open' || !data.taskRC.status"
        >Open
      </mat-radio-button>
      <mat-radio-button
        class="task-status-button"
        value="working"
        color="primary"
        (change)="radioChange($event)"
        [checked]="data.taskRC.status === 'working'"
      >
        Working
      </mat-radio-button>
      <mat-radio-button
        class="task-status-button"
        value="complete"
        color="accent"
        (change)="radioChange($event)"
        [checked]="data.taskRC.status === 'complete'"
      >
        Complete
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div fxLayout="row" style="margin-bottom: 1em">
  <div fxFlex class="members-list">
    <!-- Add anyone option
             <mat-form-field class="members-chip-list">
            <mat-chip-list #chipList style="width: 100%">
                <mat-chip *ngFor="let member of this.data.taskRC.assignedTo" [selectable]="selectable"
                    [removable]="removable" (removed)="removeMember(member)">
                    {{member}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Assign to Member..." [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="addMember($event)">
            </mat-chip-list>
        </mat-form-field> -->

    <!-- Only allow adding users who are assigned to req already -->
    <mat-chip-list #reqUsersChipList aria-label="Assign Member Selection">
      <mat-chip
        *ngFor="let member of this.data.taskRC.assignedTo"
        [selectable]="selectableReqUser"
        [removable]="removableReqUser"
        (removed)="removeMember(member)"
      >
        {{ member }}
        <mat-icon matChipRemove *ngIf="removableReqUser">cancel</mat-icon>
      </mat-chip>

      <input
        placeholder="Assign to Member..."
        #reqUserInput
        [formControl]="reqUsersControl"
        [matAutocomplete]="autoReqUsers"
        [matChipInputFor]="reqUsersChipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      />

      <!--[matChipInputAddOnBlur]="addOnBlur"  (matChipInputTokenEnd)="addMember($event)" -->
    </mat-chip-list>

    <mat-autocomplete #autoReqUsers="matAutocomplete" (optionSelected)="selectedReqUser($event)">
      <mat-option *ngFor="let reqUser2 of filteredReqUsers | async" [value]="reqUser2">
        {{ reqUser2.firstName }} {{ reqUser2.lastName }}
      </mat-option>
    </mat-autocomplete>

    <!-- Debug info 
            <div *ngFor="let reqUser of this.data.reqUsers">
            {{reqUser | json}}
        </div> -->
  </div>
</div>

<div mat-dialog-content>
  <p class="task-desc">{{ data.task.desc }}</p>

  <editable
    class="notes-editable-container"
    (update)="addNote(true, 'update')"
    triggerEvent="click"
    triggerClose="{{ triggerClose }}"
    triggerEditOpen="{{ triggerEditOpen }}"
  >
    <!-- Make sure any button elements event.stopPropagation() or edit view will flip back to view mode -->
    <ng-template viewMode>
      <div fxLayout="column" fxLayoutGap="1em" class="notes-editable-view">
        <div fxLayout="row">
          <button mat-mini-fab color="primary" class="nav-icon" (click)="editNote($event)">
            <mat-icon>add</mat-icon>
          </button>

          <p class="with-icon">Notes</p>
        </div>
      </div>
    </ng-template>
    <ng-template editMode>
      <!-- <input [formControl]="getControl(index, 'name')" focusable editableOnEnter> -->

      <div fxLayout="column" fxLayoutGap="1em" class="notes-editable-edit">
        <div fxLayout="row">
          <div fxFlex>
            <mat-form-field class="default-activity-editor">
              <textarea
                matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                placeholder="Write a comment..."
                [formControl]="activityDefaultControl"
                focusable
                editableOnEnter
              >
              </textarea>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row">
          <div fxFlex>
            <button mat-raised-button color="primary" (click)="addNote(true, 'save')">Save</button>
            <button mat-icon-button (click)="addNote(false, 'cancel')">
              <mat-icon matSuffix class="muted" size="smv">close</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </editable>

  <section class="list-notes-section" *ngIf="data.taskRC.notes">
    <div
      fxLayout="column"
      fxLayoutGap="1em"
      *ngFor="let note of data.taskRC.notes; index as noteIndex"
      class="task-desc"
    >
      <!-- 

        by: "",
  createdAt: new Date().toISOString(),
  modifiedAt: 


        -->

      <mat-card class="note-card" style="background-color: transparent" (click)="$event.stopPropagation()">
        <mat-card-header>
          <div fxFlex fxLayout="row" fxLayoutGap="1em">
            <div fxFlex fxLayoutAlign="start center">
              <!-- Material inserting a mat-card-header-text element even if specified-->
              <div fxFlex fxLayout="row" style="margin-left: -2em">
                <div
                  mat-card-avatar
                  class="note-card-header-avatar"
                  fxLayoutAlign="start center"
                  style="margin-right: 1em"
                >
                  <button mat-mini-fab>{{ getShortName(note.by) }}</button>
                </div>

                <div fxLayout="column" fxFlex fxLayoutAlign="start start">
                  <mat-card-title>{{ getFullName(note.by) }}</mat-card-title>
                  <mat-card-subtitle
                    >{{ note.createdAt | date: 'medium' }}
                    <span
                      style="font-size: 0.6em"
                      *ngIf="note.createdAt != note.modifiedAt"
                      title="Last edited {{ note.modifiedAt | date: 'medium' }} "
                      >(edited)
                    </span>
                  </mat-card-subtitle>
                </div>
              </div>
            </div>
          </div>

          <div fxLayoutAlign="end start" class="show-hover-note-card">
            <button mat-icon-button (click)="editNoteByIndex($event, noteIndex, note.id)">
              <mat-icon>mode_edit</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onDeleteNote($event, note.id)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </mat-card-header>

        <mat-card-content style="margin-bottom: 0">
          <editable
            class="notes-editable-container"
            index="{{ noteIndex }}"
            (update)="saveNoteByIndex($event, true, noteIndex, note.id, 'update')"
            triggerEvent="click"
            triggerClose="{{ triggerCloseByIndex[noteIndex] }}"
            triggerEditOpen="{{ triggerEditOpenByIndex[noteIndex] }}"
          >
            <ng-template viewMode>
              <div class="notes-editable-list-view">
                {{ note.content }}
              </div>
            </ng-template>
            <ng-template editMode>
              <div fxLayout="column" fxLayoutGap="1em" class="notes-editable-edit">
                <div fxLayout="row">
                  <div fxFlex>
                    <mat-form-field class="default-activity-editor">
                      <textarea
                        matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        placeholder="Write a comment..."
                        [formControl]="getInPlaceEditControl(noteIndex, 'content')"
                        focusable
                        editableOnEnter
                      >
                      </textarea>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row">
                  <div fxFlex>
                    <button
                      mat-raised-button
                      color="primary"
                      (click)="saveNoteByIndex($event, true, noteIndex, note.id, 'save button')"
                    >
                      Save
                    </button>
                    <button
                      mat-icon-button
                      (click)="saveNoteByIndex($event, true, noteIndex, note.id, 'cancel button')"
                    >
                      <mat-icon matSuffix class="muted" size="smv">close</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </editable>
        </mat-card-content>
        <!-- <mat-card-actions style="margin-top: 0;">
                    <button mat-button (click)="editNoteByIndex($event, noteIndex, note.id)">edit</button>
                    <button mat-button (click)="onDeleteNote($event, note.id)">delete</button>
                </mat-card-actions> -->
      </mat-card>
    </div>
  </section>
</div>
