<div class="filter-header">
  <div class="dropdown-container">
    <mat-form-field *ngFor="let dropdown of dropdowns">
      <mat-select
        [placeholder]="dropdown.field.label"
        [(value)]="dropdown.selected"
        (selectionChange)="onFilter(dropdown.field, $event.value)"
      >
        <mat-option *ngFor="let option of dropdown.options" [value]="option.value">
          {{ option.value | statusName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- <mat-button-toggle-group name="timeframeToggle" aria-label="Timeframe Toggle" class="timeframe-toggle">
        <mat-button-toggle value="week" checked>Last Week</mat-button-toggle>
        <mat-button-toggle value="month">Last Month</mat-button-toggle>
        <mat-button-toggle value="year">Last Year</mat-button-toggle>
        <mat-button-toggle value="all">All Time</mat-button-toggle>
    </mat-button-toggle-group> -->
</div>
