import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Injectable()
export class ImageCachingService {
  constructor(private sanitizer: DomSanitizer) {}
  private _cachedImages: any = {};

  public checkImageInCache(key: string) {
    return !!this._cachedImages[key];
  }

  public getImage(key: string) {
    return new Observable<any>((ob) => {
      if (this._cachedImages[key]) {
        const image = this._cachedImages[key];
        console.log('FOUND IMAGE IN CACHE!');
        ob.next({
          data: {
            getContactAvatarUrl: {
              url: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(image)),
              id: key,
            },
          },
        });
      }
    });
  }

  public checkAndCacheImage(key: string, blob: Blob) {
    this._cachedImages[key] = blob;
  }

  public cacheImageFromUrl(url: string, id: string) {
    if (url['changingThisBreaksApplicationSecurity']) return;
    fetch(url)
      .then((data) => data.blob())
      .then((blob: Blob) => {
        this._cachedImages[id] = blob;
      });
  }
}
