import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
export interface AlertDialogButton {
  label: string;
  color?: ThemePalette;
  value?: string;
}

export interface AlertDialogConfig {
  title?: string;
  message?: string;
  extraMessage?: string;
  confirmButton?: AlertDialogButton;
  cancelButton?: AlertDialogButton;
}

@Component({
  selector: 'mm-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  data: AlertDialogConfig;

  constructor(
    dialogRef: MatDialogRef<AlertDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) data: AlertDialogConfig
  ) {
    this.data = data;
    dialogRef.backdropClick().subscribe(() => dialogRef.close(false));
  }
}
