import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Activity, ActivityType } from '../model/activity';
import { Filter, Option, Field } from '../model/filter';
import { ActivityCriteria, FilterResult } from 'app/shared/model/filter';

@Component({
  selector: 'mm-activity-filter-dialog',
  templateUrl: './activity-filter-dialog.component.html',
})
export class ActivityFilterDialogComponent implements OnInit {
  startDate = new Date();
  filterCriteria: ActivityCriteria;
  activityTypes: Option[] = [];
  stageTypes: Option[] = [];
  reqs: Option[] = [{ value: '', title: '- None -' }];

  completedBy: Field = {
    label: 'Completed By',
    className: 'title-field',
    hint: 'Write person name to search',
    criteria: 'completedBy',
  };
  activityFilter: Filter;
  isSideBar: boolean;

  constructor(
    public dialogRef: MatDialogRef<ActivityFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    // load initial filter criteria sent in and filter by that criteria
    this.isSideBar = this.data.isSideBar;
    this.data.activityTypes.forEach((key) => this.activityTypes.push({ value: key.value, title: key.title }));
    this.data.stageTypes.forEach((key) => this.stageTypes.push({ value: key.value, title: key.title }));
    this.initActivityFilter();
    this.data.requisitions.forEach((req) => {
      this.reqs.push({ value: req.reqId, title: req.title });
    });
  }

  initActivityFilter() {
    this.filterCriteria = {};
    this.filterCriteria = this.data.filterCriteria;
    this.activityFilter = {
      inputFields: [
        {
          label: 'Title',
          className: 'title-field',
          hint: 'Write title to search',
          criteria: 'title',
        },
        {
          label: 'Description',
          className: 'title-field',
          hint: 'Write description to search',
          criteria: 'description',
        },
        {
          label: 'Participant',
          className: 'title-field',
          hint: 'Write participant to search',
          criteria: 'participant',
        },
      ],
      dropdowns: [
        {
          field: {
            label: 'Activity Type',
            hint: 'Select desired activity type',
          },
          className: 'activity-type-selection',
          options: this.activityTypes,
          criteria: 'type',
        },
        {
          field: {
            label: 'Stage',
            hint: 'Select a desired stage',
          },
          className: 'stage-selection',
          options: this.stageTypes,
          criteria: 'stage',
        },
      ],
      dateFields: [],
    };
    if (!this.data.isFromRequisition) {
      this.activityFilter.dropdowns.push({
        field: {
          label: 'Pipeline',
          hint: 'Select a desired Pipeline',
        },
        className: 'requisition-selection',
        options: this.reqs,
        criteria: 'requisition',
      });
    }

    if (!this.data.isHistory) {
      this.activityFilter.dateFields.push({
        label: 'Start Date',
        className: 'date-field',
        criteria: 'dueDateStart',
      });
      this.activityFilter.dateFields.push({
        label: 'End Date',
        className: 'date-field',
        criteria: 'dueDateEnd',
      });
    } else {
      this.activityFilter.inputFields.push(this.completedBy);
      this.activityFilter.dateFields.push({
        label: 'Compeleted Start Date',
        className: 'date-field',
        criteria: 'compeletedDateStart',
      });
      this.activityFilter.dateFields.push({
        label: 'Compeleted End Date',
        className: 'date-field',
        criteria: 'compeletedDateEnd',
      });
    }
  }

  onCloseClick(): void {
    const result: FilterResult = {
      filterAction: 'close',
      filterCriteria: this.filterCriteria,
    };
    this.dialogRef.close(result);
  }

  onClearClick(): void {
    const filters: ActivityCriteria = {};
    filters.todoSection = this.data.filterCriteria.todoSection;
    filters.activity = this.data.filterCriteria.activity;
    //close dialog while sending back filter criteria and filtered activities
    const result: FilterResult = {
      filterAction: 'apply',
      filterCriteria: filters,
    };
    this.dialogRef.close(result);
  }

  onApplyClick(): void {
    //filter results
    this.filter();
    //close dialog while sending back filter criteria and filtered activities
    const result: FilterResult = {
      filterAction: 'apply',
      filterCriteria: this.filterCriteria,
    };
    this.dialogRef.close(result);
  }

  filter(): void {
    //verify both start and end dates are set if one is
    if (this.filterCriteria.dueDateStart && !this.filterCriteria.dueDateEnd) {
      this.filterCriteria.dueDateEnd = this.filterCriteria.dueDateStart;
    } else if (!this.filterCriteria.dueDateStart && this.filterCriteria.dueDateEnd) {
      this.filterCriteria.dueDateStart = this.filterCriteria.dueDateEnd;
    }

    if (this.filterCriteria.compeletedDateStart && !this.filterCriteria.compeletedDateEnd) {
      this.filterCriteria.compeletedDateEnd = this.filterCriteria.compeletedDateStart;
    } else if (!this.filterCriteria.compeletedDateStart && this.filterCriteria.compeletedDateEnd) {
      this.filterCriteria.compeletedDateStart = this.filterCriteria.compeletedDateEnd;
    }
    //delete empty filter criteria
    Object.keys(this.filterCriteria).forEach((key) => !this.filterCriteria[key] && delete this.filterCriteria[key]);

    console.log('ActivityFilterDialog: filterCriteria', this.filterCriteria);
  }

  search(activity) {
    return Object.keys(this).every((key) => {
      switch (key) {
        case 'type':
          return activity[key] ? activity[key].toUpperCase() == this[key].toUpperCase() : false;
        case 'reqId':
          return activity[key] ? activity[key] == this[key] : false;
        case 'startDate':
          return activity.dueOn
            ? new Date(this[key]).toISOString().substring(0, 10) <= activity.dueOn.substring(0, 10)
            : false;
        case 'endDate':
          return activity.dueOn
            ? new Date(this[key]).toISOString().substring(0, 10) >= activity.dueOn.substring(0, 10)
            : false;
        case 'activity':
        default:
          return true;
      }
    });
  }

  onActivityFilterClick(event): void {
    if (event.value == 'all') {
      this.filterCriteria.activity = 'all';
    } else {
      this.filterCriteria.activity = 'user';
    }
  }

  prefillOtherDate(field) {
    if (field === 'dueDateStart' && !this.filterCriteria.dueDateEnd) {
      this.filterCriteria.dueDateEnd = this.filterCriteria.dueDateStart;
    } else if (field === 'dueDateEnd' && !this.filterCriteria.dueDateStart) {
      this.filterCriteria.dueDateStart = this.filterCriteria.dueDateEnd;
    }

    if (field === 'compeletedDateStart' && !this.filterCriteria.compeletedDateEnd) {
      this.filterCriteria.compeletedDateEnd = this.filterCriteria.compeletedDateStart;
    } else if (field === 'compeletedDateEnd' && !this.filterCriteria.compeletedDateStart) {
      this.filterCriteria.compeletedDateStart = this.filterCriteria.compeletedDateEnd;
    }
  }
}
