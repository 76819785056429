import { Optional, Component, Input, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'report-link-share',
  templateUrl: './link-share.component.html',
  styleUrls: ['./link-share.component.scss'],
})
export class ReportLinkShareComponent implements OnInit {
  @Input() linkId: string;

  constructor(
    private router: Router,
    @Optional() public snackBarRef: MatSnackBarRef<ReportLinkShareComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data?: any
  ) {}

  onXClick() {
    if (this.snackBarRef) this.snackBarRef.dismiss();
  }

  onCopy(path) {
    return `${window.location.host}/#/share${this.data.path}`;
  }

  ngOnInit() {
    console.log('data', this.data);
  }
}
