import {
  OnInit,
  OnDestroy,
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  Input,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { CanComponentDeactivate } from 'app/can-deactivate.guard';
import { SMSServiceNeo } from 'app/services/sms.appsync.service';
import { SMSService } from 'app/services/sms.service';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { differenceInMinutes } from 'date-fns';
import { MessageLog } from '../model/sms';
import { ReportingService } from 'app/services/reporting.service';
import { HistoryInput, ActivityType } from '../../shared/model/activity';
import { IntegrationsService } from 'app/services/integrations.service';
import { UserService } from 'app/services/user.service';
import { ImageCachingService } from '../../services/imagecaching.service';
import { MMSMedia } from '../model/user';
import { TodoService } from 'app/services/todo.service';
import { NotificationsService } from 'app/services/notifications.service';

@Component({
  selector: 'mm-chat-component',
  templateUrl: './chat.component.html',
  styleUrls: ['../../nick_styles/nick_op.css', './chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit, CanComponentDeactivate {
  @ViewChild('textArea', { read: ElementRef }) textArea: ElementRef;
  @ViewChild('scrollframe', { read: ElementRef }) private scrollFrame: ElementRef;
  @ViewChildren('item') itemElements: QueryList<any>;
  @Output() onCloseQuickSMS = new EventEmitter<any>();
  @Input() assignedNumber;
  @Input() moObj; // ConversationTarget
  @Input() SAC: boolean = false; // Stand Alone Complex
  private scrollContainer: any;
  private isNearBottom = true;
  public mmsMedia: any = [];
  public isLoadingOlderMessages: boolean = false;
  public lastRequest: string;
  public firstRequest: string;
  public lastDisplayedTimeStamp: string;
  public messageHistory: MessageLog[] = [];
  public initialLoadComplete: boolean = false;
  public topThreshold = 150;
  public sending: boolean = false;
  public firstSMSMessage: boolean = false;
  public userAvatarUrl: string = '';
  public userId: string;
  public updatedMessageLogSubscription;
  public messageToSend: string = '';
  public mmsMediaToSend: MMSMedia[] = [];
  public maximize: boolean = true;
  public showingEmojiPicker: boolean = true;
  public message = '';
  public showEmojiPicker = false;
  public showMMSMediaPicker = false;
  public showSMSTemplatePicker = false;
  public sets = ['native', 'google', 'twitter', 'facebook', 'emojione', 'apple', 'messenger'];
  public set = 'twitter';
  public fileover: boolean = false;
  // Disable input if no number is assigned.
  disabled: boolean = false;
  constructor(
    private smsServiceNeo: SMSServiceNeo,
    private smsService: SMSService,
    private reportingService: ReportingService,
    private integrationsService: IntegrationsService,
    private userService: UserService,
    private imageCachingService: ImageCachingService,
    private todoService: TodoService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    if (this.assignedNumber) {
      if (this.moObj?.contactNumber && this.assignedNumber?.friendlyName) {
        this.markAsRead(this.assignedNumber?.friendlyName, this.moObj.contactNumber);

        this.getMoreMessages('15', true);

        this.checkIfFirstMessage();
      }
    } else {
      this.loadAssignedNumber();
    }
  }

  onFireFileOver($event) {
    if (this.fileover !== $event) {
      this.fileover = $event;
    }
  }

  onFileDropped($event) {
    console.log($event);
    this.uploadDocumentHandler($event);
  }

  removeMedia(id: string) {
    console.log(this.mmsMediaToSend.indexOf(this.mmsMediaToSend.find((media) => media.id === id)));
    this.mmsMediaToSend.splice(
      this.mmsMediaToSend.indexOf(
        this.mmsMediaToSend.find((media) => media.id === id),
        1
      )
    );
  }

  uploadDocumentHandler($event) {
    // this.progressMessage = 'Upload started - allocating storage';
    // this.uploadingMedia = true;

    let file: File = $event[0];
    // this.uploadDocument.emit(file);

    const MMSMediaDocId = '';

    this.smsService
      .buildMMSMediaStorageLocation(file, file.name, file.type, MMSMediaDocId)
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log(result);

          // this.progressMessage = 'Storage Allocated - uploading file';

          delete result.data.buildMMSMediaStorageLocation.__typename;

          this.smsService
            .uploadFileToS3(file, file.name, file.type, result.data.buildMMSMediaStorageLocation)
            .pipe(take(1))
            .subscribe(
              (putResult: any) => {
                //console.log('smsService :: buildMMSMediaStorageLocation - Pit result Event == %s', JSON.stringify(event));

                // this.progressMessage = 'File uploaded to storage - processing';

                this.smsService
                  .resizeMMSMediaImages(result.data.buildMMSMediaStorageLocation)
                  .pipe(take(1))
                  .subscribe(
                    (thumbResult: any) => {
                      console.log(result);
                      // this.progressMessage = 'Media Processed';

                      this.mmsMedia.push(result.data.buildMMSMediaStorageLocation);
                      this.mmsMediaToSend.push(result.data.buildMMSMediaStorageLocation);

                      //console.log('SingleContactComponent :: contact upload document ', result);

                      // let snackBarRef = this.snackBar.open('MMS Media Uploaded Successfully ...', '', {
                      //   duration: 2000,
                      // });
                      // snackBarRef.afterDismissed().subscribe(() => {
                      //   // this.setAllHistories();
                      // });

                      // this.uploadingMedia = false;

                      // this.mediaSelected.emit(this.mmsMedia[this.mmsMedia.length - 1]);
                    },
                    (error: any) => {
                      console.error('smsService :: buildMMSMediaStorageLocation - Error == %s', JSON.stringify(error));
                      // this.uploadingMedia = false;
                    }
                  );
              },
              (error: any) => {
                console.error('smsService :: buildMMSMediaStorageLocation - Error == %s', JSON.stringify(error));
                // this.uploadingMedia = false;
              }
            );
        },
        (error) => {
          console.log('SingleContactComponent :: error upload contact document ', error);
          // this.snackBar.open('An error occurred, please try again later.', '', {
          //   duration: 5000,
          // });
          // this.uploadingMedia = false;
        }
      );
  }

  twemojify(message: string): string {
    return twemoji.parse(message, {
      folder: 'svg',
      ext: '.svg',
    });
  }

  closeQuickSMS() {
    this.onCloseQuickSMS.emit(false);
  }

  addEmoji(event) {
    this.messageToSend = `${this.messageToSend}${event.emoji.native}`;
    this.showEmojiPicker = false;
  }

  maximizeMessages() {
    this.maximize = !this.maximize;
    this.scrollToBottom();
  }

  checkIfFirstMessage() {
    this.firstSMSMessage = false;

    if (this.moObj.contact?.id)
      this.reportingService
        .getReportingCounts(
          ['history'],
          null,
          '',
          '',
          JSON.stringify([{ completedDate: { order: 'desc' } }]),
          '1',
          '',
          '',
          '',
          [],
          ['currentUser'],
          [
            { field: 'type.keyword', value: [ActivityType.SMS], qualifier: 'match', bool: 'AND' },
            { field: 'contacts.id.keyword', value: [this.moObj.contact.id], qualifier: 'match', bool: 'AND' },
            { field: 'analytics.disposition.keyword', value: ['Initial'], qualifier: 'match', bool: 'AND' },
          ],
          ''
        )
        .pipe(take(1))
        .toPromise()
        .then((res) => {
          console.log('verfifyCallLogsAreComplete getReportingCounts result ', res);
          // parse results as JSON
          let queryResults = JSON.parse(res.data.getReportingCounts);

          console.log('getReportingCounts queryResults parsed ', queryResults);

          // If no results found - no SMS ever made
          if (queryResults.hits.hits.length === 0) {
            this.firstSMSMessage = true;
            return;
          }

          // If 1 results found as expected
          if (queryResults.hits.hits.length === 1) {
            // Found a last record

            this.firstSMSMessage = false;
            return;
          } else {
            this.firstSMSMessage = false;
            return;
          }
        })
        .catch((err) => {
          console.log('checkIfFirstMessage: There was an error getting messages ', err);
        });
  }

  markAsRead(agentNumber, contactNumber) {
    this.smsServiceNeo
      .updateMessageRead(agentNumber, contactNumber)
      .then((result: any) => {})
      .catch((error) => {
        console.log('markAsRead Convo: There was an error updating active message ', error);
      });
  }

  getMoreMessages(size: string, scrollToBottomFlag: boolean = false) {
    if (!size) size = '5';

    if (this.isLoadingOlderMessages) return;

    this.isLoadingOlderMessages = true;
    console.log(this.moObj?.contactNumber);
    this.smsService
      .listMessageConversationalHistory(
        this.moObj?.contactNumber,
        this.assignedNumber.phoneNumber,
        size,
        this.lastRequest,
        '0'
      )
      .pipe(take(1))
      .toPromise()
      .then((res) => {
        let resultsParsed = JSON.parse(res.data.listMessageConversationalHistory);

        if (resultsParsed.length) {
          this.lastRequest = resultsParsed[resultsParsed.length - 1].createdDate;

          if (!this.firstRequest) this.firstRequest = resultsParsed[0].createdDate;
        }

        // Reset for each section
        this.lastDisplayedTimeStamp = '';

        // Verfiy messages are not already in local array
        // this.messageHistory.unshift(...resultsParsed.reverse());
        // resultsParsed = resultsParsed.reverse();
        console.log('PARSED RESULTS', resultsParsed);
        resultsParsed.forEach((ele) => {
          ele.displayTimeDate = this.getLastDisplayedTimeStamp(ele);

          if (this.messageHistory.findIndex((arr) => arr.id === ele.id) < 0) {
            // Fix any expired images
            if (ele.mType === 1)
              ele.mediaUrl?.map((each) => {
                // Erase old default thumbnail
                each.thumbUrl = '';

                this.smsServiceNeo
                  .GetMMSMediaUrl(each.id, '-thumb')
                  .then((result: any) => {
                    // Replace with refreshed thumbnail
                    each.thumbUrl = result.data.getMMSMediaUrl;
                  })
                  .catch((err) => {});
              });

            this.messageHistory.unshift(ele);
          }
        });

        this.isLoadingOlderMessages = false;
        console.log('getMoreMessages: ', this.messageHistory);

        setTimeout(() => {
          // Since directly messing with native elements causes virtual dom delays
          // Set it after delay for completeness
          this.initialLoadComplete = true;
        }, 1000);

        if (scrollToBottomFlag) {
          // this.scrollToBottom();
        } else {
          if (resultsParsed.length) this.scrollOnLoadMore(resultsParsed.length);
        }
      })
      .catch((err) => {
        console.log('getMoreMessages: There was an error getting messages ', err);
      });
  }

  private scrollOnLoadMore(num): void {
    this.scrollContainer.scroll({
      top: this.topThreshold + 10, // 56 * num,
      left: 0,
      behavior: 'smooth',
    });
  }

  private scrollToBottom(): void {
    // Do it now for responsiveness
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });

    for (let x = 1; x < 6; x++) {
      setTimeout(() => {
        // this.scrollContainer.nativeElement.scrollTop = whatever;

        // Since directly messing with native elements causes virtual dom delays
        // Do it again after delay for completeness
        this.scrollContainer.scroll({
          top: this.scrollContainer.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }, x * 200);
    }
  }

  updateMessage($event) {
    this.messageToSend = $event.target.value;
  }

  sendSMS() {
    if (this.disabled) {
      this.openErrorMessage();
      return;
    }
    this.sending = true;
    // this.showEmojiPicker = false;
    // this.showMMSMediaPicker = false;
    // this.showSMSTemplatePicker = false;

    if (this.assignedNumber) {
      delete this.moObj?.contact?.stage;
      this.smsService
        .sendSMS(
          this.moObj?.contactNumber,
          this.assignedNumber.phoneNumber,
          this.messageToSend,
          this.moObj?.contact,
          null,
          null,
          null,
          this.mmsMediaToSend,
          this.moObj?.reqId,
          null,
          null
        )
        .pipe(take(1))
        .subscribe(
          (provisioned: any) => {
            let result = JSON.parse(provisioned.data.sendSMS);

            //this.messageHistory.push({ id: '5', mType: 1, message: this.messageToSend });
            this.messageHistory.push(result);
            this.messageToSend = '';
            this.mmsMediaToSend = [];

            this.getNewerMessages(null);
            console.log(this.messageHistory);
            this.sending = false;

            if (this.firstSMSMessage) {
              this.dispoSMSInit();
            }
          },
          (error) => {
            console.log('sendSMS: There was an error sending message ', error);
            this.messageToSend = '';
            this.mmsMediaToSend = [];

            this.getNewerMessages(null);
            console.log(this.messageHistory);
            this.sending = false;
          }
        );
    }

    this.scrollToBottom();
  }

  dispoSMSInit() {
    // reset to false if you get here
    this.firstSMSMessage = false;

    let input: HistoryInput = {
      type: ActivityType.SMS,
      note: `SMS - First Message Sent`,
      contactId: this.moObj.contact.id,
      reqId: this.moObj.reqId,
      isPublic: true,
      analytics: JSON.stringify({
        disposition: 'Initial',
        agentPhone: this.assignedNumber.phoneNumber,
        contactPhone: this.moObj?.contactNumber,
        contactName: `${this.moObj.contact?.firstName} ${this.moObj.contact?.lastName}`,
      }),
    };

    this.todoService.addHistory(input).subscribe(
      (result) => {},
      (error) => {
        console.log(error);
      }
    );
  }

  getNewerMessages(size: string) {
    if (!size) size = '5';

    if (this.isLoadingOlderMessages) return;

    // Wait until history loaded at least once
    // if (!this.firstRequest) return;

    this.isLoadingOlderMessages = true;

    this.smsService
      .listMessageConversationalHistory(
        this.moObj?.contactNumber,
        this.assignedNumber.phoneNumber,
        size,
        this.firstRequest,
        '1'
      )
      .pipe(take(1))
      .subscribe(
        (results: any) => {
          let resultsParsed = JSON.parse(results.data.listMessageConversationalHistory);

          if (resultsParsed.length) this.firstRequest = resultsParsed[resultsParsed.length - 1].createdDate;

          // Verfiy messages are not already in local array
          // this.messageHistory.push(...resultsParsed.reverse());
          // resultsParsed = resultsParsed.reverse();
          resultsParsed.forEach((ele) => {
            if (this.messageHistory.findIndex((arr) => arr.id === ele.id) < 0) {
              this.messageHistory.push(ele);
            }
          });

          this.isLoadingOlderMessages = false;

          this.scrollToBottom();
        },
        (error) => {
          console.log('getMoreMessages: There was an error getting messages ', error);
          this.isLoadingOlderMessages = false;
        }
      );
  }

  getLastDisplayedTimeStamp(message) {
    let retVal = '';

    let messageDate = new Date(message.createdDate);

    if (message.createdDate) {
      if (this.lastDisplayedTimeStamp) {
        let lastDisplayDate = new Date(this.lastDisplayedTimeStamp);
        if (differenceInMinutes(lastDisplayDate, messageDate) > 240) {
          retVal = message.createdDate;
          this.lastDisplayedTimeStamp = message.createdDate;
        }
      } else {
        this.lastDisplayedTimeStamp = message.createdDate;
        retVal = message.createdDate;
      }
    }

    return retVal;
  }

  subScribeToMessages() {
    if (this.updatedMessageLogSubscription) {
      this.updatedMessageLogSubscription.unsubscribe();
      this.updatedMessageLogSubscription = null;
    }

    this.updatedMessageLogSubscription = this.smsServiceNeo
      .updatedMessageLog(this.userId, this.moObj?.contactNumber, this.assignedNumber?.phoneNumber)
      .subscribe(
        (result: any) => {
          let resultsParsedData = JSON.parse(result.data.updatedMessageLog.data);

          if (this.messageHistory.findIndex((arr) => arr.id === resultsParsedData.id) < 0) {
            this.messageHistory.push(resultsParsedData);
          }
        },
        (error) => {
          console.log('updatedMessageLog Convo: There was an error sending message ', error);
        }
      );
  }

  loadAssignedNumber() {
    this.integrationsService
      .readIntegrationKey('SMS_TWILIO')
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.assignedNumber = result.data.readIntegrationKey.apiKey;
          console.log('loadAssignedNumber: ', this.assignedNumber);
          if (result.data.readIntegrationKey.apiKey) {
            this.assignedNumber = JSON.parse(result.data.readIntegrationKey.apiKey);
          }

          if (this.assignedNumber) {
            if (this.moObj?.contactNumber && this.assignedNumber?.friendlyName) {
              this.markAsRead(this.assignedNumber?.friendlyName, this.moObj.contactNumber);

              this.getMoreMessages('15', true);

              this.checkIfFirstMessage();
            }

            this.userService
              .getAuthenticatedUserId()
              .pipe(take(1))
              .subscribe(
                (userId: string) => {
                  this.userId = userId;

                  // User Id required for subscription
                  this.subScribeToMessages();

                  this.userService
                    .getUserAvatarUrl(this.userId)
                    .pipe(take(1))
                    .subscribe(
                      ({ data }) => {
                        const url = data.getUserAvatarUrl?.url || data.getContactAvatarUrl?.url;
                        this.userAvatarUrl = url || '';
                        if (url) this.imageCachingService.cacheImageFromUrl(url, this.userId);
                      },
                      (error) => console.log('ContactsTabComponent :: error download user avatar', error)
                    );
                },
                (error) => {
                  console.log('LandingPageComponent: Error getting authenticated userId -> ' + error.message);
                }
              );
          } else {
            console.log(`SMS_TWILIO: Phone number not configured!`);
            this.disabled = true;
            this.openErrorMessage();
          }
        },
        (error) => {
          console.log('ManageBombBombComponent: There was an error reading key ', error);
        }
      );
  }

  autoGrow() {
    const textArea = this.textArea.nativeElement;
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  ngAfterViewInit() {
    this.scrollContainer = this.scrollFrame.nativeElement;
    this.itemElements.changes.subscribe((_) => this.onItemElementsChanged());
  }

  private onItemElementsChanged(): void {
    if (this.isNearBottom) {
      this.scrollToBottom();
    }
  }

  canDeactivate(): Observable<boolean> {
    return of(true);
  }

  ngOnDestroy() {
    if (this.updatedMessageLogSubscription) {
      this.updatedMessageLogSubscription.unsubscribe();
    }
  }

  openErrorMessage() {
    if (this.disabled) {
      this.notificationsService.openSnackBar('Phone number not configured!', {
        duration: 5000,
        verticalPosition: 'bottom',
      });
    }
  }
}
