<h1 mat-dialog-title *ngIf="data.title">{{ data.title }}</h1>
<mat-dialog-content *ngIf="data.message">{{ data.message }}</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" color="{{ data.cancelButton?.color }}">
    {{ data.cancelButton?.label || 'Cancel' }}
  </button>
  <button
    *ngFor="let confirmButton of data.confirmButtons"
    mat-raised-button
    color="{{ confirmButton?.color || 'primary' }}"
    [mat-dialog-close]="confirmButton?.label || true"
  >
    {{ confirmButton?.label || 'Ok' }}
  </button>
</mat-dialog-actions>
<!--  (click)="close(confirmButton)" -->
