import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { TenantService } from '../../services/tenant.service';
import { TenantInput } from '../model/tenant';
import { Address } from '../model/contacts';
import { StripeService } from 'app/services/stripe.service';
import {
  defaultTenantType,
  getTenantTypeDisplayNames,
  getTenantTypeFromDisplayName,
} from '../../../../../app/src/model/tenantTypes';

@Component({
  selector: 'mm-add-tenant-dialog',
  templateUrl: './add-tenant-dialog.component.html',
  styleUrls: ['./add-tenant-dialog.component.scss'],
})
export class AddTenantDialogComponent implements OnInit {
  createTenantForm: FormGroup;
  tenantTypes = getTenantTypeDisplayNames();

  constructor(
    private dialogRef: MatDialogRef<AddTenantDialogComponent>,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private tenantService: TenantService,
    private stripeService: StripeService
  ) {}

  ngOnInit() {
    this.createTenantForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', null],
      line1: ['', null],
      line2: ['', null],
      city: ['', null],
      state: ['', null],
      zip: ['', null],
      stripeSubId: ['', null],
      type: [defaultTenantType.displayName, Validators.required],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public createTenant(): void {
    let tenantType = getTenantTypeFromDisplayName(this.createTenantForm.get('type').value);
    let tenant: TenantInput = {
      title: this.createTenantForm.get('title').value,
      stripeSubId: this.createTenantForm.get('stripeSubId').value,
      type: tenantType?.key ?? defaultTenantType.key,
    };

    if (this.createTenantForm.get('description').value) {
      tenant['description'] = this.createTenantForm.get('description').value;
    }

    if (
      this.createTenantForm.get('line1').value ||
      this.createTenantForm.get('line2').value ||
      this.createTenantForm.get('city').value ||
      this.createTenantForm.get('state').value ||
      this.createTenantForm.get('zip').value
    ) {
      let address: Address = {};

      if (this.createTenantForm.get('line1').value) {
        address['street'] = this.createTenantForm.get('line1').value;
      }

      if (this.createTenantForm.get('line2').value) {
        address['street2'] = this.createTenantForm.get('line2').value;
      }

      if (this.createTenantForm.get('city').value) {
        address['city'] = this.createTenantForm.get('city').value;
      }

      if (this.createTenantForm.get('state').value) {
        address['state'] = this.createTenantForm.get('state').value;
      }

      if (this.createTenantForm.get('zip').value) {
        address['zipCode'] = this.createTenantForm.get('zip').value;
      }

      tenant['address'] = address;
    }

    this.tenantService.addTenant(tenant).subscribe(
      (result) => {
        let tenantResult = result.data.addTenant;
        console.log('AddTenantDialogComponent :: createTenant - Added tenant ', tenant);

        this.snackBar.open('Tenant Created', null, {
          duration: 3000,
        });
        this.closeDialog();
      },
      (error) => {
        console.log('AddTenantDialogComponent :: createTenant - Error adding tenant ', error);
        this.snackBar.open('Error encountered when creating tenant', null, {
          duration: 3000,
        });
        this.closeDialog();
      }
    );
  }
}
