import { Component, Input, Output, OnInit, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { History, ActivityType } from '../model/activity';
import { User } from '../model/user';
import { Requisition, UserReq } from '../model/requisitions';
import { Contact, ContactStage } from '../model/contacts';
import { ActivityReqLabel } from '../pipes/activity.pipe';
import { RequisitionRoutePipe } from '../pipes/requisition.pipe';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmLogActivityDialogComponent, ContactsAddedDialogComponent } from '../dialogs/contacts-added-dialog.component';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { EditNoteDialogComponent } from '../dialogs/edit-note-dialog.component';

import { DisplayHistoryDialogComponent } from '../dialogs/display-history-dialog.component';
import { ActivityFilterDialogComponent } from './../dialogs/activity-filter-dialog.component';
import { Page, ActivityCriteria, FilterResult, FilterData, Option, SortOption } from 'app/shared/model/filter';
import { SatDatepickerRangeValue } from 'saturn-datepicker-ext';
import * as moment from 'moment';
import { DateRange } from '../../shared/model/filter';
import { ContactService } from '../../services/contact.service';
import { ImageCachingService } from '../../services/imagecaching.service';
import { RequisitionService } from '../../services/requisition.service';
import { NotificationsService } from '../../services/notifications.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TodoService } from 'app/services/todo.service';

@Component({
  selector: 'mm-history-table-tab',
  templateUrl: './history-table-tab.component.html',
  styleUrls: ['./history-table-tab.component.scss', '../../nick_styles/nick.css'],
})
export class HistoryTableTabComponent implements OnInit, OnDestroy {
  private _histories = new BehaviorSubject<History[]>([]);
  private _pageHistory = new BehaviorSubject<Page>({});
  private _users: User[] = [];
  private historySub: Subscription;
  historyTableSource: MatTableDataSource<History>;
  @Output() pageChanged: EventEmitter<Page> = new EventEmitter();
  editedHistoryNotes: History[];
  loggedActivities = [{ name: 'Phone Call', key: ActivityType.Call }, { name: 'Meeting', key: ActivityType.Meeting }, { name: 'To-Do', key: ActivityType.Task }, { name: 'Text Message', key: ActivityType.SMS }];
  isReqContactPage = window.location.href.includes('CONTACT$') && window.location.href.includes('REQ$')

  onChange($event: Page) {
    this._pageHistory.next($event);
    this.pageChanged.emit(this._pageHistory.getValue());
  }

  @Input()
  set history(value) {
    this._histories.next(value);
  }

  get history() {
    return this._histories.getValue();
  }

  @Input()
  set pageHistory(value) {
    this._pageHistory.next(value);
  }

  get pageHistory() {
    return this._pageHistory.getValue();
  }

  @Input()
  set users(value) {
    // this._users = value;
    // Hard code to null to disable this feature for now
    // Show all history by default
    this._users = [];
    if (this.historyTableSource) {
      this.historyTableSource.filter = JSON.stringify(this.users);
    }
  }

  get users() {
    return this._users;
  }

  // startDate = new Date(2020, 1, 1);
  startDate = new Date();
  reqOptions: Option[] = [{ value: '', title: 'View All Activity' }];
  @Input() show: boolean;

  @Input() historyFilters: ActivityCriteria;
  @Output() filterApplied: EventEmitter<ActivityCriteria> = new EventEmitter();
  @Input() sortOptionHistory: SortOption;
  @Input() requisition: Requisition;

  private _requisitions: Requisition[] = [];
  @Input()
  set requisitions(value) {
    this._requisitions = value;
  }

  get requisitions() {
    return this._requisitions;
  }

  @Input() displayedHistoryColumns: string[] = [
    'type',
    'title',
    'contacts',
    'completed-by',
    'requisition',
    'stage',
    'completed-start-date',
  ];
  @Input() displayedFilterColumns: string[] = [
    'type-filter',
    'title-filter',
    'contacts-filter',
    'completed-by-filter',
    'requisition-filter',
    'stage-filter',
    'completed-start-date-filter',
  ];

  @Output() reqUserRemoved = new EventEmitter<User>();

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  readonly typeDropOptions = [
    {
      label: '- None -',
      value: '',
    },
    {
      label: ActivityType.Call,
      value: ActivityType.Call,
    },
    {
      label: ActivityType.Meeting,
      value: ActivityType.Meeting,
    },
    {
      label: ActivityType.Task,
      value: ActivityType.Task,
    },
    {
      label: ActivityType.Other,
      value: ActivityType.Other,
    },
    {
      label: ActivityType.document,
      value: ActivityType.document,
    },
    {
      label: ActivityType.Bonzo,
      value: ActivityType.Bonzo,
    },
    {
      label: ActivityType.Email,
      value: ActivityType.Email,
    },
    {
      label: ActivityType.Assigned,
      value: ActivityType.Assigned,
    },
    {
      label: ActivityType.Pipeline,
      value: ActivityType.Pipeline,
    },
    {
      label: ActivityType.SMS,
      value: ActivityType.SMS,
    },
    {
      label: ActivityType.Note,
      value: ActivityType.Note,
    },
  ];

  readonly stageOptions = [
    {
      label: '- None -',
      value: '',
    },
    {
      label: ContactStage.PROSPECTS,
      value: ContactStage.PROSPECTS,
    },
    {
      label: ContactStage.CANDIDATES,
      value: ContactStage.CANDIDATES,
    },
    {
      label: ContactStage.MEETINGS,
      value: ContactStage.MEETINGS,
    },
    {
      label: ContactStage.OFFERS,
      value: ContactStage.OFFERS,
    },
    {
      label: ContactStage.HIRES,
      value: ContactStage.HIRES,
    },
  ];

  activeFilters: ActivityCriteria = {};
  removable = true;

  private dateRange$: BehaviorSubject<DateRange> = new BehaviorSubject({
    startDate: null,
    endDate: null,
  });

  avatarUrls: any = {};
  allReqs: UserReq[] = [];

  viewTodo: boolean = false;

  contactId: string;
  reqId: string;
  queryParams;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private contactService: ContactService,
    private imageCachingService: ImageCachingService,
    private reqService: RequisitionService,
    private notificationsService: NotificationsService,
    private snackBar: MatSnackBar,
    private todoService: TodoService
  ) {}

  ngOnInit() {
    let oldReqHistoryFilterChoice = localStorage.getItem('reqHistoryFilterChoice');
    if (oldReqHistoryFilterChoice) {
      this.activeFilters = {
        requisition: '',
      };
      this.filterApplied.emit(this.activeFilters);
    }
    this.historyTableSource = new MatTableDataSource<History>([]);
    this.historyTableSource.sort = this.sort;
    this.historyTableSource.filterPredicate = (data: History, filter: string) => this.doFilter(data);
    this.reqService
      .getMyReqs()
      .pipe(take(1))
      .subscribe(({ data: { getMyReqs } }) => {
        this.allReqs = getMyReqs.map(({ reqId, id, ...a }) => {
          this.reqOptions.push({ value: reqId, title: a.title });
          return { ...a, id: reqId };
        });
      });
    // custom sorting
    this.historyTableSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'complete':
          return '';
        // case 'due-date': return new Date(item.dueOn);
        case 'completed-start-date':
          return new Date(item.completedDate);
        case 'completed-end-date':
          return '';
        case 'contacts':
          return '';
        case 'completed-by':
          return item.completedBy.firstName + ' ' + item.completedBy.lastName;
        case 'requisition':
          return new ActivityReqLabel().transform(item.reqId, this.requisitions);
        default:
          return item[property];
      }
    };

    this.historySub = this._histories.subscribe((arrHistory) => {
      this.historyTableSource.data = arrHistory
        ? arrHistory.filter((a) => a.type !== 'MENTION' && a.type !== 'EDITEDNOTE')
        : [];
      console.log('historyTableSource', this.historyTableSource.data);
      this.getAvatarUrls();
      this.getEditedNotesHistory();
    });

    combineLatest([this.route.params, this.route.queryParams])
      .pipe(map((results) => ({ params: results[0], queryParams: results[1] })))
      .subscribe(({ params, queryParams }) => {
        this.contactId = params['contactId'];
        this.reqId = params['reqId'];
        this.queryParams = { queryParams };
      });
  }

  getAvatarUrls() {
    this.historyTableSource.data.forEach((history) => {
      history.contacts.slice(0, 3).forEach((contact) => {
        if (!this.avatarUrls[contact.id]) {
          this.contactService
            .getContactAvatarUrl(contact.id)
            .pipe(take(1))
            .subscribe(
              ({ data }) => {
                const url = data.getContactAvatarUrl.url;
                this.avatarUrls[contact.id] = data.getContactAvatarUrl?.url || '';
                if (url) this.imageCachingService.cacheImageFromUrl(url, contact.id);
              },
              (error: unknown) => console.log(error)
            );
        }
      });
    });
  }

  logActivity(activityKey: string) {
    
    if (!this.reqId || !this.contactId || !activityKey) return

    const dialogRef = this.dialog.open(ConfirmLogActivityDialogComponent, {
      data: {
        contactId: this.contactId,
        reqId: this.reqId,
        activityKey
      },
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(status => {
      if (status === 'updated') {
        setTimeout(() => {
          // TODO: refresh history table when activity manually logged

        }, 600);
      }
    })
  }

  async applyFilter(field: string, filterValue: any) {
    if (!filterValue) {
      this.clearFilter(field);
    }

    if (field === 'dueDateStart') {
      this.activeFilters.dueDateStart = filterValue.toISOString();
      const endDate = new Date(filterValue);
      endDate.setDate(endDate.getDate() + 1);
      this.activeFilters.dueDateEnd = endDate.toISOString();
    } else if (field === 'compeletedDateStart') {
      this.activeFilters.compeletedDateStart = filterValue.toISOString();
      if (!this.activeFilters.compeletedDateEnd) {
        const endDate = new Date(filterValue);
        endDate.setDate(endDate.getDate() + 1);
        this.activeFilters.compeletedDateEnd = endDate.toISOString();
      }
    } else if (field === 'compeletedDateEnd') {
      this.activeFilters.compeletedDateEnd = filterValue.toISOString();
      if (!this.activeFilters.compeletedDateStart) {
        const startDate = new Date(filterValue);
        startDate.setDate(startDate.getDate() + 1);
        this.activeFilters.compeletedDateStart = startDate.toISOString();
      }
    } else if (field === 'title') {
      // Let the backend be responsible for trim - UI messes up when user is searching title with multiple words
    } else if (field === 'requisition') {
      this.activeFilters.requisition = filterValue.trim();
      if (typeof filterValue.trim() === 'string' && !filterValue.trim())
        localStorage.setItem('reqHistoryFilterChoice', 'all');
      else localStorage.setItem('reqHistoryFilterChoice', 'specific');
      console.log(localStorage.getItem('reqHistoryFilterChoice'));
    } else {
      this.activeFilters[field] = filterValue.trim();
    }
    // this.historyTableSource.filter = JSON.stringify(this.activeFilters);
    console.log('filterResults:', this.activeFilters);
    this.filterApplied.emit(this.activeFilters);
  }

  clearDate(field: string) {
    if (field === 'compeletedDateStart') {
      this.clearFilter('compeletedDateEnd');
      this.clearFilter('compeletedDateStart');
    } else if (field === 'compeletedDateEnd') {
      this.clearFilter('compeletedDateEnd');
      this.clearFilter('compeletedDateStart');
    }
    this.filterApplied.emit(this.activeFilters);
  }

  clearFilter(field: string) {
    delete this.activeFilters[field];
  }

  sortData($event) {
    this.sortOptionHistory.field = this.sort.active;
    this.sortOptionHistory.direction = this.sort.direction;
    this.filterApplied.emit(this.activeFilters);
  }

  removeUser(removedUser: User) {
    this.reqUserRemoved.emit(removedUser);
  }

  doFilter(data: History): boolean {
    return (
      this.matchType(data) &&
      this.matchTitle(data) &&
      this.matchContacts(data) &&
      this.matchReq(data) &&
      this.matchUsers(data) &&
      this.matchCompletedBy(data) &&
      this.matchDate(data, 'dueOn') &&
      this.matchDate(data, 'completedDate')
    );
  }

  matchType(data: History): boolean {
    if (!this.activeFilters['type']) {
      return true;
    }

    return this.activeFilters['type'].toLowerCase() == data.type.toLowerCase();
  }

  matchTitle(data: History): boolean {
    if (!this.activeFilters['title']) {
      return true;
    }

    return data.title.trim().toLowerCase().includes(this.activeFilters['title'].trim().toLowerCase());
  }

  matchContacts(data: History): boolean {
    if (!this.activeFilters['contacts']) {
      return true;
    }

    let names = [];

    if (data.contacts) {
      data.contacts.forEach((contact) => {
        names.push(contact.firstName);
        names.push(contact.lastName);
      });
    }

    let namesString = names.join(' ').toLowerCase();
    return namesString.includes(this.activeFilters['contacts'].toLowerCase());
  }

  matchUsers(data: History) {
    if (this.users.length == 0) {
      return true;
    }
    let dataUsers = [];

    if (data.users) {
      for (let user of data.users) {
        dataUsers.push(user.id);
      }
    }
    let usersString = dataUsers.join(' ');

    if (this.users.filter((user) => usersString.includes(user.id)).length != 0) {
      return true;
    }

    return false;
  }

  matchCompletedBy(data: History): boolean {
    if (!this.activeFilters['completedBy']) {
      return true;
    }

    let name = data.completedBy.firstName.trim() + ' ' + data.completedBy.lastName.trim();
    return name.toLowerCase().includes(this.activeFilters['completedBy'].toLowerCase());
  }

  matchReq(data: History): boolean {
    console.log('matchReq', data);
    if (!this.activeFilters['requisition']) {
      return true;
    }

    let requistionName = new ActivityReqLabel().transform(data.reqId, this.requisitions);
    console.log(requistionName);
    return requistionName.toLowerCase().includes(this.activeFilters['requisition'].toLowerCase());
  }

  matchDate(data: History, field: string): boolean {
    if (!this.activeFilters[field]) {
      return true;
    }

    let dueDateObj = new Date(data[field]);
    let dueDateString = dueDateObj
      .toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      })
      .split(' ')
      .join('/');

    return dueDateString.startsWith(this.activeFilters[field].toLowerCase());
  }

  onContactRowClick(row: any): void {
    const dialogRef = this.dialog.open(ContactsAddedDialogComponent, {
      data: {
        contacts: row.contacts,
        requisition: row.requisition,
      },
      panelClass: 'contacts-added-dialog',
      width: '75vw',
    });
  }

  onHistoryClick(history: any): void {
    if (history.type !== ActivityType.Note) this.todoService.todo = history;
    if (history.type === ActivityType.Meeting || history.type === ActivityType.Call) {
      // Route to meeting
      this.openMeeting();
    } else if (
      history.type === ActivityType.Task ||
      history.type === ActivityType.Email ||
      history.type === ActivityType.SMS
    ) {
      this.viewTodo = true;
    } else if (history.type === ActivityType.Note) {
      this.onEditNoteClick(history);
    }
  }

  openMeeting() {
    if (this.reqId && this.contactId) {
      this.router.navigate(['contacts', this.contactId, this.reqId, 'meeting']);
    } else if (this.reqId) {
      this.router.navigate(['requisitions', this.reqId, 'meeting']);
    } else if (this.contactId) {
      this.router.navigate(['contacts', this.contactId, 'meeting']);
    } else {
      this.router.navigate(['activity/meeting']);
    }
  }

  onContactClick({ firstName, lastName, id }: Contact, { reqId }: History): void {
    console.log('onContactClick', firstName, lastName, id, reqId);
    this.contactService
      .getContactActive(id)
      .pipe(take(1))
      .subscribe((result) => {
        let active = result.data.getContactActive.active;

        if (active) {
          var link = window.location.origin + '/#/';
          if (reqId) {
            // Navigate to req-single-contact
            link = link + `contacts/${id}/${reqId}?filters=${JSON.stringify(this.activeFilters)}`;
            let encodedLink = encodeURI(link);
            window.open(encodedLink, '_blank');
          } else {
            link = link + `contacts/${id}`;
            window.open(link, '_blank');
          }
        } else {
          let snackBarRef = this.snackBar.open(
            `${firstName} ${lastName} is deleted. Would you like to restore?`,
            'Restore'
          );
          snackBarRef.onAction().subscribe(() => {
            console.log('Restoring contact.');
            this.contactService
              .disableContact(id, true)
              .pipe(take(1))
              .subscribe((result) => {
                console.log(result);
                this.snackBar.open('Contact restored.', 'Dismiss');
              });
          });
        }
      });
  }

  onRequisitionClick(history: History): void {
    const { reqId } = history;
    const reqTitle = new ActivityReqLabel().transform(reqId, this.requisitions);
    const route = new RequisitionRoutePipe().transform(reqTitle);
    this.router.navigate(['requisitions', reqId]);
  }

  getStage(history: History) {
    if (history.contacts.length > 0) {
      if (this.activeFilters['stage']) {
        let filtered = history.contacts.filter((contact) => contact.stage === this.activeFilters['stage']);
        return filtered[0] != null ? filtered[0].stage : '';
      } else {
        return history.contacts[0].stage;
      }
    } else {
      return '';
    }
  }

  getEditedNotesHistory() {
    if (this.pageHistory.page) {
      const page = {
        page: this.pageHistory.page,
        pageSize: 100,
      };

      const noteFilter = {
        activity: 'all',
        requisition: this.historyFilters.requisition,
        type: 'EDITEDNOTE',
      };

      const allEditedNotes = this.todoService
        .filterAllActivityHistories(page, noteFilter, this.sortOptionHistory)
        .subscribe(
          (result) => {
            // console.log(result);
            this.editedHistoryNotes = result.data.filterAllActivityHistory.histories as History[];
            allEditedNotes.unsubscribe();
          },
          (error) => {
            console.log('historyTableTab error getting edited notes', error);
            allEditedNotes.unsubscribe();
          }
        );
    }
  }

  onEditNoteClick(history: History) {
    const editMatch = this.editedHistoryNotes.filter((match) => match.id === history.previousEdit);
    if (editMatch.length > 0) {
      const previousEdits = editMatch.slice(0, 4).map((match) => match.previousEdit);
      const matchingEdits = this.editedHistoryNotes.filter((match) => previousEdits.includes(match.id));
      editMatch.push(...matchingEdits);
    }
    const contactId = history.contacts[0]?.id;
    let dialogRef = this.dialog.open(EditNoteDialogComponent, {
      width: '30%',
      data: {
        description: history.description,
        user: history.completedBy,
        completedDate: history.completedDate,
        historyId: history.id,
        title: history.title,
        reqId: history.reqId,
        isPublic: history.isPublic,
        contactId: contactId,
        editMatch: editMatch,
        history: history,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        //console.log(result);
      });
  }

  onFilterClick() {
    const activityTypes = [];
    this.typeDropOptions.forEach((type) => activityTypes.push({ value: type.value, title: type.label }));
    const stageTypes = [];
    this.stageOptions.forEach((type) => stageTypes.push({ value: type.value, title: type.label }));
    const data: FilterData = {
      requisitions: this.requisitions,
      activityTypes: activityTypes,
      stageTypes: stageTypes,
      filterCriteria: this.historyFilters,
      isHistory: true,
      isSideBar: false,
      isFromRequisition: false,
    };
    const dialogRef = this.dialog.open(ActivityFilterDialogComponent, {
      panelClass: 'filter-dialog',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result: FilterResult) => {
      console.log('filterResults:', result);
      if (
        result != null &&
        result.filterAction != null &&
        result.filterAction != undefined &&
        result.filterAction.toLocaleLowerCase() === 'apply'
      ) {
        this.activeFilters = {};
        this.historyFilters = result.filterCriteria;
        if (
          result.filterCriteria.compeletedDateStart !== null &&
          result.filterCriteria.compeletedDateEnd !== null &&
          result.filterCriteria.compeletedDateStart !== undefined &&
          result.filterCriteria.compeletedDateEnd !== undefined &&
          result.filterCriteria.compeletedDateStart === result.filterCriteria.compeletedDateEnd
        ) {
          const endDate = new Date(result.filterCriteria.compeletedDateStart);
          endDate.setDate(endDate.getDate() + 1);
          result.filterCriteria.compeletedDateEnd = endDate.toISOString();
        }
        this.activeFilters = result.filterCriteria;
        this.filterApplied.emit(result.filterCriteria);
      }
    });
  }

  onDateChange(dateRange: SatDatepickerRangeValue<Date>) {
    const startDate = dateRange ? moment(dateRange.begin).startOf('day').toISOString() : null;

    const endDate = dateRange ? moment(dateRange.end).endOf('day').toISOString() : null;

    this.activeFilters.compeletedDateEnd = endDate;
    this.activeFilters.compeletedDateStart = startDate;

    if (!endDate) this.clearFilter('compeletedDateEnd');
    if (!startDate) this.clearFilter('compeletedDateStart');

    console.log('filterResults:', this.activeFilters);
    this.filterApplied.emit(this.activeFilters);

    this.dateRange$.next({ startDate, endDate });
  }

  closeTodo(closed) {
    this.viewTodo = closed;
  }

  ngOnDestroy() {
    this.historySub.unsubscribe();
  }
}
