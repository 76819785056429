import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  InjectionToken,
  Injector,
  Input,
  NgModule,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ConnectedPosition, Overlay, OverlayModule, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { animate } from 'motion';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

export type TooltipData = {
  data: string | TemplateRef<void>;
  animationEnded$: Observable<boolean>;
};
export const TOOLTIP_DATA = new InjectionToken<TooltipData>('Data to display in tooltip');

@Component({
  selector: 'app-tooltip-container',
  template: `
    <ng-container *ngIf="asString as string">
      <span>{{ string }}</span>
    </ng-container>

    <ng-container *ngIf="asTemplate as template">
      <ng-template *ngTemplateOutlet="template; context: { $implicit: tooltipData.animationEnded$ }"></ng-template>
    </ng-container>
  `,
  styleUrls: ['../nick_styles/nick.css'],
  styles: [
    `
      :host {
        @apply bg-slate-900 shadow-sm rounded-md px-2 py-1 text-xs text-white;
      }

      :host-context(.top) {
        margin-bottom: 0.5rem;
      }

      :host-context(.bottom) {
        margin-top: 0.5rem;
      }

      :host-context(.tooltip-backdrop) {
        height: 0;
        width: 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipContainerComponent {
  @Output() closed = new EventEmitter<void>();

  get asString(): string | false {
    return typeof this.tooltipData.data === 'string' ? this.tooltipData.data : false;
  }

  get asTemplate(): TemplateRef<void> | false {
    return this.tooltipData.data instanceof TemplateRef ? this.tooltipData.data : false;
  }

  constructor(
    @Inject(TOOLTIP_DATA) public tooltipData: TooltipData,
    public cdr: ChangeDetectorRef
  ) {}

  @HostListener('mouseenter')
  @HostListener('focus')
  showTooltip(): void {
    // console.log('showTooltip');
  }

  @HostListener('mouseleave', ['$event.toElement'])
  @HostListener('blur', ['$event.toElement'])
  hideTooltip(): void {
    this.closed.emit();
  }
}

@Directive({
  selector: '[mmTooltip]',
})
export class ModelMatchTooltipDirective implements OnInit, OnDestroy, OnChanges {
  @Input() mmTooltip: string | TemplateRef<HTMLElement> | boolean | null;
  @Input() interactive: boolean = false;
  @Input() minTooltipLength: number;
  @Input() delay: number = 300;
  @Input() showOnTruncate: boolean = false; // Add this input property
  private overlayRef: OverlayRef | null = null;
  private overlayRefSubscription: Subscription = null;
  private resizeObserver: ResizeObserver;
  private isTextTruncated: boolean = false;
  @Input() mmTooltipShowOnHoverAndFocus: boolean = true;

  @Input() mmTooltipId: string = 'mm-tooltip';

  @Input() mmTooltipPositions: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      panelClass: 'top',
    },
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      panelClass: 'bottom',
    },
  ];

  private componentRef: ComponentRef<TooltipContainerComponent> | null = null;
  private timeoutRef: NodeJS.Timeout = undefined;

  private _animationEnded = new BehaviorSubject<boolean>(false);
  public animationEnded$ = this._animationEnded.asObservable();

  private isTooltipVisible: boolean = false;

  constructor(
    private element: ElementRef<HTMLElement>,
    private overlay: Overlay,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    if (this.showOnTruncate) {
      // Initialize the observer only if showOnTruncate is true
      this.resizeObserver = new ResizeObserver(() => this.checkTruncation());
      this.resizeObserver.observe(this.element.nativeElement);
    }
  }

  ngOnChanges(): void {
    // if (this.overlayRef?.hasAttached() === true) {
    //   this.componentRef?.instance.cdr.detectChanges();
    //   this.cdr.detectChanges();
    // }
  }

  @HostListener('mouseenter')
  @HostListener('focus')
  _showTooltip(): void {
    if (this.mmTooltipShowOnHoverAndFocus) {
      this.showTooltip();
    }
  }

  showTooltip(): void {
    if (this.isTooltipVisible) {
      return;
    }

    this.isTooltipVisible = true;

    // console.log('showTooltip', this.overlayRef?.hasAttached());
    if (this.overlayRef?.hasAttached() === true) {
      return;
    }

    if (!this.element) {
      return;
    }

    // If showOnTruncate is true and the text is truncated, set mmTooltip to the element's text
    if (this.showOnTruncate && this.isTextTruncated) {
      this.mmTooltip = this.element.nativeElement.textContent;
    }

    if (this.mmTooltip && (this.showOnTruncate === false || this.isTextTruncated === true)) {
      if (this.overlayRef?.hasAttached() === true) {
        this.overlayRef?.detach();
      }
      this.timeoutRef = setTimeout(() => {
        this.attachTooltip();
        if (this.overlayRef?.overlayElement.childNodes.length > 0) {
          (this.overlayRef?.overlayElement.childNodes[0] as HTMLElement)?.classList?.add(
            this.mmTooltipPositions[0].originX === 'center'
              ? 'origin-center'
              : this.mmTooltipPositions[0].originX === 'start'
              ? 'origin-bottom-left'
              : 'origin-bottom-right'
          );
          animate(
            this.overlayRef?.overlayElement.childNodes[0] as HTMLElement,
            { scale: [0.85, 1], opacity: [0.5, 1], y: [2, 0] },
            { duration: 0.075, easing: 'ease-in' }
          ).finished.then(() => {
            this._animationEnded.next(true);
          });
        }
        this.cdr.detectChanges();
        this.componentRef?.instance.cdr.detectChanges();
      }, this.delay);
    }
  }

  @HostListener('mouseleave', ['$event.toElement'])
  @HostListener('blur', ['$event.toElement'])
  @HostListener('click', ['$event.toElement'])
  _hideTooltip(target): void {
    if (this.mmTooltipShowOnHoverAndFocus) {
      this.hideTooltip(target);
    }
  }

  hideTooltip(target?: HTMLElement): void {
    if (!this.isTooltipVisible) {
      return;
    }

    this.isTooltipVisible = false;

    clearTimeout(this.timeoutRef);
    switch (this.interactive) {
      case true:
        if (this.overlayRef && !this.overlayRef.overlayElement.contains(target)) {
          this.overlayRef.detach();
          this._animationEnded.next(false);
        }
        break;
      case false:
        if (this.overlayRef?.hasAttached() === true) {
          this.overlayRef?.detach();
          this._animationEnded.next(false);
        }
        break;
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutRef);
    this.overlayRef?.dispose();
    this.overlayRefSubscription?.unsubscribe();
  }

  private attachTooltip(): void {
    if (!this.minTooltipLength || this.mmTooltip.toString().length >= this.minTooltipLength) {
      if (this.overlayRef === null) {
        const positionStrategy = this.getPositionStrategy();
        this.overlayRef = this.overlay.create({
          positionStrategy,
          hasBackdrop: false,
        });
        this.overlayRefSubscription?.unsubscribe();
        this.overlayRefSubscription = this.overlayRef.detachments().subscribe((_) => this.hideTooltip(null));
      }

      const injector = Injector.create({
        providers: [
          {
            provide: TOOLTIP_DATA,
            useValue: {
              data: this.mmTooltip,
              animationEnded$: this.animationEnded$,
            },
          },
        ],
      });
      // add the animationEnded observable to the injector
      const component = new ComponentPortal(TooltipContainerComponent, this.viewContainer, injector);
      this.componentRef = this.overlayRef.attach(component);
      this.componentRef.instance.closed
        .pipe(takeUntil(this.overlayRef.detachments()))
        .subscribe(() => this.overlayRef?.detach());
    }
  }

  private getPositionStrategy(): PositionStrategy {
    return this.overlay.position().flexibleConnectedTo(this.element).withPositions(this.mmTooltipPositions);
  }

  private checkTruncation(): void {
    this.isTextTruncated = this.element.nativeElement.offsetWidth < this.element.nativeElement.scrollWidth;
  }
}

@NgModule({
  declarations: [ModelMatchTooltipDirective, TooltipContainerComponent],
  exports: [ModelMatchTooltipDirective],
  imports: [OverlayModule, CommonModule],
})
export class ModelMatchTooltipModule {}
