import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SequenceTemplate } from '../../../shared/model/sms';
import { SMSServiceNeo } from '../../../services/sms.appsync.service';
import { map, startWith, take } from 'rxjs/operators';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AlertDialogComponent, AlertDialogConfig } from '../../../shared/dialogs/alert-dialog.component';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'mm-sequence-picker',
  templateUrl: './sequence-picker.component.html',
  styleUrls: ['./sequence-picker.component.scss'],
})
export class SequencePickerComponent {
  menuItems: SequenceTemplate[] = [];
  searchSeqsControl = new FormControl();
  filteredMenuItems: Observable<SequenceTemplate[]>;
  systemTemplatesFilter: boolean = false;
  tenantTemplatesFilter: boolean = false;
  userTemplatesFilter: boolean = false;
  mmAdmin: boolean = false;
  tenantAdmin: boolean = false;
  tenantLeader: boolean = false;
  dataLoaded: boolean = false;

  @ViewChild('searchObjListInput') searchObjListInput: ElementRef<HTMLInputElement>;

  constructor(
    private dialogRef: MatDialogRef<SequencePickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private confirmDialog: MatDialog,
    private userService: UserService,
    private smsServiceNeo: SMSServiceNeo
  ) {}

  ngOnInit() {
    this.menuItems = this.data.sequenceTemplates ? this.data.sequenceTemplates : [];

    console.log('this.menuItems', this.menuItems);

    if (this.menuItems.length === 0 || !this.menuItems) {
      this.loadSequences();
    } else {
      this.filteredMenuItems = this.searchSeqsControl.valueChanges.pipe(
        startWith<string | SequenceTemplate>(''),
        map((value) => {
          // console.log("filteredMenuItems map value ", value);
          return value;
        }),
        // If name is "" or undefined - then return shallow copy of entire array
        map((title: string) => {
          let retVal = this._filterMenuItems(title);

          // let retVal = title ? this._filterMenuItems(title) : this.menuItems.slice();
          // console.log("retVal map value ", title, retVal, this.reqs);
          return retVal;
        })
      );

      this.dataLoaded = true;
    }

    //// console.log('SequencePickerComponent this.menuItems', this.menuItems);

    this.userService
      .getAuthenticatedUserGroups()
      .pipe(
        take(1)
        // map(({ data }) => data.getTenant.user.toggleActive = )
      )
      .subscribe(
        (result) => {
          this.mmAdmin = result.includes('mmAdmin');
          this.tenantAdmin = result.includes('tenantAdmin');
          this.tenantLeader = result.includes('tenantLeader');
        },
        (error) => {
          console.log('getAuthenticatedUserGroups: There was an error loading tenants ', error);
        }
      );
  }

  // Method to filter users list based on what has been typed so far in search box
  private _filterMenuItems(title: string): SequenceTemplate[] {
    const filterValue = title?.toLowerCase();

    let menuItemsBuff = JSON.parse(JSON.stringify(this.menuItems));

    return menuItemsBuff.filter((currObj) => {
      // Combine text search as well as systemTarget from pKey
      // everything by default
      let searchFilter = true;
      let systemFilter = true;
      let userFilter = true;

      // Then progressively filter down
      if (title)
        searchFilter =
          currObj?.title?.toLowerCase().indexOf(filterValue) >= 0 ||
          currObj?.desc?.toLowerCase().indexOf(filterValue) >= 0;

      if (this.systemTemplatesFilter) systemFilter = currObj.pKey === 'SYSTEM';

      if (this.userTemplatesFilter) userFilter = currObj.pKey.includes('USER$');

      // console.log(
      //   '_filterMenuItems running this.systemTemplatesFilter systemFilter',
      //   this.systemTemplatesFilter,
      //   systemFilter
      // );

      // Combine all filter checks
      return searchFilter && systemFilter && userFilter;
    });
  }

  clearAll() {
    let data = { SequenceTemplate: null, clear: true };

    this.dialogRef.close({ data: data });
  }

  onClickAdd(sequenceTemplate: SequenceTemplate) {
    // Pass back new user to assign todos data
    let data = { sequenceTemplate: sequenceTemplate };

    this.dialogRef.close({ data: data });
  }

  onCloseClick() {
    // Pass back new user to assign todos data
    let data = { sequenceTemplate: null };

    this.dialogRef.close({ data: data });
  }

  showSystemTemplates() {
    // console.log('showSystemTemplates');

    this.systemTemplatesFilter = true;
    this.tenantTemplatesFilter = false;
    this.userTemplatesFilter = false;

    // Trigger re-filter on new array of items based on current filters
    this.searchSeqsControl.setValue(this.searchSeqsControl.value, { emitEvent: true });
  }

  showTenantTemplates() {
    this.systemTemplatesFilter = false;
    this.tenantTemplatesFilter = true;
    this.userTemplatesFilter = false;

    // Trigger re-filter on new array of items based on current filters
    this.searchSeqsControl.setValue(this.searchSeqsControl.value, { emitEvent: true });
  }

  showUserTemplates() {
    this.systemTemplatesFilter = false;
    this.tenantTemplatesFilter = false;
    this.userTemplatesFilter = true;

    // Trigger re-filter on new array of items based on current filters
    this.searchSeqsControl.setValue(this.searchSeqsControl.value, { emitEvent: true });
  }

  showAllTemplates() {
    this.systemTemplatesFilter = false;
    this.tenantTemplatesFilter = false;
    this.userTemplatesFilter = false;

    // Trigger re-filter on new array of items based on current filters
    this.searchSeqsControl.setValue(this.searchSeqsControl.value, { emitEvent: true });
  }

  onOptions(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDeleteTemplate(event: any, mItem: SequenceTemplate) {
    event.preventDefault();
    event.stopPropagation();

    let systemTarget = '';

    if (mItem.pKey === 'SYSTEM') systemTarget = 'SYSTEM';

    // ***TODO Add Are you sure confirmation

    this.confirmDiscardTemplate().subscribe(
      (discardTemplateConfirmed) => {
        if (discardTemplateConfirmed) {
          this.smsServiceNeo.logicalDeleteSequence(mItem.id, null, systemTarget).then(
            (result) => {
              // console.log("onDeleteTemplate :: result ", result);

              // Remove menu item by Id from main list
              let index = this.menuItems.findIndex((item) => item.id === mItem.id);
              this.menuItems.splice(index, 1);

              // Trigger re-filter on new array of items based on current filters
              this.searchSeqsControl.setValue(this.searchSeqsControl.value, { emitEvent: true });
            },
            (error) => {
              console.log('loadSequenceTemplates User :: Error  ', error);
            }
          );
        }
      },
      (error) => {
        console.log('confirmDiscardTemplate error: ', error);
      }
    );
  }

  saveSys(event: any, mItem: SequenceTemplate) {
    // console.log('saveSys', mItem);

    // this.savingSequenceTemplate = true;

    let sequenceTemplateBuff = JSON.parse(JSON.stringify(mItem));

    sequenceTemplateBuff.id = '';

    sequenceTemplateBuff.objects.forEach((sequenceObj) => {
      // Clean up anything you want removed.
    });

    // Remove global filters
    console.log('sequenceTemplateBuff', sequenceTemplateBuff);
    console.log('sequenceTemplateBuff.sequenceTemplateBuff.sequenceTemplateSettings', sequenceTemplateBuff);

    // since it is save as new - remove the current .id
    sequenceTemplateBuff.id = '';

    this.smsServiceNeo.createSequence(sequenceTemplateBuff, null, 'SYSTEM').then(
      (result) => {
        // console.log("getReportingCounts User :: result ", result);

        // Create new needs to return new template so we can get Id

        // this.savingSequenceTemplate = false;

        // Reload remote templates on every change so picker keeps up to date
        // Do reload instead of local update to help keep in sync with other possible remote changes

        this.menuItems.push(JSON.parse(result.data.createSequence));

        // Trigger re-filter on new array of items based on current filters
        this.searchSeqsControl.setValue(this.searchSeqsControl.value, { emitEvent: true });
      },
      (error) => {
        console.log('saveSequenceTemplate System :: Error', error);

        // this.savingSequenceTemplate = false;
      }
    );
  }

  saveTenant(event: any, mItem: SequenceTemplate) {
    // console.log('saveSys', mItem);

    // this.savingSequenceTemplate = true;

    let sequenceTemplateBuff = JSON.parse(JSON.stringify(mItem));

    sequenceTemplateBuff.id = '';

    sequenceTemplateBuff.objects.forEach((sequenceObj) => {
      // Clean up anything you want removed.
    });

    // Remove global filters
    console.log('sequenceTemplateBuff', sequenceTemplateBuff);
    console.log('sequenceTemplateBuff.sequenceTemplateBuff.sequenceTemplateSettings', sequenceTemplateBuff);

    // since it is save as new - remove the current .id
    sequenceTemplateBuff.id = '';

    this.smsServiceNeo.createSequence(sequenceTemplateBuff, null, 'TENANT').then(
      (result) => {
        // console.log("getReportingCounts User :: result ", result);

        // Create new needs to return new template so we can get Id

        // this.savingSequenceTemplate = false;

        // Reload remote templates on every change so picker keeps up to date
        // Do reload instead of local update to help keep in sync with other possible remote changes

        this.menuItems.push(JSON.parse(result.data.createSequence));

        // Trigger re-filter on new array of items based on current filters
        this.searchSeqsControl.setValue(this.searchSeqsControl.value, { emitEvent: true });
      },
      (error) => {
        console.log('saveSequenceTemplate System :: Error', error);

        // this.savingSequenceTemplate = false;
      }
    );
  }

  confirmDiscardTemplate(): Observable<boolean> {
    const dialogConfig: AlertDialogConfig = {
      title: 'Delete this template?',
      message: 'Warning: This template will no longer be available!',
      confirmButton: {
        label: 'Delete, yes?',
        color: 'warn',
      },
    };

    const dialogRef = this.confirmDialog.open<AlertDialogComponent, AlertDialogConfig, boolean>(AlertDialogComponent, {
      panelClass: 'alert-dialog-component',
      data: dialogConfig,
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  permissionDelete(mItem: SequenceTemplate) {
    if (mItem.pKey === 'SYSTEM' && this.mmAdmin) return true;

    if (mItem.pKey === 'TENANT' && this.tenantAdmin) return true;

    if (mItem.pKey === 'TENANT' && this.tenantLeader) return true;

    if (mItem.pKey.includes('USER$')) return true;

    return false;
  }

  permissionSaveSystem(mItem: SequenceTemplate) {
    // Can only save user templates to system AND must be a mmAdmin
    if (mItem.pKey.includes('USER$') && this.mmAdmin) return true;

    return false;
  }

  permissionSaveTenant(mItem: SequenceTemplate) {
    // Can only save user templates to system AND must be a mmAdmin
    if (mItem.pKey.includes('USER$') && this.tenantAdmin) return true;

    return false;
  }

  loadSequences() {
    this.smsServiceNeo
      .listSequences(JSON.stringify({ active: true }), '')
      .then((result: any) => {
        this.menuItems = JSON.parse(result.data.listSequences);

        this.filteredMenuItems = this.searchSeqsControl.valueChanges.pipe(
          startWith<string | SequenceTemplate>(''),
          map((value) => {
            // console.log("filteredMenuItems map value ", value);
            return value;
          }),
          // If name is "" or undefined - then return shallow copy of entire array
          map((title: string) => {
            let retVal = this._filterMenuItems(title);

            // let retVal = title ? this._filterMenuItems(title) : this.menuItems.slice();
            // console.log("retVal map value ", title, retVal, this.reqs);
            return retVal;
          })
        );

        this.dataLoaded = true;
      })
      .catch((error) => {
        console.log(' listSequences error -> ', error);
        this.dataLoaded = true;
      });
  }
}
