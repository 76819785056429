import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModelMatchTooltipModule } from 'app/components/betterTooltip.component';
import { ModelMatchButtonModule } from 'app/components/buttons.component';
import { CheckboxModule } from 'app/components/checkbox.component';
import { SpinnerModule } from 'app/components/spinner.component';
import { ContactAddToReqPopoverComponent } from './addtoreq.component';
import { AddUserToReqPopoverComponent } from './addUserToReq';
import { ContactAssignPopoverComponent } from './assign.component';
import { ContactSharePopoverComponent, UserRowComponent } from './share.component';
import { ContactStageChangePopoverComponent } from './stage.component';

@NgModule({
  imports: [CommonModule, CheckboxModule, ModelMatchButtonModule, SpinnerModule, ModelMatchTooltipModule],
  exports: [
    ContactSharePopoverComponent,
    UserRowComponent,
    ContactAssignPopoverComponent,
    ContactStageChangePopoverComponent,
    ContactAddToReqPopoverComponent,
    AddUserToReqPopoverComponent,
  ],
  declarations: [
    ContactSharePopoverComponent,
    UserRowComponent,
    ContactAssignPopoverComponent,
    ContactStageChangePopoverComponent,
    ContactAddToReqPopoverComponent,
    AddUserToReqPopoverComponent,
  ],
})
export class ContactActionsModule {}
