import { environment } from '../../environments/environment';
import { CognitoService } from '../services/cognito.service';
// Using appSync to have a public endpoint that graphQL can hit
// Cognito baked into it
// AUTH_TYPE is pulled in for list of possible constants - we use AUTH_TYPE.AMAZON_COGNITO_USER_POOLS
import AppSync, { AUTH_TYPE } from 'aws-appsync';
import { Injectable } from '@angular/core';
import { NormalizedCacheObject } from '@apollo/client/cache';

@Injectable()
export class AppsyncService {
  // hc;
  _client: AppSync<NormalizedCacheObject>;

  constructor(private cognitoService: CognitoService) {
    // url - get from our environment
    // region - hard code for now but move back to environment
    // jwtToken -in theory this is the token we store and use until it expires
    // async() => await(await this.cognitoService.getIdToken().toPromise()).idToken

    // Client just contains all the apollo stuff we were using Apollo-Boost before
    const client = new AppSync({
      url: environment.graphQLUri,
      region: 'us-west-2',
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => (await this.cognitoService.getIdToken().toPromise()).idToken,
      },
    });

    this._client = client;
    // console.log('🤬', this._client);
  }
  hydratedClient() {
    return this._client.hydrated();
  }

  nonHydratedClient() {
    return this._client;
  }
}
