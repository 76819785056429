<h1 mat-dialog-title>Add User</h1>
<mat-dialog-content>
  <form [formGroup]="addUserForm">
    <mat-form-field>
      <mat-select placeholder="Tenant" formControlName="tenantId" required>
        <mat-option *ngFor="let tenant of tenants" [value]="tenant.id">
          {{ tenant.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Email" required formControlName="email" />
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="First Name" required formControlName="firstName" />
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Last Name" required formControlName="lastName" />
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Title" formControlName="title" />
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Work Phone" formControlName="workPhone" />
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Cell Phone" formControlName="cellPhone" />
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Work Address Line 1" formControlName="line1" />
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Work Address Line 2" formControlName="line2" />
    </mat-form-field>

    <mat-form-field class="half-width">
      <input matInput placeholder="Work City" formControlName="city" />
    </mat-form-field>

    <mat-form-field class="half-width">
      <input matInput placeholder="Work State" formControlName="state" />
    </mat-form-field>

    <mat-form-field class="half-width">
      <input matInput placeholder="Work Zip Code" formControlName="zip" />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="warn" [mat-dialog-close]="true">Cancel</button>
  <button mat-button color="primary" (click)="addUser()" [disabled]="!addUserForm.valid">Add</button>
</mat-dialog-actions>
