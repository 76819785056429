import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  NgModule,
  AfterViewInit,
  ChangeDetectorRef,
  Renderer2,
  Injectable,
  ElementRef,
  OnInit,
} from '@angular/core';
import { modelMatchIcons } from './modelMatchIcons';
import { CommonModule } from '@angular/common';
import { ModelMatchIcon, ModelMatchIconName } from './mmIcons';
import * as icons from './mmIcons';
import { environment } from 'src/environments/environment';
// Create an Anugalr Component
@Component({
  selector: 'mm-icon',
  template: ` <span #iconContainer class="flex items-center justify-center"></span> `,
  styleUrls: ['../nick_styles/nick.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelMatchIconComponent implements AfterViewInit, OnChanges {
  @ViewChild('iconContainer', { static: true }) iconContainer;
  @Input() icon: string;
  @Input() size: string = '16';
  @Input() color: string;
  @Input() style: string;
  @Input() class: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    const iconSvg = this.paintIcon();
    this.renderer.setProperty(this.iconContainer.nativeElement, 'innerHTML', iconSvg);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (
    //   changes.icon ||
    //   changes.size ||
    //   changes.color ||
    //   changes.style ||
    //   changes.class
    // ) {
    //   const iconSvg = this.paintIcon();
    //   this.iconHtml = iconSvg;
    //   this.cdr.detectChanges();
    // }
  }

  paintIcon() {
    let iconSvg = modelMatchIcons[this.icon];
    if (this.size) {
      if (!iconSvg.includes('width')) {
        iconSvg = iconSvg.replace(/<svg/, `<svg width="${this.size}" height="${this.size}"`);
      } else {
        iconSvg = iconSvg.replace(/width="(\d+)"/, `width="${this.size}"`);
        iconSvg = iconSvg.replace(/height="(\d+)"/, `height="${this.size}"`);
      }
    }

    if (this.color) {
      iconSvg = iconSvg.replace(/fill="(\w+)"/, `fill="${this.color}"`);
      iconSvg = iconSvg.replace(/stroke="(\w+)"/, `stroke="${this.color}"`);
    }

    if (this.style || this.class) {
      iconSvg = iconSvg.replace(/<svg/, `<svg style="${this.style}" class="${this.class}"`);
    }

    return iconSvg;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ModelMatchIconsRegistry {
  private registry = new Map<string, string>();

  public registerIcons(icons: ModelMatchIcon[]) {
    icons.forEach((icon) => this.registry.set(icon.name, icon.svg));
  }

  public getIcon(iconName: ModelMatchIconName) {
    if (!this.registry.has(iconName)) {
      console.warn(`
      We could not find an icon with the name ${iconName} in the registry.
      If you are hoping to add the icon to the registry, use the ModelMatch Dev
      CLI. mm --register-icon <iconName> <iconFilePath>
      `);
    }

    return this.registry.get(iconName);
  }
}

@Component({
  selector: 'mm-icons-two',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelMatchIconsComponent implements OnInit, OnChanges {
  @Input() size: string;
  @Input() fill: string;
  @Input() strokeWidth: string = '1.5';
  @Input() strokeColor: string 
  private svgIcon: HTMLElement;

  @Input() name: ModelMatchIconName;

  constructor(
    private element: ElementRef,
    private modelMatchIconsRegistry: ModelMatchIconsRegistry
  ) {}

  ngOnInit(): void {
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
    const svgData = this.modelMatchIconsRegistry.getIcon(this.name);
    this.svgIcon = this.svgElementFromString(svgData);
    if (!this.svgIcon) return;
    this.element.nativeElement.appendChild(this.svgIcon);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.name && !changes.name.firstChange) {
      this.ngOnInit();
    }
  }

  private svgElementFromString(svgString: string): HTMLElement {
    const i = document.createElement('i');
    i.innerHTML = svgString;
    i.style.display = 'flex';
    i.style.alignItems = 'center';
    i.style.justifyContent = 'center';
    const svg = i.querySelector('svg');
    if (!svg) {
      console.warn('Cannot find svg inside of icon tag');
      return;
    }
    svg.style.width = this.size ?? '16';
    svg.style.height = this.size ?? '16';
    if (this.fill) {
      svg.setAttribute('fill', this.fill);
    }
    if (this.strokeWidth) {
      svg.setAttribute('stroke-width', this.strokeWidth);
    }
    if (this.strokeColor) {
      svg.setAttribute('color', this.strokeColor);
    }
    return i;
  }
}
@NgModule({
  declarations: [ModelMatchIconComponent, ModelMatchIconsComponent],
  imports: [CommonModule],
  exports: [ModelMatchIconComponent, ModelMatchIconsComponent],
})
export class ModelMatchIconModule {
  constructor(private modelMatchIconsRegistry: ModelMatchIconsRegistry) {
    Object.keys(icons).forEach((key) => {
      this.modelMatchIconsRegistry.registerIcons([icons[key]]);
    });
  }

  registerIcons(icons: ModelMatchIcon[]) {
    this.modelMatchIconsRegistry.registerIcons(icons);
  }
}
