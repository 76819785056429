<div class="add-header" *ngIf="isAddTodoAvailable">
  <button mat-mini-fab color="accent" (click)="addTodo()" class="add-button">
    <mat-icon>add</mat-icon>
  </button>
</div>
<mm-create-task-picker
  id="createTaskPicker"
  *ngIf="taskPickerView"
  (closeTaskPicker)="closeTaskPicker($event)"
  (openCreateTodo)="openCreateTodo($event)"
>
</mm-create-task-picker>
<mm-create-activity-popover
  id="createTodoCard"
  *ngIf="createTodoView"
  [viewOnly]="false"
  (closeCreateTodo)="closeCreateTodo($event)"
>
</mm-create-activity-popover>
<mm-create-activity-popover
  id="editTodoCard"
  *ngIf="editTodoView"
  [viewOnly]="false"
  (completeTodo)="doComplete($event)"
  (closeEditTodo)="closeEditTodo($event)"
>
</mm-create-activity-popover>
<mat-table
  [dataSource]="todoTableSource"
  matSort
  class="todo-table accent-table"
  [ngClass]="tableClass"
  (matSortChange)="sortData($event)"
>
  <ng-container matColumnDef="type">
    <!-- Make a little room for the add button if this is in a requisition -->
    <div *ngIf="isAddTodoAvailable">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        matTooltip="Type"
        [matTooltipShowDelay]="700"
        fxLayoutAlign="center center"
        >To Do
      </mat-header-cell>
    </div>

    <div *ngIf="!isAddTodoAvailable">
      <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Type" [matTooltipShowDelay]="700"
        >To Do
      </mat-header-cell>
    </div>

    <mat-cell *matCellDef="let todo" (click)="onTodoRowClick(todo)">
      <mat-icon *ngIf="todo.type === 'NOTE'" [color]="todo.dueOn | activityIconColor">create</mat-icon>
      <mat-icon *ngIf="todo.type === 'ADD'" [color]="todo.dueOn | activityIconColor">add</mat-icon>
      <mat-icon *ngIf="todo.type === 'CALL'" [color]="todo.dueOn | activityIconColor">call</mat-icon>
      <mat-icon *ngIf="todo.type === 'STAGE'" [color]="todo.dueOn | activityIconColor">arrow_forward</mat-icon>
      <mat-icon *ngIf="todo.type === 'MEETING'" [color]="todo.dueOn | activityIconColor">group</mat-icon>
      <mat-icon *ngIf="todo.type === 'TASK'" [color]="todo.dueOn | activityIconColor">done</mat-icon>
      <mat-icon *ngIf="todo.type === 'OTHER'" [color]="todo.dueOn | activityIconColor">folder_open</mat-icon>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Title" [matTooltipShowDelay]="700"
      >Title
    </mat-header-cell>
    <mat-cell
      *matCellDef="let todo"
      [matTooltip]="todo.title"
      [matTooltipShowDelay]="700"
      (click)="onTodoRowClick(todo)"
    >
      <div>
        <mat-icon *ngIf="todo.type === 'NOTE'" [color]="todo.dueOn | activityIconColor">create</mat-icon>
        <mat-icon *ngIf="todo.type === 'ADD'" [color]="todo.dueOn | activityIconColor">add</mat-icon>
        <mat-icon *ngIf="todo.type === 'CALL'" [color]="todo.dueOn | activityIconColor">call</mat-icon>
        <mat-icon *ngIf="todo.type === 'STAGE'" [color]="todo.dueOn | activityIconColor">arrow_forward </mat-icon>
        <mat-icon *ngIf="todo.type === 'MEETING'" [color]="todo.dueOn | activityIconColor">group</mat-icon>
        <mat-icon *ngIf="todo.type === 'TASK'" [color]="todo.dueOn | activityIconColor">done</mat-icon>
        <mat-icon *ngIf="todo.type === 'OTHER'" [color]="todo.dueOn | activityIconColor">folder_open</mat-icon>
        {{ todo.title }}
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="participants">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Participants" [matTooltipShowDelay]="700">
      Participants</mat-header-cell
    >
    <mat-cell *matCellDef="let todo" (click)="onTodoRowClick(todo)">
      <mat-chip-list>
        <mat-chip
          [matTooltip]="user.firstName + ' ' + user.lastName"
          *ngFor="let user of todo.users.slice(0, 5)"
          [color]="'compliment'"
          [matTooltipShowDelay]="300"
        >
          <mat-chip-avatar class="chipAvatar"
            ><img *ngIf="avatarUrls[user.id]" [src]="avatarUrls[user.id]" /><span
              *ngIf="!avatarUrls[user.id]"
              class="avatarName userAvatar"
              >{{ user.firstName[0] | titlecase }}{{ user.lastName[0] | titlecase }}</span
            ></mat-chip-avatar
          >
          <span *ngIf="!(todo.users.length + todo.contacts.length > 2)" class="nameWrapper"
            >{{ user.firstName }} {{ user.lastName }}</span
          >
        </mat-chip>
        <mat-chip
          [matTooltip]="contact.firstName + ' ' + contact.lastName"
          [matTooltipShowDelay]="300"
          *ngFor="let contact of todo.contacts.slice(0, todo.users.length < 5 ? 5 - todo.users.length : 0)"
          [color]="'accent'"
          (click)="onContactClick($event, contact, todo)"
        >
          <mat-chip-avatar class="chipAvatar"
            ><img *ngIf="avatarUrls[contact.id]" [src]="avatarUrls[contact.id]" /><span
              *ngIf="!avatarUrls[contact.id]"
              class="avatarName contactAvatar"
              >{{ contact.firstName[0] | titlecase }}{{ contact.lastName[0] | titlecase }}</span
            ></mat-chip-avatar
          >
          <span *ngIf="!(todo.users.length + todo.contacts.length > 2)" class="nameWrapper"
            >{{ contact.firstName }} {{ contact.lastName }}</span
          >
        </mat-chip>
      </mat-chip-list>
      <a *ngIf="todo.users.length + todo.contacts.length > 5" mat-button class="link"
        >...{{ todo.users.length + todo.contacts.length - 5 }} more</a
      >
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="requisition">
    <mat-header-cell *matHeaderCellDef matTooltip="Pipeline" [matTooltipShowDelay]="700"
      >Pipeline
    </mat-header-cell>
    <mat-cell
      *matCellDef="let todo"
      [matTooltip]="todo.requisition"
      [matTooltipShowDelay]="700"
      (click)="onTodoRowClick(todo)"
    >
      <a mat-button (click)="onRequisitionClick(todo)">{{ todo.reqId | todoReqName: requisitions }}</a>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="stage">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Stage" [matTooltipShowDelay]="700"
      >Stage
    </mat-header-cell>
    <mat-cell
      *matCellDef="let todo"
      [matTooltip]="getStage(todo)"
      [matTooltipShowDelay]="700"
      (click)="onTodoRowClick(todo)"
      >{{ getStage(todo) }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="start-date">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Scheduled or Due Date" [matTooltipShowDelay]="700"
      >Due Date</mat-header-cell
    >
    <mat-cell
      *matCellDef="let todo"
      [matTooltip]="todo.dueOn | todoTimeLabel: todo.type"
      [matTooltipShowDelay]="700"
      (click)="onTodoRowClick(todo)"
    >
      <div>{{ todo.dueOn | todoTimeLabel: todo.type }}</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="complete">
    <mat-header-cell *matHeaderCellDef matTooltip="Complete" [matTooltipShowDelay]="700">Complete</mat-header-cell>
    <mat-cell *matCellDef="let todo" fxLayoutAlign="end center">
      <mat-icon
        *ngIf="todo.type === 'MEETING'"
        [color]="completedTodos[todo.id] ? 'accent' : 'muted'"
        (click)="selectDisposition(todo)"
      >
        {{ completedTodos[todo.id] ? 'check_circle_outline' : 'radio_button_unchecked' }}</mat-icon
      >
      <mat-icon
        *ngIf="todo.type !== 'MEETING'"
        [color]="completedTodos[todo.id] ? 'accent' : 'muted'"
        (click)="openCompleteOrContinueDialog(todo)"
      >
        {{ completedTodos[todo.id] ? 'check_circle_outline' : 'radio_button_unchecked' }}</mat-icon
      >
    </mat-cell>
  </ng-container>

  <!--Requistions Todo Filters-->
  <ng-container matColumnDef="type-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field table-dropdown">
        <mat-select
          [(value)]="typeFilterValue"
          (valueChange)="applyFilter('type', $event.toString())"
          [(ngModel)]="activeFilters['type']"
        >
          <mat-option *ngFor="let option of typeDropOptions" [value]="option.value">
            {{ option.label || option.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="title-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input matInput (keyup)="applyFilter('title', $event.target.value)" [(ngModel)]="activeFilters['title']" />
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="participants-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input
          matInput
          (keyup)="applyFilter('participant', $event.target.value)"
          [(ngModel)]="activeFilters['participant']"
        />
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="requisition-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field table-dropdown">
        <mat-select (valueChange)="applyFilter('requisition', $event)" [(ngModel)]="activeFilters['requisition']">
          <mat-option *ngFor="let option of reqOptions" [value]="option.value">
            {{ option.title }}
          </mat-option>
        </mat-select>
        <!-- <input matInput (keyup)="applyFilter('requisition', $event.target.value)"> -->
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="stage-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field table-dropdown">
        <mat-select (valueChange)="applyFilter('stage', $event)" [(ngModel)]="activeFilters['stage']">
          <mat-option *ngFor="let option of stageOptions" [value]="option.value">
            {{ option.label || option.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="start-date-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field" floatLabel="never">
        <sat-datepicker-toggle matPrefix [for]="resultPicker"></sat-datepicker-toggle>
        <input matInput placeholder="" [satDatepicker]="resultPicker" (dateChange)="onDateChange($event.value)" />
        <sat-datepicker #resultPicker [rangeMode]="true"></sat-datepicker>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>

  <ng-container matColumnDef="complete-filter">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
  </ng-container>

  <!-- /Requistions Todo Filters-->

  <ng-container matColumnDef="section-header">
    <mat-header-cell *matHeaderCellDef>{{ sectionTitle }}</mat-header-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedTodoColumns; sticky: true" [ngClass]="{ 'is-hidden': mainHeaderHidden }">
  </mat-header-row>
  <mat-header-row
    *matHeaderRowDef="['section-header']"
    class="section-header"
    [ngClass]="{ 'is-hidden': !sectionTitle }"
  ></mat-header-row>
  <mat-header-row *matHeaderRowDef="displayedFilterColumns" class="filter-row"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedTodoColumns"></mat-row>
</mat-table>
<app-paginator *ngIf="show" [page]="_pageTodo._value" (pageChanged)="onChange($event)"></app-paginator>
