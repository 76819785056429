import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { LoginComponent } from './login.component';
import { LoginDialogComponentComponent } from 'app/shared/dialogs/login-dialog-component.component';
import { ResetPasswordDialogComponentComponent } from 'app/shared/dialogs/reset-password-dialog-component.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full',
    data: { title: 'Login' },
  },
];

@NgModule({
  declarations: [
    LoginComponent, 
    LoginDialogComponentComponent,
    ResetPasswordDialogComponentComponent
  ],
  imports: [SharedModule, RouterModule.forChild(routes)],
})
export class LoginModule {}
