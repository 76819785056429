import { Component, OnInit, Input, Optional, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { take, switchMap } from 'rxjs/operators';
import { MatSnackBar, MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Click2CallService } from '../../services/click2call.service';
import { UserService } from '../../services/user.service';
import { HistoryInput, ActivityType } from '../../shared/model/activity';
import { TodoService } from '../../services/todo.service';
import { ReportingService } from '../../services/reporting.service';
import { timer, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AlertDialogConfig } from '../../shared/dialogs/alert-dialog.component';
import { AlertDialogComponent } from '../../shared/dialogs/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ContactService } from 'app/services/contact.service';
import { NewActivityDialogComponent } from '../../shared/dialogs/new-activity-dialog.component';
import { IntegrationsService } from 'app/services/integrations.service';
import { CreateActivityPopoverComponent } from 'app/shared/create-activity/create-activity-popover.component';
import { UpdateUserInfoInput } from 'app/shared/model/user';

/**
 * This is the Integrations section for Twilio
 */
@Component({
  selector: 'mm-click2call-twilio-page',
  templateUrl: './click2call-twilio.component.html',
  styleUrls: ['./click2call-twilio.component.scss'],
})
export class Click2CallTwilioComponent implements OnInit {
  @Output() allDone: EventEmitter<any> = new EventEmitter();
  @Output() addTodo: EventEmitter<any> = new EventEmitter();
  @Input() click2CallExpanded: boolean;
  @Input() contactId: string;
  @Input() reqId: string;
  @Input() firstName: String = '';
  @Input() lastName: String = '';
  @Input() contactPhone: string = '';
  @Input() sourceLink: string = '';

  displayOption: number = 1;
  agentPhone: string = '';
  agentFirst: string = '';
  agentLast: string = '';
  agentUserId: string = '';
  currentMFACode: string = '';
  callRequestInProgress: boolean = false;
  currentMFAError: boolean = false;
  callStatus: string = '';
  callTimeSec: string = '0';
  callSID: string = '';
  callStatusQueryRef: any = null;
  callSub: any = null;
  listDispositions: string[] = [
    'Answered',
    'Left Voicemail',
    'Unanswered - No Voicemail Left',
    'Meeting Set',
    'Do Not Call',
  ];
  selectedDisposition: any = '';
  time: number = 0;
  timerDisplay: object = {
    hours: { digit1: '0', digit2: '0' },
    minutes: { digit1: '0', digit2: '0' },
    seconds: { digit1: '0', digit2: '0' },
  };
  timerSubscription: Subscription;
  dataLoaded: boolean = false;
  savingDispoComplete: boolean = true;

  recoveredReqId: string = '';
  recoveredContactId: string = '';

  inReq: boolean = true;

  showTransferOptions: boolean = false;
  transferPhone: string = '';

  // Give info for dispo - may not be for current contact/req if old call
  needPreviousDispoDisplay: string = '';

  originalAgentPhone: string = this.agentPhone;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private click2CallService: Click2CallService,
    private userService: UserService,
    private todoService: TodoService,
    private reportingService: ReportingService,
    private router: Router,
    private contactService: ContactService,
    private integrationsService: IntegrationsService,
    @Optional() public snackBarRef: MatSnackBarRef<Click2CallTwilioComponent> // @Inject(MAT_SNACK_BAR_DATA) public data?: any // @Optional() public data?: any
  ) {}

  ngOnInit() {
    // console.log('Click2Call Snackbar data found this.data', this.data);

    // if (this.data) {
    //   console.log('Click2Call Snackbar data found');
    // }
    if (!this.sourceLink) this.inReq = false;

    this.loadUserInfo();
    this.contactPhone = this.inputFormat(this.contactPhone);

    this.recoveredReqId = '';
    this.recoveredContactId = '';

    // Look in log for any "outstanding" calls
    this.verfifyCallLogsAreComplete();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      // console.log("changes propName", propName);
      if (changes.hasOwnProperty(propName) && Object.keys(changes).length === 1) {
        switch (propName) {
          case 'contactPhone': {
            // Take target phone from @Input and format for better UX
            this.contactPhone = this.inputFormat(changes.contactPhone.currentValue);

            this.displayOption = 1;

            console.log('ngOnChanges verfifyCallLogsAreComplete');

            this.verfifyCallLogsAreComplete();

            this.callRequestInProgress = false;

            // Check call logs on switch

            break;
          }
        }
      }
    }

    console.log('Click2CallTwilioComponent ngOnChanges');
  }

  inputFormat(numberStr: string): string {
    let newVal = numberStr ? numberStr.replace(/\D/g, '') : '';

    if (newVal.slice(0, 2) == '+1') newVal = newVal.slice(2);
    if (newVal.slice(0, 1) === '1') newVal = newVal.slice(1);

    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      // Arbitrary extension limit to 40 characters total - expand if needed
      newVal = newVal.slice(0, 40);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,4})/, '($1) $2-$3 X$4');
    }
    return newVal;
  }

  callAgent() {
    // Verify agent number first

    this.callRequestInProgress = true;
    this.callStatus = '';
    this.callTimeSec = '0';
    this.needPreviousDispoDisplay = '';
    this.savingDispoComplete = true;

    this.click2CallService
      .validateCallerId(this.agentPhone)
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log('validateCallerId: result ', result);

          if (result.data.validateCallerId) {
            if (this.agentPhone !== this.originalAgentPhone) {
              console.log('new agent phone default', this.agentPhone, this.originalAgentPhone);

              // Update agent phone to last used
              let input: UpdateUserInfoInput = {
                agentPhone: this.agentPhone,
              };

              this.userService
                .updateUserInfo(this.userService.userId, input)
                .pipe(
                  take(1),
                  switchMap((ret) => {
                    return this.click2CallService.startClick2Call(this.agentPhone, this.contactPhone).pipe(take(1));
                  })
                )
                .subscribe(
                  (result) => {
                    console.log('startClick2Call: result ', result);
                    this.callRequestInProgress = false;
                    this.callStatus = 'Preparing Call';
                    this.callSID = result.data.startClick2Call.sid;
                    this.displayOption = 2;
                    this.getCallStatus();
                    this.dispoCallStarted();

                    this.timerSubscription = timer(0, 1000).subscribe((ec) => {
                      this.time++;
                      this.timerDisplay = this.getDisplayTimer(this.time);
                    });
                  },
                  (error) => {
                    console.log('startClick2Call: There was an error validating caller id', error);
                    this.callRequestInProgress = false;
                  }
                );
            } else {
              console.log('original agent phone default', this.agentPhone, this.originalAgentPhone);

              this.click2CallService
                .startClick2Call(this.agentPhone, this.contactPhone)
                .pipe(take(1))
                .subscribe(
                  (result) => {
                    console.log('startClick2Call: result ', result);
                    this.callRequestInProgress = false;
                    this.callStatus = 'Preparing Call';
                    this.callSID = result.data.startClick2Call.sid;
                    this.displayOption = 2;
                    this.getCallStatus();
                    this.dispoCallStarted();
                    this.timerSubscription = timer(0, 1000).subscribe((ec) => {
                      this.time++;
                      this.timerDisplay = this.getDisplayTimer(this.time);
                    });
                  },
                  (error) => {
                    console.log('startClick2Call: There was an error validating caller id', error);
                    this.callRequestInProgress = false;
                  }
                );
            }

            // this.click2CallService
            //   .startClick2Call(this.agentPhone, this.contactPhone)
            //   .pipe(take(1))
            //   .subscribe(
            //     (result) => {
            //       console.log('startClick2Call: result ', result);
            //       this.callRequestInProgress = false;
            //       this.callStatus = 'Preparing Call';
            //       this.callSID = result.data.startClick2Call.sid;
            //       this.displayOption = 2;
            //       this.getCallStatus();
            //       this.dispoCallStarted();

            //       this.timerSubscription = timer(0, 1000).subscribe((ec) => {
            //         this.time++;
            //         this.timerDisplay = this.getDisplayTimer(this.time);
            //       });
            //     },
            //     (error) => {
            //       console.log('startClick2Call: There was an error validating caller id', error);
            //       this.callRequestInProgress = false;
            //     }
            //   );
          } else {
            // Change display to CID MFA - Display option 2
            this.displayOption = 4;
            this.callRequestInProgress = false;
          }
        },
        (error) => {
          console.log('validateCallerId: There was an error validating caller id', error);
          this.callRequestInProgress = false;
        }
      );
  }

  verifyCallerId() {
    this.callRequestInProgress = true;

    // Clear old codes
    this.currentMFACode = '';
    this.currentMFAError = false;

    this.click2CallService
      .verifyCallerId(this.agentPhone, `${this.agentUserId} ${this.agentLast}, ${this.agentFirst}`)
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log('verifyCallerId: result ', result);

          if (result.data.verifyCallerId.validationCode) {
            this.currentMFACode = result.data.verifyCallerId.validationCode;
            this.displayOption = 5;
            this.callRequestInProgress = false;
          } else {
            if (result.data.verifyCallerId.msg.search('"code":21450') > -1) {
              // Already verified - These droids are not the ones you are looking for. Move along.
              // User could have hit back button and tried again after already verifying
              this.displayOption = 1;
              this.callRequestInProgress = false;
            } else {
              // some other error
              this.currentMFAError = true;
              this.currentMFACode = 'An error has occurred.';
              this.displayOption = 5;
              this.callRequestInProgress = false;
            }
          }
        },
        (error) => {
          console.log('verifyCallerId: There was an error validating caller id', error);
          this.callRequestInProgress = false;
        }
      );
  }

  async loadUserInfo() {
    // Get the twilio integration first to see if we have an SMS number to use. If we don't then use the user's defined phone number.
    let twilioIntegrationKey = await this.integrationsService
      .readIntegrationKey('SMS_TWILIO')
      .pipe(take(1))
      .toPromise();

    let apiKey = twilioIntegrationKey.data.readIntegrationKey.apiKey
      ? JSON.parse(twilioIntegrationKey.data.readIntegrationKey.apiKey)
      : {};
    // console.log('🚀 ~ Click2CallTwilioComponent ~ loadUserInfo ~ apiKey', apiKey);

    let userId = await this.userService.getAuthenticatedUserId().pipe(take(1)).toPromise();
    this.agentUserId = userId;

    let userInfo = await this.userService.getUserInfo(userId).pipe(take(1)).toPromise();
    userInfo ?? console.log('🚀 ~ Click2CallTwilioComponent ~ loadUserInfo ~ userInfo is Null or Undefined', userInfo);

    let agentToUse = '';
    if (userInfo.data.getUserInfo.agentPhone) {
      agentToUse = userInfo.data.getUserInfo.agentPhone;
    } else {
      if (apiKey.friendlyUserNumber) {
        agentToUse = apiKey.friendlyName;
      }
    }

    this.agentPhone = this.inputFormat(agentToUse);
    this.originalAgentPhone = this.agentPhone;

    this.agentFirst = userInfo.data.getUserInfo.firstName;
    this.agentLast = userInfo.data.getUserInfo.lastName;
  }

  dispoCallStarted() {
    let value = 'Call Started ';
    let input: HistoryInput = {
      type: 'CALL',
      note: value,
      contactId: this.contactId,
      reqId: this.reqId,
      isPublic: true,
      analytics: JSON.stringify({
        callSid: this.callSID,
        lengthOfCall: 0,
        disposition: 'started',
        agentPhone: this.agentPhone,
        contactPhone: this.contactPhone,
        contactName: `${this.firstName} ${this.lastName}`,
        sourceLink: this.sourceLink,
      }),
    };

    // console.log("Playbook Item Edit Component :: addNote ", input);

    // Add call history and keep contact's lastUpdatedAt field in sync with history
    this.todoService.addHistory(input).subscribe(
      (result) => {
        this.contactService
          .updateContactLastUpdatedAtField(this.contactId, this.reqId, 'true')
          .pipe(take(1))
          .subscribe(
            (result) => {},
            (error) => {
              console.log('An error occurred updating contact fields after after dispoCallStarted.', error);
            }
          );
      },
      (error) => {
        this.snackBar.open('An error occurred adding the disposition note.', null, {
          duration: 3000,
        });
      }
    );
  }

  dispoCallTerminated() {
    let value = 'Call Terminated';
    let input: HistoryInput = {
      type: 'CALL',
      note: value,
      contactId: this.contactId,
      reqId: this.reqId,
      isPublic: true,
      analytics: JSON.stringify({
        callSid: this.callSID,
        lengthOfCall: 10,
        disposition: 'started',
        agentPhone: this.agentPhone,
        contactPhone: this.contactPhone,
        contactName: `${this.firstName} ${this.lastName}`,
        sourceLink: this.sourceLink,
      }),
    };

    // console.log("Playbook Item Edit Component :: addNote ", input);
    this.todoService.addHistory(input).subscribe(
      (result) => {
        this.contactService
          .updateContactLastUpdatedAtField(this.contactId, this.reqId, 'true')
          .pipe(take(1))
          .subscribe(
            (result) => {},
            (error) => {
              console.log('An error occurred updating contact fields after dispoCallTerminated.', error);
            }
          );
      },
      (error) => {
        this.snackBar.open('An error occurred adding the disposition note.', null, {
          duration: 3000,
        });
      }
    );
  }

  dispoCallComplete() {
    // console.log("dispoCallComplete this.contactId this.reqId", this.contactId, this.reqId, this.selectedDisposition);

    // console.log("dispoCallComplete ", this.selectedDisposition);
    // console.log(JSON.stringify({ callSid: this.callSID, lengthOfCall: this.callTimeSec, disposition: this.selectedDisposition }));

    if (!this.selectedDisposition[0]) {
      this.snackBar.open('Please select a disposition note.', null, {
        duration: 3000,
      });

      return;
    }

    this.savingDispoComplete = false;

    let value = `Call Completed: Disposition = ${this.selectedDisposition[0]}`;

    let localCallSID = this.callSID;

    // Set minimum call time of 100 if call time is unknown.
    // Analytics will look for calls over 0 seconds for dispositions
    let input: HistoryInput = {
      type: 'CALL',
      note: value,
      contactId: this.recoveredContactId ? this.recoveredContactId : this.contactId,
      reqId: this.recoveredReqId ? this.recoveredReqId : this.reqId,
      isPublic: true,
      analytics: JSON.stringify({
        callSid: this.callSID,
        lengthOfCall: this.callTimeSec ? this.callTimeSec : 100,
        disposition: this.selectedDisposition[0],
        agentPhone: this.agentPhone,
        contactPhone: this.contactPhone,
        contactName: `${this.firstName} ${this.lastName}`,
        sourceLink: this.sourceLink,
      }),
    };

    // console.log("Playbook Item Edit Component :: addNote ", input);
    this.todoService.addHistory(input).subscribe(
      (result) => {
        // this.snackBar.open('Added Call Disposition Note.', null, {
        //   duration: 3000,
        // });

        // MTIK-1155 - "Do you want to set a next action?" with "Yes" or "No" options.
        this.nextActionOption();

        // this.needPreviousDispoDisplay = '';
        // this.displayOption = 1;
        // this.allDone.emit(null);

        this.savingDispoComplete = true;

        let lastCallStatusCheck = {
          callSID: localCallSID,
          created: new Date().toISOString(),
        };

        this.contactService
          .updateContactLastUpdatedAtField(
            this.recoveredContactId ? this.recoveredContactId : this.contactId,
            this.recoveredReqId ? this.recoveredReqId : this.reqId,
            'true'
          )
          .pipe(take(1))
          .subscribe(
            (result) => {},
            (error) => {
              console.log('An error occurred updating contact fields after dispoCallComplete.', error);
            }
          );
      },
      (error) => {
        this.snackBar.open('An error occurred adding the disposition note.', null, {
          duration: 3000,
        });

        this.needPreviousDispoDisplay = '';
        this.displayOption = 1;
        this.allDone.emit(null);

        this.savingDispoComplete = true;
      }
    );
  }

  // Look for previous outstanding calls
  verfifyCallLogsAreComplete() {
    this.dataLoaded = false;

    this.reportingService
      .getReportingCounts(
        ['history'],
        null,
        '',
        '',
        JSON.stringify([{ completedDate: { order: 'desc' } }]),
        '1',
        '',
        '',
        '',
        [],
        ['currentUser'],
        [{ field: 'type.keyword', value: ['CALL'], qualifier: 'match', bool: 'AND' }],
        ''
      )
      .pipe(take(1))
      .subscribe(
        (result) => {
          // console.log('verfifyCallLogsAreComplete getReportingCounts result ', result);

          // parse results as JSON
          let queryResults = JSON.parse(result.data.getReportingCounts);

          // console.log('getReportingCounts queryResults parsed ', queryResults);

          // If no results found - no call ever made - move on
          if (queryResults.hits.hits.length === 0) {
            // These aren't the droids you're looking for. You can go about your business. Move along! Move along!

            this.dataLoaded = true;
            return;
          }

          // If 1 results found as expected
          if (queryResults.hits.hits.length === 1) {
            // Found a last record
            // Now check if it was closed and has a callSID

            if (
              queryResults.hits.hits[0]._source?.analytics?.callSid &&
              queryResults.hits.hits[0]._source?.analytics?.lengthOfCall === 0
            ) {
              // Call started but not finished detected

              // Is call still going?
              // OR prompt for dispo ...

              this.sourceLink = queryResults.hits.hits[0]._source?.analytics?.sourceLink;

              // Move to 2 display - either in progress or waiting for disposition
              this.displayOption = 2;

              // Set these here and use them - remove them when used
              this.recoveredReqId = queryResults.hits.hits[0]._source?.reqId;
              this.recoveredContactId = queryResults.hits.hits[0]._source?.contacts[0].id;

              this.recoverDispositionInformation(
                queryResults.hits.hits[0]._source.analytics.callSid,
                queryResults.hits.hits[0]._source.analytics.contactName
              );

              // this.contactId, this.reqId
            } else {
              this.dataLoaded = true;
              return;
            }
          } else {
            this.dataLoaded = true;
            return;
          }
        },
        (error) => {
          console.log('verfifyCallLogsAreComplete getReportingCounts Error Getting User Todo Info. ', error);

          this.dataLoaded = true;
        }
      );
  }

  recoverDispositionInformation(callSIDRecovered: string, contactName: string) {
    // If user forgets to log call result - force them to log before next call
    // Based on call Sid

    // Make sure timers are not already running - if so wait fo them to take over
    if (!this.callStatusQueryRef) {
      this.click2CallService
        .getCallStatus(callSIDRecovered)
        .valueChanges.pipe(take(1))
        .subscribe(
          ({ data }) => {
            let callInfo = JSON.parse(data.getCallStatus);
            this.callStatus = callInfo.status;

            // Translate twilio status to user friendly
            this.translateTwilioCallStatus();

            // console.log('getReportingCounts callInfo', callInfo);

            // && !this.callStatus === ''
            if (this.callStatus === 'Call Completed' || this.callStatus === '' || this.callStatus === 'canceled') {
              const endDate_dt = new Date(callInfo.dateUpdated);

              this.needPreviousDispoDisplay = `Previous call disposition needed ${contactName} on ${endDate_dt.toLocaleString()}`;

              // Recover call time
              this.callTimeSec = callInfo.duration;

              // Set timer to recorded actual
              this.timerDisplay = this.getDisplayTimer(parseInt(this.callTimeSec));

              // console.log(
              //   'getReportingCounts Dispo Requested again this.callTimeSec callSIDRecovered',
              //   this.callTimeSec,
              //   callSIDRecovered
              // );

              this.dataLoaded = true;
            } else {
              // console.log(
              //   'getReportingCounts Active CALL! this.callTimeSec callSIDRecovered',
              //   this.callTimeSec,
              //   callSIDRecovered
              // );

              this.needPreviousDispoDisplay = `Call in progress to ${contactName}`;

              // Recover call time - duration not available during active calls
              // Get difference between dateCreated and dateUpdated
              const startDate_dt = new Date(callInfo.dateCreated);
              const endDate_dt = new Date(); //callInfo.dateUpdated

              this.callTimeSec = '' + Math.abs((startDate_dt.getTime() - endDate_dt.getTime()) / 1000);
              this.time = parseInt(this.callTimeSec);

              // Set timer to recorded actual
              this.timerDisplay = this.getDisplayTimer(parseInt(this.callTimeSec));

              if (!this.timerSubscription) {
                this.time = parseInt(this.callTimeSec);

                this.timerSubscription = timer(0, 1000).subscribe((ec) => {
                  this.time++;
                  this.timerDisplay = this.getDisplayTimer(this.time);
                });
              }

              if (!this.callSID) this.callSID = callSIDRecovered;

              this.getCallStatus();

              this.dataLoaded = true;
            }
          },
          (error) => {
            console.log('LandingPageComponent: Error getting authenticated userId -> ' + error.message);
            this.dataLoaded = true;
          }
        );
    } else {
      this.dataLoaded = true;
    }
  }

  getCallStatus() {
    // If previously defined - kill it
    if (this.callStatusQueryRef) {
      this.callStatusQueryRef.stopPolling();
      this.callStatusQueryRef = null;
    }

    // Null or empty callSID will result in never finishing
    // twilio returns valid values
    if (!this.callSID) return;

    // Set alternate time out just in case
    const safetyTimeout = setTimeout(() => {
      // console.log("safety setTimeout reached");

      if (this.callStatusQueryRef) {
        this.callStatusQueryRef.stopPolling();
        this.callStatusQueryRef = null;
      }

      if (this.callSub) {
        this.callSub.unsubscribe();
        this.callSub = null;
      }

      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
        this.timerSubscription = null;
      }
    }, 10800000);

    this.callStatusQueryRef = this.click2CallService.getCallStatus(this.callSID);

    this.callSub = this.callStatusQueryRef.valueChanges.subscribe(({ data }) => {
      console.log('getCallStatus', data);

      let callInfo = JSON.parse(data.getCallStatus);
      console.log('callInfo', callInfo);

      this.callStatus = callInfo.status;

      // Translate twilio status to user friendly
      this.translateTwilioCallStatus();

      if (this.callStatus === 'Call Connected') {
        // Change polling interval after connect
        this.callStatusQueryRef.stopPolling();
        this.callStatusQueryRef.startPolling(5000);
      }

      // Stop polling after call finished
      if (this.callStatus === 'Call Completed' || this.callStatus === '') {
        // Complex recover logic - trying to not confuse the user but developer needs to be careful
        if (this.needPreviousDispoDisplay.includes('Call in progress')) this.needPreviousDispoDisplay = '';

        this.callStatusQueryRef.stopPolling();
        this.callSub.unsubscribe();
        this.timerSubscription.unsubscribe();
        this.timerSubscription = null;
        this.callTimeSec = callInfo.duration;

        if (safetyTimeout) {
          clearTimeout(safetyTimeout);
        }
      }
    });

    this.callStatusQueryRef.startPolling(1500);
  }

  onXClick() {
    switch (this.displayOption) {
      case 1:
        this.allDone.emit(null);
        break;

      case 2:
        this.allDone.emit(null);
        break;

      case 4: // pre-verify step
        this.displayOption = 1;
        break;

      case 5: // Post verify triggered step
        this.displayOption = 4;
        break;

      case 6: // Optional next action
        this.displayOption = 1;
        this.recoveredReqId = '';
        this.recoveredContactId = '';
        this.allDone.emit(null);
        break;

      default:
        this.allDone.emit(null);
        break;
    }

    if (this.snackBarRef) this.snackBarRef.dismiss();
    else {
      this.snackBar.open('Done', null, {
        duration: 100,
      });
    }
  }

  // UI relies on translated values so be careful if you change any of these descriptions
  translateTwilioCallStatus() {
    // Translate twilio status to user friendly
    if (this.callStatus === 'queued') this.callStatus = 'Queued';

    if (this.callStatus === 'ringing') this.callStatus = "We're calling you!";

    if (this.callStatus === 'in-progress') this.callStatus = 'Call Connected';

    if (this.callStatus === 'completed') this.callStatus = 'Call Completed';
  }

  getDisplayTimer(time: number) {
    const hours = '0' + Math.floor(time / 3600);
    const minutes = '0' + Math.floor((time % 3600) / 60);
    const seconds = '0' + Math.floor((time % 3600) % 60);

    return {
      hours: { digit1: hours.slice(-2, -1), digit2: hours.slice(-1) },
      minutes: { digit1: minutes.slice(-2, -1), digit2: minutes.slice(-1) },
      seconds: { digit1: seconds.slice(-2, -1), digit2: seconds.slice(-1) },
    };
  }

  onLinkBacktoSource() {
    this.router
      .navigateByUrl(this.sourceLink)
      .then((bool) => {})
      .catch();
  }

  terminateCall() {
    console.log('terminateCall');

    if (this.callStatusQueryRef) {
      this.callStatusQueryRef.stopPolling();
      this.callStatusQueryRef = null;
    }

    if (this.callSub) {
      this.callSub.unsubscribe();
      this.callSub = null;
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }

    this.callStatus = 'Call Completed';

    // Complex recover logic - trying to not confuse the user but developer needs to be careful
    if (this.needPreviousDispoDisplay.includes('Call in progress')) this.needPreviousDispoDisplay = '';

    this.click2CallService
      .terminateCall(this.callSID)
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log('terminateCall: result ', result);
          this.callRequestInProgress = false;

          // this.dispoCallTerminated();
        },
        (error) => {
          console.log('terminateCall: There was an error terminating call', error);
          this.callRequestInProgress = false;

          // this.dispoCallTerminated();
        }
      );
  }

  nextActionOption() {
    this.savingDispoComplete = true;
    this.needPreviousDispoDisplay = '';
    this.displayOption = 6;

    return;

    // Temporary testing with dialog - loo,ms better as new display type in existing stage area
    const dialogConfig: AlertDialogConfig = {
      message: 'Do you want to set a next action?',
      title: 'Next Action',
      cancelButton: {
        label: 'No',
        color: 'primary',
      },
      confirmButton: {
        label: 'Yes',
        color: 'warn',
      },
    };

    const dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, boolean>(AlertDialogComponent, {
      panelClass: 'alert-dialog-component',
      data: dialogConfig,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(
      (isYes) => {
        if (isYes) {
          console.log('isYes', isYes);
          this.addTodo.emit(null);

          this.needPreviousDispoDisplay = '';
          this.displayOption = 1;
          this.allDone.emit(null);
        }
      },
      (error) => {
        this.needPreviousDispoDisplay = '';
        this.displayOption = 1;
        this.allDone.emit(null);
      }
    );
  }

  setNextAction() {
    //this.addTodo.emit(null);
    console.log(
      'addTodoLocal Triggered contact, req',
      this.recoveredContactId ? this.recoveredContactId : this.contactId,
      this.recoveredReqId ? this.recoveredReqId : this.reqId
    );

    let dialogRef = this.dialog.open(CreateActivityPopoverComponent, {
      panelClass: 'new-activity-dialog',
      data: {
        isDialog: true,
        contactId: this.recoveredContactId ? this.recoveredContactId : this.contactId,
        reqId: this.recoveredReqId ? this.recoveredReqId : this.reqId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && !result.error && result != 'cancelled') {
        console.log('TodoTabComponent Closing Dialog after adding to do ', result);
        if (this.snackBarRef) {
          this.snackBarRef.dismiss();
          this.snackBarRef = null;
        }
      }
    });

    this.recoveredReqId = '';
    this.recoveredContactId = '';
    this.needPreviousDispoDisplay = '';
    this.displayOption = 1;
    this.allDone.emit(null);
  }

  addTodoLocal(event: any) {}

  setupTransferCall() {
    this.click2CallService
      .transferCall(this.callSID, this.transferPhone)
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log('transferCall: result ', result);

          // this.dispoCallTerminated();
        },
        (error) => {
          console.log('transferCall: There was an error transfering call', error);

          // this.dispoCallTerminated();
        }
      );
  }

  ngOnDestroy() {
    if (this.callStatusQueryRef) {
      this.callStatusQueryRef.stopPolling();
      this.callStatusQueryRef = null;
    }

    if (this.callSub) this.callSub.unsubscribe();

    if (this.timerSubscription) this.timerSubscription.unsubscribe();
  }
}
