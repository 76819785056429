import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class Phone implements PipeTransform {
  transform(value: string): string {
    let newVal = value ? value.replace(/\D/g, '') : '';

    if (newVal.slice(0, 2) == '+1') newVal = newVal.slice(2);
    if (newVal.slice(0, 1) === '1') newVal = newVal.slice(1);

    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      // Arbitrary extension limit to 40 characters total - expand if needed
      newVal = newVal.slice(0, 40);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,4})/, '($1) $2-$3 X$4');
    }

    return newVal;
  }
}
@Pipe({
  name: 'jsonPretty',
})
export class jsonPrettyPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return JSON.stringify(value, null, 2)
      .replace(/ /g, '&nbsp;') // note the usage of `/ /g` instead of `' '` in order to replace all occurences
      .replace(/\n/g, '<br/>'); // same here
  }
}
