export enum ZapierTriggers {
  contactMarkedAsTouched = 'contactMarkedAsTouched',
  contactIsAssigned = 'contactIsAssigned',
  contactAddedToReq = 'contactAddedToReq',
  contactStageChanged = 'contactStageChanged',
  contactUpdated = 'contactUpdated',
  reqContactTagged = 'reqContactTagged',
  todoCompleted = 'todoCompleted',
}

export interface ZapierTriggerBody {
  contactId?: string;
  reqId?: string;
  userId?: string;
  tenantId?: string;
  tenantTitle?: string;
  requisition?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactStage?: string;
  assignedUser?: AssignedUser;
  newStage?: string;
  tagName?: string;
  todoTitle?: string;
  checkValidity?: CheckValidity;
  completedBy?: string;
  completedDate?: string;
}

export interface AssignedUser {
  firstName?: string;
  lastName?: string;
  id?: string;
  email?: string;
}

export interface CheckValidity {
  contactId?: string;
  reqId?: string;
  tagId?: string;
  userId?: string;
  todoTitle?: string;
}
