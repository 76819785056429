import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModelMatchComponent } from 'app/market-insight/ModelMatchComponent';
import { ContactService } from 'app/services/contact.service';
import { ImageCachingService } from 'app/services/imagecaching.service';
import { RequisitionService } from 'app/services/requisition.service';
import { TenantService } from 'app/services/tenant.service';
import { UserService } from 'app/services/user.service';
import { ZapierService } from 'app/services/zapier.service';
import { Contact, UserContact } from 'app/shared/model/contacts';
import { Email, User } from 'app/shared/model/user';
import { ZapierTriggers } from 'app/shared/model/zapier';
import { forkJoin, fromEvent, Subscription, combineLatest, BehaviorSubject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ActionBarDialog } from '../actionbar/actionbar-dialog/actionbar-dialog.component';
import { ContactsService } from '../contacts.service';

@Component({
  selector: 'mm-add-user-to-req-popover',
  template: `
    <div class="flex flex-col h-48 overflow-hidden" style="margin: 0.2em">
      <div class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd"
          />
        </svg>
        <input #search class="apperance-none focus_outline-none px-2 py-1 text-sm" placeholder="Search" />
      </div>

      <!-- Global Checkbox -->
      <div style="width: 90%; display: flex; margin-bottom: 0.3em">
        <mm-checkbox [value]="selectedAll ? 1 : 0" (click)="selectOrDeselectAll()"> </mm-checkbox>
        <p style="font-size: 0.87em; margin-left: 0.8em">Select All</p>
      </div>
      <ul class="flex-grow overflow-scroll no-scrollbar">
        <li *ngFor="let user of tenantUsers">
          <mm-user-row
            [user]="user"
            [checked]="selectedUsers.includes(user.data.id)"
            (onSelectUser)="selectUser($event)"
          ></mm-user-row>
        </li>
      </ul>
      <div class="flex space-x-1 w-full">
        <div class="flex-grow">
          <mm-button
            [success]="success"
            [canRetry]="true"
            [loading]="loading"
            [disabled]="selectedUsers.length == 0 && !success"
            [options]="{
              style: 'secondary',
              size: 'sm'
            }"
            (onClick)="addUserToReq()"
          >
            {{ contact?.assignedUser ? 'Reassign' : 'Assign' }}
          </mm-button>
        </div>
        <mm-button
          [options]="{
            style: 'link',
            size: 'sm'
          }"
          (onClick)="closePopover.emit()"
        >
          Cancel
        </mm-button>
      </div>
    </div>
  `,
  styleUrls: ['../../nick_styles/nick.css'],
})
export class AddUserToReqPopoverComponent extends ModelMatchComponent implements OnInit, AfterViewInit {
  @ViewChild('search') search: ElementRef;
  @Input() contact: any;
  @Input() tenantId: string;
  @Input() reqId: string;
  @Input() currentUsers: User[] = [];
  @Output() closePopover: EventEmitter<any> = new EventEmitter();
  @Output() changeContactStageFromAssign = new EventEmitter<any>();
  public loading: boolean = false;
  public error: string;
  public success: string | boolean = false;
  public tenantUsers: {
    display: string;
    keys: string[];
    data: { id: string; firstName: string; lastName: string; emails: Email[] };
  }[];
  private _tenantUsers: {
    display: string;
    keys: string[];
    data: { id: string; firstName: string; lastName: string; emails: Email[] };
  }[];
  public selectedUsers: string[] = [];
  private tenantUsersBS = new BehaviorSubject<User[]>([]);
  filteredUsers: User[] = [];
  currentUserIds: string[];
  tenantsSub: Subscription;
  tenantUsersSub: Subscription;
  selectedAll: boolean = false;
  constructor(
    private tenantService: TenantService,
    private reqService: RequisitionService,
    private confirmDialog: MatDialog,
    private contactsService: ContactsService,
    private zapierService: ZapierService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit() {
    this.currentUserIds = this.currentUsers.map((user) => user.id);

    this.tenantUsersSub = this.tenantUsersBS.subscribe((result) => {
      this.setFilteredUsers(result);
    });

    this.userService.getAuthenticatedUserInfo().subscribe(
      (result) => {
        this.getUnassignedUsers(result);
      },
      (error) => {
        console.log('AddUserToReqComponent :: error loading authenticatedUserInfo', error);
      }
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.search.nativeElement.focus();
    }, 100);
    this.subscribeToInputChanges();
  }

  setFilteredUsers(tenantUsers: User[]): void {
    this.filteredUsers = tenantUsers
      .filter((user) => !this.currentUserIds.includes(user.id))
      .sort((userA, userB) => userA.firstName.localeCompare(userB.firstName));

    this.tenantUsers = this.filteredUsers.map(({ id, firstName, lastName, emails }) => {
      return {
        display: firstName + ' ' + lastName,
        keys: [firstName, lastName, ...emails.map((email) => email.address)],
        data: { id, firstName, lastName, emails },
      };
    });
    this._tenantUsers = this.tenantUsers;
  }

  loadTenantAndModelMatchInternalUsers(mmUserTenantId: string): Subscription {
    return combineLatest(
      this.tenantService.getTenantWithUsers({
        tenantId: this.tenantId,
      }),
      this.tenantService.getTenantWithUsers({ tenantId: mmUserTenantId })
    )
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log('AddUserToReqComponent loaded tenants ', result);
          let combinedUsers = result[0].data.getTenant.user.concat(result[1].data.getTenant.user);
          this.tenantUsersBS.next(combinedUsers.filter((user) => user['active'] == true));
        },
        (error) => {
          console.log('AddUserToReqComponent :: error loading tenant information', error);
        }
      );
  }

  loadTenantUsers(): Subscription {
    return this.tenantService
      .getTenantWithUsers({ tenantId: this.tenantId })
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log('AddUserToReqComponent loaded tenant ', result.data.getTenant);
          this.tenantUsersBS.next(result.data.getTenant.user.filter((user) => user['active'] == true));
        },
        (error) => {
          console.log('AddUserToReqComponent :: error loading tenant information', error);
        }
      );
  }

  getUnassignedUsers(userInfo: any): void {
    // if the current user is a member of another tenant && in the mm_user || mm_admin groups load req tenant users and modelmatch internal users.
    // Else just load the req tenant users only.
    let userGroups = userInfo.groups;
    if (
      userInfo.attributes['custom:tenantId'] != this.tenantId &&
      (userGroups.includes('mmUser') || userGroups.includes('mmAdmin'))
    ) {
      this.tenantsSub = this.loadTenantAndModelMatchInternalUsers(userInfo.attributes['custom:tenantId']);
    } else {
      this.tenantsSub = this.loadTenantUsers();
    }
  }

  subscribeToInputChanges() {
    this.subscribe(
      fromEvent(this.search.nativeElement, 'keyup').pipe(
        // tap((event: KeyboardEvent) => this.checkForHotKeys(event)),
        tap((event: KeyboardEvent) => {
          if (!(event.target instanceof HTMLInputElement)) return;
          const searchValue = event.target.value;
          this.tenantUsers = this._tenantUsers?.filter((user) => {
            return user.keys.some((key) => key.toLowerCase().includes(searchValue.toLowerCase()));
          });
        })
      ),
      () => {},
      (error) => {
        console.log('ShareContactToUserComponent :: error subscribing to input changes', error);
      }
    );
  }

  selectUser(userId) {
    if (this.selectedUsers.includes(userId)) {
      let index = this.selectedUsers.indexOf(userId);
      this.selectedUsers.splice(index, 1);
      this.selectedAll = false;
    } else {
      this.selectedUsers.push(userId);
    }
  }

  addUserToReq(): void {
    if (this.success) this.closePopover.emit();
    this.loading = true;
    for (let u = 0; u < this.selectedUsers.length; u++) {
      this.reqService.addUserToReq(this.selectedUsers[u], this.reqId).subscribe(
        (result) => {
          this.loading = false;
          this.success = 'Done';
          console.log('AddUserToReqComponent :: User added to req with result ', result);
        },
        (error) => {
          console.log('AddUserToReqComponent :: error in addUserToReq mutation', error);
        }
      );
    }
  }

  selectOrDeselectAll() {
    this.selectedAll = !this.selectedAll;
    this.selectedUsers = [];

    if (this.selectedAll) {
      for (let u = 0; u < this.tenantUsers.length; u++) {
        this.selectedUsers.push(this.tenantUsers[u].data.id);
      }
    }
  }

  ngOnDestroy() {
    this.tenantUsersSub.unsubscribe();

    if (this.tenantsSub) {
      this.tenantsSub.unsubscribe();
    }
  }
}
