<div mat-dialog-title>
  <button mat-icon-button class="button-close" (click)="onCloseClick()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="flex-container header-container" fxLayoutGap="1em" fxLayout="row" fxLayout.lt-lg="column">
    <div fxFlex="100%">
      <header class="dialog-header">
        <h3>Export Model Match Contacts</h3>
      </header>

      <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
    </div>

    <div fxFlex fxLayoutAlign="start center" class="req-options">
      <!-- *ngIf="currentUploadStatus !== 4 && isImporting" -->
    </div>
  </div>
</div>

<section>
  <div class="container">
    <div class="flex-container" fxLayoutGap="1em" fxLayout="row" fxLayout.lt-lg="column">
      <div class="child-1" fxFlex="25%" fxFlex.gt-md="25%" fxFlexOrder="1" fxFlexOrder.lt-lg="1" style="flex-grow: 1">
        <mat-list>
          <!-- <div mat-list-item style="white-space: nowrap">
            {{ actionInProgress }}
          </div> -->
          <div class="some-page-wrapper">
            <!-- <div class="row">
              <div class="column">
                <div class="blank_column">Selected</div>
              </div>
              <div class="column">
                <div class="blank_column">{{ selectedCount }}</div>
              </div>
            </div> -->
            <div class="row">
              <div class="column">
                <div class="blank_column">Staged</div>
              </div>
              <div class="column">
                <div class="blank_column">{{ processedCounter }}</div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="column">
                <div class="blank_column">Duplicates</div>
              </div>
              <div class="column">
                <div class="blank_column">{{ duplicateCounter }}</div>
              </div>
            </div> -->

            <div class="row">
              <div class="column">
                <div class="blank_column">Errors</div>
              </div>
              <div class="column">
                <div class="blank_column">{{ errorCounter }}</div>
              </div>
            </div>
          </div>

          <div style="font-size: 0.6em">*Don't close this dialog until transfers are complete.</div>
        </mat-list>
      </div>

      <div
        class="child-2 integrations-description"
        fxFlex="75%"
        fxFlex.gt-md="75%"
        fxFlexOrder="1"
        fxFlexOrder.lt-lg="1"
        style="flex-grow: 5; height: 40vh; overflow: auto"
      >
        <h4>Log</h4>
        <mat-progress-bar mode="determinate" value="{{ percentProcessed }}"></mat-progress-bar>

        <mat-list style="overflow: auto">
          <div mat-list-item style="white-space: nowrap" *ngFor="let item of exportLog">
            {{ item }}
          </div>
        </mat-list>
      </div>
    </div>
  </div>

  <div style="margin-bottom: 2em">
    <div fxFlex="100%">
      <header class="dialog-header">
        <button
          *ngIf="!completed"
          mat-button
          (click)="onCloseClick()"
          color="warn"
          style="margin-right: 20px"
          class="mat-elevation-z3"
        >
          Cancel
        </button>

        <button
          *ngIf="completed"
          mat-button
          (click)="onCloseClick()"
          color="primary"
          style="margin-left: 20px"
          class="mat-elevation-z3"
        >
          OK
        </button>
      </header>
    </div>
  </div>
</section>
