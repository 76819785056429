import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ReqContact } from '../../../shared/model/contacts';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { take, map, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Page, SortOption } from './../../../shared/model/filter';
import { Column } from './../../../shared/dialogs/display-columns-dialog.component';
import { DisplayColumnsDialogComponent } from 'app/shared/dialogs/display-columns-dialog.component';
import { ContactFilter, SearchFilter } from './../../../shared/model/contacts';
import { SaveFiltersDialogComponent } from 'app/shared/dialogs/save-filters-dialog.component';
import { ShareFiltersDialogComponent } from './../../../shared/dialogs/share-filters-dialog.component';
import { EditContactsDialog } from '../dialogs/edit-contacts-dialog.component';
import { ConfirmBulkDialog } from '../dialogs/bulk-contact-select-dialog.component';
import { ConfirmationDialogConfig } from 'app/shared/dialogs/confirmation-dialog.component';
import { ConfirmationDialogComponent } from './../../../shared/dialogs/confirmation-dialog.component';
import { SatDatepickerRangeValue } from 'saturn-datepicker-ext';
import moment from 'moment';
import { DateRange } from './../../../shared/model/filter';
import { MatInput } from '@angular/material/input';
import { ContactService } from './../../../services/contact.service';
import { UserService } from './../../../services/user.service';
import { ImageCachingService } from './../../../services/imagecaching.service';
import { RequisitionService } from './../../../services/requisition.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'mm-contacts-tab',
  templateUrl: './contacts-tab.component.html',
  styleUrls: ['./contacts-tab.component.scss'],
})
export class ContactsTabComponent implements OnInit {
  contactColumns: Column[] = [
    {
      colName: 'Member',
      value: 'name',
      isDisplayed: true,
      filterKey: 'name',
    },
    {
      colName: 'Last Updated',
      value: 'lastUpdatedAt',
      isDisplayed: true,
      filterKey: 'lastUpdatedAt',
    },
    {
      colName: 'City',
      value: 'city',
      isDisplayed: false,
      filterKey: 'city',
    },
    {
      colName: 'State',
      value: 'state',
      isDisplayed: false,
      filterKey: 'state',
    },
    {
      colName: 'Company',
      value: 'company',
      isDisplayed: true,
      filterKey: 'company',
    },
    {
      colName: 'NMLS ID',
      value: 'nmls',
      isDisplayed: false,
      filterKey: 'nmlsId',
    },
    {
      colName: 'Source',
      value: 'source',
      isDisplayed: true,
      filterKey: 'source',
    },
    {
      colName: 'Touched',
      value: 'contacted',
      isDisplayed: true,
      filterKey: 'contacted',
    },
    {
      colName: 'Stage',
      value: 'stage',
      isDisplayed: true,
      filterKey: 'stage',
    },
    {
      colName: 'Days in Stage',
      value: 'stage-days',
      isDisplayed: false,
      filterKey: 'daysOfStage',
    },
    {
      colName: 'Assigned To',
      value: 'assignedUser',
      isDisplayed: false,
      filterKey: 'assignedUser',
    },
    {
      colName: 'Tags',
      value: 'tags',
      isDisplayed: false,
      filterKey: 'tags',
    },
    {
      colName: 'Trailing 12 Month Volume',
      value: 'trailing',
      isDisplayed: false,
      filterKey: 'trailing',
    },
    {
      colName: 'Primary Phone Number',
      value: 'primary-phone',
      isDisplayed: false,
      filterKey: 'primaryPhone',
    },
    {
      colName: 'Secondary Phone Number',
      value: 'secondary-phone',
      isDisplayed: false,
      filterKey: 'secondaryPhone',
    },
    {
      colName: 'Date Created',
      value: 'date-created',
      isDisplayed: true,
      filterKey: 'dateCreated',
    },
    {
      colName: 'Fiscal Year - 2016',
      value: 'fiscal-year-2016',
      isDisplayed: false,
      filterKey: 'fiscalYear2016',
    },
    {
      colName: 'Fiscal Year - 2017',
      value: 'fiscal-year-2017',
      isDisplayed: false,
      filterKey: 'fiscalYear2017',
    },
    {
      colName: 'Fiscal Year - 2018',
      value: 'fiscal-year-2018',
      isDisplayed: false,
      filterKey: 'fiscalYear2018',
    },
    {
      colName: 'Title',
      value: 'title',
      isDisplayed: false,
      filterKey: 'title',
    },
  ];

  private _contacts = new BehaviorSubject<ReqContact[]>([]);
  private _contactSub: Subscription;
  private filterSubject: Subject<string> = new Subject();
  private snackBarRef: MatSnackBarRef<any> = null;

  @Input()
  set contacts(value: ReqContact[]) {
    this._contacts.next(value);
  }

  get contacts(): ReqContact[] {
    return this._contacts.getValue();
  }

  @Output() showFilters: EventEmitter<any> = new EventEmitter();
  @Output() dataChanged: EventEmitter<any> = new EventEmitter();
  @Output() saveSearch: EventEmitter<any> = new EventEmitter();
  @Output() updateSearch: EventEmitter<any> = new EventEmitter();
  @Output() shareSearch: EventEmitter<any> = new EventEmitter();
  @Output() cacheSort: EventEmitter<any> = new EventEmitter();
  @Output() cacheDisplayedColumns: EventEmitter<any> = new EventEmitter();
  @Output() cacheDisplayedFilterColumns: EventEmitter<any> = new EventEmitter();
  @Input() requisitionId: string;
  @Input() requisitionTitle: string;
  @Input() tenantId: string;
  @Input() appliedSearchFilter: SearchFilter;
  @Input() isFilteropened: boolean;
  @Input() isSortCached: boolean;
  @Input() cachedReqContactSort: any;
  @Input() contactsPage: Page = { page: 1, pageSize: 25 };
  @Input() isFilterApplied: boolean = false;
  @Input() totalSize: Number;
  show: boolean = true;
  validDaysOfStage: boolean = true;
  avatarUrls: any = {};

  isSelectContactsEnabled: boolean = false;
  isSelectCurrentPageEnabled: boolean = false;
  isSelectAllContactsEnabled: boolean = false;
  isSelectAllBulkEnabled: boolean = false;
  selectedContacts: ReqContact[];
  contactSelector = new SelectionModel<ReqContact>(true, []);
  toggleSelected: any = null;
  allReqContacts: ReqContact[] = [];
  deSelectedContacts: String[] = [];

  readonly contacted = [
    { label: '', value: '' },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  readonly operatorOptions = [
    { label: '', value: '' },
    { label: '>=', value: 'greater' },
    { label: '<', value: 'less' },
  ];
  startDate = new Date();
  // , 'city' , 'city-filter'
  @Input() displayedContactsColumns: string[] = ['select'];
  @Input() displayedFilterColumns: string[];
  contactsTableSource: MatTableDataSource<ReqContact>;

  @Input() activeFilters: ContactFilter = {};
  @Input() sortOptionContacts: SortOption;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('stageDateInput', { read: MatInput }) stageDateInput: MatInput;

  private createdDateRange$: BehaviorSubject<DateRange> = new BehaviorSubject({
    startDate: null,
    endDate: null,
  });
  private lastUpdatedAtRange$: BehaviorSubject<DateRange> = new BehaviorSubject({
    startDate: null,
    endDate: null,
  });

  @Input() createdDateRange: SatDatepickerRangeValue<Date>;
  @Input() lastUpdatedAtRange: SatDatepickerRangeValue<Date>;

  contactedFilterValue: string;
  isInternalTenantUserNotAdmin: boolean = null;

  get selectedContactIds(): string[] {
    return this.contactSelector.selected.map(({ contactId }) => contactId);
  }

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private contactService: ContactService,
    private userService: UserService,
    private imageCachingService: ImageCachingService,
    private reqService: RequisitionService
  ) {}

  ngOnInit() {
    // MOD-2411 - Internal users that are not Admins are no longer allowed to export contacts - everyone else can
    this.userService.isInternalTenantUserNotAdmin().then((result: boolean) => {
      this.isInternalTenantUserNotAdmin = result;
    });

    this.reDisplayContactColumns();
    this.contactsTableSource = new MatTableDataSource<ReqContact>([]);
    this.contactsTableSource.sort = this.sort;

    this.filterSubject.pipe(debounceTime(500)).subscribe((searchTextValue) => {
      if (this.validDaysOfStage || !this.activeFilters['daysOfStage']) {
        const page: Page = { page: 1, pageSize: this.contactsPage.pageSize };
        this.dataChanged.emit({ page: page, filters: this.activeFilters });
      } else {
        this.snackBar.open('Days Of Stage search key must start with operator (>=,<=,=) and a number', null, {
          duration: 3000,
        });
      }
    });

    // custom sorting
    this.contactsTableSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.firstName + ' ' + item.lastName;
        case 'source':
          return item.source;
        case 'contacted':
          return !item.contacted;
        case 'stage-days':
          return this.calcDaysInStage(item);
        // the next two lines missed in merge of this branch with master
        case 'city':
          return item.address.city;
        case 'state':
          return item.address.state;
        case 'lastUpdatedAt':
          return new Date(item.lastUpdatedAt);
        case 'primary-phone':
          return this.getPhone('primary', item);
        case 'secondary-phone':
          return this.getPhone('secondary', item);
        case 'date-created':
          return new Date(item.createdAt);
        default:
          return item[property];
      }
    };

    this._contactSub = this._contacts.subscribe((contacts) => {
      this.contactsTableSource.data = contacts;
      if (this.isSelectAllContactsEnabled) {
        this.selectCurrentPage();
      }
      if (this.isSortCached && this.sort) {
        this.sort.sort({
          id: this.cachedReqContactSort.id,
          start: this.cachedReqContactSort.start,
          disableClear: false,
        });
        this.isSortCached = false;
      }
      this.loadContactAvatars(contacts);
    });
  }

  sortData($event) {
    this.sortOptionContacts.field = this.sort.active;
    this.sortOptionContacts.direction = this.sort.direction;

    this.cacheSort.emit({
      id: this.sort.active,
      start: this.sort.direction,
    });

    this.dataChanged.emit({ page: this.contactsPage, filters: this.activeFilters });
  }

  async applyFilter(field: string, filterValue: any) {
    if (!filterValue) {
      this.clearFilter(field);
    } else {
      if (field === 'daysOfStage') {
        var res = this.activeFilters['daysOfStage'].match(/^((<=|>=|=)([0-9]+))|([0-9]+)$/);
        if (res == null || res.length < 1) {
          this.validDaysOfStage = false;
        } else {
          this.validDaysOfStage = true;
        }
      } else if (field === 'lastUpdatedAt') {
        this.activeFilters.lastUpdatedAt = filterValue.toISOString();
      } else if (field === 'dateCreated') {
        this.activeFilters.dateCreated = filterValue.toISOString();
      } else {
        this.activeFilters[field] = filterValue;
      }
    }

    if (Object.keys(this.activeFilters).length > 0) {
      this.isFilterApplied = true;
    } else {
      this.isFilterApplied = false;
      this.isFilteropened = false;
    }
    this.filterSubject.next(field);
  }

  onCreatedDateChange(dateRange: SatDatepickerRangeValue<Date>) {
    const startDate = dateRange ? moment(dateRange.begin).startOf('day').toISOString() : null;

    const endDate = dateRange ? moment(dateRange.end).endOf('day').toISOString() : null;

    this.activeFilters.dateCreatedEnd = endDate;
    this.activeFilters.dateCreatedStart = startDate;

    this.createdDateRange$.next({ startDate, endDate });
    this.applyFilter('dateCreated', '');
    // this.isFilterApplied = true;
  }

  clearCreatedDate() {
    delete this.activeFilters['dateCreatedStart'];
    delete this.activeFilters['dateCreatedEnd'];
    this.createdDateRange = {
      begin: null,
      end: null,
    };
    // this.filterSubject.next('dateCreated');
    this.applyFilter('dateCreated', '');
  }

  onLastUpdatedAtChange(dateRange: SatDatepickerRangeValue<Date>) {
    const startDate = dateRange ? moment(dateRange.begin).startOf('day').toISOString() : null;

    const endDate = dateRange ? moment(dateRange.end).endOf('day').toISOString() : null;

    this.activeFilters.lastUpdatedAtEnd = endDate;
    this.activeFilters.lastUpdatedAtStart = startDate;

    this.lastUpdatedAtRange$.next({ startDate, endDate });
    this.applyFilter('lastUpdatedAt', '');
    // this.isFilterApplied = true;
  }

  clearLastUpdatedAt() {
    delete this.activeFilters['lastUpdatedAtStart'];
    delete this.activeFilters['lastUpdatedAtEnd'];
    this.lastUpdatedAtRange = {
      begin: null,
      end: null,
    };
    // this.filterSubject.next('dateCreated');
    this.applyFilter('lastUpdatedAt', '');
  }

  getPhone(type: string, contact: ReqContact) {
    var phoneNum = '';
    if (contact.phones != null) {
      for (const phone of contact.phones) {
        if (phone.type === type) {
          phoneNum = phone.number;
          break;
        }
      }
    }

    return phoneNum;
  }

  calcDaysInStage(contact: ReqContact) {
    var days = '';
    var stageTime;
    if (contact.stageEnteredDate) {
      stageTime = new Date(contact.stageEnteredDate).getTime();
    } else if (contact.stage === 'prospects') {
      stageTime = new Date(contact.createdAt).getTime();
    } else {
      stageTime = new Date(contact.lastUpdatedAt).getTime();
    }

    let now = new Date().getTime();
    let diff = now - stageTime;
    days = '' + (Math.round(diff) / (24 * 3600 * 1000)).toFixed(0);
    return days;
  }

  getDateInStage(contact: ReqContact) {
    var stageTime;
    if (contact.stageEnteredDate) {
      stageTime = new Date(contact.stageEnteredDate).toISOString();
    } else if (contact.stage === 'prospects') {
      stageTime = new Date(contact.createdAt).toISOString();
    } else {
      stageTime = new Date(contact.lastUpdatedAt).toISOString();
    }
    return stageTime;
  }

  onDateChange(dateRange: SatDatepickerRangeValue<Date>) {
    const startDate = dateRange ? moment(dateRange.begin).startOf('day').toISOString() : null;

    const endDate = dateRange ? moment(dateRange.end).endOf('day').toISOString() : null;
    this.isFilterApplied = true;
    this.activeFilters['stageEnteredDateStart'] = startDate;
    this.activeFilters['stageEnteredDateEnd'] = endDate;
    this.activeFilters['stageDateRange'] = dateRange;
    this.filterSubject.next('stageEnteredDate');
  }

  clearDate() {
    this.clearFilter('stageDateRange');
    this.isFilterApplied = false;
    this.clearFilter('stageEnteredDateStart');
    this.clearFilter('stageEnteredDateEnd');
    this.filterSubject.next('stageEnteredDate');
  }

  getFiscal(year: number, contact: ReqContact) {
    var fiscalVolume;
    if (contact.statistics != null) {
      const fiscalYears = contact.statistics.filter((stat) => stat.period === 'YEAR');
      for (const fiscalYear of fiscalYears) {
        if (fiscalYear.endYear === year) {
          fiscalVolume = fiscalYear.volume;
          break;
        }
      }
    }

    return fiscalVolume;
  }

  getTrailing(contact: ReqContact) {
    var trailingVolume;
    if (contact.statistics != null) {
      const trailing = contact.statistics.filter((stat) => stat.period === 'TRAILING_12');
      if (trailing.length > 0) {
        trailingVolume = trailing[0].volume;
      }
    }

    return trailingVolume;
  }

  loadContactAvatars(contacts: ReqContact[]) {
    contacts.forEach(({ contactId, sourceId }) => {
      //get avatar for source user/tenant
      if (!this.avatarUrls[sourceId] && sourceId) {
        this.userService
          .getUserAvatarUrl(sourceId)
          .pipe(take(1))
          .subscribe(
            ({ data }) => {
              const url = data.getUserAvatarUrl?.url || data.getContactAvatarUrl?.url;
              this.avatarUrls[sourceId] = url || '';
              if (url) this.imageCachingService.cacheImageFromUrl(url, sourceId);
            },
            (error: unknown) => console.log('ContactsTabComponent :: error download user avatar', error)
          );
      }

      //get avatar for contact
      if (!this.avatarUrls[contactId]) {
        this.contactService
          .getContactAvatarUrl(contactId)
          .pipe(take(1))
          .subscribe(
            ({ data }) => {
              const url = data.getContactAvatarUrl?.url;
              this.avatarUrls[contactId] = url || '';
              if (url) this.imageCachingService.cacheImageFromUrl(url, contactId);
            },
            (error: unknown) => {
              console.log('ContactsTabComponent :: error download contact avatar', error);
            }
          );
      }
    });
  }

  onEditToggle(bool = false) {
    this.isSelectContactsEnabled = bool || !this.isSelectContactsEnabled;
  }

  onSelectorToggleChange(event: MatButtonToggleChange) {
    const toggle = event.source;
    if (toggle) {
      let group = toggle.buttonToggleGroup;
      if (event.value.some((item) => item == toggle.value)) {
        group.value = [toggle.value];
      }
      switch (toggle.value) {
        case 'selectContacts':
          this.isSelectContactsEnabled = true;
          this.isSelectAllContactsEnabled = false;
          break;
        case 'selectAllContacts':
          this.isSelectAllContactsEnabled = true;
          this.isSelectContactsEnabled = false;
          break;
      }
    } else {
      this.isSelectContactsEnabled = false;
      this.isSelectAllContactsEnabled = false;
    }
  }

  selectAll() {
    if (this.totalSize > 1000) return this.handleBulkContactSelect();
    if (!this.allReqContacts.length) {
      this.reqService
        .filterReqContacts(
          [this.requisitionId],
          { page: 1, pageSize: 1000 },
          this.activeFilters,
          this.sortOptionContacts
        )
        .valueChanges.subscribe(
          ({
            data: {
              filterReqContacts: { contacts },
            },
          }) => {
            this.allReqContacts = contacts;
            this.contactSelector.select(...contacts.filter((a) => !this.isContactSelected(a)));
          },
          (error: unknown) => {
            console.log('Contacts Tab Component : Error fetching Req Contacts == ', error);
          }
        );
    } else {
      this.contactSelector.select(...this.allReqContacts.filter((a) => !this.isContactSelected(a)));
    }
    this.isSelectAllContactsEnabled = true;
  }

  handleBulkContactSelect() {
    if (!this.isSelectAllBulkEnabled) {
      this.dialog
        .open(ConfirmBulkDialog, {
          data: {
            totalSize: this.totalSize,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (!!result) {
            this.isSelectAllBulkEnabled = true;
            this.isSelectAllContactsEnabled = true;
          }
        });
    }
  }

  deSelectAll() {
    this.contactSelector.deselect(...this.contactSelector.selected);
    this.isSelectAllContactsEnabled = false;
    this.isSelectCurrentPageEnabled = false;
    this.isSelectAllBulkEnabled = false;
  }

  selectCurrentPage() {
    this.contactSelector.select(...this.contactsTableSource.data.filter((a) => !this.isContactSelected(a)));
  }

  toggleContactSelection(contact: ReqContact) {
    if (this.selectedContactIds.includes(contact.contactId)) {
      const selectedContact = this.contactSelector.selected.filter(
        ({ contactId }) => contactId === contact.contactId
      )[0];
      this.contactSelector.deselect(selectedContact);
      this.isSelectCurrentPageEnabled = false;
      this.isSelectAllBulkEnabled = false;
      this.isSelectAllContactsEnabled = false;
    } else {
      this.contactSelector.select(contact);
      if (this.contactSelector.selected.length === this.totalSize) this.selectAll();
    }
  }

  handleCheckboxChange() {
    if (this.isSelectAllBulkEnabled || this.contactSelector.selected.length === this.totalSize) {
      this.deSelectAll();
    } else {
      this.selectAll();
    }
    console.log(this.contactSelector.selected.length);
  }

  async exportContacts() {
    const selectedContacts = this.contactSelector.selected;
    const contactIds = selectedContacts.map(({ contactId }) => contactId);
    if (this.isSelectAllBulkEnabled && this.totalSize > 1000) {
      {
        //need new export process here to get contactIds on the backend
        this.contactService.initContactExport(contactIds).subscribe(
          () => {
            if (this.snackBarRef) {
              this.snackBarRef.dismiss();
              this.snackBarRef = null;
            }

            this.snackBarRef = this.snackBar.open(
              'An email will be sent to you containing a link to your contact export file.',
              '',
              {
                duration: 5000,
              }
            );
          },
          (error: unknown) => {
            console.error('ContactsComponent: error requesting contacts export: ', error);
            if (this.snackBarRef) {
              this.snackBarRef.dismiss();
              this.snackBarRef = null;
            }

            this.snackBarRef = this.snackBar.open('An error occurred, please try again later.', '', {
              duration: 5000,
            });
          }
        );
      }
    } else {
      this.contactService
        .getMyContactsWithReqs(contactIds)
        .pipe(
          take(1),
          map((results) => results.data.getMyContacts)
        )
        .subscribe(
          (contactsWithReqs) => {
            this.contactService.exportContactsToCSV(contactsWithReqs);
          },
          (error: unknown) => {
            console.error('ContactsComponent: error getting contacts with reqs for export: ', error);
          }
        );
    }
  }

  handleEditContacts() {
    const selectedContacts = this.contactSelector.selected;
    const dialogRef = this.dialog.open(EditContactsDialog, {
      data: {
        selectedContacts,
        reqId: this.requisitionId,
        reqName: this.requisitionTitle,
        tenantId: this.tenantId,
        allSelectedBulk: this.isSelectAllBulkEnabled,
        totalSize: this.totalSize,
        activeFilters: this.activeFilters,
      },
      panelClass: 'new-activity-dialog',
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data)
        this.snackBar.open(`Successfully updated ${data} contacts!`, null, {
          duration: 3000,
        });
    });
  }

  isContactSelected(contact: ReqContact): boolean {
    return this.selectedContactIds.includes(contact.contactId);
  }

  clearFilter(field: string) {
    delete this.activeFilters[field];

    if (Object.keys(this.activeFilters).length > 0) {
      this.isFilterApplied = true;
    } else {
      this.isFilterApplied = false;
      this.isFilteropened = false;
    }
  }

  resetUnusedColumns() {
    let unDisplayedColumns = this.contactColumns.filter((column) => column.isDisplayed == false);
    unDisplayedColumns.forEach((column) => {
      delete this.activeFilters[column.value];
      this.isFilteropened = false;
    });
    var keys = Object.keys(this.activeFilters);
    if (keys && keys.length > 0) {
      this.isFilterApplied = true;
    } else {
      this.isFilterApplied = false;
    }
  }

  resetFilters() {
    this.activeFilters = {};
    this.isFilterApplied = false;
    this.isFilteropened = false;
  }

  reset() {
    this.resetFilters();
    this.dataChanged.emit({ page: this.contactsPage, filters: this.activeFilters });
  }

  goToContact(contact: ReqContact): void {
    // Navigate to single-contact
    this.router.navigate(['contacts', contact.contactId, this.requisitionId]);
  }

  onChange($event: Page) {
    const page: Page = {
      totalSize: $event.totalSize,
      page: $event.page,
      pageSize: $event.pageSize,
    };
    this.dataChanged.emit({ page: page, filters: this.activeFilters });
  }

  reDisplayContactColumns() {
    this.contactColumns.forEach((contactColumn) => {
      let displayedColumns = this.displayedContactsColumns.filter((col) => col === contactColumn.value);
      if (displayedColumns && displayedColumns.length > 0) {
        contactColumn.isDisplayed = true;
      } else {
        contactColumn.isDisplayed = false;
      }
    });
  }

  setContactColumns() {
    let displayedColumns = this.contactColumns.filter((column) => column.isDisplayed == true);
    this.displayedContactsColumns = ['select'];
    this.displayedContactsColumns.push(...displayedColumns.map((cd) => cd.value));
    this.displayedContactsColumns.push('filter');
    this.cacheDisplayedColumns.emit(this.displayedContactsColumns);
  }

  setFilterColumns() {
    let displayedColumns = this.contactColumns.filter((column) => column.isDisplayed == true);
    this.displayedFilterColumns = displayedColumns.map((cd) => cd.value + '-filter');
    this.displayedFilterColumns.push('filter-filter');
    this.cacheDisplayedFilterColumns.emit(this.displayedFilterColumns);
  }

  toggleColumnsSelector() {
    const columns = this.contactColumns;
    let dialogRef = this.dialog.open(DisplayColumnsDialogComponent, {
      panelClass: 'display-columns-dialog',
      data: {
        columns: columns,
      },
    });

    dialogRef.afterClosed().subscribe((result: Column[]) => {
      if (result) {
        console.log('ContactsComponent Closing Dialog after selecting columns ', result);
        this.contactColumns = result;
        this.setContactColumns();
        this.setFilterColumns();
        this.resetUnusedColumns();
        this.dataChanged.emit({ page: this.contactsPage, filters: this.activeFilters });
      }
    });
  }

  openSearchFilters() {
    this.showFilters.emit();
  }

  renderColumnsWithFilters() {
    this.contactColumns.forEach((column) => {
      let key = column.filterKey;
      if (this.activeFilters[key] != null) {
        column.isDisplayed = true;
      }
    });
    this.setContactColumns();
    this.setFilterColumns();
  }

  saveSearchFilters() {
    if (this.isFilteropened) {
      const data: ConfirmationDialogConfig = {
        title: 'Update Saved List',
        message: 'You are about to make changes to the selected list. Do you want to continue?',
        cancelButton: {
          label: 'Cancel',
          color: 'warn',
        },
        confirmButtons: [
          {
            label: 'Update',
            color: 'primary',
          },
          {
            label: 'Save As',
            color: 'primary',
          },
        ],
      };
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        panelClass: 'alert-dialog-component',
        data: data,
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'Update') {
          this.updateSearch.emit({ id: this.appliedSearchFilter.id, reqId: this.requisitionId });
        } else if (result === 'Save As') {
          this.saveFiltersDialog();
        }
      });
    } else {
      this.saveFiltersDialog();
    }
  }

  saveFiltersDialog() {
    const dialogRef = this.dialog.open(SaveFiltersDialogComponent, {});
    dialogRef.afterClosed().subscribe((filterInput) => {
      if (filterInput) {
        this.saveSearch.emit({ reqId: this.requisitionId, name: filterInput.name, filterCriteria: this.activeFilters });
      }
    });
  }

  getToolTipTags(tags): string {
    let displayOutput = '';

    // console.log("getToolTipTags tags", tags);

    tags.forEach((tag, index) => {
      if (index === 0) displayOutput = displayOutput + tag.name;
      else displayOutput = displayOutput + ', ' + tag.name;
    });

    return displayOutput;
  }

  ngAfterViewChecked() {
    this.contactsTableSource.sort = this.sort;
  }

  getAssignedUser(contact) {
    // console.log('getAssignedUser contact', contact);
    if (contact.assignedUser) return `${contact.assignedUser.firstName} ${contact.assignedUser.lastName}`;
    else return 'Not Assigned';
  }

  ngOnDestroy() {
    this._contactSub.unsubscribe();
  }
}
