<h1 mat-dialog-title>Add Pipeline</h1>
<mat-dialog-content (keydown)="onKeydown($event)">
  <form [formGroup]="createReqForm">
    <mat-form-field>
      <input matInput placeholder="Pipeline Name" data-testid="add-req-name" required [formControl]="nameFormControl" />
    </mat-form-field>

    <mat-form-field>
      <textarea
        matInput
        placeholder="Description"
        data-testid="add-req-desc"
        required
        [formControl]="descriptionFormControl"
      ></textarea>
    </mat-form-field>

    <mat-form-field class="half-width">
      <input
        type="text"
        placeholder="Tenant"
        aria-label="String"
        data-testid="add-req-tenant"
        matInput
        [formControl]="tenantFormControl"
        [matAutocomplete]="autoTenant"
        [(ngModel)]="userTenant"
        required
      />
      <mat-autocomplete
        autoActiveFirstOption
        #autoTenant="matAutocomplete"
        [displayWith]="displayTenant"
        (optionSelected)="tenantSelected($event.option.value)"
        (closed)="verifyTenantSelected()"
      >
        <mat-option *ngFor="let tenant of filteredTenants | async" [value]="tenant">
          {{ tenant.title }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="half-width">
      <input
        type="text"
        placeholder="Owner"
        aria-label="String"
        data-testid="add-req-owner"
        matInput
        [formControl]="ownerFormControl"
        [matAutocomplete]="autoOwner"
        required
      />
      <mat-autocomplete
        autoActiveFirstOption
        #autoOwner="matAutocomplete"
        [displayWith]="displayOwner"
        (closed)="verifyOwnerSelected()"
      >
        <mat-option *ngFor="let owner of filteredOwners | async" [value]="owner">
          {{ owner?.firstName + ' ' + owner?.lastName }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Assignees" [formControl]="assigneeFormControl" multiple>
        <mat-option *ngFor="let assignee of assignees" [value]="assignee">
          {{ assignee?.firstName + ' ' + assignee?.lastName }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <!-- <mat-form-field>
            <input matInput placeholder="Address Line 1" formControlName="line1">
        </mat-form-field>
        <mat-form-field class="half-width">
            <input matInput placeholder="Address Line 2" formControlName="line2">
        </mat-form-field>
        <mat-form-field class="half-width">
            <input matInput placeholder="City" formControlName="city">
        </mat-form-field>
        <mat-form-field class="half-width">
            <input matInput placeholder="State" formControlName="state">
        </mat-form-field>
        <mat-form-field class="half-width">
            <input matInput placeholder="Zip" formControlName="zip">
        </mat-form-field> -->
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="warn" [mat-dialog-close]="true">Cancel</button>
  <button mat-button color="primary" data-testid="add-req-form-submit" (click)="createReq()">Add</button>
</mat-dialog-actions>
