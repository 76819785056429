import { PermissionsOverride } from '../permissions';

export default {
  data: {
    loan_originator: {
      enabled: false
    },
    real_estate_agent: {
      enabled: false
    }
  },
  market_insights: {
    enabled: false,
  },
  pulse: {
    enabled: false
  }
} as PermissionsOverride;
