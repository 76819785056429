import { TemplateLiteralElement } from '@angular/compiler';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { arrow, autoUpdate, computePosition, flip, offset, shift } from '@floating-ui/dom';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from 'tailwind.config.js';
import { fromEvent, Observable, ReplaySubject, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { CdkListbox, CdkListboxModule, ListboxValueChangeEvent } from '@angular/cdk/listbox';
import { CdkMenu, CdkMenuModule, CdkMenuTrigger } from '@angular/cdk/menu';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ModelMatchMenuModule } from './betterMenu.component';
import { CdkConnectedOverlay, CdkOverlayOrigin, OverlayModule } from '@angular/cdk/overlay';
import { ModelMatchTooltipModule } from './betterTooltip.component';
import { ModelMatchSelectComponent } from './betterSelect.component';

const fullConfig = resolveConfig(tailwindConfig);

type HTMLInputType = 'email' | 'number' | 'password' | 'search' | 'tel' | 'url' | 'cur' | 'card' | 'text';

const defaultIcons = {
  email: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
  </svg>`,
  number: 'number',
  password: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
  </svg>`,
  search: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
  </svg>`,
  tel: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
  </svg>`,
  url: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
  </svg>`,
  cur: 'currency',
  card: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
  </svg>`,
  text: 'text',
};

const invalid_types = [
  'button',
  'checkbox',
  'color',
  'date',
  'datetime-local',
  'file',
  'hidden',
  'image',
  'month',
  'radio',
  'range',
  'reset',
  'submit',
  'time',
  'week',
];

const phone = {
  format(value: string): string {
    // remove all non-numeric characters
    // handle parentheses, dashes, spaces, and periods
    value = value.replace(/[^0-9]/g, '');
    value = value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    return value;
  },
};

function fromMutationObserver(target: Element, options: MutationObserverInit): Observable<MutationRecord[]> {
  return new Observable((subscriber) => {
    const observer = new MutationObserver((mutations) => {
      subscriber.next(mutations);
    });
    observer.observe(target, options);
    return () => observer.disconnect();
  });
}

@Directive({
  selector: 'input[mm-input]',
})
export class ModelMatchInputDirective implements OnInit, OnDestroy {
  private destroyed$ = new ReplaySubject<void>(1);

  @Output() changes = new EventEmitter<boolean>();

  @HostBinding('class') get classes(): string {
    return `
    focus_outline-none apperance-none w-full
    disabled_bg-slate-200 disabled_cursor-not-allowed
    `;
  }

  constructor(public el: ElementRef<HTMLInputElement>) {
    // listen for changes is the disabled attribute
    // and emit the changes to the parent component
    fromMutationObserver(this.el.nativeElement, {
      attributes: true,
      attributeFilter: ['disabled'],
    })
      .pipe(
        takeUntil(this.destroyed$),
        // @ts-ignore
        map((mutations) => mutations[0].target.disabled)
      )
      .subscribe((disabled) => {
        console.log('disabled', disabled);
        this.changes.emit(disabled);
      });
  }

  ngOnInit(): void {
    if (this.el.nativeElement.type === 'tel') {
      fromEvent(this.el.nativeElement, 'input')
        .pipe(
          takeUntil(this.destroyed$),
          map((e: any) => e.target.value),
          map((value) => phone.format(value)),
          tap((value) => (this.el.nativeElement.value = value))
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

@Directive({
  selector: 'label[mm-label]',
})
export class ModelMatchLabelDirective {
  @HostBinding('class') get classes(): string {
    return `
    font-medium pb-1 text-slate-800 text-sm
    `;
  }
}

@Directive({
  selector: '[mm-field-content]',
})
export class ModelMatchFormFieldContentDirective {}

@Component({
  selector: 'mm-form-field',
  template: `
    <ng-content select="[mm-label]"></ng-content>
    <div class="flex items-center space-x-2 flex-grow mm-form-field">
      <div
        [ngClass]="{
          'border-red-300 focus-within_border-red-500 focus-within_ring-2 focus-within_ring-red-300': validationControl
            ? validationControl?.invalid && validationControl?.touched
            : control?.invalid && control?.touched,
          'border-slate-300 focus-within_border-blue-500 focus-within_ring-2 focus-within_ring-blue-300':
            !(validationControl
              ? validationControl?.invalid && validationControl?.touched
              : control?.invalid && control?.touched),
          '!bg-slate-200 !cursor-not-allowed': disabledWithin,
          'bg-white cursor-pointer': !disabledWithin
        }"
        class="flex-grow py-2.5 space-x-2 shadow-sm px-3 flex items-center border rounded-lg text-slate-800 focus-within_text-slate-900 relative"
      >
        <ng-content select="[mm-input-prefix]"></ng-content>
        <div class="flex-grow pl-2">
          <ng-content select="input[mm-input]"></ng-content>
          <ng-content select="textarea"></ng-content>
          <ng-content select="mm-select"></ng-content>
        </div>
        <div class="flex-shrink-0">
          <!-- <p *ngIf="control.status === 'PENDING'">L</p> -->
          <svg
            [mmTooltip]="error"
            *ngIf="
              validationControl
                ? validationControl?.invalid && validationControl?.touched
                : control?.invalid && control?.touched
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5 text-red-500"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </svg>
          <svg
            [mmTooltip]="warn"
            [interactive]="interactive.warn"
            *ngIf="
              validationControl
                ? validationControl?.warnings && !validationControl?.invalid
                : control?.warnings && !control?.invalid
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5 text-yellow-500"
          >
            <path
              fill-rule="evenodd"
              d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <ng-content select="[mm-input-inner-suffix]"></ng-content>
      </div>
      <ng-content select="[mm-input-suffix]"></ng-content>
    </div>
  `,
  styleUrls: ['../nick_styles/nick.css'],
})
export class ModelMatchFormFieldComponent implements AfterViewInit, OnDestroy {
  @Input() control: FormControl & { warnings: any };
  @Input() validationControl: FormControl & { warnings: any };
  @Input() error: string | TemplateRef<HTMLElement>;
  @Input() warn: string | TemplateRef<HTMLElement>;
  @Input() interactive: { error: boolean; warn: boolean; hint: boolean } = {
    error: false,
    warn: false,
    hint: false,
  };
  @ContentChild(ModelMatchFormFieldContentDirective)
  content: ModelMatchFormFieldContentDirective;
  @ContentChild(ModelMatchInputDirective)
  input: ModelMatchInputDirective;

  private destroyed$ = new ReplaySubject<void>(1);

  public disabledWithin = false;

  constructor(
    public hostEl: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.input?.changes.pipe(takeUntil(this.destroyed$)).subscribe((disabled) => {
      console.log('disabled', disabled);
      this.disabledWithin = disabled;
      console.log('this.disabledWithin', this.disabledWithin);
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

@Component({
  selector: 'mm-selected-option',
  template: ' <ng-content></ng-content> ',
  styleUrls: ['../nick_styles/nick.css'],
})
export class ModelMatchSelectedOptionComponent {
  @Input() value: any;

  constructor(public hostEl: ElementRef) {}
}

@NgModule({
  declarations: [
    ModelMatchInputDirective,
    ModelMatchLabelDirective,
    ModelMatchFormFieldComponent,
    ModelMatchFormFieldContentDirective,
  ],
  exports: [
    ModelMatchInputDirective,
    ModelMatchLabelDirective,
    ModelMatchFormFieldComponent,
    ModelMatchFormFieldContentDirective,
  ],
  imports: [
    CommonModule,
    CdkListboxModule,
    ReactiveFormsModule,
    FormsModule,
    CdkMenuModule,
    ModelMatchMenuModule,
    OverlayModule,
    ModelMatchTooltipModule,
  ],
})
export class ModelMatchInputModule {}
