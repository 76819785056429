<section class="picker-editor-section" style="padding: 1em">
  <div *ngIf="showAdd">
    <h4 class="add-header">
      <button mat-button mat-mini-fab color="accent" (click)="onClickAdd(null)" class="add-button">
        <mat-icon>add</mat-icon>
      </button>
      Templates
    </h4>
  </div>

  <div class="picker">
    <!-- 
          (selectionChange)="onSelection($event)"
      [compareWith]="compareCategoryObjects"
      
     -->
    <mat-selection-list [multiple]="false" (ngModelChange)="onSelection($event)" [(ngModel)]="selectedOptions">
      <mat-list-option *ngFor="let smsTemplate of smsTemplates" [value]="smsTemplate">
        <mat-icon matListIcon class="muted">chat_bubble_outline</mat-icon>
        <div matLine>{{ smsTemplate.title }}</div>
        <!-- <p matLine>
      <span> {{message.subject}} </span>
    
    </p> -->
      </mat-list-option>
    </mat-selection-list>
  </div>
</section>
