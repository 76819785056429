<div mat-dialog-title>
  <button mat-icon-button class="button-close" (click)="onCloseClick()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="flex-container header-container" fxLayoutGap="1em" fxLayout="row" fxLayout.lt-lg="column">
    <div fxFlex="100%">
      <header class="dialog-header">
        <h3>Update GLOBAL Agent Info</h3>
      </header>

      <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
    </div>

    <div fxFlex fxLayoutAlign="start center" class="req-options">
      <!-- *ngIf="currentUploadStatus !== 4 && isImporting" -->
    </div>
  </div>
</div>

<mat-dialog-content>
  <h3>Agent Id: {{ data?.agent?.id }}</h3>
  <form [formGroup]="updateAgentForm">
    <mat-form-field style="display: block">
      <input matInput placeholder="Agent Name" required formControlName="agt_name" />
    </mat-form-field>

    <mat-form-field style="display: block">
      <input matInput placeholder="Email" formControlName="agt_email" />
    </mat-form-field>

    <mat-form-field style="display: block">
      <input matInput placeholder="Title" formControlName="title" />
    </mat-form-field>

    <mat-form-field class="half-width" style="display: block">
      <input matInput placeholder="Company" formControlName="company" />
    </mat-form-field>

    <mat-form-field style="display: block">
      <input matInput placeholder="Agent Phone" formControlName="agt_phone" />
    </mat-form-field>

    <mat-form-field style="display: block">
      <input matInput placeholder="Office Phone" formControlName="off_phone" />
    </mat-form-field>

    <mat-form-field style="display: block">
      <input matInput placeholder="Complete Address" formControlName="address" />
    </mat-form-field>

    <!-- Add option to mark as catch all to ignore -->
  </form>
</mat-dialog-content>

<div style="margin-bottom: 2em">
  <div fxFlex="100%">
    <header class="dialog-header">
      <button mat-button (click)="onCloseClick()" color="warn" style="margin-right: 20px" class="mat-elevation-z3">
        Cancel
      </button>

      <button mat-button (click)="onSave()" color="primary" style="margin-left: 20px" class="mat-elevation-z3">
        Save
      </button>
    </header>
  </div>
</div>
