import { DeepPermissionsDetails, Permissions } from './permissions';
/**The hardcoded detail information about each permission to help ease confusion about what each toggle does (can be directly consumed on the frontend).*/
export const permissionDetails: DeepPermissionsDetails<Permissions> = {
  session: {
    enabled: {
      display_name: 'Enabled',
      description: 'Turns off all functionality surrounding user sessions.',
    },
    multiple_sessions: {
      display_name: 'Multiple Sessions',
      description: 'Allows the user to have multiple sessions simultaneously.',
    },
  },
  cms: {
    enabled: {
      display_name: 'Enabled',
      description: 'Turn the entire CMS system off.',
    },
    create_requisitions: {
      display_name: 'Create Pipelines',
      description: 'Allows the user to create Pipelines.',
      isCompanySetting: true,
    },
    allow_imports: {
      display_name: 'Allow Imports',
      description: 'Allows the user to import contacts via a CSV.',
      isCompanySetting: true,
    },
    add_to_all_reqs: {
      display_name: 'Add To All Pipelines',
      description: 'Allows the Tenant Admin to add a user to all existing and future Pipelines.',
      isTenantAdminSetting: true,
    },
    white_label: {
      display_name: 'White Label',
      description: 'Shows the RadCRM logo over the Model Match logo in the sidebar.',
    },
    marketing_export: {
      display_name: 'Marketing Export',
      description: 'Allows the user to export all contacts.',
    },
    todos: {
      display_name: 'To-Dos',
      description: 'Limits viewing of the todo system.',
    },
    // Add to all reqs.
    contacts: {
      enabled: {
        display_name: 'Enabled',
        description: 'Turn all contact features off.',
      },
      allow_exports: {
        display_name: 'Allow Exports',
        description: 'Allow the user to export contacts out of Model Match to a CSV.',
      },
      create: {
        display_name: 'Create Contacts',
        description:
          'Allows the user to create contacts. Affects any type of creation ie. Add to Pipeline and the create contact page.',
      },
      delete: {
        display_name: 'Delete Contacts',
        description: 'Allows the user to delete contacts in the app.',
        isCompanySetting: true,
      },
      limit_contacts: {
        display_name: 'Limit Contacts',
        description: 'Limits the user to only Pipeline Contacts in the app.',
      },
      click_to_call: {
        display_name: 'Click To Call',
        description: 'Allows the user to initiate calls by clicking on contact phone numbers.',
      },
      nmls_id: {
        display_name: 'NMLS ID',
        description: 'Allows the user to view NMLS ID of contacts.',
      },
      premium_contacts: {
        enabled: {
          display_name: 'Enabled',
          description: 'Enables the use of premium contact functionality.',
        },
        export: {
          enabled: {
            display_name: 'Enabled',
            description:
              'Allows the user to export contacts using the premium export logic. Replaces buttons in the app with the premium export button.',
          },
          less: {
            display_name: 'Less Fields',
            description:
              "Gives less fields to the premium contact export. Excluded fields: 'trailing12Units' and 'trailing12Volume'",
          },
        },
      },
    },
  },
  data: {
    enabled: {
      display_name: 'Enabled',
      description: 'Gives access to data sources in Model Match.',
    },
    pdl_enrichment: {
      display_name: 'PDL Enrichment',
      description: 'Allows the user to enrich contacts using the PDL database.',
    },
    loan_originator: {
      enabled: {
        display_name: 'Enabled',
        description: 'Gives access to loan originator data in Model Match.',
      },
      raw_loan_transactions: {
        display_name: 'Raw Loan Transactions',
        description: 'Gives the user the ability to see each transaction inside of the LO dataset.',
      },
    },
    real_estate_agent: {
      enabled: {
        display_name: 'Enabled',
        description: 'Gives access to the real estate agent dataset in Model Match.',
      },
    },
    company_raw_transactions: {
      display_name: 'Company Raw Transactions',
      description: 'Gives access to company transaction dataset in Model Match.',
    },
    branch_raw_transactions: {
      display_name: 'Branch Raw Transactions',
      description: 'Gives access to the branch transaction dataset in Model Match.',
    },
  },
  market_insights: {
    enabled: {
      display_name: 'Enabled',
      description: 'Enables the Market Insights feature to be viewed and navigated to.',
    },
    ai_filter: {
      display_name: 'AI Filter ✨',
      description: 'Chat GPT input for adding filters to Market Insights.',
      experimental: true,
    },
    allow_imports_at_stage: {
      display_name: 'Allow Imports at Stage',
      description:
        'Allows the user to import a lead that is a duplicate at a specific stage or higher in Market Insights.',
      isCompanySetting: true,
    },
    allow_bulk_duplicate_imports: {
      display_name: 'Allow Bulk Duplicate Imports',
      description: 'Allows the user to import multiple duplicates at once in Market Insights.',
      isCompanySetting: true,
    },
    view_on_zillow: {
      display_name: 'View on Zillow',
      description: 'Allows the user to view properties on Zillow.',
    },
    export_transactions: {
      display_name: 'Export Transactions',
      description: 'Allows the user to export transactions from Market Insights.',
    },
    full_date_ranges: {
      display_name: 'Full Date Ranges',
      description: 'Allows the user to use all available date ranges to filter transactions',
    },
    debug_actions: {
      display_name: 'Debug Actions',
      description: 'Allows the user to view debug actions in Market Insights.',
    },
  },
  reporting_analytics: {
    enabled: {
      display_name: 'Enabled',
      description: 'Controls access to the Reporting and Analytics feature.',
    },
  },
  sms_chat: {
    enabled: {
      display_name: 'Enabled',
      description: 'Controls access to the SMS Chat feature.',
    },
    sequence: {
      display_name: 'Sequence',
      description: 'Allows the user to use the sequence feature in SMS chat.',
    },
  },
  integrations: {
    enabled: {
      display_name: 'Enabled',
      description: 'Controls access to integrations inside of Model Match.',
    },
    bomb_bomb: {
      display_name: 'Bomb Bomb',
      description: 'Enables the Bomb Bomb integration.',
    },
    bonzo: {
      display_name: 'Bonzo',
      description: 'Enables the Bonzo integration.',
    },
    google: {
      display_name: 'Google',
      description: 'Enables the Google integration.',
    },
    microsoft: {
      display_name: 'Microsoft',
      description: 'Enables the Microsoft integration.',
    },
    total_expert: {
      enabled: {
        display_name: 'Enabled',
        description: 'Enables the Total Expert integration.',
      },
      export: {
        display_name: 'Export',
        description: 'Allows the user to send contacts to Total Expert.',
      },
    },
    zapier: {
      display_name: 'Zapier',
      description: 'Enables the Zapier integration.',
    },
    hubspot: {
      display_name: 'HubSpot',
      description: 'Enables the HubSpot integration.',
    },
    salesforce: {
      display_name: 'SalesForce',
      description: 'Enables the SalesForce integration.',
    },
  },
  playbooks: {
    enabled: {
      display_name: 'Enabled',
      description: 'Enables playbooks for the tenant.',
    },
  },
  pulse: {
    enabled: {
      display_name: 'Enabled',
      description: 'Controls access to the Pulse feature.',
    },
    title_company: {
      display_name: 'Title Company',
      description: 'Allows extra features in pulse if this user works for a title company.',
    },
  },
  compass: {
    enabled: {
      display_name: 'Enabled',
      description: 'Controls access to the Compass Alert feature.',
      experimental: true,
    },
    title_company: {
      display_name: 'Title Company Alerts',
      description: 'Allows the user to get alerts for Title Co.',
    },
  },
  team_chat: {
    enabled: {
      display_name: 'Enabled',
      description: 'Controls access to the Team Chat feature.',
      experimental: true,
    },
  },
  trust_hub: {
    enabled: {
      display_name: 'Enabled',
      description: 'Controls access to the Team Chat feature.',
      experimental: true,
    },
    sms: {
      display_name: 'SMS app to person campaign',
      description: 'compliance portal for starting sms campaigns',
    }
  },
  debug: {
    enabled: {
      display_name: 'Enabled',
      description: "Enables console logs on the user's computer. Developer permissions to help with debugging users.",
    },
    permissions: {
      display_name: 'Permissions',
      description: 'Show additional debug information for the permissions system.',
    },
    tail_logs: {
      display_name: 'Tail Logs',
      description:
        'Turns on log streaming to the backend from the client computer. This must be on before you can tail the logs of a user. Warning! When this is on, logs will be streamed into cloudwatch even if not actively tailing.',
    },
  },
};
