import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Input, OnInit, Directive, ElementRef, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { Component, EventEmitter, NgModule, Output, ViewChild } from '@angular/core';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from 'tailwind.config.js';
import { PopperModule } from 'app/market-insight/map/components/tooltip.directive';
const fullConfig = resolveConfig(tailwindConfig);

@Component({
  selector: 'mm-checkbox',
  template: `
    <div [appPopper] [target]="target" [arrow]="arrow" class="relative">
      <div>
        <div trigger class="relative z-10" (click)="!disabled && this.onChange.emit($event)">
          <input [disabled]="disabled" type="checkbox" [value]="value" name="checkbox" />
          <div #checkbox [ngClass]="disabled ? disabledClass : class">
            <svg
              class="w-full h-full text-white checkbox"
              *ngIf="value === 1"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="3"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
            </svg>
            <svg
              class="w-full h-full text-white checkbox"
              *ngIf="value === 2"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 16 16"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M2 7.75A.75.75 0 0 1 2.75 7h10a.75.75 0 0 1 0 1.5h-10A.75.75 0 0 1 2 7.75z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div #target class="popper group">
        <div
          *ngIf="disabled && disabledReason"
          [ngClass]="'bg-slate-800 text-white'"
          class="relative rounded-md p-1 border border-slate-200"
        >
          <div class="relative z-50 w-full flex">
            <span target class="w-36 text-center text-sm">{{ disabledReason }}</span>
          </div>
        </div>
        <span #arrow [ngClass]="disabled && disabledReason ? arrowClassName : ''"></span>
      </div>
    </div>
  `,
  styleUrls: ['../nick_styles/nick.css'],
  styles: [
    `
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
    `,
  ],
})
export class CheckboxComponent implements AfterViewInit, OnChanges {
  @Input() backgroundColor: string = fullConfig.theme.colors['blue'][500];
  private _disabled: boolean = false;
  @Input() set disabled(value: boolean) {
    this._disabled = this.canBeDisabled() ? value : false;
  }
  get disabled(): boolean {
    return this._disabled;
  }
  @Input() disabledReason: string;
  @Input() size: string = '16px';
  @Input() class: string = `
    rounded-md flex items-center justify-center 
    shadow border border-slate-300 cursor-pointer
    transform transition-150 transition-transform
    active_scale-95 hover_scale-105 ease-in-out
  `;
  public arrowClassName: string = `
    bg-slate-800
    popper__arrow 
    w-3 h-3 z-10
    popper-top_-bottom-1.5 
    popper-top_border-r 
    popper-top_border-b 
    popper-top_rounded-br
    popper-bottom_-top-1.5 
    popper-bottom_border-l 
    popper-bottom_border-t 
    popper-bottom_rounded-tl
    popper-bottom_ml-3
    popper-left_border-r
    popper-left_border-t
    popper-left_rounded-tr 
    popper-left_-right-1.5 
    popper-right_-left-1.5
    popper-right_border-l 
    popper-right_border-b
    popper-right_rounded-bl
    border-slate-200 
  `;
  public disabledClass: string = `
    rounded-md flex items-center justify-center
    shadow border border-slate-300 cursor-not-allowed
  `;
  @Input() tristate: boolean = false;
  @Input() value: 0 | 1 | 2 = 0;
  @ViewChild('checkbox') checkbox: ElementRef;
  @ViewChild('check') check: ElementRef;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onChange = new EventEmitter<any>();
  private sizeOptions = {
    xs: '16px',
    sm: '20px',
    md: '24px',
    lg: '28px',
    xl: '32px',
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.update();
    }
  }

  ngAfterViewInit(): void {
    this.update();
  }

  update() {
    if (Object.keys(this.sizeOptions).includes(this.size)) this.size = this.sizeOptions[this.size];
    if (this.backgroundColor === 'dark' || this.backgroundColor === 'blue')
      this.backgroundColor =
        this.backgroundColor === 'dark' ? fullConfig.theme.colors['slate'][800] : fullConfig.theme.colors['blue'][500];
    if (this.checkbox?.nativeElement) {
      this.checkbox.nativeElement.style.width = this.size;
      this.checkbox.nativeElement.style.height = this.size;
      this.checkbox.nativeElement.style.backgroundColor = this.value > 0 ? this.backgroundColor : 'transparent';
    }
  }

  private canBeDisabled(): boolean {
    // Prevent disabling if the checkbox is selected
    return this.value === 0;
  }
}

@NgModule({
  declarations: [CheckboxComponent],
  imports: [CommonModule, PopperModule],
  exports: [CheckboxComponent],
})
export class CheckboxModule {}
