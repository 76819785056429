<!-- <input type="file" (change)="fileChangeEvent($event)" /> -->
<input #documentInput (change)="fileChangeEvent($event)" type="file" hidden />
<button class="mat-raised-button mat-primary-light" (click)="documentInput.click()">Select File</button>
<button class="mat-raised-button mat-primary-light" (click)="useImageUrl = !useImageUrl">Image URL</button>
<span id="optionsGroup" style="margin-left: 30px">
  <button class="mat-raised-button" (click)="rotateLeft()">Rotate left</button>
  <button class="mat-raised-button" (click)="rotateRight()">Rotate right</button>
  <button class="mat-raised-button" (click)="flipHorizontal()">Flip horizontal</button>
  <button class="mat-raised-button" (click)="flipVertical()">Flip vertical</button>
  <button *ngIf="croppedImage" class="mat-raised-button mat-primary-light" (click)="handleReset()">Reset</button>
</span>
<form [hidden]="!useImageUrl" class="imgUrl" style="margin-top: 20px">
  <mat-form-field class="full-width" style="display: block">
    <mat-label>Enter an image url</mat-label>
    <input matInput #imageUrlInput class="form-control" />
    <mat-error *ngIf="true"> Please enter a valid image url </mat-error>
    <mat-error *ngIf="true"> Email is <strong>required</strong> </mat-error>
  </mat-form-field>
</form>
<div id="imgCont">
  <div *ngIf="imageChangedEvent" id="cropperDiv">
    <h4 style="text-align: center">Your Image</h4>
    <image-cropper
      style="height: 60vh"
      [maintainAspectRatio]="true"
      [imageChangedEvent]="imageChangedEvent"
      [resizeToWidth]="360"
      [cropperMinWidth]="128"
      [aspectRatio]="3 / 3"
      format="png"
      [roundCropper]="true"
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="loadImageFailed()"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      [imageURL]="imageUrl || ''"
    ></image-cropper>
  </div>
  <div *ngIf="!imageChangedEvent" id="image-cropper-placehold">
    <h4 style="text-align: center; justify-content: center" class="mat-body-2">No Image Selected</h4>
  </div>
  <div id="imgDiv" *ngIf="imageChangedEvent" style="width: 500px">
    <h4 *ngIf="croppedImage" style="text-align: center">Preview</h4>
    <img [src]="croppedImage" style="margin-left: 10%; width: 80%; border-radius: 50%" />
  </div>
</div>
<button *ngIf="croppedImage" style="float: right" class="mat-raised-button mat-primary-light" (click)="handleUpload()">
  Done
</button>
