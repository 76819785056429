import {
  Component,
  OnInit,
  Input,
  AfterViewChecked,
  AfterViewInit,
  ElementRef,
  ViewChild,
  ViewChildren,
  QueryList,
  SimpleChanges,
} from '@angular/core';

import { SMSService } from '../../services/sms.service';
import { UserService } from '../../services/user.service';
import { ImageCachingService } from '../../services/imagecaching.service';
import { take } from 'rxjs/operators';
import { MessageLog } from '../../shared/model/sms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MMSMedia } from '../../shared/model/user';
import { SMSServiceNeo } from '../../services/sms.appsync.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IntegrationsService } from '../../services/integrations.service';
import { saveAs } from 'file-saver';
import { differenceInMinutes, parseISO } from 'date-fns';
import { HistoryInput, ActivityType } from '../../shared/model/activity';
import { TodoService } from '../../services/todo.service';
import { ReportingService } from '../../services/reporting.service';
import { NotificationsService } from '../../services/notifications.service';

export interface MessageObj {
  id?: string;
  message?: string;
  desc?: string;
  mType: number;
  timestamp?: string;
}
@Component({
  selector: 'mm-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
})
export class ConversationComponent implements OnInit, AfterViewInit {
  // @ViewChild('scrollframe', { static: false }) scrollFrame: MatSelect;
  // https://github.com/angular/components/issues/3922 - to get access to mat-list
  @ViewChild('scrollframe', { read: ElementRef }) private scrollFrame: ElementRef;
  @ViewChildren('item') itemElements: QueryList<any>;

  constructor(
    private smsService: SMSService,
    private userService: UserService,
    private imageCachingService: ImageCachingService,
    private snackBar: MatSnackBar,
    private smsServiceNeo: SMSServiceNeo,
    private httpClient: HttpClient,
    private integrationsService: IntegrationsService,
    private todoService: TodoService,
    private reportingService: ReportingService,
    private notificationsService: NotificationsService
  ) {}

  private itemContainer: any;
  private scrollContainer: any;
  private items = [];
  private isNearBottom = true;

  @Input() assignedNumber;
  @Input() moObj; // ConversationTarget
  @Input() SAC: boolean = false; // Stand Alone Complex

  messageHistory: MessageLog[] = [];
  messageToSend: string = '';
  mmsMediaToSend: MMSMedia[] = [];
  lastRequest: string;
  firstRequest: string;
  isLoadingOlderMessages: boolean = false;
  userAvatarUrl: string = '';
  userId: string;
  userFirstName: string;
  userLastName: string;
  updatedMessageLogSubscription;
  initialLoadComplete: boolean = false;
  topThreshold = 150;
  sending: boolean = false;
  lastDisplayedTimeStamp: string;
  firstSMSMessage: boolean = false;
  // Disable input if no number is assigned.
  disabled: boolean = false;

  // Emoji stuff
  message = '';
  showEmojiPicker = false;
  showMMSMediaPicker = false;
  showSMSTemplatePicker = false;
  sets = ['native', 'google', 'twitter', 'facebook', 'emojione', 'apple', 'messenger'];
  set = 'twitter';

  ngOnInit(): void {
    // this.messageHistory.push({ id: '1', mType: 1, message: 'Hi Henry!!' });
    // this.messageHistory.push({ id: '2', mType: 1, message: 'How can I help you today?' });

    // this.messageHistory.push({ id: '3', mType: 2, message: 'Hi Bill, nice to meet you!' });
    // this.messageHistory.push({ id: '4', mType: 2, message: `Hope you're doing fine` });

    console.log(`ConversationComponent assignedNumber: ${this.assignedNumber} SAC: ${this.SAC}`);

    if (this.assignedNumber) {
      if (this.moObj?.contactNumber && this.assignedNumber?.friendlyName) {
        this.markAsRead(this.assignedNumber?.friendlyName, this.moObj.contactNumber);

        this.getMoreMessages('15', true);

        this.checkIfFirstMessage();
      }

      this.userService
        .getAuthenticatedUserId()
        .pipe(take(1))
        .subscribe(
          (userId: string) => {
            this.userId = userId;

            // User Id required for subscription
            this.subScribeToMessages();

            this.userService
              .getUserAvatarUrl(this.userId)
              .pipe(take(1))
              .subscribe(
                ({ data }) => {
                  const url = data.getUserAvatarUrl?.url || data.getContactAvatarUrl?.url;
                  this.userAvatarUrl = url || '';
                  if (url) this.imageCachingService.cacheImageFromUrl(url, this.userId);
                },
                (error) => console.log('error download user avatar', error)
              );

            this.userService
              .getUserInfo(this.userId)
              .pipe(take(1))
              .subscribe(
                ({ data }) => {
                  this.userFirstName = data.getUserInfo.firstName;
                  this.userLastName = data.getUserInfo.lastName;
                },
                (error) => console.log('error getting user info', error)
              );
          },
          (error) => {
            console.log('LandingPageComponent: Error getting authenticated userId -> ' + error.message);
          }
        );
    } else {
      this.loadAssignedNumber();
    }
  }

  ngAfterViewInit() {
    this.scrollContainer = this.scrollFrame.nativeElement;
    this.itemElements.changes.subscribe((_) => this.onItemElementsChanged());
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName) && Object.keys(changes).length === 1) {
        switch (propName) {
          case 'moObj': {
            if (changes.moObj.currentValue?.contactNumber !== changes.moObj.previousValue?.contactNumber)
              // Clear message history
              this.messageHistory = [];

            if (this.assignedNumber) {
              // Clear looked-up so far
              this.lastRequest = null;
              this.firstRequest = null;
              this.initialLoadComplete = false;

              this.markAsRead(this.assignedNumber?.friendlyName, this.moObj.contactNumber);

              this.getMoreMessages('15', true);

              this.subScribeToMessages();

              this.checkIfFirstMessage();
            }

            break;
          }
        }
      }
    }
  }

  private onItemElementsChanged(): void {
    if (this.isNearBottom) {
      this.scrollToBottom();
    }
  }

  private scrollToBottom(): void {
    // Do it now for responsiveness
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });

    for (let x = 1; x < 6; x++) {
      setTimeout(() => {
        // this.scrollContainer.nativeElement.scrollTop = whatever;

        // Since directly messing with native elements causes virtual dom delays
        // Do it again after delay for completeness
        this.scrollContainer.scroll({
          top: this.scrollContainer.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }, x * 200);
    }
  }

  private scrollOnLoadMore(num): void {
    this.scrollContainer.scroll({
      top: this.topThreshold + 10, // 56 * num,
      left: 0,
      behavior: 'smooth',
    });
  }

  private isUserNearBottom(): boolean {
    const threshold = 150;
    const position = this.scrollContainer.scrollTop + this.scrollContainer.offsetHeight;
    const height = this.scrollContainer.scrollHeight;

    return position > height - threshold;
  }

  private isUserNearTop(): boolean {
    return this.scrollContainer.scrollTop < this.topThreshold;
  }

  scrolled(event: any): void {
    this.isNearBottom = this.isUserNearBottom();

    if (this.isUserNearTop() && this.initialLoadComplete) {
      this.getMoreMessages('15');
    }
  }

  getMoreMessages(size: string, scrollToBottomFlag: boolean = false) {
    if (!size) size = '5';

    if (this.isLoadingOlderMessages) return;

    this.isLoadingOlderMessages = true;

    this.smsService
      .listMessageConversationalHistory(
        this.moObj?.contactNumber,
        this.assignedNumber.phoneNumber,
        size,
        this.lastRequest,
        '0'
      )
      .pipe(take(1))
      .subscribe(
        (results: any) => {
          let resultsParsed = JSON.parse(results.data.listMessageConversationalHistory);

          if (resultsParsed.length) {
            this.lastRequest = resultsParsed[resultsParsed.length - 1].createdDate;

            if (!this.firstRequest) this.firstRequest = resultsParsed[0].createdDate;
          }

          // Reset for each section
          this.lastDisplayedTimeStamp = '';

          // Verfiy messages are not already in local array
          // this.messageHistory.unshift(...resultsParsed.reverse());
          // resultsParsed = resultsParsed.reverse();
          resultsParsed.forEach((ele) => {
            ele.displayTimeDate = this.getLastDisplayedTimeStamp(ele);

            if (this.messageHistory.findIndex((arr) => arr.id === ele.id) < 0) {
              // Fix any expired images
              if (ele.mType === 1)
                ele.mediaUrl?.map((each) => {
                  // Erase old default thumbnail
                  each.thumbUrl = '';

                  this.smsServiceNeo
                    .GetMMSMediaUrl(each.id, '-thumb')
                    .then((result: any) => {
                      // Replace with refreshed thumbnail
                      each.thumbUrl = result.data.getMMSMediaUrl;
                    })
                    .catch((err) => {});
                });

              this.messageHistory.unshift(ele);
            }
          });

          this.isLoadingOlderMessages = false;

          setTimeout(() => {
            // Since directly messing with native elements causes virtual dom delays
            // Set it after delay for completeness
            this.initialLoadComplete = true;
          }, 1000);

          if (scrollToBottomFlag) {
            // this.scrollToBottom();
          } else {
            if (resultsParsed.length) this.scrollOnLoadMore(resultsParsed.length);
          }
        },
        (error) => {
          console.log('getMoreMessages: There was an error getting messages ', error);
          this.isLoadingOlderMessages = false;
        }
      );
  }

  getNewerMessages(size: string) {
    if (!size) size = '5';

    if (this.isLoadingOlderMessages) return;

    // Wait until history loaded at least once
    // if (!this.firstRequest) return;

    this.isLoadingOlderMessages = true;

    this.smsService
      .listMessageConversationalHistory(
        this.moObj?.contactNumber,
        this.assignedNumber.phoneNumber,
        size,
        this.firstRequest,
        '1'
      )
      .pipe(take(1))
      .subscribe(
        (results: any) => {
          let resultsParsed = JSON.parse(results.data.listMessageConversationalHistory);

          if (resultsParsed.length) this.firstRequest = resultsParsed[resultsParsed.length - 1].createdDate;

          // Verfiy messages are not already in local array
          // this.messageHistory.push(...resultsParsed.reverse());
          // resultsParsed = resultsParsed.reverse();
          resultsParsed.forEach((ele) => {
            if (this.messageHistory.findIndex((arr) => arr.id === ele.id) < 0) {
              this.messageHistory.push(ele);
            }
          });

          this.isLoadingOlderMessages = false;

          this.scrollToBottom();
        },
        (error) => {
          console.log('getMoreMessages: There was an error getting messages ', error);
          this.isLoadingOlderMessages = false;
        }
      );
  }

  sendSMS() {
    if (this.disabled) {
      this.openErrorMessage();
      return;
    }
    this.sending = true;
    this.showEmojiPicker = false;
    this.showMMSMediaPicker = false;
    this.showSMSTemplatePicker = false;

    if (this.assignedNumber) {
      this.smsService
        .sendSMS(
          this.moObj?.contactNumber,
          this.assignedNumber.phoneNumber,
          this.messageToSend,
          this.moObj?.contact,
          null,
          null,
          null,
          this.mmsMediaToSend,
          this.moObj?.reqId,
          null,
          null
        )
        .pipe(take(1))
        .subscribe(
          (provisioned: any) => {
            let result = JSON.parse(provisioned.data.sendSMS);

            //this.messageHistory.push({ id: '5', mType: 1, message: this.messageToSend });
            this.messageHistory.push(result);
            this.messageToSend = '';
            this.mmsMediaToSend = [];

            this.getNewerMessages(null);

            this.sending = false;

            if (this.firstSMSMessage) {
              this.dispoSMSInit();
            }
          },
          (error) => {
            console.log('sendSMS: There was an error sending message ', error);

            if (error.graphQLErrors) {
              error.graphQLErrors.forEach((e) => {
                this.snackBar.open('There was an error sending message. ' + e.message, null, {
                  duration: 3000,
                });
              });
            }

            this.sending = false;
          }
        );
    }

    this.scrollToBottom();
  }

  toggleEmojiPicker(event) {
    console.log('toggleEmojiPicker');
    event.stopPropagation();

    // this.snackBar.open(
    //   `For Windows users simply press the Windows key and the period ( . )button \n
    // On Mac, you can add emoji to documents and text fields by using the keyboard shortcut Control + Command + Space`,
    //   null,
    //   {
    //     duration: 13000,
    //     panelClass: ['success-snackbar'],
    //   }
    // );

    this.showSMSTemplatePicker = false;
    this.showEmojiPicker = !this.showEmojiPicker;
    this.showMMSMediaPicker = false;
  }

  toggleMMSMediaPicker(event) {
    event.stopPropagation();
    this.showSMSTemplatePicker = false;
    this.showMMSMediaPicker = !this.showMMSMediaPicker;
    this.showEmojiPicker = false;
  }

  toggleSMSTemplatePicker(event) {
    event.stopPropagation();
    this.showMMSMediaPicker = false;
    this.showEmojiPicker = false;
    this.showSMSTemplatePicker = !this.showSMSTemplatePicker;
  }

  addEmoji(event) {
    const { messageToSend } = this;

    const text = `${messageToSend}${event.emoji.native}`;

    this.messageToSend = text;
    // this.showEmojiPicker = false;
  }

  onFocus() {
    this.showEmojiPicker = false;
    this.showMMSMediaPicker = false;
  }
  onBlur() {}

  mediaSelectedEvent(event: MMSMedia) {
    if (event) {
      this.mmsMediaToSend.push(event);
      this.showEmojiPicker = false;
      this.showMMSMediaPicker = false;
    }
  }

  onDeleteTagItem(event, mmsIndex) {
    this.mmsMediaToSend.splice(mmsIndex, 1);
  }

  subScribeToMessages() {
    if (this.updatedMessageLogSubscription) {
      this.updatedMessageLogSubscription.unsubscribe();
      this.updatedMessageLogSubscription = null;
    }

    this.updatedMessageLogSubscription = this.smsServiceNeo
      .updatedMessageLog(this.userId, this.moObj?.contactNumber, this.assignedNumber?.phoneNumber)
      .subscribe(
        (result: any) => {
          let resultsParsedData = JSON.parse(result.data.updatedMessageLog.data);

          if (this.messageHistory.findIndex((arr) => arr.id === resultsParsedData.id) < 0) {
            this.messageHistory.push(resultsParsedData);
          }
        },
        (error) => {
          console.log('updatedMessageLog Convo: There was an error sending message ', error);
        }
      );
  }

  onDownloadItem(imgUrl, imgName, id, mType) {
    // const imgUrl = img.src;

    if (!imgName) imgName = imgUrl.substring(imgUrl.lastIndexOf('/') + 1);

    // mode: no-cors, *cors, same-origin
    // twilio s3 does not like cors
    if (mType == 2) {
      // Bandaid
      // window.open("imgUrl")

      // Cross domain image retrieval - have to open in new window - browser security
      let saveDownload = document.createElement('a');
      saveDownload.href = imgUrl;
      saveDownload.download = 'output.png';
      saveDownload.target = '_blank';
      document.body.appendChild(saveDownload);
      saveDownload.click();
      document.body.removeChild(saveDownload);

      // // Stupid twilio Cors - no headers
      // // S3 bucket ultimately but after multiple redirects
      // fetch(imgUrl, { referrer: 'https://api.twilio.com', mode: 'same-origin', headers: { Origin: '', Referer: '' } })
      //   .then((res) => {

      //     return res.blob();
      //   })
      //   .then((blob) => {
      //     saveAs(blob, imgName);
      //   });
    } else {
      let currentUrl = '';

      this.smsServiceNeo
        .GetMMSMediaUrl(id, '')
        .then((result: any) => {
          // Replace with refreshed thumbnail
          currentUrl = result.data.getMMSMediaUrl;

          // saveAs(currentUrl, imgName);

          fetch(currentUrl)
            .then((res) => res.blob())
            .then((blob) => {
              saveAs(blob, imgName);
            });
        })
        .catch((err) => {});
    }
  }

  markAsRead(agentNumber, contactNumber) {
    this.smsServiceNeo
      .updateMessageRead(agentNumber, contactNumber)
      .then((result: any) => {})
      .catch((error) => {
        console.log('markAsRead Convo: There was an error updating active message ', error);
      });
  }

  getLastDisplayedTimeStamp(message) {
    let retVal = '';

    let messageDate = new Date(message.createdDate);

    if (message.createdDate) {
      if (this.lastDisplayedTimeStamp) {
        let lastDisplayDate = new Date(this.lastDisplayedTimeStamp);

        if (differenceInMinutes(lastDisplayDate, messageDate) > 240) {
          retVal = message.createdDate;
          // message.createdDate +
          // ' - ' +
          // differenceInMinutes(lastDisplayDate, messageDate) +
          // ' - ' +
          // JSON.stringify(this.lastDisplayedTimeStamp);

          this.lastDisplayedTimeStamp = message.createdDate;
        } else {
          // retVal =
          //   'not - ' +
          //   JSON.stringify(message.createdDate) +
          //   ' - ' +
          //   JSON.stringify(this.lastDisplayedTimeStamp) +
          //   ' = ' +
          //   differenceInMinutes(lastDisplayDate, messageDate);
        }
      } else {
        this.lastDisplayedTimeStamp = message.createdDate;
        retVal = message.createdDate;
      }
    }

    return retVal;
  }

  checkIfFirstMessage() {
    this.firstSMSMessage = false;

    if (this.moObj.contact?.id)
      this.reportingService
        .getReportingCounts(
          ['history'],
          null,
          '',
          '',
          JSON.stringify([{ completedDate: { order: 'desc' } }]),
          '1',
          '',
          '',
          '',
          [],
          ['currentUser'],
          [
            { field: 'type.keyword', value: [ActivityType.SMS], qualifier: 'match', bool: 'AND' },
            { field: 'contacts.id.keyword', value: [this.moObj.contact.id], qualifier: 'match', bool: 'AND' },
            { field: 'analytics.disposition.keyword', value: ['Initial'], qualifier: 'match', bool: 'AND' },
          ],
          ''
        )
        .pipe(take(1))
        .subscribe(
          (result) => {
            // console.log('verfifyCallLogsAreComplete getReportingCounts result ', result);

            // parse results as JSON
            let queryResults = JSON.parse(result.data.getReportingCounts);

            // console.log('getReportingCounts queryResults parsed ', queryResults);

            // If no results found - no SMS ever made
            if (queryResults.hits.hits.length === 0) {
              this.firstSMSMessage = true;
              return;
            }

            // If 1 results found as expected
            if (queryResults.hits.hits.length === 1) {
              // Found a last record

              this.firstSMSMessage = false;
              return;
            } else {
              this.firstSMSMessage = false;
              return;
            }
          },
          (error) => {
            console.log('verfifyCallLogsAreComplete getReportingCounts Error Getting User Todo Info. ', error);

            this.firstSMSMessage = false;
          }
        );
  }

  dispoSMSInit() {
    // reset to false if you get here
    this.firstSMSMessage = false;

    let input: HistoryInput = {
      type: ActivityType.SMS,
      note: `SMS - First Message Sent`,
      contactId: this.moObj.contact.id,
      reqId: this.moObj.reqId,
      isPublic: true,
      analytics: JSON.stringify({
        disposition: 'Initial',
        agentPhone: this.assignedNumber.phoneNumber,
        contactPhone: this.moObj?.contactNumber,
        contactName: `${this.moObj.contact?.firstName} ${this.moObj.contact?.lastName}`,
      }),
    };

    this.todoService.addHistory(input).subscribe(
      (result) => {},
      (error) => {
        this.snackBar.open('An error occurred adding the disposition note.', null, {
          duration: 3000,
        });
      }
    );
  }

  loadAssignedNumber() {
    this.integrationsService
      .readIntegrationKey('SMS_TWILIO')
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.assignedNumber = result.data.readIntegrationKey.apiKey;

          if (result.data.readIntegrationKey.apiKey) {
            this.assignedNumber = JSON.parse(result.data.readIntegrationKey.apiKey);
          }

          if (this.assignedNumber) {
            if (this.moObj?.contactNumber && this.assignedNumber?.friendlyName) {
              this.markAsRead(this.assignedNumber?.friendlyName, this.moObj.contactNumber);

              this.getMoreMessages('15', true);

              this.checkIfFirstMessage();
            }

            this.userService
              .getAuthenticatedUserId()
              .pipe(take(1))
              .subscribe(
                (userId: string) => {
                  this.userId = userId;

                  // User Id required for subscription
                  this.subScribeToMessages();

                  this.userService
                    .getUserAvatarUrl(this.userId)
                    .pipe(take(1))
                    .subscribe(
                      ({ data }) => {
                        const url = data.getUserAvatarUrl?.url || data.getContactAvatarUrl?.url;
                        this.userAvatarUrl = url || '';
                        if (url) this.imageCachingService.cacheImageFromUrl(url, this.userId);
                      },
                      (error) => console.log('ContactsTabComponent :: error download user avatar', error)
                    );
                },
                (error) => {
                  console.log('LandingPageComponent: Error getting authenticated userId -> ' + error.message);
                }
              );
          } else {
            console.log(`SMS_TWILIO: Phone number not configured!`);
            this.disabled = true;
            this.openErrorMessage();
          }
        },
        (error) => {
          console.log('ManageBombBombComponent: There was an error reading key ', error);
        }
      );
  }

  onSelectionEvent(eventObj: any) {
    console.log('eventObj', eventObj);

    if (eventObj.objects[0].text) {
      let message = eventObj.objects[0].text;
      message = this.processMergeTags(
        message,
        this.moObj.contact,
        { id: this.moObj.reqId, name: '' },
        { is: this.userId, firstName: this.userFirstName, lastName: this.userLastName }
      );

      this.messageToSend = message;
    } else {
      let message = `Selected SMS Template "${eventObj.title}" has no message defined`;

      this.snackBar.open(message, null, {
        duration: 3000,
      });
    }

    this.showSMSTemplatePicker = false;
  }

  processMergeTags(message, contact, req, user) {
    /*
        Tag format {x:zzzzzzzz}

        c: contact
        r: requisition
        u: user info
    */
    // Brute force for now
    message = message.replace(new RegExp(`{c:id}`, 'gi'), contact.id ? contact.id : '');
    message = message.replace(new RegExp(`{c:firstname}`, 'gi'), contact.firstName ? contact.firstName : '');
    message = message.replace(new RegExp(`{c:lastname}`, 'gi'), contact.lastName ? contact.lastName : '');

    message = message.replace(new RegExp(`{t:crap}`, 'gi'), user.crap ? user.crap : '');

    message = message.replace(new RegExp(`{r:id}`, 'gi'), req.id ? req.id : '');
    message = message.replace(new RegExp(`{r:name}`, 'gi'), req.name ? req.name : '');

    message = message.replace(new RegExp(`{u:id}`, 'gi'), user.id ? user.id : '');
    message = message.replace(new RegExp(`{u:firstname}`, 'gi'), user.firstName ? user.firstName : '');
    message = message.replace(new RegExp(`{u:lastname}`, 'gi'), user.lastName ? user.lastName : '');

    return message;
  }

  ngOnDestroy() {
    if (this.updatedMessageLogSubscription) {
      this.updatedMessageLogSubscription.unsubscribe();
    }
  }

  openErrorMessage() {
    if (this.disabled) {
      this.notificationsService.openSnackBar('Phone number not configured!', {
        duration: 5000,
        verticalPosition: 'bottom',
      });
    }
  }
}
