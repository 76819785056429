export const environment = {
  production: true,
  useServiceWorker: true,
  region: 'us-west-2',
  userPoolId: 'us-west-2_yp4ILQY5w',
  clientId: '22k5toso08ace3b4tj0t9qgak',
  graphQLUri: 'https://i5u226rhjrbsdpqkjgetegcaza.appsync-api.us-west-2.amazonaws.com/graphql',
  stripePublishableKey: 'pk_test_QXvg8cUxyg4usFmW33uXJTIW',
  appsyncApiKey: 'da2-s4swrds3dffxraq776yxqohxra',
  mapboxApiKey: 'pk.eyJ1IjoiY2hhZHRhbyIsImEiOiJja200MDR6YnoxN2lxMnJtOTJiN2FvaTY5In0.VYPT0ZDE4IBoFX0ATBu3oQ',
  versionCheckUrl: 'version.json',
  developer: 'qa',
};
