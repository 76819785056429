/* eslint-disable rxjs/no-nested-subscribe */
import { Component, Inject, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ReportingService } from '../../../services/reporting.service';
import { UserService } from '../../../services/user.service';
import { IntegrationsService } from '../../../services/integrations.service';
import { TodoService } from '../../../services/todo.service';
import { HistoryInput, ActivityType } from '../../../shared/model/activity';
import { differenceInSeconds, parseJSON } from 'date-fns';
import { ContactService } from 'app/services/contact.service';
import { RequisitionService } from '../../../services/requisition.service';
import { AlertDialogComponent, AlertDialogConfig } from 'app/shared/dialogs/alert-dialog.component';
import { Permissions, PermissionsService } from 'app/services/permissions.service';
@Component({
  selector: 'mm-export-req-contacts-dialog',
  templateUrl: './export-req-contacts-dialog.component.html',
  styleUrls: ['./export-req-contacts-dialog.component.scss'],
})
export class ExportReqContactsDialogComponent implements OnInit, OnDestroy {
  userIA: Permissions;
  exportLog: string[] = [];
  private unsubscribe$ = new Subject<void>();
  selectedCount: number = 0;
  processedCounter: number = 0;
  percentProcessed: number = 0;
  duplicateCounter: number = 0;
  errorCounter: number = 0;
  cancelRequest: boolean = false;
  actionInProgress: string = '';
  completed: boolean = false;
  iAPIOAuthKeyStored: any;
  loadingExportContacts: boolean = false;
  integrationVerified: boolean = true;
  fileHandle: FileSystemFileHandle | undefined;

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ExportReqContactsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contactService: ContactService,
    private reqService: RequisitionService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.logMessage('Started');

    this.getPermissions();
    this.selectedCount = 0;

    this.loadReqContactsFromRemote();
  }

  initAfterPermissions() {
    let doNada = 0;
  }

  onCloseClick() {
    if (this.selectedCount > this.processedCounter || !this.completed) {
      let dialogConfig: AlertDialogConfig = {
        title: 'There are still contact exports processing?',
        message: 'This will stop importing any that are left to process',
        extraMessage: 'Are you sure you want to stop now?',
        confirmButton: {
          label: 'Yes',
          color: 'primary',
        },
        cancelButton: {
          label: "Don't Stop me Now",
          color: 'accent',
        },
      };

      let dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, string>(AlertDialogComponent, {
        panelClass: 'alert-dialog-component',
        data: dialogConfig,
        autoFocus: false,
        width: '35%',
      });

      dialogRef.afterClosed().subscribe((confirmation) => {
        if (confirmation) {
          this.cancelRequest = true;
          // this.dialogRef.close({ data: this.exportLog });
        }
      });
    } else {
      this.cancelRequest = true;
      this.dialogRef.close({ data: this.exportLog });
    }
  }

  getPermissions() {
    this.permissionsService.permissions$.pipe(takeUntil(this.unsubscribe$)).subscribe((userIA) => {
      // console.log('permissions updated', userIA);
      this.userIA = userIA;
      this.initAfterPermissions();
    });
  }

  async loadReqContactsFromRemote() {
    this.completed = false;

    this.loadingExportContacts = true;

    this.logMessage('Retrieving Selected Model Match Contact Data');

    let waitGetMyReqs = [];

    if (this.data.reqId) {
      waitGetMyReqs.push({ id: this.data.reqId });
    } else {
      waitGetMyReqs = await this.reqService
        .getMyReqs()
        .pipe(take(1))
        .toPromise()
        .then(({ data: { getMyReqs } }) => {
          // console.log(getMyReqs);

          let allReqs = getMyReqs.map(({ reqId, id, ...a }) => {
            // this.reqOptions.push({ value: reqId, title: a.title });
            return { id: reqId };
          });

          return allReqs;
        })
        .catch((err) => {
          console.log('There was an error getting reqs', err);
        });
    }

    console.log('waitGetMyReqs', waitGetMyReqs);
    console.log('this.data.premium', this.data.premium);
    console.log(
      'this.userIA.cms.contacts.premium_contacts.export.less',
      this.userIA.cms.contacts.premium_contacts.export.less
    );

    const now = new Date().toISOString();
    const csvFilename = `modelmatch-contacts-${now}`;

    // FilePickerOptions
    const saveFileOptions: any = {
      suggestedName: csvFilename,
      startIn: 'downloads',
      types: [
        {
          description: 'CSV Files',
          accept: { 'text/csv': ['.csv'] },
        },
      ],
    };

    this.fileHandle = await window
      .showSaveFilePicker(saveFileOptions)
      .then((fileHandle) => {
        return fileHandle;
      })
      .catch((err) => {
        console.log('There was an error getting file location', err);

        return null;
      });

    // console.log('this.fileHandle', this.fileHandle);

    if (this.fileHandle == null) {
      this.logMessage('Canceled while choosing file');

      this.dialogRef.close({ data: this.exportLog });
      return;
    }

    const file: File = await this.fileHandle.getFile();
    const writableStream = await this.fileHandle.createWritable();

    let writeHeader = true;

    for (let i = 0; i < waitGetMyReqs.length; i++) {
      if (this.cancelRequest) break;

      let waitCountResults = await this.contactService
        .getCountReqContactsForExport(waitGetMyReqs[i]?.id)
        .pipe(
          take(1),
          map((results) => results.data.getCountReqContactsForExport)
        )
        .toPromise()
        .then((count) => {
          console.log(count);

          this.logMessage(`Count: ${waitGetMyReqs[i]?.id} ${count}`);

          return count;
        })
        .catch((err) => {
          console.log('There was an error getting counts', err);

          return 0;
        });

      let startKey = '0';

      while (startKey && waitCountResults > 0) {
        if (this.cancelRequest) break;

        let waitResults = await this.contactService
          .getReqContactsForExport(waitGetMyReqs[i]?.id, startKey, '100')
          .pipe(
            take(1),
            map((results) => results.data.getReqContactsForExport)
          )
          .toPromise()
          .then((contactsWithReqs) => {
            // console.log(contactsWithReqs);

            // Set next startKey
            startKey = contactsWithReqs?.endKey;

            if (this.cancelRequest) startKey = null;

            this.selectedCount = this.selectedCount + parseInt(contactsWithReqs.count);

            this.exportLoop([...contactsWithReqs.hits]);

            this.loadingExportContacts = false;

            return [...contactsWithReqs.hits];
          })
          .catch((err) => {
            console.log('There was an error staging contacts', err);

            startKey = null;

            return [];
          });

        let csvOut: any = {};

        if (this.cancelRequest !== true) {
          if (this.data.premium) {
            // let lessFlag = this.userIA.cms.contacts.premium_contacts.export.less === false;

            let lessFlag = this.userIA.cms.contacts.premium_contacts.export.less;

            csvOut = this.contactService.generatePremiumContactsToCSV(waitResults, lessFlag);
          } else csvOut = this.contactService.generateRegularContactsToCSV(waitResults);

          // Only write header once
          if (writeHeader) await writableStream.write(csvOut.csvHeader);

          await writableStream.write(csvOut.csvData);

          writeHeader = false;
        }
      }
    }

    await writableStream.close();

    // if (this.cancelRequest !== true) {
    //   if (this.data.premium) {
    //     let lessFlag = this.userIA.cms.contacts.premium_contacts.export.less === true;

    //     this.contactService.exportPremiumContactsToCSV(stagedContacts, lessFlag);
    //   } else this.contactService.exportRegularContactsToCSV(stagedContacts);
    // }

    this.completed = true;

    if (this.cancelRequest === true) this.dialogRef.close({ data: this.exportLog });
  }

  async exportLoop(contactsWithReqs) {
    this.logMessage('Exporting Contacts Now!');

    this.cancelRequest = false;
    this.completed = false;
    this.actionInProgress = 'processing';

    if (contactsWithReqs)
      for (let x = 0; x < contactsWithReqs.length; x++) {
        // console.log(contactsWithReqs[x]);

        this.actionInProgress = 'Getting next contact info';

        if (contactsWithReqs.length > 0) this.percentProcessed = ((x + 1) / contactsWithReqs.length) * 100;

        this.actionInProgress = 'exporting contact';

        this.logMessage(`${contactsWithReqs[x].firstName} ${contactsWithReqs[x].lastName} Staged`);

        // await this.sendContactToReqContactsOauth(contactsWithReqs[x]);

        this.processedCounter++;

        if (this.cancelRequest) break;
      }

    this.logMessage('Req Stage Complete!');
  }

  logMessage(message, priority = 0) {
    this.exportLog.unshift(' ');
    if (this.exportLog.length > 100) this.exportLog.pop();

    if (priority > 10) {
      this.exportLog.unshift('***');
      if (this.exportLog.length > 100) this.exportLog.pop();
    }
    this.exportLog.unshift(message);
    if (this.exportLog.length > 100) this.exportLog.pop();
    if (priority > 100) {
      this.exportLog.unshift('***');
      if (this.exportLog.length > 100) this.exportLog.pop();
    }
    if (priority > 10) {
      this.exportLog.unshift(' ');
      if (this.exportLog.length > 100) this.exportLog.pop();
    }
  }

  ngOnDestroy() {
    this.cancelRequest = true;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
