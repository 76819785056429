<h1 mat-dialog-title>
  {{ data.contacts.length }} Purchased Candidates Added to
  <a class="is-capitalized" [routerLink]="'/requisitions/' + data.requisition" (click)="closeDialog()">{{
    data.requisition
  }}</a>
</h1>
<mat-dialog-content>
  <div class="table-container">
    <mat-table [dataSource]="contactsTotalTableSource" ngClass="contacts-added-table">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell
          *matCellDef="let contact"
          [matTooltip]="contact.firstName + ' ' + contact.lastName"
          [matTooltipShowDelay]="700"
        >
          <div>{{ contact.firstName }} {{ contact.lastName }}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="score">
        <mat-header-cell *matHeaderCellDef>Score</mat-header-cell>
        <mat-cell *matCellDef="let contact" [matTooltip]="contact.score | percent" [matTooltipShowDelay]="700">
          {{ contact.score | percent }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="company">
        <mat-header-cell *matHeaderCellDef>Company</mat-header-cell>
        <mat-cell *matCellDef="let contact" [matTooltip]="contact.company" [matTooltipShowDelay]="700">
          <div>{{ contact.company }}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef>City</mat-header-cell>
        <mat-cell *matCellDef="let contact" [matTooltip]="contact.city" [matTooltipShowDelay]="700">
          <div>{{ contact.city }}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="zip-code">
        <mat-header-cell *matHeaderCellDef>Zip Code</mat-header-cell>
        <mat-cell *matCellDef="let contact" [matTooltip]="contact.zipCode" [matTooltipShowDelay]="700">
          <div>{{ contact.zipCode }}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="nmls">
        <mat-header-cell *matHeaderCellDef>NMLS</mat-header-cell>
        <mat-cell *matCellDef="let contact" [matTooltip]="contact.nmlsId" [matTooltipShowDelay]="700">
          <div>{{ contact.nmlsId }}</div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedContactsColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedContactsColumns"></mat-row>
    </mat-table>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>
