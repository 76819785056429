import { Injectable, OnDestroy, inject } from '@angular/core';
import {
  addRole,
  updateRolePriorities,
  savePermissions,
  updateRoleName,
  deleteRole,
  updateUserRoleIds,
  uploadTailLog,
  duplicateRole,
} from '../graphql/mutations';
import {
  getAllUserPermissionRecords,
  getUserPermissionsRecord,
  getPermissions,
  getTenantPermissions,
} from '../graphql/queries';
import { AppsyncService } from '../graphql/graphql.appsync.service';
import { gql, Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-angular-boost';
import { BehaviorSubject, Observable, ReplaySubject, Subject, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PermissionsResponse, RolePrioritiesInput } from 'graphql.types';
import {
  Permissions,
  PermissionsRole,
  TenantPermissionsBreakdown,
  UserPermissionsRecord,
  setAllPropertiesToFalse,
  getUserPermissionDefaults,
  PermissionsOverride,
  removeEmptyObjectsFromPermissions,
  validatePermissions,
} from '../../../../app/src/model/permissions/permissions';
import { getPermissionsSubscription, getTailLogsSubscription } from 'app/graphql/subscriptions';

export { Permissions } from '../../../../app/src/model/permissions/permissions';

@Injectable()
export class PermissionsService implements OnDestroy {
  public readonly permissions$: BehaviorSubject<Permissions> = new BehaviorSubject(
    this.getPermissionsFromLocalStorage()
  );
  public readonly permissions: Observable<Permissions> = this.permissions$.asObservable();

  public readonly permissionChanges$: BehaviorSubject<PermissionsOverride> = new BehaviorSubject({});

  private permissionsSub: ZenObservable.Subscription;

  // Client - AppSync<NormalizedCacheObject>
  client = this.appsync.nonHydratedClient();
  apollo = inject(Apollo);

  constructor(private appsync: AppsyncService) {}

  ngOnDestroy(): void {
    this.permissions$.getValue().debug.permissions && console.log('🔒 Permissions Service :: OnDestroy');
    this.permissions$.complete();
    this.permissionsSub.unsubscribe();
  }

  /** Set up the subscription for changes and get the initial value of permissions. Permissions are initialized in the user service upon authentication. */
  async initPermissions(tenantId: string) {
    let permissions = await this.getPermissions();
    console.log(
      '🔒 Initialized permissions!',
      permissions.debug.permissions === true || window.location.hostname === 'localhost'
        ? permissions
        : 'No Debug Access.'
    );

    // See if we have any changes since the last time we logged in.
    // let permissionUpdates = this.getPermissionChanges(this.permissions$.getValue(), permissions);

    // if (Object.keys(permissionUpdates).length) {
    //   this.permissions$.getValue().debug.permissions &&
    //     console.log('🔒 Permissions that changed:', permissionUpdates);
    //   this.runPermissionAction(permissionUpdates);
    // }

    // Unsubscribe from the subscription if we have one.
    if (this.permissionsSub) {
      console.log('🔒 Unsubscribed from realtime updates.');
      this.permissionsSub.unsubscribe();
    }
    // Set up the permissions subscription.
    this.permissionsSub = this.getPermissionsSubscription(tenantId).subscribe(async (response) => {
      this.permissions$.getValue().debug.permissions && console.log('🔒 Received realtime permissions update!');
      let permissions = await this.getPermissions();

      // See if we have any change this update.
      let permissionUpdates = this.getPermissionChanges(this.permissions$.getValue(), permissions);
      this.permissions$.getValue().debug.permissions && console.log('🔒 Permissions that changed:', permissionUpdates);
      this.runPermissionAction(permissionUpdates);

      this.permissions$.next(permissions);
    });
    // Set the permissions subject.
    this.permissions$.next(permissions);

    return permissions;
  }

  // PERMISSION ACTIONS
  /** @experimental */
  runPermissionAction(permissionChanges: PermissionsOverride) {
    this.permissionChanges$.next(permissionChanges);
  }

  /**Get the current permissions that the user has. This is probably the easiest way to check for any given permission.
   * This does not subscribe to any future updates to permissions.
   * ```
   *  Example:
   *  this.permissionsService.getCurrentPermissions().integrations.total_expert.export === true
   * ```
   */
  getCurrentPermissions(): Permissions {
    return this.permissions$.getValue();
  }

  /** Generates a subscription for realtime updates to any permissions action. */
  getPermissionsSubscription(tenantId: string) {
    this.permissions$.getValue().debug.permissions &&
      console.log('🔒 Subscribed to realtime permissions for tenant: ', tenantId);
    return this.client.subscribe<Permissions>({
      query: gql(getPermissionsSubscription),
      variables: {
        tenantId,
      },
      fetchPolicy: 'network-only',
    });
  }

  getPermissionChanges(oldPermissions: Permissions, newPermissions: Permissions) {
    console.log('🪵 ~ PermissionsService ~ getPermissionChanges ~ newPermissions:', newPermissions);
    console.log('🪵 ~ PermissionsService ~ getPermissionChanges ~ oldPermissions:', oldPermissions);
    function keepChangedPermissionValuesOnly(
      accumulator: PermissionsOverride,
      oldPermissions: PermissionsOverride,
      newPermissions: Permissions
    ): void {
      for (let key in oldPermissions) {
        if (typeof oldPermissions[key] === 'boolean') {
          if (newPermissions[key] !== oldPermissions[key]) {
            accumulator[key] = newPermissions[key] as boolean;
          }
        } else if (
          typeof oldPermissions[key] === 'object' &&
          oldPermissions[key] !== null &&
          oldPermissions[key] !== undefined
        ) {
          if (!accumulator[key]) accumulator[key] = {};
          keepChangedPermissionValuesOnly(
            accumulator[key] as PermissionsOverride,
            oldPermissions[key] as PermissionsOverride,
            newPermissions[key]
          );
        }
      }
    }

    let accumulator: PermissionsOverride = {};
    keepChangedPermissionValuesOnly(accumulator, oldPermissions, newPermissions);
    return removeEmptyObjectsFromPermissions(accumulator);
  }

  /** Get the compiled permissions based on tenant access, assigned roles, static roles and user overrides. Save result into localStorage for fast retrieval next refresh.  */
  async getPermissions(tenantId?: string, userId?: string): Promise<Permissions> {
    let permissionsRequest = await this.client.query<{ getPermissions: string }>({
      query: gql(getPermissions),
      ...(tenantId &&
        userId && {
          variables: {
            tenantId,
            userId,
          },
        }),
      fetchPolicy: 'network-only',
    });
    let permissions = JSON.parse(permissionsRequest.data.getPermissions) as Permissions;
    // Save to local storage.
    localStorage.setItem('PERMISSIONS', JSON.stringify(permissions));
    return permissions;
  }

  /** This function adds the new role and assigns it the lowest priority(highest index) by default,
   * adds the roleId to the tenantPermissions record, then returns the entire updated/sorted role record
   */
  addRole(
    tenantId: string,
    nameInput: string,
    permissions: PermissionsOverride,
    overwrite: boolean = false
  ): Observable<{ addedRole: PermissionsRole; allRoles: PermissionsRole[] }> {
    return this.apollo
      .mutate({
        mutation: gql(addRole),
        variables: { tenantId, nameInput, permissions: JSON.stringify(permissions), overwrite },
      })
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ addRole: PermissionsResponse }>) => {
          return JSON.parse(result.data.addRole.data);
        })
      );
  }

  duplicateRole(tenantId: string, roleIdToDuplicate: string, newRoleName: string): Observable<PermissionsRole> {
    return this.apollo
      .mutate({
        mutation: gql(duplicateRole),
        variables: { tenantId, roleIdToDuplicate, newRoleName },
      })
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ duplicateRole: PermissionsResponse }>) => {
          const addedRoleRecord = result.data.duplicateRole;
          if (addedRoleRecord) {
            return JSON.parse(addedRoleRecord.data);
          }
        })
      );
  }

  /** Gets permissions for all users on a given tenant. */
  getAllUserPermissionRecords(tenantId: string) {
    return this.apollo
      .query({
        query: gql(getAllUserPermissionRecords),
        variables: { tenantId },
        fetchPolicy: 'network-only',
      })
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ getAllUserPermissionRecords: string }>) => {
          const allUserPermissionRecords = result.data.getAllUserPermissionRecords;
          if (allUserPermissionRecords) {
            return JSON.parse(allUserPermissionRecords) as UserPermissionsRecord[];
          }
        })
      );
  }

  /** Gets a single user's permissions record. */
  getUserPermissionsRecord(tenantId: string, userId: string) {
    return this.apollo
      .query({
        query: gql(getUserPermissionsRecord),
        variables: { tenantId, userId },
        fetchPolicy: 'network-only',
      })
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ getUserPermissionsRecord: string }>) => {
          const userPermissionsRecord = result.data.getUserPermissionsRecord;
          if (userPermissionsRecord) {
            return JSON.parse(userPermissionsRecord) as UserPermissionsRecord;
          }
        })
      );
  }

  /** This function will always return a tenant permissions record. If it does not exist, it will create one using code defaults and return it.
   *  It will also return all roles records that exist.
   * Return object structure:
   * { roles: PermissionsRole[], tenantPermissions: TenantPermissionsRecord, everyoneRole: PermissionsRole  }
   */
  getTenantPermissions(tenantId: string): Observable<TenantPermissionsBreakdown> {
    return this.apollo
      .query({
        query: gql(getTenantPermissions),
        variables: { tenantId },
        fetchPolicy: 'network-only',
      })
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ getTenantPermissions: string }>) => {
          const roleRecords = result.data.getTenantPermissions;
          if (roleRecords) {
            return JSON.parse(roleRecords) as TenantPermissionsBreakdown;
          }
        })
      );
  }

  /** Updates roles on a user permissions record. Takes a set of the roles in the backend so we don't have duplicates. */
  updateUserRoleIds(tenantId: string, userId: string, roleIds: string[]) {
    return this.apollo
      .mutate({
        mutation: gql(updateUserRoleIds),
        variables: {
          tenantId,
          userId,
          roleIds: [...new Set(roleIds)],
        },
      })
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ updateUserRoleIds: PermissionsResponse }>) => {
          return JSON.parse(result.data.updateUserRoleIds.data);
        })
      );
  }

  /** Used when moving roles around or deleting roles. Responsible for reassigning the role priorities for sorting. */
  updateRolePriorities(tenantId: string, rolePriorities: RolePrioritiesInput[]) {
    return this.apollo
      .mutate({
        mutation: gql(updateRolePriorities),
        variables: { tenantId: tenantId, input: rolePriorities },
      })
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ updateRolePriorities: PermissionsResponse }>) => {
          return JSON.parse(result.data.updateRolePriorities.data);
        })
      );
  }

  /** The main function for saving any permissions object at any key for permissions. */
  savePermissions(dynamoKey: { pKey: string; rKey: string }, permissions: Permissions | PermissionsOverride) {
    let permissionsJSON = JSON.stringify(permissions);
    return this.apollo
      .mutate({
        mutation: gql(savePermissions),
        variables: { input: dynamoKey, permissions: permissionsJSON },
      })
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ savePermissions: PermissionsResponse }>) => {
          const savedPermissions = result.data.savePermissions.data;
          if (savedPermissions) {
            return JSON.parse(savedPermissions);
          }
        })
      );
  }

  updateRoleName(tenantId: string, newRoleName: string, roleId: string): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: gql(updateRoleName),
        variables: { tenantId, text: newRoleName, roleId },
      })
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ updateRoleName: PermissionsResponse }>) => {
          const editedRole = result.data.updateRoleName.data;
          if (editedRole) {
            return JSON.parse(editedRole);
          }
        })
      );
  }

  /** Deletes a role as well as grabs all tenant user permission records and makes sure the role on their account is deleted too.
   * TODO: Make this function fail if there are any users with this role.
   */
  deleteRole(tenantId: string, roleId: string): Observable<PermissionsRole[]> {
    return this.apollo
      .mutate({
        mutation: gql(deleteRole),
        variables: { tenantId, roleId },
      })
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ deleteRole: PermissionsResponse }>) => {
          const roleRecord = result.data.deleteRole.data;
          if (roleRecord) {
            return JSON.parse(roleRecord);
          }
        })
      );
  }

  getPermissionsFromLocalStorage(): Permissions {
    let localStoragePermissions = localStorage.getItem('PERMISSIONS');
    if (localStoragePermissions) {
      let parsedPermissions = JSON.parse(localStoragePermissions) as Permissions;
      if (
        !parsedPermissions ||
        Object.keys(parsedPermissions).length < Object.keys(getUserPermissionDefaults()).length
      ) {
        console.error('Failed to parse local storage permissions. Loading default permissions.');
        return setAllPropertiesToFalse(getUserPermissionDefaults()) as Permissions;
      }
      let permissionsValid = validatePermissions(parsedPermissions);
      if (permissionsValid) {
        console.log('🔒 Local Permissions valid.');
        return parsedPermissions;
      } else {
        console.log('🔒 Local Permissions invalid.');
        return setAllPropertiesToFalse(getUserPermissionDefaults()) as Permissions;
      }
    } else {
      return setAllPropertiesToFalse(getUserPermissionDefaults()) as Permissions;
    }
  }

  /** Counts the amount of values in an object. Useful for seeing how many values are set on a PermissionsOverride object. */
  countValues(obj: Object) {
    let count = 0;

    for (let key in obj) {
      //@ts-ignore
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // If the value is an object, recursively call the function
        //@ts-ignore
        count += this.countValues(obj[key]);
      } else {
        // If the value is not an object, increase the count
        count++;
      }
    }

    return count;
  }

  uploadTailLog(tenantId: string, userId: string, data: string) {
    // console.log('🪵 ~ PermissionsService ~ uploadTailLog:', tenantId, userId, data);
    // return of('test')
    return this.apollo
      .mutate({
        mutation: gql(uploadTailLog),
        variables: { tenantId, userId, data },
      })
      .pipe(
        take(1),
        map((result: ApolloQueryResult<any>) => {
          return result.data.uploadTailLog;
        })
      );
  }

  /** Generates a subscription for realtime updates to any permissions action. */
  getTailLogSubscription(tenantId: string, userId: string) {
    console.log('🔒 Subscribed to realtime tailLog.');
    return this.client.subscribe({
      query: gql(getTailLogsSubscription),
      variables: {
        tenantId,
        userId,
      },
      fetchPolicy: 'network-only',
    });
  }

  /**
   * Clear permissions for the given user. Typically this will be done on logout.
   */
  clearLocalPermissions() {
    localStorage.removeItem('PERMISSIONS');
    this.permissionsSub?.unsubscribe();
  }
}
