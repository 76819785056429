<div
  class="mat-card-container transition-all duration-300 transform translate-y-600px"
  [ngClass]="this.openEmail ? 'translate-y-0' : 'translate-y-600px'"
  id="container"
>
  <mat-card class="mat-elevation-z7">
    <mat-card-title>
      <div class="card-title-content">
        <mat-icon class="card-title-icon">email</mat-icon>
        <span class="card-title">Send Email</span>
        <button mat-button class="card-title-close" (click)="closeEmailDraft()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card-title>
    <mat-card-content>
      <form class="email-form">
        <div style="display: flex; flex-direction: column; height: 100%">
          <mat-form-field floatLabel="always">
            <mat-label>From</mat-label>
            <input matInput readonly type="text" placeholder="{{ getEmailFrom() }}" />
          </mat-form-field>
          <div class="to-cc">
            <mat-form-field>
              <mat-label>To</mat-label>
              <input matInput style="color: #598b72" [(ngModel)]="emailTo" name="emailTo" />
            </mat-form-field>
            <button mat-icon-button (click)="toggleCc()">
              <mat-icon style="color: #52b4ff">closed_caption</mat-icon>
            </button>
          </div>
          <mat-form-field *ngIf="showCc">
            <mat-label>cc</mat-label>
            <input matInput style="color: #598b72" [(ngModel)]="emailCc" name="emailCc" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Subject</mat-label>
            <input matInput [(ngModel)]="emailSubject" name="emailSubject" />
          </mat-form-field>
          <mm-loading-spinner *ngIf="isLoading"></mm-loading-spinner>
          <!-- <quill-editor
                        class="emailBody"
                        id="emailBody"
                        [(ngModel)]="emailBody"
                        name="emailBody"
                        format="html"
                    >
                    </quill-editor> -->
          <div id="quillEditor"></div>
        </div>
      </form>
      <div *ngIf="emailAttachments.length > 0" class="attachmentsContainer" style="margin: -0.5em; width: 102%">
        <ng-container *ngFor="let att of emailAttachments">
          <mat-card
            class="attachmentCard"
            [ngClass]="{
              pdf: att.type == pdfType,
              doc: att.type == docType || att.type == docxType,
              otherAttType: true
            }"
          >
            <button mat-icon-button class="deleteAttButton" (click)="deleteAttachment(att)">
              <mat-icon>close</mat-icon>
            </button>
            <img src="assets/images/integrations/pdfIcon.png" class="attachmentIcon" *ngIf="att.type == pdfType" />
            <img
              src="assets/images/integrations/docIcon.png"
              class="attachmentIcon"
              *ngIf="att.type == docType || att.type == docxType"
            />
            <img
              src="assets/images/integrations/otherAttIcon.jpg"
              class="attachmentIcon"
              *ngIf="att.type != pdfType && att.type != docType && att.type != docxType"
            />
            <div style="margin-left: 5px">
              <span
                ><b>{{ att.name }}</b></span
              >
              <span style="font-size: 10px"
                ><em>{{ convertSize(att.size) }}mb</em></span
              >
            </div>
          </mat-card>
        </ng-container>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <div class="footer">
        <mat-card *ngIf="showEmailTemplates" class="emailTemplatesCard mat-elevation-z4">
          <mat-form-field appearance="outline" class="searchBar" [ngClass]="colorClass ? colorClass : null">
            <mat-label>Search</mat-label>
            <input (keyup)="filterEmailTemplates($event.target.value)" matInput />
            <mat-icon matSuffix color="muted">search</mat-icon>
          </mat-form-field>
          <mat-selection-list
            [(ngModel)]="selectedTemplate"
            [multiple]="false"
            (selectionChange)="updateEmailBody()"
            class="emailTemplates"
          >
            <mat-list-option *ngFor="let template of allTemplates" [value]="template">
              {{ template.templateTitle }}
            </mat-list-option>
          </mat-selection-list>
          <button mat-button class="manageTemplates" (click)="routeToEmailSettings()">
            <span style="color: #52b4ff">Manage Templates</span>
          </button>
        </mat-card>
        <div class="footerOptions">
          <input
            #documentInput
            (change)="uploadAttachmentHandler($event)"
            type="file"
            value="file"
            id="fileUpload"
            multiple
            hidden
          />
          <button mat-icon-button class="templatesButton" (click)="toggleShowEmailTemplates()">
            <mat-icon>list_alt</mat-icon>
          </button>
          <button mat-icon-button class="attachmentButton" (click)="documentInput.click()">
            <mat-icon>attachment</mat-icon>
          </button>
        </div>
        <button mat-mini-fab class="sendButton" color="primary" (click)="sendEmail()">
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
