import { Injectable } from '@angular/core';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';

import {
  GetMyReqsQuery,
  FilterMyReqsQuery,
  GetReqContactsQuery,
  ChangeReqContactTouchedStatusQuery,
  GetReqQuery,
  GetTenantReqsQuery,
  FilterReqContactsQuery,
  GetReqActivityHistory,
  GetReqUsers,
  GetUserReqForDropdownQuery,
  UpdateReq,
  RemoveUserFromReq,
  UpdateReqOwner,
  GetReqContactSavedFilters,
  AddReqContactSearchFilter,
  ShareReqContactSearchFilter,
  UpdateReqContactSearchFilter,
  DeleteReqContactSearchFilter,
  AddReqMutation,
  GetProcessTotalsForAllReqs,
} from '../graphql/types';
import {
  getMyReqsDropdownQuery,
  getMyReqsAdminFiltered,
  getReqContactsQuery,
  changeReqContactTouchedStatusQuery,
  getReqQuery,
  getTenantReqsQuery,
  getMyReqsQuery,
  filterMyReqsQuery,
  getMyReqTodosQuery,
  getReqActivityHistory,
  getReqUsers,
  getContactReqs,
  filterReqContactsQuery,
  getUserReqsForDropdownsQuery,
  getReqContactSavedFilters,
  getMyReqIdsQuery,
  getProcessTotalsForAllReqs,
  getReqTags,
} from '../graphql/queries';
import {
  ReqContactInput,
  ReqContactFilter,
  RequisitionStatus,
  ReqFilter,
  UpdateReqInput,
  ReqContactSearchFilter,
} from '../shared/model/requisitions';
import { ReqContact, ContactFilter, ContactTag } from '../shared/model/contacts';
import { User } from '../shared/model/user';
import {
  addReqMutation,
  addUserToReq,
  updateContactReq,
  updateReq,
  removeUserFromReq,
  addReqContactSearchFilter,
  shareReqContactSearchFilter,
  updateReqContactSearchFilter,
  deleteReqContactSearchFilter,
  updateReqOwner,
  updateReqTags,
} from '../graphql/mutations';
import { Page, SortOption } from './../shared/model/filter';
import { ApolloQueryResult } from 'apollo-client';

@Injectable()
export class RequisitionService {
  private filteredReqContactsCache: ReqContact[] = [];
  private cachedReqContactFilter: ReqContactFilter;
  private cachedReqContactSort: any;
  private cachedDisplayedColumns: string[];
  private cachedDisplayedFilterColumns: string[];

  private cachedProcessFilter: any;
  private cachedProcessSort: any;
  private cachedProcessDisplayedColumns: string[];
  private cachedProcessDisplayedFilterColumns: string[];

  constructor(private apollo: Apollo) {}

  public setLastFilteredReqContacts(reqContacts: ReqContact[]) {
    this.filteredReqContactsCache = reqContacts;
  }

  public getLastFilteredReqContacts(): ReqContact[] {
    return this.filteredReqContactsCache;
  }

  public setCachedReqContactFilter(filter: ReqContactFilter) {
    this.cachedReqContactFilter = filter;
  }

  public getCachedReqContactFilter(): ReqContactFilter {
    return this.cachedReqContactFilter;
  }

  public setCachedReqContactSort(cachedReqContactSort: any) {
    this.cachedReqContactSort = cachedReqContactSort;
  }

  public getCachedReqContactSort(): any {
    return this.cachedReqContactSort;
  }

  public setCachedDisplayedColumns(cachedDisplayedColumns: string[]) {
    this.cachedDisplayedColumns = cachedDisplayedColumns;
  }

  public getCachedDisplayedColumns(): string[] {
    return this.cachedDisplayedColumns;
  }

  public setCachedDisplayedFilterColumns(cachedDisplayedFilterColumns: string[]) {
    this.cachedDisplayedFilterColumns = cachedDisplayedFilterColumns;
  }

  public getCachedDisplayedFilterColumns(): string[] {
    return this.cachedDisplayedFilterColumns;
  }

  public setCachedProcessFilter(filter: ReqContactFilter) {
    this.cachedProcessFilter = filter;
  }

  public getCachedProcessFilter(): any {
    return this.cachedProcessFilter;
  }

  public setCachedProcessSort(cachedProcessSort: any) {
    this.cachedProcessSort = cachedProcessSort;
  }

  public getCachedProcessSort(): any {
    return this.cachedProcessSort;
  }

  public setCachedProcessDisplayedColumns(cachedProcessDisplayedColumns: string[]) {
    this.cachedProcessDisplayedColumns = cachedProcessDisplayedColumns;
  }

  public getCachedProcessDisplayedColumns(): string[] {
    return this.cachedProcessDisplayedColumns;
  }

  public setCachedProcessDisplayedFilterColumns(cachedProcessDisplayedFilterColumns: string[]) {
    this.cachedProcessDisplayedFilterColumns = cachedProcessDisplayedFilterColumns;
  }

  public getCachedProcessDisplayedFilterColumns(): string[] {
    return this.cachedProcessDisplayedFilterColumns;
  }

  public loadRequisition(id: string, networkOnly?: boolean): Observable<any> {
    return this.apollo.watchQuery<GetReqQuery>({
      query: gql(getReqQuery),
      variables: { reqId: id },
      fetchPolicy: networkOnly ? 'network-only' : 'cache-first',
    }).valueChanges;
  }

  public loadReqContacts(id: string, networkOnly?: boolean): Observable<any> {
    return this.apollo.watchQuery<GetReqContactsQuery>({
      query: gql(getReqContactsQuery),
      variables: { reqId: id },
      fetchPolicy: networkOnly ? 'network-only' : 'cache-first',
    }).valueChanges;
  }

  public changeReqContactTouchedStatus(reqId: string, contactId: string): Observable<any> {
    return this.apollo.mutate<ChangeReqContactTouchedStatusQuery>({
      mutation: gql(changeReqContactTouchedStatusQuery),
      variables: { reqId: reqId, contactId: contactId },
    });
  }

  public saveReqContactSearchFilter(searchFilter: any): Observable<any> {
    return this.apollo.mutate<AddReqContactSearchFilter>({
      mutation: gql(addReqContactSearchFilter),
      variables: { searchFilter: searchFilter },
      update: (store, { data: { addReqContactSearchFilter } }) => {
        if (!addReqContactSearchFilter) {
          return;
        }

        const query = gql(getReqContactSavedFilters);
        const variables = { reqId: searchFilter.reqId };
        const data: GetReqContactSavedFilters = store.readQuery({
          query,
          variables,
        }) as GetReqContactSavedFilters;
        let clonedData = structuredClone(data);
        clonedData.getReqContactSavedFilters.push(addReqContactSearchFilter);
        store.writeQuery({ query, variables, data: clonedData });
      },
    });
  }

  public updateReqContactSearchFilter(id: string, reqId: string, filterCriteria: any): Observable<any> {
    return this.apollo.mutate<UpdateReqContactSearchFilter>({
      mutation: gql(updateReqContactSearchFilter),
      variables: { id: id, reqId: reqId, filterCriteria: filterCriteria },
      update: (store, { data: { updateReqContactSearchFilter } }) => {
        if (!updateReqContactSearchFilter) {
          return;
        }

        const query = gql(getReqContactSavedFilters);
        const variables = { reqId: reqId };
        const data: GetReqContactSavedFilters = store.readQuery({
          query,
          variables,
        }) as GetReqContactSavedFilters;
        let clonedData = structuredClone(data);
        let index = data.getReqContactSavedFilters.findIndex((obj) => obj.id === updateReqContactSearchFilter.id);
        if (index === -1) {
          clonedData.getReqContactSavedFilters.push(updateReqContactSearchFilter);
        } else {
          clonedData.getReqContactSavedFilters[index] = updateReqContactSearchFilter;
        }
        clonedData.getReqContactSavedFilters.push(updateReqContactSearchFilter);
        store.writeQuery({ query, variables, data: clonedData });
      },
    });
  }

  public shareReqContactSearchFilter(userIds: string[], searchFilter: ReqContactSearchFilter): Observable<any> {
    return this.apollo.mutate<ShareReqContactSearchFilter>({
      mutation: gql(shareReqContactSearchFilter),
      variables: { userIds: userIds, searchFilter: searchFilter },
    });
  }

  public deleteReqContactSearchFilter(id: string, reqId: string): Observable<any> {
    return this.apollo.mutate<DeleteReqContactSearchFilter>({
      mutation: gql(deleteReqContactSearchFilter),
      variables: { id: id, reqId: reqId },
      update: (store, { data: { deleteReqContactSearchFilter } }) => {
        if (!deleteReqContactSearchFilter) {
          return;
        }
        const query = gql(getReqContactSavedFilters);
        const variables = { reqId: reqId };
        const data: GetReqContactSavedFilters = store.readQuery({
          query,
          variables,
        }) as GetReqContactSavedFilters;
        let clonedData = structuredClone(data);
        let index = clonedData.getReqContactSavedFilters.findIndex((obj) => obj.id === id);
        clonedData.getReqContactSavedFilters.splice(index, 1);
        store.writeQuery({ query, variables, data: clonedData });
      },
    });
  }

  public filterReqContacts(
    reqIds: string[],
    page: Page,
    filters: ReqContactFilter,
    sortOption: SortOption,
    query = filterReqContactsQuery
  ) {
    return this.apollo.watchQuery<
      FilterReqContactsQuery,
      {
        reqIds: string[];
        page: Page;
        filters: ContactFilter;
        sortOption: SortOption;
      }
    >({
      query: gql(query),
      variables: { reqIds, page, filters, sortOption },
      fetchPolicy: 'network-only',
    });
  }

  public getReqContactFromCache(contactId: string, reqId: string): ReqContact {
    // This object should be cached. but a browser refresh could have caused the cache to be cleared.
    // Unsure at this time if a cache miss should be reloaded automatically, or if the user needs to nav off the page.
    let filters = {};
    const data: FilterReqContactsQuery = this.apollo.getClient().readQuery({
      query: gql(filterReqContactsQuery),
      variables: { reqId: reqId, cursor: 0, filters: filters },
    });
    const filteredContactReq = data.filterReqContacts.contacts.filter((cr) => cr.id == reqId + '|' + contactId);
    if (filteredContactReq && filteredContactReq.length > 0) {
      return filteredContactReq[0];
    }

    return null;
  }

  public loadReqActivityHistory(id: string): Observable<any> {
    return this.apollo.watchQuery<GetReqActivityHistory>({
      query: gql(getReqActivityHistory),
      variables: { reqId: id },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  //Doing refetches for the dropdown menus after req is created, instead of dropdowns not updating after reqs
  //added if using cache, or doing excessive network calls on individual invocations of each dropdown.
  //later - clean up req queries and retrieve data from ES (currently these 3 geMyReqs queries call dynamodb )
  public createRequisition(reqInfo: any) {
    if (!reqInfo.title || !reqInfo.summary || !reqInfo.tenantId || !reqInfo.ownerId) {
      return;
    }

    return this.apollo.mutate<AddReqMutation>({
      mutation: addReqMutation,
      variables: reqInfo,
      refetchQueries: [
        { query: gql(getMyReqsQuery) },
        { query: gql(getMyReqsDropdownQuery) },
        { query: gql(getMyReqTodosQuery) },
      ],
    });
  }

  public getTenantReqs(tenantId): Observable<any> {
    return this.apollo.watchQuery<GetTenantReqsQuery>({
      query: gql(getTenantReqsQuery),
      variables: { tenantId: tenantId },
    }).valueChanges;
  }

  public getMyReqsForDropdown(): Observable<any> {
    return this.apollo.watchQuery<GetMyReqsQuery>({
      query: gql(getMyReqsDropdownQuery),
    }).valueChanges;
  }

  public getUserReqsForDropdown(userId): Observable<any> {
    return this.apollo.watchQuery<GetUserReqForDropdownQuery>({
      query: gql(getUserReqsForDropdownsQuery),
      variables: { userId: userId },
    }).valueChanges;
  }

  public getMyReqs(): Observable<any> {
    return this.apollo.watchQuery<GetMyReqsQuery>({
      query: gql(getMyReqsQuery),
    }).valueChanges;
  }

  public getMyReqIds(): Observable<any> {
    return this.apollo.watchQuery<GetMyReqsQuery>({
      query: gql(getMyReqIdsQuery),
    }).valueChanges;
  }

  public getMyReqsAdminFiltered(tenantId: string, reqFilter: ReqFilter): Observable<any> {
    let vars: any = {};

    if (reqFilter) vars.reqFilter = reqFilter;

    if (tenantId) vars.tenantId = tenantId;

    return this.apollo.watchQuery<GetMyReqsQuery>({
      query: gql(getMyReqsAdminFiltered),
      variables: vars,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getMyReqWithTodos(): Observable<ApolloQueryResult<GetMyReqsQuery>> {
    //@ts-ignore
    return this.apollo.watchQuery<GetMyReqsQuery>({
      query: gql(getMyReqTodosQuery),
      // pollInterval: 30000,
    }).valueChanges;
  }

  public getReqContactSavedFilters(reqId: string): Observable<any> {
    return this.apollo.watchQuery<GetReqContactSavedFilters>({
      query: gql(getReqContactSavedFilters),
      variables: { reqId: reqId },
    }).valueChanges;
  }

  public getReqUsers(reqId: string, fromDate?: string, toDate?: string): Observable<any> {
    let vars = {
      reqId: reqId,
    };

    if (fromDate) {
      vars['prodStartDate'] = fromDate;
    }

    return this.apollo.watchQuery<GetReqUsers>({
      query: gql(getReqUsers),
      variables: vars,
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  public addUserToReq(userId: string, reqId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(addUserToReq),
      variables: { userId: userId, reqId: reqId },
    });
  }

  public updateReqContact(input: ReqContactInput, sendMail?: boolean): Observable<any> {
    console.log('updateReqContact', input);
    console.log('updateReqContact sendmail', sendMail);

    return this.apollo.mutate({
      mutation: gql(updateContactReq),
      variables: { input: input, sendMail: sendMail },
      // refresh to get new aggs which changed with a stage update
      refetchQueries: [
        { query: gql(getMyReqsQuery) },
        {
          query: gql(getContactReqs),
          variables: { contactId: input.contactId },
        },
      ],
    });
  }

  public filterMyReqs(reqFilter: ReqFilter = { status: 'active' }): QueryRef<GetMyReqsQuery> {
    return this.apollo.watchQuery<GetMyReqsQuery>({
      query: gql(getMyReqsQuery),
      variables: { reqFilter },
      fetchPolicy: 'network-only',
    });
  }

  public filterMyReqsQuery(page: Page, filters: ReqFilter, sortOption?: SortOption) {
    if (!filters.status && filters.status !== '') filters.status = 'active';

    return this.apollo.watchQuery<FilterMyReqsQuery, { page: Page; filters: ReqFilter; sortOption: SortOption }>({
      query: filterMyReqsQuery,
      variables: { page, filters, sortOption },
      fetchPolicy: 'network-only',
    });
  }

  public fitlerMyReqsQueryRef() {
    return this.apollo.watchQuery<FilterMyReqsQuery, { page: Page; filters: ReqFilter; sortOption: SortOption }>({
      query: filterMyReqsQuery,
      fetchPolicy: 'network-only',
    });
  }

  /**
   * Method to refetch all the filterMyReqsQueries by getting
   * all the current variabels and refetching them
   */
  public refetchFilterMyReqsQuery() {
    const { variables } = this.fitlerMyReqsQueryRef();
    console.log('refetchFilterMyReqsQuery', variables);
    if (!variables?.filters) variables.filters = {};
    if (!variables?.filters?.status) variables.filters.status = 'active';
    this.filterMyReqsQuery(variables.page, variables.filters, variables.sortOption).refetch();
  }

  public updateReq(reqId: string, input: UpdateReqInput): Observable<any> {
    return this.apollo.mutate<UpdateReq>({
      mutation: gql(updateReq),
      variables: { reqId, input },
      update: (store) => {
        this.filterMyReqs().refetch();
        this.refetchFilterMyReqsQuery();
      },
    });
  }

  public getReqTags(reqId: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getReqTags),
      variables: { reqId },
    }).valueChanges;
  }

  public updateReqTags(reqId: string, tags: string[]): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(updateReqTags),
      variables: { reqId, tags },
      update: (store) => {
        this.filterMyReqs().refetch();
        this.refetchFilterMyReqsQuery();
      },
    });
  }

  public updateReqOwner(reqId: string, ownerId: string, firstName: string, lastName: string): Observable<any> {
    return this.apollo.mutate<UpdateReqOwner>({
      mutation: gql(updateReqOwner),
      variables: { reqId, ownerId, firstName, lastName },
    });
  }

  public removeUserFromReq(userId: string, reqId: string): Observable<any> {
    return this.apollo.mutate<RemoveUserFromReq>({
      mutation: gql(removeUserFromReq),
      variables: { userId: userId, reqId: reqId },
    });
  }

  public getProcessTotalsForAllReqs(filters: ReqFilter): Observable<any> {
    if (!filters.status && filters.status !== '') filters.status = 'active';

    return this.apollo.watchQuery<GetProcessTotalsForAllReqs>({
      query: gql(getProcessTotalsForAllReqs),
      variables: { filters: filters },
    }).valueChanges;
  }
}
