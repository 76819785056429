import {
  Component,
  ContentChild,
  HostListener,
  ElementRef,
  EventEmitter,
  Output,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { ViewModeDirective } from './view-mode.directive';
import { EditModeDirective } from './edit-mode.directive';
import { NgControl } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import { switchMap, takeUntil, filter, take, switchMapTo } from 'rxjs/operators';
// import { untilDestroyed } from "ngx-take-until-destroy";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'editable',
  template: ' <ng-container *ngTemplateOutlet="currentView"></ng-container> ',
  styleUrls: ['./editable.component.scss'],
})
export class EditableComponent {
  @ContentChild(ViewModeDirective) viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective) editModeTpl: EditModeDirective;
  @Output() update = new EventEmitter();
  @Input() triggerEvent: string = 'dblclick';
  @Input() triggerClose: boolean = false;
  @Input() triggerEditOpen: boolean = false;
  @Input() index: number = 0;

  editMode = new Subject();
  editMode$ = this.editMode.asObservable();

  mode: 'view' | 'edit' = 'view';

  constructor(private host: ElementRef) {}

  ngOnInit() {
    this.viewModeHandler();
    this.editModeHandler();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName) && Object.keys(changes).length === 1) {
        switch (propName) {
          case 'triggerClose': {
            this.toViewMode();
            break;
          }
          case 'triggerEditOpen': {
            this.toEditMode();
            // this.editModeHandler();
            break;
          }
        }
      }
    }
  }

  toViewMode() {
    this.update.next();
    this.mode = 'view';
  }

  toEditMode() {
    this.mode = 'edit';
    this.editMode.next(true);
  }

  private get element() {
    return this.host.nativeElement;
  }

  private viewModeHandler() {
    fromEvent(this.element, this.triggerEvent)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.mode = 'edit';
        this.editMode.next(true);
      });
  }

  private editModeHandler() {
    const clickOutside$ = fromEvent(document, 'click').pipe(
      filter(({ target }) => this.element.contains(target) === false),
      take(1)
    );

    this.editMode$.pipe(switchMapTo(clickOutside$), untilDestroyed(this)).subscribe((event) => {
      this.toViewMode();
    });
  }

  get currentView() {
    return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
  }

  ngOnDestroy() {}
}
