import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'playBookTitleStr' })
export class PlayBookTitleStr implements PipeTransform {
  transform(value: string): string {
    return value?.trim().length ? value : 'My Playbook';
  }
}

@Pipe({ name: 'playBookCategoryTitleStr' })
export class PlayBookCategoryTitleStr implements PipeTransform {
  transform(value: string): string {
    return value?.trim().length ? value : 'My Category';
  }
}

@Pipe({ name: 'playBookTaskTitleStr' })
export class PlayBookTaskTitleStr implements PipeTransform {
  transform(value: string): string {
    return value?.trim().length ? value : 'My Task';
  }
}
