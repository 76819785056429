<div class="settings-page-container" fxFlex>
  <section class="integrations-main-section container">
    <div *ngIf="!dataLoaded">
      <mat-card class="contact-card">
        <mat-card-header>
          <div mat-card-avatar class="contact-card-header-image">
            <mat-icon class="muted">support_agent</mat-icon>
          </div>
          <mat-card-title>
            Call <span *ngIf="firstName || lastName">{{ firstName }} {{ lastName }}</span>
            <span *ngIf="!firstName && !lastName">History Incomplete</span>
          </mat-card-title>

          <div fxFlex fxLayoutAlign="end center">
            <button mat-icon-button (click)="onXClick()" color="gray-light" class="closeButton">
              <mat-icon matSuffix class="muted" size="sm">close</mat-icon>
            </button>
          </div>
        </mat-card-header>

        <mat-card-content>
          <div fxFlex fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- Display 1 capture Agent target and hit go  -->
    <!-- Display 2 call in progress -->
    <!-- Display 3 call complete -->
    <!-- Display 4 need to validate MFA -->
    <!-- Display 5 verify MFA Sent - enter this code -->
    <!-- Display 6 Set next action option -->

    <!-- Capture targets and go -->
    <div *ngIf="displayOption === 1 && dataLoaded">
      <mat-card class="contact-card">
        <mat-card-header>
          <div mat-card-avatar class="contact-card-header-image">
            <mat-icon class="muted">support_agent</mat-icon>
          </div>
          <mat-card-title> Call {{ firstName }} {{ lastName }} </mat-card-title>

          <div fxFlex fxLayoutAlign="end center">
            <button mat-icon-button (click)="onXClick()" color="gray-light" class="closeButton">
              <mat-icon matSuffix class="muted" size="sm">close</mat-icon>
            </button>
          </div>
        </mat-card-header>

        <mat-card-content>
          <mat-form-field class="contact-card-full-width">
            <mat-label>Your Phone Number (Caller ID)</mat-label>
            <input matInput class="form-control" appPhoneMask [(ngModel)]="agentPhone" />
          </mat-form-field>

          <mat-form-field class="contact-card-full-width">
            <mat-label>Contact Phone</mat-label>
            <input matInput appPhoneMask [(ngModel)]="contactPhone" />
          </mat-form-field>

          <div fxLayout="row">
            <div fxFlex fxLayoutAlign="end start">
              <!-- Keep user from clicking twice while waiting - let them know somthing is happening -->
              <a *ngIf="!callRequestInProgress" mat-raised-button (click)="callAgent()" color="primary">Call Now</a>
              <a *ngIf="callRequestInProgress" mat-raised-button disabled>Calling...</a>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div *ngIf="displayOption === 2 && dataLoaded">
      <mat-card class="contact-card">
        <mat-card-header>
          <div mat-card-avatar class="contact-card-header-image">
            <mat-icon class="muted">support_agent</mat-icon>
          </div>
          <mat-card-title>
            Call Status
            <a mat-button color="primary" (click)="onLinkBacktoSource()">
              {{ callStatus }}
            </a>
          </mat-card-title>

          <div fxFlex fxLayoutAlign="end center">
            <button mat-icon-button (click)="onXClick()" color="gray-light" class="closeButton">
              <mat-icon matSuffix class="muted" size="sm">close</mat-icon>
            </button>
          </div>
        </mat-card-header>

        <mat-card-content>
          <!-- <a mat-button color="primary" (click)="onLinkBacktoSource()">
                        {{callStatus}}
                    </a> -->

          <div *ngIf="callStatus != 'canceled'">
            {{ timerDisplay.hours.digit1 }}{{ timerDisplay.hours.digit2 }}:{{ timerDisplay.minutes.digit1
            }}{{ timerDisplay.minutes.digit2 }}:{{ timerDisplay.seconds.digit1 }}{{ timerDisplay.seconds.digit2 }}
          </div>

          <p *ngIf="needPreviousDispoDisplay">
            <a (click)="onLinkBacktoSource()">{{ needPreviousDispoDisplay }}</a>
          </p>

          <div *ngIf="callStatus != 'Call Completed' && callStatus && callStatus != 'canceled'">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>

          <div *ngIf="!savingDispoComplete">
            <div fxFlex fxLayoutAlign="center center">
              <mat-spinner></mat-spinner>
            </div>
          </div>

          <div *ngIf="callStatus != 'Call Completed' && callStatus != 'canceled'" style="margin-top: 1em">
            <!-- <mat-form-field class="contact-card-full-width">
              <mat-label>Transfer to Phone</mat-label>
              <input matInput appPhoneMask [(ngModel)]="transferPhone" />
            </mat-form-field> -->

            <div fxLayout="row">
              <div fxFlex fxLayoutAlign="end center" class="verify-complete">
                <!-- <a mat-raised-button (click)="setupTransferCall()" color="primary">Transfer Call</a> -->

                <a mat-raised-button (click)="terminateCall()" color="primary">End Call</a>
              </div>
            </div>
          </div>

          <div *ngIf="callStatus === 'Call Completed' || callStatus === 'canceled'">
            <hr />

            <p>What was the result of this call?</p>

            <mat-selection-list #disposition [multiple]="false" [(ngModel)]="selectedDisposition">
              <mat-list-option *ngFor="let disposition of listDispositions" [value]="disposition">
                {{ disposition }}
              </mat-list-option>
            </mat-selection-list>

            <div fxLayout="row">
              <div fxFlex fxLayoutAlign="end center" class="verify-now">
                <a mat-raised-button (click)="dispoCallComplete()" color="primary">Log Call</a>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div *ngIf="displayOption === 4 && dataLoaded">
      <mat-card class="contact-card">
        <mat-card-header>
          <div mat-card-avatar class="contact-card-header-image">
            <mat-icon class="muted">support_agent</mat-icon>
          </div>
          <mat-card-title> Verify User Phone Number </mat-card-title>

          <div fxFlex fxLayoutAlign="end center">
            <button mat-icon-button (click)="onXClick()" color="gray-light" class="closeButton">
              <mat-icon matSuffix class="muted" size="sm">close</mat-icon>
            </button>
          </div>
        </mat-card-header>

        <mat-card-content>
          <ul class="instruction-description">
            <li>This number is currently not verified.</li>
            <li>You will need to verify that your User Phone number is in your possession.</li>
            <li>You should only need to do this once per number you use.</li>
            <li>Once verified this step will be skipped next time you use this feature.</li>
          </ul>

          <mat-form-field class="contact-card-full-width">
            <mat-label>User Phone</mat-label>
            <input matInput class="form-control" appPhoneMask [(ngModel)]="agentPhone" />
          </mat-form-field>

          <div fxLayout="row">
            <div fxFlex fxLayoutAlign="end center" class="verify-now">
              <a mat-raised-button (click)="verifyCallerId()" color="primary">Verify Now</a>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div *ngIf="displayOption === 5 && dataLoaded">
      <mat-card class="contact-card">
        <mat-card-header>
          <div mat-card-avatar class="contact-card-header-image">
            <mat-icon class="muted">support_agent</mat-icon>
          </div>
          <mat-card-title> Enter this code </mat-card-title>

          <div fxFlex fxLayoutAlign="end center">
            <button mat-icon-button (click)="onXClick()" color="gray-light" class="closeButton">
              <mat-icon matSuffix class="muted" size="sm">close</mat-icon>
            </button>
          </div>
        </mat-card-header>

        <mat-card-content>
          <div *ngIf="!currentMFAError">
            <h1 class="mfaCode">{{ currentMFACode }}</h1>

            <p>
              You should momentarily be receiving a phone call at <b>{{ agentPhone }}</b
              >. You will be prompted to enter your verification code.
            </p>

            <div fxLayout="row" class="verify-complete">
              <div fxFlex fxLayoutAlign="end center">
                <a mat-raised-button (click)="displayOption = 4" color="primary">Back</a>

                <a mat-raised-button (click)="displayOption = 1" color="primary">Finished</a>
              </div>
            </div>
          </div>

          <div *ngIf="currentMFAError">
            <h4 class="mfaCode">{{ currentMFACode }}</h4>

            <p>Contact your administrator or try again later.</p>

            <div fxLayout="row" class="verify-complete">
              <div fxFlex fxLayoutAlign="end center">
                <a mat-raised-button (click)="displayOption = 4" color="primary">Back</a>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div *ngIf="displayOption === 6 && dataLoaded">
      <mat-card class="contact-card">
        <mat-card-header>
          <div mat-card-avatar class="contact-card-header-image">
            <mat-icon class="muted">support_agent</mat-icon>
          </div>
          <mat-card-title> Next Steps </mat-card-title>

          <div fxFlex fxLayoutAlign="end center">
            <button mat-icon-button (click)="onXClick()" color="gray-light" class="closeButton">
              <mat-icon matSuffix class="muted" size="sm">close</mat-icon>
            </button>
          </div>
        </mat-card-header>

        <mat-card-content>
          <p>Would you like to set a next action?</p>

          <div fxLayout="row">
            <div fxFlex fxLayoutAlign="end center" class="verify-complete">
              <a mat-raised-button (click)="onXClick()" color="primary">No Thanks</a>

              <a mat-raised-button (click)="setNextAction()" color="primary">Set Next Action Now</a>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </section>

  <footer>
    <!-- <div *ngIf="sourceLink && snackBarRef">
            <a (click)="onLinkBacktoSource()">To the Req</a>
        </div> -->
  </footer>
</div>
