export const reportingThemesArr = ['theme-news', 'theme-blues', 'theme-light', 'theme-dark'];
export type reportingThemes = 'theme-news' | 'theme-blues' | 'theme-light' | 'theme-dark';
export const reqStagesArr = ['prospect', 'candidate', 'meeting', 'offer', 'hire'];
export type reqStages = 'Prospect' | 'Candidate' | 'Meeting' | 'Offer' | 'Hire';

import { UserReq } from '../../shared/model/requisitions';
import { User } from '../../shared/model/user';
export interface esFilter {
  field: string;
  value: string[];
  valueType?: String;
  qualifier: string;
  bool: string;
}

export interface ReportObj {
  id?: string;
  name: string;
  desc?: string;
  rType?: string;
  cols: number;
  rows: number;
  order?: number;
  x?: number;
  y?: number;
  ribbon?: string;
  permission?: string;
  data?: any;
  esFilters?: esFilter[];
  index?: string;
  dateTarget?: string;
  loaded?: boolean;
  useStoredData?: boolean;
  triggerChange?: boolean;
}

export interface ReportObjMenuItem {
  name: string;
  desc: string;
  rType: string;
  cols: number;
  rows: number;
  ribbon?: string;
  permission?: string;
  data?: any;
  icon?: string;
  esFilters?: esFilter[];
}

export interface ReportTemplateSettings {
  reqIds?: string[];
  userIds?: string[];
  startDate?: string;
  endDate?: string;
  selectedReqIds?: string[];
  selectedReqs?: UserReq[];
  selectedUserIds?: string[];
  selectedUsers?: User[];
  reportingTheme?: reportingThemes;
}

export interface ReportTemplate {
  id?: string;
  pKey?: string;
  title?: string;
  desc?: string;
  reportObjects: ReportObj[];
  reportTemplateSettings: ReportTemplateSettings;
}

export interface ReportTemplateHistory {
  id: string;
  title?: string;
  desc?: string;
}

export const newReportObj: ReportObj = {
  id: '',
  name: 'New Report',
  order: 0,
  cols: 0,
  rows: 0,
};

export interface ReportingSnapshot {
  id: string;
  uploadUrl: string;
  downloadUrl: string;
  reportTemplate: ReportTemplate;
  sharedWithPublic: ReportingSnapshotPublicLink;
}

export interface ReportingSnapshotPublicLink {
  id: string;
  path: string;
}

export interface CreateReportingSnapshotInput {
  input: {
    name?: string;
    password?: string;
    shareWithPublic?: boolean;
    shareWithEmails?: string[];
    shareWithUsers?: string[];
  };
}

export interface ReadReportingSnapshotInput {
  input: {
    linkId?: string;
    snapshotId?: string;
  };
}
