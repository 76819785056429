import { Injectable } from '@angular/core';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from '../../environments/environment';

import { Observable, Observer } from 'rxjs';

export interface Callback {
  callback(): void;
  callbackWithParam(result: any): void;
}

@Injectable()
export class CognitoService {
  public static USER_POOL_ID = environment.userPoolId;
  public static CLIENT_ID = environment.clientId;
  public static POOL_DATA: any = {
    UserPoolId: environment.userPoolId,
    ClientId: environment.clientId,
  };

  getUserPool() {
    return new CognitoUserPool(CognitoService.POOL_DATA);
  }

  getCurrentUser() {
    let result = this.getUserPool().getCurrentUser();

    // https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-with-identity-providers.html
    // How long is new CognitoUserPool this good for - 1 hour by default settings
    // Can we cache it? Save trips?
    // Invalidate it on fail?
    // Why not use CognitoUser instead of CognitoUserPool?

    // **** Don't do this (or any console logs here) when tail logs - or hang on tight to the infinite loop
    // console.log('*****CognitoUserPool getCurrentUser', result);

    return result;
  }

  getAccessToken(): Observable<{ accessToken: string }> {
    const currentUser = this.getCurrentUser();
    return Observable.create((observer: Observer<{ accessToken: string }>) => {
      if (currentUser === null) {
        observer.error(new Error('CognitoService: CognitoUser cannot be null.'));
        return;
      }
      currentUser.getSession((err, session) => {
        if (err) {
          observer.error(err);
          return;
        }

        // console.log('**********Session is Access Token', session);

        if (!session.isValid()) {
          observer.error(new Error('Session is invalid'));
          return;
        }
        observer.next({ accessToken: session.getAccessToken().getJwtToken() });
        observer.complete();
      });
    });
  }

  getIdToken(): Observable<{ idToken: string }> {
    const currentUser = this.getCurrentUser();

    return Observable.create((observer: Observer<{ idToken: string }>) => {
      if (currentUser === null) {
        observer.error(new Error('CognitoService: CognitoUser cannot be null.'));
        return;
      }
      currentUser.getSession((err, session) => {
        if (err) {
          observer.error(err);
          return;
        }

        // console.log('**********Session is ID Token', session, { idToken: session.getIdToken().getJwtToken() });

        if (!session.isValid()) {
          observer.error(new Error('Session is invalid'));
          return;
        }
        observer.next({ idToken: session.getIdToken().getJwtToken() });
        observer.complete();
      });
    });
  }

  getIdTokenPayload(): Observable<{ idToken: string }> {
    const currentUser = this.getCurrentUser();
    return Observable.create((observer: Observer<{ idToken: string }>) => {
      if (currentUser === null) {
        observer.error(new Error('CognitoService: CognitoUser cannot be null.'));
        return;
      }
      currentUser.getSession((err, session) => {
        if (err) {
          observer.error(err);
          return;
        }
        // console.log('**********Session is ID Token Payload', session, session.idToken.payload);

        if (!session.isValid()) {
          observer.error(new Error('Session is invalid'));
          return;
        }
        observer.next(session.idToken.payload);
        observer.complete();
      });
    });
  }

  getRefreshToken(): Observable<{ refreshToken: string }> {
    const currentUser = this.getCurrentUser();
    return Observable.create((observer: Observer<{ refreshToken: string }>) => {
      if (currentUser === null) {
        observer.error(new Error('CognitoService: CognitoUser cannot be null.'));
        return;
      }
      currentUser.getSession((err, session) => {
        if (err) {
          observer.error(err);
          return;
        }
        // console.log('**********Session is ID Token Payload', session, {
        //  refreshToken: session.getRefreshToken(),
        // });

        if (!session.isValid()) {
          observer.error(new Error('Session is invalid'));
        }
        observer.next({ refreshToken: session.getRefreshToken() });
        observer.complete();
      });
    });
  }

  listDevices(): Observable<any> {
    let cognitoUser = this.getCurrentUser();

    return new Observable((observer: Observer<any>) => {
      if (cognitoUser != null) {
        cognitoUser.getSession((error, session) => {
          if (error) {
            // console.log('CognitoService: User not authenticated (session -> ' + error + ')', error.stack);
            observer.error(error);
            return;
          }
          cognitoUser.listDevices(60, null, {
            onSuccess: (result) => {
              let parsedResult: any = JSON.parse(JSON.stringify(result.Devices));
              let arrayOfDevices = parsedResult.map((item) => ({
                lastAuthenticated: item.DeviceLastModifiedDate,
                lastModified: item.DeviceLastModifiedDate,
                createdDate: item.DeviceCreateDate,
                deviceKey: item.DeviceKey,
                IP: item.DeviceAttributes.find((ele) => ele.Name === 'last_ip_used'),
                deviceName: item.DeviceAttributes.find((ele) => ele.Name === 'device_name'),
              }));
              observer.next(arrayOfDevices);
            },
            onFailure: (error) => {
              // console.log('CognitoService: could not list devices.', error);
              observer.error(error);
            },
          });
        });
      } else {
        // console.log('CognitoService: can\'t retrieve the current user');
        observer.next(false);
        observer.complete();
      }
    });
  }

  forgetDevices(deviceKeys): Observable<boolean> {
    let cognitoUser = this.getCurrentUser();

    return new Observable((observer: Observer<any>) => {
      if (cognitoUser != null) {
        cognitoUser.getSession(async (error, session) => {
          if (error) {
            // console.log('CognitoService: User not authenticated (session -> ' + error + ')', error.stack);
            observer.error(error);
            return;
          }

          for await (const key of deviceKeys) {
            cognitoUser.forgetSpecificDevice(key, {
              onSuccess: (result) => {
                // console.log('Forget device result', key, +result);
              },
              onFailure: (err) => {
                // console.log('Forget device error', key, err);
                observer.error(error);
              },
            });
          }
          observer.next(true);
          observer.complete();
        });
      } else {
        // console.log('CognitoService: can\'t retrieve the current user');
        observer.next(false);
        observer.complete();
      }
    });
  }
}
