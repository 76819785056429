<h1 mat-dialog-title>Activity Filter</h1>
<h1 mat-dialog-title *ngIf="isSideBar">
  <mat-button-toggle-group
    name="activitiesFilterToggle"
    aria-label="Activities Filter Toggle"
    class="pill-toggle"
    (change)="onActivityFilterClick($event)"
    [value]="filterCriteria.activity"
  >
    <mat-button-toggle value="user" matTooltip="My Activities" [matTooltipShowDelay]="700">
      <mat-icon>person</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="all" matTooltip="All Activities" [matTooltipShowDelay]="700">
      <mat-icon>group</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
</h1>
<mat-dialog-content>
  <form>
    <mat-form-field
      *ngFor="let dropdown of activityFilter.dropdowns"
      [hintLabel]="dropdown.field.hint"
      [class]="dropdown.className"
    >
      <mat-select
        [placeholder]="dropdown.field.label"
        [(ngModel)]="filterCriteria[dropdown.criteria]"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option *ngFor="let option of dropdown.options" [value]="option.value">
          {{ option.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      *ngFor="let inputField of activityFilter.inputFields"
      [hintLabel]="inputField.hint"
      [class]="inputField.className"
    >
      <input
        matInput
        [placeholder]="inputField.label"
        [(ngModel)]="filterCriteria[inputField.criteria]"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
    <mat-form-field
      *ngFor="let dateField of activityFilter.dateFields"
      [hintLabel]="dateField.hint"
      [class]="dateField.className"
    >
      <input
        matInput
        [matDatepicker]="picker"
        [placeholder]="dateField.label"
        [(ngModel)]="filterCriteria[dateField.criteria]"
        [ngModelOptions]="{ standalone: true }"
        (dateChange)="prefillOtherDate(dateField.criteria)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker startView="year" [startAt]="startDate"></mat-datepicker>
    </mat-form-field>
    <button
      mat-flat-button
      color="warn"
      [disabled]="
        !filterCriteria.type &&
        !filterCriteria.requisition &&
        !filterCriteria.dueDateStart &&
        !filterCriteria.dueDateEnd &&
        !filterCriteria.title &&
        !filterCriteria.description &&
        !filterCriteria.participant &&
        !filterCriteria.completedBy &&
        !filterCriteria.compeletedDateStart &&
        !filterCriteria.compeletedDateEnd
      "
      (click)="onClearClick()"
    >
      Clear Filter
    </button>
    <!-- && filterCriteria.activity != 'user' -->
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="warn" (click)="onCloseClick()">Close</button>
  <button mat-button color="primary" (click)="onApplyClick()">Apply</button>
</mat-dialog-actions>
