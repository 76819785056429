import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';

@Component({
  selector: 'mm-spinner',
  template: `
    <svg
      aria-label="Loading"
      [ngStyle]="{ width: calcSize, height: calcSize }"
      class="animate-spin {{ class }}"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle
        cx="8"
        cy="8"
        r="7"
        stroke="currentColor"
        stroke-opacity="0.25"
        [attr.stroke-width]="stroke"
        vector-effect="non-scaling-stroke"
      ></circle>
      <path
        d="M15 8a7.002 7.002 0 00-7-7"
        stroke="currentColor"
        [attr.stroke-width]="stroke"
        stroke-linecap="round"
        vector-effect="non-scaling-stroke"
      ></path>
    </svg>
  `,
  styleUrls: ['../nick_styles/nick.css'],
})
export class SpinnerComponent {
  @Input() size: number | string = 24;
  @Input() stroke = '2';
  @Input() class = 'text-slate-400';
  private sizeOptions = {
    xs: '16px',
    sm: '20px',
    md: '24px',
    lg: '28px',
    xl: '32px',
  };
  constructor() {}

  get calcSize() {
    if (this.class?.includes('h') || this.class?.includes('w')) return 'auto';
    if (typeof this.size === 'number') {
      return this.size + 'px';
    } else {
      return this.sizeOptions[this.size];
    }
  }
}

@NgModule({
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent],
  imports: [CommonModule],
})
export class SpinnerModule {}
