import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mm-resend-user-code-dialog',
  templateUrl: './resend-user-code-dialog.component.html',
  styleUrls: ['./resend-user-code-dialog.component.scss'],
})
export class ResendUserCodeComponent implements OnInit {
  resendUserCodeForm: FormGroup;
  resendUserCodeSub: Subscription;
  constructor(
    private dialogRef: MatDialogRef<ResendUserCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.resendUserCodeForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  resendUserCode() {
    let email = this.resendUserCodeForm.get('email').value;

    this.resendUserCodeSub = this.userService.resendConfirmationCode(email).subscribe(
      () => {
        console.log('ResendUserCodeComponent :: resendUserCode - successfully resent registration');
        this.snackBar.open('Successfully resent registration.', null, {
          duration: 3000,
        });

        this.closeDialog();
      },
      (error) => {
        console.log('ResendUserCodeComponent :: resendUserCode - encounterd an error ', error);
        this.snackBar.open('Error - Failed to resend registration', null, {
          duration: 3000,
        });

        this.closeDialog();
      }
    );
  }
}
