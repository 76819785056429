import { debounceTime } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'mm-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit {
  @Input() noFilter = false;
  @Input() colorClass: string;
  @Output() filter = new EventEmitter<string>();

  private filterSubject: Subject<string> = new Subject();

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'settings-filter',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/settings-filter.svg')
    );
  }

  ngOnInit() {
    this.filterSubject.pipe(debounceTime(500)).subscribe((searchTextValue) => {
      this.onSearch(searchTextValue);
    });
  }

  onSearch(value: string) {
    this.filter.emit(value);
  }

  applySearch(value: string) {
    this.filterSubject.next(value);
  }
}
