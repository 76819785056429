<div class="report-page-container">
  <header></header>

  <section class="report-main-section">
    <h5>Report 1</h5>

    {{ message }}
  </section>

  <footer></footer>
</div>
