<section>
  <!-- <div>{{viewStyle}}</div> -->

  <!-- navigation list for each playbook template item -->
  <div *ngIf="playbookReqContact?.playbookObj">
    <div cdkDropListGroup>
      <div class="kanban-container" fxflex fxLayout="row" fxLayoutGap="1em">
        <div
          cdkDropList
          [cdkDropListData]="column"
          (cdkDropListDropped)="dropKanban($event)"
          fxFlex="33"
          fxLayoutGap="1em"
          *ngFor="let column of columns; let index = colIndex"
          class="kanban-col"
        >
          <div class="col-header">{{ column | uppercase }}</div>

          <div
            cdkDrag
            [cdkDragData]="playbookTask"
            *ngFor="let playbookTask of playbookTasks; let index = index"
            [ngClass]="
              viewStyle === 'standard'
                ? 'kanban-task-item kanban-task-item-plain'
                : 'kanban-task-item kanban-task-item-sticky'
            "
          >
            <!-- Special style overides for the kanban task mat-card -->
            <mat-card
              *ngIf="getReqContactTask(playbookTask.id).status === column"
              [ngStyle]="
                viewStyle === 'standard'
                  ? { padding: '.5em 1em', 'min-width': '70px' }
                  : { 'background-color': 'transparent', 'box-shadow': 'none', 'padding-left': 0 }
              "
            >
              <mat-card-content>
                <i class="pin"></i>
                <div
                  class="note cardStyle"
                  [ngStyle]="
                    viewStyle === 'sticky' && playbookTask.color
                      ? { 'background-color': playbookTask.color }
                      : { 'border-left': '.2em solid ' + playbookTask.color }
                  "
                >
                  <div fxLayout="row">
                    <div fxFlex class="item-title">
                      {{ playbookTask.title }}
                    </div>

                    <div fxLayoutAlign="end start" class="item-edit-icon">
                      <button mat-icon-button color="primary" class="task-edit-button">
                        <mat-icon
                          matSuffix
                          class="muted"
                          size="sm"
                          (click)="onEditTask($event, index, playbookTask.id)"
                        >
                          mode_edit</mat-icon
                        >
                      </button>
                    </div>
                  </div>

                  <div fxLayout="row">
                    <!--
                                            'background-color':'#eae672'

fxFlexAlign="stretch"
Test text
Babel is a JavaScript compiler. It takes next generation JavaScript
(ES6/ES7/ES8) and compiles it down to widely supported ES5
 JavaScript code.
 -->
                    <div fxFlex class="item-desc">
                      <span fxHide.lt-md class="task-desc">{{ playbookTask.desc }}</span>
                    </div>
                  </div>

                  <div fxLayout="row">
                    <div
                      *ngIf="getReqContactTask(playbookTask.id).status !== 'complete'"
                      fxFlex
                      fxLayoutAlign="start end"
                      [innerHTML]="getDSE(playbookTask.dueDSE)"
                    ></div>

                    <div fxFlex fxLayoutAlign="end end">
                      <cite class="author">{{ getAssignedPrimaryMember(getReqContactTask(playbookTask.id)) }}</cite>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Debug info

    cdkDropListSortingDisabled -->

  <!-- 
        <div>
        <h5>Requisition Id: {{reqId}}</h5>
        <h5>Contact Id: {{contactId}}</h5>
        <h5>Playbook Id: {{playbookTemplateId}}</h5>
        <h5>catId Index: {{catId}}</h5>

    </div>

    <div class="">
        <pre class=" language-javascript">{{playbookReqContact| json}}</pre>
        <pre class=" language-javascript">{{playbookReqContact?.playbookObj| json}}</pre>

        <h3>playbookTemplate</h3>
        <pre class=" language-javascript">{{playbookTemplate | json}}</pre>


    </div> -->

  <!-- <div class="">
        <pre class=" language-javascript">{{playbookReqContact| json}}</pre>
        <pre class=" language-javascript">{{playbookReqContact?.playbookObj| json}}</pre>

        <h3>playbookTemplate</h3>
        <pre class=" language-javascript">{{playbookTemplate | json}}</pre>


    </div> -->
</section>
