import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './services/user.service';

/**
 * Route guard to ensure that a user is logged in.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<true | UrlTree> {
    console.log('AuthenticationGuard: ', state.url);
    const isAuthenticated = await this.userService.isAuthenticated().toPromise();
    if (isAuthenticated) {
      return true;
    }
    // If not logged in, save query params URL to user service variable
    // Then redirect to log in
    // state.url is the originally requested route

    this.userService.urlPath = state.url;

    // Goofy Hack
    // Router is caching last loaded component/path combo so login dialog is not displayed if it is already the last loaded component/path combo
    // No lifecycle event to detect this
    // Add secondary path with date time stamp to force load alternating component/path combos

    if (this.userService.loginLast.length < 7) {
      const d = new Date();
      console.log('curr date', d.getTime());
      // this.userService.loginLast = `/login/${d.getTime()}`;
      return this.router.parseUrl(`/login/${d.getTime()}`);
    } else {
      // this.userService.loginLast = '/login';
      return this.router.parseUrl('/login');
    }
  }
}
