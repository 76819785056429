import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTitle',
})
export class FormatTitlePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case undefined:
        return '';
      default:
        return this.isCamelCase(value) ? this.camelToTitleCase(value) : this.toTitleCase(value);
    }
  }

  isCamelCase(value: string): boolean {
    return /^([a-z]+[A-Z][a-z]*)+$/.test(value);
  }

  camelToTitleCase(value: string): string {
    return value.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, (letter) => letter.toUpperCase());
  }

  toTitleCase(value: string): string {
    return value.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  }
}
