<svg viewBox="0 0 99 66">
  <defs>
    <linearGradient id="a" x1=".5" y1="33" x2="65.75" y2="33" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#3b3b3d" />
      <stop offset="1" stop-color="#cfd0d2" />
    </linearGradient>
    <linearGradient id="b" x1="33.25" y1="33" x2="98.5" y2="33" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#396eb5" />
      <stop offset="1" stop-color="#20386c" />
    </linearGradient>
  </defs>
  <path
    d="M65.75 36A32.76 32.76 0 0 1 .5 36h7.77a25.24 25.24 0 0 0 24.86 22.51A25.26 25.26 0 0 0 58 36zM58 30A25.26 25.26 0 0 0 33.13 7.49 25.24 25.24 0 0 0 8.27 30H.5a32.76 32.76 0 0 1 65.25 0z"
    style="fill: url(#a)"
  />
  <path
    d="M41 36a25 25 0 0 0 49.72 0h7.78a32.76 32.76 0 0 1-65.25 0zm-7.76-6a32.76 32.76 0 0 1 65.26 0h-7.77A25 25 0 0 0 41 30z"
    style="fill: url(#b)"
  />
</svg>
