<h1 mat-dialog-title>Edit Pipeline</h1>
<mat-dialog-content>
  <form [formGroup]="requisitionForm">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="title" [value]="title" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="summary" [value]="summary"></textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Default Playbook</mat-label>
      <mat-select
        single
        [value]="playbookTemplateId"
        formControlName="playbookTemplateId"
        (selectionChange)="changeDefaultPlaybookTemplate($event)"
      >
        <mat-option *ngFor="let playbookTemplate of playbookTemplates" [value]="playbookTemplate.id">
          {{ playbookTemplate.title }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="warn" [mat-dialog-close]>Cancel</button>
  <button mat-button color="primary" [mat-dialog-close]="requisitionForm.value" [disabled]="!requisitionForm.valid">
    Update
  </button>
</mat-dialog-actions>

<section *ngIf="isAdmin || isTenantAdmin || isTenantLeader || isReqOwner" style="margin: 2em 0">
  <hr />
  <h4 style="margin: 1em 0 0 0">Change Pipeline Owner</h4>
  <p class="mat-caption" style="margin: 0 0 2em 0">(Pipeline Owner or Admins Only)</p>

  <mat-form-field>
    <mat-label>Pipeline Owner</mat-label>
    <mat-select single [(value)]="currentOwnerId" (selectionChange)="changeReqOwner($event)">
      <mat-option *ngFor="let reqUser of reqUsers" [value]="reqUser.id">
        {{ reqUser.firstName }} {{ reqUser.lastName }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</section>

<section style="margin: 2em 0">
  <hr />
  <h4 style="margin: 1em 0 0 0">Pipeline Type</h4>
  <div class="p-4 flex flex-col w-96">
    <!-- <p class="text-center my-4" *ngIf="assignedTags.length > 0">Assigned Tags:</p> -->
    <div class="flex flex-wrap -m-1 mb-4" >
      <div *ngFor="let tag of assignedTags; let i = index"
           class="bg-indigo-50 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 m-1 flex items-center">
        {{ tag }}
        <button (click)="removeTag($event, tag)"
                class="text-red-500 hover_text-red-700 ml-2"
                >
          x
        </button>
      </div>
    </div>
    <!-- <hr class="my-2 font-bold" *ngIf="assignedTags.length > 0"> -->
    <!-- <p class="text-center my-4">Available Tags:</p> -->
   
    <div class="flex flex-wrap -m-1 mb-4">
      <div *ngFor="let tag of tags; let i = index" (click)="assignTag(tag)"
           class="bg-indigo-50 rounded-full cursor-pointer px-3 py-1 text-sm font-semibold text-gray-700 m-1 flex items-center">
        {{ tag }}
       
      </div>
    </div>
  
    <div class="flex ">
      <input type="text" [(ngModel)]="newTag" (keyup.enter)="addTag()" (focus)="getSuggestions()" (keyup)="getSuggestions()" 
             class="shadow appearance-none focus_outline-none border rounded py-2 pl-2 text-grey-darker flex-1">
             <ul *ngIf="suggestions.length > 0" class="absolute bg-white shadow-lg mt-10 z-10 max-h-60 overflow-y-auto">
              <li *ngFor="let suggestion of suggestions" (click)="selectSuggestion(suggestion)" class="p-2 hover_bg-gray-200 cursor-pointer">
                {{ suggestion }}
              </li>
            </ul>
      <button (click)="addTag()"
              class="bg-indigo-500 text-white text-sm font-bold py-2 px-4 rounded-lg">
        Add New Type
      </button>
    </div>
  </div>
  
</section>
