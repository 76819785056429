import { Component, OnInit, OnDestroy, Inject, Output, EventEmitter, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mm-meeting-disp-dialog',
  templateUrl: './meeting-disp-dialog.component.html',
  styleUrls: ['./meeting-disp-dialog.component.scss'],
})
export class MeetingDispositionDialog implements OnInit, OnDestroy {
  dispositions: string[] = ['Took Place', 'No Show', 'Will Reschedule', 'Move to Offer'];
  constructor(
    public dialogRef: MatDialogRef<MeetingDispositionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.data.disposition = '';
  }

  handleSelect(val) {
    this.data.disposition = val;
  }

  onComplete() {
    this.dialogRef.close(true);
  }

  ngOnDestroy() {}
}
