<mm-create-activity-popover
  id="viewTodoCard"
  *ngIf="viewTodo"
  [viewOnly]="viewTodo"
  (closeEditTodo)="closeTodo($event)"
>
</mm-create-activity-popover>

<div class="pl-1 my-1 " *ngIf="isReqContactPage">
  <mm-context-menu 
  label="Log Activity"
  verticalPosition="top"
  horizontalPosition="right"
  shiftUp="40"
  buttonColor="light"
  buttonSize="sm"
  >
  
  <div *ngFor="let activity of loggedActivities">
    <p class="cursor-pointer px-3 py-1 hover_bg-indigo-100" (click)="logActivity(activity.key)">{{activity.name}}</p>
  </div>
</mm-context-menu>
</div>

<mat-table
  [dataSource]="historyTableSource.data"
  matSort
  matSortActive="completed-start-date"
  matSortDirection="desc"
  class="history-table accent-table activity-table"
  (matSortChange)="sortData($event)"
>
  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Type" [matTooltipShowDelay]="700">
      Type
    </mat-header-cell>
    <mat-cell *matCellDef="let history" (click)="onHistoryClick(history)">
      <mat-icon *ngIf="history.type === 'NOTE'" color="muted-light">create</mat-icon>
      <mat-icon *ngIf="history.type === 'CALL'" color="muted-light">call</mat-icon>
      <mat-icon *ngIf="history.type === 'EDITEDNOTE'" color="muted-light">history</mat-icon>
      <mat-icon *ngIf="history.type === 'ADD' || history.type === 'CREATED'" color="muted-light">add</mat-icon>
      <mat-icon
        *ngIf="history.type === 'STAGE' || history.type === 'OTHER' || history.type === 'UPDATED'"
        color="muted-light"
        >arrow_forward</mat-icon
      >
      <mat-icon *ngIf="history.type === 'MEETING'" color="muted-light">group</mat-icon>
      <mat-icon *ngIf="history.type === 'TASK'" color="muted-light">done</mat-icon>
      <mat-icon *ngIf="history.type === 'DOCUMENT'" color="muted-light">attach_file</mat-icon>
      <mat-icon *ngIf="history.type === 'BONZO'" color="muted-light"
        ><img class="nav-logo" src="assets/images/integrations/bonzo-b.svg" class="imgLogo"
      /></mat-icon>

      <img
        *ngIf="history.type === 'TOTALEXPERT'"
        src="assets/images/integrations/totalexpertlogo.svg"
        class="img-logo"
      />
      <img
        *ngIf="history.type === 'HUBSPOT'"
        src="assets/images/integrations/hubspotlogo.svg"
        class="img-logo"
      />
      <img
        *ngIf="history.type === 'SALESFORCE'"
        src="assets/images/integrations/salesforce.svg"
        class="img-logo"
      />

      <mat-icon *ngIf="history.type === 'EMAIL'" color="muted-light">email</mat-icon>
      <mat-icon *ngIf="history.type === 'ASSIGNED'" color="muted-light">assignment_ind</mat-icon>
      <fa-icon
        *ngIf="history.type === 'PIPELINE'"
        style="font-size: 20px; position: relative; left: 3px; color: #c8c8c8"
        color="muted-light"
        [icon]="['fas', 'stream']"
      ></fa-icon>
      <mat-icon *ngIf="history.type === 'SMS'" color="muted-light">chat_bubble_outline</mat-icon>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Title" [matTooltipShowDelay]="700"
      >Title
    </mat-header-cell>
    <mat-cell
      *matCellDef="let history"
      [matTooltip]="history.type === 'NOTE' ? 'View/Edit Note' : history.description"
      [matTooltipShowDelay]="700"
      (click)="onHistoryClick(history)"
    >
      <div>
        {{ history.title }}
        {{ history.disposition && '(' + history.disposition + ')' }}
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="contacts">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Contacts" [matTooltipShowDelay]="700"
      >Contacts
    </mat-header-cell>

    <!-- scss for tables is doing something goofy here - override -->
    <mat-cell *matCellDef="let history" (click)="onHistoryClick(history)" style="height: auto; line-height: normal">
      <mat-chip-list *ngIf="history.contacts">
        <mat-chip
          *ngFor="let contact of history.contacts.slice(0, 2)"
          [color]="'accent'"
          (click)="onContactClick(contact, history)"
          [matTooltip]="contact.firstName + ' ' + contact.lastName"
          [matTooltipShowDelay]="300"
        >
          <mat-chip-avatar class="chipAvatar"
            ><img
              *ngIf="avatarUrls[contact.id]"
              onerror="this.src='/assets/images/contact_placeholder_small.png'"
              [src]="avatarUrls[contact.id]"
            /><span *ngIf="!avatarUrls[contact.id]" class="avatarName contactAvatar"
              >{{ contact.firstName[0] | titlecase }}{{ contact.lastName[0] | titlecase }}</span
            ></mat-chip-avatar
          >
          <span *ngIf="!(history.contacts.length > 1)" class="nameWrapper"
            >{{ contact.firstName }} {{ contact.lastName }}</span
          ></mat-chip
        >
      </mat-chip-list>
      <a *ngIf="history.contacts && history.contacts.length > 2" mat-button class="link"
        >...{{ history.contacts.length - 2 }} more</a
      >
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="completed-by">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Completed By" [matTooltipShowDelay]="700">
      Completed By</mat-header-cell
    >
    <mat-cell
      *matCellDef="let history"
      (click)="onHistoryClick(history)"
      [matTooltip]="history.completedBy ? history.completedBy.firstName + ' ' + history.completedBy.lastName : ''"
      [matTooltipShowDelay]="300"
    >
      <mat-chip-list *ngIf="history.completedBy">
        <mat-chip [color]="'compliment'">
          <mat-chip-avatar
            ><img
              class="avatarImg"
              *ngIf="history.completedBy?.avatar"
              onerror="this.src='/assets/images/contact_placeholder_small.png'"
              src="https://upload.wikimedia.org/wikipedia/commons/3/34/Macaca_nigra_self-portrait_%28rotated_and_cropped%29.jpg"
            /><span *ngIf="!history.completedBy?.avatar" class="avatarName userAvatar"
              >{{ history.completedBy.firstName[0] | titlecase }}{{ history.completedBy.lastName[0] | titlecase }}</span
            ></mat-chip-avatar
          >
          <span>{{ history.completedBy.firstName }} {{ history.completedBy.lastName }}</span></mat-chip
        >
      </mat-chip-list>
      <!-- <div *ngIf="history.completedBy">{{ history.completedBy.firstName }} {{ history.completedBy.lastName }}</div> -->
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="requisition">
    <mat-header-cell *matHeaderCellDef matTooltip="Pipeline" [matTooltipShowDelay]="700">
      Pipeline</mat-header-cell
    >
    <mat-cell
      *matCellDef="let history"
      (click)="onHistoryClick(history)"
      [matTooltip]="history.requisition"
      [matTooltipShowDelay]="700"
    >
      <a mat-button (click)="onRequisitionClick(history)">{{ history.reqId | todoReqName: allReqs }}</a>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="stage">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Stage" [matTooltipShowDelay]="700"
      >Stage
    </mat-header-cell>
    <mat-cell
      *matCellDef="let history"
      (click)="onHistoryClick(history)"
      [matTooltip]="getStage(history)"
      [matTooltipShowDelay]="700"
      >{{ getStage(history) }}
    </mat-cell>
  </ng-container>
  <!-- <ng-container matColumnDef="due-date">
        <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Scheduled or Due Date" [matTooltipShowDelay]="700">Due Date</mat-header-cell>
        <mat-cell *matCellDef="let history" (click)="onHistoryClick(history)" [matTooltip]="history.dueDate" [matTooltipShowDelay]="700"><div>{{ history.dueOn | date: 'MM/dd/yy' }}</div></mat-cell>
    </ng-container> -->
  <ng-container matColumnDef="completed-start-date">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Completed Start Date" [matTooltipShowDelay]="700"
      >Completed Date</mat-header-cell
    >
    <mat-cell *matCellDef="let history" (click)="onHistoryClick(history)">
      <div>
        {{ history.completedDate | date: 'MM/dd/yy' }}
      </div>
    </mat-cell>
  </ng-container>

  <!-- <ng-container matColumnDef="meeting-disposition">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Disposition" [matTooltipShowDelay]="700"
      >Disposition</mat-header-cell
    >
    <mat-cell *matCellDef="let history" (click)="onHistoryClick(history)">
      {{ history.disposition || '' }}
    </mat-cell>
  </ng-container> -->

  <!--Requistions History Filters-->
  <ng-container matColumnDef="type-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field table-dropdown">
        <mat-select
          [(value)]="typeFilterValue"
          (valueChange)="applyFilter('type', $event.toString())"
          [(ngModel)]="activeFilters['type']"
        >
          <mat-option *ngFor="let option of typeDropOptions" [value]="option.value">
            {{ option.label || option.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="title-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input matInput (keyup)="applyFilter('title', $event.target.value)" [(ngModel)]="activeFilters['title']" />
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="contacts-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input
          matInput
          (keyup)="applyFilter('participant', $event.target.value)"
          [(ngModel)]="activeFilters['participant']"
        />
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="completed-by-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input
          matInput
          (keyup)="applyFilter('completedBy', $event.target.value)"
          [(ngModel)]="activeFilters['completedBy']"
        />
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="requisition-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field table-dropdown">
        <mat-select (valueChange)="applyFilter('requisition', $event)" [(ngModel)]="activeFilters['requisition']">
          <mat-option *ngFor="let option of reqOptions" [value]="option.value">
            {{ option.title }}
          </mat-option>
        </mat-select>
        <!-- <input matInput (keyup)="applyFilter('requisition', $event.target.value)"> 
                 (selectionChange)="applyFilter('requisition', $event.target.value)"-->
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="stage-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field table-dropdown">
        <mat-select (valueChange)="applyFilter('stage', $event)" [(ngModel)]="activeFilters['stage']">
          <mat-option *ngFor="let option of stageOptions" [value]="option.value">
            {{ option.label || option.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <!-- <ng-container matColumnDef="due-date-filter" >
        <mat-header-cell *matHeaderCellDef>
            <mat-form-field appearance="outline" class="table-filter-field date-field">
                <input matInput [matDatepicker]="picker" 
                        (dateChange)="applyFilter('dueDateStart', $event.target.value)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="year" [startAt]="startDate"></mat-datepicker>
            </mat-form-field>
            <mat-icon matDatepickerToggleIcon (click)="clearDate('dueDateStart')">clear</mat-icon>
        </mat-header-cell>
    </ng-container> -->
  <!-- <input matInput [matDatepicker]="picker" (keyup)="applyFilter('dueDateStart', $event.target.value)">  -->

  <ng-container matColumnDef="completed-start-date-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field" floatLabel="never">
        <sat-datepicker-toggle matSuffix [for]="resultPicker"></sat-datepicker-toggle>
        <input matInput placeholder="" [satDatepicker]="resultPicker" (dateChange)="onDateChange($event.value)" />
        <sat-datepicker #resultPicker [rangeMode]="true"></sat-datepicker>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>

  <!-- /Requistions Todo Filters-->

  <ng-container matColumnDef="users">
    <mat-header-cell *matHeaderCellDef
      >Users:
      <mat-chip-list #chipList>
        <mat-chip
          id="userReqChip"
          *ngFor="let user of users"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="removeUser(user)"
        >
          {{ user.firstName + ' ' + user.lastName }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-header-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedHistoryColumns; sticky: true"></mat-header-row>
  <mat-header-row *matHeaderRowDef="['users']" [ngClass]="{ 'is-hidden': users.length == 0 }"></mat-header-row>
  <mat-header-row *matHeaderRowDef="displayedFilterColumns" class="filter-row"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: displayedHistoryColumns"
    (click)="row.type === 'add' ? onContactRowClick(row) : null"
  ></mat-row>
</mat-table>
<app-paginator *ngIf="show" [page]="_pageHistory._value" (pageChanged)="onChange($event)"></app-paginator>
