import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ContactService } from 'app/services/contact.service';
import { Subject, Subscription } from 'rxjs';
import { ReqContact, SearchResult, UserContact, ContactTag } from './../model/contacts';
import { Router } from '@angular/router';
import { RequisitionService } from 'app/services/requisition.service';
import { Requisition } from 'app/shared/model/requisitions';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'mm-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss'],
})
export class SearchDialogComponent implements OnInit, OnDestroy {
  isProgress: boolean = false;
  searchResult: SearchResult;
  searchKey: string;
  requisitions: Requisition[] = [];
  myReqSub: Subscription;

  private filterSubject: Subject<string> = new Subject();
  constructor(
    public dialogRef: MatDialogRef<SearchDialogComponent>,
    private contactService: ContactService,
    private reqService: RequisitionService,
    private router: Router
  ) {}

  ngOnInit() {
    this.myReqSub = this.reqService.getMyReqs().subscribe(
      (result) => {
        console.log('SearchDialogComponent: loaded my reqs ', result.data.getMyReqs);
        this.requisitions = result.data.getMyReqs;
      },
      (error) => {
        console.log('SearchDialogComponent: error loading reqs == ', error);
      }
    );

    this.filterSubject.pipe(debounceTime(500)).subscribe((searchTextValue) => {
      this.searchMyData(searchTextValue);
    });
  }

  search(filterValue: any) {
    this.filterSubject.next(filterValue);
  }

  searchMyData(searchKey: string) {
    this.isProgress = true;
    if (searchKey) {
      this.contactService.searchMyData(searchKey).subscribe(
        (result) => {
          console.log('SearchDialogComponent :: searchMyData result ', result.data.searchMyData);
          this.searchResult = result.data.searchMyData;
          this.isProgress = false;
        },
        (error) => {
          console.log('SearchDialogComponent :: searchMyData error ', error);
          this.isProgress = false;
        }
      );
    } else {
      this.searchResult = {};
      this.isProgress = false;
    }
  }

  getReqNam(contact: ReqContact) {
    let reqId = contact.reqId;
    let req = this.requisitions.find((req) => req.reqId === reqId);
    return req ? req.title : '';
  }

  getContactTagName(result: UserContact) {
    let tags: ContactTag[] = result.tags;
    let filterdTags = tags.filter((tag) => tag.name.toLocaleLowerCase().includes(this.searchKey.toLocaleLowerCase()));
    var tagName = filterdTags[0].name;
    for (let index = 1; index < filterdTags.length; index++) {
      tagName += ', ' + filterdTags[index].name;
    }
    let name = `${result.firstName} ${result.lastName}`;
    return `${name} - ${tagName}`;
  }

  getReqTagName(result: ReqContact) {
    let tags: ContactTag[] = result.tags;
    let filterdTags = tags.filter((tag) => tag.name.toLocaleLowerCase().includes(this.searchKey.toLocaleLowerCase()));
    var tagName = filterdTags[0].name;
    for (let index = 1; index < filterdTags.length; index++) {
      tagName += ', ' + filterdTags[index].name;
    }
    let name = `${result.firstName} ${result.lastName}`;
    let reqName = this.getReqNam(result);
    return `${name} - ${reqName} - ${tagName}`;
  }

  showPhones(result: UserContact) {
    let phoneNum = '';
    for (const phone of result.phones) {
      if (phone.number.includes(this.searchKey)) {
        if (phoneNum.length > 0) {
          phoneNum += ' , ';
        }
        let arr = phone.number.split(this.searchKey);
        phoneNum += `${arr[0]}<span class="highlightText">${this.searchKey}</span>${arr[1]}`;
      }
    }
    return `&nbsp;<span> - ${phoneNum}</span>`;
  }

  showEmails(result: UserContact) {
    let emailAddress = '';
    for (const email of result.emails) {
      if (email.address.toLowerCase().includes(this.searchKey.toLowerCase())) {
        if (emailAddress.length > 0) {
          emailAddress += ' , ';
        }
        let arr = email.address.toLowerCase().split(this.searchKey.toLowerCase());
        emailAddress += `${arr[0]}<span class="highlightText">${this.searchKey.toLowerCase()}</span>${arr[1]}`;
      }
    }
    return `&nbsp;<span> - ${emailAddress}</span>`;
  }

  showNmls(result: UserContact) {
    let nmls = '';
    let arr = result.nmlsId.toString().split(this.searchKey);
    nmls += `${arr[0]}<span class="highlightText">${this.searchKey}</span>${arr[1]}`;
    return `&nbsp;<span> - ${nmls}</span>`;
  }

  openContact(result: UserContact) {
    // Navigate to single-contact
    this.router.navigate(['contacts', result.contactId]);
    this.dialogRef.close();
  }

  openReqContact(result: ReqContact) {
    // Navigate to single-contact
    this.router.navigate(['contacts', result.contactId, result.reqId]);
    this.dialogRef.close();
  }

  viewMore(key: string, filterKey: string) {
    let activeFilters = {};
    switch (key) {
      case 'contact':
        if (filterKey === 'phone') {
          activeFilters['primaryPhone'] = this.searchKey;
          activeFilters['secondaryPhone'] = this.searchKey;
        } else if (filterKey === 'email') {
          activeFilters['primaryEmail'] = this.searchKey;
          activeFilters['secondaryEmail'] = this.searchKey;
        } else {
          activeFilters[filterKey] = this.searchKey;
        }
        this.contactService.setCachedFilter(activeFilters);
        // Navigate to contacts page with filters
        this.router.navigate(['contacts']);
        break;
      case 'company':
        activeFilters = {
          company: filterKey ? filterKey : this.searchKey,
        };
        this.contactService.setCachedFilter(activeFilters);
        // Navigate to contacts page with filters
        this.router.navigate(['contacts']);
        break;
      case 'contactTag':
        activeFilters = {
          tags: this.searchKey,
        };
        this.contactService.setCachedFilter(activeFilters);
        this.router.navigate(['contacts']);
        break;
      case 'reqTag':
        activeFilters = {
          tags: this.searchKey,
        };
        this.reqService.setCachedProcessFilter(activeFilters);
        this.router.navigate(['process-totals']);
        break;
      case 'requisition':
        activeFilters = {
          reqName: filterKey ? filterKey : this.searchKey,
        };
        this.reqService.setCachedProcessFilter(activeFilters);
        this.router.navigate(['process-totals']);
        break;
    }

    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.myReqSub.unsubscribe();
  }
}
