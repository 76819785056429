import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ChartDataSets, ChartOptions, Chart } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ReportingService } from '../../../services/reporting.service';
import { take } from 'rxjs/operators';
import { esFilter, reportingThemes } from '../../../shared/model/reporting';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Requisition, UserReq } from '../../../shared/model/requisitions';
import { Tenant } from '../../../shared/model/tenant';
import { ReportObjectSettingsDialogComponent } from '../../pickers/report-object-settings/report-object-settings-dialog.component';
import { format } from 'date-fns';
import { User } from '../../../shared/model/user';
import { Page } from '../../../shared/model/filter';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Contact, ContactTag } from '../../../shared/model/contacts';
import { SequenceTemplate } from '../../../shared/model/sms';

/**
 * This is a report object for Reporting and Analytics
 */
@Component({
  selector: 'mm-report-obj-eight',
  templateUrl: './report-eight.component.html',
  styleUrls: ['./report-eight.component.scss'],
})
export class ReportEightComponent {
  @Output() objDataChanged: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() loaded: EventEmitter<any> = new EventEmitter();
  @Output() clone: EventEmitter<any> = new EventEmitter();
  @Input() contactIds: string[];
  @Input() reqIds: string[];
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() data: any;
  @Input() userIds: string[];
  // @Input() users: User[];
  @Input() esFilters: esFilter[];
  @Input() useStoredData: boolean = false;
  @Input() reqs: UserReq[] = [];
  @Input() tenants: Tenant[];
  @Input() reportingTheme: reportingThemes = 'theme-news';
  @Input() rType: string = 'mm-report-obj-eight';
  @Input() preview: boolean = false;
  @Input() triggerChange: boolean = false;
  @Input() requisitions: Requisition[];
  @Input() reqFiltersUsersGlobal: User[] = [];
  @Input() contactTags: ContactTag[] = [];
  @Input() sequenceItems: SequenceTemplate[] = [];

  loading: boolean = false;
  lastAction: string = '';
  raw: any;
  rawDetails: any;
  intiComplete: boolean = false;

  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>([]);
  page: Page = { page: 1, pageSize: 5 };

  public lineChartData: ChartDataSets[] = [{ data: [], label: 'Series A' }];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
      labels: {
        fontColor: 'white',
        // fontWeight: 100,
        fontSize: 10,
      },
    },
    // tooltips: {
    //   callbacks: {
    //     label: function (tooltipItem) {
    //       console.log(tooltipItem)
    //       return tooltipItem.yLabel;
    //     }
    //   }
    // },
    elements: {
      line: {
        tension: 0,
      },
    },
    title: {
      display: false,
      // text: 'Chart.js Line Chart'
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: true,
            color: 'rgba(255,255,255,0.1)',
            borderDash: [22, 2],
            drawTicks: false,
          },
          ticks: {
            padding: 20,
          },
          scaleLabel: {
            display: false,
            // labelString: "Day",
          },
        },
      ],
      yAxes: [
        {
          display: true,
          gridLines: {
            display: true,
            color: 'rgba(255,255,255,0.1)',
            borderDash: [22, 2],
            drawTicks: false,
          },
          ticks: {
            padding: 20,
            beginAtZero: true,
          },
          scaleLabel: {
            display: false,
            // labelString: "Count",
          },
        },
      ],
    },
  };

  public lineChartColors: Color[] = [
    {
      borderColor: 'white', // line color
      backgroundColor: 'rgba(255,255,255,0.1)', // fill under line color
      pointBackgroundColor: 'white', // point color
      // pointRadius: 1,
      borderWidth: 2,
    },
  ];
  public lineChartLegend = true;
  public lineChartPlugins = [];
  public subText = 'Contact performance metric';

  constructor(
    private reportingService: ReportingService,
    private reportPickerDialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    // Read the data

    if (!this.data) this.data = {};
    if (!this.data.color) this.data.color = 'box-color-3';
    if (!this.data.aggregation) this.data.aggregation = '';
    if (!this.data.aggs) this.data.aggs = '';
    if (!this.data.sort) this.data.sort = '';
    if (!this.data.size) this.data.size = 0;
    if (!this.data.index) this.data.index = ['history'];
    if (!this.data.dateTarget) this.data.dateTarget = 'completedDate';
    if (!this.data.esFilters) this.data.esFilters = '';
    if (!this.data.name) this.data.name = '';
    if (!this.data.interval) this.data.interval = '';
    if (!this.data.selectedReqs) this.data.selectedReqs = [];
    if (!this.data.selectedReqIds) this.data.selectedReqIds = [];
    if (!this.data.selectedUsers) this.data.selectedUsers = [];
    if (!this.data.selectedUserIds) this.data.selectedUserIds = [];
    if (!this.data.chartType) this.data.chartType = 'line';
    if (!this.data.startDate) this.data.startDate = '';
    if (!this.data.endDate) this.data.endDate = '';
    if (!this.data.reqStage) this.data.reqStage = '';
    if (!this.data.tagsFilter) this.data.tagsFilter = '';
    if (!this.data.sequenceFilter) this.data.sequenceFilter = '';
    if (!this.data.sequenceStep) this.data.sequenceStep = '';
    if (!this.data.showReqStageOptions) this.data.showReqStageOptions = false;
    if (!this.data.filters) this.data.filters = '';
    if (!this.data.symbol) this.data.symbol = '';
    if (!this.data.ignoreReqFilter) this.data.ignoreReqFilter = 0;
    if (!this.data.yStepSize) this.data.yStepSize = '1';
    if (!this.data.endPoint) this.data.endPoint = '';

    this.data.slideRight === 'true' || this.data.slideRight === true
      ? (this.data.slideRight = true)
      : (this.data.slideRight = false);
    // Array of objects for controlling what details are shown
    if (!this.data.details)
      this.data.details = [
        { field: 'type', display: 'Type' },
        { field: 'title', display: 'Title' },
        { field: this.data.dateTarget, display: 'Date' },
        { field: 'reqId', display: 'Requisition' },
        { field: 'contacts', display: 'Contacts' },
        { field: 'completedBy', display: 'Source' },
      ];

    this.data.details.forEach((ele) => {
      this.displayedColumns.push(ele.field);
    });

    // Set default font styles
    Chart.defaults.global.defaultFontColor = 'white';

    // Careful... Dollar sign for decimals or something else large crashes the chart when set to integers
    if (this.data.yStepSize && this.data.symbol != '$' && parseInt(this.data.yStepSize) > 0) {
      this.lineChartOptions.scales.yAxes[0].ticks.stepSize = this.data.yStepSize;
    }

    this.loadHistoryCounts();

    this.intiComplete = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      // console.log("changes propName", propName);
      //  && Object.keys(changes).length === 1
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'startDate':
          case 'endDate': {
            break;
          }
        }
      }
    }

    if (this.intiComplete) this.loadHistoryCounts();

    // console.log('ReportEightComponent ngOnChanges this.intiComplete', changes, this.intiComplete);
  }

  loadHistoryCounts() {
    if (this.loading) return;

    this.loading = true;

    // Limit to specified date range
    const startDate_dt = new Date(this.data.startDate ? this.data.startDate : this.startDate);
    const endDate_dt = new Date(this.data.endDate ? this.data.endDate : this.endDate);

    // Dynamic interval or use forced overload
    let interval = this.data.interval;

    if (!interval) {
      interval = 'day';

      // under 32 - use day
      // over 31 & under 120 - use week
      // over 120 - use month
    }

    /***
     *
     * There are many occasions when aggregations are required but search hits are not. For these cases the hits can be ignored by setting size=0.
     * With extended_bounds setting, you now can "force" the histogram aggregation to start building buckets on a specific min value and also keep on building buckets up to a max value (even if there are no documents anymore).
     *
     * ***/

    if (!this.data.aggs)
      this.data.aggregation = JSON.stringify({
        type: 'date_histogram',
        target: this.data.dateTarget,
        options: {
          interval: interval,
          min_doc_count: 0,
          extended_bounds: {
            min: startDate_dt,
            max: endDate_dt,
          },
        },
      });

    if (typeof this.data.aggs === 'object') {
      // console.log('this.data.aggregation parse now before stage');
      if (this.data.aggs) this.data.aggs = JSON.stringify(this.data.aggs);
    }

    if (typeof this.data.filters === 'object') {
      // console.log('this.data.aggregation parse now before stage');
      if (this.data.filters) this.data.filters = JSON.stringify(this.data.filters);
    }

    // Replace custom values based on user settings
    this.data.aggregation.replace('%reqStage%', this.data.reqStage);
    // this.esFilters.forEach((ele) => ele.field.replace('%reqStage%', this.data.reqStage));

    let mergeItems = JSON.stringify(this.esFilters);
    mergeItems = mergeItems.replace('%reqStage%', this.data.reqStage);
    mergeItems = mergeItems.replace('%tagsFilter%', this.data.tagsFilter.toLowerCase());
    mergeItems = mergeItems.replace('%sequenceFilter%', this.data.sequenceFilter.id ? this.data.sequenceFilter.id : '');
    mergeItems = mergeItems.replace('%sequenceStep%', this.data.sequenceStep);
    this.esFilters = JSON.parse(mergeItems);

    // If value in ES filter array is an object - it needs to be stringified first to pass through - graphQL input expects string
    this.esFilters.forEach((esfilterEle) => {
      esfilterEle.value?.forEach((valItem, index) => {
        if (typeof valItem === 'object') {
          esfilterEle.value[index] = JSON.stringify(valItem);
        }
      });
    });

    if (this.useStoredData) {
      this.translateData(this.data.raw);
      this.loadComplete();
    } else {
      this.reportingService

        // For user - reqs - date range - filters
        // Use local data over-rides OR use main or set as empty array

        .getReportingCounts(
          this.data.index,
          this.page,
          this.data.aggregation,
          this.data.aggs,
          this.data.sort,
          this.data.size,
          this.data.dateTarget === 'none' ? '' : this.data.dateTarget,
          this.data.startDate ? this.data.startDate : this.startDate,
          this.data.endDate ? this.data.endDate : this.endDate,
          this.data.ignoreReqFilter === 0
            ? this.data.selectedReqIds.length
              ? this.data.selectedReqIds
              : this.reqIds?.length
              ? this.reqIds
              : []
            : [],
          this.data.selectedUserIds.length ? this.data.selectedUserIds : this.userIds ? this.userIds : [],
          this.esFilters,
          this.data.filters,
          this.data.endPoint
        )
        .pipe(take(1))
        .subscribe(
          (result) => {
            // console.log('getReportingCounts result ', result);

            // parse results as JSON
            let queryResults = JSON.parse(result.data.getReportingCounts);

            // Bucket name is dependant on dateTarget variable
            let nameOfBucket = `agg_date_histogram_${this.data.dateTarget}`;

            this.translateData(queryResults.aggregations[nameOfBucket].buckets);

            // Save current raw data in case want to share/export
            this.raw = queryResults.aggregations[nameOfBucket].buckets;

            this.rawDetails = queryResults.hits.hits;
            this.page = queryResults.page;
            this.dataSource = new MatTableDataSource<any>(this.rawDetails);

            this.loadComplete();
          },
          (error) => {
            console.log('getReportingCounts User :: Error Getting User Todo Info. ', error);

            this.loading = false;
          }
        );
    }
  }

  // Change data from raw to visual
  translateData(rawData: any) {
    let pointRadius: number[] = [];
    this.lineChartData = [{ data: [], label: '', pointRadius: [] }]; // , label: ""
    this.lineChartLabels = [];

    // console.log('this.data.aggregation rawData', rawData);

    // Different aggs can return array of bucks or list of bucket objects
    // Translate to array for final use
    let finalRaw = [];
    if (!Array.isArray(rawData)) {
      // translate csv list of objects to array of buckets
      Object.entries(rawData).forEach((entry) => {
        const [key, value] = entry;

        finalRaw.push({ key: key, doc_count: rawData[key].doc_count });
      });

      rawData = [...finalRaw];
    }

    if (rawData.length === 0) {
      rawData.push({
        doc_count: 1,
        key: 'No Data',
      });
    }

    rawData.forEach((buck, index) => {
      this.lineChartData[0].data.push(buck.doc_count);

      pointRadius.push(buck.doc_count > 0 ? 4 : 0);
      this.lineChartLabels.push(this.formatDateMonthDay(buck.key_as_string));

      // Store last action for card note
      if (buck.doc_count > 0) this.lastAction = this.formatDateOnly(buck.key_as_string);
    });

    // This will be ignored is a global pointRadius is already set
    this.lineChartData[0].pointRadius = pointRadius;
  }

  loadComplete() {
    this.loading = false;

    // Set the current raw data
    this.loaded.emit(this.raw);

    // test delayed loading times
    // function sleep(ms) {
    //   return new Promise((resolve) => setTimeout(resolve, ms));
    // }

    // console.log('Hello');
    // sleep(5000).then(() => {
    //   console.log('World!');

    //   // Set the current raw data
    //   this.loaded.emit(this.raw);
    // });
  }

  formatDateOnly(input) {
    let datetime = format(new Date(input), 'yyyy-MM-dd');
    return datetime;
  }

  formatDateMonthDay(input) {
    let datetime = format(new Date(input), 'MM-dd');
    return datetime;
  }

  onClickAdd() {
    if (!this.data) this.data = {};

    this.data.color = 'box-color-3';

    this.objDataChanged.emit(this.data);
  }

  onClickColorChange(color) {
    // Cleanup possible blank data - create new data obj
    if (!this.data) this.data = {};

    // Set the passed in color
    this.data.color = color;

    // emit all data back up to the reporting stage
    this.objDataChanged.emit(this.data);
  }

  onClickRemove() {
    this.delete.emit(null);
  }

  onSettings() {
    this.closeDetails();

    this.openReportObjectSettingsDialog().subscribe(
      (openReportObjectSettingsResult: any) => {
        console.log('openUsersPickerDialog ', openReportObjectSettingsResult);

        if (openReportObjectSettingsResult?.data.objRef) {
          // Change selectedUsers

          var objRef = openReportObjectSettingsResult.data.objRef;
          // this.data.selectedUsers = openReportObjectSettingsResult.data.selectedUsers;
          // this.data.selectedUserIds = openReportObjectSettingsResult.data.selectedUsers.map((user) => user.id);

          this.contactIds = JSON.parse(JSON.stringify(objRef.contactIds ? objRef.contactIds : []));
          this.data.reqIds = JSON.parse(JSON.stringify(objRef.reqIds ? objRef.reqIds : []));
          this.reqIds = objRef.reqIds;
          this.startDate = objRef.startDate;
          this.endDate = objRef.endDate;
          // this.users = JSON.parse(JSON.stringify(objRef.users ? objRef.users : []));
          this.userIds = JSON.parse(JSON.stringify(objRef.userIds ? objRef.userIds : []));
          this.esFilters = JSON.parse(JSON.stringify(objRef.esFilters ? objRef.esFilters : []));
          this.useStoredData = objRef.useStoredData;
          this.reqs = JSON.parse(JSON.stringify(objRef.reqs ? objRef.reqs : []));
          this.tenants = JSON.parse(JSON.stringify(objRef.tenants ? objRef.tenants : []));
          this.reportingTheme = objRef.reportingTheme;
          this.rType = objRef.rType;
          this.preview = false;
          this.data = JSON.parse(JSON.stringify(objRef.data ? objRef.data : {}));

          // Let parent know data has changed
          this.objDataChanged.emit(this.data);

          // Refresh the data based on new filters
          this.ngOnChanges(null);

          // console.log('Added Filtered selectedUsers this.selectedUsers', this.selectedUsers);
          // console.log('Added Filtered selectedUsers this.selectedUserIds', this.selectedUserIds);
        } else if (openReportObjectSettingsResult?.data.clear) {
          this.data.selectedUsers = [];
          this.data.selectedUserIds = [];

          // Let parent know data has changed
          this.objDataChanged.emit(this.data);

          // Refresh the data based on new filters
          this.ngOnChanges(null);
        } else {
        }
      },

      (error) => {
        console.log('AStageComponent - openUsersPickerDialog error: ', error);
      }
    );
  }

  openReportObjectSettingsDialog(): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      maxWidth: '1000px',
      width: '100%',
      // maxHeight: '80vh',
      autoFocus: false,
      panelClass: 'add-object-dialog',
    };

    // JSON.parse(JSON.stringify(this));

    var copyObj: any = {};

    copyObj.contactIds = JSON.parse(JSON.stringify(this.contactIds ? this.contactIds : []));
    copyObj.reqIds = JSON.parse(JSON.stringify(this.reqIds ? this.reqIds : []));
    copyObj.startDate = this.startDate;
    copyObj.endDate = this.endDate;
    // copyObj.users = JSON.parse(JSON.stringify(this.users ? this.users : []));
    copyObj.userIds = JSON.parse(JSON.stringify(this.userIds ? this.userIds : []));
    copyObj.esFilters = JSON.parse(JSON.stringify(this.esFilters ? this.esFilters : []));
    copyObj.useStoredData = this.useStoredData;
    copyObj.reqs = JSON.parse(JSON.stringify(this.reqs ? this.reqs : []));
    copyObj.tenants = JSON.parse(JSON.stringify(this.tenants ? this.tenants : []));
    copyObj.reportingTheme = this.reportingTheme;
    copyObj.rType = this.rType;
    copyObj.preview = true;
    copyObj.data = JSON.parse(JSON.stringify(this.data ? this.data : {}));

    // console.log('this.reqFiltersUsersGlobal', this.reqFiltersUsersGlobal);

    dialogConfig.data = {
      target: `${this.data.name}`,
      objRef: copyObj,
      reportingTheme: this.reportingTheme,
      isAdd: false,
      reqFiltersUsersGlobal: this.reqFiltersUsersGlobal,
      contactTags: this.contactTags,
      sequenceItems: this.sequenceItems,
    };

    const dialogRef = this.reportPickerDialog.open<ReportObjectSettingsDialogComponent, MatDialogConfig, boolean>(
      ReportObjectSettingsDialogComponent,
      dialogConfig
    );

    return dialogRef.afterClosed();
  }

  toggleDetails() {
    if (!this.useStoredData) {
      this.data.slideRight = !this.data.slideRight;

      // emit all data back up to the reporting stage
      this.objDataChanged.emit(this.data);
    }
  }

  closeDetails() {
    if (!this.useStoredData) {
      this.data.slideRight = false;

      // emit all data back up to the reporting stage
      this.objDataChanged.emit(this.data);
    }
  }

  onChangePage($event: Page) {
    this.page = {
      totalSize: $event.totalSize,
      page: $event.page,
      pageSize: $event.pageSize,
    };

    this.loadHistoryCounts();

    // this.loadContacts(page);
  }

  onLinkToReq(reqId: string) {
    let url = `/#/requisitions/${reqId}`;

    // this.router.navigateByUrl(url).then((bool) => {}).catch();

    window.open(url, '_reqTarget');
  }

  onLinkToContact(reqId: string, contact: Contact, source: any) {
    let url = reqId ? `/#/contacts/${contact.id}/${reqId}` : `/#/contacts/${contact.id}`;

    // this.router.navigateByUrl(url).then((bool) => {}).catch();

    window.open(url, '_contactTarget');
  }

  onLinkToContactById(reqId: string, contactId: string, source: any) {
    let url = reqId ? `/#/contacts/${contactId}/${reqId}` : `/#/contacts/${contactId}`;

    // this.router.navigateByUrl(url).then((bool) => {}).catch();
    window.open(url, '_contactTarget');
  }

  sortData($event) {
    if ($event.direction) {
      let sortArray = [];

      // Watch for sorting on possible objects and arrays
      if ($event.active === 'contacts') {
        sortArray = [
          { 'contacts.lastName.keyword': $event.direction },
          { 'contacts.firstName.keyword': $event.direction },
        ];
      } else if ($event.active === 'completedBy') {
        sortArray = [
          { 'completedBy.lastName.keyword': $event.direction },
          { 'completedBy.firstName.keyword': $event.direction },
        ];
      } else {
        // Text fields need to sort on .keyword - dates dont
        let key = $event.active;
        let obj = {};

        key +=
          $event.active != 'created' && $event.active != 'completedDate' && $event.active != this.data.dateTarget
            ? '.keyword'
            : '';

        obj[key] = $event.direction;
        sortArray.push(obj);
      }

      this.data.sort = JSON.stringify(sortArray);
    } else {
      this.data.sort = '';
    }

    this.loadHistoryCounts();
  }

  onClickNameEvent($event) {
    $event.stopPropagation();
  }

  onClone() {
    this.clone.emit(null);
  }

  getTrailing12FromProduction(production: any) {
    if (Array.isArray(production)) {
      const trailing12: any = production.filter((ele) => ele.period === 'TRAILING_12');

      if (trailing12.length) {
        return trailing12[0].volume;
      }
    }

    return '';
  }

  // Resolve nested paths in variables to get to value - ex: analytics.phoneNumber
  resolvePath = (object, path, defaultValue) => path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object);

  ngOnDestroy() {}
}
