<div style="padding: 1em">
  <a mat-button (click)="goBackToPreviousPage()" style="margin-bottom: 2em">
    <mat-icon>chevron_left</mat-icon>
    {{ breadCrumb }}
  </a>
  <form [formGroup]="createMeetingForm" class="create-meeting-form" [formGroup]="formOptions">
    <div class="left-column">
      <mat-form-field class="title-field" appearance="standard">
        <input
          matInput
          class="title-field-input"
          [value]="title || null"
          placeholder="Title"
          [formControl]="titleFormControl"
          required
        />
      </mat-form-field>
      <div class="date-time-div">
        <mat-form-field class="date-field" appearance="outline" [floatLabel]="floatLabelControl.value">
          <mat-label *ngIf="!viewOnly">Date</mat-label>
          <mat-label *ngIf="viewOnly">Completed on</mat-label>
          <!-- Below is for calendar integration -->
          <input
            matInput
            data-testid="new-meeting-dialog-date"
            [matDatepicker]="taskDatePicker"
            [value]="dueDate || null"
            [formControl]="dueOnFormControl"
            (dateChange)="getCalendar(dueOnFormControl.value)"
            required
          />
          <mat-datepicker-toggle matSuffix [for]="taskDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #taskDatePicker startView="year" [startAt]="startDate"></mat-datepicker>
        </mat-form-field>
        <span style="padding: 10px" *ngIf="!viewOnly">at</span>
        <mat-form-field
          class="time-field"
          appearance="outline"
          *ngIf="!viewOnly"
          [floatLabel]="floatLabelControl.value"
        >
          <mat-label>Time</mat-label>
          <mat-select [formControl]="timeFormControl" required>
            <mat-option *ngFor="let time of timeOptions" [value]="time.value">
              {{ time.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span style="padding: 10px" *ngIf="!viewOnly">for</span>
        <mat-form-field
          class="duration-field"
          appearance="outline"
          *ngIf="!viewOnly"
          [floatLabel]="floatLabelControl.value"
        >
          <mat-label>Duration</mat-label>
          <mat-select [formControl]="durationFormControl" required>
            <mat-option *ngFor="let duration of durationOptions" [value]="duration.value">
              {{ duration.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-checkbox *ngIf="!todo" color="primary" [formControl]="sendInviteFormControl">Add To Calendar</mat-checkbox>

      <mat-slide-toggle
        *ngIf="isOutlookAuthorized && !viewOnly && hasTeamsForBusiness"
        [formControl]="isTeamsMeetingFormControl"
        style="margin-bottom: 1em"
      >
        <div style="display: flex">
          <img class="nav-logo" src="assets/images/integrations/teams-logo.png" class="imgTeams" />
          Make it a Teams meeting
        </div>
      </mat-slide-toggle>

      <div
        class="fullcalendarDiv"
        *ngIf="dueOnFormControl.value && (isOutlookAuthorized || isGoogleAuthorized) && !viewOnly"
      >
        <full-calendar #calendar id="fullCalendar" [options]="calendarOptions"></full-calendar>
      </div>
      <mat-form-field appearance="outline" *ngIf="!viewOnly" [floatLabel]="floatLabelControl.value">
        <span matPrefix style="float: right">+1 &nbsp;</span>
        <mat-label>Meeting number</mat-label>
        <input type="tel" matInput [formControl]="phoneFormControl" />
      </mat-form-field>
      <mat-form-field
        *ngIf="todo?.type !== 'CALL' && !viewOnly"
        class="location-field"
        appearance="outline"
        [floatLabel]="floatLabelControl.value"
      >
        <mat-icon matPrefix>location_on</mat-icon>
        <mat-label>Location</mat-label>
        <input matInput [value]="requisition || null" [formControl]="locationFormControl" />
      </mat-form-field>
      <mat-form-field class="meeting-description" appearance="outline" [floatLabel]="floatLabelControl.value">
        <mat-label>Description</mat-label>
        <textarea
          matInput
          oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
          data-testid="new-activity-dialog-description"
          style="min-height: 10em"
          [value]="description || null"
          [formControl]="descriptionFormControl"
        >
        </textarea>
      </mat-form-field>
    </div>
    <div class="right-column" id="right-column">
      <div class="action-buttons">
        <button
          mat-icon-button
          *ngIf="!viewOnly"
          id="editMeetingButton"
          style="display: none; padding-top: 1.7em"
          (click)="enableEditMeeting()"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          *ngIf="!todo && !viewOnly"
          mat-raised-button
          color="primary"
          class="save-meeting-button"
          (click)="prepareCreateMeeting()"
        >
          Create Meeting
        </button>
        <button
          *ngIf="todo && createMeetingForm.enabled && !viewOnly"
          mat-raised-button
          color="primary"
          data-testid="new-activity-dialog-submit"
          class="save-meeting-button"
          style="float: right"
          (click)="updateMeeting()"
        >
          Save Meeting
        </button>
        <button
          *ngIf="todo && createMeetingForm.disabled && !viewOnly"
          mat-raised-button
          color="primary"
          class="save-meeting-button"
          style="float: right"
          (click)="selectDisposition(todo)"
        >
          Select Disposition
        </button>
      </div>
      <div class="tenant-and-req" *ngIf="!viewOnly">
        <mat-form-field appearance="outline" class="tenant-field" [floatLabel]="floatLabelControl.value">
          <mat-icon matPrefix>location_city</mat-icon>
          <mat-label>Tenant</mat-label>
          <input
            type="text"
            aria-label="String"
            matInput
            [formControl]="tenantFormControl"
            [matAutocomplete]="autoTenant"
            (blur)="verifyTenantSelected()"
            [(ngModel)]="userTenant"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #autoTenant="matAutocomplete"
            [displayWith]="displayTenant"
            (optionSelected)="tenantSelected($event)"
          >
            <mat-option *ngFor="let tenant of filteredTenants | async" [value]="tenant">{{ tenant.title }}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" class="req-field" [floatLabel]="floatLabelControl.value">
          <mat-icon matPrefix>business</mat-icon>
          <mat-label>Pipeline</mat-label>
          <mat-select
            [formControl]="reqFormControl"
            [disabled]="readonlyReq || todo"
            [(ngModel)]="reqId"
            (selectionChange)="selectReq($event)"
          >
            <mat-option *ngFor="let req of reqOptions" [value]="req.value">{{ req.title }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field
        appearance="outline"
        class="assigned-to-field"
        chipListAutocompleteFix
        [floatLabel]="floatLabelControl.value"
      >
        <mat-icon matPrefix>supervised_user_circle</mat-icon>
        <mat-label>Assigned To</mat-label>
        <mat-chip-list #assigneeChipList required>
          <mat-chip
            *ngFor="let assignee of assignees"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="removeAssignee(assignee)"
          >
            {{ assignee.firstName + ' ' + assignee.lastName }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            type="text"
            aria-label="String"
            matInput
            #assigneeInput
            [formControl]="assigneesFormControl"
            [matAutocomplete]="autoAssignee"
            [matChipInputFor]="assigneeChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            required
          />
        </mat-chip-list>
        <mat-autocomplete
          autoActiveFirstOption
          #autoAssignee="matAutocomplete"
          (optionSelected)="selectedAssignee($event)"
        >
          <mat-option *ngFor="let assignee of filteredAssignees | async" [value]="assignee">
            <img class="avatarImg" *ngIf="avatarUrls[assignee.id]" alt="User Avatar" [src]="avatarUrls[assignee.id]" />
            <img
              class="avatarImg"
              *ngIf="!avatarUrls[assignee.id]"
              src="/assets/images/contact_placeholder.png"
              alt="User Avatar"
            />
            <span class="nameWrapper">{{ assignee.firstName + ' ' + assignee.lastName }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="associated-contacts-field"
        chipListAutocompleteFix
        [floatLabel]="floatLabelControl.value"
      >
        <mat-icon matPrefix>perm_identity</mat-icon>
        <mat-label>Associated Contacts</mat-label>
        <mat-chip-list #contactChipList>
          <mat-chip
            *ngFor="let contact of contacts"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="removeContact(contact)"
          >
            {{ contact.firstName + ' ' + contact.lastName }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            type="text"
            aria-label="String"
            matInput
            #contactInput
            [formControl]="contactsFormControl"
            [matAutocomplete]="autoContact"
            [matChipInputFor]="contactChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
          />
        </mat-chip-list>
        <mat-autocomplete
          autoActiveFirstOption
          #autoContact="matAutocomplete"
          (optionSelected)="selectedContact($event)"
          (opened)="autoCompleteScroll()"
        >
          <mat-option *ngFor="let contact of filteredContacts | async" [value]="contact">
            <img class="avatarImg" *ngIf="avatarUrls[contact.id]" alt="Contact Avatar" [src]="avatarUrls[contact.id]" />
            <img
              class="avatarImg"
              *ngIf="!avatarUrls[contact.id]"
              src="/assets/images/contact_placeholder.png"
              alt="Contact Avatar"
            />
            <span class="nameWrapper">
              {{ contact.firstName + ' ' + contact.lastName }}
            </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </form>
</div>
