<section *ngIf="playbookTemplate && playbookReqContact">
  <nav mat-tab-nav-bar [backgroundColor]="background" (selectedTabChange)="onSelectedTabChange($event)">
    <a
      mat-tab-link
      *ngFor="let playbookCategory of playbookTemplate?.categories; let index = index"
      (click)="onSelectedNavTabChange($event, index)"
      [active]="activeLink == index"
    >
      <span
        *ngIf="dataLoaded && dataLinksLoaded"
        [matBadge]="getCountIncomplete(index, playbookCategory.id)"
        matBadgeOverlap="false"
        [matBadgeHidden]="getCountIncomplete(index, playbookCategory.id) === 0"
        >{{ playbookCategory.title }}
      </span>
    </a>
  </nav>
</section>

<!-- 

activeLink = playbookCategory.title; 

 -->
