import { Page } from 'app/shared/model/filter';
import { CustomField, CustomFieldContact, CustomFieldInput, UserContactCustomFieldsMap } from 'graphql.types';
import { CustomFieldContactInput } from '../../../../../app/graphql.types';
import { Requisition, UserReq } from './requisitions';
import { User } from './user';

export { CustomField };
export interface ContactResults {
  cursor?: string;
  contacts: Contact[];
}

export class Contact {
  id?: string;
  firstName: string;
  lastName: string;
  leadsIndex?: string;
  phones?: Phone[];
  emails?: Email[];
  links?: Link[];
  address?: Address;
  company?: string;
  lastUpdatedAt?: Date;
  nmlsId?: string;
  emailDripEnabled?: boolean;
  title?: string;
  ownerId?: string;
  active?: boolean;
  linkedIn?: string;
  facebook?: string;
  website?: string;
  statistics?: ContactBusiness[];
  documents?: ContactDocument[];
  stage?: string;
  tags?: ContactTag[];
  tenantId?: string;
  avatarId?: string;
  twilioLookedUp?: boolean;
  disabledAt?: string;
}

export interface PersonalInfo {
  details?: string;
  birthdate?: string;
  spouse?: string;
  kids?: string;
  anniversary?: string;
  education?: string;
  hobbies?: string;
}

export interface ContactBusiness {
  volume?: number;
  avgPerUnit?: number;
  totalUnits?: number;
  purchasePercentage?: number;
  period: string;
  productMix?: ProductMix;
  loanTypes?: LoanTypeStats[];
  topCities?: TopCity[];
  endYear?: number;
  endMonth?: number;
  isUserInput: boolean;
  isUserImport?: boolean;
  __typename?: string;
}

export interface LoanTypeStats {
  key?: string;
  volume?: number;
  avgPerUnit?: number;
  totalUnits?: number;
}

export interface TopCity {
  name?: string;
  count?: number;
  volume?: number;
}

export type NmlsIdValidationResult = 'valid' | 'invalid' | 'no_production';

export interface ContactDocument {
  id?: string;
  documentName?: string;
  lastUpdatedAt?: Date;
  createdAt?: Date;
  ownerId?: string;
  contactId?: string;
  reqId?: string;
  uploadUrl?: string;
}
export interface ContactAvatarUrl {
  uploadUrl?: string;
}

export interface ContactDocumentUrl {
  id?: string;
  url?: string;
}

export interface ContactDocument {
  id?: string;
  documentName?: string;
  lastUpdatedAt?: Date;
  createdAt?: Date;
  ownerId?: string;
  contactId?: string;
  reqId?: string;
  uploadUrl?: string;
}

export interface ContactDocumentUrl {
  id?: string;
  url?: string;
}

export interface ProductMix {
  conventional?: number;
  government?: number;
  nonConforming?: number;
  other?: number;
  __typename?: string;
}

export const StatisticMonth = [
  { name: 'FISCAL', value: 0 },
  { name: 'January', value: 1 },
  { name: 'February', value: 2 },
  { name: 'March', value: 3 },
  { name: 'April', value: 4 },
  { name: 'May', value: 5 },
  { name: 'June', value: 6 },
  { name: 'July', value: 7 },
  { name: 'August', value: 8 },
  { name: 'September', value: 9 },
  { name: 'October', value: 10 },
  { name: 'November', value: 11 },
  { name: 'December', value: 12 },
];

export class UserContact {
  id?: string;
  firstName: string;
  lastName: string;
  phones?: Phone[];
  emails?: Email[];
  links?: Link[];
  company?: string;
  lastUpdatedAt?: Date;
  nmlsId?: string;
  createdAt?: Date;
  source?: string;
  sourceId?: string;
  contactId?: string;
  active?: boolean;
  address?: Address;
  reqs?: Requisition[];
  statistics?: ContactBusiness[];
  documents?: ContactDocument[];
  stage?: ContactStage;
  title?: string;
  tags?: ContactTag[];
  customFields?: UserContactCustomFieldsMap[];
  tenantId?: string;
  trailing12Units?: string;
  trailing12Volume?: string;
}

export class UserContactListFilteredItem {
  id?: string;
  firstName: string;
  lastName: string;
  phones?: Phone[];
  emails?: Email[];
  links?: Link[];
  company?: string;
  lastUpdatedAt?: Date;
  nmlsId?: string;
  createdAt?: Date;
  source?: string;
  sourceId?: string;
  contactId?: string;
  active?: boolean;
  address?: Address;
  reqs?: Requisition[];
  statistics?: ContactBusiness[];
  documents?: ContactDocument[];
  stage?: ContactStage;
  title?: string;
  tags?: ContactTag[];
  reqId?: string;
}

export class ReqContact {
  id?: string;
  nmlsId?: string;
  firstName: string;
  lastName: string;
  source?: string;
  sourceId?: string;
  active?: boolean;
  stage?: ContactStage;
  stageEnteredDate?: string;
  lastUpdatedAt?: Date;
  createdAt?: Date;
  contacted?: boolean;
  company?: string;
  contactId?: string;
  phones?: Phone[];
  emails?: Email[];
  links?: Link[];
  address?: Address;
  statistics?: ContactBusiness[];
  documents?: ContactDocument[];
  reqId?: string;
  reqName?: string;
  tags?: ContactTag[];
  customFields?: CustomFieldContact[];
  assignedUser?: User;
  opportunityValue?: number | string;
  opportunityValueHard?: boolean;
  trailing12Units?: string;
  trailing12Volume?: string;
  title?: string;
  product_mix_conventional?: string;
  product_mix_fha?: string;
  product_mix_government?: string;
  product_mix_jumbo?: string;
  product_mix_usda?: string;
  product_mix_va?: string;
  purpose_mix_purchase?: string;
  purpose_mix_refinance?: string;
}

// 12_month_volume
// 12_month_units
// product_mix_conventional
// product_mix_fha
// product_mix_government
// product_mix_jumbo
// product_mix_usda
// product_mix_va
// purpose_mix_purchase
// purpose_mix_refinance

export interface SearchResult {
  contactsByName?: UserContact[];
  contactsByPhone?: UserContact[];
  contactsByEmail?: UserContact[];
  contactsByNmls?: UserContact[];
  requisitions?: UserReq[];
  companies?: UserContact[];
  tags?: ReqContact[];
}

export interface FilterReqContactResults {
  page?: Page;
  filters?: ContactFilter;
  contacts: ReqContact[];
  __typename?: string;
}

export interface ContactName {
  firstName: string;
  lastName: string;
}

export interface Address {
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  coordinates?: {
    lat: number;
    lon: number;
  };
}

export interface Phone {
  type: string;
  number: string;
}

export interface Email {
  type: string;
  address: string;
}

export interface Link {
  type: string;
  url: string;
}
// export interface CustomField {
//   id: string;
//   name?: string;
//   type: 'LIST' | 'TEXT';
//   value?: string;
//   options?: CustomFieldOption[];
//   active?: boolean;
// }

// export interface CustomFieldOption {
//   id: string;
//   order: number;
//   name: string;
// }

export interface TWGContactInfoInput {
  LID?: string;
  Status?: string;
  Validated?: string;
  LOOrgID?: string;
  LOOrgName?: string;
  Collected_EmployerCOMPANY?: string;
  Collected_EmployerCOMPANYID?: string;
  Collected_Employer_Type?: string;
  Collected_EmployerAddress?: string;
  Collected_EmployerCity?: string;
  Collected_EmployerState?: string;
  Collected_EmployerZip?: string;
  Collected_OfficePhone?: string;
  Collected_OfficePhoneExt?: string;
  Collected_CellPhone?: string;
  Collected_WorkEmail?: string;
  Collected_PersonalEmail?: string;
  Collected_EmployerWebsite?: string;
  Collected_LOWebSite?: string;
  Collected_Linkedin?: string;
  Collected_Twitter?: string;
  Collected_Facebook?: string;
  Collected_Other?: string;
  ModiType?: string;
  __typename?: string;
}
export interface ContactInput {
  id?: string;
  firstName: string;
  lastName: string;
  company?: string;
  nmlsId?: string;
  emails?: Email[];
  phones?: Phone[];
  links?: Link[];
  title?: string;
  reqId?: string;
  address?: Address;
  linkedIn?: string;
  facebook?: string;
  website?: string;
  tags?: ContactTag[];
  isCompanyContact?: boolean;
  duplicate?: string;
  TWGContactInfo?: TWGContactInfoInput;
  customFields?: CustomFieldContactInput[];
  __typename?: string;
}

export interface ContactFilter {
  name?: string;
  lastUpdatedAt?: string;
  city?: string;
  state?: string;
  company?: string;
  nmlsId?: string;
  mobileSearch?: string;
  primaryPhone?: string;
  secondaryPhone?: string;
  primaryEmail?: string;
  secondaryEmail?: string;
  title?: string;
  dateCreated?: string;
  tags?: string;
  daysOfStage?: string;
  dateCreatedStart?: string;
  dateCreatedEnd?: string;
  lastUpdatedAtStart?: string;
  lastUpdatedAtEnd?: string;
}

export interface QueryStringContactFilter {
  query: string;
  strict: boolean;
  options: {
    default_field?: string;
    fields?: string[];
    allow_leading_wildcard?: boolean;
    analyze_wildcard?: boolean;
    auto_generate_synonyms_phrase_query?: boolean;
    boost?: number;
    default_operator?: string;
    enable_position_increments?: boolean;
    fuzziness?: string;
    fuzzy_max_expansions?: number;
    fuzzy_prefix_length?: number;
    fuzzy_transpositions?: boolean;
    lenient?: boolean;
    max_determinized_states?: number;
    minimum_should_match?: string;
    quote_analyzer?: string;
    phrase_slop?: number;
    quote_field_suffix?: string;
    rewrite?: string;
    time_zone?: string;
    all_fields?: boolean;
    flags?: string;
  };
}

export interface QueryStringRawContactFilter {
  displayName: string;
  key: string;
  type: string;
  value: string;
}

export interface SearchFilterCriteria {
  name?: string;
  lastUpdatedAt?: string;
  source?: string;
  stage?: ContactStage;
  contacted?: boolean;
  city?: string;
  state?: string;
  company?: string;
  nmlsId?: string;
  primaryPhone?: string;
  secondaryPhone?: string;
  title?: string;
  dateCreated?: string;
}

export interface SearchFilter {
  name?: string;
  createdAt?: string;
  id?: string;
  filterCriteria?: QueryStringContactFilter[];
  isDeleted?: boolean;
}

export interface SearchFilterData {
  isContact?: boolean;
  searchFilters?: SearchFilter[];
}

export interface UserContactSearchFilter {
  id?: string;
  createdAt?: string;
  name: string;
  filterCriteria: any[];
}

export interface ContactResponse {
  contacts?: UserContact[];
  page?: Page;
  __typename?: string;
}

export enum ContactStage {
  PROSPECTS = 'prospects',
  CANDIDATES = 'candidates',
  MEETINGS = 'meetings',
  OFFERS = 'offers',
  HIRES = 'hires',
  DISQUALIFIED = 'disqualified',
  NOT_INTRESTED = 'not_intrested',
}

export interface ContactTag {
  tagId: string;
  name: string;
  order?: number;
  __typename?: string;
}

export const newContactTag: ContactTag = {
  tagId: '',
  name: 'New Tag',
  order: 0,
};

export interface Click2CallResult {
  msg?: string;
  sid?: string;
  parentSid?: string;
  dateCreated?: string;
  dateUpdated?: string;
  to?: string;
  from?: string;
  contactNumber?: string;
}

export interface VerifyCallerIdResult {
  msg?: string;
  validationCode?: string;
  callSid?: string;
  phoneNumber?: string;
}

export interface SuggestionDefinition {
  mmKey?: string;
  mmValue?: string;
  mmType?: string;
  pdlSuggestion?: string;
}
