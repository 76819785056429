<button mat-icon-button (click)="toggleColumnsSelector()" type="button">
  <mat-icon [ngStyle]="{ color: '#739069' }">view_column</mat-icon>
</button>
<button mat-icon-button (click)="openSearchFilters()" type="button">
  <mat-icon color="gray-light">list</mat-icon>
</button>

<button
  matTooltip="Select Contacts"
  matTooltipShowDelay="500"
  (click)="onEditToggle()"
  mat-button
  class="edit-button"
  type="button"
>
  <mat-icon color="gray-light">check_box_outline_blank</mat-icon>
</button>
<button
  matTooltip="Select Contacts"
  matTooltipShowDelay="500"
  (click)="onEditToggle(true)"
  class="more-options"
  [matMenuTriggerFor]="dropdownMenuOne"
  mat-button
  type="button"
  [autofocus]="true"
>
  <mat-icon color="gray-light">expand_more</mat-icon>
</button>

<mat-menu #dropdownMenuOne="matMenu">
  <button (click)="selectAll()" mat-menu-item>All Contacts</button>
  <button (click)="selectCurrentPage()" mat-menu-item>Current Page</button>
  <button (click)="deSelectAll()" mat-menu-item>None</button>
</mat-menu>

<button mat-icon-button *ngIf="isFilterApplied" (click)="saveSearchFilters()" type="button">
  <mat-icon color="gray-light">save</mat-icon>
</button>
<button mat-icon-button *ngIf="isFilterApplied" (click)="reset()" type="button">
  <mat-icon color="gray-light">clear</mat-icon>
</button>

<span id="contactActionButtons">
  <button
    mat-raised-button
    class="actionBtn"
    *ngIf="isSelectContactsEnabled"
    [disabled]="contactSelector.isEmpty()"
    (click)="handleEditContacts()"
  >
    Edit
  </button>
  <button
    mat-raised-button
    class="actionBtn"
    *ngIf="isSelectContactsEnabled && !isInternalTenantUserNotAdmin"
    [disabled]="contactSelector.isEmpty()"
    (click)="exportContacts()"
  >
    Export
  </button>
</span>

<mat-table
  [dataSource]="contactsTableSource"
  matSort
  (matSortChange)="sortData($event)"
  class="contacts-table accent-table contact-scrollable resize-table"
>
  <ng-container matColumnDef="select">
    <mat-header-cell style="flex: none" *matHeaderCellDef> </mat-header-cell>
    <div style="flex: none; overflow: visible !important" *matCellDef="let contact">
      <mat-checkbox
        style="overflow: visible !important; margin-left: 15px"
        [class.is-hidden]="!isSelectContactsEnabled"
        aria-label="Select Contact"
        (change)="toggleContactSelection(contact)"
        [checked]="isSelectAllContactsEnabled || isSelectAllBulkEnabled || isContactSelected(contact)"
      ></mat-checkbox>
    </div>
  </ng-container>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Member" [matTooltipShowDelay]="700"
      >Member
    </mat-header-cell>
    <mat-cell
      class="nameCell"
      *matCellDef="let contact"
      [matTooltip]="contact.firstName + ' ' + contact.lastName"
      [matTooltipShowDelay]="700"
    >
      <span class="nameTag" (click)="goToContact(contact)">
        <img
          class="avatarImg"
          *ngIf="avatarUrls[contact.contactId]"
          [src]="avatarUrls[contact.contactId]"
          onerror="this.src='/assets/images/contact_placeholder_small.png'"
        />
        <img
          class="avatarImg"
          *ngIf="!avatarUrls[contact.contactId]"
          src="/assets/images/contact_placeholder_small.png"
        />
        <span class="nameWrapper">{{ contact.firstName }} {{ contact.lastName }}</span>
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="stage">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Stage" [matTooltipShowDelay]="700"
      >Stage
    </mat-header-cell>
    <mat-cell *matCellDef="let contact" (click)="goToContact(contact)">{{ contact.stage }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="stage-days">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Days in Stage" [matTooltipShowDelay]="700"
      >Days in Stage
    </mat-header-cell>
    <mat-cell
      *matCellDef="let contact"
      (click)="goToContact(contact)"
      [matTooltipShowDelay]="700"
      [matTooltip]="getDateInStage(contact)"
      >{{ calcDaysInStage(contact) }}</mat-cell
    >
  </ng-container>

  <ng-container matColumnDef="assignedUser">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Assigned To" [matTooltipShowDelay]="700"
      >Assigned To
    </mat-header-cell>
    <mat-cell
      *matCellDef="let contact"
      (click)="goToContact(contact)"
      [matTooltipShowDelay]="700"
      [matTooltip]="contact.assignedUser?.id"
      >{{ getAssignedUser(contact) }}</mat-cell
    >
  </ng-container>

  <ng-container matColumnDef="source">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Source" [matTooltipShowDelay]="700"
      >Source
    </mat-header-cell>
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="contact.source"
      [matTooltipShowDelay]="700"
      (click)="goToContact(contact)"
    >
      <span (click)="handleContactClick(contact)">
        <img
          class="avatarImg"
          *ngIf="avatarUrls[contact.sourceId]"
          [src]="avatarUrls[contact.sourceId]"
          onerror="this.src='/assets/images/contact_placeholder_small.png'"
        />
        <img
          class="avatarImg"
          *ngIf="!avatarUrls[contact.sourceId]"
          src="/assets/images/contact_placeholder_small.png"
        />
        <span class="nameWrapper">{{ contact.source }}</span>
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="company">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Company" [matTooltipShowDelay]="700"
      >Company
    </mat-header-cell>
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="contact.company"
      [matTooltipShowDelay]="700"
      (click)="goToContact(contact)"
    >
      <div>{{ contact.company }}</div>
    </mat-cell>
  </ng-container>
  <!-- mat-sort-header -->
  <ng-container matColumnDef="contacted">
    <mat-header-cell *matHeaderCellDef matTooltip="Touched" [matTooltipShowDelay]="700">Touched </mat-header-cell>
    <mat-cell *matCellDef="let contact">
      <mat-icon [color]="contact.contacted ? 'accent' : 'muted'" (click)="goToContact(contact)">
        {{ contact.contacted ? 'done' : 'clear' }}</mat-icon
      >
    </mat-cell>
  </ng-container>
  <!-- mat-sort-header -->
  <ng-container matColumnDef="lastUpdatedAt">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Last Updated</mat-header-cell>
    <!-- <mat-cell></mat-cell> -->
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="contact.lastUpdatedAt"
      [matTooltipShowDelay]="700"
      (click)="handleContactClick(contact)"
    >
      <div>{{ contact.lastUpdatedAt | date: 'MM/dd/yy' }}</div>
    </mat-cell>
  </ng-container>
  <!-- new columns -->
  <ng-container matColumnDef="city">
    <mat-header-cell *matHeaderCellDef mat-sort-header>City</mat-header-cell>
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="contact.address ? contact.address.city : ''"
      [matTooltipShowDelay]="700"
      (click)="handleContactClick(contact)"
    >
      <div>{{ contact.address ? contact.address.city : '' }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="state">
    <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="contact.address ? contact.address.state : ''"
      [matTooltipShowDelay]="700"
      (click)="handleContactClick(contact)"
    >
      <div>{{ contact.address ? contact.address.state : '' }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="nmls">
    <mat-header-cell *matHeaderCellDef mat-sort-header>NMLS</mat-header-cell>
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="contact.nmlsId"
      [matTooltipShowDelay]="700"
      (click)="handleContactClick(contact)"
    >
      <div>{{ contact.nmlsId }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="tags">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Tags</mat-header-cell>
    <!-- <mat-cell></mat-cell> -->
    <mat-cell
      *matCellDef="let contact"
      (click)="handleContactClick(contact)"
      [matTooltip]="getToolTipTags(contact.tags)"
      [matTooltipShowDelay]="700"
    >
      <!-- <div>{{ contact.tags }}</div> -->

      <div>
        <span cdkDrag *ngFor="let tag of contact.tags; let index = index">
          {{ tag.name }}<span *ngIf="index < contact.tags.length - 1">,</span>
        </span>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="trailing">
    <mat-header-cell *matHeaderCellDef>Trailing 12 Month Volume</mat-header-cell>
    <!-- <mat-cell></mat-cell> -->
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="getTrailing(contact)"
      [matTooltipShowDelay]="700"
      (click)="handleContactClick(contact)"
    >
      <div>{{ getTrailing(contact) | currency }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="primary-phone">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Primary Phone Number</mat-header-cell>
    <!-- <mat-cell></mat-cell> -->
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="getPhone('primary', contact)"
      [matTooltipShowDelay]="700"
      (click)="handleContactClick(contact)"
    >
      <div>{{ getPhone('primary', contact) }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="secondary-phone">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Secondary Phone Number</mat-header-cell>
    <!-- <mat-cell></mat-cell> -->
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="getPhone('secondary', contact)"
      [matTooltipShowDelay]="700"
      (click)="handleContactClick(contact)"
    >
      <div>{{ getPhone('secondary', contact) }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="date-created">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
    <!-- <mat-cell></mat-cell> -->
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="contact.createdAt"
      [matTooltipShowDelay]="700"
      (click)="handleContactClick(contact)"
    >
      <div>{{ contact.createdAt | date: 'MM/dd/yy' }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="fiscal-year-2016">
    <mat-header-cell *matHeaderCellDef>Fiscal Year 2016</mat-header-cell>
    <!-- <mat-cell></mat-cell> -->
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="getFiscal(2016, contact)"
      [matTooltipShowDelay]="700"
      (click)="handleContactClick(contact)"
    >
      <div>{{ getFiscal(2016, contact) | currency }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="fiscal-year-2017">
    <mat-header-cell *matHeaderCellDef>Fiscal Year 2017</mat-header-cell>
    <!-- <mat-cell></mat-cell> -->
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="getFiscal(2017, contact)"
      [matTooltipShowDelay]="700"
      (click)="handleContactClick(contact)"
    >
      <div>{{ getFiscal(2017, contact) | currency }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="fiscal-year-2018">
    <mat-header-cell *matHeaderCellDef>Fiscal Year 2018</mat-header-cell>
    <!-- <mat-cell></mat-cell> -->
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="getFiscal(2018, contact)"
      [matTooltipShowDelay]="700"
      (click)="handleContactClick(contact)"
    >
      <div>{{ getFiscal(2018, contact) | currency }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
    <mat-cell
      *matCellDef="let contact"
      [matTooltip]="contact.title"
      [matTooltipShowDelay]="700"
      (click)="handleContactClick(contact)"
    >
      <div>{{ contact.title }}</div>
    </mat-cell>
  </ng-container>
  <!--  -->
  <ng-container matColumnDef="filter">
    <mat-header-cell *matHeaderCellDef matTooltip="Filter" [matTooltipShowDelay]="700"
      ><button mat-icon-button>
        <fa-icon [icon]="['fas', 'filter']" size="lg" class="table"></fa-icon></button
    ></mat-header-cell>
    <mat-cell *matCellDef="let contact"></mat-cell>
  </ng-container>
  <ng-container matColumnDef="name-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        style="margin-right: 20px"
        [class.is-hidden]="!isSelectContactsEnabled"
        [checked]="contactSelector.selected.length === totalSize || isSelectAllBulkEnabled"
        [indeterminate]="selectedContactIds.length > 0 && contactSelector.selected.length !== totalSize"
        (change)="handleCheckboxChange()"
        class="select-all"
        matTooltip="All Contacts"
        matTooltipShowDelay="500"
      >
      </mat-checkbox>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input
          matInput
          data-testid="req-contact-name-search"
          placeholder="Search"
          (keyup)="applyFilter('name', $event.target.value)"
          [(ngModel)]="activeFilters['name']"
        />
        <mat-icon matSuffix color="muted">search</mat-icon>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="stage-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field"
        ><input
          matInput
          [value]="activeFilters.stage != null && activeFilters.stage != undefined ? activeFilters.stage : ''"
          (keyup)="applyFilter('stage', $event.target.value)"
      /></mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="stage-days-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input
          matInput
          (keyup)="applyFilter('daysOfStage', $event.target.value)"
          [(ngModel)]="activeFilters['daysOfStage']"
        />
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="source-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field"
        ><input matInput (keyup)="applyFilter('source', $event.target.value)" [(ngModel)]="activeFilters['source']" />
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="company-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field"
        ><input matInput (keyup)="applyFilter('company', $event.target.value)" [(ngModel)]="activeFilters['company']" />
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="contacted-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field table-dropdown">
        <mat-select
          [(value)]="contactedFilterValue"
          (valueChange)="applyFilter('contacted', $event.toString())"
          [(ngModel)]="activeFilters['contacted']"
        >
          <mat-option *ngFor="let option of contacted" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="lastUpdatedAt-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field" floatLabel="never">
        <sat-datepicker-toggle matSuffix [for]="lastUpdatedAtPicker"></sat-datepicker-toggle>
        <input
          matInput
          placeholder=""
          [satDatepicker]="lastUpdatedAtPicker"
          [(ngModel)]="lastUpdatedAtRange"
          (dateChange)="onLastUpdatedAtChange($event.value)"
        />
        <sat-datepicker #lastUpdatedAtPicker [rangeMode]="true"></sat-datepicker>
      </mat-form-field>
      <mat-icon matDatepickerToggleIcon (click)="clearLastUpdatedAt()">clear</mat-icon>
    </mat-header-cell>
  </ng-container>
  <!-- new columns filters -->
  <ng-container matColumnDef="city-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input matInput (keyup)="applyFilter('city', $event.target.value)" [(ngModel)]="activeFilters['city']" />
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="state-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input matInput (keyup)="applyFilter('state', $event.target.value)" [(ngModel)]="activeFilters['state']" />
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="nmls-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input matInput (keyup)="applyFilter('nmlsId', $event.target.value)" [(ngModel)]="activeFilters['nmlsId']" />
      </mat-form-field>
    </mat-header-cell>
  </ng-container>

  <ng-container matColumnDef="tags-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input
          matInput
          placeholder="Search"
          (keyup)="applyFilter('tags', $event.target.value)"
          [(ngModel)]="activeFilters['tags']"
        />
        <mat-icon matSuffix color="muted">search</mat-icon>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>

  <ng-container matColumnDef="assignedUser-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input
          matInput
          placeholder="Search"
          (keyup)="applyFilter('assignedUser', $event.target.value)"
          [(ngModel)]="activeFilters['assignedUser']"
        />
        <mat-icon matSuffix color="muted">search</mat-icon>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>

  <ng-container matColumnDef="trailing-filter">
    <mat-header-cell *matHeaderCellDef> </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="primary-phone-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input
          matInput
          placeholder="Search"
          (keyup)="applyFilter('primaryPhone', $event.target.value)"
          [(ngModel)]="activeFilters['primaryPhone']"
        />
        <mat-icon matSuffix color="muted">search</mat-icon>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="secondary-phone-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input
          matInput
          placeholder="Search"
          (keyup)="applyFilter('secondaryPhone', $event.target.value)"
          [(ngModel)]="activeFilters['secondaryPhone']"
        />
        <mat-icon matSuffix color="muted">search</mat-icon>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <!--  -->
  <ng-container matColumnDef="date-created-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field" floatLabel="never">
        <sat-datepicker-toggle matSuffix [for]="resultPicker"></sat-datepicker-toggle>
        <input
          matInput
          placeholder=""
          [satDatepicker]="resultPicker"
          [(ngModel)]="createdDateRange"
          (dateChange)="onCreatedDateChange($event.value)"
        />
        <sat-datepicker #resultPicker [rangeMode]="true"></sat-datepicker>
      </mat-form-field>
      <mat-icon matDatepickerToggleIcon (click)="clearCreatedDate()">clear</mat-icon>
    </mat-header-cell>
  </ng-container>
  <!--  -->
  <ng-container matColumnDef="fiscal-year-2016-filter">
    <mat-header-cell *matHeaderCellDef> </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="fiscal-year-2017-filter">
    <mat-header-cell *matHeaderCellDef> </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="fiscal-year-2018-filter">
    <mat-header-cell *matHeaderCellDef> </mat-header-cell>
  </ng-container>
  <ng-container matColumnDef="title-filter">
    <mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" class="table-filter-field">
        <input
          matInput
          placeholder="Search"
          (keyup)="applyFilter('title', $event.target.value)"
          [(ngModel)]="activeFilters['title']"
        />
        <mat-icon matSuffix color="muted">search</mat-icon>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>
  <!--  -->
  <ng-container matColumnDef="filter-filter">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedContactsColumns; sticky: true"></mat-header-row>
  <mat-header-row *matHeaderRowDef="displayedFilterColumns" class="filter-row"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedContactsColumns"></mat-row>
</mat-table>
<app-paginator *ngIf="show" (pageChanged)="onChange($event)" [pageSizeOptions]="[25, 50, 100]" [page]="contactsPage">
</app-paginator>
