import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mm-import-alert-dialog',
  templateUrl: './import-alert-dialog.component.html',
  //   styleUrls: ['./import-alert-dialog.component.scss'],
})
export class ImportAlertDialog implements OnInit, OnDestroy {
  message: string = '';

  constructor(
    public dialogRef: MatDialogRef<ImportAlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.message = this.data.message;
  }

  ngOnDestroy() {}
}
