import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keyMask' })
export class KeyMask implements PipeTransform {
  transform(value: string): string {
    var mask = RegExp('(.{1,' + 4 + '}$)|.', 'g');
    return String(value).replace(mask, function (hide, show) {
      return show || '*';
    });
  }
}
