import { Permissions, setPermissionValue } from '../permissions';

export type LookUpKey = 'tier_01' | 'tier_02' | 'tier_1' | 'tier_2' | 'tier_3';
export type PrismLookUpKey = 'prism_1' | 'prism_2' | 'prism_3';
export type CombinedLookupKeys = LookUpKey | PrismLookUpKey;

export type PermissionTierTemplate = {
  lookup_key: CombinedLookupKeys;
  permissions: Permissions;
};

type PermissionTierMap = {
  [key in LookUpKey]: PermissionTierTemplate;
};

type PrismPermissionTierMap = {
  [key in PrismLookUpKey]: PermissionTierTemplate;
};

type ProductDetails = {
  lookupKey: LookUpKey | PrismLookUpKey;
  price: number;
  productId: string;
  productName: string;
  quantity: number;
  total: number;
};

export type TenantSelfServeRecord = {
  accountStatus: 'active' | 'past_due';
  affiliate: 'none' | 'prism';
  billingEmail: string;
  discount?: any;
  productDetails: ProductDetails[];
  subscriptionId: string;
  tenantId: string;
  creditedTiers: any;
  monthlySubscriptionSum: number;
};

export type ProductTier = {
  features: string[];
  lookup_key: CombinedLookupKeys;
  name: string;
  price: string;
};

const clone = (result: any) => JSON.parse(JSON.stringify(result));

// Permission tiers for self signup model match users
const baseTierTemplates: PermissionTierMap = {
  tier_01: {
    lookup_key: 'tier_01',
    permissions: {
      session: {
        enabled: true,
        multiple_sessions: false,
      },
      cms: {
        enabled: false,
        create_requisitions: false,
        allow_imports: false,
        add_to_all_reqs: false,
        white_label: false,
        marketing_export: false,
        todos: false,
        contacts: {
          enabled: false,
          allow_exports: false,
          create: false,
          delete: false,
          limit_contacts: false,
          click_to_call: false,
          nmls_id: true,
          premium_contacts: {
            enabled: false,
            export: {
              enabled: false,
              less: false,
            },
          },
        },
      },
      data: {
        enabled: true,
        pdl_enrichment: false,
        loan_originator: {
          enabled: true,
          raw_loan_transactions: true,
        },
        real_estate_agent: {
          enabled: true,
        },
        branch_raw_transactions: false,
        company_raw_transactions: false,
      },
      market_insights: {
        enabled: true,
        ai_filter: false,
        allow_imports_at_stage: false,
        allow_bulk_duplicate_imports: false,
        view_on_zillow: true,
        export_transactions: false,
        debug_actions: false,
        full_date_ranges: false,
      },
      reporting_analytics: {
        enabled: false,
      },
      sms_chat: {
        enabled: false,
        sequence: false,
      },
      integrations: {
        enabled: false,
        bomb_bomb: false,
        bonzo: false,
        google: false,
        microsoft: false,
        total_expert: {
          enabled: false,
          export: false,
        },
        zapier: false,
        hubspot: false,
        salesforce: false,
      },
      playbooks: {
        enabled: false,
      },
      pulse: {
        enabled: false,
        title_company: false,
      },
      compass: {
        enabled: false,
        title_company: false,
      },
      trust_hub: {
        enabled: false,
        sms: false
      },
      team_chat: {
        enabled: false,
      },
      debug: {
        enabled: false,
        permissions: false,
        tail_logs: false,
      },
    },
  },

  tier_02: {
    lookup_key: 'tier_02',
    permissions: {
      session: {
        enabled: true,
        multiple_sessions: false,
      },
      cms: {
        enabled: false,
        create_requisitions: false,
        allow_imports: false,
        add_to_all_reqs: false,
        white_label: false,
        marketing_export: false,
        todos: false,
        contacts: {
          enabled: false,
          allow_exports: false,
          create: false,
          delete: false,
          limit_contacts: false,
          click_to_call: false,
          nmls_id: true,
          premium_contacts: {
            enabled: false,
            export: {
              enabled: false,
              less: false,
            },
          },
        },
      },
      data: {
        enabled: true,
        pdl_enrichment: false,
        loan_originator: {
          enabled: true,
          raw_loan_transactions: true,
        },
        real_estate_agent: {
          enabled: true,
        },
        branch_raw_transactions: false,
        company_raw_transactions: false,
      },
      market_insights: {
        enabled: true,
        ai_filter: false,
        allow_imports_at_stage: false,
        allow_bulk_duplicate_imports: false,
        view_on_zillow: true,
        export_transactions: true,
        debug_actions: false,
        full_date_ranges: true,
      },
      reporting_analytics: {
        enabled: false,
      },
      sms_chat: {
        enabled: false,
        sequence: false,
      },
      integrations: {
        enabled: false,
        bomb_bomb: false,
        bonzo: false,
        google: false,
        microsoft: false,
        total_expert: {
          enabled: false,
          export: false,
        },
        zapier: false,
        hubspot: false,
        salesforce: false,
      },
      playbooks: {
        enabled: false,
      },
      pulse: {
        enabled: false,
        title_company: false,
      },
      compass: {
        enabled: true,
        title_company: false,
      },
      trust_hub: {
        enabled: false,
        sms: false
      },
      team_chat: {
        enabled: false,
      },
      debug: {
        enabled: false,
        permissions: false,
        tail_logs: false,
      },
    },
  },

  tier_1: {
    lookup_key: 'tier_1',
    permissions: {
      session: {
        enabled: true,
        multiple_sessions: false,
      },
      cms: {
        enabled: false,
        create_requisitions: false,
        allow_imports: false,
        add_to_all_reqs: false,
        white_label: false,
        marketing_export: false,
        todos: false,
        contacts: {
          enabled: false,
          allow_exports: false,
          create: false,
          delete: false,
          limit_contacts: false,
          click_to_call: false,
          nmls_id: true,
          premium_contacts: {
            enabled: false,
            export: {
              enabled: false,
              less: false,
            },
          },
        },
      },
      data: {
        enabled: true,
        pdl_enrichment: false,
        loan_originator: {
          enabled: true,
          raw_loan_transactions: true,
        },
        real_estate_agent: {
          enabled: true,
        },
        branch_raw_transactions: false,
        company_raw_transactions: false,
      },
      market_insights: {
        enabled: true,
        ai_filter: false,
        allow_imports_at_stage: false,
        allow_bulk_duplicate_imports: false,
        view_on_zillow: true,
        export_transactions: true,
        debug_actions: false,
        full_date_ranges: true,
      },
      reporting_analytics: {
        enabled: false,
      },
      sms_chat: {
        enabled: false,
        sequence: false,
      },
      integrations: {
        enabled: false,
        bomb_bomb: false,
        bonzo: false,
        google: false,
        microsoft: false,
        total_expert: {
          enabled: false,
          export: false,
        },
        zapier: false,
        hubspot: false,
        salesforce: false,
      },
      playbooks: {
        enabled: false,
      },
      pulse: {
        enabled: true,
        title_company: true,
      },
      compass: {
        enabled: true,
        title_company: false,
      },
      trust_hub: {
        enabled: false,
        sms: false
      },
      team_chat: {
        enabled: false,
      },
      debug: {
        enabled: false,
        permissions: false,
        tail_logs: false,
      },
    },
  },

  tier_2: {
    lookup_key: 'tier_2',
    permissions: {
      session: {
        enabled: true,
        multiple_sessions: false,
      },
      cms: {
        enabled: true,
        create_requisitions: true,
        allow_imports: true,
        add_to_all_reqs: false,
        white_label: false,
        marketing_export: true,
        todos: true,
        contacts: {
          enabled: true,
          allow_exports: true,
          create: true,
          delete: true,
          limit_contacts: true,
          click_to_call: true,
          nmls_id: true,
          premium_contacts: {
            enabled: false,
            export: {
              enabled: false,
              less: false,
            },
          },
        },
      },
      data: {
        enabled: true,
        pdl_enrichment: true,
        loan_originator: {
          enabled: true,
          raw_loan_transactions: true,
        },
        real_estate_agent: {
          enabled: true,
        },
        branch_raw_transactions: false,
        company_raw_transactions: false,
      },
      market_insights: {
        enabled: true,
        ai_filter: false,
        allow_imports_at_stage: true,
        allow_bulk_duplicate_imports: true,
        view_on_zillow: true,
        export_transactions: true,
        debug_actions: false,
        full_date_ranges: true,
      },
      reporting_analytics: {
        enabled: false,
      },
      trust_hub: {
        enabled: false,
        sms: false
      },
      sms_chat: {
        enabled: false,
        sequence: false,
      },
      integrations: {
        enabled: true,
        bomb_bomb: true,
        bonzo: false,
        google: true,
        microsoft: true,
        total_expert: {
          enabled: false,
          export: false,
        },
        zapier: false,
        hubspot: false,
        salesforce: false,
      },
      playbooks: {
        enabled: false,
      },
      pulse: {
        enabled: true,
        title_company: true,
      },
      compass: {
        enabled: true,
        title_company: false,
      },
      team_chat: {
        enabled: false,
      },
      debug: {
        enabled: false,
        permissions: false,
        tail_logs: false,
      },
    },
  },

  tier_3: {
    lookup_key: 'tier_3',
    permissions: {
      session: {
        enabled: true,
        multiple_sessions: false,
      },
      cms: {
        enabled: true,
        create_requisitions: true,
        allow_imports: true,
        add_to_all_reqs: false,
        white_label: false,
        marketing_export: true,
        todos: true,
        contacts: {
          enabled: true,
          allow_exports: true,
          create: true,
          delete: true,
          limit_contacts: true,
          click_to_call: true,
          nmls_id: true,
          premium_contacts: {
            enabled: true,
            export: {
              enabled: true,
              less: false,
            },
          },
        },
      },
      data: {
        enabled: true,
        pdl_enrichment: true,
        loan_originator: {
          enabled: true,
          raw_loan_transactions: true,
        },
        real_estate_agent: {
          enabled: true,
        },
        branch_raw_transactions: false,
        company_raw_transactions: false,
      },
      market_insights: {
        enabled: true,
        ai_filter: false,
        allow_imports_at_stage: true,
        allow_bulk_duplicate_imports: true,
        view_on_zillow: true,
        export_transactions: true,
        debug_actions: false,
        full_date_ranges: true,
      },
      reporting_analytics: {
        enabled: true,
      },
      trust_hub: {
        enabled: true,
        sms: true
      },
      sms_chat: {
        enabled: true,
        sequence: true,
      },
      integrations: {
        enabled: true,
        bomb_bomb: true,
        bonzo: false,
        google: true,
        microsoft: true,
        total_expert: {
          enabled: true,
          export: true,
        },
        zapier: true,
        hubspot: true,
        salesforce: true,
      },
      playbooks: {
        enabled: true,
      },
      pulse: {
        enabled: true,
        title_company: true,
      },
      compass: {
        enabled: true,
        title_company: false,
      },
      team_chat: {
        enabled: false,
      },
      debug: {
        enabled: false,
        permissions: false,
        tail_logs: false,
      },
    },
  },
};

export function getTierTemplates(lookup_key: CombinedLookupKeys): Permissions {
  const templates = Object.assign({}, clone(baseTierTemplates), getPrismTierTemplates()) as PermissionTierMap &
    PrismPermissionTierMap;
  const foundTemplate = templates[lookup_key];
  if (!foundTemplate) throw new Error(`Permissions tier template ${lookup_key} not found userPermissionDefaults`);
  return clone(foundTemplate.permissions);
}

export function getTierLookupKeys(): CombinedLookupKeys[] {
  const templates = Object.assign({}, clone(baseTierTemplates), getPrismTierTemplates());
  return Object.keys(templates) as CombinedLookupKeys[];
}

// backend
export function getPrismTierTemplates(): PrismPermissionTierMap[] {
  const tiersToModify = ['tier_2', 'tier_3'];
  // Filter templates by specified tiers and map to new prism keys with updated permissions
  const prismTierTemplates = Object.values(clone(baseTierTemplates))
    .filter((template: any) => tiersToModify.includes(template.lookup_key))
    .map((template: any) => {
      // Replace 'tier' with 'prism' in lookup keys
      const newLookupKey = template.lookup_key.replace('tier', 'prism') as PrismLookUpKey;

      // Enable 'white_label' permissions
      let updatedPermissions = setPermissionValue(['cms', 'white_label'], template.permissions, true);

      // Return new template object
      return { lookup_key: newLookupKey, permissions: updatedPermissions };
    });

  // Convert array of templates into a dictionary using lookup keys
  return Object.fromEntries(
    prismTierTemplates.map((template: any) => [template.lookup_key, template])
  ) as PrismPermissionTierMap[];
}

// frontend
export function displayPrismTiers(productTiers: ProductTier[]) {
  // console.log('👽 JJ LOG ~ productTiers:', productTiers);
  const tiers = ['tier_2', 'tier_3'];
  const tiersToModify = productTiers.filter((product: any) => tiers.includes(product.lookup_key));
  const prismProductTiers = tiersToModify.map((product: any) => {
    const price = product.price - 50;
    const lookup_key = product.lookup_key.replace('tier', 'prism');
    return {
      ...product,
      price,
      lookup_key,
    };
  });
  return clone(prismProductTiers);
}

const commonDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'icloud.com', 'outlook.com'];

export const emailDomainMatches = (userEmail: string, email: string) => {
  const userEmailDomain = userEmail.split('@')[1].toLowerCase().trim();
  const inviteEmailDomain = email.split('@')[1].toLowerCase().trim();

  const matchesCommon = (userDomain: string) => commonDomains.some((domain) => userDomain === domain);

  if (matchesCommon(inviteEmailDomain)) {
    return false;
  } else if (userEmailDomain === inviteEmailDomain) {
    return true;
  } else {
    return false;
  }
};
