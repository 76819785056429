import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlaybookService } from '../../services/playbook.service';
import { PlaybookTemplate, PlaybooksReqContact, PlaybookTemplateReqLink } from 'app/shared/model/playbook';
import { take } from 'rxjs/operators';

@Component({
  selector: 'mm-playbook-overall-score',
  templateUrl: './playbook-overall-score.component.html',
  styleUrls: ['./playbook-overall-score.component.scss'],
})
export class PlaybookOverallScoreComponent implements OnInit {
  @Input() reqId: string = '';
  @Input() contactId: string;
  @Input() playbookTemplateId: string = '';
  @Input() inpTaskChanged: boolean = false;
  @Input() reqTenantId: string = '';
  catId: string = '';
  @Input() stage: string = '';
  playbookTemplate: PlaybookTemplate = null;
  playbookReqContact: PlaybooksReqContact = null;
  scoreOverall: number = 0;
  dataLoaded: boolean = false;
  dataLinksLoaded: boolean = false;

  constructor(
    public playbookService: PlaybookService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.playbookTemplateId.length === 0 && this.reqId) {
      this.playbookService
        .readPlaybookTemplateReqLinkByReq(this.reqTenantId, this.reqId, false)
        .pipe(take(1))
        .subscribe(
          (result) => {
            const playbookTemplateReqLinks: PlaybookTemplateReqLink = result.data.readPlaybookTemplateReqLinkByReq;

            if (playbookTemplateReqLinks) this.playbookTemplateId = playbookTemplateReqLinks.playbookTemplateId;

            if (this.stage) {
              if (this.stage.slice(-1) === 's') this.stage = this.stage.slice(0, -1);
            } else this.stage = 'prospect';

            this.getScore(null);
            // console.log("PlaybookOverallScoreComponent ngOnInit reqId", this.reqId, this.playbookTemplateId, this.contactId, this.stage);
          },
          (error) => {
            console.log(
              'PlaybookTemplatesReqLinkerComponent: There was an error loading req playbookTemplates links ',
              error
            );
          }
        );
    } else {
      if (this.stage) {
        if (this.stage.slice(-1) === 's') this.stage = this.stage.slice(0, -1);
      } else this.stage = 'prospect';

      this.getScore(null);
      // console.log("PlaybookOverallScoreComponent ngOnInit reqId", this.reqId, this.playbookTemplateId, this.contactId, this.stage);
    }
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.stage) {
      if (this.stage.slice(-1) === 's') this.stage = this.stage.slice(0, -1);
    } else this.stage = 'prospect';
    // console.log("PlaybookOverallScoreComponent ngOnInit reqId", this.reqId, this.playbookTemplateId, this.contactId, this.stage);

    this.getScore(null);
  }

  getScore(event: any) {
    if (this.reqId && this.contactId && this.playbookTemplateId) {
      // Read in source template for description and other values that are not user changeable
      // This allows MINOR changes to top level template to propagate to lower templates
      this.playbookService
        .readPlaybookTemplate(this.reqTenantId, this.playbookTemplateId)
        .pipe(take(1))
        .subscribe(
          (result) => {
            // console.log("PlaybookCategoriesEditorComponent: playbookTemplate ", result.data.readPlaybookTemplate);

            this.playbookTemplate = result.data.readPlaybookTemplate;

            // Let UI know data loading is complete
            this.dataLoaded = true;
            this.playbookService
              .readSinglePlaybookReqContact(this.reqTenantId, this.reqId, this.contactId, this.playbookTemplateId)
              .pipe(take(1))
              .subscribe(
                (result) => {
                  this.playbookReqContact = result.data.readSinglePlaybookReqContact;

                  let totalWeightAllCat = 0;
                  let totalWeightCompleteAllCat = 0;

                  // Get sum of tasks weight all categories - from master template
                  // Get total weight for all tasks in template
                  totalWeightAllCat = this.playbookTemplate?.categories
                    .map((cat) =>
                      cat.stages
                        .map((stage) =>
                          stage.tasks.map((item) => item.weight).reduce((accCat, objCat) => accCat + objCat, 0)
                        )
                        .reduce((accStage, objStage) => accStage + objStage, 0)
                    )
                    .reduce((accItem, objItem) => accItem + objItem, 0);

                  // This one ignores "delete" or "archived" items - does not require clean copy
                  // Outer search template
                  totalWeightCompleteAllCat = this.playbookTemplate?.categories
                    .map((cat) =>
                      cat.stages
                        .map((stage) =>
                          stage.tasks
                            .filter((taskItemFilter) => {
                              // Get counts from the Req Contact Data that matches the template categories
                              let catIndexReqCon = this.playbookReqContact?.playbookObj?.categories.findIndex(
                                (thisCat) => thisCat.id === cat.id
                              );

                              let matches = [];

                              this.playbookReqContact?.playbookObj?.categories[catIndexReqCon].stages.forEach(
                                function (stage) {
                                  matches = matches.concat(
                                    stage.tasks.filter(function (task) {
                                      return task.id === taskItemFilter.id && task.status === 'complete';
                                    })
                                  );
                                }
                              );

                              // Only count if match found
                              return matches.length > 0;
                              // taskItemFilter.status === "complete";
                            })
                            .map((item) => item.weight)
                            .reduce((accCat, objCat) => accCat + objCat, 0)
                        )
                        .reduce((accStage, objStage) => accStage + objStage, 0)
                    )
                    .reduce((accItem, objItem) => accItem + objItem, 0);

                  this.scoreOverall = (totalWeightCompleteAllCat / totalWeightAllCat) * 100;

                  // console.log("getScore", this.scoreOverall, totalWeightCompleteAllCat, totalWeightAllCat);
                },
                (error) => {
                  console.log('PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact error ', error);
                }
              );
          },
          (error) => {
            console.log('PlaybookCategoriesEditorComponent: There was an error loading playbookTemplate ', error);
            // Even though error still let ui data is finished loading
            this.dataLoaded = true;
          }
        );
    } else {
      this.scoreOverall = 0;
    }
  }
}
