<div class="playbook-tab-container">
  <div *ngIf="contactId && reqId && stage && playbookTemplateId">
    <mm-playbook-cat-select-tabs
      reqId="{{ reqId }}"
      [reqTenantId]="reqTenantId"
      contactId="{{ contactId }}"
      stage="{{ stage }}"
      playbookTemplateId="{{ playbookTemplateId }}"
      (catChanged)="onCatChanged($event)"
      [inpTaskChanged]="inpTaskChanged"
    >
    </mm-playbook-cat-select-tabs>

    <mm-playbook-task-edit-kanban
      [viewStyle]="getSticky()"
      reqId="{{ reqId }}"
      [reqTenantId]="reqTenantId"
      [reqUsers]="reqUsers"
      contactId="{{ contactId }}"
      [stageEnteredDate]="stageEnteredDate"
      stage="{{ stage }}"
      playbookTemplateId="{{ playbookTemplateId }}"
      catId="{{ catId }}"
      (taskChanged)="onTaskChanged($event)"
    >
    </mm-playbook-task-edit-kanban>
  </div>

  <div *ngIf="!playbookTemplateId" class="playbook-card-container">
    <mat-card class="playbook-placeholder-card">
      <mat-card-header>
        <div mat-card-avatar class="playbook-placeholder-header-image"></div>
        <mat-card-title>No Playbook is Assigned</mat-card-title>
        <mat-card-subtitle>None Assigned by Administrator</mat-card-subtitle>
      </mat-card-header>
      <!-- Looks cheesy ... remove image for now unless find better <img mat-card-image src="assets/images/playbook.placeholder.png" alt="Playbooks"> -->
      <mat-card-content>
        <i>
          No playbook is assigned to this Requisition. You may add one or contact your account administrator for more
          details.
        </i>
      </mat-card-content>
      <mat-card-actions style="text-align: center">
        <a mat-raised-button href="https://help.modelmatch.com/help/playbooks" target="_blank">Learn More</a>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
