import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  isProgress: boolean = false;
  startLoader() {
    this.isProgress = true;
  }

  stopLoader() {
    this.isProgress = false;
  }
}
