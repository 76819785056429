// import { NgModule } from '@angular/core';
// import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
// import { ApolloClientOptions, InMemoryCache, ApolloLink, ApolloCache } from '@apollo/client/core';
// import { HttpLink } from 'apollo-angular/http';
// import { environment } from 'src/environments/environment';
// import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
// import introspectionResults from '../../fragment-matcher.json';
// import { setContext } from '@apollo/client/link/context';
// import { UserService } from './services/user.service';
// import { CognitoService } from './services/cognito.service';
// import { persistCache } from 'apollo-cache-persist';
// import { openDB } from 'idb';

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData: introspectionResults,
// });

// const uri = environment.graphQLUri;
// export function createApollo(httpLink: HttpLink, cognitoService: CognitoService): ApolloClientOptions<any> {
//   const auth = setContext(async (operation, context) => {
//     try {
//       const token = await cognitoService.getIdToken().toPromise();

//       return {
//         headers: {
//           authorization: token.idToken,
//         },
//       };
//     } catch (err) {
//       console.log(err);
//       return {
//         headers: { 'x-api-key': environment.appsyncApiKey },
//       };
//     }
//   });

//   const cache = new InMemoryCache({
//     possibleTypes: {
//       MarketInsightsResponseTypes: ['Lead', 'Company', 'ListingAgent', 'BuyerAgent', 'Property'],
//       MarketInsightsSuggestionResponseTypes: ['LocationSuggestion', 'CompanySuggestion', 'LeadSuggestion'],
//     },
//   }) as ApolloCache<unknown>;

//   const db = openDB('apollo-cache', 1, {
//     upgrade(db) {
//       if (!db.objectStoreNames.contains('apollo-cache')) {
//         db.createObjectStore('apollo-cache');
//       }
//     },
//   });

//   const persistCachePromise = db.then((db) =>
//     persistCache({
//       // @ts-ignore
//       cache,
//       storage: {
//         getItem: async (key) => {
//           const tx = db.transaction(['apollo-cache'], 'readonly');
//           const store = tx.objectStore('apollo-cache');
//           const result = await store.get(key);
//           return result;
//         },
//         setItem: async (key, value) => {
//           const tx = db.transaction(['apollo-cache'], 'readwrite');
//           const store = tx.objectStore('apollo-cache');
//           await store.put(value, key);
//         },
//         removeItem: async (key) => {
//           const tx = db.transaction(['apollo-cache'], 'readwrite');
//           const store = tx.objectStore('apollo-cache');
//           await store.delete(key);
//         },
//       },
//     })
//   );

//   persistCachePromise.catch((error) => {
//     console.error('Error initializing Apollo cache persistence:', error);
//   });

//   const link = ApolloLink.from([auth, httpLink.create({ uri })]);
//   return {
//     link,
//     cache,
//   };
// }

// @NgModule({
//   exports: [ApolloModule],
//   providers: [
//     {
//       provide: APOLLO_OPTIONS,
//       useFactory: createApollo,
//       deps: [HttpLink, CognitoService],
//     },
//   ],
// })
// export class GraphQLModule {}

import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, ApolloLink } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionResults from '../../fragment-matcher.json';
import { setContext } from '@apollo/client/link/context';
import { UserService } from './services/user.service';
import { CognitoService } from './services/cognito.service';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResults,
});

const uri = environment.graphQLUri;
export function createApollo(httpLink: HttpLink, cognitoService: CognitoService): ApolloClientOptions<any> {
  const auth = setContext(async (operation, context) => {
    try {
      const token = await cognitoService.getIdToken().toPromise();

      return {
        headers: {
          authorization: token.idToken,
        },
      };
    } catch (err) {
      console.log(err);
      return {
        headers: { 'x-api-key': environment.appsyncApiKey },
      };
    }
  });

  const link = ApolloLink.from([auth, httpLink.create({ uri })]);
  return {
    link,
    cache: new InMemoryCache({
      possibleTypes: {
        MarketInsightsResponseTypes: ['Lead', 'Company', 'ListingAgent', 'BuyerAgent', 'Property'],
        MarketInsightsSuggestionResponseTypes: ['LocationSuggestion', 'CompanySuggestion', 'LeadSuggestion'],
      },
    }),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, CognitoService],
    },
  ],
})
export class GraphQLModule {}
