import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { FilterEmailTemplates, GetAllEmailTemplates, GetEmailSignature } from 'app/graphql/types';
import { filterEmailTemplates, getAllEmailTemplates, getEmailSignature } from 'app/graphql/queries';
import { addEmailSignature, addEmailTemplate, deleteEmailTemplate, updateEmailTemplate } from '../graphql/mutations';

@Injectable()
export class EmailSettingsService {
  constructor(private apollo: Apollo) {}

  public addEmailTemplate(templateTitle: string, templateBody: string, templateSubject: string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(addEmailTemplate),
      variables: {
        templateTitle,
        templateBody,
        templateSubject,
      },
    });
  }

  public getAllEmailTemplates(): Observable<any> {
    return this.apollo.watchQuery<GetAllEmailTemplates>({
      query: gql(getAllEmailTemplates),
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public updateEmailTemplate(
    templateId: string,
    templateTitle: string,
    templateBody: string,
    templateSubject: string
  ): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(updateEmailTemplate),
      variables: {
        templateId,
        templateTitle,
        templateBody,
        templateSubject,
      },
    });
  }

  public deleteEmailTemplate(templateId: string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(deleteEmailTemplate),
      variables: {
        templateId,
      },
    });
  }

  public filterEmailTemplates(templateTitle: string): Observable<any> {
    return this.apollo.watchQuery<FilterEmailTemplates>({
      query: gql(filterEmailTemplates),
      variables: { templateTitle },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public addEmailSignature(signature: string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(addEmailSignature),
      variables: {
        signature,
      },
    });
  }

  public getEmailSignature(): Observable<any> {
    return this.apollo.watchQuery<GetEmailSignature>({
      query: gql(getEmailSignature),
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
}
