/* eslint-disable rxjs/no-nested-subscribe */
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ReportingService } from '../../../services/reporting.service';
import { IntegrationsService } from '../../../services/integrations.service';
import { TodoService } from '../../../services/todo.service';
import { ContactService } from 'app/services/contact.service';
import { AlertDialogComponent, AlertDialogConfig } from 'app/shared/dialogs/alert-dialog.component';
import { Permissions, PermissionsService } from 'app/services/permissions.service';
import { GetAgentStatsGQL, UpdateReqContactWithProductionGQL } from 'graphql.types';

@Component({
  selector: 'mm-export-integration-dialog',
  templateUrl: './export-integration-dialog.component.html',
  styleUrls: ['./export-integration-dialog.component.scss'],
})
export class ExportIntegrationDialogComponent implements OnInit, OnDestroy {
  userIA: Permissions;
  exportLog: string[] = [];
  private unsubscribe$ = new Subject<void>();
  selectedCount: number = 0;
  processedCounter: number = 0;
  percentProcessed: number = 0;
  duplicateCounter: number = 0;
  errorCounter: number = 0;
  cancelRequest: boolean = false;
  actionInProgress: string = '';
  completed: boolean = false;
  iAPIOAuthKeyStored: any;
  loadingExportContacts: boolean = false;
  integrationVerified: boolean = true;
  integrationName: string = ''

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ExportIntegrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private integrationsService: IntegrationsService,
    private contactService: ContactService,
    private updateReqContactWithProductionGQL: UpdateReqContactWithProductionGQL,
    private getAgentStats: GetAgentStatsGQL,
  ) {}

  ngOnInit() {
    this.integrationName = this.data.integrationName
    this.loadFromArray(structuredClone(this.data.selectedRows))
    // console.log('👽 JJ LOG ~ file: export-hubspot-dialog.component.ts:58 ~ this.data.selectedRows:', this.data.selectedRows);
  }

  initAfterPermissions() {
    let doNada = 0;
  }

  onCloseClick() {
    if (this.selectedCount > this.processedCounter) {
      let dialogConfig: AlertDialogConfig = {
        title: 'There are still contact exports processing.',
        message: 'This will stop the import process',
        extraMessage: 'Are you sure you want to stop now?',
        confirmButton: {
          label: 'Continue Export',
          color: 'primary',
        },
        cancelButton: {
          label: "Stop Export",
          color: 'accent',
        },
      };

      let dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, string>(AlertDialogComponent, {
        panelClass: 'alert-dialog-component',
        data: dialogConfig,
        autoFocus: false,
        width: '35%',
      });

      dialogRef.afterClosed().subscribe((confirmation) => {
        if (confirmation) {
          this.cancelRequest = true;
          this.dialogRef.close({ data: this.exportLog });
        }
      });
    } else {
      this.cancelRequest = true;
      this.dialogRef.close({ data: this.exportLog });
    }
  }


  async loadFromArray(selectedRows) {
    this.loadingExportContacts = true;

    this.logMessage('Gathering Model Match Contact Data');
    
    try {
        const contactsPromises = selectedRows.map(row => {
            const [reqId, contactId] = row.split('|');
            return this.contactService.getReqContact(contactId, reqId).pipe(take(1)).toPromise();
        });

      let contacts = await Promise.all(contactsPromises);
      
      contacts = contacts.map(contact => contact.data.getReqContact);
        

        
      this.selectedCount = contacts.length;
    
        this.exportLoop(structuredClone([...contacts]));
        
    } catch (error) {
    
    } finally {
        this.loadingExportContacts = false;
    }
}


  async exportLoop(contactsWithReqs) {

    this.initiateExportProperties();

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const BATCH_SIZE = 100;
    const contactsBatch = [];

    if (contactsWithReqs && contactsWithReqs.length) {
        for (const [index, contact] of contactsWithReqs.entries()) {
            // console.log(contact);
            this.updateExportStatus(index, contactsWithReqs.length, contact);

            const updatedContact = await this.getUpdatedContactData(contact);
            if (!updatedContact) continue;

            contactsBatch.push(updatedContact);

            // If contactsBatch reaches the BATCH_SIZE or it's the last contact, send the batch
          if (contactsBatch.length === BATCH_SIZE || index === contactsWithReqs.length - 1) {
            if (this.integrationName.toLowerCase().includes('hubspot')) {
                
              this.sendContactsToHubSpot([...contactsBatch]);
            } else if (this.integrationName.toLowerCase().includes('salesforce')) {
              this.sendContactsToSalesForce([...contactsBatch])
            }
                contactsBatch.length = 0;  // Clear the batch
            }

            this.processedCounter++;

            if (this.cancelRequest) break;
            await sleep(400);
        }
    }

    this.finalizeExport();
  }
  
  async sendContactsToHubSpot(contacts: any) {
    this.logMessage('Sending all contacts to HubSpot Now!');
     console.log('👽 JJ LOG ~ sending these contacts:', contacts);
  
     this.integrationsService.sendContactsToHubSpot(JSON.stringify(contacts)).pipe(take(1)).subscribe(res => {
    
       console.log('👽 Sent Contacts to HubSpot Results', res);
      // @ts-ignore
       if (!res[0]?.success || !res[1]?.success) {
         this.errorCounter = this.processedCounter
       }
    
   })
  }

  async sendContactsToSalesForce(contacts: any) {
    this.logMessage('Sending all contacts to SalesForce Now!');
     console.log('👽 JJ LOG ~ sending these contacts:', contacts);
  
     this.integrationsService.sendContactsToSalesForce(contacts).pipe(take(1)).subscribe(res => {
       console.log('👽 Sent Contacts to SalesForce Results', res);
    
   })
  }
  


initiateExportProperties() {
    this.cancelRequest = false;
    this.completed = false;
    this.actionInProgress = 'processing';
}

updateExportStatus(index: number, totalContacts: number, contact: any) {
    this.actionInProgress = 'Getting next contact info';
    this.percentProcessed = ((index + 1) / totalContacts) * 100;
    this.actionInProgress = 'Getting latest Production';
    this.exportLog.unshift(`Getting latest Production data for ${contact.firstName} ${contact.lastName}`);
}

// Helper to generate contact URL
generateContactURL(id: string, secondaryId?: string): string {
  const base = `${window.location.origin}/#/contacts/`;
  return secondaryId ? `${base}${id}/${secondaryId}` : `${base}${id}`;
}

// Helper to generate market insights URL
generateMarketInsightsURL(id: string): string {
  return `${window.location.origin}/#/marketinsights/${id.includes('||') ? 'agent' : 'individual'}/${id}`;
}


async getUpdatedContactData(contact: any) {

  if (contact.emails.length < 1) {
      // this.logMessage(`No valid email for contact ${contact.firstName} ${contact.lastName}`);
  }
  if (!contact.reqId) {
    contact.reqId = this.data.reqId
  }
  contact.mm_contact_url = this.generateContactURL(contact.contactId);
  
  if (!contact.nmlsId && !contact.agentId) return contact
  contact.mm_market_insights_url = this.generateMarketInsightsURL(contact.nmlsId || contact.agentId);
  
  if (contact.nmlsId) {
    try {
      const response = await this.updateReqContactWithProductionGQL
          .mutate({
              reqId: this.data.reqId,
              contactId: contact.contactId,
          })
          .pipe(take(1))
          .toPromise();

      // merge contact object with new object so some data fields that this update doesn't return aren't lost
      const clonedContact = { ...contact, ...response.data.updateReqContactWithProduction };

 
      return clonedContact;
  } catch (error) {
      console.log('updateReqContactWithProduction error -> ', error, 'updating with the following fields:', contact);
      return contact
  }
  } else if (contact.agentId) {
    try {
      const response = await this.getAgentStats
          .fetch({
              agentIds: [contact.agentId],
          })
          .pipe(take(1))
        .toPromise();
      
      console.log('👽 JJ LOG ~ file: export-hubspot-dialog.component.ts:237 ~ response:', response);

    
      const clonedContact = { ...contact, ...response.data.getAgentStats[0] };
      console.log('👽 JJ LOG ~ file: export-hubspot-dialog.component.ts:245 ~ clonedContact:', clonedContact);
 
      return clonedContact;
  } catch (error) {
      console.log('updateReqContactWithProduction error -> ', error);
      return contact
  }
  }
    
    return contact
  
}


finalizeExport() {
    this.logMessage('Complete!');
    this.completed = true;
}


  logMessage(message) {
    this.exportLog.unshift(' ');
    this.exportLog.unshift('***');
    this.exportLog.unshift(message);
    this.exportLog.unshift('***');
    this.exportLog.unshift(' ');
  }

  ngOnDestroy() {
    this.cancelRequest = true;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
