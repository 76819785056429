import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dropdown, Field, FilterEvent } from '../model/filter';

@Component({
  selector: 'mm-filter-header',
  templateUrl: './filter-header.component.html',
  styleUrls: ['./filter-header.component.scss'],
})
export class FilterHeaderComponent implements OnInit {
  @Input() dropdowns: Dropdown[];
  @Output() filter = new EventEmitter<FilterEvent>();

  selected: string = '';

  constructor() {}

  ngOnInit() {}

  onFilter(field: Field, value: any) {
    this.filter.emit({ field, value });
  }
}
