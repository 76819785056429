<h1 mat-dialog-title>Resend User Registration</h1>
<mat-dialog-content>
  <form [formGroup]="resendUserCodeForm">
    <mat-form-field>
      <input matInput placeholder="Email" required formControlName="email" />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="warn" [mat-dialog-close]="true">Cancel</button>
  <button mat-button color="primary" (click)="resendUserCode()" [disabled]="!resendUserCodeForm.valid">Resend</button>
</mat-dialog-actions>
