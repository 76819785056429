import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject } from 'rxjs';
import { Page } from 'app/shared/model/filter';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  private _element: Element;
  private _currentPage: BehaviorSubject<Page> = new BehaviorSubject<Page>(null);
  @Input()
  set page(value: Page) {
    this._currentPage.next({
      page: value.page,
      pageSize: value.pageSize,
      totalSize: value.totalSize,
    });
  }

  get page(): Page {
    return this._currentPage.value;
  }

  // MatPaginator Inputs
  @Input() pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  @Output() pageChanged: EventEmitter<Page> = new EventEmitter();

  constructor(private elRef: ElementRef) {
    this._element = this.elRef.nativeElement;
  }

  onChange($event) {
    this.pageEvent = $event;
    let currentPage: Page = this._currentPage.value;
    currentPage.page = this.pageEvent.pageIndex + 1;
    currentPage.pageSize = $event.pageSize;
    this._currentPage.next(currentPage);
    this.pageChanged.emit(currentPage);
  }

  ngOnInit() {
    // console.log(this._element);
  }
}
