import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { User } from '../model/user';
import { AlertDialogConfig } from '../dialogs/alert-dialog.component';
import { AlertDialogComponent } from './../dialogs/alert-dialog.component';
import { RequisitionService } from 'app/services/requisition.service';
import { Option } from '../model/filter';
import { SatDatepickerRangeValue } from 'saturn-datepicker-ext';
import { ImageCachingService } from '../../services/imagecaching.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'mm-productivity-tab',
  templateUrl: './productivity-tab.component.html',
  styleUrls: ['./productivity-tab.component.scss'],
})
export class ProductivityTabComponent implements OnInit {
  private usersStream = new BehaviorSubject<User[]>([]);
  private usersSub: Subscription;

  @Input()
  set productivity(value: User[]) {
    this.usersStream.next(value);
  }

  get productivity(): User[] {
    return this.usersStream.value;
  }

  selectedUser: User;
  isAlertOpened: boolean = false;
  addingUsers: boolean = false;
  @Input() reqTitle: string;
  @Input() legacyTabLastXDaysOption: string;
  @Input() reqId: string;
  @Input() tenantId: string;
  @Input() handleAddUserClick: Function;
  @Input() currentUserId: string;
  @Input() reqOwnerId: string;
  @Input() currentUsers: User[];
  @Output() reqUserSelected = new EventEmitter<User>();
  @Output() reqUserRemoved = new EventEmitter<User>();
  @Output() timeChanged = new EventEmitter<number>();
  @Output() dateRangeChanged = new EventEmitter<{ startDate; endDate }>();
  @Input() isTenantAdmin: boolean;
  @Input() isTenantLeader: boolean;
  displayAsTable: boolean = false;
  userAvatarUrls: any = {};
  // 'candidate-conversion', 'meeting-conversion', 'requisitions', 'start-date', 'filter'

  displayedProductivityColumns: string[] = [
    'name',
    'notes-added',
    'todos-opened',
    'todos-completed',
    'meetings-scheduled',
    'meetings-completed',
    'remove-user',
  ];
  productivityTableSource: MatTableDataSource<User>;

  @ViewChild(MatSort) set content(sort: MatSort) {
    this.productivityTableSource.sort = sort;
  }

  timeOptions: Option[] = [
    { value: 0, title: '' },
    { value: 7, title: 'Last 7 days' },
    { value: 30, title: 'Last 30 days' },
    { value: 60, title: 'Last 60 days' },
    { value: 90, title: 'Last 90 days' },
  ];

  timeSelected: Number = 0;

  constructor(
    public dialog: MatDialog,
    private reqService: RequisitionService,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private imageCachingService: ImageCachingService,
    private router: Router
  ) {}

  dateRangeFilter: SatDatepickerRangeValue<Date>;

  ngOnInit() {
    this.productivityTableSource = new MatTableDataSource<User>([]);
    this.productivityTableSource.sortingDataAccessor = (user, property) => {
      switch (property) {
        case 'name':
          return `${user.firstName} ${user.lastName}`;
        case 'notes-added':
          return user.productivity.notesAdded;
        case 'todos-opened':
          return user.productivity.todosOpened;
        case 'todos-completed':
          return user.productivity.todosCompleted;
        case 'meetings-scheduled':
          return user.productivity.meetingsScheduled;
        case 'meetings-completed':
          return user.productivity.meetingsCompleted;
        default:
          return user[property];
      }
    };

    this.usersSub = this.usersStream.subscribe((users) => {
      this.productivityTableSource.data = users;
      this.getUserAvatarUrls(users);
    });

    // Added for date range picker
    // Set start date here - 30 by default

    let now = new Date();
    let fromDate = new Date();
    fromDate.setDate(now.getDate() - 30);

    const startDate = now ? moment(now).startOf('day').toISOString() : null;

    const endDate = fromDate ? moment(fromDate).endOf('day').toISOString() : null;

    this.dateRangeFilter = {
      begin: new Date(endDate),
      end: new Date(startDate),
    };
  }

  setRequisitionView(viewTable: boolean) {
    return viewTable ? (this.displayAsTable = true) : (this.displayAsTable = false);
  }

  onReqUserSelected(user: User) {
    if (!this.isAlertOpened) {
      this.reqUserSelected.emit(user);
    }
    this.isAlertOpened = false;
  }

  getUserAvatarUrls(users) {
    users.forEach((user) => {
      if (!this.userAvatarUrls[user.id]) {
        this.userService
          .getUserAvatarUrl(user.id)
          .pipe(take(1))
          .subscribe(
            ({ data }) => {
              const url = data.getUserAvatarUrl?.url || data.getContactAvatarUrl?.url;
              this.userAvatarUrls[user.id] = url || '';
              if (url) this.imageCachingService.cacheImageFromUrl(url, user.id);
            },
            (error) => console.log(error)
          );
      }
    });
  }

  removeUserFromReq(user: User) {
    this.isAlertOpened = true;
    this.selectedUser = user;
    const dialogConfig: AlertDialogConfig = {
      message:
        'You are about to remove this team member from ' +
        this.reqTitle +
        '. This cannot be undone. Are you sure you want to continue?',
      title: 'Remove User',
      cancelButton: {
        label: 'Discard',
        color: 'primary',
      },
      confirmButton: {
        label: 'Yes',
        color: 'warn',
      },
    };

    const dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, boolean>(AlertDialogComponent, {
      panelClass: 'alert-dialog-component',
      data: dialogConfig,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((isClosed) => {
      if (isClosed) {
        console.log(this.selectedUser);
        this.reqService.removeUserFromReq(this.selectedUser.id, this.reqId).subscribe(
          (result) => {
            console.log('AddContactToReqComponent :: removed contact from req result ', result);
            this.reqUserRemoved.emit(this.selectedUser);
            this.snackBar.open(
              `Removed ${this.selectedUser.firstName} ${this.selectedUser.lastName} from Requisition.`,
              '',
              {
                duration: 3000,
              }
            );
          },
          (error) => {
            console.log('AddContactToReqComponent :: error removing the contact from the requisition.', error);
            this.snackBar.open('Error Removing the User from Requisition.', '', {
              duration: 3000,
            });
          }
        );
      }
    });
  }

  removeSelfFromReq(user: User) {
    this.isAlertOpened = true;
    this.selectedUser = user;
    const dialogConfig: AlertDialogConfig = {
      message:
        'You are about to remove yourself from ' +
        this.reqTitle +
        '. This cannot be undone. Are you sure you want to continue?',
      title: 'Remove Self',
      cancelButton: {
        label: 'Discard',
        color: 'primary',
      },
      confirmButton: {
        label: 'Yes',
        color: 'warn',
      },
    };

    const dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, boolean>(AlertDialogComponent, {
      panelClass: 'alert-dialog-component',
      data: dialogConfig,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((isClosed) => {
      if (isClosed) {
        console.log(this.selectedUser);
        this.reqService.removeUserFromReq(this.selectedUser.id, this.reqId).subscribe(
          (result) => {
            console.log('AddContactToReqComponent :: removed contact from req result ', result);
            this.reqUserRemoved.emit(this.selectedUser);
            this.snackBar.open(
              `Removed ${this.selectedUser.firstName} ${this.selectedUser.lastName} from Requisition.`,
              '',
              {
                duration: 3000,
              }
            );
            // if result true, navigate back to requisitions page after 3 seconds
            setTimeout(() => this.router.navigate(['/requisitions']), 3000);
          },
          (error) => {
            console.log('AddContactToReqComponent :: error removing the contact from the requisition.', error);
            this.snackBar.open('Error Removing the User from Requisition.', '', {
              duration: 3000,
            });
          }
        );
      }
    });
  }

  onTimeChange(value: number) {
    let now = new Date();
    let fromDate = new Date();
    fromDate.setDate(now.getDate() - value);

    const startDate = now ? moment(now).startOf('day').toISOString() : null;

    const endDate = fromDate ? moment(fromDate).endOf('day').toISOString() : null;

    this.dateRangeFilter = {
      begin: new Date(endDate),
      end: new Date(startDate),
    };

    this.timeChanged.emit(value);
  }

  onDateChangeLegacyTab(dateRange: SatDatepickerRangeValue<Date>) {
    const startDate = dateRange ? moment(dateRange.begin).startOf('day').toISOString() : null;

    const endDate = dateRange ? moment(dateRange.end).endOf('day').toISOString() : null;

    // Emit the new date range to parent component
    this.dateRangeChanged.emit({ startDate, endDate });
    this.timeSelected = 0;
  }

  ngOnDestroy() {
    this.usersSub.unsubscribe();
  }
}
