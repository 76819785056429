import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { String } from 'aws-sdk/clients/acm';
import { AnyNsRecord } from 'dns';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  addContact,
  addContactsToReqs,
  addUserContactSearchFilter,
  bulkUpdateReqContactsMutation,
  createContactTag,
  deleteContactDocument,
  deleteContactFromReq,
  deleteContactTag,
  deleteUserContactSearchFilter,
  disableContact,
  generateFullVolumeReport,
  generateVolumeReport,
  initContactExport,
  shareContactToUser,
  shareUserContactSearchFilter,
  updateContact,
  updateContactBusiness,
  updateContactTag,
  updatePersonalInfo,
  updateReqContactsMutation,
  updateReqContactTags,
  updateUserContactSearchFilter,
  uploadContactAvatar,
  uploadContactDocument,
  updateContactLastUpdatedAtField,
} from '../graphql/mutations';
import {
  filterMyContactsQuery,
  filterUserContactsQuery,
  getContact,
  getContactAvatarUrl,
  getContactBusinessQuery,
  getContactDocuments,
  getContactDocumentUrl,
  getContactImportDownloadURLQuery,
  getContactReqs,
  getContactUsersQuery,
  getEnrichmentSuggestionsQuery,
  getMyContactsSelectQuery,
  getMyContactsWithReqsQuery,
  getMyReqsQuery,
  getPersonalInfo,
  getReqContactQuery,
  getReqContactsQuery,
  getReqContactTags,
  getUserContactSavedFilters,
  listContactTags,
  listDuplicateContacts,
  readContactTag,
  searchMyData,
  validateNmlsId,
  getContactVolumeReportQuery,
  getContactActive,
  getMyReqContactsQuery,
  getReqContactsForExportQuery,
  getCountReqContactsForExport,
  getUserContacts,
} from '../graphql/queries';
import {
  AddContact,
  AddContactsToReq,
  AddUserContactSearchFilter,
  BulkUpdateReqContacts,
  DeleteContactDocument,
  DeleteUserContactSearchFilter,
  FilterMyContactsQuery,
  FilterUserContactsQuery,
  GenerateFullVolumeReport,
  GenerateVolumeReport,
  GetContact,
  GetContactAvatarUrl,
  getContactBusiness,
  GetContactVolumeReport,
  GetContactDocuments,
  GetContactDocumentUrl,
  GetContactReqs,
  GetMyContactsQuery,
  GetPersonalInfo,
  GetReqContact,
  GetUserContactSavedFilters,
  InitContactExport,
  ListContactTags,
  ReadContactTag,
  SearchMyData,
  ShareContactToUser,
  ShareUserContactSearchFilter,
  UpdateContact,
  UpdateContactBusiness,
  UpdatePersonalInfo,
  UpdateUserContactSearchFilter,
  UploadContactAvatar,
  UploadContactDocument,
  DisableContact,
} from '../graphql/types';
import {
  ContactBusiness,
  ContactFilter,
  ContactInput,
  ContactTag,
  PersonalInfo,
  QueryStringContactFilter,
  UserContact,
  ReqContact,
  UserContactListFilteredItem,
  UserContactSearchFilter,
} from '../shared/model/contacts';
import { User } from '../shared/model/user';
import { NewSortOptions, Page, SortOption } from './../shared/model/filter';
import { ImageCachingService } from './imagecaching.service';
import { FullVolumeReport } from '../shared/model/volumeReport';
import { MatDialog } from '@angular/material/dialog';
import { ApolloQueryResult, WatchQueryFetchPolicy } from 'apollo-client';
import { CustomFieldsService } from './custom-fields.service';
import { CustomFieldContact, TenantCustomFieldRecord } from 'graphql.types';

@Injectable()
export class ContactService {
  private filteredContactsCache: UserContactListFilteredItem[] = [];
  private cachedContactFilter: ContactFilter | undefined;
  private cachedContactSort: SortOption | undefined;
  private cachedDisplayedColumns: any;
  private cachedDisplayedFilterColumns: any;
  private cachedPage: Page | undefined;

  constructor(
    private apollo: Apollo,
    private httpClient: HttpClient,
    private imageCachingService: ImageCachingService,
    private confirmDialog: MatDialog,
    private customFieldsService: CustomFieldsService
  ) {
    console.log('ContactService constructor!');
  }

  public getCachedContactFilter() {
    return this.cachedContactFilter;
  }

  public setCachedFilter(filter: ContactFilter) {
    this.cachedContactFilter = filter;
  }

  public setCachedContactSort(sort: SortOption) {
    this.cachedContactSort = sort;
  }

  public getCachedContactSort() {
    return this.cachedContactSort;
  }

  public setCachedDisplayedColumns(cachedDisplayedColumns: any) {
    this.cachedDisplayedColumns = cachedDisplayedColumns;
  }

  public getCachedDisplayedColumns(): any {
    return this.cachedDisplayedColumns;
  }

  public setCachedDisplayedFilterColumns(cachedDisplayedFilterColumns: any) {
    this.cachedDisplayedFilterColumns = cachedDisplayedFilterColumns;
  }

  public getCachedDisplayedFilterColumns(): any {
    return this.cachedDisplayedFilterColumns;
  }

  public getCachedFilteredContacts() {
    return this.filteredContactsCache;
  }

  public setCachedFilteredContacts(contacts: UserContact[]) {
    this.filteredContactsCache = contacts;
  }

  public getCachedPage() {
    return this.cachedPage;
  }

  public setCachedPage(page: Page) {
    this.cachedPage = page;
  }

  public filterMyContacts(
    page: Page,
    filters: ContactFilter,
    sortOption: SortOption,
    query = filterMyContactsQuery,
    fuzziness = []
  ) {
    const filterMyContactsQueryRef = this.apollo.watchQuery<
      FilterMyContactsQuery,
      {
        page: Page;
        filters: ContactFilter;
        sortOption: SortOption;
        fuzziness: string[];
      }
    >({
      query: gql(query),
      variables: {
        page: { page: page.page, pageSize: page.pageSize },
        filters,
        sortOption,
        fuzziness,
      },
      fetchPolicy: 'cache-first',
    });

    return filterMyContactsQueryRef;
  }

  public filterUserContacts(
    page: Page,
    filters: QueryStringContactFilter[],
    sortOption: NewSortOptions[],
    index: string,
    policy: WatchQueryFetchPolicy = 'network-only',
    reqStatus?: string,
    showDeletedContactsOnly: boolean = false,
    query = filterUserContactsQuery
  ) {
    // Pagination stopped working on reqs that were 'on hold' but no req status filter is specified.
    // Backend assumes all requests are 'active' req status by default
    if (index === 'req_contacts' && !reqStatus) reqStatus = 'active';

    const filterUserContactsQueryRef = this.apollo.watchQuery<
      FilterUserContactsQuery,
      {
        page: Page;
        filters: QueryStringContactFilter[];
        sortOption: NewSortOptions[];
        index: string;
        reqStatus: string;
        showDeletedContactsOnly: boolean;
      }
    >({
      query: gql(query),
      variables: {
        page: { page: page.page, pageSize: page.pageSize },
        filters,
        sortOption,
        index,
        reqStatus: reqStatus || '',
        showDeletedContactsOnly,
      },
      fetchPolicy: policy,
    });
    return filterUserContactsQueryRef;
  }

  public getMyContactsForSelect(): Observable<ApolloQueryResult<GetMyContactsQuery>> {
    //@ts-ignore
    return this.apollo.watchQuery<GetMyContactsQuery>({
      query: gql(getMyContactsSelectQuery),
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  public getMyContactsWithReqs(contactIds?: string[]): Observable<ApolloQueryResult<GetMyContactsQuery>> {
    //@ts-ignore
    return this.apollo.watchQuery<GetMyContactsQuery>({
      query: getMyContactsWithReqsQuery,
      variables: { contactIds },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  public getCountReqContactsForExport(reqId: string): Observable<ApolloQueryResult<any>> {
    //@ts-ignore
    return this.apollo.watchQuery<any>({
      query: gql(getCountReqContactsForExport),
      variables: { reqId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getReqContactsForExport(
    reqId: string,
    startContactId: string,
    size: string
  ): Observable<ApolloQueryResult<any>> {
    //@ts-ignore
    return this.apollo.watchQuery<any>({
      query: gql(getReqContactsForExportQuery),
      variables: { reqId, startContactId, size },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getContact(contactId: string): QueryRef<GetContact, { contactId: string }> {
    return this.apollo.watchQuery<GetContact, { contactId: string }>({
      query: gql(getContact),
      variables: { contactId },
      fetchPolicy: 'network-only',
    });
  }

  public getMyReqContacts(reqId: string, contactIds?: string[]): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getMyReqContactsQuery),
      variables: { reqId, contactIds },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getUserContacts(contactIds: string[]): Observable<any> {
    return this.apollo
      .query({
        query: gql(getUserContacts),
        variables: { contactIds: JSON.stringify(contactIds) },
        fetchPolicy: 'network-only',
      })
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ getUserContacts: string }>) => {
          const getUserContacts = result.data.getUserContacts;
          if (getUserContacts) {
            return JSON.parse(getUserContacts);
          }
        })
      );
  }

  public getContactActive(contactId: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getContactActive),
      variables: { contactId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getReqContact(contactId: string, reqId: string): Observable<any> {
    return this.apollo.watchQuery<GetReqContact>({
      query: gql(getReqContactQuery),
      variables: { contactId, reqId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  //changed getContact to use cache-first to greatly reduce the queries calling statistics sub-resolver
  //so I needed to create this separate process for getting contact business on the volume report page
  //because that process requires polling and it cannot work with cache-first fetch policy
  public getContactBusiness(contactId: string): QueryRef<getContactBusiness, { contactId: string }> {
    return this.apollo.watchQuery<getContactBusiness, { contactId: string }>({
      query: gql(getContactBusinessQuery),
      variables: { contactId },
    });
  }

  public getContactReqs(contactId: string): Observable<any> {
    return this.apollo.watchQuery<GetContactReqs>({
      query: gql(getContactReqs),
      variables: { contactId: contactId },
    }).valueChanges;
  }

  public searchMyData(searchKey: string): Observable<any> {
    return this.apollo.watchQuery<SearchMyData>({
      query: gql(searchMyData),
      variables: { searchKey: searchKey },
    }).valueChanges;
  }

  public getContactDocuments(contactId: string, reqId: string): Observable<any> {
    return this.apollo.watchQuery<GetContactDocuments>({
      query: gql(getContactDocuments),
      variables: { contactId: contactId, reqId: reqId },
    }).valueChanges;
  }

  public getContactDocumentUrl(contactId: string, documentId: string, reqId: string): Observable<any> {
    return this.apollo.watchQuery<GetContactDocumentUrl>({
      query: gql(getContactDocumentUrl),
      variables: { contactId: contactId, documentId: documentId, reqId: reqId },
    }).valueChanges;
  }

  public getContactAvatarUrl(contactId: string, documentId: any = ''): Observable<any> {
    const imageInCache = this.imageCachingService.checkImageInCache(contactId);
    if (imageInCache) return this.imageCachingService.getImage(contactId);
    return this.apollo.watchQuery<GetContactAvatarUrl>({
      query: gql(getContactAvatarUrl),
      variables: { contactId, documentId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public deleteDocument(contactId: string, documentId: string, reqId: string): Observable<any> {
    return this.apollo.mutate<DeleteContactDocument>({
      mutation: gql(deleteContactDocument),
      variables: { contactId: contactId, documentId: documentId, reqId: reqId },
      update: (store, { data: { deleteContactDocument } }: any) => {
        if (!deleteContactDocument) {
          return;
        }
        const query = gql(getContactDocuments);
        const variables = { contactId: contactId, reqId: reqId };
        const data: GetContactDocuments = store.readQuery({
          query,
          variables,
        }) as any;
        let index = data.getContactDocuments.findIndex((contactDocument) => contactDocument.id === documentId);
        data.getContactDocuments.splice(index, 1);

        store.writeQuery({ query, variables, data });
      },
    });
  }

  public uploadContactDocument(
    file: File,
    fileName: string,
    fileType: string,
    contactId: string,
    reqId: string,
    documentId: string
  ): Observable<any> {
    return this.apollo.mutate<UploadContactDocument>({
      mutation: gql(uploadContactDocument),
      variables: {
        fileName: fileName,
        fileType: fileType,
        contactId: contactId,
        reqId: reqId,
        documentId: documentId,
      },
      update: (store, { data: { uploadContactDocument } }: any) => {
        if (!uploadContactDocument) {
          return;
        }

        let headers: HttpHeaders = new HttpHeaders();
        headers.set('Content-Type', file.type);
        this.httpClient
          .put(uploadContactDocument.uploadUrl, file, {
            headers: headers,
          })
          .subscribe(
            (event: any) => {
              console.log('ContactService :: uploadContactDocument - Event == %s', JSON.stringify(event));
            },
            (error: unknown) => {
              console.error('ContactService :: uploadContactDocument - Error == %s', JSON.stringify(error));
            }
          );

        const query = gql(getContactDocuments);
        const variables = { contactId: contactId, reqId: reqId };
        const notClonedData: GetContactDocuments = store.readQuery({
          query,
          variables,
        }) as any;
        const data = structuredClone(notClonedData);
        let index = data.getContactDocuments.findIndex(
          (contactDocument) => contactDocument.id === uploadContactDocument.id
        );
        if (index === -1) {
          data.getContactDocuments.push(uploadContactDocument);
        } else {
          data.getContactDocuments[index] = uploadContactDocument;
        }

        store.writeQuery({ query, variables, data });
      },
    });
  }

  public uploadContactAvatar(file: File, fileName: string, fileType: string, contactId: string): Observable<any> {
    return this.apollo.mutate<UploadContactAvatar>({
      mutation: gql(uploadContactAvatar),
      variables: {
        fileName: fileName,
        fileType: fileType,
        contactId: contactId,
      },
      update: (store, { data: { uploadContactAvatar } }: any) => {
        if (!uploadContactAvatar) {
          return;
        }
        let headers: HttpHeaders = new HttpHeaders();
        headers.set('Content-Type', file.type);
        this.httpClient
          .put(uploadContactAvatar.uploadUrl, file, {
            headers: headers,
          })
          .subscribe(
            (event: any) => {
              try {
                const contactInfo: GetContact | null = store.readQuery({
                  query: gql(getContact),
                  variables: { contactId },
                });
                console.log(contactInfo);
                store.writeQuery({
                  query: gql(getContact),
                  variables: { contactId },
                  data: {
                    getContact: {
                      ...contactInfo?.getContact,
                      avatarId: fileName,
                    },
                  },
                });
              } catch (e) {
                console.log(e);
              }
              this.imageCachingService.checkAndCacheImage(contactId, file);
            },
            (error: unknown) => {
              console.error('ContactService :: uploadContactDocument - Error == %s', JSON.stringify(error));
            }
          );
      },
    });
  }

  public getUserContactSavedFilters(): Observable<any> {
    return this.apollo.watchQuery<GetUserContactSavedFilters>({
      query: gql(getUserContactSavedFilters),
    }).valueChanges;
  }

  public getPersonalInfo(contactId: string): Observable<any> {
    return this.apollo.watchQuery<GetPersonalInfo>({
      query: gql(getPersonalInfo),
      variables: { contactId: contactId },
    }).valueChanges;
  }

  public updatePersonalInfo(contactId: string, info: PersonalInfo): Observable<any> {
    return this.apollo.mutate<UpdatePersonalInfo>({
      mutation: gql(updatePersonalInfo),
      variables: { contactId: contactId, info: info },
      update: (store, { data: { updateContactPersonalInfo } }: any) => {
        const data: GetPersonalInfo | null = store.readQuery({
          query: gql(getPersonalInfo),
          variables: { contactId: contactId },
        });
        if (data) {
          let clonedData = structuredClone(data);
          clonedData.getContactPersonalInfo = updateContactPersonalInfo;
          store.writeQuery({
            query: gql(getPersonalInfo),
            variables: { contactId: contactId },
            data: clonedData,
          });
        }
      },
    });
  }

  public addContact(contact: ContactInput): Observable<any> {
    console.log(contact);
    return this.apollo.mutate<AddContact>({
      mutation: gql(addContact),
      variables: { contact: contact },
      refetchQueries: [{ query: gql(getMyReqsQuery) }],
    });
  }

  /**
   * Used for disabling or enabling a contact's active status.
   * @param contactId The contact id to disable.
   * @returns A boolean of whether the contact should be enabled or not.
   */
  public disableContact(contactId: string, enableContact: boolean = false): Observable<any> {
    // console.log('Contact ID that was passed in to front end service: ', contactId, enableContact);
    return this.apollo.mutate<DisableContact>({
      mutation: gql(disableContact),
      variables: { contactId: contactId, enableContact },
    });
  }

  public async disableBulkContacts(contactIds: string[], enableContacts: boolean = false) {
    let disableContactPromises = contactIds.map((id) => this.disableContact(id, enableContacts).toPromise());
    return Promise.all(disableContactPromises);
  }

  public saveUserContactSearchFilter(searchFilter: any): Observable<any> {
    return this.apollo.mutate<AddUserContactSearchFilter>({
      mutation: gql(addUserContactSearchFilter),
      variables: { searchFilter: searchFilter },
      update: (store, { data: { addUserContactSearchFilter } }: any) => {
        if (!addUserContactSearchFilter) {
          return;
        }

        const query = gql(getUserContactSavedFilters);
        const data: GetUserContactSavedFilters = store.readQuery({
          query,
        }) as GetUserContactSavedFilters;
        let clonedData = structuredClone(data);
        clonedData.getUserContactSavedFilters.push(addUserContactSearchFilter);
        store.writeQuery({ query, data: clonedData });
      },
    });
  }

  public updateUserContactSearchFilter(id: string, filterCriteria: any[]): Observable<any> {
    return this.apollo.mutate<UpdateUserContactSearchFilter>({
      mutation: gql(updateUserContactSearchFilter),
      variables: { id: id, filterCriteria: filterCriteria },
      update: (store, { data: { updateUserContactSearchFilter } }: any) => {
        if (!updateUserContactSearchFilter) {
          return;
        }

        const query = gql(getUserContactSavedFilters);
        const data: GetUserContactSavedFilters = store.readQuery({
          query,
        }) as GetUserContactSavedFilters;
        let clonedData = structuredClone(data);
        let index = data.getUserContactSavedFilters.findIndex((obj) => obj.id === updateUserContactSearchFilter.id);
        if (index === -1) {
          clonedData.getUserContactSavedFilters.push(updateUserContactSearchFilter);
        } else {
          clonedData.getUserContactSavedFilters[index] = updateUserContactSearchFilter;
        }
        store.writeQuery({ query, data: clonedData });
      },
    });
  }

  public shareUserContactSearchFilter(userIds: string[], searchFilter: UserContactSearchFilter): Observable<any> {
    return this.apollo.mutate<ShareUserContactSearchFilter>({
      mutation: gql(shareUserContactSearchFilter),
      variables: { userIds: userIds, searchFilter: searchFilter },
    });
  }

  public deleteUserContactSearchFilter(id: string): Observable<any> {
    return this.apollo.mutate<DeleteUserContactSearchFilter>({
      mutation: gql(deleteUserContactSearchFilter),
      variables: { id: id },
      update: (store, { data: { deleteUserContactSearchFilter } }: any) => {
        if (!deleteUserContactSearchFilter) {
          return;
        }
        const query = gql(getUserContactSavedFilters);
        const data: GetUserContactSavedFilters = store.readQuery({
          query,
        }) as GetUserContactSavedFilters;
        let index = data.getUserContactSavedFilters.findIndex((obj) => obj.id === id);
        data.getUserContactSavedFilters.splice(index, 1);

        store.writeQuery({ query, data });
      },
    });
  }

  public updateContact(contactId: String, input: ContactInput, contactReqId: string = '') {
    console.log('Sending mutation: ', contactId, input);
    return this.apollo.mutate<UpdateContact>({
      mutation: gql(updateContact),
      variables: {
        contactId,
        input,
        contactReqId,
      },
      // update: (store, { data: { updateContact } }) => {
      //   const data: GetContact = store.readQuery({
      //     query: gql(getContact),
      //     variables: { contactId },
      //   });
      //   data.getContact = updateContact;
      //   console.log(updateContact);
      //   store.writeQuery({
      //     query: gql(getContact),
      //     variables: { contactId },
      //     data,
      //   });
      //   store.writeQuery({
      //     query: gql(getReqContactQuery),
      //     variables: { contactId, reqId },
      //     data,
      //   });
      // },
    });
  }

  public updateContactBusiness(contactId: String, input: ContactBusiness, isMerge: Boolean): Observable<any> {
    return this.apollo.mutate<UpdateContactBusiness>({
      mutation: gql(updateContactBusiness),
      variables: { contactId: contactId, input: input, isMerge: isMerge },
      update: (store, { data: { updateContactBusiness } }: any) => {
        const data: getContactBusiness | null = store.readQuery({
          query: gql(getContactBusinessQuery),
          variables: { contactId },
        });
        const business: ContactBusiness[] = data?.getContactBusiness || [];
        const updatedBusiness = business.map((a) => {
          return a.isUserInput ? updateContactBusiness : a;
        });
        if (updatedBusiness.every((a) => !a.isUserInput)) updatedBusiness.unshift(updateContactBusiness);
        store.writeQuery({
          query: gql(getContactBusinessQuery),
          variables: { contactId },
          data: { getContactBusiness: updatedBusiness },
        });
      },
    });
  }

  public addContactsToReq(contactIds: string[], reqId: string): Observable<any> {
    return this.apollo.mutate<AddContactsToReq>({
      mutation: gql(addContactsToReqs),
      variables: { contactIds, reqIds: [reqId] },
      update: (store, { data: { addContactsToReqs } }: any) => {
        contactIds.forEach((contactId) => {
          const data: GetContactReqs | null = store.readQuery({
            query: gql(getContactReqs),
            variables: { contactId: contactId },
          });
          if (data) {
            let clonedData = structuredClone(data);
            clonedData.getContactReqs.push(addContactsToReqs[0]);
            store.writeQuery({
              query: gql(getContactReqs),
              variables: { contactId: contactId },
              data: clonedData,
            });
          }
        });
      },
      refetchQueries: [
        { query: gql(getMyReqsQuery) },
        { query: gql(getReqContactsQuery), variables: { reqId: reqId } },
      ],
    });
  }

  public shareContactToUser(contactId: string, userId: string): Observable<any> {
    return this.apollo.mutate<ShareContactToUser>({
      mutation: gql(shareContactToUser),
      variables: { contactId: contactId, userId: userId },
    });
  }

  public initContactExport(contactIds?: string[]): Observable<any> {
    return this.apollo.mutate<InitContactExport>({
      mutation: gql(initContactExport),
      variables: { contactIds },
    });
  }

  public replaceSpaces(str) {
    let formattedString = str.replace(/\s+/g, '_').toLowerCase();
    return formattedString;
  }

  public async exportContactsToCSV(contacts: any[]) {
    const formatPhoneNumber = (input: string): string => {
      // If already in the correct format
      if (/^\d{3}-\d{3}-\d{4}$/.test(input)) {
        return input;
      }

      // If in the (123) 456 7890 or (123) 456-7890 format
      if (/^\(\d{3}\) \d{3}[- ]\d{4}$/.test(input)) {
        return input.replace(/^\((\d{3})\) (\d{3})[- ](\d{4})$/, '$1-$2-$3');
      }

      // If in the 3528712469 format
      if (/^\d{10}$/.test(input)) {
        return input.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
      }

      // If in the +12345678901 format
      if (/^\+\d{11}$/.test(input)) {
        return input.replace(/^\+\d(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
      }

      return input;
    };

    let reqId = contacts[0]?.reqId;
    console.log('🪵 ~ ContactService ~ exportContactsToCSV ~ contacts:', contacts);
    const csvData: any = [];
    const customFieldHeaders: any = [];
    if (reqId) {
      this.customFieldsService
        .getCustomFields({ reqId })
        .pipe(take(1))
        .subscribe((record) => {
          record.customFields.forEach((field) => customFieldHeaders.push(field.name));
        });
    }
    for (let contact of contacts) {
      let emails: any = [];
      if (contact.emails) {
        contact.emails.forEach((email) => {
          emails.push(`${email.type || 'Work'}: ${email.address}`);
        });
      }
      let phones: any = [];
      if (contact.phones) {
        contact.phones.forEach((phone) => {
          phones.push(`${phone.type || 'Office'}: ${formatPhoneNumber(phone.number)}`);
        });
      }
      const { nmlsId, firstName, lastName, createdAt, lastUpdatedAt, address } = contact;
      const company = contact.company || '';
      const reqId = contact.reqId || '';
      const reqName = contact.reqName || '';
      const stage = contact.stage || '';

      const assignedUser = contact.assignedUser
        ? `${contact.assignedUser.firstName} ${contact.assignedUser.lastName}`
        : '';

      let customFields = [];
      if (customFieldHeaders.length) {
        customFields = new Array(customFieldHeaders.length).fill('');
        if (contact.customFields) {
          let appendedValues: ReqContact[] = await this.customFieldsService.appendCustomFieldValues(contact.reqId, [
            contact,
          ]);
          // filter out custom fields that we could not find matching names for (this will be the case on deleted Tenant Custom Field Record)
          let contactCF: CustomFieldContact[] = appendedValues[0].customFields.filter((field) => field.name);
          contactCF.forEach((field) => {
            let matched = customFieldHeaders.indexOf(field.name);
            if (matched >= 0) {
              customFields[matched] = field.value;
            }
          });
        }
      }

      if (reqId)
        csvData.push({
          firstName,
          lastName,
          phones: phones.join(', '),
          emails: emails.join(', '),
          company,
          createdAt,
          address: `${address?.street || ''} ${address?.street2 || ''}`,
          city: address?.city || '',
          state: address?.state || '',
          zipCode: address?.zipCode || '',
          nmlsId: nmlsId || '',
          lastUpdatedAt,
          reqId,
          reqName,
          stage,
          assignedUser,
          customFields: customFields || '',
        });
      else
        csvData.push({
          firstName,
          lastName,
          phones: phones.join(', '),
          emails: emails.join(', '),
          company,
          createdAt,
          address: `${address?.street || ''} ${address?.street2 || ''}`,
          city: address?.city || '',
          state: address?.state || '',
          zipCode: address?.zipCode || '',
          nmlsId: nmlsId || '',
          lastUpdatedAt,
          customFields: customFields || '',
        });
    }
    const now = new Date().toISOString();
    const csvFilename = `modelmatch-contacts-${now}`;
    let csvOptions = {};

    if (reqId)
      csvOptions = {
        showLabels: true,
        headers: [
          'first_name',
          'last_name',
          'phones',
          'emails',
          'company_name',
          'created_date',
          'address',
          'city',
          'state',
          'zip_code',
          'nmlsid',
          'last_updated',
          'pipeline_id',
          'pipeline_name',
          'contact_stage',
          'assignedUser',
          ...customFieldHeaders.map((header) => this.replaceSpaces(header)),
        ],
      };
    else
      csvOptions = {
        showLabels: true,
        headers: [
          'first_name',
          'last_name',
          'phones',
          'emails',
          'company_name',
          'created_date',
          'address',
          'city',
          'state',
          'zip_code',
          'nmlsid',
          'last_updated',
          ...customFieldHeaders.map((header) => this.replaceSpaces(header)),
        ],
      };
    new ngxCsv(csvData, csvFilename, csvOptions);
  }

  public generateContactsToCSV(contacts: UserContact[]) {
    console.log('🪵 ~ ContactService ~ generateContactsToCSV ~ contacts:', contacts);
    let csvData: string = '';
    let customFieldHeaderCache: { [key: string]: string } = {};
    let customFieldHeaders = () => Object.values(customFieldHeaderCache);

    contacts.forEach((contact) => {
      let emails: any = [];
      if (contact.emails) {
        contact.emails.forEach((email) => {
          emails.push(`${email.type}: ${email.address}`);
        });
      }
      let phones: any = [];
      if (contact.phones) {
        contact.phones.forEach((phone) => {
          phones.push(`${phone.type}: ${phone.number}`);
        });
      }

      const { nmlsId, firstName, lastName, createdAt, lastUpdatedAt, address } = contact;

      const company = contact.company || '';

      contact.reqs?.forEach((req) => {
        const reqId = req.id;
        const reqName = req.title;
        const stage = req.stage;

        let customFields = [];
        let contactCustomFieldsMap = contact.customFields;

        // Add all of the customField names to the cache using their id as the key name.
        contactCustomFieldsMap.forEach((reqContactCustomFields) => {
          reqContactCustomFields.customFields.forEach((field) => {
            if (!customFieldHeaderCache[field.fieldId]) {
              customFieldHeaderCache[field.fieldId] = field.name;
            }
          });
        });

        // Loop through the headers and add the custom field value to the header that matches.
        if (customFieldHeaders().length) {
          // Default all row values of custom fields to an empty string.
          customFields = new Array(customFieldHeaders().length).fill('');
          // Find the contact custom fields that correspond to this req that we are looping over.
          let customFieldsMap = contactCustomFieldsMap.find((map) => map.reqId === reqId);
          // Assign the custom field value from the contact to the matching place in the custom fields array (based on the header).
          if (customFieldsMap.customFields) {
            customFieldsMap.customFields.forEach((field) => {
              let matched = customFieldHeaders().indexOf(field.name);
              if (matched >= 0) {
                customFields[matched] = JSON.stringify(field.value);
              }
            });
          }
        }

        csvData += `'${firstName}','${lastName}','${phones.join(', ')}','${emails.join(
          ','
        )}','${company}','${createdAt}','${address?.street || ''} ${address?.street2 || ''}','${
          address?.city || ''
        }','${address?.state || ''}','${
          address?.zipCode || ''
        }','${nmlsId}','${lastUpdatedAt}','${reqId}','${reqName}','${stage}',${customFields
          .map((item) => item?.replace(',', ' '))
          .join(',')}\n`;
      });
    });

    const now = new Date().toISOString();
    const csvFilename = `modelmatch-contacts-${now}`;
    const csvHeader = `'first_name','last_name','phones','emails','company_name','created_date','address','city','state','zip_code','nmlsid','last_updated','pipeline_ids','pipeline_names','contact_stage', ${customFieldHeaders()
      .map((header) => this.replaceSpaces(header))
      .join(',')}\n`;

    return { csvData, csvHeader };
  }

  public async exportPremiumContactsToCSV(contacts: ReqContact[], lessFlag: boolean) {
    const csvData: any = [];
    const customFieldHeaders: any = [];

    // console.log('exportPremiumContactsToCSV ', lessFlag);

    let reqId = contacts[0]?.reqId;
    if (reqId) {
      this.customFieldsService
        .getCustomFields({ reqId })
        .pipe(take(1))
        .subscribe((record) => {
          record.customFields.forEach((field) => customFieldHeaders.push(field.name));
        });
    }

    for (let contact of contacts) {
      let emails: any = [];
      if (contact.emails) {
        contact.emails.forEach((email) => {
          emails.push(`${email.type}: ${email.address}`);
        });
      }
      let phones: any = [];
      if (contact.phones) {
        contact.phones.forEach((phone) => {
          phones.push(`${phone.type}: ${phone.number}`);
        });
      }

      let links: any = [];
      if (contact.links) {
        contact.links.forEach((link) => {
          links.push(`${link.type}: ${link.url}`);
        });
      }
      let customFields: any = [];
      if (customFieldHeaders.length) {
        customFields = new Array(customFieldHeaders.length).fill('');
        if (contact.customFields) {
          let appendedValues: ReqContact[] = await this.customFieldsService.appendCustomFieldValues(contact.reqId, [
            contact,
          ]);

          // filter out custom fields that we could not find matching names for (this will be the case on deleted Tenant Custom Field Record)
          let contactCF: CustomFieldContact[] = appendedValues[0].customFields.filter((field) => field.name);

          contactCF.forEach((field) => {
            let matched = customFieldHeaders.indexOf(field.name);
            if (matched >= 0) {
              customFields[matched] = field.value;
            }
          });
        }
      }

      if (lessFlag) {
        const { nmlsId, firstName, lastName, createdAt, lastUpdatedAt, address, stage, reqId, reqName, contactId } =
          contact;

        const assignedUser = contact.assignedUser
          ? `${contact.assignedUser.firstName} ${contact.assignedUser.lastName}`
          : '';

        const company = contact.company || '';

        let searchURL = `https://google.com/search?q=${encodeURIComponent(
          firstName + '' + lastName
        )}+${encodeURIComponent(company)}`;

        let modelMatchURL = `https://${window.location.hostname}/#/contacts/${contactId}/${reqId}`;

        csvData.push({
          firstName,
          lastName,
          phones: phones.join(', '),
          emails: emails.join(', '),
          links: links.join(', '),
          company,
          createdAt,
          modelMatchURL,
          address: `${address?.street || ''} ${address?.street2 || ''}`,
          city: address?.city || '',
          state: address?.state || '',
          zipCode: address?.zipCode || '',
          nmlsId,
          lastUpdatedAt,
          reqId,
          reqName,
          stage,
          assignedUser,
          searchURL,
          customFields: customFields,
        });
      } else {
        const {
          nmlsId,
          firstName,
          lastName,
          createdAt,
          lastUpdatedAt,
          address,
          trailing12Units,
          trailing12Volume,
          stage,
          reqId,
          reqName,
          contactId,
          product_mix_conventional,
          product_mix_fha,
          product_mix_government,
          product_mix_jumbo,
          product_mix_usda,
          product_mix_va,
          purpose_mix_purchase,
          purpose_mix_refinance,
        } = contact;

        const assignedUser = contact.assignedUser
          ? `${contact.assignedUser.firstName} ${contact.assignedUser.lastName}`
          : '';

        const company = contact.company || '';

        let searchURL = `https://google.com/search?q=${encodeURIComponent(
          firstName + '' + lastName
        )}+${encodeURIComponent(company)}`;

        let modelMatchURL = `https://${window.location.hostname}/#/contacts/${contactId}/${reqId}`;

        csvData.push({
          firstName,
          lastName,
          phones: phones.join(', '),
          emails: emails.join(', '),
          links: links.join(', '),
          company,
          createdAt,
          modelMatchURL,
          address: `${address?.street || ''} ${address?.street2 || ''}`,
          city: address?.city || '',
          state: address?.state || '',
          zipCode: address?.zipCode || '',
          nmlsId,
          lastUpdatedAt,
          reqId,
          reqName,
          stage,
          assignedUser,
          trailing12Units,
          trailing12Volume,
          searchURL,
          product_mix_conventional: product_mix_conventional ? product_mix_conventional : '',
          product_mix_fha: product_mix_fha ? product_mix_fha : '',
          product_mix_government: product_mix_government ? product_mix_government : '',
          product_mix_jumbo: product_mix_jumbo ? product_mix_jumbo : '',
          product_mix_usda: product_mix_usda ? product_mix_usda : '',
          product_mix_va: product_mix_va ? product_mix_va : '',
          purpose_mix_purchase: purpose_mix_purchase ? purpose_mix_purchase : '',
          purpose_mix_refinance: purpose_mix_refinance ? purpose_mix_refinance : '',
          customFields: customFields,
        });
      }
    }

    const now = new Date().toISOString();
    const csvFilename = `modelmatch-contacts-${now}`;

    let csvOptions = {};
    if (lessFlag) {
      csvOptions = {
        showLabels: true,
        headers: [
          'first_name',
          'last_name',
          'phones',
          'emails',
          'links',
          'company_name',
          'created_date',
          'modelMatchURL',
          'address',
          'city',
          'state',
          'zip_code',
          'nmlsid',
          'last_updated',
          'pipelineId',
          'pipelineName',
          'contact_stage',
          'assignedUser',
          'searchURL',
          ...customFieldHeaders.map((header) => this.replaceSpaces(header)),
        ],
      };
    } else {
      csvOptions = {
        showLabels: true,
        headers: [
          'first_name',
          'last_name',
          'phones',
          'emails',
          'links',
          'company_name',
          'created_date',
          'modelMatchURL',
          'address',
          'city',
          'state',
          'zip_code',
          'nmlsid',
          'last_updated',
          'pipelineId',
          'pipelineName',
          'contact_stage',
          'assignedUser',
          'trailing14Units',
          'trailing14Volume',
          'searchURL',
          'product_mix_conventional',
          'product_mix_fha',
          'product_mix_government',
          'product_mix_jumbo',
          'product_mix_usda',
          'product_mix_va',
          'purpose_mix_purchase',
          'purpose_mix_refinance',
          ...customFieldHeaders.map((header) => this.replaceSpaces(header)),
        ],
      };
    }

    // console.log('exportPremiumContactsToCSV', csvData);

    new ngxCsv(csvData, csvFilename, csvOptions);
  }

  // prettier-ignore
  public async generatePremiumContactsToCSV(contacts: ReqContact[], lessFlag: boolean) {
    let csvData: string = '';
    const customFieldHeaders: any = [];

    let reqId = contacts[0]?.reqId;
    if (reqId) {
      this.customFieldsService
        .getCustomFields({ reqId })
        .pipe(take(1))
        .subscribe((record) => {
          record.customFields.forEach((field) => customFieldHeaders.push(field.name));
        });
    }

    for (let contact of contacts) {
      let emails: any = [];
      if (contact.emails) {
        contact.emails.forEach((email) => {
          emails.push(`${email.type}: ${email.address}`);
        });
      }
      let phones: any = [];
      if (contact.phones) {
        contact.phones.forEach((phone) => {
          phones.push(`${phone.type}: ${phone.number}`);
        });
      }

      let links: any = [];
      if (contact.links) {
        contact.links.forEach((link) => {
          links.push(`${link.type}: ${link.url}`);
        });
      }
      let customFields: any = [];
      if (customFieldHeaders.length) {
        customFields = new Array(customFieldHeaders.length).fill("");
        if (contact.customFields) {
          let appendedValues: ReqContact[] = await this.customFieldsService.appendCustomFieldValues(contact.reqId, [
            contact,
          ]);

          // filter out custom fields that we could not find matching names for (this will be the case on deleted Tenant Custom Field Record)
          let contactCF: CustomFieldContact[] = appendedValues[0].customFields.filter((field) => field.name);

          contactCF.forEach((field) => {
            let matched = customFieldHeaders.indexOf(field.name);
            if (matched >= 0) {
              customFields[matched] = field.value;
            }
          });
        }
      }

      if (lessFlag) {

        let {
          nmlsId,
          firstName,
          lastName,
          createdAt,
          lastUpdatedAt,
          address,
          stage,
          reqId,
          reqName,
          contactId,
        } = contact;

         const assignedUser = contact.assignedUser
           ? `${contact.assignedUser.firstName} ${contact.assignedUser.lastName}`
           : '';

        
        let company = contact.company || "";

        // @ts-ignore
        company = company.replaceAll('"', '""'); // @ts-ignore
        firstName = firstName.replaceAll('"', '""'); // @ts-ignore
        lastName = lastName.replaceAll('"', '""'); // @ts-ignore
        reqName = reqName.replaceAll('"', '""'); // @ts-ignore

        let searchURL = `https://google.com/search?q=${encodeURIComponent(
          firstName + "" + lastName
        )}+${encodeURIComponent(company)}`;

        let modelMatchURL = `https://${window.location.hostname}/#/contacts/${contactId}/${reqId}`;

        csvData += `"${firstName}","${lastName}","${phones.join(', ')}","${emails.join(', ')}","${links.join(
          ', '
        )}","${company}","${createdAt}","${modelMatchURL}","${address?.street || ''} ${address?.street2 || ''} ","${
          address?.city || ''
        } ","${address?.state || ''} ","${
          address?.zipCode || ''
        } ","${nmlsId} ","${lastUpdatedAt} ","${reqId}","${reqName}","${stage}","${assignedUser}","${searchURL}",${customFields
          .map((item) => item?.replace(', ', ' '))
          .join(', ')},\n`;
      } else {

        let {
          nmlsId,
          firstName,
          lastName,
          createdAt,
          lastUpdatedAt,
          address,
          trailing12Units,
          trailing12Volume,
          stage,
          reqId,
          reqName,
          contactId,
          product_mix_conventional,
          product_mix_fha,
          product_mix_government,
          product_mix_jumbo,
          product_mix_usda,
          product_mix_va,
          purpose_mix_purchase,
          purpose_mix_refinance
        } = contact;

         const assignedUser = contact.assignedUser
           ? `${contact.assignedUser.firstName} ${contact.assignedUser.lastName}`
           : '';


        let company = contact.company || "";

        // @ts-ignore
        company = company.replaceAll('"', '""'); // @ts-ignore
        firstName = firstName.replaceAll('"', '""'); // @ts-ignore
        lastName = lastName.replaceAll('"', '""'); // @ts-ignore
        reqName = reqName.replaceAll('"', '""'); // @ts-ignore
        assignedUser = assignedUser.replaceAll('"', '""'); // @ts-ignore

        let searchURL = `https://google.com/search?q=${encodeURIComponent(
          firstName + "" + lastName
        )}+${encodeURIComponent(company)}`;

        let modelMatchURL = `https://${window.location.hostname}/#/contacts/${contactId}/${reqId}`;

        csvData += `"${firstName}","${lastName}","${phones.join(', ')}","${emails.join(', ')}","${links.join(
          ', '
        )}","${company}","${createdAt}","${modelMatchURL}","${address?.street || ''} ${address?.street2 || ''} ","${
          address?.city || ''
        } ","${address?.state || ''} ","${
          address?.zipCode || ''
        } ","${nmlsId} ","${lastUpdatedAt}","${reqId}","${reqName}","${stage}","${assignedUser}","${trailing12Units}","${trailing12Volume}","${searchURL}",${customFields
          .map((item) => item?.replace(', ', ' '))
          .join(
            ', '
          )},"${product_mix_conventional}","${product_mix_fha}","${product_mix_government}","${product_mix_jumbo}","${product_mix_usda}","${product_mix_va}","${purpose_mix_purchase}","${purpose_mix_refinance}",\n`;
      }
    }

    const now = new Date().toISOString();
    const csvFilename = `modelmatch-contacts-${now}`;

    let csvHeader = "";
    if (lessFlag) {
      csvHeader = `"first_name","last_name","phones","emails","links","company_name","created_date","modelMatchURL","address","city","state","zip_code","nmlsid","last_updated","pipelineId","pipelineName","contact_stage","assignedUser","searchURL",${customFieldHeaders
        .map((header) => this.replaceSpaces(header))
        .join(',')}\n`;
    } else {
      csvHeader = `"first_name","last_name","phones","emails","links","company_name","created_date","modelMatchURL","address","city","state","zip_code","nmlsid","last_updated","pipelineId","pipelineName","contact_stage","assignedUser", "trailing14Units","trailing14Volume","searchURL",${customFieldHeaders
        .map((header) => this.replaceSpaces(header))
        .join(
          ','
        )}, "product_mix_conventional","product_mix_fha","product_mix_government","product_mix_jumbo","product_mix_usda","product_mix_va","purpose_mix_purchase","purpose_mix_refinance"\n`;
    }

    return { csvData, csvHeader };
  }

  public exportRegularContactsToCSV(contacts: ReqContact[]) {
    const csvData: any = [];
    contacts.forEach((contact) => {
      let emails: any = [];
      if (contact.emails) {
        contact.emails.forEach((email) => {
          emails.push(`${email.type}: ${email.address}`);
        });
      }
      let phones: any = [];
      if (contact.phones) {
        contact.phones.forEach((phone) => {
          phones.push(`${phone.type}: ${phone.number}`);
        });
      }

      let links: any = [];
      if (contact.links) {
        contact.links.forEach((link) => {
          links.push(`${link.type}: ${link.url}`);
        });
      }

      const {
        nmlsId,
        firstName,
        lastName,
        createdAt,
        lastUpdatedAt,
        address,
        trailing12Units,
        trailing12Volume,
        stage,
        reqId,
        reqName,
        contactId,
      } = contact;

      const assignedUser = contact.assignedUser
        ? `${contact.assignedUser.firstName} ${contact.assignedUser.lastName}`
        : '';

      const company = contact.company || '';

      let searchURL = `https://google.com/search?q=${encodeURIComponent(
        firstName + '' + lastName
      )}+${encodeURIComponent(company)}`;

      let modelMatchURL = `https://${window.location.hostname}/#/contacts/${contactId}/${reqId}`;

      csvData.push({
        firstName,
        lastName,
        phones: phones.join(', '),
        emails: emails.join(', '),
        company,
        createdAt,
        address: `${address?.street || ''} ${address?.street2 || ''}`,
        city: address?.city || '',
        state: address?.state || '',
        zipCode: address?.zipCode || '',
        nmlsId,
        lastUpdatedAt,
        reqId,
        reqName,
        stage,
        assignedUser,
      });
    });

    const now = new Date().toISOString();
    const csvFilename = `modelmatch-contacts-${now}`;
    const csvOptions = {
      showLabels: true,
      headers: [
        'first_name',
        'last_name',
        'phones',
        'emails',
        'company_name',
        'created_date',
        'address',
        'city',
        'state',
        'zip_code',
        'nmlsid',
        'last_updated',
        'pipeline_ids',
        'pipeline_names',
        'contact_stage',
        'assignedUser',
      ],
    };

    new ngxCsv(csvData, csvFilename, csvOptions);
  }

  // prettier-ignore
  public generateRegularContactsToCSV(contacts: ReqContact[]) {
    let csvData: string = '';
    contacts.forEach((contact) => {
      let emails: any = [];
      if (contact.emails) {
        contact.emails.forEach((email) => {
          emails.push(`${email.type}: ${email.address}`);
        });
      }
      let phones: any = [];
      if (contact.phones) {
        contact.phones.forEach((phone) => {
          phones.push(`${phone.type}: ${phone.number}`);
        });
      }

      let links: any = [];
      if (contact.links) {
        contact.links.forEach((link) => {
          links.push(`${link.type}: ${link.url}`);
        });
      }

      let {
        nmlsId,
        firstName,
        lastName,
        createdAt,
        lastUpdatedAt,
        address,
        trailing12Units,
        trailing12Volume,
        stage,
        assignedUser,
        reqId,
        reqName,
        contactId,
      } = contact;

      let company = contact.company || '';

      // @ts-ignore
      company = company.replaceAll('"', '""'); // @ts-ignore
      firstName = firstName.replaceAll('"', '""'); // @ts-ignore
      lastName = lastName.replaceAll('"', '""'); // @ts-ignore
      reqName = reqName.replaceAll('"', '""'); // @ts-ignore
      assignedUser = assignedUser.replaceAll('"', '""'); // @ts-ignore

      let searchURL = `https://google.com/search?q=${encodeURIComponent(
        firstName + '' + lastName
      )}+${encodeURIComponent(company)}`;

      let modelMatchURL = `https://${window.location.hostname}/#/contacts/${contactId}/${reqId}`;

      csvData += `"${firstName}","${lastName}","${phones.join(', ')}","${emails.join(
        ', '
      )}","${company}","${createdAt}","${address?.street || ''} ${address?.street2 || ''}","${address?.city || ''}","${
        address?.state || ''
      }","${
        address?.zipCode || ''
      }","${nmlsId}","${lastUpdatedAt}","${reqId}","${reqName}","${stage}", "${assignedUser}",\n`;
    });

    const csvHeader = `"first_name","last_name","phones","emails","company_name","created_date","address","city","state","zip_code","nmlsid","last_updated","pipeline_ids","pipeline_names","contact_stage","assignedUser"\n`;

    return { csvData, csvHeader };
    // new ngxCsv(csvData, csvFilename, csvOptions);
    // ""
  }

  // CREATE
  public createContactTag(tenantId: string, contactTagData: ContactTag, reqId: string): Observable<any> {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete contactTagData.__typename;

    return this.apollo.mutate({
      mutation: gql(createContactTag),
      variables: {
        tenantId: tenantId,
        contactTagData: contactTagData,
        reqId: reqId,
      },
      update: (store, { data: { addContactTag } }: any) => {
        // Add a new history item.
      },
    });
  }

  // READ
  public readContactTag(
    tenantId: string,
    contactTagId: string,
    networkOnly: boolean = true,
    reqId: string
  ): Observable<any> {
    return this.apollo.watchQuery<ReadContactTag>({
      query: gql(readContactTag),
      variables: {
        tenantId: tenantId,
        contactTagId: contactTagId,
        reqId: reqId,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  //  // fetchPolicy: networkOnly ? "network-only" : "cache-first",

  // Fomo ghosts 02-24-2023

  // LIST
  public listContactTags(tenantId: string, contactTagFilter: string, reqId: string): Observable<any> {
    return this.apollo.watchQuery<ListContactTags>({
      query: gql(listContactTags),
      variables: {
        tenantId: tenantId,
        contactTagFilter: contactTagFilter,
        reqId: reqId,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  // UPDATE
  updateContactTag(tenantId: string, contactTagId: string, contactTagData: any, reqId: string): Observable<any> {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete contactTagData.__typename;

    return this.apollo.mutate({
      mutation: gql(updateContactTag),
      variables: {
        tenantId: tenantId,
        contactTagId: contactTagId,
        contactTagData: contactTagData,
        reqId: reqId,
      },
      update: (store, { data: { updateContactTag } }: any) => {
        // Add a new history item.
      },
    });
  }

  // DELETE
  deleteContactTag(tenantId: string, contactTagId: string, reqId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(deleteContactTag),
      variables: {
        tenantId: tenantId,
        contactTagId: contactTagId,
        reqId: reqId,
      },
      update: (store, { data: { updateContactTag } }: any) => {
        // Add a new history item.
      },
    });
  }

  reorderOnDropContactTags(tenantId: string, contactTags: ContactTag[], reqId: string) {
    // initially relies on moveItemInArray from CDK to do the work for order
    // ***Todo - how to handle multiple users changing the same templates at the same time?

    // Quick and dirty - Better/faster to do this remote with its own end point
    // loop over templates - use plain for loop to keep access to playbook service scope
    for (let i = 0; i < contactTags.length; i++) {
      let dataToUpdateObj = { order: i };
      // contactTags[i].order = i;
      this.updateContactTag(tenantId, contactTags[i].tagId, dataToUpdateObj, reqId).pipe(take(1)).subscribe();
    }
  }

  // UPDATE User Contact Tags
  updateReqContactTags(reqId: string, contactId: string, contactTagData: any): Observable<any> {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    // Hack type as "any" to remove __typename....

    contactTagData.forEach((tag: any) => delete tag.__typename);

    return this.apollo.mutate({
      mutation: gql(updateReqContactTags),
      variables: {
        reqId: reqId,
        contactId: contactId,
        contactTagData: contactTagData,
      },
      update: (store, { data: { updateReqContactTags } }: any) => {
        // Add a new history item.
      },
    });
  }

  // Read User Contact Tags
  public getReqContactTags(reqId: string, contactId: string): Observable<any> {
    return this.apollo.watchQuery<ListContactTags>({
      query: gql(getReqContactTags),
      variables: {
        reqId: reqId,
        contactId: contactId,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  // LIST Duplicates
  public listDuplicateContacts(contactId: String): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(listDuplicateContacts),
      variables: {
        contactId: contactId,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  // DELETE Contact from Req
  deleteContactFromReq(contactId: string, reqId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(deleteContactFromReq),
      variables: {
        contactId: contactId,
        reqId: reqId,
      },
    });
  }

  public bulkUpdateReqContacts(reqId: string, filters: ContactFilter, tags: ContactTag[], assignedUser: User) {
    return this.apollo.mutate<BulkUpdateReqContacts>({
      mutation: gql(bulkUpdateReqContactsMutation),
      variables: {
        reqId,
        filters,
        tags,
        assignedUser: { id: assignedUser.id },
      },
    });
  }

  public getContactUsers(contactId: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getContactUsersQuery),
      variables: {
        contactId,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  public getContactImportDownloadURL(fileName: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getContactImportDownloadURLQuery),
      variables: {
        fileName,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  public getEnrichmentSuggestions(contactId: string, reqId: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(getEnrichmentSuggestionsQuery),
      variables: {
        contactId,
        reqId,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  public updateReqContacts(reqContacts: string, assignedUser: User, reqId: string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(updateReqContactsMutation),
      variables: {
        reqContacts,
        assignedUser,
        reqId,
      },
    });
  }

  generateVolumeReport(contactId: string, nmlsId: string): Observable<ContactBusiness[]> {
    return this.apollo
      .mutate<GenerateVolumeReport, { contactId: string; nmlsId: string }>({
        mutation: gql(generateVolumeReport),
        variables: { contactId, nmlsId },
        update: (store, { data: { generateVolumeReport } }: any) => {
          const newData = generateVolumeReport.map((a: any) => ({
            ...a,
            isUserInput: false,
            isUserImport: false,
          }));
          const query = gql(getContactBusinessQuery);
          const variables = { contactId };
          store.writeQuery({
            query,
            variables,
            data: { getContactBusiness: newData },
          });
        },
      })
      .pipe(map(({ data }) => data?.generateVolumeReport || []));
  }

  //MARKET INSIGHTS LEGACY CODE

  generateFullVolumeReport(
    contactId: string,
    nmlsId: string,
    updateContactRecord?: boolean
  ): Observable<FullVolumeReport | undefined> {
    return this.apollo
      .mutate<GenerateFullVolumeReport, { contactId: string; nmlsId: string; updateContactRecord: boolean }>({
        mutation: gql(generateFullVolumeReport),
        variables: {
          contactId,
          nmlsId,
          updateContactRecord: !!updateContactRecord,
        },
        update: (store, { data: { generateFullVolumeReport } }: any) => {
          const query = gql(getContactVolumeReportQuery);
          const variables = { contactId };
          store.writeQuery({
            query,
            variables,
            data: { getContactVolumeReport: generateFullVolumeReport },
          });
        },
      })
      .pipe(map(({ data }) => data?.generateFullVolumeReport));
  }

  // MARKET INSIGHTS LEGACY CODE
  validateNmlsId(nmlsId: string, since: string): Observable<any> {
    return this.apollo.query<any>({
      query: gql(validateNmlsId),
      variables: { nmlsId, since },
    });
  }

  // MAREKT INSIGHTS LEGACY CODE
  public getContactVolumeReport(contactId: string): QueryRef<GetContactVolumeReport, { contactId: string }> {
    return this.apollo.watchQuery<GetContactVolumeReport, { contactId: string }>({
      query: gql(getContactVolumeReportQuery),
      variables: { contactId },
    });
  }

  public updateContactLastUpdatedAtField(contactId: string, reqId: string, isPublic: string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(updateContactLastUpdatedAtField),
      variables: { contactId, reqId, isPublic },
    });
  }

  public formatPhoneNumber = (input: string): string | undefined => {
    // If already in the correct format
    if (/^\d{3}-\d{3}-\d{4}$/.test(input)) {
      return input;
    }

    // If in the (123) 456 7890 or (123) 456-7890 format
    if (/^\(\d{3}\) \d{3}[- ]\d{4}$/.test(input)) {
      return input.replace(/^\((\d{3})\) (\d{3})[- ](\d{4})$/, '$1-$2-$3');
    }

    // If in the 3528712469 format
    if (/^\d{10}$/.test(input)) {
      return input.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
    }

    // If in the +12345678901 format
    if (/^\+\d{11}$/.test(input)) {
      return input.replace(/^\+\d(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
    }

    return undefined;
  };
}
