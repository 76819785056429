export const themeblues = {
  color1: '#6497b3',
  color2: '#4383a5',
  color3: '#31688a',
  color4: '#b8e0ff',
  color5: '#52b4ff',
  color6: '#4985a5',
  color7: '#397293',
  color8: '#2f89c9',
  color9: '#85caff',
  color10: '#38a9ff',
  fontColor: '#000000',
};

export const themenews = {
  color1: '#eb1e00',
  color2: '#ffa726',
  color3: '#66bb6a',
  color4: '#26c6da',
  color5: '#ab47bc',
  color6: '#ef5350',
  color7: '#fa9632',
  color8: '#3cc37d',
  color9: '#009bff',
  color10: '#aa50e6',
  fontColor: '#000000',
};
