<h1 mat-dialog-title>Create a To-Do</h1>
<mat-dialog-content>
  <form [formGroup]="createActivityForm">
    <div class="titleAndDate static-container">
      <mat-form-field class="title-field" appearance="standard">
        <input
          matInput
          data-testid="new-activity-dialog-title"
          [value]="title || null"
          placeholder="Title"
          [formControl]="titleFormControl"
          required
        />
      </mat-form-field>
      <mat-form-field class="date-field" appearance="outline">
        <mat-label>Date</mat-label>
        <input
          matInput
          data-testid="new-activity-dialog-date"
          [matDatepicker]="taskDatePicker"
          placeholder="Due Date"
          [value]="dueDate || null"
          [formControl]="dueOnFormControl"
          required
        />
        <!-- Below is for calendar integration -->
        <!-- <input
          matInput
          data-testid="new-activity-dialog-date"
          [matDatepicker]="taskDatePicker"
          placeholder="{{ typeDrop.value === 'TASK' ? 'Due Date' : (typeDrop.value | activityTypeLabel) + ' Date' }}"
          [value]="dueDate || null"
          [formControl]="dueOnFormControl"
          (dateChange)="getCalendar(dueOnFormControl.value, typeDrop.value)"
          required
        /> -->
        <mat-datepicker-toggle matSuffix [for]="taskDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #taskDatePicker startView="year" [startAt]="startDate"></mat-datepicker>
      </mat-form-field>

      <!-- <mat-form-field *ngIf="typeDrop.value === 'OTHER'" class="title-field">
        <input matInput [value]="customType || null" placeholder="Custom Type" />
        <mat-hint>Type is not case sensitive</mat-hint>
      </mat-form-field> -->
    </div>
    <mat-form-field class="task-description" appearance="outline">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        data-testid="new-activity-dialog-description"
        [value]="description || null"
        [formControl]="descriptionFormControl"
      ></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" class="associatedContacts">
      <mat-label>Associated Contacts</mat-label>
      <mat-chip-list #contactChipList>
        <mat-chip
          *ngFor="let contact of contacts"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="removeContact(contact)"
        >
          {{ contact.firstName + ' ' + contact.lastName }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          type="text"
          placeholder="Associated Contacts"
          aria-label="String"
          matInput
          #contactInput
          [formControl]="contactsFormControl"
          [matAutocomplete]="autoContact"
          [matChipInputFor]="contactChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
        />
      </mat-chip-list>
      <mat-autocomplete
        autoActiveFirstOption
        #autoContact="matAutocomplete"
        (optionSelected)="selectedContact($event)"
        (opened)="autoCompleteScroll()"
      >
        <mat-option *ngFor="let contact of filteredContacts | async" [value]="contact">
          <img class="avatarImg" *ngIf="avatarUrls[contact.id]" alt="Contact Avatar" [src]="avatarUrls[contact.id]" />
          <img
            class="avatarImg"
            *ngIf="!avatarUrls[contact.id]"
            src="/assets/images/contact_placeholder.png"
            alt="Contact Avatar"
          />
          <span class="nameWrapper">
            {{ contact.firstName + ' ' + contact.lastName }}
          </span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> More options </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="otherOptions static-container">
        <div class="tenantAndReq">
          <mat-form-field *ngIf="authenticatedUserInfo?.groups.includes('mmUser')" appearance="outline">
            <mat-label>Tenant</mat-label>
            <input
              type="text"
              placeholder="Tenant"
              aria-label="String"
              matInput
              [formControl]="tenantFormControl"
              [matAutocomplete]="autoTenant"
              (blur)="verifyTenantSelected()"
              [(ngModel)]="userTenant"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #autoTenant="matAutocomplete"
              [displayWith]="displayTenant"
              (optionSelected)="tenantSelected($event)"
            >
              <mat-option *ngFor="let tenant of filteredTenants | async" [value]="tenant">{{
                tenant.title
              }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Pipeline</mat-label>
            <mat-select
              placeholder="Pipeline"
              [formControl]="reqFormControl"
              [(ngModel)]="reqId"
              (selectionChange)="selectReq($event)"
            >
              <mat-option *ngFor="let req of reqOptions" [value]="req.value">{{ req.title }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="assignedToDiv">
          <mat-form-field appearance="outline" class="assignedTo">
            <mat-label>Assigned To</mat-label>
            <mat-chip-list #assigneeChipList required>
              <mat-chip
                *ngFor="let assignee of assignees"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="removeAssignee(assignee)"
              >
                {{ assignee.firstName + ' ' + assignee.lastName }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                type="text"
                aria-label="String"
                matInput
                #assigneeInput
                [formControl]="assigneesFormControl"
                [matAutocomplete]="autoAssignee"
                [matChipInputFor]="assigneeChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                required
              />
            </mat-chip-list>
            <mat-autocomplete
              autoActiveFirstOption
              #autoAssignee="matAutocomplete"
              (optionSelected)="selectedAssignee($event)"
            >
              <mat-option *ngFor="let assignee of filteredAssignees | async" [value]="assignee">
                <img
                  class="avatarImg"
                  *ngIf="avatarUrls[assignee.id]"
                  alt="User Avatar"
                  [src]="avatarUrls[assignee.id]"
                />
                <img
                  class="avatarImg"
                  *ngIf="!avatarUrls[assignee.id]"
                  src="/assets/images/contact_placeholder.png"
                  alt="User Avatar"
                />
                <span class="nameWrapper">{{ assignee.firstName + ' ' + assignee.lastName }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- <div class="static-container">
      <mat-form-field class="date-field">
        <input
          matInput
          data-testid="new-activity-dialog-date"
          [matDatepicker]="taskDatePicker"
          placeholder="{{ typeDrop.value === 'TASK' ? 'Due Date' : (typeDrop.value | activityTypeLabel) + ' Date' }}"
          [value]="dueDate || null"
          [formControl]="dueOnFormControl"
          required
        />
        Below is for calendar integration
        <input
          matInput
          data-testid="new-activity-dialog-date"
          [matDatepicker]="taskDatePicker"
          placeholder="{{ typeDrop.value === 'TASK' ? 'Due Date' : (typeDrop.value | activityTypeLabel) + ' Date' }}"
          [value]="dueDate || null"
          [formControl]="dueOnFormControl"
          (dateChange)="getCalendar(dueOnFormControl.value, typeDrop.value)"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="taskDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #taskDatePicker startView="year" [startAt]="startDate"></mat-datepicker>
      </mat-form-field>
      <mat-checkbox *ngIf="typeDrop.value === 'MEETING'" color="primary" [formControl]="sendInviteFormControl"
        >Add To Calendar</mat-checkbox
      >
    </div> -->
    <!-- <div
      class="fullcalendarDiv"
      *ngIf="typeDrop.value === 'MEETING' && dueOnFormControl.value && (isOutlookAuthorized || isGoogleAuthorized)">
        <full-calendar #calendar id="fullCalendar" [options]="calendarOptions"></full-calendar>
    </div> -->

    <!-- <div *ngIf="typeDrop.value === 'CALL' || typeDrop.value === 'MEETING'" class="task-content">
      <mat-form-field class="date-field">
        <mat-select
          [placeholder]="(typeDrop.value | activityTypeLabel) + ' Time'"
          [formControl]="timeFormControl"
          required
        >
          <mat-option *ngFor="let time of timeOptions" [value]="time.value">
            {{ time.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="typeDrop.value === 'CALL' || typeDrop.value === 'MEETING'" class="location-container">
      <mat-form-field>
        <mat-select
          [placeholder]="(typeDrop.value | activityTypeLabel) + ' Duration'"
          [formControl]="durationFormControl"
          required
        >
          <mat-option *ngFor="let duration of durationOptions" [value]="duration.value">
            {{ duration.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <span matPrefix>+1 &nbsp;</span>
        <input
          type="tel"
          matInput
          [placeholder]="(typeDrop.value | activityTypeLabel) + ' Number'"
          [formControl]="phoneFormControl"
        />
      </mat-form-field>
    </div>

    <div *ngIf="typeDrop.value === 'MEETING'" class="location-container">
      <mat-form-field class="location-field">
        <input
          matInput
          [placeholder]="(typeDrop.value | activityTypeLabel) + ' Location'"
          [value]="requisition || null"
          [formControl]="locationFormControl"
        />
      </mat-form-field>
    </div> -->
    <!--<mat-checkbox *ngIf="data" class="completed-checkbox" color="primary" [checked]="completedDate" [disabled]="readonly">Complete</mat-checkbox>-->
  </form>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!readonly">
  <button mat-stroked-button color="warn" [mat-dialog-close]="cancelled">Cancel</button>
  <button mat-raised-button color="primary" data-testid="new-activity-dialog-submit" (click)="createTodo()">
    Add To-Do
  </button>
</mat-dialog-actions>
<mat-dialog-actions *ngIf="readonly">
  <button mat-button color="warn" (click)="readonly = !readonly">Edit</button>
  <button mat-button color="primary" [mat-dialog-close]="cancelled">Close</button>
</mat-dialog-actions>
