import {
  updateContactCustomFields,
  updateCustomFieldsOnTenantRecord,
} from './../contacts/custom-fields-manager/custom-fields.mutations';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  createCustomField,
  deleteCustomField,
  deleteCustomFieldOption,
  updateCustomField,
} from 'app/contacts/custom-fields-manager/custom-fields.mutations';
import { getCustomFields } from 'app/contacts/custom-fields-manager/custom-fields.queries';
import {
  CustomField,
  CustomFieldInput,
  CustomFieldOption,
  GetCustomFieldsQuery,
  TenantCustomFieldRecord,
  CustomFieldContact,
  CustomFieldOptionInput,
  CustomFieldContactInput,
} from 'graphql.types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

type getCustomFieldsParams = {
  reqId?: string;
  filterOutInactive?: boolean;
};

// should this go into the root or the core module?
@Injectable()
export class CustomFieldsService {
  // ReqId key : TenantCustomFieldRecord value
  private _tenantCustomFieldsRecords = new BehaviorSubject<{ [key: string]: TenantCustomFieldRecord }>({});

  constructor(private apollo: Apollo) {}

  private getCustomFieldsRecordFromCache(reqId?: string): TenantCustomFieldRecord | undefined {
    if (!reqId) {
      reqId = 'DEFAULT';
    }
    let tenantCustomFieldRecords = this._tenantCustomFieldsRecords.getValue();
    return structuredClone(tenantCustomFieldRecords[reqId]);
  }

  private setCustomFieldsRecordFromCache(reqId: string, tenantCustomFieldRecord: TenantCustomFieldRecord) {
    let tenantCustomFieldRecords = this._tenantCustomFieldsRecords.getValue();
    let newRecord = {
      ...tenantCustomFieldRecords,
      [reqId]: structuredClone(tenantCustomFieldRecord),
    };
    this._tenantCustomFieldsRecords.next(newRecord);
  }

  /**
   * Gets the custom fields that are on the tenant of your user.
   *
   * **Caching**: This is using a caching system under the hood. Any subsequent calls with the same reqId will be returned from memory. This makes this call safe for UI rendering.
   */
  public getCustomFields({
    reqId,
    filterOutInactive,
  }: getCustomFieldsParams = {}): Observable<TenantCustomFieldRecord> {
    // Possible bug: Filtering out inactive with this cache system will only ever return the first value that was queried from the database.
    let cachedTenantRecord = this.getCustomFieldsRecordFromCache(reqId);

    if (cachedTenantRecord) {
      return of(cachedTenantRecord);
    } else {
      let response = this.apollo.query<GetCustomFieldsQuery>({
        query: getCustomFields,
        variables: {
          reqId: reqId ?? '',
          filterOutInactive: filterOutInactive ?? true,
        },
        // Why not use apollo cache here? instead of custom cache?
        fetchPolicy: 'network-only',
      });
      // Dress up the response to make it look pretty
      let result = response.pipe(
        take(1),
        map((res) => structuredClone(res.data.getCustomFields))
      );

      if (!reqId) {
        reqId = 'DEFAULT';
      }

      // Set value in cache
      // do we subscribe every time we set a value in the cache?
      // and never unsub?
      result.subscribe((tenantCustomFieldRecord) => {
        this.setCustomFieldsRecordFromCache(reqId, tenantCustomFieldRecord);
      });

      return result;
    }
  }

  createCustomField(customField: CustomFieldInput): Observable<any> {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    // @ts-ignore
    delete customField.__typename;

    return this.apollo.mutate({
      mutation: createCustomField,
      variables: {
        customField: customField,
      },
    });
  }

  updateContactCustomFields(
    reqId: string,
    contactId: string,
    contactCustomFields: CustomFieldContactInput[]
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: updateContactCustomFields,
      variables: {
        reqId,
        contactId,
        contactCustomFields,
      },
    });
  }

  updateCustomField(customField: CustomFieldInput): Observable<any> {
    return this.apollo.mutate({
      mutation: updateCustomField,
      variables: {
        customField,
      },
    });
  }

  updateCustomFieldsOnTenantRecord(customFields: CustomFieldInput[]): Observable<any> {
    return this.apollo.mutate({
      mutation: updateCustomFieldsOnTenantRecord,
      variables: {
        customFields,
      },
    });
  }

  deleteCustomField(customField: any, restore: boolean): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteCustomField,
      variables: {
        customField: customField,
        restore,
      },
    });
  }

  deleteCustomFieldOption(customField: any, option: CustomFieldOptionInput, restore: boolean): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteCustomFieldOption,
      variables: {
        customField: customField,
        option,
        restore,
      },
    });
  }

  public deleteTypenameOnCustomField(customField: CustomField) {
    // @ts-ignore
    delete customField.__typename;

    if (customField.options) {
      customField.options = customField.options.map((option) => {
        //@ts-ignore
        delete option.__typename;
        return option;
      });
    }

    return customField;
  }

  public getCustomFieldFilterChoices(selectedType: CustomField['type']) {
    switch (selectedType) {
      case 'TEXT': {
        return ['is', 'is not', 'contains'];
      }
      case 'LIST': {
        return ['is', 'is not'];
      }
    }
  }

  public getCustomFieldValueName(customField: CustomField): string {
    if (!customField.value) {
      return 'None';
    }

    switch (customField.type) {
      case 'TEXT': {
        return customField.value;
      }
      case 'LIST': {
        let foundOption = customField.options.find((o) => o.id === customField.value);
        if (foundOption) {
          return foundOption.name;
        } else {
          return '';
          // return customField.value
        }
      }
    }
  }

  public getOptionNameFromOptionId(optionId: string, customField: CustomField): string {
    let foundName = customField.value || '';
    customField?.options?.forEach((option) => {
      if (optionId === option.id) {
        foundName = option.name;
      }
    });
    return foundName;
  }

  public filterOutSelectedOption(optionId: string, customField: CustomField): CustomFieldOption[] {
    return customField.options.filter((o) => o.id !== optionId);
  }

  /** method to append corresponding name and value strings to contacts with uuid values on customFields, primarily for displaying on contact tables
   *  @param reqId used to fetch TenantCustomFieldRecord for looping contacts array
   *  @param contacts array of contacts with customField uuid values
   *  @returns contacts array with customField string names and values
   */
  public async appendCustomFieldValues<T>(reqId: string, contacts: T[]): Promise<T[]> {
    let clonedContacts = JSON.parse(JSON.stringify(contacts));

    let tenantCustomFieldRecord: TenantCustomFieldRecord = await this.getCustomFields({
      ...(reqId && { reqId }),
    }).toPromise();

    let tenantCustomFields: CustomField[] = tenantCustomFieldRecord.customFields;

    clonedContacts.forEach((contact) => {
      contact.customFields.forEach((contactCustomField: CustomFieldContact) => {
        let tenantCustomField = tenantCustomFields.find(
          (tenantCustomField) => tenantCustomField.id === contactCustomField.fieldId
        );
        if (tenantCustomField) {
          contactCustomField.name = tenantCustomField.name;
          contactCustomField.value = this.getCustomFieldValueName({
            ...tenantCustomField,
            ...contactCustomField,
          } as CustomField);
        }
      });
    });

    return clonedContacts;
  }

  public getCustomFieldColumns(showInDropdown: boolean, reqId?: string) {
    return this.getCustomFields({
      ...(reqId && { reqId }),
    }).pipe(
      take(1),
      map(({ customFields, tenantId }: TenantCustomFieldRecord) => {
        return customFields.map((field) => ({
          showInDropdown,
          colName: field.name,
          filterKey: `customFields.${field.id}.keyword`,
          isDisplayed: !showInDropdown,
          order: 0,
          // Custom sort key sequence to be able to parse and sort on the backend.
          sortKey: `customFields|${tenantId}|${field.type}|${field.id}`,
          value: field.name,
          type: field.type,
          options: field.options,
        }));
      })
    );
  }
}
