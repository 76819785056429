import { OnChanges, SimpleChanges } from '@angular/core';
import { animate } from '@angular/animations';
import { Directive, ElementRef, Input, NgModule, OnDestroy, OnInit, Renderer2 } from '@angular/core';
//@ts-ignore
import Popper, { Placement, PopperOptions } from 'popper.js';
import { fromEvent, merge, Subject } from 'rxjs';
import { filter, pluck, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appPopper]',
})
export class PopperDirective implements OnInit, OnDestroy, OnChanges {
  private showTimeout: any;
  @Input() show: boolean = false;
  @Input() showProgrammatically: boolean = false;
  // The hint to display
  @Input() target: HTMLElement;
  @Input() arrow: HTMLElement;
  // Its positioning (check docs for available options)
  @Input() placement?: Placement;
  @Input() offset?: number;
  // Optional hint target if you desire using other element than specified one
  @Input() appPopper?: HTMLElement;
  // The popper instance
  private popper: Popper;
  private readonly defaultConfig: PopperOptions = {
    placement: 'top',
    removeOnDestroy: true,
    modifiers: {
      arrow: {
        element: '.popper__arrow',
      },
    },
    eventsEnabled: false,
  };
  private popperTransform: string;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    // An element to position the hint relative to
    const reference = this.appPopper ? this.appPopper : this.el.nativeElement;
    this.arrow && this.renderer.setStyle(this.arrow, 'position', 'absolute');
    this.arrow && this.renderer.setStyle(this.arrow, 'transform', 'rotate(45deg)');
    this.popper = new Popper(reference, this.target, {
      ...this.defaultConfig,
      // onUpdate: function (data) {
      //   data.styles.transitionDuration = '0s';
      //   data.styles.transform = `translate3d(${parseInt(data.styles.left)-10}px, ${parseInt(data.styles.top)}px, 0px)`;
      //   data.styles.opacity = '0';

      //   setTimeout(function () {
      //     data.instance.scheduleUpdate();
      //     data.styles.transitionDuration = '0.325s';
      //     data.styles.opacity = '1';
      //     data.styles.backgroundColor = 'red';
      //     data.styles.transform = `translate3d(${parseInt(data.styles.left)}px, ${parseInt(data.styles.top)}px, 0px)`;
      //   }, 1);
      // },
      placement: this.placement || this.defaultConfig.placement,
      modifiers: {
        ...this.defaultConfig.modifiers,
        offset: {
          offset: `0, ${this.offset || '15'}`,
        },
      },
    });
    this.renderer.setStyle(this.target, 'z-index', 1001);
    this.renderer.setStyle(this.target, 'display', 'none');

    merge(
      fromEvent(reference, 'mouseenter'),
      fromEvent(reference, 'mouseleave'),
      fromEvent(this.target, 'mouseenter'),
      fromEvent(this.target, 'mouseleave')
    )
      .pipe(
        filter(() => this.popper != null),
        pluck('type'),
        takeUntil(this.destroy$)
      )
      .subscribe((e: any) => this.mouseHoverHandler(e));
  }

  private arrowDirection() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.show?.isFirstChange()) return;
    if (changes.show?.currentValue) {
      this.showPopper();
    } else {
      this.hidePopper();
    }
  }

  showPopper() {
    // fade up and in the target
    this.renderer.setStyle(this.target, 'opacity', 0);
    this.renderer.setStyle(this.target, 'transition', 'opacity 0.325s ease-in-out');
    this.renderer.removeStyle(this.target, 'display');
    clearTimeout(this.showTimeout);
    this.showTimeout = setTimeout(() => {
      this.renderer.setStyle(this.target, 'opacity', 1);
      this.popper.enableEventListeners();
      this.popper.scheduleUpdate();
      this.show = true;
    }, 1);
  }

  hidePopper() {
    this.renderer.setStyle(this.target, 'opacity', 0);
    clearTimeout(this.showTimeout);
    this.showTimeout = setTimeout(() => {
      this.renderer.setStyle(this.target, 'display', 'none');
      this.popper?.disableEventListeners();
      this.show = false;
    }, 325);
  }

  ngOnDestroy(): void {
    if (!this.popper) {
      return;
    }

    this.popper.destroy();

    this.destroy$.next();
    this.destroy$.complete();
  }

  private mouseHoverHandler(e: string): void {
    // get the current popper position
    const { top, left } = this.popper.popper.getBoundingClientRect();
    // get the current reference position
    const { top: refTop, left: refLeft } = this.popper.reference.getBoundingClientRect();
    // get the current arrow position
    // const { top: arrowTop, left: arrowLeft } =  this.arrow.getBoundingClientRect();
    if (e === 'mouseenter' && !this.showProgrammatically) {
      this.showPopper();
    } else {
      !this.showProgrammatically && this.hidePopper();
    }
  }
}

@NgModule({
  declarations: [PopperDirective],
  exports: [PopperDirective],
})
export class PopperModule {}
