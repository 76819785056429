import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

type PaneType = 'left' | 'right';

/*
Based on https://medium.com/@asm/animated-slide-panel-with-angular-e985ad646f9
modded to work within Reporting objects as a slide content in from right only

OnPush Change Detection Strategy
We can set the ChangeDetectionStrategy of our component to ChangeDetectionStrategy.OnPush .

This tells Angular that the component only depends on its @inputs() ( aka pure ) and needs to be checked only in the following cases:
https://netbasal.com/a-comprehensive-guide-to-angular-onpush-change-detection-strategy-5bac493074a4


By setting 'left' state to height 0 - nothing takes up space until right panel slides in
*/

@Component({
  selector: 'report-slide-panel',
  templateUrl: './slide-panel.component.html',
  styleUrls: ['./slide-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      state('left', style({ height: 0, transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(-50%)' })),
      transition('* => *', animate(300)),
    ]),
  ],
})
export class ReportSlidePanelComponent implements OnInit {
  @Input() activePane: PaneType = 'left';

  // Left panel is the default...
  panelToggle = true;

  constructor() {}

  togglePane() {
    this.panelToggle = !this.panelToggle;
    this.activePane = this.panelToggle ? 'left' : 'right';

    // cccc
  }
  ngOnInit() {}
}
