import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { ReportTemplate } from '../../../shared/model/reporting';
import { User } from 'app/shared/model/user';
import { IntegrationsService } from '../../../services/integrations.service';
import { ReportingService } from '../../../services/reporting.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'mm-report-share-picker-dialog',
  templateUrl: './report-share-picker-dialog.component.html',
})
export class ReportSharePickerDialogComponent implements OnInit {
  tenantUsers: User[] = [];
  currentUser: any = {};
  selectedUser: any = {};
  shareToUserFormGroup: FormGroup;
  reportTemplate: ReportTemplate;
  reportTemplateId: string;
  reportTemplateIDForRecip: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReportSharePickerDialogComponent>,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private integrationsService: IntegrationsService,
    private reportingService: ReportingService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.reportTemplate = this.data.reportTemplate;
    this.reportTemplateId = this.data.reportTemplate.id;
    this.tenantUsers = this.data.tenantUsers;
    this.currentUser = this.data.currentUser;

    this.shareToUserFormGroup = this.formBuilder.group({
      userId: ['', Validators.required],
    });
  }

  onShare($event): void {
    if (!this.shareToUserFormGroup.valid) {
      console.log('ShareContactToUserComponent :: Form not valid. Aborting Submit.');
      return;
    }

    this.selectedUser.id = this.shareToUserFormGroup.value.userId;

    this.userService
      .getUserInfo(this.selectedUser.id)
      .pipe(take(1))
      .subscribe((result) => {
        delete result.data.getUserInfo.emails.__typename;

        result.data.getUserInfo.emails.forEach((email) => {
          delete email.__typename;
        });

        this.selectedUser.firstName = result.data.getUserInfo.firstName;
        this.selectedUser.lastName = result.data.getUserInfo.lastName;
        this.selectedUser.emails = result.data.getUserInfo.emails;

        console.log('from getSelectedUserInfo: this.selectedUser', this.selectedUser);

        this.shareReportTemplate();
      });
  }

  shareReportTemplate() {
    this.reportingService
      .shareReportTemplateToUser(this.reportTemplateId, this.selectedUser.id)
      .pipe(take(1))
      .subscribe(
        (result) => {
          let reportTemplate = JSON.parse(result.data.shareReportTemplateToUser);
          console.log('ShareReportComponent :: shared report to user result ', reportTemplate);

          let parsedString = JSON.parse(reportTemplate);
          this.reportTemplateIDForRecip = parsedString.id;

          this.snackBar.open('Report Shared to User Successfully ...', '', {
            duration: 2000,
          });

          this.dialogRef.close(reportTemplate[0]);

          this.sendEmailToUser();
        },
        (error) => {
          console.log('ShareReportComponent :: error sharing report to user.', error);
          this.snackBar.open('Error Sharing Report to User.', '', {
            duration: 3000,
          });
        }
      );
  }

  sendEmailToUser() {
    const url = `${window.location.origin}/#reporting/analytics/${this.reportTemplateIDForRecip}`;
    const selectedUserEmail = this.selectedUser.emails[0].address;

    const emailObj = {
      to: selectedUserEmail,
      subject: 'A report has been shared with you!',
      html: `
                <p>${this.currentUser.firstName} ${this.currentUser.lastName} has shared a new report with you!</p>
                <p>${this.reportTemplate.title}</p>
                <p>${url}</p>
              `,
    };

    console.log('from sendReportEmailNotification: this.emailObj', emailObj);

    this.integrationsService
      .sendEmail(emailObj)
      .pipe(take(1))
      .subscribe(
        (result) => {},
        (error) => {
          console.log('sendReportEmailNofication : Error sending notification email ', error);
        }
      );
  }
}
