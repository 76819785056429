<div class="dark-overlay spinner_container" *ngIf="isProgress">
  <mat-spinner class="spinner" mode="indeterminate"> </mat-spinner>
</div>
<mat-dialog-content>
  <mat-form-field class="search-input">
    <mat-icon matSuffix color="muted">search</mat-icon>
    <input matInput placeholder="Search" (keyup)="search($event.target.value)" [(ngModel)]="searchKey" />
  </mat-form-field>
  <div *ngIf="searchResult">
    <div *ngIf="searchResult.contactsByName && searchResult.contactsByName.length > 0">
      <h3>Contacts By Name</h3>
      <mat-divider></mat-divider>
      <mat-nav-list>
        <mat-list-item *ngFor="let result of searchResult.contactsByName" (click)="openContact(result)"
          >{{ result.firstName }} {{ result.lastName }}</mat-list-item
        >
        <mat-divider></mat-divider>
        <a mat-list-item (click)="viewMore('contact', 'name')">View more</a>
      </mat-nav-list>
    </div>
    <div *ngIf="searchResult.contactsByPhone && searchResult.contactsByPhone.length > 0">
      <h3>Contacts By Phone Number</h3>
      <mat-divider></mat-divider>
      <mat-nav-list>
        <mat-list-item *ngFor="let result of searchResult.contactsByPhone" (click)="openContact(result)"
          >{{ result.firstName }} {{ result.lastName }}
          <p [innerHTML]="showPhones(result)"></p
        ></mat-list-item>
        <mat-divider></mat-divider>
        <a mat-list-item (click)="viewMore('contact', 'phone')">View more</a>
      </mat-nav-list>
    </div>
    <div *ngIf="searchResult.contactsByEmail && searchResult.contactsByEmail.length > 0">
      <h3>Contacts By Email Address</h3>
      <mat-divider></mat-divider>
      <mat-nav-list>
        <mat-list-item *ngFor="let result of searchResult.contactsByEmail" (click)="openContact(result)"
          >{{ result.firstName }} {{ result.lastName }}
          <p [innerHTML]="showEmails(result)"></p
        ></mat-list-item>
        <mat-divider></mat-divider>
        <a mat-list-item (click)="viewMore('contact', 'email')">View more</a>
      </mat-nav-list>
    </div>
    <div *ngIf="searchResult.contactsByNmls && searchResult.contactsByNmls.length > 0">
      <h3>Contacts By NMLS ID</h3>
      <mat-divider></mat-divider>
      <mat-nav-list>
        <mat-list-item *ngFor="let result of searchResult.contactsByNmls" (click)="openContact(result)"
          >{{ result.firstName }} {{ result.lastName }}
          <p [innerHTML]="showNmls(result)"></p
        ></mat-list-item>
        <mat-divider></mat-divider>
        <a mat-list-item (click)="viewMore('contact', 'nmlsId')">View more</a>
      </mat-nav-list>
    </div>
    <div *ngIf="searchResult.requisitions && searchResult.requisitions.length > 0">
      <h3>Pipeline</h3>
      <mat-divider></mat-divider>
      <mat-nav-list>
        <mat-list-item
          *ngFor="let result of searchResult.requisitions"
          (click)="viewMore('requisition', result.title)"
          >{{ result.title }}</mat-list-item
        >
        <mat-divider></mat-divider>
        <a mat-list-item (click)="viewMore('requisition')">View more</a>
      </mat-nav-list>
    </div>
    <div *ngIf="searchResult.companies && searchResult.companies.length > 0">
      <h3>Companies</h3>
      <mat-divider></mat-divider>
      <mat-nav-list>
        <mat-list-item *ngFor="let result of searchResult.companies" (click)="viewMore('company', result.company)">{{
          result.company
        }}</mat-list-item>
        <mat-divider></mat-divider>
        <a mat-list-item (click)="viewMore('company')">View more</a>
      </mat-nav-list>
    </div>
    <div *ngIf="searchResult.contactTags && searchResult.contactTags.length > 0">
      <h3>Contact Tags</h3>
      <mat-divider></mat-divider>
      <mat-nav-list>
        <mat-list-item *ngFor="let result of searchResult.contactTags" (click)="openContact(result)">{{
          getContactTagName(result)
        }}</mat-list-item>
        <mat-divider></mat-divider>
        <a mat-list-item (click)="viewMore('contactTag')">View more</a>
      </mat-nav-list>
    </div>
    <div *ngIf="searchResult.reqTags && searchResult.reqTags.length > 0">
      <h3>Requistion Tags</h3>
      <mat-divider></mat-divider>
      <mat-nav-list>
        <mat-list-item *ngFor="let result of searchResult.reqTags" (click)="openReqContact(result)">{{
          getReqTagName(result)
        }}</mat-list-item>
        <mat-divider></mat-divider>
        <a mat-list-item (click)="viewMore('reqTag')">View more</a>
      </mat-nav-list>
    </div>
  </div>
</mat-dialog-content>
