<mat-card id="create-todo-card" fxFlexAlign="stretch" class="mat-elevation-z8">
  <mat-card-content>
    <form [formGroup]="createActivityForm">
      <div class="title-container static-container">
        <mat-form-field class="title-field" appearance="standard">
          <input
            matInput
            data-testid="new-activity-dialog-title"
            class="title-field-input"
            [value]="title || null"
            placeholder="Title"
            [formControl]="titleFormControl"
            required
          />
        </mat-form-field>
      </div>
      <div class="date-container">
        <mat-form-field class="date-field" appearance="outline">
          <mat-label *ngIf="!viewOnly">Date</mat-label>
          <mat-label *ngIf="viewOnly">Completed on</mat-label>
          <input
            style="width: 6em"
            matInput
            data-testid="new-activity-dialog-date"
            [matDatepicker]="taskDatePicker"
            placeholder="Due Date"
            [value]="dueDate || null"
            [formControl]="dueOnFormControl"
            required
          />
          <mat-datepicker-toggle matSuffix [for]="taskDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #taskDatePicker startView="year" [startAt]="startDate"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="recurring-form">
          <mat-select [formControl]="recurringFrequencyFormControl" [disabled]="todo">
            <mat-option *ngFor="let option of recurringOptions" [value]="option">{{ option }}</mat-option>
          </mat-select>
        </mat-form-field>
        <p
          *ngIf="recurringFrequencyFormControl.value === 'Monthly' && dueOnFormControl.value"
          style="margin: 1.5em 1em 1em 0.5em"
        >
          on day {{ createActivityForm.get('dueOn').value.getDate() }} of the month
        </p>
        <div>
          <div *ngIf="recurringFrequencyFormControl.value === 'Custom'" class="custom-recurring-container">
            <p style="margin: 1.5em 1em 1em 0.5em">every</p>
            <mat-form-field appearance="outline" class="recurring-form custom-recurring-num">
              <input type="number" matInput min="1" max="31" [formControl]="customRecurringNumFormControl" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="recurring-form">
              <mat-select [formControl]="customRecurringFrequencyFormControl">
                <mat-option *ngFor="let freq of customRecurringFrequencyOptions" [value]="freq">{{ freq }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <p
            *ngIf="
              recurringFrequencyFormControl.value === 'Custom' && customRecurringFrequencyFormControl.value === 'Months'
            "
            style="margin: -1.5em 0 0 7em"
          >
            on day {{ createActivityForm.get('dueOn').value.getDate() }} of the month
          </p>
        </div>
      </div>
      <mat-form-field class="task-description" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea
          matInput
          oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
          data-testid="new-activity-dialog-description"
          [value]="description || null"
          [formControl]="descriptionFormControl"
        >
        </textarea>
      </mat-form-field>

      <mat-form-field appearance="outline" class="associated-contacts" chipListAutocompleteFix>
        <mat-icon matPrefix>perm_identity</mat-icon>
        <mat-label>Associated Contacts</mat-label>
        <mat-chip-list #contactChipList>
          <mat-chip
            *ngFor="let contact of contacts"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="removeContact(contact)"
          >
            {{ contact.firstName + ' ' + contact.lastName }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            type="text"
            placeholder="Associated Contacts"
            aria-label="String"
            matInput
            #contactInput
            [formControl]="contactsFormControl"
            [matAutocomplete]="autoContact"
            [matChipInputFor]="contactChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
          />
        </mat-chip-list>
        <mat-autocomplete
          autoActiveFirstOption
          #autoContact="matAutocomplete"
          (optionSelected)="selectedContact($event)"
          (opened)="autoCompleteScroll()"
        >
          <mat-option *ngFor="let contact of filteredContacts | async" [value]="contact">
            <img class="avatarImg" *ngIf="avatarUrls[contact.id]" alt="Contact Avatar" [src]="avatarUrls[contact.id]" />
            <img
              class="avatarImg"
              *ngIf="!avatarUrls[contact.id]"
              src="/assets/images/contact_placeholder.png"
              alt="Contact Avatar"
            />
            <span class="nameWrapper">
              {{ contact.firstName + ' ' + contact.lastName }}
            </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-expansion-panel [disabled]="false">
        <mat-expansion-panel-header>
          <mat-panel-title> More options </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="more-options static-container">
          <div class="tenant-and-req" *ngIf="!viewOnly">
            <mat-form-field appearance="outline" class="tenantForm">
              <mat-icon matPrefix>location_city</mat-icon>
              <mat-label>Tenant</mat-label>
              <input
                type="text"
                placeholder="Tenant"
                aria-label="String"
                matInput
                [formControl]="tenantFormControl"
                [matAutocomplete]="autoTenant"
                (blur)="verifyTenantSelected()"
                [(ngModel)]="userTenant"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #autoTenant="matAutocomplete"
                [displayWith]="displayTenant"
                (optionSelected)="tenantSelected($event)"
              >
                <mat-option *ngFor="let tenant of filteredTenants | async" [value]="tenant">{{
                  tenant.title
                }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="outline" class="requisition-form">
              <mat-icon matPrefix>business</mat-icon>
              <mat-label>Pipeline</mat-label>
              <mat-select
                placeholder="Pipeline"
                [formControl]="reqFormControl"
                [disabled]="readonlyReq || todo"
                [(ngModel)]="reqId"
                (selectionChange)="selectReq($event)"
              >
                <mat-option *ngFor="let req of reqOptions" [value]="req.value">{{ req.title }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="assignedto-div">
            <mat-form-field appearance="outline" class="assignedto-form" chipListAutocompleteFix>
              <mat-icon matPrefix>supervised_user_circle</mat-icon>
              <mat-label>Assigned To</mat-label>
              <mat-chip-list #assigneeChipList required>
                <mat-chip
                  *ngFor="let assignee of assignees"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="removeAssignee(assignee)"
                >
                  {{ assignee.firstName + ' ' + assignee.lastName }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input
                  type="text"
                  aria-label="String"
                  matInput
                  #assigneeInput
                  [formControl]="assigneesFormControl"
                  [matAutocomplete]="autoAssignee"
                  [matChipInputFor]="assigneeChipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  required
                />
              </mat-chip-list>
              <mat-autocomplete
                autoActiveFirstOption
                #autoAssignee="matAutocomplete"
                (optionSelected)="selectedAssignee($event)"
              >
                <mat-option *ngFor="let assignee of filteredAssignees | async" [value]="assignee">
                  <img
                    class="avatarImg"
                    *ngIf="avatarUrls[assignee.id]"
                    alt="User Avatar"
                    [src]="avatarUrls[assignee.id]"
                  />
                  <img
                    class="avatarImg"
                    *ngIf="!avatarUrls[assignee.id]"
                    src="/assets/images/contact_placeholder.png"
                    alt="User Avatar"
                  />
                  <span class="nameWrapper">{{ assignee.firstName + ' ' + assignee.lastName }}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </form>
    <div>
      <button mat-stroked-button color="warn" (click)="closePopover()">Cancel</button>
      <button
        mat-icon-button
        id="editTodoButton"
        style="margin-left: 17em; visibility: hidden"
        (click)="enableEditTodo()"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        *ngIf="!todo"
        mat-raised-button
        color="primary"
        data-testid="new-activity-dialog-submit"
        style="float: right"
        (click)="prepareCreateTodo()"
      >
        Add To-Do
      </button>
      <button
        *ngIf="todo && createActivityForm.enabled && !viewOnly"
        mat-raised-button
        color="primary"
        data-testid="new-activity-dialog-submit"
        style="float: right"
        (click)="updateTodo()"
      >
        Save To-Do
      </button>
      <button
        *ngIf="canDelete && todo && createActivityForm.enabled && !viewOnly"
        mat-raised-button
        color="warning"
        data-testid="new-activity-dialog-submit"
        style="float: right; margin-right: 1em"
        (click)="openConfirmDeleteDialog()"
      >
        Delete To-Do
      </button>
      <button
        *ngIf="todo && createActivityForm.disabled && !viewOnly"
        mat-raised-button
        color="primary"
        style="float: right"
        (click)="openCompleteOrContinueDialog()"
      >
        Complete
      </button>
    </div>
  </mat-card-content>
</mat-card>
