import { gql } from 'apollo-angular';

export const getMyReqsQuery = `
            query getMyReqs($reqFilter: ReqFilter) {
                getMyReqs(reqFilter: $reqFilter) {
                    id
                    created
                    title
                    ownerId
                    ownerFirst
                    ownerLast
                    targetHires
                    status
                    tenant
                    reqId
                    icon
                    color
                    tags
                    statistics {
                        prospects
                        candidates
                        meetings
                        offers
                        hires
                    }
                }
            }`;

export const getMyReqIdsQuery = `
            query getMyReqs($reqFilter: ReqFilter) {
                getMyReqs(reqFilter: $reqFilter) {
                    id                   
                }
            }`;

export const getMyReqsAdminFiltered = `
            query getMyReqsAdminFiltered($tenantId: String, $reqFilter: ReqFilter) {
                getMyReqsAdminFiltered(tenantId: $tenantId, reqFilter: $reqFilter) {
                    id
                    created
                    title
                    ownerId
                    ownerFirst
                    ownerLast
                    targetHires
                    status
                    tenant
                    reqId
                    tags
                    statistics {
                        prospects
                        candidates
                        meetings
                        offers
                        hires
                    }
                }
            }`;

export const filterMyReqsQuery = gql`
  query filterMyReqs($page: Page, $filters: ReqFilter, $sortOption: SortOption) {
    filterMyReqs(page: $page, filters: $filters, sortOption: $sortOption) {
      page {
        page
        pageSize
        totalSize
      }
      userReqs {
        id
        icon
        color
        created
        title
        ownerId
        ownerFirst
        ownerLast
        status
        tenant
        reqId
        tags
        statistics {
          prospects
          candidates
          meetings
          offers
          hires
        }
      }
    }
  }
`;

export const getProcessTotalsForAllReqs = `
  query getProcessTotalsForAllReqs($filters: ReqFilter) {
    getProcessTotalsForAllReqs(filters: $filters) {
        prospects
        candidates
        meetings
        offers
        hires
    }
  }
`;

export const getReqTags = `
  query getReqTags($reqId: String!) {
    getReqTags(reqId: $reqId) 
  }
`;

export const getMyReqsDropdownQuery = `
            query getMyReqs {
                getMyReqs {
                    id
                    reqId
                    title
                    tenant
                    status
                    tags
                }
            }`;

export const getReqQuery = `
            query getReq($reqId: String!) {
                getReq(reqId: $reqId) {
                    id
                    ownerId
                    ownerFirst
                    ownerLast
                    created
                    title
                    tenantId
                    statistics {
                      prospects
                      candidates
                      meetings
                      offers
                      hires
                    }
                    status
                    summary
                    openedDate
                    todos {
                        id
                        type
                        title
                        dueOn
                        reqId
                        description
                        location
                        phone
                        duration
                        users {
                            id
                            firstName
                            lastName
                        }
                        contacts {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            }`;

export const getReqContactsQuery = `
            query getReqContacts($reqId: String!) {
                getReqContacts(reqId: $reqId) {
                    id
                    firstName
                    lastName
                    stage
                    stageEnteredDate
                    createdAt
                    source
                    company
                    contacted
                    lastUpdatedAt
                    contactId
                    assignedUser {
                        id
                        firstName
                        lastName
                    }
                }
            }`;

export const getMyReqContactsQuery = `
            query getMyReqContacts($reqId: String!, $contactIds: [String]) {
                getMyReqContacts(reqId: $reqId, contactIds: $contactIds) {
                    id
                    reqId
                    reqName
                    firstName
                    lastName
                    stage
                    stageEnteredDate
                    createdAt
                    source
                    company
                    contacted
                    lastUpdatedAt
                    contactId
                    nmlsId
                    trailing12Units
                    trailing12Volume
                    address {
                            street
                            street2
                            city
                            state
                            zipCode
                        }
                    assignedUser {
                        id
                        firstName
                        lastName
                    },
                    emails {
                        type
                        address
                    }
                    phones {
                        type
                        number
                    }
                    links {
                        type
                        url
                    }
                    customFields {
                        fieldId
                        value
                      }
                    product_mix_conventional
                    product_mix_fha
                    product_mix_government
                    product_mix_jumbo
                    product_mix_usda
                    product_mix_va
                    purpose_mix_purchase
                    purpose_mix_refinance  
                }
            }`;

export const changeReqContactTouchedStatusQuery = `
        query changeReqContactTouchedStatus($reqId: String!, $contactId: String!){
            changeReqContactTouchedStatus(reqId: $reqId, contactId: $contactId) {
                firstName
                lastName
                contacted
                contactId
            }
        }`;

export const filterReqContactsQuery = `
            query filterReqContacts($reqIds: [String]!, $page: Page, $filters: ReqContactFilter, $sortOption: SortOption) {
                filterReqContacts(reqIds: $reqIds, page: $page, filters: $filters, sortOption: $sortOption) {
                    page {
                        page
                        pageSize
                        totalSize
                    }
                    contacts {
                        id
                        firstName
                        lastName
                        stage
                        stageEnteredDate
                        title
                        createdAt
                        source
                        sourceId
                        company
                        contacted
                        lastUpdatedAt
                        reqId
                        reqName
                        contactId
                        nmlsId
                        address {
                            city
                            state
                        }
                        phones {
                            type
                            number
                        }
                        links {
                            type
                            url
                        }
                        statistics {
                            volume
                            period
                            endYear
                        }
                        tags
                        {
                            tagId
                            name
                        }
                        assignedUser
                        {
                            id
                            firstName
                            lastName
                        }
                    }


                }
            }`;

export const filterMyContactsQuery = `
            query filterMyContacts($page: Page, $filters: ContactFilter, $sortOption: SortOption, $fuzziness: [String]) {
                filterMyContacts(page: $page, filters: $filters, sortOption: $sortOption, fuzziness: $fuzziness) {
                    page {
                        page
                        pageSize
                        totalSize
                    }
                    contacts {
                        id
                        firstName
                        lastName
                        lastUpdatedAt
                        createdAt
                        address {
                            street
                            street2
                            city
                            state
                            zipCode
                        }
                        emails {
                            type
                            address
                        }
                        phones {
                            type
                            number
                        }
                        links {
                            type
                            url
                        }
                        title
                        company
                        nmlsId
                        contactId
                        statistics {
                            volume
                            period
                            endYear
                        }
                        tags
                        {
                            tagId
                            name
                        }
                    }
                }
            }`;

export const filterUserContactsQuery = `
            query filterUserContacts($page: Page, $filters: [QueryStringContactFilter], $sortOption: [NewSortOption], $index: String, $reqStatus: String, $showDeletedContactsOnly: Boolean) {
                filterUserContacts(page: $page, filters: $filters, sortOption: $sortOption, index: $index, reqStatus: $reqStatus, showDeletedContactsOnly: $showDeletedContactsOnly) {
                    page {
                        page
                        pageSize
                        totalSize
                    }
                    contacts {
                        id
                        active
                        lastAction {
                            id
                            completedDate
                            reqId
                            title
                            description
                            users {
                                id
                                firstName
                                lastName
                            }
                            contacts {
                                id
                                firstName
                                lastName
                            }
                        }
                        nextAction {
                            id
                            type
                            title
                            dueOn
                            reqId
                            description
                            location
                            phone
                            duration
                            users {
                                id
                                firstName
                                lastName
                            }
                            contacts {
                                id
                                firstName
                                lastName
                            }
                        }
                        firstName
                        lastName
                        lastUpdatedAt
                        createdAt
                        stage
                        stageEnteredDate
                        contacted
                        reqName
                        reqId
                        source
                        sourceId
                        assignedUser
                        {
                            id
                            firstName
                            lastName
                        }
                        address {
                            street
                            street2
                            city
                            state
                            zipCode
                        }
                        emails {
                            type
                            address
                        }
                        phones {
                            type
                            number
                        }
                        links {
                            type
                            url
                        }
                        title
                        company
                        nmlsId
                        agentId
                        opportunityValue
                        opportunityValueHard
                        contactId
                        tenantId
                        production {
                            volume
                            period
                            endYear
                            endMonth
                            purchasePercentage
                            transactionMix {
                                per_purchase
                            }
                        }
                        tags
                        {
                            tagId
                            name
                        }
                        customFields {
                            fieldId
                            value
                        }
                    }
                }
            }`;

export const getMyContactsSelectQuery = `
            query getMyContacts {
                getMyContacts {
                        id
                        firstName
                        lastName
                    }
                }`;

export const filterMyContactsForSelect = `
    query filterMyContacts($page: Page, $filters: ContactFilter, $sortOption: SortOption) {
        filterMyContacts(page: $page, filters: $filters, sortOption: $sortOption) {
            contacts {
                id
                contactId
                firstName
                lastName
            }
            page {
                page
                pageSize
                totalSize
            }
        }
    }
`;

export const filterReqContactsForSelect = `
    query filterReqContacts($reqIds: [String!]!, $page: Page, $filters: ReqContactFilter, $sortOption: SortOption) {
        filterReqContacts(reqIds: $reqIds, page: $page, filters: $filters, sortOption: $sortOption) {
            contacts {
                id
                contactId
                firstName
                lastName
            }
            page {
                page
                pageSize
                totalSize
            }
        }
    }
`;

export const getMyContactsWithReqsQuery = gql`
  query getMyContacts($contactIds: [String]) {
    getMyContacts(contactIds: $contactIds) {
      id
      createdAt
      lastUpdatedAt
      firstName
      lastName
      company
      address {
        street
        street2
        city
        state
        zipCode
      }
      nmlsId
      agentId
      emails {
        type
        address
      }
      reqs {
        id
        title
        stage
      }
      phones {
        type
        number
      }
      links {
        type
        url
      }
      customFields {
        reqId
        customFields {
          fieldId
          name
          value
          active
        }
      }
    }
  }
`;

export const getMyTenantsDataQuery = `
            query getMyTenants {
                getMyTenants {
                    id
                    title
                    type
                    selfSignup
                    user {
                        id
                        firstName
                        lastName
                        active
                    }
                }
            }`;

export const adminListTenantsQuery = `
            query adminListTenants($titleFilter:String) {
                adminListTenants (titleFilter: $titleFilter) {
                    id
                    title
                    type
                    selfSignup
                    user {
                        id
                        firstName
                        lastName
                        active
                    }
                }
            }`;

export const getMyTodosQuery = `
            query getMyTodos {
                getMyTodos {
                    id
                    type
                    title
                    dueOn
                    reqId
                    description
                    location
                    phone
                    duration
                    users {
                        id
                        firstName
                        lastName
                    }
                    contacts {
                        id
                        firstName
                        lastName
                    }
                }
            }`;

export const getMyReqTodosQuery = `
            query getMyReqs {
                getMyReqs {
                    id
                    reqId
                    title
                    status
                    tenant
                    todos {
                        id
                        type
                        title
                        dueOn
                        reqId
                        description
                        location
                        phone
                        duration
                        users {
                            id
                            firstName
                            lastName
                        }
                        contacts {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            }`;

export const filterMyTodosQuery = `
            query filterMyTodos ($page: Page, $filters: ActivityCriteria, $sortOption: SortOption) {
                filterMyTodos (page: $page, filters: $filters, sortOption: $sortOption){
                    count
                    page {
                        page
                        pageSize
                        totalSize
                    }
                    todos {
                        id
                        type
                        title
                        dueOn
                        reqId
                        description
                        location
                        phone
                        duration
                        users {
                            id
                            firstName
                            lastName
                        }
                        contacts {
                            id
                            firstName
                            lastName
                            stage
                        }
                    }
                }
            }`;

export const filterAllTodosQuery = `
            query filterAllTodos ($page: Page, $filters: ActivityCriteria, $sortOption: SortOption){
                filterAllTodos (page: $page, filters: $filters, sortOption: $sortOption){
                    count
                    page {
                        page
                        pageSize
                        totalSize
                    }
                    todos {
                        id
                        type
                        title
                        dueOn
                        reqId
                        description
                        location
                        phone
                        duration
                        users {
                            id
                            firstName
                            lastName
                        }
                        contacts {
                            id
                            firstName
                            lastName
                            stage
                        }
                        recurring {
                            frequency
                            customRecurringNum
                            customRecurringFrequency
                        }
                    }
                }
            }`;

export const getUserMentionsQuery = `
            query getUserMentions($userId:String!){
                getUserMentions(userId: $userId){
                    title
                    description
                    type
                    completedDate
                    id
                    completedBy {
                        firstName
                        lastName
                        id
                    }
                    isPublic
                    users {
                        id
                        firstName
                        lastName
                    }
                    contacts {
                       id
                       firstName
                       lastName
                    }
                    reqId
                    requisition
                    mentionChecked
                    userId
                }
            }
`;

export const getUserReqsForDropdownsQuery = `
            query getUserReqs($userId: String!) {
                getUserReqs(userId: $userId) {
                    id
                    reqId
                    title
                    status
                    tenant
                    active
                    tags
                }
            }`;

export const getTenantReqsQuery = `
            query getTenantReqs($tenantId: String!) {
                getTenantReqs(tenantId: $tenantId) {
                    id
                    title
                }
            }`;

export const filterMyActivityHistory = `
            query filterMyActivityHistory ($page: Page, $filters: ActivityCriteria, $sortOption: SortOption){
                filterMyActivityHistory (page: $page, filters: $filters, sortOption: $sortOption){
                    page {
                        page
                        pageSize
                        totalSize
                    }
                    histories {
                        id
                        type
                        title
                        description
                        disposition
                        dueOn
                        reqId
                        completedDate
                        previousEdit
                        completedBy {
                            id
                            firstName
                            lastName
                        }
                        users {
                            id
                            firstName
                            lastName
                        }
                        contacts {
                            id
                            active
                            firstName
                            lastName
                            stage
                        }
                    }
                }
            }`;

export const filterAllActivityHistory = `
            query filterAllActivityHistory ($page: Page, $filters: ActivityCriteria, $sortOption: SortOption){
                filterAllActivityHistory (page: $page, filters: $filters, sortOption: $sortOption){
                    page {
                        page
                        pageSize
                        totalSize
                    }
                    histories {
                        id
                        type
                        title
                        description
                        disposition
                        dueOn
                        reqId
                        completedDate
                        isPublic
                        previousEdit
                        completedBy {
                            id
                            firstName
                            lastName
                        }
                        contacts {
                            id
                            firstName
                            lastName
                            stage
                        }
                    }
                }
            }`;

export const getReqActivityHistory = `
            query getReqActivityHistory($reqId: String!) {
                getReqActivityHistory(reqId: $reqId) {
                    id
                    type
                    title
                    description
                    users {
                        id
                        firstName
                        lastName
                    }

                    contacts {
                        id
                        active
                        firstName
                        lastName
                    }

                    completedBy {
                        id
                        firstName
                        lastName
                    }

                    completedDate
                    dueOn
                }
            }`;

export const getContact = `
            query getContact($contactId: String!) {
                getContact(contactId: $contactId) {
                    active
                    id
                    nmlsId
                    title
                    company
                    emailDripEnabled
                    createdAt
                    tenantId
                    lastUpdatedAt
                    leadsIndex
                    phones {
                        type
                        number
                    }
                    emails {
                        type
                        address
                    }
                    links {
                        type
                        url
                    }
                    address {
                        street
                        street2
                        state
                        city
                        zipCode
                    }

                    firstName
                    lastName
                    ownerId
                    linkedIn
                    facebook
                    website
                    avatarId
                    twilioLookedUp
                    customFields {
                        fieldId
                        value
                    }
                }
            }`;

export const getContactActive = `
            query getContactActive($contactId: String!) {
                getContactActive(contactId: $contactId) {
                    active
                    id
                }
            }
    `;

export const getContactBusinessQuery = `
            query getContactBusiness($contactId: String!) {
                getContactBusiness(contactId: $contactId) {
                    volume
                    avgPerUnit
                    totalUnits
                    purchasePercentage
                    period
                    productMix {
                        conventional
                        government
                        nonConforming
                        other
                    }
                    endMonth
                    endYear
                    isUserInput
                    isUserImport
                }
            }
`;

export const getContactReqs = `
            query getContactReqs($contactId: String!) {
                getContactReqs(contactId: $contactId) {
                    requisition {
                      id
                      title
                      status
                      tenantId
                    }
                    stage
                    score
                    contactId
                    assignedUser {
                        id
                        firstName
                        lastName
                    }
                }
            }`;

export const searchMyData = `
            query searchMyData($searchKey: String!) {
                searchMyData(searchKey: $searchKey) {
                    contactsByName {
                        contactId
                        firstName
                        lastName
                    }
                    contactsByPhone {
                        contactId
                        firstName
                        lastName
                        phones {
                            type
                            number
                        }
                    }
                    contactsByEmail {
                        contactId
                        firstName
                        lastName
                        emails {
                            type
                            address
                        }
                    }
                    contactsByNmls {
                        contactId
                        firstName
                        lastName
                        nmlsId
                    }
                    requisitions {
                        id
                        title
                    }
                    companies {
                        contactId
                        company
                        firstName
                        lastName
                    }
                    contactTags {
                        contactId
                        firstName
                        lastName
                        tags {
                            tagId
                            name
                        }
                    }
                    reqTags {
                        contactId
                        firstName
                        lastName
                        reqId
                        tags {
                            tagId
                            name
                        }
                    }
                }
            }`;

export const isZapAuthenticated = ` query isZapAuthenticated(){
    isZapAuthenticated(){
        isAuthenticated
    }
}`;

export const getContactDocuments = `
            query getContactDocuments($contactId: String!, $reqId: String) {
                getContactDocuments(contactId: $contactId, reqId: $reqId) {
                    id
                    documentName
                    createdAt
                    lastUpdatedAt
                    ownerId
                    contactId
                }
            }`;

export const getContactDocumentUrl = `
            query getContactDocumentUrl($contactId: String!, $documentId: String!, $reqId: String) {
                getContactDocumentUrl(contactId: $contactId, documentId: $documentId, reqId: $reqId) {
                    id
                    url
                }
            }`;

export const getContactAvatarUrl = `
            query getContactAvatarUrl($contactId: String!) {
                getContactAvatarUrl(contactId: $contactId) {
                    id
                    url
                }
            }`;

export const getUserAvatarUrl = `
            query getUserAvatarUrl($userId: String!) {
                getUserAvatarUrl(userId: $userId) {
                    id
                    url
                }
            }`;

export const getUserContactSavedFilters = `
            query getUserContactSavedFilters {
                getUserContactSavedFilters {
                    name
                    createdAt
                    id
                    filterCriteria {
                        displayName
                        type
                        value
                        key
                    }
                }
            }`;

export const getReqContactSavedFilters = `
            query getReqContactSavedFilters($reqId: String!) {
                getReqContactSavedFilters(reqId: $reqId) {
                    name
                    createdAt
                    id
                    filterCriteria {
                        displayName
                        type
                        value
                        key
                    }
                }
            }`;

export const getPersonalInfo = `
            query getContactPersonalInfo($contactId: String!) {
                getContactPersonalInfo(contactId: $contactId) {
                    details
                    spouse
                    birthdate
                    kids
                    anniversary
                    education
                    hobbies
                }
            }`;

export const getReqUsers = `
            query getReqUsers($reqId: String!, $prodStartDate: String, $prodEndDate: String) {
                getReqUsers(reqId: $reqId, prodStartDate: $prodStartDate, prodEndDate: $prodEndDate) {
                    id
                    firstName
                    lastName
                    active
                    emails {
                        type
                        address
                    }
                    productivity {
                        todosOpened
                        todosCompleted
                        meetingsScheduled
                        meetingsCompleted
                        notesAdded
                    }
                }
            }`;

export const getTenantWithUsers = `
            query getTenant($tenantId: String!, $prodStartDate: String, $prodEndDate: String) {
                getTenant(tenantId: $tenantId, prodStartDate: $prodStartDate, prodEndDate: $prodEndDate) {
                    id
                    title
                    type
                    selfSignup
                    user {
                        id
                        firstName
                        lastName
                        username
                        active
                        addToAllReqs
                        isActivated
                        emails {
                            type
                            address
                        }
                        productivity {
                            todosOpened
                            todosCompleted
                            meetingsScheduled
                            meetingsCompleted
                            notesAdded
                        }
                    }
                }
            }`;

export const getTenantInfo = `
            query getTenant($tenantId: String!) {
                getTenant(tenantId: $tenantId) {
                    id
                    title
                    type
                    selfSignup                    
                }
            }`;

export const getContactImportStatus = `
            query getContactImportStatus($uploadId: String!) {
                getContactImportStatus(uploadId: $uploadId) {
                    uploadId
                    status
                    completed
                    contactsImported
                    inProgress
                    error
                }
            }`;

export const getUserImportStatus = `
    query getUserImportStatus($uploadId: String!) {
        getUserImportStatus(uploadId: $uploadId) {
            uploadId
            status
            completed
            usersImported
            inProgress
            error
        }
    }`;

export const getLeadSeachResults = `
            query getLeadsResults($leadExecutionId: String!, $cursor: String) {
                getLeadsResults(leadExecutionId: $leadExecutionId, cursor:$cursor) {
                    executionId
                    status
                    cursor
                    leads {
                        nmlsId
                        firstName
                        lastName
                        cellPhone
                        city
                        company
                        county
                        email
                        title
                        workPhone
                        website
                        state
                        totalLoanAmount
                        totalLoans
                        totalPurchase
                        totalRefinance
                        totalConforming
                        totalGovernment
                        totalConventional
                        totalOther
                        percentPurchase
                        volume
                        locations {
                            StartDate
                            CompanyName
                            LocationNMLSID
                            State
                            Street
                            PostalCode
                            Country
                            City
                            LocationType
                            category
                            CompanyNMLSID
                        }
                        licenses {
                            Status
                            StatusDate
                            LicenseNumber
                            IsAuthorized
                            IssueDate
                            Regulator
                            RenewedThrough
                            LicenseID
                            LicenseType
                        }
                    }
                }
            }`;

export const getUserInfo = `
            query getUserInfo($userId: String!) {
                getUserInfo(userId: $userId) {
                    tenantId
                    created
                    lastUpdated
                    nmlsId
                    title
                    company
                    phones {
                        type
                        number
                    }
                    emails {
                        type
                        address
                    }
                    links {
                        type
                        url
                    }
                    address {
                        street
                        street2
                        state
                        city
                        zipCode
                        coordinates {
                            lat
                            lon
                        }
                    }
                    avatarId
                    firstName
                    lastName
                    username
                    notifications
                    agentPhone
                    smsPhone
                    smsNotify  
                    deviceRemembered
                }
            }`;

export const getTodo = `
            query getTodo($todoId: String!) {
                getTodo(todoId: $todoId) {
                    id
                    type
                    title
                    dueOn
                    reqId
                    description
                    location
                    phone
                    duration
                    users {
                        id
                        firstName
                        lastName
                    }
                    contacts {
                        id
                        firstName
                        lastName
                    }
                }
            }
`;

export const listPlaybookTemplates = `
    query listPlaybookTemplates($tenantId: String!, $playbookTemplateFilter: String!) {
        listPlaybookTemplates(tenantId: $tenantId, playbookTemplateFilter: $playbookTemplateFilter) {
            id
            title
            desc
            categories
            {
                id
                title
                desc
                stages{
                    id
                    tasks{
                        id
                        color
                        title
                        desc
                        notes
                        {
                            id
                            content
                            by
                            createdAt
                            modifiedAt
                        }
                        status
                        dueDSE
                        weight
                        assignedTo
                    }
                }
            }
            order
        }
    }`;

export const readPlaybookTemplate = `
    query readPlaybookTemplate($tenantId: String!, $playbookTemplateId: String!) {
        readPlaybookTemplate(tenantId: $tenantId, playbookTemplateId: $playbookTemplateId) {
            id
            title
            desc
            categories
            {
                id
                title
                desc
                stages{
                    id
                    tasks{
                        id
                        color
                        title
                        desc
                        notes
                        {
                            id
                            content
                            by
                            createdAt
                            modifiedAt
                        }
                        status
                        dueDSE
                        weight
                        assignedTo
                    }
                }
            }
            order
        }
    }`;

export const readSinglePlaybookReqContact = `
    query readSinglePlaybookReqContact($tenantId: String!, $reqId: String!, $contactId: String!, $playbookTemplateId: String!) {
        readSinglePlaybookReqContact(tenantId: $tenantId, reqId: $reqId, contactId: $contactId, playbookTemplateId: $playbookTemplateId) {
            id
            playbookTemplateId
            playbookObj
            {
                id
                title
                desc
                categories
                {
                    id
                    title
                    desc
                    stages{
                        id
                        tasks{
                            id
                            color
                            title
                            desc
                            notes
                            {
                                id
                                content
                                by
                                createdAt
                                modifiedAt
                            }
                            status
                            dueDSE
                            weight
                            assignedTo
                        }
                    }
                }
                order
            }
        }
    }`;

export const readPlaybookTemplateReqLinkByReq = `
    query readPlaybookTemplateReqLinkByReq($tenantId: String!, $reqId: String!, $useTopDefault: Boolean!) {
        readPlaybookTemplateReqLinkByReq(tenantId: $tenantId, reqId: $reqId, useTopDefault: $useTopDefault) {
            id
            reqId
            playbookTemplateId
        }
    }`;

export const listPlaybookTemplateReqLinks = `
    query listPlaybookTemplateReqLinks($playbookTemplateReqLinksFilter: String!) {
        listPlaybookTemplateReqLinks(playbookTemplateReqLinksFilter: $playbookTemplateReqLinksFilter) {
            id
            reqId
            playbookTemplateId
        }
    }`;

export const listContactTags = `
    query listContactTags($tenantId: String, $contactTagFilter: String!, $reqId: String) {
        listContactTags(tenantId: $tenantId, contactTagFilter: $contactTagFilter, reqId: $reqId) {
            tagId
            name
            order
        }
    }`;

export const readContactTag = `
    query readContactTag($tenantId: String, $contactTagId: String!, $reqId: String) {
        readContactTag(tenantId: $tenantId, contactTagId: $contactTagId, reqId: $reqId) {
            tagId
            name
            order
        }
    }`;

export const getUserTodos = `
    query getUserTodos($userId: String!) {
        getUserTodos(userId: $userId) {
            id
            type
            title
            dueOn
            reqId
            description
            location
            phone
            duration
            users {
                id
                firstName
                lastName
            }
        }
    }`;

export const listDuplicateContacts = `
    query listDuplicateContacts($contactId: String!) {
        listDuplicateContacts(contactId: $contactId) {
            id
            firstName
            lastName
            lastUpdatedAt
            createdAt
            address {
                street
                street2
                city
                state
                zipCode
            }
            emails {
                type
                address
            }
            phones {
                type
                number
            }
            links {
                type
                url
            }
            title
            company
            nmlsId
            contactId
            tenantId
            statistics {
                volume
                avgPerUnit
                totalUnits
                purchasePercentage
                period
                productMix {
                    conventional
                    government
                    nonConforming
                    other
                }
                endMonth
                endYear
                isUserInput
                isUserImport
            }
            tags
            {
                tagId
                name
            }
        }

    }`;
export const getNmlsValidation = `
    query getNmlsValidation($executionId: String!) {
        getNmlsValidation(executionId: $executionId) {
            executionId
            status
            result
        }
    }
`;

export const validateNmlsId = `
    query validateNmlsId($nmlsId: String!, $since: String) {
        validateNmlsId(nmlsId: $nmlsId, since: $since)
    }
`;

export const getReqContactTags = `
    query getReqContactTags($reqId: String, $contactId: String!) {
        getReqContactTags(reqId: $reqId, contactId: $contactId) {
            tagId
            name
            order
        }
    }`;

export const readIntegrationKey = `
    query readIntegrationKey($iName: String!) {
        readIntegrationKey(iName: $iName) {
            iName
            apiKey
            webhookCode
        }
    }`;

export const validateCallerId = `
    query validateCallerId($agentNumber: String!) {
        validateCallerId(agentNumber: $agentNumber)
    }`;

export const verifyCallerId = `
    query verifyCallerId($agentNumber: String!, $agentInfo: String!) {
        verifyCallerId(agentNumber: $agentNumber, agentInfo: $agentInfo)
        {
            msg
            validationCode
            callSid
            phoneNumber
        }
    }`;

export const getFeatureClick2Call = `
    query getFeatureClick2Call($tenantId: String!) {
        getFeatureClick2Call(tenantId: $tenantId)
    }`;

export const getCallStatus = `
    query getCallStatus($callServiceId: String!) {
        getCallStatus(callServiceId: $callServiceId)
    }`;

export const getReportingCounts = `
    query getReportingCounts($index: [String]!, $page: Page, $aggregation: String, $aggs: String, $sort: String, $size: String, $dateTarget: String!, $startDate: String!, $endDate: String!, $reqIds: [String]!, $userIds: [String]!, $esFilters: [ESFilterInput], $filters: String, $endPoint: String ) {
        getReportingCounts(index: $index, page: $page, aggregation: $aggregation, aggs: $aggs, sort: $sort, size: $size, dateTarget: $dateTarget, startDate: $startDate, endDate: $endDate, reqIds: $reqIds, userIds: $userIds, esFilters: $esFilters, filters: $filters, endPoint: $endPoint )
    }`;

export const getFeatureByName = `
    query getFeatureByName($feature: String!, $tenantId: String!) {
        getFeatureByName(feature: $feature, tenantId: $tenantId)
    }`;

export const getMarketInsightsFeatureByName = `
    query getMarketInsightsFeatureByName($feature: String!, $tenantId: String!) {
        getMarketInsightsFeatureByName(feature: $feature, tenantId: $tenantId)
    }`;

export const getFeatureByNameForUser = `
    query getFeatureByNameForUser($feature: String!, $userId: String!, $tenantId: String, $companySettingsFeature: Boolean) {
        getFeatureByNameForUser(feature: $feature, userId: $userId, tenantId: $tenantId, companySettingsFeature: $companySettingsFeature)
    }`;

export const listReportingTemplates = `
    query listReportingTemplates($reportingTemplateFilter: String!, $tenantId: String) {
        listReportingTemplates(reportingTemplateFilter: $reportingTemplateFilter, tenantId: $tenantId)
    }`;

export const readReportingTemplate = `
    query readReportingTemplate($reportingTemplateId: String!, $tenantId: String, $systemTarget: String) {
        readReportingTemplate(reportingTemplateId: $reportingTemplateId, tenantId: $tenantId, systemTarget: $systemTarget)
    }`;

export const getESQueryResults = `
    query getESQueryResults($index: String!, $esQuery: String!, $endPoint: String!) {
        getESQueryResults(index: $index, esQuery: $esQuery, endPoint: $endPoint)
    }`;

export const readUserSettingKey = `
    query readUserSettingKey($iName: String!) {
        readUserSettingKey(iName: $iName) {
            iName
            settingValue
        }
    }`;

export const readUserSettingKeyForUser = `
    query readUserSettingKeyForUser($iName: String!, $userId: String!) {
        readUserSettingKeyForUser(iName: $iName, userId: $userId) {
            iName
            settingValue
        }
    }`;

export const listReportObjects = `
    query listReportObjects($ReportObjectFilter: String!, $tenantId: String) {
        listReportObjects(ReportObjectFilter: $ReportObjectFilter, tenantId: $tenantId)
    }`;

export const readReportObject = `
    query readReportObject($ReportObjectId: String!, $tenantId: String, $systemTarget: String) {
        readReportObject(ReportObjectId: $ReportObjectId, tenantId: $tenantId, systemTarget: $systemTarget)
    }`;

export const getReqFiltersUsers = `
    query getReqFiltersUsers($reqIds: [String]!) {
        getReqFiltersUsers(reqIds: $reqIds) {
            id
            firstName
            lastName
            active
        }
    }`;

export const readReportingSnapshot = `
    query readReportingSnapshot($input: ReadReportingSnapshotInput!) {
        readReportingSnapshot(input: $input) {
            id
            downloadUrl
        }
    }
`;

export const listBonzoCampaigns = `
    query listBonzoCampaigns($iAPIKeyBONZO: String!) {
        listBonzoCampaigns(iAPIKeyBONZO: $iAPIKeyBONZO)
    }`;

export const proxyGet = `
    query proxyGet($url: String!, $headers: String) {
        proxyGet(url: $url, headers : $headers)
    }`;

export const readSystemKey = `
    query readSystemKey($iName: String!) {
        readSystemKey(iName: $iName) {
            iName
            sysKey
        }
    }`;

export const getCurrentOAuthToken = `
    query getCurrentOAuthToken($iName: String!, $basicAuth: Boolean) {
        getCurrentOAuthToken(iName: $iName, basicAuth: $basicAuth)
    }`;

export const getContactUsersQuery = `
    query getContactUsers($contactId: String!){
        getContactUsers(contactId: $contactId)
    }
`;

export const listAvailableNumbers = `
    query listAvailableNumbers($total: String, $npa: String, $npx: String) {
        listAvailableNumbers(total: $total, npa: $npa, npx: $npx)
    }`;

export const readNumber = `
    query readNumber($number: String!) {
        readNumber(number: $number)
    }`;

export const listMessageConversationalHistory = `
    query listMessageConversationalHistory($to: String!, $from: String!, $size: String, $start: String, $direction: String) {
        listMessageConversationalHistory(to: $to, from: $from, size: $size, start: $start, direction: $direction)         
    }`;

export const searchMessageConversationalHistory = `
    query searchMessageConversationalHistory($searchPhrase: String, $businessAddress: String!, $conversationAddress: String, $size: String, $start: String, $direction: String) {
        searchMessageConversationalHistory(searchPhrase: $searchPhrase, businessAddress: $businessAddress, conversationAddress: $conversationAddress, size: $size, start: $start, direction: $direction)         
    }`;

export const getMMSMedia = `
            query getMMSMedia($mmsMediaDocId: String) {
                getMMSMedia(mmsMediaDocId: $mmsMediaDocId) {
                    id
                    documentName
                    createdAt
                    lastUpdatedAt
                    ownerId
                    url
                    thumbUrl
                    fileType
                }
            }`;

export const getMMSMediaUrl = `
            query getMMSMediaUrl($mmsMediaDocId: String!, $thumb: String!) {
                getMMSMediaUrl(mmsMediaDocId: $mmsMediaDocId, thumb: $thumb)
            }`;

export const getLiveCounter = `
            query getLiveCounter($placeholder: String) {
                getLiveCounter(placeholder: $placeholder) 
            }`;

export const listConversations = `
            query listConversations($assignedNumber: String!, $size: String, $start: String, $direction: Boolean, $active: Boolean, $filter: String) {
                listConversations(assignedNumber: $assignedNumber, size: $size, start: $start, direction: $direction, active: $active, filter: $filter) 
                {
                    Count
                    ScannedCount
                    LastEvaluatedKey{
                        pKey
                        rKey
                    }
                    Items{
                    contactNumber
                    created
                    read
                    contact {
                        id
                        firstName
                        lastName
                        linkedIn
                        facebook
                        website
                        avatarId
                        title
                        company
                        nmlsId
                    }
                    }
                }
            }`;

export const trainingGetUserInfo = `
            query trainingGetUserInfo($placeHolder: String) {
                trainingGetUserInfo(placeHolder: $placeHolder) {
                    id
                    firstName
                    lastName                    
                }
            }`;

export const trainingGetUserMoreInfo = `
    query trainingGetUserInfo($placeHolder: String) {
        trainingGetUserInfo(placeHolder: $placeHolder) {
            id
            firstName
            lastName
            emails{
                type
                address
            }                    
        }
    }`;

export const getUserImportHistory = `
    query getUserImportHistory($userId: String!){
        getUserImportHistory(userId: $userId){
            completed
            contactsImported
            createdAt
            duplicates
            fileUploaded
            key
            status
            inProgress
            options {
                assignee {
                    firstName
                    id
                    lastName
                }
                importType
                reqId
                reqName
                skipEmail
                tags {
                    name
                }
                tenantId
                tenantName
                count
            }
            error
        }
    }
`;

export const getContactImportDownloadURLQuery = `
    query getContactImportDownloadURL($fileName: String!){
        getContactImportDownloadURL(fileName: $fileName){
            id
            url
        }
    }
`;

export const getEnrichmentSuggestionsQuery = `
    query getEnrichmentSuggestions($form: String!, $contactId: String!, $reqId: String!){
        getEnrichmentSuggestions(form: $form, contactId: $contactId, reqId: $reqId) {
            mmKey
            mmValue
            mmType
            pdlSuggestion
        }
    }
`;

export const searchLocationQuery = `
    query searchLocation($inputStr: String!, $filters: String!){
        searchLocation(inputStr: $inputStr, filters: $filters){
            found
            branchData
            data
        }
    }
`;

export const searchBranchesQuery = `
    query searchBranches($coords: GeoCodeInput!, $filters: String!){
        searchBranches(coords: $coords, filters: $filters){
            found
            branchData
            data
        }
    }
`;

export const getContactFromUserContacts = `
    query getContactFromUserContacts($phoneNumber: String!) {
        getContactFromUserContacts(phoneNumber: $phoneNumber) {
            id
            firstName
            lastName
            linkedIn
            facebook
            website
            avatarId
            title
            company
            nmlsId
        }
    }
`;

export const getBonzoWebhookDocQuery = `
    query getBonzoWebhookDoc($webhookCode: String!){
        getBonzoWebhookDoc(webhookCode: $webhookCode) {
            pipeflowSettings {
                candidates
                meetings
                offers
                hires
                featureEnabled
            }
        }
    }
`;

export const getUserMetricsQuery = `
    query getUserMetrics($userId: String!){
        getUserMetrics(userId: $userId){
            places
            map_render
            lastActivity
        }
    }
`;

export const getReqContactQuery = `
    query getReqContact($contactId: String!, $reqId: String!){
        getReqContact(contactId: $contactId, reqId: $reqId) {
            id
            reqName
            reqId
            firstName
            lastName
            nmlsId
            agentId
            stage
            stageEnteredDate
            createdAt
            source
            company
            title
            opportunityValue
            opportunityValueHard
            links {
                type
                url
            }
            emails {
                type
                address
            }
            phones {
                type
                number
            }
            address {
                street
                zipCode
                state
                city
            }
            contacted
            lastUpdatedAt
            contactId   
            assignedUser {
                id
                firstName
                lastName
            }     
            customFields {
                fieldId
                value
            }
            trailing12Units
            trailing12Volume
            product_mix_conventional
            product_mix_fha
            product_mix_government
            product_mix_jumbo
            product_mix_usda
            product_mix_va
            purpose_mix_purchase
            purpose_mix_refinance     
        }
    }
`;

export const getReqContactsForExportQuery = `
    query getReqContactsForExport($reqId: String!, $startContactId: String, $size: String){
        getReqContactsForExport(reqId: $reqId, startContactId: $startContactId, size: $size) {

            hits {    
                id
                firstName
                lastName
                nmlsId
                agentId
                stage
                stageEnteredDate
                createdAt
                source
                company
                title
                reqId
                reqName
                opportunityValue
                opportunityValueHard
                trailing12Units
                trailing12Volume
                links {
                    type
                    url
                }
                emails {
                    type
                    address
                }
                phones {
                    type
                    number
                }
                address {
                    street
                    zipCode
                    state
                    city
                }
                contacted
                lastUpdatedAt
                contactId   
                assignedUser {
                    id
                    firstName
                    lastName
                }   
                product_mix_conventional
                product_mix_fha
                product_mix_government
                product_mix_jumbo
                product_mix_usda
                product_mix_va
                purpose_mix_purchase
                purpose_mix_refinance            
            }
            count
            endKey
        }
    }
`;

export const getCountReqContactsForExport = `
    query getCountReqContactsForExport($reqId: String!) {
        getCountReqContactsForExport(reqId: $reqId) 
    }`;

export const listSequences = `
    query listSequences($sequenceFilter: String!, $tenantId: String) {
        listSequences(sequenceFilter: $sequenceFilter, tenantId: $tenantId) 
    }`;

export const readSequence = `
    query readSequence($sequenceId: String!, $tenantId: String, $systemTarget: String) {
        readSequence(sequenceId: $sequenceId, tenantId: $tenantId, systemTarget: $systemTarget) 
    }`;
export const getBranchDetailsQuery = `
    query getBranchDetails($branchID: String!, $companyID: String!){
        getBranchDetails(branchID: $branchID,companyID: $companyID) {
            branchManagers{
                BranchManagerName
                BranchManagerIndustry
                BranchNMLSID
                BranchManagerIndustryID
                title
                IndividualNMLSID
            }
            company{
                Name
                CompanyNMLSID
            }
        }
    }
`;

export const getCompanySuggestionsQuery = `
    query getCompanySuggestions($text: String!){
        getCompanySuggestions(text: $text)
    }
`;

export const searchCompanyQuery = `
    query searchCompany($text: String!, $filters: String!){
        searchCompany(text: $text, filters: $filters){
            Street
            Street2
            City
            Country
            State
            location {
                lon
                lat
            }
            CompanyNMLSID
            PostalCode
            FormedIn
            DateFormed
            BusinessStructure
            PrimaryFederalRegulator
            teamSize
            individuals
            volume
            unitCount
            isFedRegulated
            StockSymbol
            RegistrationStatus
            Name
            websites
            branches
            trailing12 {
                volume
                avgPerUnit
                totalUnits
                purchasePercentage
                period
                productMix {
                    conventional
                    government
                    nonConforming
                    other
                }
                transactionMix {
                    purchase
                    refinance
                    construction
                    equity
                    REO
                }
                loanTypes {
                    conventional
                    HE
                    FHA
                    VA
                    USDA
                    HELOC
                    building
                    balloon
                    commercial
                    reverse
                    other
                    unknown
                }
            }
        }
    }
    `;

export const searchIndividualsQuery = `
    query searchIndividuals($text: String!, $filters: String!){
        searchIndividuals(text: $text, filters: $filters){
            location {
                lat
                lon
            }
            originators {
                nmlsId
                firstName
                lastName
                middleName
                title
                volume
                unitCount
                locations {
                    StartDate
                    CompanyName
                    LocationNMLSID
                    State
                    Street
                    PostalCode
                    Country
                    City
                    LocationType
                    category
                    CompanyNMLSID
                }
                TWGContactInfo
                {
                    LID
                    Status
                    Validated
                    LOOrgID
                    LOOrgName
                    Collected_EmployerCOMPANY
                    Collected_EmployerCOMPANYID
                    Collected_Employer_Type
                    Collected_EmployerAddress
                    Collected_EmployerCity
                    Collected_EmployerState
                    Collected_EmployerZip
                    Collected_OfficePhone
                    Collected_OfficePhoneExt
                    Collected_CellPhone
                    Collected_WorkEmail
                    Collected_PersonalEmail
                    Collected_EmployerWebsite
                    Collected_LOWebSite
                    Collected_Linkedin
                    Collected_Twitter
                    Collected_Facebook
                    Collected_Other
                    ModiType
                }
            }
        }
    }
    `;

export const getLeadsInfoQuery = `
    query getLeadsInfo($leads: [String]!){
        getLeadsInfo(leads: $leads){
            nmlsId
            firstName
            lastName
            cellPhone
            city
            company
            county
            email
            title
            workPhone
            website
            state
            volume
            unitCount
            locations {
                StartDate
                CompanyName
                LocationNMLSID
                State
                Street
                PostalCode
                Country
                City
                LocationType
                category
                CompanyNMLSID
            }
        }
    }
`;

export const getLeadsInfoWithOptions = `
    query getLeadsInfoWithOptions($locations: [String]!, $options: TeamTableOptionsInput){
        getLeadsInfoWithOptions(locations, : $locations, options: $options ){
            total
            data {
                nmlsId
                firstName
                lastName
                cellPhone
                city
                company
                county
                email
                title
                workPhone
                website
                state
                volume
                unitCount
                locations {
                    StartDate
                    CompanyName
                    LocationNMLSID
                    State
                    Street
                    PostalCode
                    Country
                    City
                    LocationType
                    category
                    CompanyNMLSID
                }
                TWGContactInfo
                {
                    LID
                    Status
                    Validated
                    LOOrgID
                    LOOrgName
                    Collected_EmployerCOMPANY
                    Collected_EmployerCOMPANYID
                    Collected_Employer_Type
                    Collected_EmployerAddress
                    Collected_EmployerCity
                    Collected_EmployerState
                    Collected_EmployerZip
                    Collected_OfficePhone
                    Collected_OfficePhoneExt
                    Collected_CellPhone
                    Collected_WorkEmail
                    Collected_PersonalEmail
                    Collected_EmployerWebsite
                    Collected_LOWebSite
                    Collected_Linkedin
                    Collected_Twitter
                    Collected_Facebook
                    Collected_Other
                    ModiType
                }
            }
        }
    }
`;

export const getBranchesWithOptions = `
    query getBranchesWithOptions($companyID: String!, $options: BranchTableOptionsInput){
        getBranchesWithOptions(companyID: $companyID, options: $options){
            total
            data {
                Street
                Street2 
                City 
                Country 
                State 
                location {
                    lon
                    lat
                }
                IsAuthorized
                CompanyNMLSID 
                BranchNMLSID 
                type 
                PostalCode 
                teamSize
                individuals
                Name
                volume
                unitCount
            }
        }
    }
`;

export const checkPipelineDupesQuery = `
    query checkPipelineDupes($leads: [DupeLeadInput]!, $tenantId: String!){
        checkPipelineDupes(leads: $leads, tenantId: $tenantId){
            duplicates {
                nmlsId
                firstName
                lastName
                company
                stage
                reqName
                contactId
                reqId
                assignedUser {
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const listSequenceQueue = `
    query listSequenceQueue($sequenceQueueFilter: String) {
        listSequenceQueue(sequenceQueueFilter: $sequenceQueueFilter)
         {
            createdAt
            scheduledAt
            eventId
            queueId
            target
            source
            channel
            sequenceId
            system
            step
            contact {
                id
                firstName
                lastName
            }
            req {
                id
                name
            }
            user
            {
                id
                firstName
                lastName
                tenantId
            }
        }
    }`;

export const readSequenceQueue = `
    query readSequenceQueue($sequenceQueueKey: String!, ) {
        readSequenceQueue(sequenceQueueKey: $sequenceQueueKey)
         {
            createdAt
            scheduledAt
            eventId
            queueId
            target
            source
            channel           
            sequenceId
            system
            step
            contact {
                id
                firstName
                lastName
            }
            req {
                id
                name
            }
            user
            {
                id
                firstName
                lastName
                tenantId
            }
        }
    }`;

export const searchSequenceQueue = `
    query searchSequenceQueue($sequenceQueueKey: String!) {
        searchSequenceQueue(sequenceQueueKey: $sequenceQueueKey)
        {
            createdAt
            scheduledAt
            eventId
            queueId
            target
            source
            channel            
            sequenceId
            system
            step
            contact {
                id
                firstName
                lastName
            }
            req {
                id
                name
            }
            user
            {
                id
                firstName
                lastName
                tenantId
            }
        }
    }`;

export const checkPhoneNumberType = `
    query checkPhoneNumberType($phoneNumber: String!) {
        checkPhoneNumberType(phoneNumber: $phoneNumber)
      }`;
export const getCreditProfileQuery = `
    query getCreditProfile($tenantId: String!){
        getCreditProfile(tenantId: $tenantId){
            dateCreated
            createdBy
            notificationEmail
            lastUpdated
            totalCredits
            creditMax
            notificationThreshold
            enabled
        }
    }  
`;

export const getCreditUsageQuery = `
    query getCreditUsage($tenantId: String!){
        getCreditUsage(tenantId: $tenantId)
    }
`;

export const buildCompanyVolumeReportQuery = `
    query buildCompanyVolumeReport($companyNMLSID: String!){
        buildCompanyVolumeReport(companyNMLSID: $companyNMLSID){
            trailing12 {
                period
                endYear
                endMonth
                volume
                totalUnits
                avgPerUnit
                purchasePercentage
                productMix {
                    conventional
                    government
                    nonConforming
                    other
                }
                transactionMix {
                    purchase
                    refinance
                    construction
                    equity
                    REO
                }
                loanTypes {
                    conventional
                    HE
                    FHA
                    VA
                    USDA
                    HELOC
                    building
                    balloon
                    commercial
                    reverse
                    other
                    unknown
                }
                topStates {
                    name
                    count
                    volume
                }
            }
            monthly {
                date_range
                purchase
                refi
                per_purchase
                per_refi
                per_conventional
                per_government
                production {
                    count
                    min
                    max
                    avg
                    sum
                }
                productMix {
                    conventional
                    government
                    nonConforming
                    other
                }
            }
        } 
    }
`;

export const buildBranchVolumeReportQuery = `
    query buildBranchVolumeReport($branchNMLSID: String!){
        buildBranchVolumeReport(branchNMLSID: $branchNMLSID){
            trailing12 {
                period
                endYear
                endMonth
                volume
                totalUnits
                avgPerUnit
                purchasePercentage
                productMix {
                    conventional
                    government
                    nonConforming
                    other
                }
                transactionMix {
                    purchase
                    refinance
                    construction
                    equity
                    REO
                }
                loanTypes {
                    conventional
                    HE
                    FHA
                    VA
                    USDA
                    HELOC
                    building
                    balloon
                    commercial
                    reverse
                    other
                    unknown
                }
                topStates {
                    name
                    count
                    volume
                }
            }
            monthly {
                date_range
                purchase
                refi
                per_purchase
                per_refi
                per_conventional
                per_government
                production {
                    count
                    min
                    max
                    avg
                    sum
                }
                productMix {
                    conventional
                    government
                    nonConforming
                    other
                }
            }
        } 
    }
`;

export const buildOriginatorVolumeReportQuery = `
    query buildOriginatorVolumeReport($nmlsId: String!){
        buildOriginatorVolumeReport(nmlsId: $nmlsId){
            trailing12 {
                period
                endYear
                endMonth
                volume
                totalUnits
                avgPerUnit
                purchasePercentage
                productMix {
                    conventional
                    government
                    nonConforming
                    other
                }
                transactionMix {
                    purchase
                    refinance
                    construction
                    equity
                    REO
                }
                loanTypes {
                    conventional
                    HE
                    FHA
                    VA
                    USDA
                    HELOC
                    building
                    balloon
                    commercial
                    reverse
                    other
                    unknown
                }
                topStates {
                    name
                    count
                    volume
                }
                topLenders {
                    name
                    count
                    volume
                }
            }
            monthly {
                date_range
                purchase
                refi
                per_purchase
                per_refi
                per_conventional
                per_government
                production {
                    count
                    min
                    max
                    avg
                    sum
                }
                productMix {
                    conventional
                    government
                    nonConforming
                    other
                }
            }
        } 
    }
`;

export const searchNMLSIDQuery = `
    query searchNMLSID($nmlsId: String!){
        searchNMLSID(nmlsId: $nmlsId){
            Street
            Street2
            City
            Country
            State
            location {
                lon
                lat
            }
            CompanyNMLSID
            BranchNMLSID
            IsAuthorized
            PostalCode
            FormedIn
            DateFormed
            BusinessStructure
            PrimaryFederalRegulator
            teamSize
            individuals
            volume
            unitCount
            isFedRegulated
            StockSymbol
            RegistrationStatus
            Name
            websites
            branches
        }
    }
`;

export const getAllEmailTemplates = `
    query getAllEmailTemplates {
        getAllEmailTemplates {
            templateId
            templateTitle
            templateBody
            templateSubject
        }
    }
`;

export const getEmailSignature = `
    query getEmailSignature {
        getEmailSignature {
            signature
            useSignature
        }
    }
`;

export const listSMSTemplates = `
    query listSMSTemplates($smsTemplateFilter: String!, $tenantId: String) {
        listSMSTemplates(smsTemplateFilter: $smsTemplateFilter, tenantId: $tenantId) 
    }`;

export const readSMSTemplate = `
    query readSMSTemplate($smsTemplateId: String!, $tenantId: String, $systemTarget: String) {
        readSMSTemplate(smsTemplateId: $smsTemplateId, tenantId: $tenantId, systemTarget: $systemTarget) 
    }`;

export const getContactVolumeReportQuery = `
    query getContactVolumeReport($contactId: String!){
        getContactVolumeReport(contactId: $contactId){
            lastUpdatedAt
            createdAt
            nmlsId
            report {
                volume
                avgPerUnit
                totalUnits
                purchasePercentage
                period
                productMix {
                    conventional
                    government
                    nonConforming
                    other
                }
                transactionMix {
                    purchase
                    refinance
                    construction
                    equity
                    REO
                }
                loanTypes {
                    conventional
                    HE
                    FHA
                    VA
                    USDA
                    HELOC
                    building
                    balloon
                    commercial
                    reverse
                    other
                    unknown
                }
                topCities {
                    name
                    count
                    volume
                }
                topLenders {
                    name
                    count
                    volume
                }
                monthly {
                    date_range
                    production {
                        count
                        min
                        max
                        avg
                        sum
                    }
                }
                endMonth
                endYear
            }
        }
    }
`;

export const readSequenceLockTable = `
    query readSequenceLockTable($threadId: String) {
        readSequenceLockTable(threadId: $threadId) 
    }`;

export const filterEmailTemplates = `
    query filterEmailTemplates($templateTitle: String) {
        filterEmailTemplates(templateTitle: $templateTitle) {
            templateId
            templateTitle
            templateBody
            templateSubject
        }
    }
`;

export const mceFilterContacts = `
            query mceFilterContacts($page: Page, $filters: [QueryStringContactFilter], $sortOption: [NewSortOption], $index: String) {
                mceFilterContacts(page: $page, filters: $filters, sortOption: $sortOption, index: $index) {
                    page {
                        page
                        pageSize
                        totalSize
                    }
                    contacts {
                        id
                        firstName
                        lastName
                        lastUpdatedAt
                        createdAt
                        stage
                        stageEnteredDate
                        contacted
                        reqName
                        reqId
                        source
                        sourceId
                        assignedUser
                        {
                            id
                            firstName
                            lastName
                        }
                        address {
                            street
                            street2
                            city
                            state
                            zipCode
                        }
                        emails {
                            type
                            address
                        }
                        phones {
                            type
                            number
                        }
                        links {
                            type
                            url
                        }
                        title
                        company
                        nmlsId
                        contactId
                        tenantId
                        statistics {
                            volume
                            period
                            endYear
                        }

                        tags
                        {
                            tagId
                            name
                        }
                    }
                }
            }`;

export const listGroupsForUser = `
query listGroupsForUser($userNames: [String]!) {
    listGroupsForUser(userNames: $userNames) {
        email,
        groups
    }
}`;

export const stripeTest = `
query stripeTest($test: Boolean) {
    stripeTest(test: $test)
}
`;

export const getTotalSentSequenceSummary = `
    query getTotalSentSequenceSummary($sequenceID: String!){
        getTotalSentSequenceSummary(sequenceID: $sequenceID){
            contactID,
            firstName,
            lastName, 
            totalSent
        }
    }  
`;

export const getOptOutSequenceSummary = `
    query getOptOutSequenceSummary($sequenceID: String!){
        getOptOutSequenceSummary(sequenceID: $sequenceID){
            contactID,
            firstName,
            lastName, 
            step,
            lastMessageSent,
            createdDate
        }
    }  
`;

export const getUpdatedTenantStripeSub = `
    query getUpdatedTenantStripeSub($tenantId: String!) {
        getUpdatedTenantStripeSub(tenantId: $tenantId) {
            tenantTitle
            subscriptionId
            customerId
            status
            subItems {
                subItemId
                productId
                priceId
                title
                price
                quantity
                billingInterval
            }
            paymentMethods {
                pmId
                name
                type
                brand
                last4
                exp_month
                exp_year
                billingDetails {
                    line1
                    line2
                    city
                    state
                    postalCode
                    country
                }
            }
            defaultPaymentMethodId
            invoices {
                invoiceId
                dateSeconds
                date
                periodStart
                periodEnd
                items {
                    invoiceItemId
                    description
                    amountStr
                    priceId
                    productId
                    interval
                }
                itemsStr
                amountStr
                status
                pdfLink
            }
            billingInterval
            errorMessage
            discount
        }
    }
`;

export const getSMSDeliveryTotals = `
    query getSMSDeliveryTotals($userID: String!, $sequenceID: String!){
        getSMSDeliveryTotals(userID: $userID, sequenceID: $sequenceID){
            delivered,
            total
        }
    }  
`;

export const checkTenantStripeSubStatus = `
    query checkTenantStripeSubStatus($tenantId: String!) {
        checkTenantStripeSubStatus(tenantId: $tenantId)
    }
`;

export const getPlaidLinkToken = `
    query getPlaidLinkToken {
        getPlaidLinkToken {
            link_token
            expiration
            request_id
        }
    }
`;

export const getUnpaidInvoiceUrl = `
    query getUnpaidInvoiceUrl($subscriptionId: String!) {
        getUnpaidInvoiceUrl(subscriptionId: $subscriptionId)
    }
`;

export const getUsersActivatedStatus = `
    query getUsersActivatedStatus($usersToCheck: [UsersToCheck!]){
        getUsersActivatedStatus(usersToCheck: $usersToCheck){
            Username,
            UserStatus
        }
    }
`;

export const getUserDevicesInfo = `
    query getUserDevicesInfo($userID: String!){
        getUserDevicesInfo(userID: $userID){
            deviceKey,
            lastUpdated,
            created,
            id,
            deviceInfo {
                deviceKey,
                lastModified,
                createdDate,
                lastAuthenticated,
                refreshToken,
                accessToken
                deviceName {
                    Value,
                    Name
                },
                IP {
                    Value,
                    Name
                }
            }
        }
    }  
`;

export const getListRunningTasksFromLogs = `
    query getListRunningTasksFromLogs($input: String){
        getListRunningTasksFromLogs(input: $input){
            pKey
            rKey
            requestedByUser
            messageBody
            {
                step
                maxSteps
                requestedByUser
                userName
                error
            }
            started
            task
            taskId
        }
    }
`;

export const getPermissions = `
    query getPermissions ($tenantId: String, $userId: String) {
        getPermissions(tenantId: $tenantId, userId: $userId) 
}`;

export const userHasPermissionToContact = `
    query userHasPermissionToContact ($reqId: String!, $contactId: String!) {
        userHasPermissionToContact(reqId: $reqId, contactId: $contactId)
    }
`;

export const sendgridTest = `
    query sendgridTest {
        sendgridTest
    }
`;

export const sendSendgridTemplateEmail = `
    query sendSendgridTemplateEmail ($template: String!, $personalizations: Personalizations!) {
        sendSendgridTemplateEmail(template: $template, personalizations: $personalizations)
    }
`;

export const getTenantPermissions = `
    query getTenantPermissions($tenantId: String!) {
        getTenantPermissions(tenantId: $tenantId) 
    }`;

export const getAllUserPermissionRecords = `
    query getAllUserPermissionRecords($tenantId: String!) {
        getAllUserPermissionRecords(tenantId: $tenantId) 
    }`;

export const getUserPermissionsRecord = `
    query getUserPermissionsRecord($tenantId: String!, $userId: String!) {
        getUserPermissionsRecord(tenantId: $tenantId, userId: $userId) 
    }`;

export const getUserDefaultRoutePreferenceQuery = `
    query getUserDefaultRoutePreference {
        getUserDefaultRoutePreference
    }
`;

export const createOutlookSubscription = `
    query createOutlookSubscription ($email: String!, $accessToken: String!) {
        createOutlookSubscription(email: $email, accessToken: $accessToken)
    }
`;

export const getOutlookRefreshToken = `
query getOutlookRefreshToken {
    getOutlookRefreshToken
}`;

export const getAlertLogs = `
query getAlertLogs ($startKey: String, $size: String, $filter: String, $startDate: String, $endDate: String){
    getAlertLogs  (startKey: $startKey, size: $size, filter: $filter, startDate: $startDate, endDate: $endDate)
}`;

export const getOutlookUserInfo = `
query getOutlookUserInfo ($accessToken: String!) {
    getOutlookUserInfo (accessToken: $accessToken) {
        displayName
        surname
        givenName
        id
        userPrincipalName
        businessPhones
        jobTitle
        mail
        mobilePhone
        officeLocation
        preferredLanguage
    }
}`;

export const getAlertSubscriptions = `
    query getAlertSubscriptions($opt: String) {
        getAlertSubscriptions(opt: $opt) 
        {
            id
            desc
            zipCode
            distance
            method
            userId
            firstName
            lastName
            userName
            alertLocation
            {
                lat
                lon
                desc
            }
            frequency
            agents            
            {
                id
                firstName
                lastName                
                agt_name
            }
            filters
            pulseFilters
            nmlsId
            lastRan
            lastUpdatedBy
            lastUpdated
            lastChecked


        }
    }`;

export const getAlertSubscription = `
    query getAlertSubscription($id: String!) {
        getAlertSubscription(id: $id) 
        {
            id
            desc
            zipCode
            distance
            method
            userId
            firstName
            lastName
            userName
            alertLocation
            {
                lat
                lon
                desc
            }
            frequency
            agents            
            {
                id
                firstName
                lastName                
                agt_name
            }
            filters
            pulseFilters
            nmlsId
            lastRan
            lastUpdatedBy
            lastUpdated
            lastChecked

        }
    }`;

export const getExclusions = `
    query getExclusions($opt: String) {
        getExclusions(opt: $opt) 
        {
            id           
            userId
            firstName
            lastName
            type
            reason           
            lastUpdatedBy
            lastUpdated  

        }
    }`;

export const getExclusion = `
    query getExclusion($id: String!) {
        getExclusion(id: $id) 
        {
            id           
            userId
            firstName
            lastName
            type
            reason           
            lastUpdatedBy
            lastUpdated  

        }
    }`;

export const getAlertDataRun = `
    query getAlertDataRun($alertData: String!) {
        getAlertDataRun(alertData: $alertData)         
    }`;

export const hubSpotUserAuthorized = `
    query hubSpotUserAuthorized {
        hubSpotUserAuthorized       
    }`;

export const hubSpotGet = `
    query hubSpotGet($callName: String!) {
        hubSpotGet(callName: $callName)         
    }`;

export const salesForceGet = `
    query salesForceGet($callName: String!) {
        salesForceGet(callName: $callName)         
    }`;

export const getProductTiers = `
    query getProductTiers {
        getProductTiers  
    }`;

export const getStripeSubscriptionInfo = `
    query getStripeSubscriptionInfo {
        getStripeSubscriptionInfo  

    }`;

export const isCognitoUser = `
    query isCognitoUser($username: String!) {
        isCognitoUser(username: $username)         
    }`;

export const getCognitoUserStatus = `
    query getCognitoUserStatus($email: String!) {
        getCognitoUserStatus(email: $email)         
    }`;

export const getStripeCustomerPortal = `
    query getStripeCustomerPortal($callbackURL: String!) {
        getStripeCustomerPortal(callbackURL: $callbackURL)         
    }`;

export const getUserContacts = `
    query getUserContacts($contactIds: String!) {
        getUserContacts(contactIds: $contactIds)         
    }`;

export const getTenantSelfServeRecord = `
    query getTenantSelfServeRecord($tenantId: String) {
        getTenantSelfServeRecord(tenantId: $tenantId)         
    }`;

export const getCurrentUserTier = `
    query getCurrentUserTier {
        getCurrentUserTier  
    }`;
