import { gql } from 'apollo-angular';

export const createCustomField = gql`
  mutation createCustomField($customField: CustomFieldInput!) {
    createCustomField(customField: $customField) {
      customFields {
        id
        name
        active
        type
        options {
          id
          active
          name
          order
        }
        value
      }
    }
  }
`;

export const updateCustomField = gql`
  mutation updateCustomField($customField: CustomFieldInput!) {
    updateCustomField(customField: $customField) {
      customFields {
        id
        name
        active
        type
        options {
          id
          active
          name
          order
        }
        value
      }
    }
  }
`;

export const updateCustomFieldsOnTenantRecord = gql`
  mutation updateCustomFieldsOnTenantRecord($customFields: [CustomFieldInput]!) {
    updateCustomFieldsOnTenantRecord(customFields: $customFields) {
      customFields {
        id
        name
        active
        type
        options {
          id
          active
          name
          order
        }
        value
      }
    }
  }
`;

export const updateContactCustomFields = gql`
  mutation updateContactCustomFields(
    $reqId: String!
    $contactId: String!
    $contactCustomFields: [CustomFieldContactInput]
  ) {
    updateContactCustomFields(reqId: $reqId, contactId: $contactId, contactCustomFields: $contactCustomFields)
  }
`;

export const deleteCustomField = gql`
  mutation deleteCustomField($customField: CustomFieldInput!, $restore: Boolean) {
    deleteCustomField(customField: $customField, restore: $restore) {
      customFields {
        id
      }
    }
  }
`;

export const deleteCustomFieldOption = gql`
  mutation deleteCustomFieldOption(
    $customField: CustomFieldInput!
    $option: CustomFieldOptionInput
    $restore: Boolean
  ) {
    deleteCustomFieldOption(customField: $customField, option: $option, restore: $restore) {
      customFields {
        id
      }
    }
  }
`;
