<ng-container *ngIf="isNoteOwner" class="dialogcontainer">
  <!-- <button mat-button class="deleteButton" color="warn">Delete this note</button>   -->

  <p
    mat-dialog-title
    [ngStyle]="{ color: '#4985a5', cursor: 'move' }"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    Viewing your note
    <small *ngIf="data.history.previousEdit">(edited)</small>
  </p>

  <mat-dialog-content>
    <mat-form-field appearance="fill" [ngStyle]="{ width: '100%' }">
      <mat-label>Edit Note</mat-label>
      <textarea
        matInput
        rows="8"
        [(ngModel)]="data.description"
        [mention]="mentionList"
        [mentionConfig]="{ triggerChar: '@', labelKey: 'name' }"
        (itemSelected)="handleClosed($event)"
        (keyup)="checkNoteLength(data.description)"
      ></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-accordion *ngIf="data.editMatch.length > 0">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> View Past Edits: </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>
      <p
        *ngFor="let match of data.editMatch"
        class="truncate"
        [matTooltip]="match.description"
        [matTooltipShowDelay]="700"
      >
        <i
          >{{ match.description }} <br />
          <small>-{{ match.completedDate | date: 'short' }}</small></i
        >
      </p>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- [(ngModel)]="data.description"  -->

  <mat-dialog-actions>
    <button mat-button color="warn" [mat-dialog-close]="true">Cancel</button>
    <button mat-button color="primary" [disabled]="!validNoteLength" [mat-dialog-close]="data" (click)="editNote(data)">
      Confirm
    </button>
  </mat-dialog-actions>
</ng-container>

<!-- if current user is not note originator -->
<ng-container *ngIf="!isNoteOwner">
  <p
    mat-dialog-title
    [ngStyle]="{ color: '#4985a5', cursor: 'move' }"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    Viewing {{ data.user.firstName }} {{ data.user.lastName }}'s note
    <small *ngIf="data.editMatch.length > 0">(edited)</small>
  </p>

  <mat-dialog-content>
    <p>
      <i>{{ data.description }}</i>
    </p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button color="primary" [mat-dialog-close]>Close</button>
  </mat-dialog-actions>
</ng-container>
