import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContactService as GlobalContactService } from 'app/services/contact.service';
import { map, take } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { UserContact } from 'app/shared/model/contacts';
import { NewSortOptions } from 'app/shared/model/filter';
import { UserService } from '../services/user.service';
import { WatchQueryFetchPolicy } from '@apollo/client/core';
import { CustomFieldsService } from 'app/services/custom-fields.service';
import { PermissionsService } from 'app/services/permissions.service';

@Injectable()
export class ContactsService {
  public contacts = new BehaviorSubject<UserContact[]>([]);
  public currentContacts = this.contacts.asObservable();
  private contactReqs = new BehaviorSubject<any[]>([]);
  public currentContactReqs = this.contactReqs.asObservable();
  public page = new BehaviorSubject<{
    page?: number;
    pageSize?: number;
    totalSize?: number;
  }>({});
  public currentPage = this.page.asObservable();
  public loading = new BehaviorSubject<boolean>(false);
  public isLoading = this.loading.asObservable();
  public selectedReqIdForCustomFields: string = undefined;
  private pagination = {
    page: 1,
    pageSize: parseInt(localStorage.getItem('pageSize')) || 25,
  };
  private sanitizedFilters = [];
  private sorting: NewSortOptions[] = [
    {
      field: 'lastUpdatedAt',
      options: {
        order: 'desc',
      },
    },
  ];
  public activeFilters = new BehaviorSubject<any[]>([]);
  public currentFilters = this.activeFilters.asObservable();
  private index: string | null = null;
  private debug: boolean = false;

  private tenantId: string = null;
  private userId: string = null;
  private limitContactsList: boolean = null;

  private readonly _showDeletedContactsOnlySource = new BehaviorSubject<boolean>(false);
  public readonly showDeletedContactsOnly = this._showDeletedContactsOnlySource.asObservable();

  constructor(
    private gcs: GlobalContactService,
    private utils: UtilsService,
    private userService: UserService,
    private customFieldsService: CustomFieldsService,
    private permissionsService: PermissionsService
  ) {}

  get getShowDeletedContactsOnly() {
    return this._showDeletedContactsOnlySource.getValue();
  }

  setShowDeletedContactsOnly(value: boolean) {
    this._showDeletedContactsOnlySource.next(value);
  }

  fetchContacts(params?: { index?: string; policy?: WatchQueryFetchPolicy }, reqStatus?: string) {
    this.loading.next(true);
    if (params?.index) this.index = params.index;
    if (this.limitContactsList) this.index = 'req_contacts';
    this.debug && console.log('fetchcontacts reqstatus', reqStatus);
    this.debug && console.log('sorting', this.sorting);
    this.gcs
      .filterUserContacts(
        this.pagination,
        this.sanitizedFilters,
        this.sorting,
        this.index,
        params?.policy,
        reqStatus,
        this.getShowDeletedContactsOnly
      ) //pass the index
      .valueChanges.pipe(take(1))
      .toPromise()
      .then(
        async (result) => {
          let contacts = structuredClone(result.data.filterUserContacts.contacts);
          if (this.index !== 'req_contacts') this.fetchContactReqs(contacts);

          if (this.index === 'req_contacts') {
            console.time('💧 Contacts hydrated with custom fields');
            // Hydrate the contacts with a custom field values if we have a reqId selected.
            contacts = await this.customFieldsService.appendCustomFieldValues(
              this.selectedReqIdForCustomFields,
              contacts
            );
            console.timeEnd('💧 Contacts hydrated with custom fields');
          }

          this.contacts.next(contacts);
          this.debug && console.log('contacts=', contacts);
          this.gcs.setCachedFilteredContacts(contacts);

          // console.log('***** nested contact service setCachedFilteredContacts called', contacts);

          this.page.next(result.data.filterUserContacts.page);
          this.loading.next(false);
          console.log('DONE LOADING CONTACTS');
        },
        (error) => {
          console.log('ContactsComponent: There was an error loading contacts ', error);
          this.loading.next(false);
        }
      );
  }

  updateContact(newContact) {
    this.currentContacts
      .pipe(take(1))
      .toPromise()
      .then((contacts) => {
        const oldContact = contacts.find((c) => c.id === newContact.ownerId + '|' + newContact.id);
        this.contacts.next([...contacts.splice(contacts.indexOf(oldContact), 1), newContact]);
      })
      .catch((error) => console.log(error));
  }

  async filterContacts(filters, params?: { index?: string; policy?: WatchQueryFetchPolicy }, reqStatus?: string) {
    if (!reqStatus) reqStatus = 'active';
    this.setShowDeletedContactsOnly(false);
    this.activeFilters.next(filters);
    this.utils.filters = filters;
    this.sanitizedFilters = await this.utils.sanitize();
    filters.forEach((filter) => {
      if (filter.key === 'reqId.keyword') {
        this.index = 'req_contacts';
      }
      // console.log('🚀 Test', filter.value.replace(/: /g, ':'));

      if (filter.value.replace(/: /g, ':').includes('active:false')) {
        this.setShowDeletedContactsOnly(true);

        let newFilter = filter.value.replace(/: /g, ':').split('active:false').join(' ');
        // console.log('🚀 ~ ContactsService ~ filters.forEach ~ newFilter', newFilter);
        filter.value = newFilter;
      }
    });

    this.activeFilters.next(filters);
    this.utils.filters = filters;
    this.sanitizedFilters = await this.utils.sanitize();
    this.debug && console.log('filtercontacts reqstatus', reqStatus);

    let limitContactsList = this.permissionsService.getCurrentPermissions().cms.contacts.limit_contacts;

    if (limitContactsList) {
      params?.index && (params.index = 'req_contacts');
    }

    this.fetchContacts(params || null, reqStatus);
  }

  fetchContactReqs(contacts) {
    this.contactReqs.next([]);
    contacts.forEach((c, i) => {
      if (!this.contactReqs.getValue().find((req) => req.contactId === c.contactId)) {
        this.gcs
          .getContactReqs(c.contactId)
          .pipe(take(1))
          .toPromise()
          .then((res) => this.contactReqs.next([...this.contactReqs.getValue(), ...res.data.getContactReqs]));
      }
    });
  }

  sort(field, direction, options?) {
    this.sorting = [
      {
        field: field,
        options: {
          order: direction ? 'asc' : 'desc',
          ...options,
        },
      },
    ];
    this.fetchContacts();
  }

  changePage(page) {
    this.pagination = page;
    this.fetchContacts();
  }
}
