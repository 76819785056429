import { Component, Inject, Input, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'mm-add-note-dialog',
  templateUrl: './add-note-dialog.component.html',
})
export class AddNoteDialogComponent implements OnInit {
  @Input() colorClass: string;

  note = new FormControl('', Validators.required);

  constructor(
    public dialogRef: MatDialogRef<AddNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  addNote() {
    this.dialogRef.close({ note: this.note.value });
  }

  onNoClick(): void {
    this.dialogRef.close({ note: null });
  }
}
