<div *ngIf="legacyTabLastXDaysOption">
  <span class="legacy-range-picker-item">
    <mat-form-field>
      <mat-select
        placeholder="Choose a Quick Pick"
        [(value)]="timeSelected"
        (selectionChange)="onTimeChange($event.value)"
      >
        <mat-option *ngFor="let time of timeOptions" [value]="time.value">{{ time.title }}</mat-option>
      </mat-select>
    </mat-form-field>
  </span>
  <span class="legacy-range-picker-item">or</span>
  <span class="legacy-range-picker-item">
    <mat-form-field>
      <input
        matInput
        placeholder="Choose a date range"
        [satDatepicker]="resultPickerLegacyTab"
        (dateChange)="onDateChangeLegacyTab($event.value)"
        [(ngModel)]="dateRangeFilter"
      />
      <sat-datepicker #resultPickerLegacyTab [rangeMode]="true"></sat-datepicker>
      <sat-datepicker-toggle matSuffix [for]="resultPickerLegacyTab"></sat-datepicker-toggle>
    </mat-form-field>
  </span>
</div>

<div class="toggle-container">
  <div class="admin-actions" *ngIf="!isTenantAdmin || !isTenantLeader">
    <button
      mat-raised-button
      type="button"
      color="primary"
      data-testid="productivity-add-user-to-team-button"
      (click)="addingUsers = !addingUsers"
    >
      Add User to Team
    </button>
  </div>
  <div target *ngIf="addingUsers" class="assign-users">
    <mm-add-user-to-req-popover
      (closePopover)="addingUsers = false"
      [reqId]="reqId"
      [tenantId]="tenantId"
      [contact]="selectedRows"
      [currentUsers]="currentUsers"
    ></mm-add-user-to-req-popover>
  </div>
  <mat-button-toggle-group name="productivityViewToggle" aria-label="Productivity View Toggle" class="pill-toggle">
    <mat-button-toggle value="card" checked (click)="setRequisitionView(false)">
      <mat-icon>view_module</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="list" (click)="setRequisitionView(true)">
      <mat-icon>notes</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
<mat-table *ngIf="displayAsTable" [dataSource]="productivityTableSource" matSort class="productivity-table">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Name" [matTooltipShowDelay]="700"
      >Name
    </mat-header-cell>
    <mat-cell
      class="nameCell"
      *matCellDef="let user"
      [matTooltip]="user.firstName + ' ' + user.lastName"
      [matTooltipShowDelay]="700"
    >
      <span class="avatarWrapper">
        <img
          class="avatarImg"
          *ngIf="userAvatarUrls[user.id]"
          [src]="userAvatarUrls[user.id]"
          onerror="this.src='/assets/images/contact_placeholder_small.png'"
        />
        <img class="avatarImg" *ngIf="!userAvatarUrls[user.id]" src="/assets/images/contact_placeholder_small.png" />
      </span>
      <span class="nameWrapper">{{ user.firstName | titlecase }} {{ user.lastName | titlecase }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="notes-added">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Notes Added" [matTooltipShowDelay]="700">
      Notes Added</mat-header-cell
    >
    <mat-cell *matCellDef="let user">
      <div>{{ user.productivity.notesAdded }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="todos-opened">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="To-Do's Opened" [matTooltipShowDelay]="700">
      To-Do's Opened</mat-header-cell
    >
    <mat-cell *matCellDef="let user">
      <div>{{ user.productivity.todosOpened }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="todos-completed">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="To-Do's Completed" [matTooltipShowDelay]="700">
      To-Do's Completed</mat-header-cell
    >
    <mat-cell *matCellDef="let user">
      <div>{{ user.productivity.todosCompleted }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="meetings-scheduled">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Meetings Scheduled" [matTooltipShowDelay]="700">
      Meetings Scheduled</mat-header-cell
    >
    <mat-cell *matCellDef="let user">
      <div>{{ user.productivity.meetingsScheduled }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="meetings-completed">
    <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Meetings Completed" [matTooltipShowDelay]="700">
      Meetings Completed</mat-header-cell
    >
    <mat-cell *matCellDef="let user">
      <div>{{ user.productivity.meetingsCompleted }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="remove-user">
    <mat-header-cell style="flex: none; width: 30px" *matHeaderCellDef></mat-header-cell>
    <mat-cell style="flex: none; width: 20px" *matCellDef="let user">
      <div>
        <mat-icon
          *ngIf="currentUserId == reqOwnerId && currentUserId != user.id && reqId"
          matTooltip="Remove user from Pipeline"
          [matTooltipShowDelay]="700"
          class="close-btn-list"
          (click)="removeUserFromReq(user)"
          >delete_forever</mat-icon
        >
        <mat-icon
          *ngIf="currentUserId === user.id && currentUserId !== reqOwnerId && reqId"
          matTooltip="Remove yourself from this requisition"
          [matTooltipShowDelay]="700"
          class="close-btn-list"
          (click)="removeSelfFromReq(user)"
          >delete_forever</mat-icon
        >
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedProductivityColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedProductivityColumns"></mat-row>
</mat-table>

<div *ngIf="!displayAsTable" class="card-container productivity-card-container">
  <mat-card *ngFor="let user of productivity" class="userCard">
    <mat-icon
      *ngIf="currentUserId == reqOwnerId && currentUserId != user.id && reqId"
      matTooltip="Remove user from Pipeline"
      [matTooltipShowDelay]="700"
      class="close-btn"
      (click)="removeUserFromReq(user)"
      >delete_forever</mat-icon
    >

    <!-- if not req owner, remove self from req -->
    <mat-icon
      *ngIf="currentUserId === user.id && currentUserId !== reqOwnerId && reqId"
      matTooltip="Remove yourself from this requisition"
      [matTooltipShowDelay]="700"
      class="close-btn"
      (click)="removeSelfFromReq(user)"
      >delete_forever</mat-icon
    >

    <div class="userAvatarContainer">
      <img
        class="userAvatar"
        *ngIf="userAvatarUrls[user.id]"
        alt="User Avatar"
        [src]="userAvatarUrls[user.id]"
        onerror="this.src='/assets/images/contact_placeholder_small.png'"
      />
      <img
        class="userAvatar"
        *ngIf="!userAvatarUrls[user.id]"
        src="/assets/images/contact_placeholder.png"
        alt="User Avatar"
      />
    </div>
    <mat-card-header>
      <mat-card-title>{{ user.firstName | titlecase }} {{ user.lastName | titlecase }}</mat-card-title>
      <mat-card-subtitle>{{ user.email }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="mat-body-1" style="margin-top: 20px">
      <div class="statistic">
        <label>Notes Added</label>
        <span class="value">{{ user.productivity.notesAdded }}</span>
      </div>
      <div class="statistic">
        <label>To-Do's Opened</label>
        <span class="value">{{ user.productivity.todosOpened }}</span>
      </div>
      <div class="statistic">
        <label>To-Do's Completed</label>
        <span class="value">{{ user.productivity.todosCompleted }}</span>
      </div>
      <div class="statistic">
        <label>Meetings Scheduled</label>
        <span class="value">{{ user.productivity.meetingsScheduled }}</span>
      </div>
      <div class="statistic">
        <label>Meetings Completed</label>
        <span class="value">{{ user.productivity.meetingsCompleted }}</span>
      </div>
    </mat-card-content>
  </mat-card>
</div>
