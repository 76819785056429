import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  NgModule,
  OnChanges,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { SpinnerComponent } from './spinner.component';

type Colors = 'blue' | 'indigo' | 'light' | 'dark' | 'purple';
type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

@Directive({
  selector: '[mm-button]',
})
export class ModelMatchPrimaryButtonDirective implements AfterViewInit, OnChanges {
  @Input() full: boolean = false;
  @Input() color: Colors = 'blue';
  @Input() size: Size = 'md';
  @Input() danger: boolean = false;
  @Input() warning: boolean = false;
  @Input() success: boolean | string = false;
  @Input() loading: boolean = false;
  @Input() borderRadius: string = 'rounded-lg';
  @Input() shadow: string = 'shadow-sm';
  @Input() padding: string = 'px-3.5 py-2';
  @Input() display: string = 'flex';
  @Input() alignment: string = 'items-center';
  @Input() spacing: string = 'space-x-2';
  @Input() square: boolean = false;
  @Input() kbd: string;
  @ContentChild('iconStart', { static: false }) iconStart: TemplateRef<any>;
  @ContentChild('iconEnd', { static: false }) iconEnd: TemplateRef<any>;

  private sizeMap = {
    xs: 16.5,
    sm: 16.5,
    md: 18,
    lg: 18,
    xl: 22,
  };
  constructor(
    private hostEl: ElementRef,
    private _viewContainerRef: ViewContainerRef
  ) {}

  ngAfterViewInit() {
    if (this.iconStart) {
      let embeddedViewRef = this._viewContainerRef.createEmbeddedView(this.iconStart);
      for (let node of embeddedViewRef.rootNodes) {
        node.style.width = `${this.sizeMap[this.size]}px`;
        node.style.height = `${this.sizeMap[this.size]}px`;
        this.hostEl.nativeElement.prepend(node);
      }
    }

    if (this.iconEnd) {
      let embeddedViewRef = this._viewContainerRef.createEmbeddedView(this.iconEnd);
      for (let node of embeddedViewRef.rootNodes) {
        node.style.width = `${this.sizeMap[this.size]}px`;
        node.style.height = `${this.sizeMap[this.size]}px`;
        this.hostEl.nativeElement.append(node);
      }
    }

    if (this.loading) this.startLoadingSpinner();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.loading &&
      changes.loading.currentValue !== changes.loading.previousValue &&
      changes.loading.currentValue
    ) {
      this.startLoadingSpinner();
    }

    if (
      changes.loading &&
      changes.loading.currentValue !== changes.loading.previousValue &&
      !changes.loading.currentValue
    ) {
      this.stopLoadingSpinner();
    }

    if (
      changes.success &&
      changes.success.currentValue !== changes.success.previousValue &&
      changes.success.currentValue
    ) {
      this.displaySuccess();
    }
  }

  displaySuccess() {
    // this.hostEl.nativeElement.disabled = false;
    this.hostEl.nativeElement.classList.add('relative', 'justify-center');

    let html = `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
    </svg>
    `;
  }

  startLoadingSpinner() {
    // this.hostEl.nativeElement.disabled = true;
    this.hostEl.nativeElement.classList.add('relative', 'justify-center', 'disabled_cursor-wait');
    this.hostEl.nativeElement.classList.remove('disabled_cursor-not-allowed');
    let componentRef = this._viewContainerRef.createComponent<SpinnerComponent>(SpinnerComponent);
    componentRef.instance.size = this.size;
    componentRef.instance.class = `${
      this.color === 'light' ? 'text-slate-500' : 'text-slate-50'
    } absolute spinner-element`;

    let spinnerEl = componentRef.location.nativeElement.childNodes[0];

    for (let node of this.hostEl.nativeElement.childNodes) {
      if (node?.style) node.style.opacity = 0;
    }

    this.hostEl.nativeElement.prepend(spinnerEl);
  }

  stopLoadingSpinner() {
    // this.hostEl.nativeElement.disabled = false;
    this.hostEl.nativeElement.classList.remove('relative', 'justify-center', 'disabled_cursor-wait');
    this.hostEl.nativeElement.classList.add('disabled_cursor-not-allowed');
    let spinnerEl = this.hostEl.nativeElement.querySelector('.spinner-element');
    if (spinnerEl) {
      spinnerEl.remove();
    }

    for (let node of this.hostEl.nativeElement.childNodes) {
      if (node?.style) node.style.opacity = 1;
    }
  }

  @HostBinding('class') get classes(): string {
    let colorClasses = this.getColor();
    let sizeClasses = this.getSize();
    return `
      ${colorClasses.color}
      ${colorClasses.ring}
      ${this.display}
      ${this.alignment}
      ${sizeClasses.text}
      ${sizeClasses.gap}
      ${this.borderRadius}
      ${this.shadow}
      ${sizeClasses.padding}
      cursor-pointer
      disabled_cursor-not-allowed
      focus_outline-none
      whitespace-nowrap
      ${this.full ? 'w-full' : ''}
    `;
  }

  getSize(): { text: string; gap: string; padding: string } {
    switch (this.size) {
      case 'xs':
        return {
          text: 'text-sm',
          gap: 'space-x-2',
          padding: this.square ? 'p-1' : 'px-3.5 py-2',
        };
      case 'sm':
        return {
          text: 'text-sm',
          gap: 'space-x-2',
          padding: this.square ? 'p-2' : 'px-4 py-2',
        };
      case 'md':
        return {
          text: 'text',
          gap: 'space-x-2',
          padding: this.square ? 'p-2.5' : 'px-4.5 py-2.5',
        };
      case 'lg':
        return {
          text: 'text',
          gap: 'space-x-2',
          padding: this.square ? 'p-3' : 'px-5 py-3',
        };
      case 'xl':
        return {
          text: 'text-lg',
          gap: 'space-x-2',
          padding: this.square ? 'p-3.5' : 'px-5.5 py-3.5',
        };
    }
  }

  getColor(): { color: string; ring: string } {
    const colorMap = {
      blue: {
        bg: {
          hover: 'hover_bg-blue-600',
          active: 'active_bg-blue-700',
          default: 'bg-blue-500',
          disabled: 'disabled_bg-blue-300',
        },
        border: {
          hover: 'hover_border-blue-600',
          active: 'active_border-blue-700',
          default: 'border border-blue-500',
          disabled: 'disabled_border-blue-300',
        },
        text: {
          hover: 'hover_text-white',
          active: 'active_text-white',
          default: 'text-white',
          disabled: 'disabled_text-blue-50',
        },
      },
      indigo: {
        bg: {
          hover: 'hover_bg-indigo-600',
          active: 'active_bg-indigo-700',
          default: 'bg-indigo-500',
          disabled: 'disabled_bg-indigo-300',
        },
        border: {
          hover: 'hover_border-indigo-600',
          active: 'active_border-indigo-700',
          default: 'border border-indigo-500',
          disabled: 'disabled_border-indigo-300',
        },
        text: {
          hover: 'hover_text-white',
          active: 'active_text-white',
          default: 'text-white',
          disabled: 'disabled_text-indigo-50',
        },
      },
      purple: {
        bg: {
          hover: 'hover_bg-purple-600',
          active: 'active_bg-purple-700',
          default: 'bg-purple-500',
          disabled: 'disabled_bg-purple-300',
        },
        border: {
          hover: 'hover_border-purple-600',
          active: 'active_border-purple-700',
          default: 'border border-purple-500',
          disabled: 'disabled_border-purple-300',
        },
        text: {
          hover: 'hover_text-white',
          active: 'active_text-white',
          default: 'text-white',
          disabled: 'disabled_text-purple-50',
        },
      },
      light: {
        bg: {
          hover: 'hover_bg-slate-50',
          active: 'active_bg-slate-100',
          default: 'bg-white',
          disabled: 'disabled_bg-slate-100',
        },
        border: {
          hover: 'hover_slate-300',
          active: 'active_slate-300',
          default: 'border border-slate-300',
          disabled: 'disabled_border-slate-300',
        },
        text: {
          hover: 'hover_text-slate-900',
          active: 'active_text-slate-900',
          default: 'text-slate-900',
          disabled: 'disabled_text-slate-500',
        },
      },
      danger: {
        bg: {
          hover: 'hover_bg-red-600',
          active: 'active_bg-red-700',
          default: 'bg-red-500',
          disabled: 'disabled_bg-red-300',
        },
        border: {
          hover: 'hover_border-red-600',
          active: 'active_border-red-700',
          default: 'border border-red-500',
          disabled: 'disabled_border-red-300',
        },
        text: {
          hover: 'hover_text-white',
          active: 'active_text-white',
          default: 'text-white',
          disabled: 'disabled_text-red-50',
        },
      },
    };

    if (this.danger) {
      return {
        color: Object.keys(colorMap.danger)
          .map((key) =>
            Object.keys(colorMap.danger[key])
              .map((key2) => colorMap.danger[key][key2])
              .join(' ')
          )
          .join(' '),
        ring: 'focus_ring-2 focus_ring-red-300',
      };
    }

    if (this.color) {
      switch (this.color) {
        case 'blue':
          return {
            color: Object.keys(colorMap.blue)
              .map((key) =>
                Object.keys(colorMap.blue[key])
                  .map((key2) => colorMap.blue[key][key2])
                  .join(' ')
              )
              .join(' '),
            ring: 'focus_ring-2 focus_ring-blue-300',
          };
        case 'indigo':
          return {
            color: Object.keys(colorMap.indigo)
              .map((key) =>
                Object.keys(colorMap.indigo[key])
                  .map((key2) => colorMap.indigo[key][key2])
                  .join(' ')
              )
              .join(' '),
            ring: 'focus_ring-2 focus_ring-indigo-300',
          };
        case 'purple':
          return {
            color: Object.keys(colorMap.purple)
              .map((key) =>
                Object.keys(colorMap.purple[key])
                  .map((key2) => colorMap.purple[key][key2])
                  .join(' ')
              )
              .join(' '),
            ring: 'focus_ring-2 focus_ring-purple-300',
          };
        case 'light':
          return {
            color: Object.keys(colorMap.light)
              .map((key) =>
                Object.keys(colorMap.light[key])
                  .map((key2) => colorMap.light[key][key2])
                  .join(' ')
              )
              .join(' '),
            ring: 'focus_ring-2 focus_ring-blue-300',
          };
        case 'dark':
          return {
            color: Object.keys(colorMap.blue)
              .map((key) =>
                Object.keys(colorMap.blue[key])
                  .map((key2) => colorMap.blue[key][key2])
                  .join(' ')
              )
              .join(' '),
            ring: 'focus_ring-2 focus_ring-slate-600',
          };
      }
    }
  }
}

@Directive({
  selector: '[mm-button-secondary]',
})
export class ModelMatchSecondaryButtonDirective {
  @Input() full: boolean = false;
  @Input() color: Colors = 'blue';
  @Input() size: Size = 'md';
  @Input() danger: boolean = false;
  @Input() warning: boolean = false;
  @Input() success: boolean | string = false;
  @Input() loading: boolean = false;
  @Input() borderRadius: string = 'rounded-lg';
  @Input() shadow: string = 'shadow-sm';
  @Input() padding: string = 'px-3.5 py-2';
  @Input() display: string = 'flex';
  @Input() alignment: string = 'items-center';
  @Input() spacing: string = 'space-x-2';
  @Input() square: boolean = false;
  @Input() kbd: string;
  @ContentChild('iconStart', { static: false }) iconStart: TemplateRef<any>;
  @ContentChild('iconEnd', { static: false }) iconEnd: TemplateRef<any>;

  private sizeMap = {
    xs: 16.5,
    sm: 16.5,
    md: 18,
    lg: 18,
    xl: 22,
  };
  constructor(
    private hostEl: ElementRef,
    private _viewContainerRef: ViewContainerRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    if (this.iconStart) {
      let embeddedViewRef = this._viewContainerRef.createEmbeddedView(this.iconStart);
      for (let node of embeddedViewRef.rootNodes) {
        node.style.width = `${this.sizeMap[this.size]}px`;
        node.style.height = `${this.sizeMap[this.size]}px`;
        this.hostEl.nativeElement.prepend(node);
      }
    }

    if (this.iconEnd) {
      let embeddedViewRef = this._viewContainerRef.createEmbeddedView(this.iconEnd);
      for (let node of embeddedViewRef.rootNodes) {
        node.style.width = `${this.sizeMap[this.size]}px`;
        node.style.height = `${this.sizeMap[this.size]}px`;
        this.hostEl.nativeElement.append(node);
      }
    }

    if (this.loading) this.startLoadingSpinner();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.loading &&
      changes.loading.currentValue !== changes.loading.previousValue &&
      changes.loading.currentValue
    ) {
      this.startLoadingSpinner();
    }

    if (
      changes.loading &&
      changes.loading.currentValue !== changes.loading.previousValue &&
      !changes.loading.currentValue
    ) {
      this.stopLoadingSpinner();
    }

    if (
      changes.success &&
      changes.success.currentValue !== changes.success.previousValue &&
      changes.success.currentValue
    ) {
      this.displaySuccess();
    }
  }

  displaySuccess() {
    // this.hostEl.nativeElement.disabled = false;
    this.hostEl.nativeElement.classList.add('relative', 'justify-center');

    let html = `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
    </svg>
    `;
  }

  startLoadingSpinner() {
    // this.hostEl.nativeElement.disabled = true;
    this.hostEl.nativeElement.classList.add('relative', 'justify-center', 'disabled_cursor-wait');
    this.hostEl.nativeElement.classList.remove('disabled_cursor-not-allowed');
    let componentRef = this._viewContainerRef.createComponent<SpinnerComponent>(SpinnerComponent);
    componentRef.instance.size = this.size;
    componentRef.instance.class = `
      ${this.color === 'light' ? 'text-slate-700' : this.color === 'blue' ? 'text-blue-700' : 'text-indigo-700'} 
      absolute spinner-element ${this.danger ? 'text-red-700' : ''}
    `;

    let spinnerEl = componentRef.location.nativeElement.childNodes[0];

    for (let node of this.hostEl.nativeElement.childNodes) {
      if (node?.style) node.style.opacity = 0;
    }

    this.hostEl.nativeElement.prepend(spinnerEl);
  }

  stopLoadingSpinner() {
    // this.hostEl.nativeElement.disabled = false;
    this.hostEl.nativeElement.classList.remove('relative', 'justify-center', 'disabled_cursor-wait');
    this.hostEl.nativeElement.classList.add('disabled_cursor-not-allowed');
    let spinnerEl = this.hostEl.nativeElement.querySelector('.spinner-element');
    if (spinnerEl) {
      spinnerEl.remove();
    }

    for (let node of this.hostEl.nativeElement.childNodes) {
      if (node?.style) node.style.opacity = 1;
    }
  }

  @HostBinding('class') get classes(): string {
    let colorClasses = this.getColor();
    let sizeClasses = this.getSize();
    return `
      ${colorClasses.color}
      ${colorClasses.ring}
      ${this.display}
      ${this.alignment}
      ${sizeClasses.text}
      ${sizeClasses.gap}
      ${this.borderRadius}
      ${sizeClasses.padding}
      cursor-pointer
      disabled_cursor-not-allowed
      focus_outline-none
      whitespace-nowrap
      ${this.full ? 'w-full' : ''}
    `;
  }

  getSize(): { text: string; gap: string; padding: string } {
    switch (this.size) {
      case 'xs':
        return {
          text: 'text-sm',
          gap: 'space-x-2',
          padding: this.square ? 'p-1' : 'px-3.5 py-2',
        };
      case 'sm':
        return {
          text: 'text-sm',
          gap: 'space-x-2',
          padding: this.square ? 'p-2' : 'px-4 py-2.5',
        };
      case 'md':
        return {
          text: 'text-base',
          gap: 'space-x-2',
          padding: this.square ? 'p-2.5' : 'px-4.5 py-2.5',
        };
      case 'lg':
        return {
          text: 'text-base',
          gap: 'space-x-2',
          padding: this.square ? 'p-3' : 'px-5 py-3',
        };
      case 'xl':
        return {
          text: 'text-lg',
          gap: 'space-x-2',
          padding: this.square ? 'p-3.5' : 'px-5.5 py-3.5',
        };
    }
  }

  getColor(): { color: string; ring: string } {
    const colorMap = {
      blue: {
        bg: {
          hover: 'hover_bg-blue-100',
          active: 'active_bg-blue-200',
          default: 'bg-blue-50',
          disabled: 'disabled_bg-blue-50',
        },
        border: {
          hover: 'hover_border-blue-100',
          active: 'active_border-blue-200',
          default: 'border border-blue-50',
          disabled: 'disabled_border-blue-50',
        },
        text: {
          hover: 'hover_text-blue-700',
          active: 'active_text-blue-700',
          default: 'text-blue-700',
          disabled: 'disabled_text-blue-300',
        },
      },
      indigo: {
        bg: {
          hover: 'hover_bg-indigo-100',
          active: 'active_bg-indigo-200',
          default: 'bg-indigo-50',
          disabled: 'disabled_bg-indigo-50',
        },
        border: {
          hover: 'hover_border-indigo-100',
          active: 'active_border-indigo-200',
          default: 'border border-indigo-50',
          disabled: 'disabled_border-indigo-50',
        },
        text: {
          hover: 'hover_text-indigo-700',
          active: 'active_text-indigo-700',
          default: 'text-indigo-700',
          disabled: 'disabled_text-indigo-300',
        },
      },
      light: {
        bg: {
          hover: 'hover_bg-slate-50',
          active: 'active_bg-slate-100',
          default: 'bg-white',
          disabled: 'disabled_bg-slate-100',
        },
        border: {
          hover: 'hover_border-slate-50',
          active: 'active_slate-300',
          default: 'border border-transparent',
          disabled: 'disabled_border-slate-100',
        },
        text: {
          hover: 'hover_text-slate-900',
          active: 'active_text-slate-900',
          default: 'text-slate-900',
          disabled: 'disabled_text-slate-500',
        },
      },
      danger: {
        bg: {
          hover: 'hover_bg-red-100',
          active: 'active_bg-red-200',
          default: 'bg-red-50',
          disabled: 'disabled_bg-red-50',
        },
        border: {
          hover: 'hover_border-red-100',
          active: 'active_border-red-200',
          default: 'border border-red-50',
          disabled: 'disabled_border-red-50',
        },
        text: {
          hover: 'hover_text-red-700',
          active: 'active_text-red-700',
          default: 'text-red-700',
          disabled: 'disabled_text-red-300',
        },
      },
    };

    if (this.danger) {
      return {
        color: Object.keys(colorMap.danger)
          .map((key) =>
            Object.keys(colorMap.danger[key])
              .map((key2) => colorMap.danger[key][key2])
              .join(' ')
          )
          .join(' '),
        ring: 'focus_ring-2 focus_ring-red-300',
      };
    }

    if (this.color) {
      switch (this.color) {
        case 'blue':
          return {
            color: Object.keys(colorMap.blue)
              .map((key) =>
                Object.keys(colorMap.blue[key])
                  .map((key2) => colorMap.blue[key][key2])
                  .join(' ')
              )
              .join(' '),
            ring: 'focus_ring-2 focus_ring-blue-300',
          };
        case 'indigo':
          return {
            color: Object.keys(colorMap.indigo)
              .map((key) =>
                Object.keys(colorMap.indigo[key])
                  .map((key2) => colorMap.indigo[key][key2])
                  .join(' ')
              )
              .join(' '),
            ring: 'focus_ring-2 focus_ring-indigo-300',
          };
        case 'light':
          return {
            color: Object.keys(colorMap.light)
              .map((key) =>
                Object.keys(colorMap.light[key])
                  .map((key2) => colorMap.light[key][key2])
                  .join(' ')
              )
              .join(' '),
            ring: 'focus_ring-2 focus_ring-blue-300',
          };
        case 'dark':
          return {
            color: Object.keys(colorMap.blue)
              .map((key) =>
                Object.keys(colorMap.blue[key])
                  .map((key2) => colorMap.blue[key][key2])
                  .join(' ')
              )
              .join(' '),
            ring: 'focus_ring-2 focus_ring-slate-600',
          };
      }
    }
  }
}

@Directive({
  selector: '[mm-button-link]',
})
export class ModelMatchTertiaryButtonDirective {
  @Input() color: Colors;
}

@NgModule({
  declarations: [
    ModelMatchPrimaryButtonDirective,
    ModelMatchSecondaryButtonDirective,
    ModelMatchTertiaryButtonDirective,
  ],
  exports: [ModelMatchPrimaryButtonDirective, ModelMatchSecondaryButtonDirective, ModelMatchTertiaryButtonDirective],
})
export class ModelMatchButtonModule {}
