<h1 mat-dialog-title>Share Report To User</h1>
<mat-dialog-content>
  <form [formGroup]="shareToUserFormGroup">
    <mat-form-field>
      <mat-select placeholder="User" formControlName="userId">
        <mat-option *ngFor="let tenantUser of tenantUsers" [value]="tenantUser.id">
          {{ tenantUser.firstName }} {{ tenantUser.lastName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="warn" [mat-dialog-close]="'cancelled'">Cancel</button>
  <button mat-button color="primary" [disabled]="!shareToUserFormGroup.valid" (click)="onShare($event)">Share</button>
</mat-dialog-actions>
