import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { addToZapierTriggerQueue } from 'app/graphql/mutations';
import { ZapierTriggerBody } from 'app/shared/model/zapier';

@Injectable()
export class ZapierService {
  constructor(private apollo: Apollo) {}

  addToZapierTriggerQueue(triggerName: string, zapierTriggerBody: ZapierTriggerBody) {
    return this.apollo.mutate<any>({
      mutation: gql(addToZapierTriggerQueue),
      variables: {
        triggerName,
        zapierTriggerBody,
      },
    });
  }
}
