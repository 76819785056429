import { Component, ViewChild, Inject, OnInit, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageTransform, Dimensions } from 'ngx-image-cropper';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';

@Component({
  selector: 'mm-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent {
  @ViewChild('imageUrlInput', { static: true }) imageUrlInput: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  transform: ImageTransform = {};
  fileName: string = '';
  validUrlRegex: any =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  imageUrl: string = '';
  useImageUrl: boolean = false;

  ngOnInit() {
    fromEvent(this.imageUrlInput.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // if input is a valid url
        filter((res) => this.validUrlRegex.test(res)),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((url) => {
        this.imageUrl = url;
        this.imageChangedEvent = { isTrusted: true };
        this.fileName = 'webImage';
        this.canvasRotation = 0;
        this.rotation = 0;
      });
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  fileChangeEvent(event: any): void {
    this.canvasRotation = 0;
    this.rotation = 0;
    if (event.target?.files?.length) {
      this.imageUrl = '';
      this.imageChangedEvent = event;
      this.fileName = event.target?.files[0].name;
      console.log(event.target?.files[0].name);
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }

  handleUpload() {
    const convertedImgFile = this.base64ToFile(this.croppedImage);
    this.dialogRef.close({ convertedImgFile, name: this.fileName });
  }

  handleReset() {
    this.canvasRotation = 0;
    this.rotation = 0;
    this.imageChangedEvent = null;
    this.fileName = '';
    this.imageUrl = '';
    this.croppedImage = '';
    this.imageUrlInput.nativeElement.value = '';
  }
}
