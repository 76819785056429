import {
  Component,
  Inject,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { debounceTime, map, pairwise, startWith, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { Option, Page } from '../model/filter';
import { User } from '../model/user';
import {
  ActivityDurationOption,
  ActivityTimeOption,
  ActivityType,
  ActivityTypeOption,
  Participant,
  UpdateTodoInput,
} from '../model/activity';
import { Tenant } from '../model/tenant';
import { TodoService } from '../../services/todo.service';
import { UserService } from '../../services/user.service';
import { Contact, UserContact, ReqContact } from '../model/contacts';
import { ContactService } from '../../services/contact.service';
import { TenantService } from '../../services/tenant.service';
import { RequisitionService } from '../../services/requisition.service';
import { ImageCachingService } from '../../services/imagecaching.service';
import { UserReq, Requisition } from '../model/requisitions';
import { filterMyContactsForSelect, filterReqContactsForSelect } from 'app/graphql/queries';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertDialogComponent, AlertDialogConfig } from '../dialogs/alert-dialog.component';
import { SendgridService } from 'app/services/sendgrid.service';

@Component({
  selector: 'mm-create-activity-popover',
  templateUrl: './create-activity-popover.component.html',
  styleUrls: ['./create-activity-popover.component.scss'],
})
export class CreateActivityPopoverComponent implements OnInit, OnDestroy {
  @Input() viewOnly: boolean;
  @Output() closeCreateTodo: EventEmitter<boolean> = new EventEmitter();
  @Output() closeEditTodo: EventEmitter<boolean> = new EventEmitter();
  @Output() completeTodo: EventEmitter<boolean> = new EventEmitter();

  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  readonlyReq: boolean = false;
  // type: ActivityType;
  // customType: string;
  title: string;
  description: string;
  dueDate: Date;
  participants: Participant[];
  requisition: string;
  completedDate: Date;
  nextRecurringDate: Date = null;

  startDate = new Date();

  readonly typeOptions: Option[] = ActivityTypeOption;
  readonly durationOptions: Option[] = ActivityDurationOption;
  readonly timeOptions: Option[] = ActivityTimeOption;

  createActivityForm: FormGroup;
  typeFormControl = new FormControl('', Validators.required);
  titleFormControl = new FormControl('', Validators.required);
  descriptionFormControl = new FormControl();
  tenantFormControl = new FormControl();
  reqFormControl = new FormControl();
  assigneesFormControl = new FormControl('', Validators.required);
  contactsFormControl = new FormControl();
  dueOnFormControl = new FormControl('', Validators.required);
  timeFormControl = new FormControl();
  durationFormControl = new FormControl();
  phoneFormControl = new FormControl();
  locationFormControl = new FormControl();
  sendInviteFormControl = new FormControl(false);
  recurringFrequencyFormControl = new FormControl('Does not repeat');
  customRecurringNumFormControl = new FormControl(1);
  customRecurringFrequencyFormControl = new FormControl('Days');

  tenants: Tenant[];
  filteredTenants: Observable<Tenant[]>;

  usersReqs: UserReq[];
  reqOptions: Option[] = [{ value: '', title: '' }];

  assignees: User[] = [];
  filteredAssignees: Observable<User[]>;
  allAssignees: User[] = [];

  contacts: UserContact[] = [];
  filteredContactsBatch = new BehaviorSubject<{ id: string; firstName: string; lastName: string }[]>([]);
  filteredContacts = this.filteredContactsBatch.asObservable();
  allContacts: UserContact[] & ReqContact[] = [];
  contactsToAddToReq: UserContact[] = [];

  authenticatedUserInfo: any = null;
  userInfoSub: Subscription;
  routeSub: Subscription;
  currentUser: any;
  getMyReqsSub: Subscription;
  getMyTenantsSub: Subscription;
  contactFromSubscription: Subscription;

  userTenant: Tenant;
  reqId: string;
  contactId: string;
  currentUserId: string;
  canDelete: boolean = false;
  currentContact: Contact;
  avatarUrls: any = {};
  filteredContactsInput: string;
  filteredContactsPageSize = 50;

  reqStream: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  prevChosenReq: string = null;
  todo: any;

  recurringOptions: string[] = ['Does not repeat', 'Daily', 'Weekly', 'Monthly', 'Custom'];

  customRecurringFrequencyOptions: string[] = ['Days', 'Weeks', 'Months'];
  warnUserMonthlyRecurring: boolean = false;

  @ViewChild('assigneeInput', { static: false }) assigneeInput: ElementRef<HTMLInputElement>;
  @ViewChild('contactInput', { static: false }) contactInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoAssignee', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('autoContact') associatedContactsAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  private filterMyContactsSub: Subscription;
  private filterReqContactsSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<CreateActivityPopoverComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private todoService: TodoService,
    private userService: UserService,
    private contactService: ContactService,
    private tenantService: TenantService,
    private route: ActivatedRoute,
    private requisitionService: RequisitionService,
    private imageCachingService: ImageCachingService,
    private sendgridService: SendgridService
  ) {}

  ngOnInit() {
    this.createActivityForm = this.formBuilder.group({
      title: this.titleFormControl,
      description: this.descriptionFormControl,
      tenant: this.tenantFormControl,
      req: this.reqFormControl,
      assignees: this.assigneesFormControl,
      contacts: this.contactsFormControl,
      dueOn: this.dueOnFormControl,
      time: this.timeFormControl,
      duration: this.durationFormControl,
      phone: this.phoneFormControl,
      location: this.locationFormControl,
      sendInvite: this.sendInviteFormControl,
      recurringFrequency: this.recurringFrequencyFormControl,
      customRecurringNum: this.customRecurringNumFormControl,
      customRecurringFrequency: this.customRecurringFrequencyFormControl,
    });

    this.routeSub = this.route.params.subscribe((params) => {
      if (Object.keys(params).length === 0) this.adjustStyleForActivity();

      this.contactId = params['contactId'] || this.data.contactId;

      // If you are on a req (params only contains reqId), make the req dropdown readonly
      this.readonlyReq = this.contactId ? false : true;

      if (this.todoService.todo) {
        this.todo = this.todoService.todo;
        this.getTodo();
      } else {
        this.reqId = params['reqId'] || this.data.reqId;
        this.reqStream.next(this.reqId);

        this.getAuthenticatedUserInfo();

        if (this.contactId) {
          this.contactService
            .getContact(this.contactId)
            .valueChanges.pipe(take(1))
            .subscribe((data: any) => {
              // Get the reqs on the contact and add it to this.contacts
              this.contactService
                .getContactReqs(this.contactId)
                .pipe(take(1))
                .subscribe((result) => {
                  let contactReqs = result.data.getContactReqs.map((ele) => {
                    return ele.requisition;
                  });

                  let contact = data.data.getContact;
                  this.contacts = [
                    { id: contact.id, firstName: contact.firstName, lastName: contact.lastName, reqs: contactReqs },
                  ];
                });
            });
        }
      }
    });

    // load data for selection lists
    this.getReqs();

    // enable type ahead chip selection elements
    this.filteredAssignees = this.assigneesFormControl.valueChanges.pipe(
      startWith(null),
      map((assignee: string | null) => (assignee ? this._filterAssignee(assignee) : this.allAssignees.slice()))
    );

    // We subscribe to the forms changes, and everytime there is a change,
    // go fetch 10 contacts who name starts with the value of the input
    // TODO: When you scroll down get 10 more contacts?
    this.contactFromSubscription = this.contactsFormControl.valueChanges
      .pipe(
        debounceTime(1000),
        map((input: string | null) => {
          this.filteredContactsInput = input;
          this.filteredContactsPageSize = 50;
          if (this.reqId) {
            this.requisitionService
              .filterReqContacts(
                [this.reqId],
                { page: 1, pageSize: this.filteredContactsPageSize },
                {
                  name: input,
                },
                {},
                filterReqContactsForSelect
              )
              .valueChanges.pipe(
                take(1),
                map((result) => {
                  return result.data.filterReqContacts.contacts.map(({ contactId: id, firstName, lastName }) => {
                    return { id, firstName, lastName };
                  });
                })
              )
              .subscribe((result) => this.filteredContactsBatch.next(result));
          } else {
            if (typeof input === 'string') {
              this.contactService
                .filterMyContacts(
                  { page: 1, pageSize: this.filteredContactsPageSize },
                  {
                    name: input,
                  },
                  {},
                  filterMyContactsForSelect
                )
                .valueChanges.pipe(
                  take(1),
                  map((result) => {
                    return result.data.filterMyContacts.contacts.map(({ contactId: id, firstName, lastName }) => {
                      return { id, firstName, lastName };
                    });
                  })
                )
                .subscribe((result) => this.filteredContactsBatch.next(result));
            }
          }
        })
      )
      .subscribe(); // We have to subscribe here for the valueChanges to be fired

    if (this.data.isDialog) {
      document.getElementById('create-todo-card').style.margin = '-5em 0 0 -20em';
    }

    // get current user id and if user id exists on todo, allow user to delete todo
    this.userService.getAuthenticatedUserInfo().subscribe(
      (userInfo: any) => {
        this.currentUserId = userInfo.attributes['custom:userId'];
        // console.log(this.currentUserId, this.todo);
        if (this.todo?.users) {
          const exists = this.todo.users.some((user) => user.id === this.currentUserId);
          if (exists) {
            this.canDelete = true;
          } else {
            this.canDelete = false;
          }
        }
      },
      (error) => {
        console.log('error getting current userId ' + error.message);
      }
    );
  }

  getTodo() {
    this.createActivityForm.controls['title'].setValue(this.todo.title);
    this.createActivityForm.controls['description'].setValue(this.todo.description);
    this.createActivityForm.controls['assignees'].setValue(this.todo.users);
    this.createActivityForm.controls['contacts'].setValue(this.todo.contacts);
    this.createActivityForm.controls['recurringFrequency'].setValue(
      this.todo.recurring?.frequency == null ? 'Does not repeat' : this.todo.recurring?.frequency
    );
    this.createActivityForm.controls['customRecurringNum'].setValue(
      this.todo.recurring?.customRecurringNum == null ? 1 : this.todo.recurring?.customRecurringNum
    );
    this.createActivityForm.controls['customRecurringFrequency'].setValue(
      this.todo.recurring?.customRecurringFrequency == null ? 'Days' : this.todo.recurring?.customRecurringFrequency
    );

    // If task is completed, date is stored in completedDate. If not, it's stored in dueOn
    let date = this.todo.dueOn ? this.todo.dueOn : this.todo.completedDate;
    this.createActivityForm.controls['dueOn'].setValue(new Date(date));

    if (!this.viewOnly) this.createActivityForm.controls['req'].setValue(this.todo.reqId);

    this.createActivityForm.disable();
    // document.getElementById('assigneeInput').setAttribute("readonly", "true");
    // document.getElementById('contactInput').setAttribute("readonly", "true");

    this.title = this.todo.title;
    this.contacts = this.todo.contacts;
    this.reqId = this.todo.reqId;
    this.reqStream.next(this.reqId);
    this.assignees = this.todo.users;

    this.getAuthenticatedUserInfo();

    // Update this.contacts to have the reqs of each contact
    this.contacts.forEach((contact) => {
      this.contactService
        .getContactReqs(contact.id)
        .pipe(take(1))
        .subscribe((result) => {
          let contactReqs = result.data.getContactReqs.map((ele) => {
            return ele.requisition;
          });

          contact.reqs = contactReqs;
        });
    });

    if (!this.viewOnly) document.getElementById('editTodoButton').style.visibility = 'visible';
  }

  private getAuthenticatedUserInfo(): void {
    this.userService.getAuthenticatedUserInfo().subscribe(
      (userInfo: any) => {
        this.authenticatedUserInfo = userInfo;

        // Disable tenantFormControl if not mmUser or mmAdmin
        if (
          !(
            this.authenticatedUserInfo?.groups?.includes('mmUser') ||
            this.authenticatedUserInfo?.groups?.includes('mmAdmin')
          )
        )
          this.tenantFormControl.disable();

        console.log('CreateActivityPopoverComponent: authenticatedUserInfo ', userInfo);

        // now that we have the authenticatedUserInfo and know the user's group, make request for their tenants he can access
        this.getTenants();
      },
      (error) => {
        console.log('CreateActivityPopoverComponent: Error getting authenticated user groups -> ' + error.message);
      }
    );
  }

  private getTenants(): void {
    // Within req on fresh load this subscription getting called twice - results in duplicate user
    // added .pipe(take(1))
    this.getMyTenantsSub = this.tenantService
      .getMyTenants()
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log('CreateActivityPopoverComponent: tenant data ', result.data.getMyTenants);
          let getMyTenants = structuredClone(result.data.getMyTenants);
          getMyTenants.forEach((item) => {
            item.user = item.user.filter((user) => user['active'] == true);
          });

          this.tenants = getMyTenants;
          let assignedTenant = this.tenants.filter(
            (t) => t.id == this.authenticatedUserInfo.attributes['custom:tenantId']
          );
          if (assignedTenant && assignedTenant.length > 0) {
            this.allAssignees = [...assignedTenant[0].user].sort((userA, userB) => {
              return userA.firstName.localeCompare(userB.firstName);
            });
          }
          this.filteredTenants = this.tenantFormControl.valueChanges.pipe(
            startWith<string | Tenant>(''),
            map((value) => (typeof value === 'string' ? value : value.title)),
            map((name) => (name ? this._filterTenant(this.tenants, name) : this.tenants))
          );
          this.assignCurrentUser();
          this.loadTenantAndReq();
          if (this.reqId) {
            this.addReqUsersToAllAssignees();
          }
        },
        (error) => {
          console.log('CreateActivityPopoverComponent: There was an error loading tenant data ', error);
        }
      );
  }

  // This just sets the current user and adds to the assignees form.
  private assignCurrentUser(): void {
    console.log('assignCurrentUser this.assignees', JSON.stringify(this.assignees));

    this.currentUser = this.allAssignees.filter(
      (assignee) => assignee.id === this.authenticatedUserInfo.attributes['custom:userId']
    )[0];

    // If on a new todo, add the current user to the assignees form.
    // If on an existing todo, the assignees form gets the list of users from an existing todo in getTodo()
    if (!this.todo) {
      this.createActivityForm.controls['assignees'].setValue(this.currentUser);
      this.assignees.push(this.currentUser);
    }
  }

  private addReqUsersToAllAssignees(): void {
    this.requisitionService.getReqUsers(this.reqId).subscribe(
      (result) => {
        console.log('CreateActivityPopoverComponent: my req users ', result.data.getReqUsers);
        const reqUsers: User[] = result.data.getReqUsers;
        const filteredReqUsers: User[] = reqUsers.filter(
          (user) => user.id !== this.authenticatedUserInfo.attributes['custom:userId']
        );

        this.allAssignees = [this.currentUser, ...filteredReqUsers];
        this.getAvatarUrls();
      },
      (error) => {
        console.log('CreateActivityPopoverComponent: There was an error loading req users', error);
      }
    );
  }

  private loadTenantAndReq() {
    let tenantId = this.authenticatedUserInfo.attributes['custom:tenantId'];
    if (this.reqId) {
      this.requisitionService.loadRequisition(this.reqId, true).subscribe((result) => {
        console.log('SingleRequisitionComponent: Current Req == ', result.data.getReq);
        let r = Object.assign({}, result.data.getReq, { contacts: [] });
        let currentReq: Requisition = r;
        tenantId = currentReq.tenantId;
        this.userTenant = this.tenants.find((tenant) => {
          return tenant.id === tenantId;
        });
      });
    } else {
      this.userTenant = this.tenants.find((tenant) => {
        return tenant.id === tenantId;
      });
    }
  }

  autoCompleteScroll() {
    setTimeout(() => {
      if (
        this.associatedContactsAutocomplete &&
        this.autocompleteTrigger &&
        this.associatedContactsAutocomplete.panel
      ) {
        fromEvent(this.associatedContactsAutocomplete.panel.nativeElement, 'scroll')
          .pipe(
            map((x) => this.associatedContactsAutocomplete.panel.nativeElement.scrollTop),
            takeUntil(this.autocompleteTrigger.panelClosingActions)
          )
          .subscribe((x) => {
            const scrollTop = this.associatedContactsAutocomplete.panel.nativeElement.scrollTop;
            const scrollHeight = this.associatedContactsAutocomplete.panel.nativeElement.scrollHeight;
            const elementHeight = this.associatedContactsAutocomplete.panel.nativeElement.clientHeight;
            const atBottom = scrollHeight === scrollTop + elementHeight;

            // If the scrollbar is at the top, atBottom will be true because all the scroll values = 0.
            // So we ignore changing anything if the scroll values are 0.
            if (atBottom && scrollTop != 0) {
              // fetch more data
              this.filteredContactsPageSize += 50;
              if (this.reqId) {
                this.requisitionService
                  .filterReqContacts(
                    [this.reqId],
                    { page: 1, pageSize: this.filteredContactsPageSize },
                    { name: this.filteredContactsInput },
                    {},
                    filterReqContactsForSelect
                  )
                  .valueChanges.pipe(
                    take(1),
                    map((result) => {
                      return result.data.filterReqContacts.contacts.map(({ contactId: id, firstName, lastName }) => {
                        return { id, firstName, lastName };
                      });
                    })
                  )
                  .subscribe((result) => this.filteredContactsBatch.next(result));
              } else {
                this.contactService
                  .filterMyContacts(
                    { page: 1, pageSize: this.filteredContactsPageSize },
                    { name: this.filteredContactsInput },
                    {},
                    filterMyContactsForSelect
                  )
                  .valueChanges.pipe(
                    take(1),
                    map((result) => {
                      return result.data.filterMyContacts.contacts.map(({ contactId: id, firstName, lastName }) => {
                        return { id, firstName, lastName };
                      });
                    })
                  )
                  .subscribe((result) => this.filteredContactsBatch.next(result));
              }
            }
          });
      }
    });
  }

  /**
   * Updates the contactsToAddToReq array with all the contacts that need to be added to the chosen req
   */
  findContactsToAddToReq(reqId) {
    if (!reqId) return;

    this.contactsToAddToReq = this.contacts.filter((contact) => {
      return !contact.reqs.some((req) => req.id == this.reqId);
    });
    console.log('CreateActivtyPopoverComponent:: contactsToAddToReq', this.contactsToAddToReq);

    this.prevChosenReq = this.reqStream.value;

    if (this.contactsToAddToReq.length > 0) {
      // Prompt user that they have chosen a req that some contacts are not on.
      // Yes: do nothing.
      // No: this.createActivityForm.controls['req'].setValue(previousReqId);
      this.warnReqChange().subscribe((confirmation) => {
        if (confirmation) {
          this.reqStream.next(reqId);
          this.addReqUsersToAllAssignees();
        } else {
          this.createActivityForm.controls['req'].setValue(this.prevChosenReq);
          return;
        }
      });
    } else {
      this.reqStream.next(reqId);
      this.addReqUsersToAllAssignees();
    }
  }

  warnReqChange(): Observable<boolean> {
    const dialogConfig: AlertDialogConfig = {
      title: 'Add contacts to requisition?',
      message: `Warning: Some contacts are not on the requisition you chose. It will be added
                to the requisition when creating the To-Do. Do you wish to continue?`,
      confirmButton: {
        label: 'Yes, continue',
        color: 'primary',
      },
    };
    const dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, boolean>(AlertDialogComponent, {
      panelClass: 'alert-dialog-component',
      data: dialogConfig,
      autoFocus: false,
    });
    return dialogRef.afterClosed();
  }

  selectReq($event) {
    // Save the previous chosen req so that you can come back to it if user doesn't want to change reqs
    this.findContactsToAddToReq($event.value);
  }

  private _filterTenant(data: any, name: string): Tenant[] {
    const filterValue = name.toLowerCase();
    return data.filter((tenant) => tenant.title.toLowerCase().includes(filterValue));
  }

  displayTenant(tenant?: Tenant): string | undefined {
    return tenant ? tenant.title : undefined;
  }

  tenantSelected(event) {
    if (this.authenticatedUserInfo.groups.includes('mmAdmin') || this.authenticatedUserInfo.groups.includes('mmUser')) {
      let mmTenant = this.tenants.filter((t) => t.id == this.authenticatedUserInfo.attributes['custom:tenantId']);
      let mmInternalUsers = [];
      if (mmTenant.length > 0) {
        if (event.option.value.id != mmTenant[0].id) {
          mmInternalUsers = mmTenant[0].user;
        }
      }

      this.allAssignees = [].concat(mmInternalUsers, event.option.value.user);
    } else {
      this.allAssignees = event.option.value.user;
    }

    this.allAssignees.sort((userA, userB) => userA.firstName.localeCompare(userB.firstName));
    console.log('CreateActivityPopoverComponent: allAssignees data ', this.allAssignees);

    //new tenant selected so clear out owner and assignee
    this.createActivityForm.controls['assignees'].reset();
    this.assignees = [];

    let selectTenantId = event.option.value.id;
    this.reqOptions = this.usersReqs
      .filter((req) => req.tenant === selectTenantId)
      .map((req) => ({ value: req.reqId, title: req.title }))
      .sort((roA, roB) => roA.title.localeCompare(roB.title));
  }

  getAvatarUrls() {
    this.allAssignees.forEach((user) => {
      if (!this.avatarUrls[user.id]) {
        this.userService.getUserAvatarUrl(user.id).subscribe(
          ({ data }) => {
            const url = data.getUserAvatarUrl?.url || data.getContactAvatarUrl?.url;
            this.avatarUrls[user.id] = url;
            if (url) this.imageCachingService.cacheImageFromUrl(url, user.id);
          },
          (error) => console.log(error)
        );
      }
    });

    this.allContacts.forEach((contact) => {
      if (!this.avatarUrls[contact.id]) {
        this.contactService
          .getContactAvatarUrl(contact.id)
          .pipe(take(1))
          .subscribe(
            ({ data }) => {
              const url = data.getContactAvatarUrl.url;
              this.avatarUrls[contact.id] = data.getContactAvatarUrl?.url || '';
              if (url) this.imageCachingService.cacheImageFromUrl(url, contact.id);
            },
            (error) => console.log(error)
          );
      }
    });
  }

  verifyTenantSelected() {
    //verify selection is a valid selection from list
    if (!this.tenants.includes(this.createActivityForm.value.tenant)) {
      this.createActivityForm.controls['tenant'].setValue('');
      this.reqOptions = [];
    }
  }

  getReqs() {
    this.getMyReqsSub = this.requisitionService.getMyReqsForDropdown().subscribe(
      (result) => {
        console.log('CreateActivityPopoverComponent: my reqs ', result.data.getMyReqs);
        this.usersReqs = result.data.getMyReqs;
        this.reqOptions = this.usersReqs
          .map((req) => ({ value: req.reqId, title: req.title }))
          .sort((reqA, reqB) => reqA.title.localeCompare(reqB.title));
      },
      (error) => {
        console.log('CreateActivityPopoverComponent: There was an error loading reqs ', error);
      }
    );
  }

  removeAssignee(assignee): void {
    this.assignees = this.assignees.filter((item) => item.id !== assignee.id);

    console.log('this.assignees', this.assignees);
  }

  selectedAssignee(event: MatAutocompleteSelectedEvent): void {
    if (!this.assignees.includes(event.option.value)) {
      this.assignees.push(event.option.value);
      this.assigneeInput.nativeElement.value = '';
    }
    console.log('this.assignees', this.assignees);
  }

  private _filterAssignee(value: string): any[] {
    if (typeof value == 'string') {
      return this.allAssignees.filter((assignee) =>
        [assignee.firstName, assignee.lastName].join(' ').toLowerCase().includes(value.toLowerCase())
      );
    } else {
      return this.allAssignees;
    }
  }

  removeContact(contact): void {
    this.contacts = this.contacts.filter((item) => item.id !== contact.id);

    console.log('this.contacts', this.contacts);
  }

  selectedContact(event: MatAutocompleteSelectedEvent): void {
    let contact = event.option.value;
    if (!this.contacts.includes(contact)) {
      // Get the reqs on the contact and add it to this.contacts
      this.contactService
        .getContactReqs(contact.id)
        .pipe(take(1))
        .subscribe((result) => {
          let contactReqs = result.data.getContactReqs.map((ele) => {
            return ele.requisition;
          });

          this.contacts.push({
            id: contact.id,
            firstName: contact.firstName,
            lastName: contact.lastName,
            reqs: contactReqs,
          });
          this.contactInput.nativeElement.value = '';
          console.log('this.contacts', this.contacts);
        });
    }
  }

  private _filterContact(value: string): any[] {
    if (typeof value == 'string') {
      return this.allContacts
        .filter(({ id }) => !this.contacts.map(({ id }) => id).includes(id))
        .filter((contact) =>
          [contact.firstName, contact.lastName].join(' ').toLowerCase().includes(value.toLowerCase())
        );
    } else {
      return this.allContacts;
    }
  }

  prepareCreateTodo() {
    // stop here if form is invalid
    if (this.createActivityForm.invalid) {
      console.log('CreateActivityPopoverComponent: form is invalid', this.createActivityForm.invalid);

      //mark each field as touched so required fields display error state
      for (let i in this.createActivityForm.controls) {
        this.createActivityForm.controls[i].markAsTouched();
      }
      return;
    }

    // Warn user if the recurring todo is monthly and doesn't fall on months with that specific date
    let recurringFreq = this.createActivityForm.get('recurringFrequency').value;
    let date = this.createActivityForm.get('dueOn').value;

    // Check the days in the month. If the day doesn't exist, warn user
    if (recurringFreq == 'Monthly' && date.getDate() > 29) {
      let dialogConfig: AlertDialogConfig = {
        title: 'Create the monthly recurring todo?',
        message: `This To-Do is scheduled for day ${date.getDate()} of each month and will skip months without ${date.getDate()} days.`,
        extraMessage: 'Are you sure you want to create this To-Do',
        confirmButton: {
          label: 'Add To-Do',
          color: 'primary',
        },
        cancelButton: {
          label: 'Cancel',
          color: 'accent',
        },
      };

      let dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, string>(AlertDialogComponent, {
        panelClass: 'alert-dialog-component',
        data: dialogConfig,
        autoFocus: false,
        width: '35%',
      });

      dialogRef.afterClosed().subscribe((confirmation) => {
        if (confirmation) {
          // Add contacts to req if they don't yet belong on it.
          if (this.contactsToAddToReq.length > 0) {
            let contactIds = this.contactsToAddToReq.map((c) => c.id);
            this.contactService
              .addContactsToReq(contactIds, this.reqId)
              .pipe(take(1))
              .toPromise()
              .then(() => {
                this.createTodo();
              })
              .catch((err) => console.log(err));
          } else {
            this.createTodo();
          }
        }
      });
    } else {
      // Add contacts to req if they don't yet belong on it.
      if (this.contactsToAddToReq.length > 0) {
        let contactIds = this.contactsToAddToReq.map((c) => c.id);
        this.contactService
          .addContactsToReq(contactIds, this.reqId)
          .pipe(take(1))
          .toPromise()
          .then(() => {
            this.createTodo();
          })
          .catch((err) => console.log(err));
      } else {
        this.createTodo();
      }
    }
  }

  createTodo() {
    let form = this.createActivityForm.value;
    console.log('form', form);

    let assignees = this.assignees.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
    }));
    let contacts = this.contacts.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
    }));
    let dueTime = form.time ? form.time.split(':') : null;
    let dueOn = dueTime ? new Date(form.dueOn.setHours(dueTime[0], dueTime[1])).toISOString() : form.dueOn;
    dueOn = this.nextRecurringDate ? this.nextRecurringDate : dueOn;
    dueOn = dueOn.toISOString();
    let todoInfo = {
      title: form.title,
      type: ActivityType.Task,
      description: form.description,
      users: assignees,
      contacts: contacts,
      reqId: form.req,
      location: form.location,
      phone: form.phone,
      duration: form.duration,
      createdBy: this.authenticatedUserInfo.attributes['custom:userId'],
      dueOn: dueOn,
      recurring: {
        frequency: form.recurringFrequency,
        customRecurringNum: form.recurringFrequency == 'Custom' ? form.customRecurringNum : null,
        customRecurringFrequency: form.recurringFrequency == 'Custom' ? form.customRecurringFrequency : null,
      },
    };
    console.log('CreateActivityPopoverComponent: adding todo', todoInfo);

    this.todoService.createTodo(todoInfo).subscribe(
      (result) => {
        console.log('CreateActivityPopoverComponent: todo created ', result);
        this.closePopover();
        this.snackBar.open('Added To-Do', null, {
          duration: 4000,
          panelClass: 'success-snack-bar',
        });
      },
      (error) => {
        console.log('CreateActivityPopoverComponent: Error creating todo ', error);
        this.closePopover();
        this.snackBar.open('Error adding To-Do', null, {
          duration: 4000,
          panelClass: 'error-snack-bar',
        });
      }
    );
  }

  updateTodo(): void {
    // submit form here.
    // stop here if form is invalid
    if (this.createActivityForm.invalid) {
      console.log('CreateActivityPopoverComponent: form is invalid', this.createActivityForm.invalid);

      //mark each field as touched so required fields display error state
      for (let i in this.createActivityForm.controls) {
        this.createActivityForm.controls[i].markAsTouched();
      }
      return;
    }

    let assignees: User[] = this.assignees.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
    }));
    let contacts: Contact[] = this.contacts.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      stage: item.stage,
    }));

    let update: UpdateTodoInput = {
      id: this.todo.id,
    };

    this._updateIfChanged(update, 'title');
    this._updateIfChanged(update, 'description');

    let updatedCustomRecurringNum =
      this.createActivityForm.get('recurringFrequency').value != 'Custom'
        ? null
        : this.createActivityForm.get('customRecurringNum').value;
    let customRecurringFrequency =
      this.createActivityForm.get('recurringFrequency').value != 'Custom'
        ? null
        : this.createActivityForm.get('customRecurringFrequency').value;

    update['recurring'] = {
      frequency: this.createActivityForm.get('recurringFrequency').value,
      customRecurringNum: updatedCustomRecurringNum,
      customRecurringFrequency: customRecurringFrequency,
    };

    update['users'] = assignees;
    update['contacts'] = contacts;

    if (this.createActivityForm.get('dueOn')?.dirty) {
      let dueDateStr = this.createActivityForm.get('dueOn').value;
      let dueOn = new Date(dueDateStr).toISOString();
      update['dueOn'] = dueOn;
    }

    this.todoService.updateTodo(update).subscribe(
      (result) => {
        console.log('CreateActivityPopoverComponent: todo created ', result);
        this.closePopover();
        this.snackBar.open('To-Do updated', null, {
          duration: 4000,
          panelClass: 'success-snack-bar',
        });
      },
      (error) => {
        console.log('CreateActivityPopoverComponent: Error creating todo ', error);
        this.closePopover();
        this.snackBar.open('Error updating To-Do', null, {
          duration: 4000,
          panelClass: 'error-snack-bar',
        });
      }
    );
  }

  // Only open the coninue or complete dialog if the todo is recurring
  openCompleteOrContinueDialog() {
    if (this.todo.recurring?.frequency == 'Does not repeat' || !this.todo.recurring?.frequency) {
      this.onCompleteTodo();
    } else {
      let tempRecurringDate = this.todoService.calculateNextRecurringTodoDate(this.todo);
      let dialogConfig = this.todoService.getCompleteOrContinueDialogConfig(tempRecurringDate);

      let dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, string>(AlertDialogComponent, {
        panelClass: 'alert-dialog-component',
        data: dialogConfig,
        autoFocus: false,
        width: '35%',
      });

      return dialogRef.afterClosed().subscribe((confirmation) => {
        if (confirmation == 'continue') {
          // Create a new recurring todo
          this.nextRecurringDate = tempRecurringDate;
          this.createTodo();
          this.onCompleteTodo();
        } else if (confirmation == 'complete') {
          this.onCompleteTodo();
        }
      });
    }
  }

  openConfirmDeleteDialog() {
    let dialogConfig: AlertDialogConfig = {
      title: 'Confirmation',
      message:
        'Do you wish to delete this To-Do? This action cannot be undone. To-Do’s that are deleted will not appear as completed.',
      confirmButton: {
        label: 'Confirm Deletion',
        color: 'primary',
        value: 'continue',
      },
      cancelButton: {
        label: 'Cancel',
        color: 'accent',
        value: 'cancel',
      },
    };

    let dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, string>(AlertDialogComponent, {
      panelClass: 'alert-dialog-component',
      data: dialogConfig,
      autoFocus: false,
      width: '35%',
    });

    return dialogRef.afterClosed().subscribe((confirmation) => {
      if (confirmation == 'continue') {
        this.onDeleteTodo();
      } else if (confirmation == 'cancel') {
        console.log('Cancelled To-Do Deletion');
      }
    });
  }

  onCompleteTodo() {
    this.completeTodo.emit(this.todo);
    this.todoService.todo = null;
    this.todo = null;
  }

  onDeleteTodo() {
    this.todo.disposition = 'delete';
    this.completeTodo.emit(this.todo);
    this.todoService.todo = null;
    this.todo = null;
  }

  private _updateIfChanged(updateInput: UpdateTodoInput, controlName: string): UpdateTodoInput {
    let control = this.createActivityForm.get(controlName);
    if (control?.dirty) {
      updateInput[controlName] = control.value;
    }

    return updateInput;
  }

  closePopover() {
    // Send output that create todo popover is being closed
    if (this.todo) this.closeEditTodo.emit(false);
    else this.closeCreateTodo.emit(false);

    if (this.data.isDialog) this.dialogRef.close();

    // Reset todo because we're closing out of the window
    this.todo = null;
    this.todoService.todo = null;
  }

  enableEditTodo() {
    this.createActivityForm.enable();
    this.reqFormControl.disable();
    this.tenantFormControl.disable();
    document.getElementById('editTodoButton').style.visibility = 'hidden';
  }

  openCreateMeeting() {
    window.location.href = window.location.origin + '/#/activity/createmeeting';
  }

  getAssigneeEmails(assignees): Promise<any> {
    return new Promise(async (resolve) => {
      var attendees = [];
      for (let a = 0; a < assignees.length; ++a) {
        await this.userService
          .getUserInfo(assignees[a].id)
          .pipe(take(1))
          .toPromise()
          .then((result) => {
            attendees.push(result.data.getUserInfo.emails[0].address);
            console.log('pushing ', result.data.getUserInfo.emails[0].address);
          });
      }
      console.log('stringified attendees', JSON.stringify(attendees));
      resolve(attendees);
    });
  }

  adjustStyleForActivity() {
    document.getElementById('create-todo-card').style.marginTop = '1em';
    document.getElementById('create-todo-card').style.marginLeft = '10em';
  }

  ngOnDestroy() {
    this.filterMyContactsSub?.unsubscribe();
    this.filterReqContactsSub?.unsubscribe();
    this.getMyReqsSub?.unsubscribe();
    this.getMyTenantsSub?.unsubscribe();
    this.routeSub?.unsubscribe();
  }
}
