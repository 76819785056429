import { PermissionsOverride } from '../permissions';

// Specific overrides that the tenant admins of each tenant should have.
export default {
  cms: {
    add_to_all_reqs: true,
  },
  trust_hub: {
    enabled: true,
    sms: true
  }
} as PermissionsOverride;
