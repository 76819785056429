import { COMMA, ENTER, P } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import { filterMyContactsForSelect, filterReqContactsForSelect } from 'app/graphql/queries';
import { ContactService } from 'app/services/contact.service';
import { GoogleService } from 'app/services/google.service';
import { ImageCachingService } from 'app/services/imagecaching.service';
import { OutlookService } from 'app/services/outlook.service';
import { RequisitionService } from 'app/services/requisition.service';
import { TenantService } from 'app/services/tenant.service';
import { TodoService } from 'app/services/todo.service';
import { UserService } from 'app/services/user.service';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith, take, takeUntil } from 'rxjs/operators';
import { AlertDialogComponent, AlertDialogConfig } from '../dialogs/alert-dialog.component';
import {
  ActivityDurationOption,
  ActivityTimeOption,
  ActivityType,
  Participant,
  UpdateTodoInput,
} from '../model/activity';
import { Contact, ReqContact, UserContact } from '../model/contacts';
import { Option } from '../model/filter';
import { Requisition, UserReq } from '../model/requisitions';
import { Tenant } from '../model/tenant';
import { User } from '../model/user';
import moment from 'moment';
import { MeetingDispositionDialog } from '../dialogs/meeting-disp-dialog.component';

@Component({
  selector: 'mm-create-meeting',
  templateUrl: './create-meeting.component.html',
  styleUrls: ['./create-meeting.component.scss', '../../nick_styles/nick_op.css'],
})
export class CreateMeetingComponent implements OnInit {
  breadCrumb: string;
  formOptions: FormGroup;
  floatLabelControl = new FormControl('always');

  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  createMeetingForm: FormGroup;
  typeFormControl = new FormControl('', Validators.required);
  titleFormControl = new FormControl('', Validators.required);
  descriptionFormControl = new FormControl();
  tenantFormControl = new FormControl();
  reqFormControl = new FormControl();
  assigneesFormControl = new FormControl('', Validators.required);
  contactsFormControl = new FormControl();
  dueOnFormControl = new FormControl('', Validators.required);
  timeFormControl = new FormControl();
  durationFormControl = new FormControl();
  phoneFormControl = new FormControl();
  locationFormControl = new FormControl();
  sendInviteFormControl = new FormControl();
  isTeamsMeetingFormControl = new FormControl();

  readonlyReq: boolean = false;
  startDate = new Date();
  dueDate: Date;
  readonly: boolean;
  type: ActivityType;
  customType: string;
  title: string;
  description: string;
  participants: Participant[];
  requisition: string;
  completedDate: Date;

  readonly durationOptions: Option[] = ActivityDurationOption;
  readonly timeOptions: Option[] = ActivityTimeOption;

  tenants: Tenant[];
  filteredTenants: Observable<Tenant[]>;

  usersReqs: UserReq[];
  reqOptions: Option[] = [{ value: '', title: '' }];

  assignees: User[] = [];
  filteredAssignees: Observable<User[]>;
  allAssignees: User[] = [];

  contacts: UserContact[] = [];
  filteredContactsBatch = new BehaviorSubject<{ id: string; firstName: string; lastName: string }[]>([]);
  filteredContacts = this.filteredContactsBatch.asObservable();
  allContacts: UserContact[] & ReqContact[] = [];
  contactsToAddToReq: UserContact[] = [];

  authenticatedUserInfo: any = null;
  routeSub: Subscription;
  currentUser: any;
  getMyReqsSub: Subscription;
  getMyTenantsSub: Subscription;
  contactFromSubscription: Subscription;

  userTenant: Tenant;
  todoId: string;
  reqId: string;
  reqTitle: string;
  contactId: string;
  contactName: string;
  currentContact: Contact;
  avatarUrls: any = {};
  filteredContactsInput: string;
  filteredContactsPageSize = 50;

  isOutlookAuthorized: boolean = false;
  isGoogleAuthorized: boolean = false;
  hasTeamsForBusiness: boolean = false;
  @ViewChild('calendar') calendar: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialView: 'listDay',
    listDaySideFormat: {
      month: 'long',
      year: 'numeric',
      day: 'numeric',
    },
    height: 300,
    weekends: true,
    handleWindowResize: true,
    headerToolbar: false,
  };
  reqStream: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  prevChosenReq: string = null;
  todo: any;
  routeParams: any;
  viewOnly: boolean;

  @ViewChild('assigneeInput', { static: false })
  assigneeInput: ElementRef<HTMLInputElement>;
  @ViewChild('contactInput', { static: false })
  contactInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoAssignee', { static: false })
  matAutocomplete: MatAutocomplete;
  @ViewChild('autoContact') associatedContactsAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger;

  private filterMyContactsSub: Subscription;
  private filterReqContactsSub: Subscription;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private todoService: TodoService,
    private userService: UserService,
    private contactService: ContactService,
    private tenantService: TenantService,
    private requisitionService: RequisitionService,
    private imageCachingService: ImageCachingService,
    private outlookService: OutlookService,
    private googleService: GoogleService
  ) {
    this.formOptions = this.formBuilder.group({
      floatLabel: this.floatLabelControl,
    });
  }

  async ngOnInit(): Promise<void> {
    this.createMeetingForm = this.formBuilder.group({
      title: this.titleFormControl,
      description: this.descriptionFormControl,
      tenant: this.tenantFormControl,
      req: this.reqFormControl,
      assignees: this.assigneesFormControl,
      contacts: this.contactsFormControl,
      dueOn: this.dueOnFormControl,
      time: this.timeFormControl,
      duration: this.durationFormControl,
      phone: this.phoneFormControl,
      location: this.locationFormControl,
      sendInvite: this.sendInviteFormControl,
      isTeamsMeeting: this.isTeamsMeetingFormControl,
    });

    await this.getIsOutlookAuthorized();
    await this.getIsGoogleAuthorized();
    await this.getHasTeamsForBusiness();

    // Set meeting time default to 10am
    this.createMeetingForm.controls['time'].setValue(this.timeOptions[20].value);

    this.routeSub = this.route.params.subscribe((params) => {
      this.routeParams = params;

      this.contactId = params['contactId'];

      this.todoId = params['todoId'];

      // If you are on a req (params only contains reqId), make the req dropdown readonly
      this.readonlyReq = this.contactId ? false : true;

      if (this.todoService.todo) {
        this.completedDate = this.todoService.todo.completedDate;
        this.viewOnly = this.completedDate ? true : false;

        if (!this.viewOnly) document.getElementById('editMeetingButton').style.display = 'inline';

        this.todo = this.todoService.todo;
        this.getMeeting();
        this.setBreadcrumb();
      } else if (this.todoId) {
        // The new meeting url routes directly to the CreateMeetingComponent. So we need to populate the todo here by
        // retrieving the todo
        this.todoService.getTodo(this.todoId).subscribe(
          (result) => {
            let getTodo = result.data.getTodo;
            console.log('CreateMeetingComponent - displaying todo %s', JSON.stringify(getTodo));

            this.completedDate = getTodo.completedDate;
            this.viewOnly = this.completedDate ? true : false;

            if (!this.viewOnly) document.getElementById('editMeetingButton').style.display = 'inline';

            this.todo = getTodo;
            this.getMeeting();
            this.setBreadcrumb();
          },
          (error) => {
            console.log(
              'ERROR - ActivityComponent::checkDisplayTodo - Error displaying todo %s',
              JSON.stringify(error)
            );
          }
        );
      } else {
        this.reqId = params['reqId'];
        this.reqStream.next(this.reqId);

        this.getAuthenticatedUserInfo();

        if (this.contactId) {
          this.contactService
            .getContact(this.contactId)
            .valueChanges.pipe(take(1))
            .subscribe((data: any) => {
              // Get the reqs on the contact and add it to this.contacts
              this.contactService
                .getContactReqs(this.contactId)
                .pipe(take(1))
                .subscribe((result) => {
                  let contactReqs = result.data.getContactReqs.map((ele) => {
                    return ele.requisition;
                  });
                  let contact = data.data.getContact;
                  this.contactName = contact.firstName + ' ' + contact.lastName;
                  this.contacts = [
                    {
                      id: contact.id,
                      firstName: contact.firstName,
                      lastName: contact.lastName,
                      reqs: contactReqs,
                    },
                  ];
                  this.breadCrumb = this.contactName.toUpperCase();
                });
            });
        } else if (this.reqId) {
          this.requisitionService
            .loadRequisition(this.reqId, true)
            .pipe(take(1))
            .subscribe((result) => {
              this.reqTitle = result.data.getReq.title;
              this.breadCrumb = this.reqTitle.toUpperCase();
            });
        }
      }
    });

    // load data for selection lists
    this.getReqs();

    // enable type ahead chip selection elements
    this.filteredAssignees = this.assigneesFormControl.valueChanges.pipe(
      startWith(null),
      map((assignee: string | null) => (assignee ? this._filterAssignee(assignee) : this.allAssignees.slice()))
    );

    // We subscribe to the forms changes, and everytime there is a change,
    // go fetch 10 contacts who name starts with the value of the input
    // TODO: When you scroll down get 10 more contacts?
    this.contactFromSubscription = this.contactsFormControl.valueChanges
      .pipe(
        debounceTime(1000),
        map((input: string | null) => {
          this.filteredContactsInput = input;
          this.filteredContactsPageSize = 50;
          if (this.reqId) {
            this.requisitionService
              .filterReqContacts(
                [this.reqId],
                { page: 1, pageSize: this.filteredContactsPageSize },
                {
                  name: input,
                },
                {},
                filterReqContactsForSelect
              )
              .valueChanges.pipe(
                take(1),
                map((result) => {
                  return result.data.filterReqContacts.contacts.map(({ contactId: id, firstName, lastName }) => {
                    return { id, firstName, lastName };
                  });
                })
              )
              .subscribe((result) => this.filteredContactsBatch.next(result));
          } else {
            if (typeof input === 'string') {
              this.contactService
                .filterMyContacts(
                  { page: 1, pageSize: this.filteredContactsPageSize },
                  {
                    name: input,
                  },
                  {},
                  filterMyContactsForSelect
                )
                .valueChanges.pipe(
                  take(1),
                  map((result) => {
                    return result.data.filterMyContacts.contacts.map(({ contactId: id, firstName, lastName }) => {
                      return { id, firstName, lastName };
                    });
                  })
                )
                .subscribe((result) => this.filteredContactsBatch.next(result));
            }
          }
        })
      )
      .subscribe(); // We have to subscribe here for the valueChanges to be fired
  }

  getMeeting() {
    console.log('todo meeting', this.todo);
    console.log('this.createMeetingform.controls', this.createMeetingForm.controls);

    this.createMeetingForm.controls['title'].setValue(this.todo.title);
    this.createMeetingForm.controls['description'].setValue(this.todo.description);
    this.createMeetingForm.controls['assignees'].setValue(this.todo.users);
    this.createMeetingForm.controls['contacts'].setValue(this.todo.contacts);

    if (this.viewOnly) {
      this.createMeetingForm.controls['dueOn'].setValue(this.todo.completedDate);
      document.getElementById('right-column').style.marginTop = '5em';
    } else {
      // Get the time and convert it to local time, then get the time object from ActivityTimeOption[]
      let localTimeStr = moment(this.todo.dueOn).format('HH:mm');
      let time = ActivityTimeOption.find((t) => t.value === localTimeStr).value;

      // Get the duration index for getting the value in ActivityDurationOption
      let durationIndex = parseInt(this.todo.duration) / 30 - 1;

      this.createMeetingForm.controls['dueOn'].setValue(this.todo.dueOn);
      this.createMeetingForm.controls['time'].setValue(time);
      this.createMeetingForm.controls['duration'].setValue(ActivityDurationOption[durationIndex].value);
      this.createMeetingForm.controls['req'].setValue(this.todo.reqId);
      this.createMeetingForm.controls['location'].setValue(this.todo.location);
      this.createMeetingForm.controls['phone'].setValue(this.todo.phone);
      this.createMeetingForm.controls['sendInvite'].setValue(this.todo.sendInvite);
      this.getCalendar(this.todo.dueOn);
    }

    this.createMeetingForm.disable();

    this.title = this.todo.title;
    this.assignees = this.todo.users;
    this.contacts = this.todo.contacts;
    this.reqId = this.todo.reqId;
    this.reqStream.next(this.reqId);

    this.getAuthenticatedUserInfo();

    // Update this.contacts to have the reqs of each contact
    this.contacts.forEach((contact) => {
      this.contactService
        .getContactReqs(contact.id)
        .pipe(take(1))
        .subscribe((result) => {
          let contactReqs = result.data.getContactReqs.map((ele) => {
            return ele.requisition;
          });

          contact.reqs = contactReqs;
        });
    });
  }

  setBreadcrumb() {
    if (this.routeParams.contactId) {
      this.contactService
        .getContact(this.contactId)
        .valueChanges.pipe(take(1))
        .subscribe((data: any) => {
          this.contactService
            .getContactReqs(this.contactId)
            .pipe(take(1))
            .subscribe((result) => {
              let contact = data.data.getContact;
              this.contactName = contact.firstName + ' ' + contact.lastName;
              this.breadCrumb = this.contactName.toUpperCase();
            });
        });
    } else if (this.routeParams.reqId) {
      this.requisitionService
        .loadRequisition(this.reqId, true)
        .pipe(take(1))
        .subscribe((result) => {
          this.reqTitle = result.data.getReq.title;
          this.breadCrumb = this.reqTitle.toUpperCase();
        });
    } else {
      this.breadCrumb = 'To Do';
    }
  }

  private getAuthenticatedUserInfo(): void {
    this.userService.getAuthenticatedUserInfo().subscribe(
      (userInfo: any) => {
        this.authenticatedUserInfo = userInfo;

        // Disable tenantFormControl if not mmUser or mmAdmin
        if (
          !(
            this.authenticatedUserInfo?.groups?.includes('mmUser') ||
            this.authenticatedUserInfo?.groups?.includes('mmAdmin')
          )
        )
          this.tenantFormControl.disable();

        // now that we have the authenticatedUserInfo and know the user's group, make request for their tenants he can access
        this.getTenants();
      },
      (error) => {
        console.log('CreateMeetingComponent: Error getting authenticated user groups -> ' + error.message);
      }
    );
  }

  private getTenants(): void {
    // Within req on fresh load this subscription getting called twice - results in duplicate user
    // added .pipe(take(1))
    this.getMyTenantsSub = this.tenantService
      .getMyTenants()
      .pipe(take(1))
      .subscribe(
        (res) => {
          let result = structuredClone(res);
          console.log('CreateMeetingComponent: tenant data ', result.data.getMyTenants);

          result.data.getMyTenants.forEach((item) => {
            item.user = item.user.filter((user) => user['active'] == true);
          });

          this.tenants = result.data.getMyTenants;
          let assignedTenant = this.tenants.filter(
            (t) => t.id == this.authenticatedUserInfo.attributes['custom:tenantId']
          );
          if (assignedTenant && assignedTenant.length > 0) {
            this.allAssignees = [...assignedTenant[0].user].sort((userA, userB) => {
              return userA.firstName.localeCompare(userB.firstName);
            });
          }
          this.filteredTenants = this.tenantFormControl.valueChanges.pipe(
            startWith<string | Tenant>(''),
            map((value) => (typeof value === 'string' ? value : value.title)),
            map((name) => (name ? this._filterTenant(this.tenants, name) : this.tenants))
          );
          this.assignCurrentUser();
          this.loadTenantAndReq();
          if (this.reqId) {
            this.addReqUsersToAllAssignees();
          }
        },
        (error) => {
          console.log('CreateMeetingComponent: There was an error loading tenant data ', error);
        }
      );
  }

  private assignCurrentUser(): void {
    console.log('assignCurrentUser this.assignees', JSON.stringify(this.assignees));

    this.currentUser = this.allAssignees.filter(
      (assignee) => assignee.id === this.authenticatedUserInfo.attributes['custom:userId']
    )[0];

    // If on a new meeting, add the current user to the assignees form.
    // If on an existing meeting, the assignees form gets the list of users from an existing meeting in getMeeting()
    if (!this.todo) {
      this.createMeetingForm.controls['assignees'].setValue(this.currentUser);
      this.assignees.push(this.currentUser);
    }
  }

  private addReqUsersToAllAssignees(): void {
    this.requisitionService.getReqUsers(this.reqId).subscribe(
      (result) => {
        console.log('CreateMeetingComponent: my req users ', result.data.getReqUsers);
        const reqUsers: User[] = result.data.getReqUsers;
        const filteredReqUsers: User[] = reqUsers.filter(
          (user) => user.id !== this.authenticatedUserInfo.attributes['custom:userId']
        );
        this.allAssignees = [this.currentUser, ...filteredReqUsers];
        this.getAvatarUrls();
      },
      (error) => {
        console.log('CreateMeetingComponent: There was an error loading req users', error);
      }
    );
  }

  private loadTenantAndReq() {
    let tenantId = this.authenticatedUserInfo.attributes['custom:tenantId'];
    if (this.reqId) {
      this.requisitionService.loadRequisition(this.reqId, true).subscribe((result) => {
        console.log('SingleRequisitionComponent: Current Req == ', result.data.getReq);
        let r = Object.assign({}, result.data.getReq, { contacts: [] });
        let currentReq: Requisition = r;
        tenantId = currentReq.tenantId;
        this.userTenant = this.tenants.find((tenant) => {
          return tenant.id === tenantId;
        });
      });
    } else {
      this.userTenant = this.tenants.find((tenant) => {
        return tenant.id === tenantId;
      });
    }
  }

  autoCompleteScroll() {
    setTimeout(() => {
      if (
        this.associatedContactsAutocomplete &&
        this.autocompleteTrigger &&
        this.associatedContactsAutocomplete.panel
      ) {
        fromEvent(this.associatedContactsAutocomplete.panel.nativeElement, 'scroll')
          .pipe(
            map((x) => this.associatedContactsAutocomplete.panel.nativeElement.scrollTop),
            takeUntil(this.autocompleteTrigger.panelClosingActions)
          )
          .subscribe((x) => {
            const scrollTop = this.associatedContactsAutocomplete.panel.nativeElement.scrollTop;
            const scrollHeight = this.associatedContactsAutocomplete.panel.nativeElement.scrollHeight;
            const elementHeight = this.associatedContactsAutocomplete.panel.nativeElement.clientHeight;
            const atBottom = scrollHeight === scrollTop + elementHeight;

            // If the scrollbar is at the top, atBottom will be true because all the scroll values = 0.
            // So we ignore changing anything if the scroll values are 0.
            if (atBottom && scrollTop != 0) {
              // fetch more data
              this.filteredContactsPageSize += 50;
              if (this.reqId) {
                this.requisitionService
                  .filterReqContacts(
                    [this.reqId],
                    { page: 1, pageSize: this.filteredContactsPageSize },
                    { name: this.filteredContactsInput },
                    {},
                    filterReqContactsForSelect
                  )
                  .valueChanges.pipe(
                    take(1),
                    map((result) => {
                      return result.data.filterReqContacts.contacts.map(({ contactId: id, firstName, lastName }) => {
                        return { id, firstName, lastName };
                      });
                    })
                  )
                  .subscribe((result) => this.filteredContactsBatch.next(result));
              } else {
                this.contactService
                  .filterMyContacts(
                    { page: 1, pageSize: this.filteredContactsPageSize },
                    { name: this.filteredContactsInput },
                    {},
                    filterMyContactsForSelect
                  )
                  .valueChanges.pipe(
                    take(1),
                    map((result) => {
                      return result.data.filterMyContacts.contacts.map(({ contactId: id, firstName, lastName }) => {
                        return { id, firstName, lastName };
                      });
                    })
                  )
                  .subscribe((result) => this.filteredContactsBatch.next(result));
              }
            }
          });
      }
    });
  }

  /**
   * Updates the contactsToAddToReq array with all the contacts that need to be added to the chosen req
   */
  findContactsToAddToReq(reqId) {
    this.contactsToAddToReq = this.contacts.filter((contact) => {
      return !contact.reqs.some((req) => req.id == this.reqId);
    });
    console.log('CreateMeetingComponent:: contactsToAddToReq', this.contactsToAddToReq);

    this.prevChosenReq = this.reqStream.value;

    if (this.contactsToAddToReq.length > 0) {
      // Prompt user that they have chosen a req that some contacts are not on.
      // Yes: do nothing.
      // No: this.createActivityForm.controls['req'].setValue(previousReqId);
      this.warnReqChange().subscribe((confirmation) => {
        if (confirmation) {
          this.reqStream.next(reqId);
          this.addReqUsersToAllAssignees();
        } else {
          this.createMeetingForm.controls['req'].setValue(this.prevChosenReq);
          return;
        }
      });
    } else {
      this.reqStream.next(reqId);
      this.addReqUsersToAllAssignees();
    }
  }

  warnReqChange(): Observable<boolean> {
    const dialogConfig: AlertDialogConfig = {
      title: 'Add contacts to requisition?',
      message: `Warning: Some contacts are not on the requisition you chose. It will be added
                to the requisition when creating the To-Do. Do you wish to continue?`,
      confirmButton: {
        label: 'Yes, continue',
        color: 'primary',
      },
    };
    const dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, boolean>(AlertDialogComponent, {
      panelClass: 'alert-dialog-component',
      data: dialogConfig,
      autoFocus: false,
    });
    return dialogRef.afterClosed();
  }

  selectReq($event) {
    // Save the previous chosen req so that you can come back to it if user doesn't want to change reqs
    this.findContactsToAddToReq($event.value);
  }

  private _filterTenant(data: any, name: string): Tenant[] {
    const filterValue = name.toLowerCase();
    return data.filter((tenant) => tenant.title.toLowerCase().includes(filterValue));
  }

  displayTenant(tenant?: Tenant): string | undefined {
    return tenant ? tenant.title : undefined;
  }

  tenantSelected(event) {
    if (this.authenticatedUserInfo.groups.includes('mmAdmin') || this.authenticatedUserInfo.groups.includes('mmUser')) {
      let mmTenant = this.tenants.filter((t) => t.id == this.authenticatedUserInfo.attributes['custom:tenantId']);
      let mmInternalUsers = [];
      if (mmTenant.length > 0) {
        if (event.option.value.id != mmTenant[0].id) {
          mmInternalUsers = mmTenant[0].user;
        }
      }

      this.allAssignees = [].concat(mmInternalUsers, event.option.value.user);
    } else {
      this.allAssignees = event.option.value.user;
    }

    this.allAssignees.sort((userA, userB) => userA.firstName.localeCompare(userB.firstName));
    console.log('CreateMeetingComponent: allAssignees data ', this.allAssignees);

    //new tenant selected so clear out owner and assignee
    this.createMeetingForm.controls['assignees'].reset();
    this.assignees = [];

    let selectTenantId = event.option.value.id;
    this.reqOptions = this.usersReqs
      .filter((req) => req.tenant === selectTenantId)
      .map((req) => ({ value: req.reqId, title: req.title }))
      .sort((roA, roB) => roA.title.localeCompare(roB.title));
  }

  getAvatarUrls() {
    this.allAssignees.forEach((user) => {
      if (!this.avatarUrls[user.id]) {
        this.userService
          .getUserAvatarUrl(user.id)
          .pipe(take(1))
          .subscribe(
            ({ data }) => {
              const url = data.getUserAvatarUrl?.url || data.getContactAvatarUrl?.url;
              this.avatarUrls[user.id] = url;
              if (url) this.imageCachingService.cacheImageFromUrl(url, user.id);
            },
            (error) => console.log(error)
          );
      }
    });

    this.allContacts.forEach((contact) => {
      if (!this.avatarUrls[contact.id]) {
        this.contactService
          .getContactAvatarUrl(contact.id)
          .pipe(take(1))
          .subscribe(
            ({ data }) => {
              const url = data.getContactAvatarUrl.url;
              this.avatarUrls[contact.id] = data.getContactAvatarUrl?.url || '';
              if (url) this.imageCachingService.cacheImageFromUrl(url, contact.id);
            },
            (error) => console.log(error)
          );
      }
    });
  }

  verifyTenantSelected() {
    //verify selection is a valid selection from list
    if (!this.tenants.includes(this.createMeetingForm.value.tenant)) {
      this.createMeetingForm.controls['tenant'].setValue('');
      this.reqOptions = [];
    }
  }

  getReqs() {
    this.getMyReqsSub = this.requisitionService.getMyReqsForDropdown().subscribe(
      (result) => {
        console.log('CreateMeetingComponent: my reqs ', result.data.getMyReqs);
        this.usersReqs = result.data.getMyReqs;
        this.reqOptions = this.usersReqs
          .map((req) => ({ value: req.reqId, title: req.title }))
          .sort((reqA, reqB) => reqA.title.localeCompare(reqB.title));
      },
      (error) => {
        console.log('CreateMeetingComponent: There was an error loading reqs ', error);
      }
    );
  }

  removeAssignee(assignee): void {
    this.assignees = this.assignees.filter((item) => item.id !== assignee.id);

    console.log('this.assignees', this.assignees);
  }

  selectedAssignee(event: MatAutocompleteSelectedEvent): void {
    if (!this.assignees.includes(event.option.value)) {
      this.assignees.push(event.option.value);
      this.assigneeInput.nativeElement.value = '';
    }
    console.log('this.assignees', this.assignees);
  }

  private _filterAssignee(value: string): any[] {
    if (typeof value == 'string') {
      return this.allAssignees.filter((assignee) =>
        [assignee.firstName, assignee.lastName].join(' ').toLowerCase().includes(value.toLowerCase())
      );
    } else {
      return this.allAssignees;
    }
  }

  removeContact(contact): void {
    this.contacts = this.contacts.filter((item) => item.id !== contact.id);

    console.log('this.contacts', this.contacts);
  }

  selectedContact(event: MatAutocompleteSelectedEvent): void {
    let contact = event.option.value;
    if (!this.contacts.includes(contact)) {
      // Get the reqs on the contact and add it to this.contacts
      this.contactService
        .getContactReqs(contact.id)
        .pipe(take(1))
        .subscribe((result) => {
          let contactReqs = result.data.getContactReqs.map((ele) => {
            return ele.requisition;
          });

          this.contacts.push({
            id: contact.id,
            firstName: contact.firstName,
            lastName: contact.lastName,
            reqs: contactReqs,
          });
          this.contactInput.nativeElement.value = '';
          console.log('this.contacts', this.contacts);
        });
    }
  }

  private _filterContact(value: string): any[] {
    if (typeof value == 'string') {
      return this.allContacts
        .filter(({ id }) => !this.contacts.map(({ id }) => id).includes(id))
        .filter((contact) =>
          [contact.firstName, contact.lastName].join(' ').toLowerCase().includes(value.toLowerCase())
        );
    } else {
      return this.allContacts;
    }
  }

  prepareCreateMeeting() {
    // stop here if form is invalid
    if (this.createMeetingForm.invalid) {
      console.log('CreateMeetingComponent: form is invalid', this.createMeetingForm.invalid);

      //mark each field as touched so required fields display error state
      for (let i in this.createMeetingForm.controls) {
        this.createMeetingForm.controls[i].markAsTouched();
      }
      return;
    }

    // Add contacts to req if they don't yet belong on it.
    if (this.contactsToAddToReq.length > 0) {
      let contactIds = this.contactsToAddToReq.map((c) => c.id);
      this.contactService
        .addContactsToReq(contactIds, this.reqId)
        .pipe(take(1))
        .toPromise()
        .then(() => {
          this.createMeeting();
        })
        .catch((err) => console.log(err));
    } else {
      this.createMeeting();
    }
  }

  createMeeting() {
    let form = this.createMeetingForm.value;
    console.log('form', form);

    if (
      (form.sendInvite && (this.isGoogleAuthorized || this.isOutlookAuthorized)) ||
      (this.isOutlookAuthorized && this.createMeetingForm.get('isTeamsMeeting').value)
    ) {
      this.createCalendarEvent(form);
    }

    let assignees = this.assignees.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
    }));
    let contacts = this.contacts.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
    }));
    let dueTime = form.time ? form.time.split(':') : null;
    let dueOn = dueTime
      ? new Date(form.dueOn.setHours(dueTime[0], dueTime[1])).toISOString()
      : form.dueOn.toISOString();
    // isEmailAuthorized value determines type of calendar event being sent on the backend
    let todoInfo = {
      title: form.title,
      type: ActivityType.Meeting,
      description: form.description,
      users: assignees,
      contacts: contacts,
      reqId: form.req,
      location: form.location,
      phone: form.phone,
      duration: form.duration,
      createdBy: this.authenticatedUserInfo.attributes['custom:userId'],
      dueOn: dueOn,
      sendInvite: form.sendInvite,
      isEmailAuthorized: this.isGoogleAuthorized || this.isOutlookAuthorized,
    };
    console.log('CreateMeetingComponent: adding todo', todoInfo);

    this.todoService.createTodo(todoInfo).subscribe(
      (result) => {
        console.log('CreateMeetingComponent: todo created ', result);
        this.goBackToPreviousPage();
        this.snackBar.open('Created meeting', null, {
          duration: 4000,
          panelClass: 'success-snack-bar',
        });
      },
      (error) => {
        console.log('CreateMeetingComponent: Error creating todo ', error);
        this.goBackToPreviousPage();
        this.snackBar.open('Error creating meeting', null, {
          duration: 4000,
          panelClass: 'error-snack-bar',
        });
      }
    );
  }

  enableEditMeeting() {
    this.createMeetingForm.enable();
    this.reqFormControl.disable();
    this.tenantFormControl.disable();
    document.getElementById('editMeetingButton').style.display = 'none';
  }

  updateMeeting(): void {
    // submit form here.
    // stop here if form is invalid
    if (this.createMeetingForm.invalid) {
      console.log('CreateMeetingComponent: form is invalid', this.createMeetingForm);

      //mark each field as touched so required fields display error state
      for (let i in this.createMeetingForm.controls) {
        this.createMeetingForm.controls[i].markAsTouched();
      }
      return;
    }
    let assignees: User[] = this.assignees.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
    }));
    let contacts: Contact[] = this.contacts.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      stage: item.stage,
    }));

    let update: UpdateTodoInput = {
      id: this.todo.id,
    };
    let todoType = this.todo.type.charAt(0) + this.todo.type.slice(1).toLowerCase();

    this._updateIfChanged(update, 'title');
    this._updateIfChanged(update, 'description');
    this._updateIfChanged(update, 'duration');
    this._updateIfChanged(update, 'phone');
    this._updateIfChanged(update, 'location');

    update['users'] = assignees;
    update['contacts'] = contacts;
    update['sendInvite'] = this.createMeetingForm.get('sendInvite').value;

    if (this.createMeetingForm.get('dueOn').dirty || this.createMeetingForm.get('time').dirty) {
      let dueTime = this.createMeetingForm.get('time').value
        ? this.createMeetingForm.get('time').value.split(':')
        : null;
      let dueDateStr = this.createMeetingForm.get('dueOn').value;

      let dueDate = new Date(dueDateStr);
      let dueOn = dueTime ? new Date(dueDate.setHours(dueTime[0], dueTime[1])).toISOString() : dueDate.toISOString();
      update['dueOn'] = dueOn;
    }

    this.todoService.updateTodo(update).subscribe(
      (result) => {
        console.log('CreateMeetingComponent: Meeting updated', result);
        this.goBackToPreviousPage();
        this.snackBar.open(`${todoType} updated`, null, {
          duration: 4000,
          panelClass: 'success-snack-bar',
        });
      },
      (error) => {
        console.log('CreateMeetingComponent: Error updating Meeting', error);
        this.goBackToPreviousPage();
        this.snackBar.open(`Error updating ${todoType}`, null, {
          duration: 4000,
          panelClass: 'error-snack-bar',
        });
      }
    );
  }

  private _updateIfChanged(updateInput: UpdateTodoInput, controlName: string): UpdateTodoInput {
    let control = this.createMeetingForm.get(controlName);
    if (control?.dirty) {
      updateInput[controlName] = control.value;
    }

    return updateInput;
  }

  // TODO: ReqId is sometimes undefined. ON DEMO
  goBackToPreviousPage(): void {
    // Reset todo because we're closing out of the window
    this.todo = null;
    this.todoService.todo = null;
    if (this.routeParams.reqId && this.routeParams.contactId) {
      this.router.navigate(['contacts', this.contactId, this.reqId]);
    } else if (this.routeParams.reqId) {
      this.router.navigate(['requisitions', this.reqId]);
    } else if (this.routeParams.contactId) {
      this.router.navigate(['contacts', this.contactId]);
    } else {
      this.router.navigate(['activity']);
    }
  }

  selectDisposition(todo) {
    const dialogRef = this.dialog.open(MeetingDispositionDialog, {
      panelClass: 'new-activity-dialog',
      data: todo,
    });
    dialogRef.afterClosed().subscribe((dispSelected) => {
      if (dispSelected) {
        this.doComplete(todo);
        this.goBackToPreviousPage();
      }
    });
  }

  doComplete(todo) {
    let todoType = todo.type.charAt(0) + todo.type.slice(1).toLowerCase();
    this.todoService.completeTodo(todo).subscribe(
      (result) => {
        console.log('TodoService: Complete todo -> result', result);
        this.snackBar.open(`${todoType} Completed`, null, {
          duration: 4000,
          panelClass: 'success-snack-bar',
        });
      },
      (error) => {
        console.log('TodoService: There was an error completing todo', error);
        this.snackBar.open(`Error completing ${todoType}`, null, {
          duration: 4000,
          panelClass: 'error-snack-bar',
        });
      }
    );
  }

  setOutlookCalendar(date) {
    this.outlookService
      .getOutlookEventsOnDate(date, Intl.DateTimeFormat().resolvedOptions().timeZone)
      .then((integrationsServiceObservable) => {
        integrationsServiceObservable.pipe(take(1)).subscribe((proxyGet) => {
          let parsedSuccess = JSON.parse(proxyGet.data.proxyGet);
          if (parsedSuccess.name != 'Error') {
            let eventsList = parsedSuccess.value;
            let eventsObj = [];
            eventsList.map((e) => {
              eventsObj.push({
                title: e.subject,
                start: e.start.dateTime,
                end: e.end.dateTime,
              });
            });
            // If there are no events to show, make calendar height small
            if (eventsObj.length < 1) {
              this.calendarOptions.height = 50;
            } else {
              this.calendarOptions.height = 300;
            }
            this.calendarOptions.events = eventsObj;
            let calendarApi = this.calendar.getApi();
            calendarApi.gotoDate(date); //Needed bc fullcalendar only shows events that the date is set to when on listDay mode
          } else {
            console.error(
              'OutlookService: There was an error getting Outlook events during the get request',
              parsedSuccess
            );
          }
        });
      });
  }

  setGoogleCalendar(date) {
    this.googleService.getGoogleEvents(date).then((eventsList) => {
      let eventsObj = [];
      eventsList.map((e) => {
        eventsObj.push({
          title: e.summary,
          start: e.start.dateTime,
          end: e.end.dateTime,
        });
      });
      // If there are no events to show, make calendar height small
      if (eventsObj.length < 1) {
        this.calendarOptions.height = 50;
      } else {
        this.calendarOptions.height = 300;
      }
      this.calendarOptions.events = eventsObj;
      let calendarApi = this.calendar.getApi();
      calendarApi.gotoDate(date); //Needed bc fullcalendar only shows events that the date is set to when on listDay mode
      console.log('GoogleService: Google events', eventsObj);
    });
  }

  getCalendar(date) {
    if (this.isOutlookAuthorized) {
      this.setOutlookCalendar(date);
    } else if (this.isGoogleAuthorized) {
      this.setGoogleCalendar(date);
    }
  }

  createCalendarEvent(form) {
    let startEndDates = this.getStartEndDates(form.dueOn, form.time, this.durationFormControl.value);
    console.log('CreateMeetingComponent: startEndDates are', startEndDates);

    if (this.isOutlookAuthorized) {
      return this.getAssigneeEmails(this.assignees).then((assigneeEmails) => {
        return this.outlookService
          .createOutlookEvent(form, startEndDates, assigneeEmails)
          .then((integrationsServiceObservable) => {
            integrationsServiceObservable.pipe(take(1)).subscribe((proxyPost) => {
              let parsedSuccess = JSON.parse(proxyPost.data.proxyPost);
              if (parsedSuccess.name != 'Error') {
                this.goBackToPreviousPage();
                // this.snackBar.open('Created meeting', null, {
                //   duration: 4000,
                //   panelClass: 'success-snack-bar'
                // });
              } else {
                console.error(
                  'OutlookService: There was an error creating the calendar event during the POST request',
                  parsedSuccess
                );
                this.goBackToPreviousPage();
                // this.snackBar.open('Error creating meeting', null, {
                //   duration: 4000,
                //   panelClass: 'error-snack-bar'
                // });
              }
            });
          });
      });
    } else if (this.isGoogleAuthorized) {
      return this.getAssigneeEmails(this.assignees).then((assigneeEmails) => {
        return this.googleService.createGoogleEvent(form, startEndDates, assigneeEmails).then((eventCreated) => {
          if (eventCreated) {
            this.goBackToPreviousPage();
            // this.snackBar.open('Created meeting', null, {
            //   duration: 4000,
            //   panelClass: 'success-snack-bar'
            // });
          } else {
            console.error('GoogleService: There was an error creating the calendar event');
            this.goBackToPreviousPage();
            // this.snackBar.open('Error creating meeting', null, {
            //   duration: 4000,
            //   panelClass: 'error-snack-bar'
            // });
          }
        });
      });
    }
  }

  getAssigneeEmails(assignees): Promise<any> {
    return new Promise(async (resolve) => {
      var attendees = [];
      for (let a = 0; a < assignees.length; ++a) {
        await this.userService
          .getUserInfo(assignees[a].id)
          .pipe(take(1))
          .toPromise()
          .then((result) => {
            attendees.push(result.data.getUserInfo.emails[0].address);
          });
      }
      console.log('stringified attendees', JSON.stringify(attendees));
      resolve(attendees);
    });
  }

  async getIsOutlookAuthorized() {
    try {
      this.isOutlookAuthorized = this.outlookService.isOutlookAuthorized();
    } catch (error) {
      console.log('getIsOutlookAuthorized Error', error);
    }
  }

  async getIsGoogleAuthorized() {
    try {
      await this.googleService.isSignedIn().then((signedIn) => {
        this.isGoogleAuthorized = signedIn;
      });
    } catch (error) {
      console.log('getIsGoogleAuthorized Error', error);
    }
  }

  async getHasTeamsForBusiness() {
    try {
      this.outlookService.hasTeamsForBusiness().then((result) => {
        this.hasTeamsForBusiness = result;
      });
    } catch (error) {
      console.log('getHasTeamsForBusiness Error', error);
    }
  }

  getStartEndDates(date, time, duration) {
    let startDate = moment(date).toISOString();
    let endDate;
    let hour = time.split(':')[0];
    let minutes = time.split(':')[1];

    startDate = moment(startDate).set('hour', hour).toISOString();
    startDate = moment(startDate).set('minutes', minutes).toISOString();

    endDate = moment(startDate).add(duration, 'm').toISOString();

    return [startDate, endDate];
  }

  ngOnDestroy() {
    this.filterMyContactsSub?.unsubscribe();
    this.filterReqContactsSub?.unsubscribe();
    this.getMyReqsSub?.unsubscribe();
    this.getMyTenantsSub?.unsubscribe();
    this.routeSub?.unsubscribe();
  }
}
