import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[focusable]',
})
export class FocusableDirective {
  constructor(private host: ElementRef) {}

  ngAfterViewInit() {
    // Workaround for angular component issue #13870
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(
      () => {
        this.host.nativeElement.focus();
      },

      0
    );
  }
}
