import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Router } from '@angular/router';

import { UserReq } from '../model/requisitions';
import { ContactService } from '../../services/contact.service';
import { ContactInput } from '../model/contacts';
import { RequisitionService } from '../../services/requisition.service';
import { Subscription } from 'rxjs';
import { Click2CallService } from 'app/services/click2call.service';

@Component({
  selector: 'mm-new-contact-dialog',
  templateUrl: './new-contact-dialog.component.html',
})
export class NewContactDialogComponent implements OnInit {
  requisitions: UserReq[] = [];
  requisitionsSub: Subscription;
  isCompanyContact: boolean = true;
  showBadge: boolean = false;
  phoneType: string;

  createContactFormGroup: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<NewContactDialogComponent>,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router,
    private reqService: RequisitionService,
    private contactService: ContactService,
    private click2CallService: Click2CallService
  ) {}

  ngOnInit() {
    this.requisitionsSub = this.reqService.getMyReqs().subscribe(
      (result) => {
        console.log('NewContactDialogComponent :: loaded by reqs with result ', result);
        this.requisitions = result.data.getMyReqs;
      },
      (error) => {
        console.log('NewContactDialogComponent :: error loading my reqs ', error);
      }
    );

    this.createContactFormGroup = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      company: [''],
      title: [''],
      nmlsId: [''],
      phone: [''],
      email: [''],
      reqId: [''],
      contactTypeToggle: [''],
    });
  }

  onSave($event): void {
    console.log('event === ', $event);
    console.log('form === ', this.createContactFormGroup);
    if (!this.createContactFormGroup.valid) {
      console.log('NewContactDialogComponent :: Form not valid. Aborting Submit.');
      return;
    }

    let input: ContactInput = {
      firstName: this.createContactFormGroup.value.firstName,
      lastName: this.createContactFormGroup.value.lastName,
    };

    if (this.createContactFormGroup.value.nmlsId) {
      input['nmlsId'] = this.createContactFormGroup.value.nmlsId;
    }

    if (this.createContactFormGroup.value.company) {
      input['company'] = this.createContactFormGroup.value.company;
    }

    if (this.createContactFormGroup.value.phone) {
      input['phones'] = [
        {
          type: 'primary',
          number: this.createContactFormGroup.value.phone,
        },
      ];
    }

    if (this.createContactFormGroup.value.email) {
      input['emails'] = [
        {
          type: 'primary',
          address: this.createContactFormGroup.value.email,
        },
      ];
    }

    if (this.createContactFormGroup.value.title) {
      input['title'] = this.createContactFormGroup.value.title;
    }

    if (this.createContactFormGroup.value.reqId) {
      input['reqId'] = this.createContactFormGroup.value.reqId;
    }

    input['isCompanyContact'] = this.isCompanyContact;
    this.contactService.addContact(input).subscribe(
      (result) => {
        const contact = result.data.addContact;
        // Navigate to single-contact or single req contact
        if (input.reqId) this.router.navigate(['contacts', contact.id, input.reqId]);
        else this.router.navigate(['contacts', contact.id]);
        this.dialogRef.close(result);
      },
      (error) => {
        console.log('NewContactDialogComponent :: Error Adding Contact. ', error);
        this.snackBar.open('Error Adding Contact', '', {
          duration: 3000,
        });
      }
    );
  }

  onChangeContactTypeClick(event): void {
    if (event.value == 'personal') {
      this.isCompanyContact = false;
    } else {
      this.isCompanyContact = true;
    }
  }

  checkPhoneNumberType(event) {
    const { value } = event.target;
    if (value.length === 16) {
      let sanitizedNumber = value.replace(/[-+()\s]/g, '');
      this.click2CallService.checkPhoneNumberType(sanitizedNumber).subscribe((result) => {
        if (result) {
          this.phoneType = result.data.checkPhoneNumberType;
          this.showBadge = !this.showBadge;
        }
      });
      console.log(value);
    }
  }

  ngOnDestroy() {
    this.requisitionsSub.unsubscribe();
  }
}
