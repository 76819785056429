import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlaybookService } from '../../services/playbook.service';
import { PlaybookTemplateReqLink } from 'app/shared/model/playbook';
import { take } from 'rxjs/operators';
import { User } from '../../shared/model/user';
import { RequisitionService } from '../../services/requisition.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mm-playbook-tab',
  templateUrl: './playbook-tab.component.html',
  styleUrls: ['./playbook-tab.component.scss'],
})
export class PlaybookTabComponent implements OnInit {
  @Output() taskChanged: EventEmitter<any> = new EventEmitter();
  @Input() reqId: string = '';
  @Input() contactId: string;
  @Input() activeTab: string = '';
  @Input() stageEnteredDate: string = '';
  @Input() stage: string = '';
  @Input() reqTenantId: string = '';
  playbookTemplateId: string = '';
  inpTaskChanged: boolean = false;
  catId: string = '';
  showSticky: boolean = false;
  reqUsers: User[] = [];
  reqUsersSub: Subscription;

  constructor(
    public dialog: MatDialog,
    public playbookService: PlaybookService,
    private reqService: RequisitionService
  ) {}

  ngOnInit() {
    // console.log(
    //   "PlaybookTabComponent ngOnInit reqId",
    //   this.reqId,
    //   this.playbookTemplateId,
    //   this.contactId,
    //   this.stage
    // );

    // Read in req users here so available for selection later - getReqUsers
    this.getReqUsers();

    if (this.stage) {
      if (this.stage.slice(-1) === 's') this.stage = this.stage.slice(0, -1);
    } else this.stage = 'prospect';

    if (this.playbookTemplateId.length === 0) {
      this.playbookService
        .readPlaybookTemplateReqLinkByReq(this.reqTenantId, this.reqId, false)
        .pipe(take(1))
        .subscribe(
          (result) => {
            const playbookTemplateReqLinks: PlaybookTemplateReqLink = result.data.readPlaybookTemplateReqLinkByReq;

            if (playbookTemplateReqLinks) this.playbookTemplateId = playbookTemplateReqLinks.playbookTemplateId;

            if (this.stage) {
              if (this.stage.slice(-1) === 's') this.stage = this.stage.slice(0, -1);
            } else this.stage = 'prospect';

            // console.log(
            //   'PlaybookTabComponent ngOnInit reqId',
            //   this.reqId,
            //   this.playbookTemplateId,
            //   this.contactId,
            //   this.stage
            // );
          },
          (error) => {
            console.log('PlaybookTabComponent: There was an error loading req playbookTemplates links ', error);
          }
        );
    } else {
      if (this.stage) {
        if (this.stage.slice(-1) === 's') this.stage = this.stage.slice(0, -1);
      } else this.stage = 'prospect';

      // console.log(
      //   'PlaybookTabComponent ngOnInit reqId',
      //   this.reqId,
      //   this.playbookTemplateId,
      //   this.contactId,
      //   this.stage
      // );
    }
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.stage) {
      if (this.stage.slice(-1) === 's') this.stage = this.stage.slice(0, -1);
    } else this.stage = 'prospect';

    // console.log(
    //   "PlaybookTabComponent ngOnChanges reqId",
    //   this.reqId,
    //   this.playbookTemplateId,
    //   this.contactId,
    //   this.stage
    // );

    // Giant hoop to make sure category is active when tab is first loaded
    // Bug / misunderstanding when a material tab is loaded but not displayed because buried in another tab
    // Even if active - indicators not rendered
    for (const propName in changes) {
      // console.log("changes propName", propName);
      if (changes.hasOwnProperty(propName) && Object.keys(changes).length === 1) {
        switch (propName) {
          case 'activeTab': {
            // console.log("activeTab changed", changes);

            if (changes.activeTab.currentValue === 'playbook') {
              // Trigger a reload categories based on a "Fake" task changed event
              this.inpTaskChanged = !this.inpTaskChanged;
            }

            break;
          }

          // case "stage": {
          //   // console.log("activeTab changed", changes);

          //   if (changes.activeTab.currentValue === "playbook") {
          //     // Trigger a reload categories based on a "Fake" task changed event
          //     this.inpTaskChanged = !this.inpTaskChanged;
          //   }

          //   break;
          // }
        }
      }
    }
  }

  onCatChanged(event: any) {
    this.catId = event.catId;
  }

  onTaskChanged(event: any) {
    // signal a task change to children
    this.inpTaskChanged = !this.inpTaskChanged;

    // console.log("PlaybookTabComponent onTaskChanged");

    // Kick the task changed event on up the chain
    this.taskChanged.emit(event);
  }

  getReqUsers(startDate = null, endDate = null) {
    this.reqUsersSub = this.reqService.getReqUsers(this.reqId, startDate, endDate).subscribe(
      (result) => {
        this.reqUsers = result.data.getReqUsers;
        //console.log('PlaybookTabComponent : Requisition reqUsers == ', this.reqUsers);

        this.reqUsersSub.unsubscribe();
      },
      (error) => {
        console.log('PlaybookTabComponent : Error fetching requisition reqUsers == ', error);
      }
    );
  }

  getSticky() {
    return this.showSticky ? 'sticky' : 'standard';
  }
}
