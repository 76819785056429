import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, Inject } from '@angular/core';
import {
  PlaybookTemplate,
  PlaybookTask,
  PlaybookCategory,
  newTaskTemplate,
  PlaybookTaskStatuses,
  PlaybookTaskStatusesArr,
  PlaybooksReqContact,
  PlaybookTaskStagesArr,
} from 'app/shared/model/playbook';
import { PlaybookService } from '../../../services/playbook.service';
import { TodoService } from '../../../services/todo.service';
import { MatRadioChange } from '@angular/material/radio';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TaskEditDialog } from './task-edit-dialog.component';
import { UserService } from '../../../services/user.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { User } from '../../../shared/model/user';
import { HistoryInput } from '../../../shared/model/activity';
import { ContactService } from 'app/services/contact.service';

@Component({
  selector: 'mm-playbook-task-edit-kanban',
  templateUrl: './task-edit-kanban.component.html',
  styleUrls: ['./task-edit-kanban.component.scss'],
})
export class PlaybookContactTaskEditKanbanComponent implements OnInit {
  @Output() taskChanged: EventEmitter<any> = new EventEmitter();
  @Input() reqId: string;
  @Input() contactId: string;
  @Input() playbookTemplateId: string;
  @Input() reqTenantId: string = '';
  @Input() catId: string = '';
  @Input() stage: string;
  @Input() viewStyle: string = 'standard';
  @Input() stageEnteredDate: string = '';
  @Input() reqUsers: User[] = [];

  playbookReqContact: PlaybooksReqContact = null;
  playbookTemplate: PlaybookTemplate = null;
  playbookTasks: PlaybookTask[] = null;
  catIndex: number = -1;
  stageIndex: number = -1;

  userInfoSub: Subscription;
  userIdSub: Subscription;
  userInfo: any;
  userId: String;

  columns = PlaybookTaskStatusesArr;

  stages: string[] = PlaybookTaskStagesArr;

  dataLoaded: boolean = false;
  dataLinksLoaded: boolean = false;

  constructor(
    private playbookService: PlaybookService,
    private todoService: TodoService,
    public dialog: MatDialog,
    private userService: UserService,
    private contactService: ContactService
  ) {}

  ngOnInit() {
    // console.log("PlaybookContactTaskEditKanbanComponent QQQ ngOnInit", this.catId, this.reqId, this.contactId, this.playbookTemplateId);

    if (this.reqId && this.contactId && this.playbookTemplateId) {
      // Read in source template for description and other values that are not user changeable
      // This allows changes to top level template to propagate to lower templates
      this.dataLoaded = false;
      this.playbookService
        .readPlaybookTemplate(this.reqTenantId, this.playbookTemplateId)
        .pipe(take(1))
        .subscribe(
          (result) => {
            // console.log("PlaybookCategoriesEditorComponent: playbookTemplate ", result.data.readPlaybookTemplate);

            this.playbookTemplate = result.data.readPlaybookTemplate;

            // Let UI know data loading is complete
            this.dataLoaded = true;

            // Get default first category Id if none is specified
            if (this.catId.length === 0) {
              this.catId = this.playbookTemplate.categories[0].id;
            }

            // Get catIndex from CatId
            this.catIndex = this.playbookTemplate.categories.findIndex((thisCat) => thisCat.id === this.catId);

            this.stageIndex = this.playbookTemplate.categories[this.catIndex].stages.findIndex(
              (thisStage) => thisStage.id === this.stage
            );
            console.log(this.playbookTemplate.categories[this.catIndex].stages);
            // Get tasks from template by unique category Id - get first one by default
            this.playbookTasks = this.playbookTemplate.categories[this.catIndex].stages[this.stageIndex]?.tasks;
            // Read in for ReqContact specific values
            this.playbookService
              .readSinglePlaybookReqContact(this.reqTenantId, this.reqId, this.contactId, this.playbookTemplateId)
              .pipe(take(1))
              .subscribe(
                (result) => {
                  this.playbookReqContact = result.data.readSinglePlaybookReqContact;

                  this.dataLoaded = true;
                  // console.log("playbookReqContact", this.playbookReqContact);
                },
                (error) => {
                  console.log('PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact error ', error);

                  this.dataLoaded = true;
                }
              );
          },
          (error) => {
            console.log('PlaybookCategoriesEditorComponent: There was an error loading playbookTemplate ', error);
            // Even though error still let ui data is finished loading
            this.dataLoaded = true;
          }
        );

      this.loadUserInfo();
    }
  }

  ngOnDestroy() {
    // do NOT forget to unsubscribe from subscriptions
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log("PlaybookContactTaskEditKanbanComponent ngOnChanges", this.catId, this.reqId, this.contactId, this.playbookTemplateId, this.stageEnteredDate, changes);

    if (this.reqId && this.contactId && this.playbookTemplateId) {
      // Read in source template for description and other values that are not user changeable
      // This allows changes to top level template to propagate to lower templates
      this.dataLoaded = false;
      this.playbookService
        .readPlaybookTemplate(this.reqTenantId, this.playbookTemplateId)
        .pipe(take(1))
        .subscribe(
          (result) => {
            // console.log("PlaybookCategoriesEditorComponent: playbookTemplate ", result.data.readPlaybookTemplate);

            this.playbookTemplate = result.data.readPlaybookTemplate;

            // Let UI know data loading is complete
            this.dataLoaded = true;

            // Get default first category Id if none is specified
            if (this.catId.length === 0) {
              this.catId = this.playbookTemplate.categories[0].id;
            }

            // Get catIndex from CatId
            this.catIndex = this.playbookTemplate.categories.findIndex((thisCat) => thisCat.id === this.catId);

            this.stageIndex = this.playbookTemplate.categories[this.catIndex].stages.findIndex(
              (thisStage) => thisStage.id === this.stage
            );

            // Get tasks from template by unique category Id - get first one by default
            this.playbookTasks = this.playbookTemplate.categories[this.catIndex].stages[this.stageIndex]?.tasks;

            // Read in for ReqContact specific values
            this.playbookService
              .readSinglePlaybookReqContact(this.reqTenantId, this.reqId, this.contactId, this.playbookTemplateId)
              .pipe(take(1))
              .subscribe(
                (result) => {
                  this.playbookReqContact = result.data.readSinglePlaybookReqContact;

                  this.dataLoaded = true;
                  // console.log("PlaybookContactTaskEditKanbanComponent ngOnChanges this.playbookReqContact", this.playbookReqContact);
                },
                (error) => {
                  console.log('PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact error ', error);

                  this.dataLoaded = true;
                }
              );
          },
          (error) => {
            console.log('PlaybookCategoriesEditorComponent: There was an error loading playbookTemplate ', error);
            // Even though error still let ui data is finished loading
            this.dataLoaded = true;
          }
        );

      this.loadUserInfo();
    }

    // const currentItem: SimpleChange = changes.item;
    // console.log("prev value: ", currentItem.previousValue);
    // console.log("got item: ", currentItem.currentValue);
  }

  dropKanban(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      // moveItemInArray(
      //   event.container.data,
      //   event.previousIndex,
      //   event.currentIndex
      // );
    } else {
      // transferArrayItem(
      //   event.previousContainer.data,
      //   event.container.data,
      //   event.previousIndex,
      //   event.currentIndex
      // );

      // Change the tasks status
      // Clarity over brevity - don't really need to break out but...
      // Get editable Category matched to Template

      // Get catIndex from CatId
      this.catIndex = this.playbookReqContact.playbookObj.categories.findIndex((thisCat) => thisCat.id === this.catId);

      this.stageIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages.findIndex(
        (thisStage) => thisStage.id === this.stage
      );

      const currTaskIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages[
        this.stageIndex
      ].tasks.findIndex((task) => task.id === event.item.data.id);

      const currTask =
        this.playbookReqContact.playbookObj.categories[this.catIndex].stages[this.stageIndex].tasks[currTaskIndex];

      const oldStatus = currTask.status;
      currTask.status = event.container.data.toString();
      console.log(`${oldStatus} changed to ${currTask.status}`);
      // console.log(currTask);

      let historyInput: HistoryInput = {
        type: 'PLAYBOOK',
        note: `Task - ${currTask.title} changed from ${oldStatus} to ${currTask.status}`,
        contactId: this.contactId,
        reqId: this.reqId,
        isPublic: true,
        analytics: JSON.stringify({
          catId: this.catIndex,
          taskId: currTask.id,
          previousStatus: oldStatus,
          newStatus: currTask.status,
        }),
      };

      // console.log("Playbook Item Edit Component :: addNote ", input);

      // Add playbook history and keep contact's lastUpdatedAt field in sync with history
      this.todoService.addHistory(historyInput).subscribe(
        (result) => {
          this.contactService
            .updateContactLastUpdatedAtField(this.contactId, this.reqId, 'true')
            .pipe(take(1))
            .subscribe(
              (result) => {},
              (error) => {
                console.log('An error occurred updated contact fields after editing playbook.', error);
              }
            );
        },
        (error) => {
          console.log('There was an error updating task status', error);
        }
      );

      // Save this playbook data
      this.playbookService
        .updatePlaybookReqContact(this.reqId, this.contactId, this.playbookTemplateId, this.playbookReqContact)
        .pipe(take(1))
        .subscribe(
          (result) => {
            // console.log("updatePlaybookReqContact: my playbookReqContact ", result.data.updatePlaybookTemplate);

            this.taskChanged.emit({
              playbookReqContactId: this.playbookReqContact.id,
              playbookTemplateId: this.playbookReqContact.playbookObj.id,
              catId: this.catId,
              taskId: event.item.data.id,
              stage: this.stage,
            });
          },
          (error) => {
            console.log('updatePlaybookReqContact: There was an error updating playbookReqContact', error);
          }
        );
    }
  }

  // Get the editable task from matching template task Id for current category Id
  getReqContactTask(taskId: string): PlaybookTask {
    // Be sure to read the task info from the ReqContact version here

    this.catIndex = this.playbookReqContact.playbookObj.categories.findIndex((thisCat) => thisCat.id === this.catId);

    // console.log(
    //   "getReqContactTask",
    //   this.playbookReqContact.playbookObj.categories
    // );

    this.stageIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages.findIndex(
      (thisStage) => thisStage.id === this.stage
    );

    // console.log("getReqContactTask this.stageIndex", this.stageIndex);

    const currTaskIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages[
      this.stageIndex
    ]?.tasks.findIndex((task) => task.id === taskId);

    // console.log(
    //   "getReqContactTask this.currTaskIndex taskId",
    //   currTaskIndex,
    //   taskId,
    //   this.playbookReqContact
    // );

    let currTask =
      this.playbookReqContact.playbookObj.categories[this.catIndex].stages[this.stageIndex]?.tasks[currTaskIndex];

    // console.log("getReqContactTask currTask", currTask);

    // Handle case of no tasks defined so ui does not throw errors trying to read it
    if (!currTask) {
      currTask = JSON.parse(JSON.stringify(newTaskTemplate));
      currTask.status = 'none';
    }

    return currTask;
  }

  onEditTask(event: any, index: number, taskId: string) {
    // Change the tasks status
    const dialogConfig = new MatDialogConfig();

    // Get editable Category matched to Template
    const currCatRC = this.playbookReqContact.playbookObj.categories.filter((cat) => cat.id === this.catId)[0];

    // Get editable task matched to Category
    const currTaskRC = currCatRC?.stages[this.stageIndex].tasks.filter((task) => task.id === taskId)[0];

    const currCat = this.playbookTemplate.categories.filter((cat) => cat.id === this.catId)[0];

    // Get editable task matched to Category
    const currTask = currCat?.stages[this.stageIndex].tasks.filter((task) => task.id === taskId)[0];

    // dialogConfig.width = "450px";

    dialogConfig.maxWidth = '100%';
    dialogConfig.minWidth = '60%';
    dialogConfig.panelClass = 'playbook-task-edit-dialog';

    dialogConfig.data = {
      taskRC: currTaskRC,
      task: currTask,
      catId: this.catId,
      playbookReqContactId: this.playbookReqContact.id,
      userInfo: this.userInfo,
      stage: this.stage,
      contactId: this.contactId,
      reqId: this.reqId,
      stageEnteredDate: this.stageEnteredDate,
      playbookTemplateId: this.playbookTemplateId,
      reqTenantId: this.reqTenantId,
      reqUsers: this.reqUsers,
    };

    const dialogRef = this.dialog.open(TaskEditDialog, dialogConfig);

    const sub = dialogRef.componentInstance.taskChanged.subscribe((event: any) => {
      // do something
      // console.log("Dialog task changed", event);

      this.taskChanged.emit(event);

      this.ngOnChanges(null);
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log("The dialog was closed", result);

      sub.unsubscribe();

      // if (result.colorChoice) {
      //   this.activity = result.activity;
      //   this.colorChoice = result.colorChoice;

      //   // Change the tasks status
      //   currCat.tasks[index].color = result.colorChoice;

      //   // Save this playbook
      //   this.playbookService.updatePlaybookReqContact(this.playbookReqContact);
      // }
    });
  }

  loadUserInfo() {
    if (this.userInfoSub) {
      this.userInfoSub.unsubscribe();
    }

    this.userIdSub = this.userService.getAuthenticatedUserId().subscribe(
      (userId: string) => {
        this.userId = userId;
        this.userInfoSub = this.userService.getUserInfo(this.userId).subscribe((result) => {
          this.userInfo = result.data.getUserInfo;
          // console.log("getUserInfo result, userId", result, userId);
          // console.log("getUserInfo this.userInfo", this.userInfo);

          if (this.userInfoSub) this.userInfoSub.unsubscribe();
          if (this.userIdSub) this.userIdSub.unsubscribe();
          this.userInfoSub = null;
        });
      },
      (error) => {
        console.log('PlaybookContactTaskEditKanbanComponent: Error getting authenticated userId -> ' + error.message);
      }
    );
  }

  getAssignedPrimaryMember(playbookTask: PlaybookTask): string {
    let primaryMember: string = '';

    if (this.userInfo) {
      // Default to blank if not assigned
      // if (this.userInfo.firstName && this.userInfo.lastName)
      //   primaryMember = this.userInfo.firstName + " " + this.userInfo.lastName;
    }

    if (playbookTask.assignedTo) {
      if (playbookTask.assignedTo.length > 0) primaryMember = playbookTask.assignedTo[0];
    }

    return primaryMember;
  }

  getDSE(DSE: string): string {
    let retVal = '';

    // Is there a DSE
    if (DSE && parseInt(DSE) !== 0) {
      // If there is a DSE is there a stageEnteredDate
      if (this.stageEnteredDate) {
        let dateDiff = moment().diff(moment(this.stageEnteredDate), 'days');
        let dueDays = parseInt(DSE) - Math.abs(dateDiff);

        // Past due
        if (dueDays === -1) {
          retVal = `<span class="past-due-item">Past Due ${Math.abs(dueDays)} day</span>`;
        } else if (dueDays < 0) {
          retVal = `<span class="past-due-item">Past Due ${Math.abs(dueDays)} days</span>`;
        }
        // Due today
        else if (dueDays === 0) {
          retVal = `<span class="today-due-item">Due Today</span>`;
        }
        // Due soon
        else if (dueDays === 1) {
          retVal = `Due by Tomorrow`;
        }
        // Due in future
        else if (dueDays > 1) {
          retVal = `Due in ${Math.abs(dueDays)} days`;
        }
      }
    }

    return retVal;
  }
}
