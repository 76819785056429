import { Component, Inject, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ReportObj,
  ReportObjMenuItem,
  ReportTemplateSettings,
  esFilter,
  ReportTemplate,
  ReportTemplateHistory,
} from '../../../shared/model/reporting';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subscription, Subject } from 'rxjs';
import { ReportingService } from '../../../services/reporting.service';
import { AlertDialogComponent, AlertDialogConfig } from '../../../shared/dialogs/alert-dialog.component';
import { UserService } from '../../../services/user.service';
import { Permissions, PermissionsService } from 'app/services/permissions.service';
@Component({
  selector: 'mm-report-template-picker-dialog',
  templateUrl: './report-template-picker-dialog.component.html',
  styleUrls: ['./report-template-picker-dialog.component.scss'],
})
export class ReportTemplatePickerDialogComponent implements OnInit, OnDestroy {
  userIA: Permissions;
  private unsubscribe$ = new Subject<void>();
  menuItems: ReportTemplate[] = [];
  searchReqsControl = new FormControl();
  filteredMenuItems: Observable<ReportTemplate[]>;
  systemTemplatesFilter: boolean = false;
  tenantTemplatesFilter: boolean = false;
  userTemplatesFilter: boolean = false;
  mmAdmin: boolean = false;
  tenantAdmin: boolean = false;
  tenantLeader: boolean = false;
  defaultTemplate: ReportTemplateHistory = {
    id: '0',
    title: '',
    desc: '',
  };

  @ViewChild('searchObjListInput')
  searchObjListInput: ElementRef<HTMLInputElement>;

  constructor(
    private dialogRef: MatDialogRef<ReportTemplatePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private router: Router,
    private reportingService: ReportingService,
    private confirmDialog: MatDialog,
    private userService: UserService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.menuItems = this.data.reportTemplates;

    this.readDefaultTemplate();

    // console.log('ReportTemplatePickerDialogComponent this.menuItems', this.menuItems);

    this.userService
      .getAuthenticatedUserGroups()
      .pipe(
        take(1)
        // map(({ data }) => data.getTenant.user.toggleActive = )
      )
      .subscribe(
        (result) => {
          this.mmAdmin = result.includes('mmAdmin');
          this.tenantAdmin = result.includes('tenantAdmin');
          this.tenantLeader = result.includes('tenantLeader');
        },
        (error) => {
          console.log('getAuthenticatedUserGroups: There was an error loading tenants ', error);
        }
      );

    this.filteredMenuItems = this.searchReqsControl.valueChanges.pipe(
      startWith<string | ReportTemplate>(''),
      map((value) => {
        // console.log("filteredMenuItems map value ", value);
        return value;
      }),
      // If name is "" or undefined - then return shallow copy of entire array
      map((title: string) => {
        let retVal = this._filterMenuItems(title);

        // let retVal = title ? this._filterMenuItems(title) : this.menuItems.slice();
        // console.log("retVal map value ", title, retVal, this.reqs);
        return retVal;
      })
    );
  }

  // Method to filter users list based on what has been typed so far in search box
  private _filterMenuItems(title: string): ReportTemplate[] {
    const filterValue = title?.toLowerCase();

    let menuItemsBuff = JSON.parse(JSON.stringify(this.menuItems));

    return menuItemsBuff.filter((currObj) => {
      // Combine text search as well as systemTarget from pKey
      // everything by default
      let searchFilter = true;
      let systemFilter = true;
      let userFilter = true;

      // Then progressively filter down
      if (title)
        searchFilter =
          currObj?.title?.toLowerCase().indexOf(filterValue) >= 0 ||
          currObj?.desc?.toLowerCase().indexOf(filterValue) >= 0;

      if (this.systemTemplatesFilter) systemFilter = currObj.pKey === 'SYSTEM';

      if (this.userTemplatesFilter) userFilter = currObj.pKey.includes('USER$');

      // console.log(
      //   '_filterMenuItems running this.systemTemplatesFilter systemFilter',
      //   this.systemTemplatesFilter,
      //   systemFilter
      // );

      // Combine all filter checks
      return searchFilter && systemFilter && userFilter;
    });
  }

  clearAll() {
    let data = { ReportTemplate: null, clear: true };

    this.dialogRef.close({ data: data });
  }

  onClickAdd(reportTemplate: ReportTemplate) {
    // Pass back new user to assign todos data
    let data = { reportTemplate: reportTemplate };

    this.dialogRef.close({ data: data });

    let url = `reporting/analytics/${reportTemplate.id}`;

    this.router
      .navigateByUrl(url)
      .then((bool) => {})
      .catch();
  }

  onCloseClick() {
    // Pass back new user to assign todos data
    let data = { ReportTemplate: null };

    this.dialogRef.close({ data: data });
  }

  showSystemTemplates() {
    // console.log('showSystemTemplates');

    this.systemTemplatesFilter = true;
    this.tenantTemplatesFilter = false;
    this.userTemplatesFilter = false;

    // Trigger re-filter on new array of items based on current filters
    this.searchReqsControl.setValue(this.searchReqsControl.value, {
      emitEvent: true,
    });
  }

  showTenantTemplates() {
    this.systemTemplatesFilter = false;
    this.tenantTemplatesFilter = true;
    this.userTemplatesFilter = false;

    // Trigger re-filter on new array of items based on current filters
    this.searchReqsControl.setValue(this.searchReqsControl.value, {
      emitEvent: true,
    });
  }

  showUserTemplates() {
    this.systemTemplatesFilter = false;
    this.tenantTemplatesFilter = false;
    this.userTemplatesFilter = true;

    // Trigger re-filter on new array of items based on current filters
    this.searchReqsControl.setValue(this.searchReqsControl.value, {
      emitEvent: true,
    });
  }

  showAllTemplates() {
    this.systemTemplatesFilter = false;
    this.tenantTemplatesFilter = false;
    this.userTemplatesFilter = false;

    // Trigger re-filter on new array of items based on current filters
    this.searchReqsControl.setValue(this.searchReqsControl.value, {
      emitEvent: true,
    });
  }

  onOptions(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDeleteTemplate(event: any, mItem: ReportTemplate) {
    event.preventDefault();
    event.stopPropagation();

    let systemTarget = '';

    if (mItem.pKey === 'SYSTEM') systemTarget = 'SYSTEM';

    // ***TODO Add Are you sure confirmation

    this.confirmDiscardTemplate().subscribe(
      (discardTemplateConfirmed) => {
        if (discardTemplateConfirmed) {
          this.reportingService
            .logicalDeleteReportingTemplate(mItem.id, null, systemTarget)
            .pipe(take(1))
            .subscribe(
              (result) => {
                // console.log("onDeleteTemplate :: result ", result);

                // Remove menu item by Id from main list
                let index = this.menuItems.findIndex((item) => item.id === mItem.id);
                this.menuItems.splice(index, 1);

                // Trigger re-filter on new array of items based on current filters
                this.searchReqsControl.setValue(this.searchReqsControl.value, {
                  emitEvent: true,
                });
              },
              (error) => {
                console.log('loadReportTemplates User :: Error  ', error);
              }
            );
        }
      },
      (error) => {
        console.log('TaskEditDialog Component - confirmDiscardNote error: ', error);
      }
    );
  }

  saveSys(event: any, mItem: ReportTemplate) {
    // console.log('saveSys', mItem);

    // this.savingReportingTemplate = true;

    let reportTemplateBuff = JSON.parse(JSON.stringify(mItem));

    reportTemplateBuff.reportObjects.forEach((reportObj) => {
      // Remove report filters
      reportObj.data.selectedReqIds = [];
      reportObj.data.selectedUserIds = [];
      reportObj.data.selectedReqs = [];
      reportObj.data.selectedUsers = [];

      delete reportObj.data.raw;
    });

    // Remove global filters
    reportTemplateBuff.reportTemplateSettings.selectedReqIds = [];
    reportTemplateBuff.reportTemplateSettings.selectedUserIds = [];

    console.log('reportTemplateBuff', reportTemplateBuff);
    console.log('reportTemplateBuff.reportTemplateBuff.reportTemplateSettings', reportTemplateBuff);

    // since it is save as new - remove the current .id
    reportTemplateBuff.id = '';

    this.reportingService
      .createReportingTemplate(reportTemplateBuff, null, 'SYSTEM')
      .pipe(take(1))
      .subscribe(
        (result) => {
          // console.log("getReportingCounts User :: result ", result);

          // Create new needs to return new template so we can get Id

          // this.savingReportingTemplate = false;

          // Reload remote templates on every change so picker keeps up to date
          // Do reload instead of local update to help keep in sync with other possible remote changes

          this.menuItems.push(JSON.parse(result.data.createReportingTemplate));

          // Trigger re-filter on new array of items based on current filters
          this.searchReqsControl.setValue(this.searchReqsControl.value, {
            emitEvent: true,
          });
        },
        (error) => {
          console.log('saveReportingTemplate System :: Error', error);

          // this.savingReportingTemplate = false;
        }
      );
  }

  confirmDiscardTemplate(): Observable<boolean> {
    const dialogConfig: AlertDialogConfig = {
      title: 'Delete this template?',
      message: 'Warning: This template will no longer be available!',
      confirmButton: {
        label: 'Delete, yes?',
        color: 'warn',
      },
    };

    const dialogRef = this.confirmDialog.open<AlertDialogComponent, AlertDialogConfig, boolean>(AlertDialogComponent, {
      panelClass: 'alert-dialog-component',
      data: dialogConfig,
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  permissionDelete(mItem: ReportTemplate) {
    if (mItem.pKey === 'SYSTEM' && this.mmAdmin) return true;

    if (mItem.pKey === 'TENANT' && this.tenantAdmin) return true;

    if (mItem.pKey === 'TENANT' && this.tenantLeader) return true;

    if (mItem.pKey.includes('USER$')) return true;

    return false;
  }

  permissionSaveSystem(mItem: ReportTemplate) {
    // Can only save user templates to system AND must be a mmAdmin
    if (mItem.pKey.includes('USER$') && this.mmAdmin) return true;

    return false;
  }

  saveAsDefault(mItem: ReportTemplate) {
    this.defaultTemplate = {
      id: mItem.id,
      title: mItem.title,
      desc: mItem.desc,
    };

    this.userService
      .setUserSettingKey('TEMPLATE_DEFAULT', JSON.stringify(this.defaultTemplate))
      .pipe(take(1))
      .subscribe(
        (result) => {},
        (error) => {
          console.log('template default: There was an error setting key ', error);
        }
      );
  }

  clearAsDefault(mItem: ReportTemplate) {
    this.defaultTemplate = {
      id: '0',
      title: '',
      desc: '',
    };

    this.userService
      .setUserSettingKey('TEMPLATE_DEFAULT', JSON.stringify(this.defaultTemplate))
      .pipe(take(1))
      .subscribe(
        (result) => {},
        (error) => {
          console.log('template default: There was an error setting key ', error);
        }
      );
  }

  readDefaultTemplate() {
    this.userService
      .readUserSettingKey('TEMPLATE_DEFAULT')
      .pipe(take(1))
      .subscribe(
        (result) => {
          // Only change default if setting found
          if (result.data.readUserSettingKey?.settingValue)
            this.defaultTemplate = JSON.parse(result.data.readUserSettingKey.settingValue);
        },
        (error) => {
          console.log('template default: There was an error setting key ', error);
        }
      );
  }

  getPermissions() {
    this.permissionsService.permissions$.pipe(takeUntil(this.unsubscribe$)).subscribe((userIA) => {
      console.log('permissions updated single req', userIA);
      this.userIA = userIA;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
