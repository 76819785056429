import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'mm-snackbar',
  templateUrl: 'mm-snackbar.component.html',
  styleUrls: ['./mm-snackbar.component.scss'],
})
export class MMSnackBarComponent implements OnInit {
  @Input() message: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) {
    this.message = data;
  }

  ngOnInit() {}
}
