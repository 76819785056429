<div [ngClass]="['report-page-container', reportingTheme]">
  <button
    *ngIf="!preview && !this.useStoredData"
    mat-icon-button
    class="button-settings"
    [matMenuTriggerFor]="menuReportOptions"
  >
    <mat-icon>build</mat-icon>
  </button>

  <mat-menu #menuReportOptions="matMenu" class="options-menu">
    <button mat-menu-item (click)="onSettings()"><mat-icon>settings</mat-icon>Settings</button>

    <button mat-menu-item (click)="ngOnChanges(null)"><mat-icon>refresh</mat-icon>Refresh</button>

    <button mat-menu-item (click)="onClone()"><mat-icon>content_copy</mat-icon>Clone</button>

    <mat-divider></mat-divider>

    <button mat-menu-item (click)="onClickRemove()"><mat-icon>delete</mat-icon>Remove</button>
  </mat-menu>

  <mat-card class="card-chart">
    <mat-card-header class="card-header-chart" [ngClass]="['card-header-chart', data.color]">
      <div class="main-chart-container" style="display: block; min-height: 250px">
        <canvas
          baseChart
          width="250"
          height="250"
          [datasets]="pieChartData"
          [labels]="pieChartLabels"
          [options]="pieChartOptions"
          [colors]="pieChartColors"
          [chartType]="data.chartType"
          [plugins]="pieChartPlugins"
          class="baseCanvas"
          [legend]="pieChartLegend"
        >
        </canvas>

        <!--  -->
      </div>
    </mat-card-header>
    <mat-card-content class="card-content-chart">
      <div (click)="toggleDetails()" [ngClass]="useStoredData ? '' : 'more-details'">
        <mat-form-field *ngIf="preview" mat-form-field class="name-input" (click)="onClickNameEvent($event)">
          <input matInput required [(ngModel)]="data.name" />
        </mat-form-field>

        <h4 *ngIf="!preview" class="headerText">{{ data.name | titlecase }}</h4>

        <hr class="card-footer-separator" />

        <div class="card-footer-content">
          <!-- <mat-icon class="footer-note-icon">access_time</mat-icon>Last {{ lastAction }} -->
        </div>
      </div>

      <report-slide-panel [activePane]="data.slideRight ? 'right' : 'left'">
        <div leftPane></div>
        <div rightPane>
          <div *ngIf="rawDetails" style="margin-top: 1em">
            <div>
              <table mat-table [dataSource]="dataSource" style="width: 100%" matSort (matSortChange)="sortData($event)">
                <!-- Build table from details data - use default or report option settings  -->
                <ng-container
                  *ngFor="let detail of data.details; let colIndex = index"
                  matColumnDef="{{ detail.field }}"
                >
                  <!-- Dont show sort options on Pipelines - will sort by Id not name so hide option for now -->
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [mat-sort-header]="detail.field !== 'reqId' ? detail.field : null"
                    [disabled]="detail.field === 'reqId' ? true : false"
                  >
                    {{ detail.display }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a *ngIf="detail.field === 'reqId'" (click)="onLinkToReq(element._source[detail.field])">
                      {{ element._source[detail.field] | todoReqName: reqs }}</a
                    >

                    <a *ngIf="detail.field === 'req'" (click)="onLinkToReq(element._source[detail.field]?.id)">
                      {{ element._source[detail.field]?.id | todoReqName: reqs }}</a
                    >

                    <div *ngIf="detail.field === 'contacts'">
                      <a
                        *ngFor="let contact of element._source[detail.field]"
                        (click)="onLinkToContact(element._source.reqId, contact)"
                      >
                        {{ contact.firstName }} {{ contact.lastName }}</a
                      >
                    </div>

                    <div
                      *ngIf="detail.field === 'contactId' && !element._source.firstName && !element._source.lastName"
                    >
                      <a (click)="onLinkToContactById(element._source.reqId, element._source.contactId)">
                        {{ element._source.contactId }}
                      </a>
                    </div>

                    <div *ngIf="detail.field === 'contactId' && element._source.firstName && element._source.lastName">
                      <a (click)="onLinkToContactById(element._source.reqId, element._source.contactId)">
                        {{ element._source.firstName }}
                        {{ element._source.lastName }}
                      </a>
                    </div>

                    <div *ngIf="detail.field === 'contact' && !element._source.firstName && !element._source.lastName">
                      <a (click)="onLinkToContactById(element._source.reqId, element._source.contact?.id)">
                        {{ element._source.contact?.firstName }}
                        {{ element._source.contact?.lastName }}
                      </a>
                    </div>

                    <div *ngIf="detail.field === 'completedBy'">
                      {{ element._source[detail.field]?.firstName }}
                      {{ element._source[detail.field]?.lastName }}
                    </div>

                    <div *ngIf="detail.field === 'created' || detail.field === 'completedDate'">
                      {{ element._source[detail.field] | date: 'shortDate' }}
                    </div>

                    <div *ngIf="detail.field === 'production'">
                      {{
                        getTrailing12FromProduction(element._source[detail.field])
                          | currency: 'USD' : 'symbol' : '1.0-0'
                      }}
                    </div>

                    <div *ngIf="detail.field === 'opportunityValue'">
                      {{
                        element._source['contacts']
                          | opportunityValue: element._source['reqId']
                          | async
                          | currency: 'USD' : 'symbol' : '1.0-0'
                      }}
                    </div>

                    <div *ngIf="detail.field.split('.').length > 1">
                      {{ resolvePath(element._source, detail.field) }}
                    </div>

                    <div
                      *ngIf="detail.field === 'tags'"
                      style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap"
                    >
                      <span *ngFor="let tag of element._source[detail.field]" style="margin-right: 0.5em">
                        {{ tag.name }}
                      </span>
                    </div>

                    <div
                      *ngIf="
                        detail.field !== 'tags' &&
                        detail.field !== 'reqId' &&
                        detail.field !== 'req' &&
                        detail.field !== 'contacts' &&
                        detail.field !== 'contactId' &&
                        detail.field !== 'contact' &&
                        detail.field !== 'completedBy' &&
                        detail.field !== 'created' &&
                        detail.field !== 'completedDate' &&
                        detail.field !== 'production' &&
                        detail.field.split('.').length < 2
                      "
                    >
                      {{ element._source[detail.field] }}
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>

              <app-paginator (pageChanged)="onChangePage($event)" [pageSizeOptions]="[5, 10, 50, 100]" [page]="page">
              </app-paginator>
            </div>
          </div>
        </div>
      </report-slide-panel>
    </mat-card-content>

    <mat-card-footer class="card-content-chart-footer">
      <!-- Display local date filter over-rides if any -->
      <div class="card-footer-filter-content">
        <span *ngIf="data.startDate">
          {{ data.startDate | date: 'shortDate' }} - {{ data.endDate | date: 'shortDate' }}</span
        >

        <a
          *ngIf="data.selectedReqIds?.length"
          matBadge="{{ data.selectedReqIds?.length }}"
          matBadgeOverlap="false"
          [matMenuTriggerFor]="reqFilters2"
        >
          Reqs</a
        >

        <mat-menu #reqFilters2="matMenu" xPosition="before">
          <button mat-menu-item *ngFor="let req2 of data.selectedReqs">
            <span>{{ req2.title }}</span>
          </button>
        </mat-menu>

        <a
          *ngIf="data.selectedUsers?.length"
          matBadge="{{ data.selectedUsers?.length }}"
          matBadgeOverlap="false"
          [matMenuTriggerFor]="userFilters2"
          >Users</a
        >

        <mat-menu #userFilters2="matMenu" xPosition="before">
          <button mat-menu-item *ngFor="let user2 of data.selectedUsers">
            <span>{{ user2.firstName }} {{ user2.lastName }}</span>
          </button>
        </mat-menu>

        <span *ngIf="data.tagsFilter"> {{ data.tagsFilter }}</span>

        <span *ngIf="data.sequenceFilter"> {{ data.sequenceFilter?.title }}</span>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
