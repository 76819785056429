import { Component, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { v4 as uuid } from 'uuid';
import {
  PlaybookTemplate,
  PlaybooksReqContact,
  newPlaybookTemplate,
  newPlaybooksReqContactTemplate,
  PlaybookTemplateReqLink,
  newPlaybookTemplateReqLinkTemplate,
} from 'app/shared/model/playbook';
import { Title } from '@angular/platform-browser';

import { Apollo, gql, QueryRef } from 'apollo-angular';
import { tap, take, map } from 'rxjs/operators';

import {
  addPlaybookTemplate,
  updatePlaybookTemplate,
  logicalDeletePlaybookTemplate,
  createPlaybookReqContact,
  updatePlaybookReqContact,
  createPlaybookTemplateReqLink,
  updatePlaybookTemplateReqLink,
  deletePlaybookTemplateReqLink,
} from '../graphql/mutations';
import {
  listPlaybookTemplates,
  readPlaybookTemplate,
  readSinglePlaybookReqContact,
  readPlaybookTemplateReqLinkByReq,
  listPlaybookTemplateReqLinks,
} from '../graphql/queries';

import {
  ListPlaybookTemplates,
  ReadPlaybookTemplate,
  ReadPlaybookReqContact,
  UpdatePlaybookReqContact,
  ListPlaybookTemplateReqLinks,
} from '../graphql/types';

declare global {
  interface Array<T> {
    indexOfReg(o: T): number;
  }
}

function removeEmpty(obj) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
    else if (typeof obj[key] == 'string' && (obj[key] == null || obj[key] == '')) delete obj[key];
  });
}

Array.prototype.indexOfReg = function (match) {
  var reg = new RegExp(match);

  for (var i = 0; i < this.length; i++) {
    if (typeof this[i] == 'string' && this[i].match(reg)) return i;
  }

  return -1;
};

@Injectable()
export class PlaybookService {
  playbookTemplates$: Observable<PlaybookTemplate[]>;

  constructor(
    private http: HttpClient,
    private apollo: Apollo
  ) {}

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  // CREATE
  public createPlaybookTemplate(tenantId: string, playbookTemplateData: PlaybookTemplate): Observable<any> {
    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete playbookTemplateData.__typename;

    // Strip the __typename added by graphQL or will fail update Input type
    if ('categories' in playbookTemplateData) {
      playbookTemplateData.categories.forEach((category) => delete category.__typename);

      playbookTemplateData.categories.forEach((category) => {
        // Strip the __typename added by graphQL or will fail update Input type
        if ('stages' in category) {
          category.stages.forEach((stage) => {
            delete stage.__typename;

            // Strip the __typename added by graphQL or will fail update Input type
            if ('tasks' in stage) {
              stage.tasks.forEach((task) => delete task.__typename);
            }
          });
        }
      });
    }

    return this.apollo.mutate({
      mutation: gql(addPlaybookTemplate),
      variables: {
        tenantId: tenantId,
        playbookTemplateData: playbookTemplateData,
      },
      update: (store, { data: { addPlaybookTemplate } }: any) => {
        // Add a new history item.
      },
    });
  }

  // READ
  readPlaybookTemplate(tenantId: string, playbookTemplateId: string, networkOnly: boolean = true): Observable<any> {
    return this.apollo.watchQuery<ReadPlaybookTemplate>({
      query: gql(readPlaybookTemplate),
      variables: { tenantId: tenantId, playbookTemplateId: playbookTemplateId },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  //  // fetchPolicy: networkOnly ? "network-only" : "cache-first",

  // LIST
  public listPlaybookTemplates(tenantId: string, playbookTemplateFilter: string): Observable<any> {
    return this.apollo.watchQuery<ListPlaybookTemplates>({
      query: gql(listPlaybookTemplates),
      variables: {
        tenantId: tenantId,
        playbookTemplateFilter: playbookTemplateFilter,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  // UPDATE
  updatePlaybookTemplate(tenantId: string, playbookTemplateId: string, playbookTemplateData: any): Observable<any> {
    console.log('b4', playbookTemplateData);
    removeEmpty(playbookTemplateData);
    console.log('after', playbookTemplateData);

    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete playbookTemplateData.__typename;

    // Strip the __typename added by graphQL or will fail update Input type
    if ('categories' in playbookTemplateData) {
      playbookTemplateData.categories.forEach((category) => delete category.__typename);

      playbookTemplateData.categories.forEach((category) => {
        // Strip the __typename added by graphQL or will fail update Input type
        if ('stages' in category) {
          category.stages.forEach((stage) => {
            delete stage.__typename;

            // Strip the __typename added by graphQL or will fail update Input type
            if ('tasks' in stage) {
              stage.tasks.forEach((task) => delete task.__typename);
            }
          });
        }
      });
    }

    return this.apollo.mutate({
      mutation: gql(updatePlaybookTemplate),
      variables: {
        tenantId: tenantId,
        playbookTemplateId: playbookTemplateId,
        playbookTemplateData: playbookTemplateData,
      },
      update: (store, { data: { updatePlaybookTemplate } }: any) => {
        // Add a new history item.
      },
    });
  }

  // DELETE - LOGICAL only for now
  logicalDeletePlaybookTemplate(tenantId: string, playbookTemplateId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(logicalDeletePlaybookTemplate),
      variables: {
        tenantId: tenantId,
        playbookTemplateId: playbookTemplateId,
      },
      update: (store, { data: { updatePlaybookTemplate } }: any) => {
        // Add a new history item.
      },
    });
  }

  reorderOnDropPlaybookTemplates(playbookTemplates: PlaybookTemplate[]) {
    // initially relies on moveItemInArray from CDK to do the work for order
    // ***Todo - how to handle multiple users changing the same templates at the same time?

    // Quick and dirty - Better/faster to do this remote with its own end point
    // loop over templates - use plain for loop to keep access to playbook service scope
    for (let i = 0; i < playbookTemplates.length; i++) {
      let dataToUpdateObj = { order: i };
      // playbookTemplates[i].order = i;
      this.updatePlaybookTemplate('', playbookTemplates[i].id, dataToUpdateObj).pipe(take(1)).subscribe();
    }
  }

  /**************************************************************************************************
   *
   * PlaybookReqContact - where the user executes the playbook
   *
   ***************************************************************************************************/

  // CREATE
  public createPlaybookReqContact(reqId: string, contactId: string, playbookTemplateId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(createPlaybookReqContact),
      variables: {
        reqId: reqId,
        contactId: contactId,
        playbookTemplateId: playbookTemplateId,
      },
      update: (store, { data: { createPlaybookReqContact } }: any) => {
        // Add a new history item.
      },
    });
  }

  // READ
  public readSinglePlaybookReqContact(
    tenantId: string,
    reqId: string,
    contactId: string,
    playbookTemplateId: string,
    networkOnly: boolean = true
  ): Observable<any> {
    return this.apollo.watchQuery<ReadPlaybookReqContact>({
      query: gql(readSinglePlaybookReqContact),
      variables: {
        tenantId: tenantId,
        reqId: reqId,
        contactId: contactId,
        playbookTemplateId: playbookTemplateId,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }
  //  fetchPolicy: networkOnly ? "network-only" : "cache-first"

  // UPDATE
  updatePlaybookReqContact(
    reqId: string,
    contactId: string,
    playbookTemplateId: string,
    playbookReqContactData: any
  ): Observable<any> {
    console.log('b4', playbookReqContactData);
    removeEmpty(playbookReqContactData);
    console.log('after', playbookReqContactData);

    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete playbookReqContactData.__typename;

    delete playbookReqContactData.playbookObj.__typename;

    // Strip the __typename added by graphQL or will fail update Input type
    if ('categories' in playbookReqContactData.playbookObj) {
      playbookReqContactData.playbookObj.categories.forEach((category) => delete category.__typename);

      playbookReqContactData.playbookObj.categories.forEach((category) => {
        // Strip the __typename added by graphQL or will fail update Input type
        if ('stages' in category) {
          category.stages.forEach((stage) => {
            delete stage.__typename;

            // Strip the __typename added by graphQL or will fail update Input type
            if ('tasks' in stage) {
              stage.tasks.forEach((task) => {
                delete task.__typename;

                // Strip the __typename added by graphQL or will fail update Input type
                if ('notes' in task) {
                  task.notes.forEach((note) => delete note.__typename);
                }
              });
            }
          });
        }
      });
    }

    return this.apollo.mutate({
      mutation: gql(updatePlaybookReqContact),
      variables: {
        reqId: reqId,
        contactId: contactId,
        playbookTemplateId: playbookTemplateId,
        playbookReqContactData: playbookReqContactData,
      },
      update: (store, { data: { updatePlaybookReqContact } }: any) => {
        // Add a new history item.
      },
    });
  }

  /*************************************************
   * Playbook - Req Link
   *
   *
   **************************************************/

  createPlaybookTemplateReqLink(tenantId: string, reqId: string, playbookTemplateId: string): Observable<any> {
    // Read all templates req links from storage

    return this.apollo.mutate({
      mutation: gql(createPlaybookTemplateReqLink),
      variables: {
        tenantId: tenantId,
        reqId: reqId,
        playbookTemplateId: playbookTemplateId,
      },
      update: (store, { data: { createPlaybookTemplateReqLink } }: any) => {
        // Add a new history item.
      },
    });
  }

  readPlaybookTemplateReqLinkByReq(tenantId: string, reqId: string, useTopDefault: boolean = true): Observable<any> {
    return this.apollo.watchQuery<PlaybookTemplateReqLink>({
      query: gql(readPlaybookTemplateReqLinkByReq),
      variables: {
        tenantId: tenantId,
        reqId: reqId,
        useTopDefault: useTopDefault,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  listPlaybookTemplateReqLinks(playbookTemplateReqLinksFilter: any): Observable<any> {
    return this.apollo.watchQuery<ListPlaybookTemplateReqLinks>({
      query: gql(listPlaybookTemplateReqLinks),
      variables: {
        playbookTemplateReqLinksFilter: playbookTemplateReqLinksFilter,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  updatePlaybookTemplateReqLink(
    reqId: string,
    playbookTemplateId: string,
    playbookTemplateReqLinkData: any
  ): Observable<any> {
    console.log('b4', playbookTemplateReqLinkData);
    removeEmpty(playbookTemplateReqLinkData);
    console.log('after', playbookTemplateReqLinkData);

    // github.com/apollographql/apollo-client/issues/1564
    // Strip the __typename added by graphQL or will fail update Input type
    delete playbookTemplateReqLinkData.__typename;

    return this.apollo.mutate({
      mutation: gql(updatePlaybookTemplateReqLink),
      variables: {
        reqId: reqId,
        playbookTemplateId: playbookTemplateId,
        playbookTemplateReqLinkData: playbookTemplateReqLinkData,
      },
      update: (store, { data: { updatePlaybookTemplateReqLink } }: any) => {
        // Add a new history item.
      },
    });
  }

  deletePlaybookTemplateReqLink(reqId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(deletePlaybookTemplateReqLink),
      variables: {
        reqId: reqId,
      },
    });
  }
}
