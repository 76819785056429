import { Component, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { Apollo, gql, QueryRef } from 'apollo-angular';

import {
  startClick2Call,
  setFeatureClick2Call,
  terminateCall,
  transferCall,
  setCallForwardingNumber,
} from '../graphql/mutations';
import {
  validateCallerId,
  verifyCallerId,
  getFeatureClick2Call,
  getCallStatus,
  checkPhoneNumberType,
} from '../graphql/queries';

import { sendVerifyCallerIdResult, StartClick2CallResult } from '../graphql/types';

export interface IntegrationKey {
  apiKey: string;
  iName: string;
  createdBy?: string;
  createdDate?: string;
  modifiedDate?: string;
}

@Injectable()
export class Click2CallService {
  constructor(private apollo: Apollo) {}

  // Validate
  validateCallerId(agentNumber: string): Observable<any> {
    return this.apollo.watchQuery<boolean>({
      query: gql(validateCallerId),
      variables: { agentNumber: agentNumber },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  // Verify
  verifyCallerId(agentNumber: string, agentInfo: string): Observable<any> {
    return this.apollo.watchQuery<sendVerifyCallerIdResult>({
      query: gql(verifyCallerId),
      variables: { agentNumber: agentNumber, agentInfo: agentInfo },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  // Start call
  public startClick2Call(agentNumber: string, contactNumber: string): Observable<any> {
    return this.apollo.mutate<StartClick2CallResult>({
      mutation: gql(startClick2Call),
      variables: {
        agentNumber: agentNumber,
        contactNumber: contactNumber,
      },
    });
  }

  // Get Feature Status
  /**@deprecated use get permissions instead */
  getFeatureClick2Call(tenantId: string): Observable<any> {
    return this.apollo.watchQuery<boolean>({
      query: gql(getFeatureClick2Call),
      variables: { tenantId: tenantId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  /**@deprecated use permissions instead */
  public setFeatureClick2Call(tenantId: string, setVal: boolean): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(setFeatureClick2Call),
      variables: {
        tenantId: tenantId,
        setVal: setVal,
      },
    });
  }

  // Get call Status
  // getCallStatus(callServiceId: string): Observable<any> {
  //   return this.apollo.watchQuery<string>({
  //     query: gql(getCallStatus),
  //     variables: { callServiceId: callServiceId },
  //     fetchPolicy: "network-only",
  //   }).valueChanges;
  // }

  getCallStatus(callServiceId: string): QueryRef<any> {
    return this.apollo.watchQuery<string>({
      query: gql(getCallStatus),
      variables: { callServiceId: callServiceId },
      fetchPolicy: 'network-only',
    });
  }

  // Terminate Call
  public terminateCall(callServiceId: string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(terminateCall),
      variables: {
        callServiceId: callServiceId,
      },
    });
  }

  // Terminate Call
  public transferCall(callServiceId: string, transferPhone: string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(transferCall),
      variables: {
        callServiceId: callServiceId,
        transferPhone: transferPhone,
      },
    });
  }

  public setCallForwardingNumber(smsNumber: string, userNumber: string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql(setCallForwardingNumber),
      variables: {
        smsNumber,
        userNumber,
      },
    });
  }

  // Twilio Lookup phone number type
  public checkPhoneNumberType(phoneNumber: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gql(checkPhoneNumberType),
      variables: {
        phoneNumber,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }
}
