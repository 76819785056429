import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OutlookService } from 'app/services/outlook.service';
import { TodoService } from 'app/services/todo.service';
import { UserService } from 'app/services/user.service';
import { AlertDialogComponent, AlertDialogConfig } from 'app/shared/dialogs/alert-dialog.component';
import { ActivityType, HistoryInput } from 'app/shared/model/activity';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { EmailSettingsService } from 'app/services/email-settings.service';
import { GoogleService } from 'app/services/google.service';
import { EmailTemplate } from 'app/shared/model/user';
import Quill from 'quill';
import QuillMention from 'quill-mention';
import { Contact } from 'app/shared/model/contacts';
import { ContactService } from 'app/services/contact.service';

const CONTACT_FIRST_NAME_MERGE_VALUE = '1';
const CONTACT_LAST_NAME_MERGE_VALUE = '2';
const CONTACT_COMPANY_MERGE_VALUE = '3';
const CONTACT_JOB_TITLE_MERGE_VALUE = '4';
const CONTACT_REQUISITION_MERGE_VALUE = '5';
const USER_FIRST_NAME_MERGE_VALUE = '6';
const USER_LAST_NAME_MERGE_VALUE = '7';

@Component({
  selector: 'mm-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
})
export class SendEmailComponent implements OnInit {
  //Attachment types
  pdfType = 'application/pdf';
  docType = 'application/msword';
  docxType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  emailFrom: string = '';
  emailCc: string;
  emailSubject: string;
  emailBody: string;
  emailAttachments: File[] = new Array();
  signature: string;
  showCc: boolean = false;
  showAttachments: boolean = false;
  isLoading: boolean = false;

  showEmailTemplates: boolean = false;
  allTemplates: EmailTemplate[] = [];
  selectedTemplate: EmailTemplate;

  quillEditor: Quill;

  userObj: any = {};

  quillToolbar = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    ['link', 'image'],
  ];

  @Input() openEmail: boolean = false;
  @Input() emailTo: string = '';
  @Input() contact: any = new Contact();
  @Input() reqId: string = '';
  @Input() reqTitle: string = '';
  @Input() emailService: string = '';
  @Output() showEmailDraft = new EventEmitter<boolean>();

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private outlookService: OutlookService,
    private googleService: GoogleService,
    private todoService: TodoService,
    private userService: UserService,
    private emailSettingsService: EmailSettingsService,
    private contactService: ContactService
  ) {}

  ngOnInit() {
    this.outlookService.readOutlookKey();

    // Changes default block tag to <div> instead of <p>
    // source: https://github.com/quilljs/quill/issues/861
    var Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);
    Quill.register({ 'modules/mention': QuillMention }, true);

    this.quillEditor = new Quill('#quillEditor', {
      modules: {
        toolbar: this.quillToolbar,
        mention: {
          mentionDenotationChars: ['{'],
          source: async function (searchTerm, renderList) {
            let suggestTemplateMerges = async (searchTerm) => {
              let mergeValues = [
                { id: CONTACT_FIRST_NAME_MERGE_VALUE, value: 'c_firstName}' },
                { id: CONTACT_LAST_NAME_MERGE_VALUE, value: 'c_lastName}' },
                { id: CONTACT_COMPANY_MERGE_VALUE, value: 'c_company}' },
                { id: CONTACT_JOB_TITLE_MERGE_VALUE, value: 'c_jobTitle}' },
                { id: CONTACT_REQUISITION_MERGE_VALUE, value: 'c_requisition}' },
                { id: USER_FIRST_NAME_MERGE_VALUE, value: 'u_firstName}' },
                { id: USER_LAST_NAME_MERGE_VALUE, value: 'u_lastName}' },
              ];
              return mergeValues.filter((merge) => merge.value.includes(searchTerm));
            };

            let filteredMergeValues = await suggestTemplateMerges(searchTerm);
            renderList(filteredMergeValues);
          },
        },
      },
      theme: 'snow',
    });

    this.emailSettingsService.getEmailSignature().subscribe((signatureObj) => {
      let signature = signatureObj.data.getEmailSignature?.signature || '';
      this.signature = signature;
      this.quillEditor.root.innerHTML = '<br><br>' + signature;
    });
    this.setEmailFrom();
    this.getAllEmailTemplates();
    this.loadUserInfo();
  }

  setEmailFrom() {
    if (this.emailService == 'Outlook') {
      this.emailFrom = this.outlookService.getUserInfo().username;
    } else if (this.emailService == 'Gmail') {
      this.googleService.getGmailAddress().then((gmailAddress) => {
        this.emailFrom = gmailAddress;
      });
    } else {
      console.error('User is not authorized with an email service');
    }
  }

  getEmailFrom() {
    return this.emailFrom;
  }

  async sendEmail() {
    if (this.emailService == 'Outlook') {
      this.sendOutlookEmail();
    } else if (this.emailService == 'Gmail') {
      await this.sendGmail();
    } else {
      console.error('User is not authenticated with an email integration');
    }
  }

  async sendGmail() {
    let emailContents = {
      to: this.emailTo,
      cc: this.showCc ? this.emailCc : null,
      subject: this.emailSubject ? this.emailSubject : '',
      bodyHTML: this.quillEditor.root.innerHTML,
      bodyText: this.quillEditor.root.innerHTML.innerText,
      attachments: this.emailAttachments,
      embeddedImgs: [],
    };

    //Handle embedded images. Need to manipulate email body change img src to contain contentId
    let imgs = document.getElementById('quillEditor').getElementsByTagName('img');
    if (imgs.length > 0) {
      for (let i = 0; i < imgs.length; ++i) {
        emailContents.embeddedImgs.push(this.googleService.generateEmbeddedImageObject(imgs[i], i));
      }

      emailContents.bodyHTML = this.quillEditor.root.innerHTML;
    }

    //Check if email recipient is empty
    if (!this.emailTo) {
      this.snackBar.open('Must provide an email recipient', null, {
        duration: 4000,
        panelClass: 'error-snack-bar',
      });
    } else if (!this.emailSubject || this.quillEditor.root.innerText == '\n' || !this.mergeTagsValid()) {
      //Check if subject/body is empty, and warn the user if it is
      this.warnMissingContents().subscribe(async (sendConfirmation) => {
        if (sendConfirmation) {
          this.replaceMergeTags();
          emailContents.bodyHTML = this.quillEditor.root.innerHTML;
          emailContents.bodyText = this.quillEditor.root.innerHTML.innerText;
          await this.sendGmailResolve(emailContents);
        }
      });
    } else {
      this.replaceMergeTags();
      emailContents.bodyHTML = this.quillEditor.root.innerHTML;
      emailContents.bodyText = this.quillEditor.root.innerHTML.innerText;
      await this.sendGmailResolve(emailContents);
    }
  }

  async sendGmailResolve(emailContents) {
    this.isLoading = true;
    const email = await this.googleService.formatMIMEEmailContents(emailContents, this.getEmailFrom());
    this.googleService
      .sendGmail(email)
      .then((gmailSent) => {
        if (gmailSent) {
          this.snackBar.open('Email sent', null, {
            duration: 4000,
            panelClass: 'success-snack-bar',
          });
          this.isLoading = false;
          this.addEmailHistory();
          this.closeEmailDraft();
        }
      })
      .catch((error) => {
        console.error('Error sending gmail', error);
        this.snackBar.open('Error sending email with Gmail', null, {
          duration: 4000,
          panelClass: 'error-snack-bar',
        });
        this.isLoading = false;
      });
  }

  sendOutlookEmail() {
    this.outlookService.readOutlookKey();

    let emailContents = {
      to: this.emailTo,
      cc: this.showCc ? this.emailCc : null,
      subject: this.emailSubject,
      body: this.quillEditor.root.innerHTML,
      attachments: this.emailAttachments,
      embeddedImgs: [],
    };
    //Handle embedded images. Need to manipulate email body change img src to contain contentId
    let imgs = document.getElementById('quillEditor').getElementsByTagName('img');
    if (imgs.length > 0) {
      let cidNum = 1;
      for (let i = 0; i < imgs.length; ++i) {
        emailContents.embeddedImgs.push(this.outlookService.generateEmbeddedImagesJson(imgs[i], cidNum));
      }

      emailContents.body = this.quillEditor.root.innerHTML;
    }

    //Check if email recipient is empty
    if (!this.emailTo) {
      this.snackBar.open('Must provide an email recipient', null, {
        duration: 4000,
        panelClass: 'error-snack-bar',
      });
      return;
    } else if (!this.emailSubject || this.quillEditor.root.innerText == '\n' || !this.mergeTagsValid()) {
      //Check if subject/body is empty, and warn the user if it is
      this.warnMissingContents().subscribe((sendConfirmation) => {
        if (sendConfirmation) {
          this.replaceMergeTags();
          emailContents.body = this.quillEditor.root.innerHTML;
          this.postRequestEmail(emailContents);
        }
      });
    } else {
      this.replaceMergeTags();
      emailContents.body = this.quillEditor.root.innerHTML;
      this.postRequestEmail(emailContents);
    }
  }

  postRequestEmail(emailContents) {
    //Get the outlookService Promise Then when it resolves
    //Get the response, which is now the integrationsService Observable
    //Subscribe to the inegrationsService Observable and do some logic
    this.isLoading = true;
    this.outlookService
      .sendOutlookEmail(emailContents)
      .then((result) => {
        console.log('mich sendemail result', result);
        if (result) {
          this.snackBar.open('Email sent', null, {
            duration: 4000,
            panelClass: 'success-snack-bar',
          });
          this.addEmailHistory();
          this.closeEmailDraft();
          this.isLoading = false;
        } else {
          console.error('OutlookService: There was an error sending outlook email during the post request', result);
        }
      })
      .catch((error) => console.log('postRequestEmail: ', error));
  }

  warnMissingContents(): Observable<boolean> {
    const dialogConfig: AlertDialogConfig = {
      title: 'Send Email?',
      message: 'Warning: You are sending an email with no subject/body and/or invalid merge tags',
      confirmButton: {
        label: 'Send anyway',
        color: 'primary',
      },
    };

    const dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, boolean>(AlertDialogComponent, {
      panelClass: 'alert-dialog-component',
      data: dialogConfig,
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  toggleCc() {
    this.showCc = !this.showCc;
    this.updateBodySize();
  }

  closeEmailDraft() {
    this.showEmailDraft.emit(false);
  }

  uploadAttachmentHandler($event) {
    if (!this.emailAttachments) {
      this.emailAttachments = Array.from($event.target.files);
    } else {
      this.emailAttachments = Array.from(this.emailAttachments).concat(Array.from($event.target.files));
    }
    this.updateBodySize();
    (<HTMLInputElement>document.getElementById('fileUpload')).value = null;
  }

  deleteAttachment(attToDelete) {
    for (let att = 0; att < this.emailAttachments.length; att++) {
      if (this.emailAttachments[att] == attToDelete) {
        this.emailAttachments.splice(att, 1);
        this.updateBodySize();
        return;
      }
    }
  }

  convertSize(size) {
    return (size / 1000000).toFixed(1);
  }

  updateBodySize() {
    let showAtt = this.emailAttachments.length > 0;
    if (this.showCc && showAtt) {
      document.getElementById('quillEditor').style.minHeight = '200px';
      document.getElementById('quillEditor').style.maxHeight = '200px';
    } else if (this.showCc && !showAtt) {
      document.getElementById('quillEditor').style.minHeight = '272px';
      document.getElementById('quillEditor').style.maxHeight = '272px';
    } else if (!this.showCc && showAtt) {
      document.getElementById('quillEditor').style.minHeight = '243px';
      document.getElementById('quillEditor').style.maxHeight = '243px';
    } else if (!this.showCc && !showAtt) {
      document.getElementById('quillEditor').style.minHeight = '315px';
      document.getElementById('quillEditor').style.maxHeight = '315px';
    }
  }

  addEmailHistory() {
    this.userService
      .getAuthenticatedUserId()
      .pipe(take(1))
      .subscribe((userId) => {
        const historyInput: HistoryInput = {
          type: ActivityType.Email,
          note: `Email sent to ${this.emailTo}`,
          reqId: this.reqId,
          userId: userId,
          contactId: this.contact.id,
          isPublic: false,
          analytics: JSON.stringify({
            contactEmail: this.emailTo,
            description: this.emailBody,
          }),
        };

        // Add email history and keep contact's lastUpdatedAt field in sync with history
        this.todoService.addHistory(historyInput).subscribe(
          (result) => {
            this.contactService
              .updateContactLastUpdatedAtField(this.contact.id, this.reqId, 'false')
              .pipe(take(1))
              .subscribe(
                (result) => {},
                (error) => {
                  console.log('An error occurred updated contact fields after sending email.', error);
                }
              );
          },
          (error) => {
            console.error('An error occurred adding email history.', error);
            this.snackBar.open('An error occurred adding email history.', null, {
              duration: 3000,
            });
          }
        );
      });
  }

  getAllEmailTemplates() {
    this.emailSettingsService.getAllEmailTemplates().subscribe((emailTemplates) => {
      this.allTemplates = emailTemplates.data.getAllEmailTemplates;
      if (this.selectedTemplate) {
        this.selectedTemplate = this.allTemplates.find((t) => t.templateId == this.selectedTemplate.templateId);
      }
    });
  }

  filterEmailTemplates(filterTemplateTitle) {
    this.emailSettingsService.filterEmailTemplates(filterTemplateTitle).subscribe((emailTemplates) => {
      this.allTemplates = emailTemplates.data.filterEmailTemplates;
    });
  }

  toggleShowEmailTemplates() {
    this.showEmailTemplates = !this.showEmailTemplates;
  }

  routeToEmailSettings() {
    window.location.href = window.location.origin + '/#/settings/emailsettings';
  }

  updateEmailBody() {
    this.quillEditor.root.innerHTML = this.selectedTemplate[0].templateBody + '\n\n' + this.signature;
    this.emailSubject = this.selectedTemplate[0].templateSubject;
    this.toggleShowEmailTemplates();
  }

  // Check for merge tags and replace them with the correct strings
  replaceMergeTags() {
    // Loop through each merge tag
    let mergeTagsCount = document.getElementsByClassName('mention').length;
    for (let mergeTag = mergeTagsCount - 1; mergeTag >= 0; mergeTag--) {
      // Check which value type it is by checking its data-id. Then create the HTML element to replace it with
      let newSpan = document.createElement('span');
      let dataId = document.getElementsByClassName('mention')[mergeTag].getAttribute('data-id');

      if (dataId == CONTACT_FIRST_NAME_MERGE_VALUE) {
        newSpan.innerHTML = this.contact.firstName || '';
      } else if (dataId == CONTACT_LAST_NAME_MERGE_VALUE) {
        newSpan.innerHTML = this.contact.lastName || '';
      } else if (dataId == CONTACT_COMPANY_MERGE_VALUE) {
        newSpan.innerHTML = this.contact.company || '';
      } else if (dataId == CONTACT_JOB_TITLE_MERGE_VALUE) {
        newSpan.innerHTML = this.contact.title || '';
      } else if (dataId == CONTACT_REQUISITION_MERGE_VALUE) {
        newSpan.innerHTML = this.reqTitle || '';
      } else if (dataId == USER_FIRST_NAME_MERGE_VALUE) {
        newSpan.innerHTML = this.userObj.firstName || '';
      } else if (dataId == USER_LAST_NAME_MERGE_VALUE) {
        newSpan.innerHTML = this.userObj.lastName || '';
      }

      // Replace the merge tag with the HTML element created above
      document.getElementsByClassName('mention')[mergeTag].replaceWith(newSpan);
    }
  }

  /**
   * @returns True if all the merge tags can be replaces with values. False if at least one merge tag
   * cannot be replaced.
   */
  mergeTagsValid() {
    let mergeTagsCount = document.getElementsByClassName('mention').length;
    for (let mergeTag = mergeTagsCount - 1; mergeTag >= 0; mergeTag--) {
      let dataId = document.getElementsByClassName('mention')[mergeTag].getAttribute('data-id');

      if (
        (dataId == CONTACT_FIRST_NAME_MERGE_VALUE && !this.contact.firstName) ||
        (dataId == CONTACT_LAST_NAME_MERGE_VALUE && !this.contact.lastName) ||
        (dataId == CONTACT_COMPANY_MERGE_VALUE && !this.contact.company) ||
        (dataId == CONTACT_JOB_TITLE_MERGE_VALUE && !this.contact.title) ||
        (dataId == CONTACT_REQUISITION_MERGE_VALUE && !this.reqTitle) ||
        (dataId == USER_FIRST_NAME_MERGE_VALUE && !this.userObj.firstName) ||
        (dataId == USER_LAST_NAME_MERGE_VALUE && !this.userObj.lastName)
      ) {
        return false;
      }
    }
    return true;
  }

  loadUserInfo() {
    this.userService
      .getAuthenticatedUserId()
      .pipe(take(1))
      .subscribe(
        (userId: string) => {
          this.userService
            .getUserInfo(userId)
            .pipe(take(1))
            .subscribe((result) => {
              this.userObj.firstName = result.data.getUserInfo.firstName;
              this.userObj.lastName = result.data.getUserInfo.lastName;
            });
        },
        (error) => {
          console.log('LandingPageComponent: Error getting authenticated userId -> ' + error.message);
        }
      );
  }
}
