import { ChangeDetectorRef, Component } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SatCalendar, SatCalendarFooter, SatDatepicker } from 'saturn-datepicker-ext';
import { DateAdapter } from 'saturn-datepicker-ext';
import {
  format,
  formatDistance,
  formatRelative,
  subDays,
  lastDayOfMonth,
  startOfMonth,
  startOfWeek,
  endOfWeek,
} from 'date-fns';

@Component({
  templateUrl: './ranges-footer.component.html',
  styleUrls: ['./ranges-footer.component.scss'],
})
export class RangesFooter<Date> implements SatCalendarFooter<Date> {
  public ranges: Array<{ key: string; label: string }> = [
    { key: 'today', label: 'Today' },
    { key: 'thisWeek', label: 'This Week' },
    { key: 'thisMonth', label: 'This Month' },
    { key: '30', label: '30' },
    { key: '60', label: '60' },
    { key: '90', label: '90' },
    { key: '120', label: '120' },
  ];
  private destroyed = new Subject<void>();

  constructor(
    private calendar: SatCalendar<Date>,
    private datePicker: SatDatepicker<Date>,
    private dateAdapter: DateAdapter<Date>,
    cdr: ChangeDetectorRef
  ) {
    calendar.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => cdr.markForCheck());
  }

  setRange(range: string) {
    const today = new Date();

    switch (range) {
      case 'today':
        this.calendar.beginDate = this.dateAdapter.deserialize(today);
        this.calendar.endDate = this.dateAdapter.deserialize(today);
        this.calendar.activeDate = this.calendar.beginDate;
        break;
      case 'thisWeek':
        this.calendar.beginDate = this.dateAdapter.deserialize(startOfWeek(today));
        this.calendar.endDate = this.dateAdapter.deserialize(endOfWeek(today));
        break;
      case 'thisMonth':
        this.calendar.beginDate = this.dateAdapter.deserialize(startOfMonth(today));
        this.calendar.endDate = this.dateAdapter.deserialize(lastDayOfMonth(today));
        break;
      case '30':
        this.calendar.beginDate = this.dateAdapter.deserialize(subDays(today, 30));
        this.calendar.endDate = this.dateAdapter.deserialize(today);
        break;
      case '60':
        this.calendar.beginDate = this.dateAdapter.deserialize(subDays(today, 60));
        this.calendar.endDate = this.dateAdapter.deserialize(today);
        break;
      case '90':
        this.calendar.beginDate = this.dateAdapter.deserialize(subDays(today, 90));
        this.calendar.endDate = this.dateAdapter.deserialize(today);
        break;
      case '120':
        this.calendar.beginDate = this.dateAdapter.deserialize(subDays(today, 120));
        this.calendar.endDate = this.dateAdapter.deserialize(today);
        break;
    }
    this.calendar.activeDate = this.calendar.beginDate;
    this.calendar.beginDateSelectedChange.emit(this.calendar.beginDate);
    this.calendar.dateRangesChange.emit({ begin: this.calendar.beginDate, end: this.calendar.endDate });
    this.datePicker.close();
  }
}
