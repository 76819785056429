<div class="header-edit-container">
  <button
    mat-mini-fab
    mat-dialog-close
    color="transparent"
    style="float: right; margin-top: -18px"
    aria-label="close edit dialog"
  >
    <mat-icon>close</mat-icon>
  </button>
  <h3 style="text-align: center; color: #505050">Edit Column Header</h3>
  <mat-divider></mat-divider>
  <mat-form-field style="margin-top: 20px; width: 100%" appearance="fill">
    <mat-label>Select a field for this column</mat-label>
    <mat-select [(value)]="selectedHeader" placeholder="Select A Label" (selectionChange)="handleSelect($event.value)">
      <mat-option [disabled]="!option.available" *ngFor="let option of headerOptions" [value]="option.value">
        {{ option.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-divider></mat-divider>
  <mat-dialog-actions>
    <button mat-button class="mat-elevation-z3" mat-dialog-close color="warn">Cancel</button>
    <button mat-button class="mat-elevation-z3" (click)="handleSubmitChange()" color="primary">Confirm</button>
  </mat-dialog-actions>
</div>
