<div mat-dialog-title style="margin-bottom: 1em">
  <button mat-icon-button class="button-close" (click)="onCloseClick()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="flex-container header-container" fxLayoutGap="1em" fxLayout="row" fxLayout.lt-lg="column">
    <div fxFlex="25%">
      <header class="dialog-header">
        <h3>
          Team Members
          <div *ngIf="data.target" class="mat-caption">{{ data.target }}</div>
        </h3>
      </header>
    </div>

    <div fxFlex fxLayoutAlign="start center" class="req-options">
      <mat-form-field appearance="outline" style="width: 100%; margin-right: 1em; margin-left: 0.8em">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" #searchUsersInput [formControl]="searchUsersControl" />
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" class="">
    <mat-checkbox
      fxFlex
      fxLayoutAlign="end center"
      [checked]="allSelected"
      [indeterminate]="someChecked()"
      (change)="selectAll($event)"
      class="select-all"
      labelPosition="before"
      >All
    </mat-checkbox>
  </div>
</div>

<section>
  <div class="container">
    <div class="flex-container" fxLayoutGap="1em" fxLayout="row" fxLayout.lt-lg="column">
      <div class="child-1" fxFlex="25%" fxFlex.gt-md="25%" fxFlexOrder="1" fxFlexOrder.lt-lg="1" style="flex-grow: 1">
        <form [formGroup]="usersPickerForm">
          <mat-form-field *ngIf="authenticatedUserInfo?.groups.includes('mmAdmin')">
            <input
              type="text"
              placeholder="Tenant"
              aria-label="String"
              matInput
              [formControl]="tenantFormControl"
              [matAutocomplete]="autoTenant"
              (blur)="verifyTenantSelected()"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #autoTenant="matAutocomplete"
              [displayWith]="displayTenant"
              (optionSelected)="tenantSelected($event)"
            >
              <mat-option *ngFor="let tenant of filteredTenants | async" [value]="tenant"
                >{{ tenant.title }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>

      <mat-dialog-content
        class="child-2 integrations-description"
        fxFlex="75%"
        fxFlex.gt-md="75%"
        fxFlexOrder="1"
        fxFlexOrder.lt-lg="1"
        style="flex-grow: 5; height: 30vh; overflow: auto"
      >
        <!--  mat-elevation-z2 -->
        <div class="picker-section-content">
          <div *ngIf="!dataLoaded">
            <div fxFlex fxLayoutAlign="center center">
              <mat-spinner></mat-spinner>
            </div>
          </div>

          <div *ngIf="dataLoaded">
            <!-- navigation list for each tenant template item -->
            <mat-selection-list #userSelect [(ngModel)]="selectedUsers" (selectionChange)="onSelection($event)">
              <mat-list-option
                *ngFor="let user of filteredUsers | async; let index = index"
                [ngClass]="autoFocusName && index === 0 ? 'tenant-list-item tenant-draw-focus' : 'tenant-list-item'"
                class="tenant-user-list-item"
                id="tenantItem{{ index }}"
                [value]="user"
              >
                <div class="edit-tenant-text">
                  <div fxFlex="40">
                    <mat-icon *ngIf="!user.altTenant" class="muted edit-user-icon">account_circle </mat-icon>
                    <mat-icon *ngIf="user.altTenant" class="muted edit-user-icon">supervisor_account </mat-icon>

                    {{ user.lastName }}, {{ user.firstName }}
                  </div>

                  <div fxFlex>
                    {{ user.username }}
                  </div>
                </div>
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </mat-dialog-content>
    </div>
  </div>
</section>

<div mat-dialog-actions class="picker-actions-container">
  <div class="picker-actions">
    <p>Users Selected: {{ selectedUsers?.length ? selectedUsers?.length : 0 }}</p>

    <button mat-raised-button (click)="clearAll()">Clear</button>
    <button mat-raised-button (click)="onFilterSelected()" color="primary">Filter</button>
  </div>
</div>
