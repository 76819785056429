import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UpdateReqInput } from '../model/requisitions';

@Component({
  selector: 'mm-save-filters-dialog',
  templateUrl: './save-filters-dialog.component.html',
})
export class SaveFiltersDialogComponent implements OnInit {
  filtersForm: FormGroup;
  name: string = '';
  summary: string;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.filtersForm = this.formBuilder.group({
      name: [this.name, Validators.required],
    });
  }
}
