<div fxLayout="row" fxLayoutGap="1em" [className]="SAC ? 'numbers-row-sac' : 'numbers-row'" style="padding: 1em">
  <div
    fxFlex
    fxLayout="column"
    fxLayoutAlign="start start"
    class="mat-small assignedNumberTop"
    (click)="getNewerMessages(null)"
  >
    {{ moObj?.contactNumber | phone }}
  </div>

  <div fxFlex fxLayout="column" fxLayoutAlign="end end" class="mat-small assignedNumberTop">
    {{ assignedNumber?.friendlyName | phone }}
  </div>
</div>

<!-- <div fxLayout="row" fxLayoutGap="1em" class="numbers-row">
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="mat-small assignedNumberTop">
    <button mat-icon-button (click)="getNewerMessages(null)" type="button">Refresh</button>
  </div>
</div> -->

<div class="message-history-container">
  <mat-list (scroll)="scrolled($event)" #scrollframe class="message-list frame">
    <mat-list-item #item *ngFor="let message of messageHistory; let index = index" style="margin: 0 0">
      <div matListText style="width: 100%; overflow: visible !important">
        <div matLine></div>
        <div matLine></div>
        <div matLine></div>

        <div matLine *ngIf="message.displayTimeDate" class="messageTimeStamp">
          {{ message.displayTimeDate | date: 'medium' }}
        </div>

        <div
          matLine
          [ngClass]="{
            'message-right-mms': message.mType === 1,
            'message-left-mms': message.mType === 2
          }"
          #mms_item
          *ngFor="let mmsMedia of message.mediaUrl; let mmsIndex = index"
          style="margin: 1em 0; height: auto; position: relative"
        >
          <!-- <img (onerror)="this.src = 'mmsMedia.url'" [src]="mmsMedia.thumbUrl" class="mmsPreview" /> -->

          <!-- ***TODO Need an image deleted or no longer available placeholder instead of just hiding it -->
          <!-- onerror="this.style.display='none'" -->
          <img
            *ngIf="
              ['image/jpeg', 'image/jpg', 'image/gif', 'image/tiff', 'image/webp', 'image/png'].indexOf(
                mmsMedia.fileType.toLowerCase()
              ) >= 0
            "
            src="{{ mmsMedia.thumbUrl }}"
            alt="Media Deleted..."
            class="mmsPreview"
          />

          <div
            *ngIf="
              ['image/jpeg', 'image/jpg', 'image/gif', 'image/tiff', 'image/webp', 'image/png'].indexOf(
                mmsMedia.fileType.toLowerCase()
              ) == -1
            "
          >
            <mat-icon matListAvatar>attach_file</mat-icon>{{ mmsMedia.documentName }}
          </div>

          <!-- download media option here -->
          <button
            mat-icon-button
            class="hide"
            [ngClass]="{
              'button-download-media-right-mms': message.mType === 1,
              'button-download-media-left-mms': message.mType === 2
            }"
            (click)="onDownloadItem(mmsMedia.thumbUrl, mmsMedia.documentName, mmsMedia.id, message.mType)"
          >
            <mat-icon>download</mat-icon>
          </button>
        </div>

        <div *ngIf="(message.mediaUrl?.length > 0 && message.message) || !message.mediaUrl?.length">
          <div matLine *ngIf="message.mType === 1" class="message-right" style="max-width: 50%">
            <div>
              {{ message.message }}
            </div>
            <img
              matListAvatar
              onerror="this.src='/assets/images/contact_placeholder_small.png'"
              [src]="userAvatarUrl"
            />
          </div>

          <div matLine *ngIf="message.mType === 2" class="message-left" style="max-width: 50%">
            <img
              matListAvatar
              onerror="this.src='/assets/images/contact_placeholder_small.png'"
              [src]="moObj.avatarURL"
              style="vertical-align: middle"
            />
            <div style="">{{ message.message }}</div>
          </div>
        </div>
      </div>
    </mat-list-item>
  </mat-list>

  <div #sand fxFlex class="message-input-container">
    <div fxLayout="row" class="emoji-container">
      <div fxFlex>
        <emoji-mart
          class="emoji-mart"
          set="{{ set }}"
          *ngIf="showEmojiPicker"
          (emojiSelect)="addEmoji($event)"
          title="Pick your emoji…"
        ></emoji-mart>
      </div>
    </div>

    <div fxLayout="row" class="mms-media-container mat-elevation-z8">
      <div fxFlex>
        <mm-mms-media-manager
          *ngIf="showMMSMediaPicker"
          (mediaSelected)="mediaSelectedEvent($event)"
        ></mm-mms-media-manager>

        <mm-sms-template-picker
          *ngIf="showSMSTemplatePicker"
          (templateSelected)="onSelectionEvent($event)"
        ></mm-sms-template-picker>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex>
        <mat-list>
          <mat-list-item
            #mms_item
            *ngFor="let mmsMedia of mmsMediaToSend; let mmsIndex = index"
            style="margin: 1em 0; height: auto; position: relative"
          >
            <!-- <img (onerror)="this.src = 'mmsMedia.url'" [src]="mmsMedia.thumbUrl" class="mmsPreview" /> -->

            <img
              *ngIf="
                ['image/jpeg', 'image/jpg', 'image/gif', 'image/tiff', 'image/webp', 'image/png'].indexOf(
                  mmsMedia.fileType.toLowerCase()
                ) >= 0
              "
              src="{{ mmsMedia.thumbUrl }}"
              (onerror)="this.src = 'mmsMedia.url'"
              alt="..."
              class="mmsPreview"
            />
            <div
              *ngIf="
                ['image/jpeg', 'image/jpg', 'image/gif', 'image/tiff', 'image/webp', 'image/png'].indexOf(
                  mmsMedia.fileType.toLowerCase()
                ) == -1
              "
            >
              <mat-icon matListAvatar>attach_file</mat-icon>{{ mmsMedia.documentName }}
            </div>

            <button mat-icon-button class="button-remove-media" (click)="onDeleteTagItem($event, mmsIndex)">
              <mat-icon>clear</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex>
        <mat-icon matPrefix class="muted" (click)="toggleSMSTemplatePicker($event)">receipt_long</mat-icon>
        <mat-form-field class="message-input-wrapper" appearance="none">
          <mat-icon matPrefix class="muted" (click)="toggleMMSMediaPicker($event)">attach_file</mat-icon>
          <textarea
            [(ngModel)]="messageToSend"
            class="message-input"
            matInput
            placeholder="Enter your message here..."
            (blur)="onBlur()"
            (focus)="onFocus()"
            autocomplete="off"
            [disabled]="disabled"
            type="text"
          ></textarea>
          <mat-icon matSuffix class="emoji-trigger" (click)="toggleEmojiPicker($event)">emoji_emotions</mat-icon>
        </mat-form-field>
      </div>

      <div fxLayoutAlign="end center">
        <a *ngIf="!sending" mat-raised-button (click)="sendSMS()" color="primary" class="send-button">Send ⇰</a>

        <a *ngIf="sending" ng-disabled="sending" mat-raised-button color="primary" class="send-button">
          <mat-progress-bar mode="buffer" style="margin: 1.2em auto"></mat-progress-bar>
        </a>
      </div>
    </div>
  </div>
</div>
