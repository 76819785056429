import { CdkMenu, CdkMenuItem, CdkMenuModule, CdkMenuTrigger, CdkMenuTriggerBase, MenuStack } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  Renderer2,
  SkipSelf,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { pairwise, takeUntil } from 'rxjs/operators';
import { ModelMatchButtonModule } from './betterButton.component';
import { MENU_TRIGGER } from '@angular/cdk/menu';
import { fromEvent, ReplaySubject } from 'rxjs';

/** Injection token used for an implementation of MenuStack. */
export const MENU_STACK = new InjectionToken<MenuStack>('cdk-menu-stack');

/** Provider that provides the parent menu stack, or a new menu stack if there is no parent one. */
export const PARENT_OR_NEW_MENU_STACK_PROVIDER = {
  provide: MENU_STACK,
  deps: [[new Optional(), new SkipSelf(), new Inject(MENU_STACK)]],
  useFactory: (parentMenuStack?: MenuStack) => parentMenuStack || new MenuStack(),
};

@Component({
  selector: 'mm-menu-container',
  template: ` <ng-content></ng-content> `,
  exportAs: 'mmMenuContainer',
  styleUrls: ['../nick_styles/nick.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelMatchMenuContainerComponent {}

@Directive({
  selector: '[mm-menu]',
  exportAs: 'mmMenu',
})
export class ModelMatchMenuDirective extends CdkMenu implements AfterViewInit, OnDestroy {
  @Input() width: string = 'w-56';
  @HostBinding('class') get classes(): string {
    return `${this.width} rounded-md bg-white shadow-sm border border-black border-opacity-5 focus_outline-none`;
  }

  private $destroyed = new ReplaySubject<void>(1);

  constructor(private hostEl: ElementRef<HTMLElement>) {
    super();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.calcMaxHeight();
    });
    // get the window size
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.$destroyed))
      .subscribe(() => {
        this.calcMaxHeight();
      });
  }

  calcMaxHeight(): void {
    // how tall is the menu includeing the space from the top
    const distanceFromTop = this.hostEl.nativeElement.getBoundingClientRect().top;
    // how tall is the menu includeing the space from the bottom
    const menuHeight = this.hostEl.nativeElement.getBoundingClientRect().height;
    const totalHeight = distanceFromTop + menuHeight;

    // how tall is the window
    const windowHeight = window.innerHeight;
    // max height should be the height of the window minus the distance from the top
    let height = windowHeight - distanceFromTop;
    // the height of the menu won't be greater than the height of the window
    this.hostEl.nativeElement.style.maxHeight = `${height - 10 /** for padding */}px`;
    this.hostEl.nativeElement.style.overflowY = 'auto';
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }
}

@Directive({
  selector: '[mm-menubar-item]',
})
export class ModelMatchMenuBarItemDirective {
  @HostBinding('class') get classes(): string {
    return 'block px-4 py-2 text-sm hover_bg-slate-100 focus_bg-slate-100 focus_outline-none';
  }
  @Input() menu: TemplateRef<any>;

  constructor(
    public hostEl: ElementRef<HTMLElement>,
    public templateRef: TemplateRef<any>
  ) {}
}

@Directive({
  selector: '[mm-menu-item]',
  exportAs: 'mmMenuItem',
})
export class ModelMatchMenuItemDirective extends CdkMenuItem {
  //@ts-ignore
  @Input() disabled: boolean = false;
  @HostBinding('class') get classes(): string {
    return 'block px-4 py-2 rounded-md m-1 text-sm hover_bg-slate-100 focus_bg-slate-100 focus_outline-none cursor-pointer disabled_bg-gray-300 disabled_cursor-not-allowed';
  }

  constructor(public hostEl: ElementRef<HTMLElement>) {
    super();
  }
}

@Directive({
  selector: '[mm-menu-divider]',
})
export class ModelMatchMenuDividerDirective {
  @HostBinding('class') get classes(): string {
    return 'border-b border-slate-300';
  }
}

@Directive({
  selector: '[mm-menu-header]',
})
export class ModelMatchMenuHeaderDirective {}

@Component({
  selector: 'mm-menu-bar',
  styleUrls: ['../nick_styles/nick.css'],
  template: `
    <div class="flex space-x-2 items-center" cdkMenuBar>
      <ng-container *ngFor="let item of menuItems">
        <div *ngIf="item.templateRef" cdkMenuItem [cdkMenuTriggerFor]="item.menu">
          <ng-template [ngTemplateOutlet]="item.templateRef"></ng-template>
        </div>
      </ng-container>
    </div>
  `,
})
export class ModelMatchMenuBarComponent {
  @ContentChildren(ModelMatchMenuBarItemDirective)
  menuItems: QueryList<ModelMatchMenuBarItemDirective>;
}

@NgModule({
  declarations: [
    ModelMatchMenuDirective,
    ModelMatchMenuItemDirective,
    ModelMatchMenuDividerDirective,
    ModelMatchMenuHeaderDirective,
    ModelMatchMenuBarComponent,
    ModelMatchMenuBarItemDirective,
  ],
  exports: [
    ModelMatchMenuDirective,
    ModelMatchMenuItemDirective,
    ModelMatchMenuDividerDirective,
    ModelMatchMenuHeaderDirective,
    ModelMatchMenuBarComponent,
    ModelMatchMenuBarItemDirective,
  ],
  imports: [CdkMenuModule, CommonModule, ModelMatchButtonModule],
})
export class ModelMatchMenuModule {}
