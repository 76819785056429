import { Component, Inject, ViewChild, ElementRef, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ReportObj,
  ReportTemplate,
  ReportTemplateSettings,
  esFilter,
  ReportObjMenuItem,
  reqStagesArr,
} from '../../../shared/model/reporting';
import { map, startWith, take } from 'rxjs/operators';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ReqsPickerDialogComponent } from '../../pickers/requisitions/reqs-picker-dialog.component';
import { UsersPickerDialogComponent } from '../../pickers/users/users-picker-dialog.component';
import { SatDatepickerRangeValue } from 'saturn-datepicker-ext';
import { RangesFooter } from '../../analytics-stage/ranges-footer.component';
import { startOfDay, endOfDay } from 'date-fns';
import { ReportingService } from '../../../services/reporting.service';
import { User } from '../../../shared/model/user';
import { ContactStage, ContactTag } from '../../../shared/model/contacts';
import { Option } from '../../../shared/model/filter';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { SequenceTemplate } from '../../../shared/model/sms';

@Component({
  selector: 'mm-report-object-settings-dialog',
  templateUrl: './report-object-settings-dialog.component.html',
  styleUrls: ['./report-object-settings-dialog.component.scss'],
})
export class ReportObjectSettingsDialogComponent {
  menuItems: ReportObjMenuItem[] = [];
  searchReqsControl = new FormControl();
  filteredMenuItems: Observable<ReportObjMenuItem[]>;
  selectedMenuItems: ReportObjMenuItem[];
  startDate: string = '';
  endDate: string = '';
  dateRangeFilter: SatDatepickerRangeValue<Date>;
  triggerChange: boolean = false;
  previewLoading: boolean = true;
  reportingTheme: string = '';
  rangesFooter = RangesFooter;
  reqFiltersUsersLocal: User[] = [];
  stages: string[] = reqStagesArr;

  contactTags: ContactTag[] = [];
  filteredContactTags: Observable<ContactTag[]>;
  contactTagsControl: FormControl = new FormControl();
  @ViewChild('contactTagInput') contactTagInput: ElementRef<HTMLInputElement>;

  sequenceItems: SequenceTemplate[] = [];
  selectedSequence: string = '';
  steps = Array(100)
    .fill(0)
    .map((x, i) => i);
  selectedStep: any = '';

  readonly stageOptions: Option[] = [
    { value: ContactStage.PROSPECTS },
    { value: ContactStage.CANDIDATES },
    { value: ContactStage.MEETINGS },
    { value: ContactStage.OFFERS },
    { value: ContactStage.HIRES },
    { value: ContactStage.DISQUALIFIED },
    { value: ContactStage.NOT_INTRESTED },
  ];

  @ViewChild('searchObjListInput')
  searchObjListInput: ElementRef<HTMLInputElement>;

  constructor(
    private dialogRef: MatDialogRef<ReportObjectSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private snackBar: MatSnackBar,
    private reportPickerDialog: MatDialog,
    private reportingService: ReportingService
  ) {}

  ngOnInit() {
    this.previewLoading = true;
    this.reportingTheme = this.dialogData.reportingTheme;

    // Use local over rides if any defined
    this.startDate = this.dialogData.objRef.data.startDate
      ? this.dialogData.objRef.data.startDate
      : this.dialogData.objRef.startDate
      ? this.dialogData.objRef.startDate
      : '';
    this.endDate = this.dialogData.objRef.data.endDate
      ? this.dialogData.objRef.data.endDate
      : this.dialogData.objRef.endDate
      ? this.dialogData.objRef.endDate
      : '';

    this.dateRangeFilter = {
      begin: new Date(this.startDate),
      end: new Date(this.endDate),
    };

    this.contactTags = this.dialogData.contactTags;
    this.sequenceItems = this.dialogData.sequenceItems;

    this.filteredContactTags = this.contactTagsControl.valueChanges.pipe(
      startWith<string | ContactTag>(''),
      map((value) => {
        return typeof value === 'string' ? value : value?.name;
      }),
      map((name) => {
        return name ? this._filterContactTags(name) : this.contactTags?.slice();
      })
    );

    if (this.dialogData.objRef.data.selectedReqIds) this.getReqFiltersUsers(this.dialogData.objRef.data.selectedReqIds);

    // console.log('settings init', this.dialogData.objRef);

    if (this.contactTagsControl)
      this.contactTagsControl.setValue({
        id: 0,
        name: this.dialogData.objRef.data.tagsFilter,
      });

    if (this.contactTagInput)
      this.contactTagInput.nativeElement.value = this.dialogData.objRef.data.tagsFilter
        ? this.dialogData.objRef.data.tagsFilter
        : '';

    this.selectedSequence = this.dialogData.objRef.data.sequenceFilter;
    this.selectedStep = this.dialogData.objRef.data.sequenceStep;
  }

  // Method to filter contact tags autocomplete list based on what has been typed so far
  private _filterContactTags(name: string): ContactTag[] {
    const filterValue = name.toLowerCase();

    return this.contactTags.filter((contactTag) => contactTag.name.toLowerCase().indexOf(filterValue) === 0);
  }

  onApplySelected() {
    // console.log('this.contactTagInput.nativeElement.value', this.contactTagInput.nativeElement.value);
    // console.log('this.contactTagsControl.value', this.contactTagsControl.value);

    // this.dialogData.objRef.data.tagsFilter = name;

    // Pass back new user to assign todos data
    let data = { objRef: this.dialogData.objRef, cancel: false };

    this.dialogRef.close({ data: data });
  }

  onCloseClick() {
    // Pass back new user to assign todos data
    let data = { reportObjMenuItem: null, cancel: true };

    this.dialogRef.close({ data: data });
  }

  onFilterByUsers() {
    this.openUsersPickerDialog().subscribe(
      (openUsersPickerResult: any) => {
        // console.log('openUsersPickerDialog ', openUsersPickerResult.data);

        if (openUsersPickerResult.data.selectedUsers) {
          // Change selectedUsers
          // console.log(
          //   'Added Filtered selectedUsers openUsersPickerResult.data.selectedUsers',
          //   openUsersPickerResult.data.selectedUsers
          // );
          this.dialogData.objRef.data.selectedUsers = openUsersPickerResult.data.selectedUsers;
          this.dialogData.objRef.data.selectedUserIds = openUsersPickerResult.data.selectedUsers.map((user) => user.id);

          // console.log('Added Filtered selectedUsers this.selectedUsers', this.selectedUsers);
          // console.log('Added Filtered selectedUsers this.selectedUserIds', this.selectedUserIds);
        } else if (openUsersPickerResult.data.clear) {
          this.dialogData.objRef.data.selectedUsers = [];
          this.dialogData.objRef.data.selectedUserIds = [];
        } else {
        }

        this.triggerChange = !this.triggerChange;
      },

      (error) => {
        console.log('AStageComponent - openUsersPickerDialog error: ', error);
      }
    );
  }

  openUsersPickerDialog(): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      maxWidth: '1000px',
      width: '100%',
      maxHeight: '80vh',
      autoFocus: false,
      panelClass: 'add-object-dialog',
    };

    // console.log('this.reqFiltersUsersLocal', this.reqFiltersUsersLocal);
    // console.log('this.dialogData.reqFiltersUsersGlobal', this.dialogData.reqFiltersUsersGlobal);
    // console.log('this.dialogData.objRef', this.dialogData.objRef);

    dialogConfig.data = {
      tenants: this.dialogData.objRef.tenants,
      selectedUserIds: this.dialogData.objRef.data.selectedUserIds,
      target: `${this.dialogData.objRef.data.name}`,
      reqFiltersUsersGlobal: this.reqFiltersUsersLocal.length
        ? this.reqFiltersUsersLocal
        : this.dialogData.reqFiltersUsersGlobal,
    };

    const dialogRef = this.reportPickerDialog.open<UsersPickerDialogComponent, MatDialogConfig, boolean>(
      UsersPickerDialogComponent,
      dialogConfig
    );

    return dialogRef.afterClosed();
  }

  onFilterByReqs() {
    this.openReqsPickerDialog().subscribe(
      (ReqsPickerDialogComponent: any) => {
        // console.log("ReqsPickerDialogComponent ", ReqsPickerDialogComponent.data);

        if (ReqsPickerDialogComponent.data.reqs) {
          // Change Reqs
          // console.log('Added Filtered Reqs ReqsPickerDialogComponent.data.reqs', ReqsPickerDialogComponent.data.reqs)
          this.dialogData.objRef.data.selectedReqs = ReqsPickerDialogComponent.data.reqs;
          this.dialogData.objRef.data.selectedReqIds = ReqsPickerDialogComponent.data.reqs.map((req) => req.reqId);

          this.getReqFiltersUsers(this.dialogData.objRef.data.selectedReqIds);

          // console.log('Added Filtered Reqs this.reqs', this.reqs)
          // console.log('Added Filtered Reqs this.selectedReqIds', this.selectedReqIds)
        } else if (ReqsPickerDialogComponent.data.clear) {
          this.dialogData.objRef.data.selectedReqs = [];
          this.dialogData.objRef.data.selectedReqIds = [];
          this.reqFiltersUsersLocal = [];
        } else {
        }

        this.triggerChange = !this.triggerChange;
      },

      (error) => {
        console.log('AStageComponent - ReqsPickerDialogComponent error: ', error);
      }
    );
  }

  onDateRangeChange(dateRange: SatDatepickerRangeValue<Date>) {
    const startDate = dateRange ? startOfDay(dateRange.begin).toISOString() : null;
    const endDate = dateRange ? endOfDay(dateRange.end).toISOString() : null;

    this.startDate = startDate;
    this.endDate = endDate;

    this.dialogData.objRef.data.startDate = startDate;
    this.dialogData.objRef.data.endDate = endDate;
  }

  openReqsPickerDialog(): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      maxWidth: '1000px',
      width: '100%',
      autoFocus: false,
      panelClass: 'add-object-dialog',
    };

    dialogConfig.data = {
      reqs: this.dialogData.objRef.reqs,
      selectedReqIds: this.dialogData.objRef.data.selectedReqIds,
      target: `${this.dialogData.objRef.data.name}`,
    };

    const dialogRef = this.reportPickerDialog.open<ReqsPickerDialogComponent, MatDialogConfig, boolean>(
      ReqsPickerDialogComponent,
      dialogConfig
    );

    return dialogRef.afterClosed();
  }

  objLoaded(event: any, index: number) {
    // console.log('objLoaded index event', index, event);
    this.previewLoading = false;
  }

  onClickColorChange(color) {
    // Set the passed in color
    this.dialogData.objRef.data.color = color;
  }

  toggleChart(chartType: string) {
    this.dialogData.objRef.data.chartType = chartType;
  }

  objDataChanged(event: any, index: number) {
    // Set data on object based on index
    // console.log("objDataChanged event", event);
  }

  getReqFiltersUsers(reqIds) {
    this.reportingService
      .getReqFiltersUsers(reqIds)
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.reqFiltersUsersLocal = result.data.getReqFiltersUsers;
        },
        (error) => {
          console.log('getReqFiltersUsers : Error fetching requisition reqUsers : ', error);
        }
      );
  }

  stageChanged() {
    console.log('stageChanged', this.dialogData.objRef.data.reqStage);

    this.triggerChange = !this.triggerChange;
  }

  tagsFilterstageChanged() {
    if (!this.contactTagInput) return;

    console.log('tags filter changed ', this.dialogData.objRef.data.tagsFilter);

    this.dialogData.objRef.data.tagsFilter = this.contactTagInput.nativeElement.value;

    this.triggerChange = !this.triggerChange;
  }

  displayContactTagFn(contactTag: ContactTag): string {
    return contactTag && contactTag.name ? contactTag.name : '';
  }

  // Event is fired whenever a new tag is selected
  selectedContactTag(event: MatAutocompleteSelectedEvent): void {
    // Only push to list if value exists AND is not already on list

    this.dialogData.objRef.data.tagsFilter = event.option.value.name;

    console.log('tags filter changed ', this.dialogData.objRef.data.tagsFilter);

    this.dialogData.objRef.data.tagsFilter = this.contactTagInput.nativeElement.value;

    this.triggerChange = !this.triggerChange;
  }

  onSequenceFilterChanged(event: any) {
    this.dialogData.objRef.data.sequenceFilter = event.value;

    this.triggerChange = !this.triggerChange;
  }

  onSequenceStepChanged(event: any) {
    this.dialogData.objRef.data.sequenceStep = event.value;

    this.triggerChange = !this.triggerChange;
  }

  public sequenceObjectComparisonFunction = function (option, value): boolean {
    return option && value ? option.id === value?.id : false;
  };
}
