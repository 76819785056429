<section class="mms-media-section">
  <div *ngIf="uploadingMedia" fxFlex fxLayoutAlign="center center" class="content-processing-section">
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center" class="progress-container">
      <div class="progress-container">
        <mat-progress-bar mode="buffer"></mat-progress-bar>
        <h5>{{ progressMessage }}</h5>
      </div>
    </div>
  </div>

  <div *ngIf="!uploadingMedia" style="width: 100%">
    <h4 class="add-header">
      <button mat-mini-fab color="accent" (click)="documentInput.click()" class="add-button">
        <mat-icon>add</mat-icon>
      </button>
      Media
    </h4>

    <mat-selection-list class="mms-media-list" [multiple]="false" (selectionChange)="onSelection($event)">
      <mat-list-option *ngFor="let item of mmsMedia; let mmsIndex = index" [value]="item" style="position: relative">
        <!-- <img matListAvatar src="{{ item.thumbUrl }}" (onerror)="this.src = 'item.url'" alt="..." /> -->

        <img
          *ngIf="
            ['image/jpeg', 'image/jpg', 'image/gif', 'image/tiff', 'image/webp', 'image/png'].indexOf(
              item.fileType.toLowerCase()
            ) >= 0
          "
          matListAvatar
          src="{{ item.thumbUrl }}"
          (onerror)="this.src = 'item.url'"
          alt="..."
        />
        <mat-icon
          matListAvatar
          *ngIf="
            ['image/jpeg', 'image/jpg', 'image/gif', 'image/tiff', 'image/webp', 'image/png'].indexOf(
              item.fileType.toLowerCase()
            ) == -1
          "
          >attach_file</mat-icon
        >

        <p matLine>
          <span> {{ item.documentName }} </span>
        </p>
        <p matLine>
          <span class="timestamp">{{ item.createdAt | date: 'shortDate' }} </span>
        </p>

        <p class="button-remove-media">
          <button mat-icon-button (click)="onDeleteMediaItem($event, mmsIndex)">
            <mat-icon>clear</mat-icon>
          </button>
        </p>
      </mat-list-option>
    </mat-selection-list>
  </div>
</section>

<input #documentInput (change)="uploadDocumentHandler($event)" type="file" hidden />
