<div mat-dialog-title>
  <button mat-icon-button class="button-close" (click)="onCloseClick()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="flex-container header-container" fxLayoutGap="1em" fxLayout="row" fxLayout.lt-lg="column">
    <div fxFlex="25%">
      <header class="dialog-header">
        <h3>
          Pipelines
          <div *ngIf="data.target" class="mat-caption">{{ data.target }}</div>
        </h3>
      </header>
    </div>

    <div fxFlex fxLayoutAlign="start center" class="req-options">
      <mat-form-field appearance="outline" style="width: 100%; margin-right: 2em">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" #searchReqsInput [formControl]="searchReqsControl" />
      </mat-form-field>
    </div>
  </div>

  <div >
    <mat-checkbox
      fxFlex
      fxLayoutAlign="end center"
      [checked]="allSelected"
      [indeterminate]="someChecked()"
      (change)="selectAll($event)"
      class="select-all"
      labelPosition="before"
      >All
    </mat-checkbox>
   
    <div style="padding-right: 2em; width: 10em">
      <mm-select
      [value]="[selectedTag]"
      (selected)="updateTypeFilter($event?.[0])"
    >
    
      <ng-container *ngIf="reqTags.length > 0" >
        <mm-option *ngFor="let tag of reqTags" [value]="tag">
          <span>{{ tag }}</span>
        </mm-option>
      </ng-container>
      </mm-select>
    </div>
  </div>
</div>

<!-- was mat-elevation-z2 -->
<mat-dialog-content class="picker-section-content">
  <div *ngIf="!dataLoaded">
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="dataLoaded">
    <!-- navigation list for each req template item -->
    <mat-selection-list #reqSelect [(ngModel)]="selectedReqs" (selectionChange)="onSelection($event)">
      <mat-list-option
        *ngFor="let req of filteredReqs | async; let index = index"
        class="req-list-item"
        id="reqItem{{ index }}"
        [value]="req"
      >
        <div class="edit-req-text">
          <div fxFlex="10">
            <mat-icon class="muted edit-req-icon">business </mat-icon>
          </div>

          <div fxFlex>
            {{ req.title }} 
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="picker-actions-container">
  <div class="picker-actions">
    <p>Pipelines Selected: {{ selectedReqs?.length }}</p>

    <button mat-raised-button (click)="clearAll()">Clear</button>
    <button mat-raised-button (click)="onFilterSelected()" color="primary">Filter</button>
  </div>
</div>
