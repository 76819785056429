import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import {
  DisplayGrid,
  CompactType,
  GridsterConfig,
  GridsterItem,
  GridType,
  GridsterPush,
  GridsterItemComponent,
} from 'angular-gridster2';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ObjectDownloadService } from '../object-download.service';
import { SatDatepickerRangeValue } from 'saturn-datepicker-ext';
import { subDays, startOfDay, endOfDay } from 'date-fns';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ContactTag, newContactTag } from '../../shared/model/contacts';
import {
  ReportObj,
  ReportTemplate,
  ReportTemplateSettings,
  esFilter,
  ReportObjMenuItem,
  reportingThemes,
  ReportTemplateHistory,
} from '../../shared/model/reporting';
import { SequenceTemplate } from '../../shared/model/sms';
import { Tenant } from '../../shared/model/tenant';
import { User } from '../../shared/model/user';
import { UserReq } from '../../shared/model/requisitions';
import { ContactService } from '../../services/contact.service';
import { ReportingService } from '../../services/reporting.service';
import { RequisitionService } from '../../services/requisition.service';
import { SMSServiceNeo } from '../../services/sms.appsync.service';
import { TenantService } from '../../services/tenant.service';
import { UserService } from '../../services/user.service';
import { ReportObjectSettingsDialogComponent } from '../pickers/report-object-settings/report-object-settings-dialog.component';
import { ReportPickerDialogComponent } from '../pickers/report-objects/report-picker-dialog.component';
import { ReportSharePickerDialogComponent } from '../pickers/report-share/report-share-picker-dialog.component';
import { ReportTemplatePickerDialogComponent } from '../pickers/report-templates/report-template-picker-dialog.component';
import { ReqsPickerDialogComponent } from '../pickers/requisitions/reqs-picker-dialog.component';
import { UsersPickerDialogComponent } from '../pickers/users/users-picker-dialog.component';
import { v4 as uuid } from 'uuid';
import { RangesFooter } from './ranges-footer.component';
import { ReportLinkShareComponent } from '../link-share/link-share.component';

/**
 * This is the Main Stage for Reporting and Analytics
 */
@Component({
  selector: 'mm-a-stage-page',
  templateUrl: './a-stage.component.html',
  styleUrls: ['./a-stage.component.scss'],
})
export class AStageComponent implements OnInit {
  @Output() dateRangeChanged = new EventEmitter<{
    startDate;
    endDate;
  }>();
  @Input() contactId: string;
  @Input() reqId: string;

  @ViewChild('files')
  inputFilesVariable: ElementRef;

  startDate: string = '';
  endDate: string = '';

  allObjsLoaded: boolean = false;

  reportingTheme: reportingThemes = 'theme-blues';

  // Need the ComponentFactoryResolver to add components
  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private snackBar: MatSnackBar,
    private objectDownloadService: ObjectDownloadService,
    private dialog: MatDialog,
    private reportPickerDialog: MatDialog,
    private reqService: RequisitionService,
    private tenantService: TenantService,
    private reportingService: ReportingService,
    private userService: UserService,
    private contactService: ContactService,
    private smsServiceNeo: SMSServiceNeo
  ) {}

  // An array of all the report objects currently on the stage - track adds/deletes/reorder here
  reportObjects: ReportObj[] = [];
  reportObjectsDefault: ReportObj[] = [];
  reportTemplate: ReportTemplate = {
    id: '',
    title: 'Sample Template',
    desc: 'Sample Template Description',
    reportObjects: [],
    reportTemplateSettings: {},
  };
  reportTemplateId: string = '';
  dateRangeFilter: SatDatepickerRangeValue<Date>;
  timeSelected: Number = 0;
  selectedUserIds: string[] = [];
  selectedUsers: User[] = [];
  reqs: UserReq[] = [];
  selectedReqs: UserReq[] = [];
  selectedReqIds: string[] = [];
  tenants: Tenant[];
  userTenant: Tenant;
  tenantUsers: User[] = [];
  reqsPreloaded: boolean = false;
  tenantsPreloaded: boolean = false;
  options: GridsterConfig;
  dashboard: Array<GridsterItem>;
  savingReportingTemplate: boolean = false;
  reportTemplates: ReportTemplate[] = [];
  rangesFooter = RangesFooter;
  templateHistory: ReportTemplateHistory[] = [];
  stageLoading: boolean = false;
  linkShareMode: boolean = false;
  linkId: string = '';
  currentUserInfo: any;
  currentUser: any = {};
  filterUsersOption: boolean = false;
  reqFiltersUsersGlobal: User[] = [];
  private snackBarRef: MatSnackBarRef<any> = null;
  contactTags: ContactTag[] = [];
  contactReqTags: ContactTag[] = [];
  sequenceItems: SequenceTemplate[] = [];

  static itemChange(item, itemComponent) {
    // console.info('itemChanged', item, itemComponent);
  }

  static itemResize(item, itemComponent) {
    //  console.info('itemResized', item, itemComponent);
  }

  ngOnInit() {
    // Preload data for pickers and widgets that need them so they respond faster initially

    // Check route for link share mode

    let today = new Date();
    let fromDate = new Date();
    fromDate = subDays(today, 120);

    this.startDate = fromDate ? startOfDay(fromDate).toISOString() : null;
    this.endDate = today ? endOfDay(today).toISOString() : null;

    this.dateRangeFilter = {
      begin: new Date(this.startDate),
      end: new Date(this.endDate),
    };

    this.activatedRoute.paramMap.subscribe((params) => {
      this.reportTemplateId = params.get('reportTemplateId');
      this.linkId = params.get('linkId');

      // Is this is a share link then do lookup of data and use it
      if (this.linkId) {
        this.linkShareMode = true;
        this.reportingService.readReportingSnapshot(this.linkId).subscribe((reportingSnapshot) => {
          this.reportTemplate = reportingSnapshot.reportTemplate;

          // Load dates
          if (this.reportTemplate.reportTemplateSettings.startDate) {
            this.startDate = this.reportTemplate.reportTemplateSettings.startDate;
          }

          if (this.reportTemplate.reportTemplateSettings.endDate) {
            this.endDate = this.reportTemplate.reportTemplateSettings.endDate;
          }

          this.dateRangeFilter = {
            begin: new Date(this.startDate),
            end: new Date(this.endDate),
          };

          // Load selectedUserIds
          if (this.reportTemplate.reportTemplateSettings.selectedUserIds) {
            this.selectedUserIds = this.reportTemplate.reportTemplateSettings.selectedUserIds;
          }

          // Load selectedUsers
          if (this.reportTemplate.reportTemplateSettings.selectedUsers) {
            this.selectedUsers = this.reportTemplate.reportTemplateSettings.selectedUsers;
          }

          // Load selectedReqIds
          if (this.reportTemplate.reportTemplateSettings.selectedReqIds) {
            this.selectedReqIds = this.reportTemplate.reportTemplateSettings.selectedReqIds;
          }

          console.log('this.reportTemplate.reportTemplateSettings', this.reportTemplate.reportTemplateSettings);
          // Load selectedReqs
          if (this.reportTemplate.reportTemplateSettings.selectedReqs) {
            this.selectedReqs = this.reportTemplate.reportTemplateSettings.selectedReqs;

            console.log('this.selectedReqs', this.selectedReqs);
          }

          console.warn(this.reportTemplate);
          this.reportTemplate.reportObjects.forEach((element) => {
            element.useStoredData = true;
          });
        });
      }

      this.stageLoading = true;

      if (!this.linkShareMode) {
        this.userService
          .getAuthenticatedUserInfo()
          .pipe(take(1))
          .subscribe(
            (result) => {
              this.currentUserInfo = result;

              this.setupUserFilterOption();
              this.preLoadUserInfo();
              this.preLoadReqs();
              this.preLoadTenants();
              this.preLoadReportTemplates(true);
              this.loadSequences();
            },
            (error) => {
              console.log('Error getting current user info.', error);
            }
          );
      }

      this.options = {
        gridType: GridType.VerticalFixed,
        compactType: CompactType.None,
        displayGrid: DisplayGrid.OnDragAndResize,
        minCols: 12,
        maxCols: 12,
        maxItemCols: 12,
        minItemCols: 2,
        fixedRowHeight: 100,
        keepFixedHeightInMobile: true,
        keepFixedWidthInMobile: false,
        mobileBreakpoint: 640,
        pushItems: true,
        pushDirections: { north: true, east: true, south: true, west: true },
        draggable: {
          enabled: true,
        },
        resizable: {
          enabled: true,
        },
        itemChangeCallback: AStageComponent.itemChange,
        itemResizeCallback: AStageComponent.itemResize,
      };

      if (this.linkShareMode) {
        this.options.draggable.enabled = false;
        this.options.resizable.enabled = false;

        this.stageLoading = false;
      }
    });

    this.loadContactTagsList();
  }

  preLoadUserInfo() {
    this.userService
      .getAuthenticatedUserId()
      .pipe(take(1))
      .subscribe(
        (userId: string) => {
          this.userService
            .getUserInfo(userId)
            .pipe(take(1))
            .subscribe((res) => {
              let result = structuredClone(res);
              delete result.data.getUserInfo.emails.__typename;

              result.data.getUserInfo.emails.forEach((email) => {
                delete email.__typename;
              });

              this.currentUser.id = userId;
              this.currentUser.firstName = result.data.getUserInfo.firstName;
              this.currentUser.lastName = result.data.getUserInfo.lastName;
              this.currentUser.emails = result.data.getUserInfo.emails;

              console.log('from preLoadUserInfo: this.currentUser', this.currentUser);
            });
        },
        (error: unknown) => {
          console.log('LandingPageComponent: Error getting authenticated userId -> ' + error);
        }
      );
  }

  preLoadReqs() {
    this.reqService
      .getMyReqs()
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.reqs = result.data.getMyReqs;
          this.reqsPreloaded = true;
        },
        (error) => {
          console.log('PreLoadReqs: error loading reqs == ', error);
        }
      );
  }

  preLoadTenants() {
    this.tenantService
      .getMyTenants()
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.tenants = result.data.getMyTenants;
          this.tenantUsers = result.data.getMyTenants[0].user.filter(
            (tenantUser) => tenantUser.id !== this.currentUser.id
          );
          this.tenantsPreloaded = true;
        },
        (error) => {
          console.log('PreLoadTenants: error loading tenants == ', error);
        }
      );
  }

  onDateRangeChange(dateRange: SatDatepickerRangeValue<Date>) {
    const startDate = dateRange ? startOfDay(dateRange.begin).toISOString() : null;
    const endDate = dateRange ? endOfDay(dateRange.end).toISOString() : null;

    this.reportTemplate.reportObjects.forEach((obj, targetIndex) => {
      this.reportTemplate.reportObjects[targetIndex].data.startDate = '';
      this.reportTemplate.reportObjects[targetIndex].data.endDate = '';
    });

    this.startDate = startDate;
    this.endDate = endDate;

    // Emit the new date range to parent component
    this.dateRangeChanged.emit({
      startDate,
      endDate,
    });
    this.timeSelected = 0;

    // Remove any date range filters on any
    this.reportTemplate.reportObjects.forEach;
  }

  changedOptions(): void {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      // console.log("changes propName", propName);
      if (changes.hasOwnProperty(propName) && Object.keys(changes).length === 1) {
        switch (propName) {
          case 'contactPhone': {
            break;
          }
        }
      }
    }
  }

  onClickAdd(reportObjMenuItem: ReportObjMenuItem) {
    // Search array of report objects

    if (typeof reportObjMenuItem.data.aggregation != 'object' && reportObjMenuItem.data.aggregation) {
      // console.log('this.data.aggregation parse now before stage');
      reportObjMenuItem.data.aggregation = JSON.parse(reportObjMenuItem.data.aggregation);
    }

    if (typeof reportObjMenuItem.data.aggs != 'object' && reportObjMenuItem.data.aggs) {
      // console.log('this.data.aggregation parse now before stage');
      reportObjMenuItem.data.aggs = JSON.parse(reportObjMenuItem.data.aggs);
    }

    if (typeof reportObjMenuItem.data.filters != 'object' && reportObjMenuItem.data.filters) {
      // console.log('this.data.aggregation parse now before stage');
      reportObjMenuItem.data.filters = JSON.parse(reportObjMenuItem.data.filters);
    }

    if (reportObjMenuItem) {
      let RO = {
        id: uuid, // '' + this.reportTemplate.reportObjects.length,
        name: 'New report',
        rType: reportObjMenuItem.rType,
        cols: reportObjMenuItem.cols,
        rows: reportObjMenuItem.rows,
        data: reportObjMenuItem.data,
        esFilters: reportObjMenuItem.esFilters,
      };

      this.reportTemplate.reportObjects.push(RO);
    }
  }

  downloadStage($event: MouseEvent | TouchEvent, withData: boolean) {
    let reportTemplateBuff = JSON.parse(JSON.stringify(this.reportTemplate));

    if (!withData) {
      reportTemplateBuff.reportObjects.forEach((reportObj) => delete reportObj.data.raw);
    }

    // ****Todo Add option to keep or ignore various values on save
    reportTemplateBuff.reportTemplateSettings.startDate = this.startDate;
    reportTemplateBuff.reportTemplateSettings.endDate = this.endDate;
    reportTemplateBuff.reportTemplateSettings.selectedUserIds = this.selectedUserIds;
    reportTemplateBuff.reportTemplateSettings.selectedUsers = this.selectedUsers;
    reportTemplateBuff.reportTemplateSettings.selectedReqIds = this.selectedReqIds;
    reportTemplateBuff.reportTemplateSettings.selectedReqs = this.selectedReqs;

    this.objectDownloadService.downloadFile(reportTemplateBuff, 'reportingTemplate');
  }

  importFromS3(linkId: string) {
    // Load string from S3 and dynamo
  }

  importFromFile(event) {
    let input = event.target;

    for (var index = 0; index < input.files.length; index++) {
      let reader = new FileReader();

      reader.onload = () => {
        // this 'text' is the content of the file
        var inpText = reader.result;

        // console.log('text read from file', inpText);

        let retVal = false;

        try {
          const newReportTemplate = JSON.parse(inpText as string);

          // Basic sanity checking on imported object
          if (typeof newReportTemplate === 'object' && newReportTemplate !== null) {
            retVal = true;

            if (!('reportObjects' in newReportTemplate)) retVal = false;

            if (!('rType' in newReportTemplate.reportObjects[0])) retVal = false;

            if (!('cols' in newReportTemplate.reportObjects[0])) retVal = false;

            if (!('rows' in newReportTemplate.reportObjects[0])) retVal = false;

            if (retVal) {
              // Empty current
              this.reportTemplate.reportObjects = [];

              // Testing... Set each obj to use cached data
              // newReportTemplate.reportObjects.forEach((element) => {
              //   element.useStoredData = true;
              // });

              // Let UI know we are now in link share mode - read data from raw object - no DB lookups
              // this.linkShareMode = true;
              // this.options.draggable.enabled = false;
              // this.options.resizable.enabled = false;
              // this.options.api.optionsChanged();

              this.reportTemplate.reportObjects = newReportTemplate.reportObjects;

              // Load dates
              if (newReportTemplate.reportTemplateSettings.startDate) {
                this.startDate = newReportTemplate.reportTemplateSettings.startDate;
              }

              if (newReportTemplate.reportTemplateSettings.endDate) {
                this.endDate = newReportTemplate.reportTemplateSettings.endDate;
              }

              this.dateRangeFilter = {
                begin: new Date(this.startDate),
                end: new Date(this.endDate),
              };

              // Load selectedUserIds
              if (newReportTemplate.reportTemplateSettings.selectedUserIds) {
                this.selectedUserIds = newReportTemplate.reportTemplateSettings.selectedUserIds;
                this.selectedUsers = newReportTemplate.reportTemplateSettings.selectedUsers;
              }

              // Load selectedReqIds
              if (newReportTemplate.reportTemplateSettings.selectedReqIds) {
                this.selectedReqIds = newReportTemplate.reportTemplateSettings.selectedReqIds;
                this.selectedReqs = newReportTemplate.reportTemplateSettings.selectedReqs;
                this.getReqFiltersUsers(this.selectedReqIds);
              }
            }
          } else retVal = false;

          this.inputFilesVariable.nativeElement.value = '';
        } catch (any) {}
      };

      reader.readAsText(input.files[index]);

      let url = 'reporting/analytics/';
      this.location.replaceState(url);
    }
  }

  onAddStage() {
    this.openReportPickerDialog().subscribe(
      (openReportPickerResult: any) => {
        // console.log('openReportPickerDialog ', openReportPickerResult.data.reportObjMenuItem, openReportPickerResult);

        if (openReportPickerResult.data.reportObjMenuItem) {
          // Move on setting picker first before adding to stage
          this.onNewReportObjectSettings(JSON.parse(JSON.stringify(openReportPickerResult.data.reportObjMenuItem)));
        } else if (openReportPickerResult.data.clear) {
          this.reportTemplate.reportObjects = [];
        } else {
        }
      },

      (error) => {
        console.log('AStageComponent - openReportPickerDialog error: ', error);
      }
    );
  }

  openReportPickerDialog(): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      maxWidth: '1000px',
      width: '100%',
      maxHeight: '80vh',
      autoFocus: false,
      panelClass: 'add-report-object-dialog',
    };

    dialogConfig.data = { startDate: this.startDate, endDate: this.endDate };

    const dialogRef = this.reportPickerDialog.open<ReportPickerDialogComponent, MatDialogConfig, boolean>(
      ReportPickerDialogComponent,
      dialogConfig
    );

    return dialogRef.afterClosed();
  }

  onFilterByUsers() {
    this.openUsersPickerDialog().subscribe(
      (openUsersPickerResult: any) => {
        // console.log('openUsersPickerDialog ', openUsersPickerResult.data);

        if (openUsersPickerResult.data.selectedUsers) {
          this.reportTemplate.reportObjects.forEach((obj, targetIndex) => {
            this.reportTemplate.reportObjects[targetIndex].data.selectedUsers = [];
            this.reportTemplate.reportObjects[targetIndex].data.selectedUserIds = [];
          });

          // Change selectedUsers
          //console.log(
          //   'Added Filtered selectedUsers openUsersPickerResult.data.selectedUsers',
          //   openUsersPickerResult.data.selectedUsers
          // );
          this.selectedUsers = openUsersPickerResult.data.selectedUsers;
          this.selectedUserIds = openUsersPickerResult.data.selectedUsers.map((user) => user.id);

          // console.log('Added Filtered selectedUsers this.selectedUsers', this.selectedUsers);
          // console.log('Added Filtered selectedUsers this.selectedUserIds', this.selectedUserIds);
        } else if (openUsersPickerResult.data.clear) {
          this.reportTemplate.reportObjects.forEach((obj, targetIndex) => {
            this.reportTemplate.reportObjects[targetIndex].data.selectedUsers = [];
            this.reportTemplate.reportObjects[targetIndex].data.selectedUserIds = [];
          });

          this.selectedUsers = [];
          this.selectedUserIds = [];
        } else {
        }
      },

      (error) => {
        console.log('AStageComponent - openUsersPickerDialog error: ', error);
      }
    );
  }

  openUsersPickerDialog(): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      maxWidth: '1000px',
      width: '100%',
      maxHeight: '80vh',
      autoFocus: false,
      panelClass: 'add-object-dialog',
    };

    dialogConfig.data = {
      tenants: this.tenants,
      selectedUserIds: this.selectedUserIds,
      reqFiltersUsersGlobal: this.reqFiltersUsersGlobal,
    };

    const dialogRef = this.reportPickerDialog.open<UsersPickerDialogComponent, MatDialogConfig, boolean>(
      UsersPickerDialogComponent,
      dialogConfig
    );

    return dialogRef.afterClosed();
  }

  onFilterByReqs() {
    this.openReqsPickerDialog().subscribe(
      (ReqsPickerDialogComponent: any) => {
        // console.log("ReqsPickerDialogComponent ", ReqsPickerDialogComponent.data);

        if (ReqsPickerDialogComponent.data.reqs) {
          this.reportTemplate.reportObjects.forEach((obj, targetIndex) => {
            this.reportTemplate.reportObjects[targetIndex].data.selectedReqs = [];
            this.reportTemplate.reportObjects[targetIndex].data.selectedReqIds = [];
            this.reportTemplate.reportObjects[targetIndex].data.reqFiltersUsersGlobal = [];
          });

          // Change Reqs
          //console.log('Added Filtered Reqs ReqsPickerDialogComponent.data.reqs', ReqsPickerDialogComponent.data.reqs);
          this.selectedReqs = ReqsPickerDialogComponent.data.reqs;
          this.selectedReqIds = ReqsPickerDialogComponent.data.reqs.map((req) => req.reqId);

          // Look up all the users for each req and merge purge into an array of alternate users for the users pickers
          this.getReqFiltersUsers(this.selectedReqIds);

          // console.log('Added Filtered Reqs this.reqs', this.reqs);
          // console.log('Added Filtered Reqs this.selectedReqIds', this.selectedReqIds);
        } else if (ReqsPickerDialogComponent.data.clear) {
          this.reportTemplate.reportObjects.forEach((obj, targetIndex) => {
            this.reportTemplate.reportObjects[targetIndex].data.selectedReqs = [];
            this.reportTemplate.reportObjects[targetIndex].data.selectedReqIds = [];
            this.reportTemplate.reportObjects[targetIndex].data.reqFiltersUsersGlobal = [];
          });

          this.selectedReqs = [];
          this.selectedReqIds = [];
          this.reqFiltersUsersGlobal = [];
        } else {
        }
      },

      (error) => {
        console.log('AStageComponent - ReqsPickerDialogComponent error: ', error);
      }
    );
  }

  openReqsPickerDialog(): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      maxWidth: '1000px',
      width: '100%',
      autoFocus: false,
      panelClass: 'add-object-dialog',
    };

    dialogConfig.data = {
      reqs: this.reqs,
      selectedReqIds: this.selectedReqIds,
    };

    const dialogRef = this.reportPickerDialog.open<ReqsPickerDialogComponent, MatDialogConfig, boolean>(
      ReqsPickerDialogComponent,
      dialogConfig
    );

    return dialogRef.afterClosed();
  }

  objDataChanged(event: any, index: number, selItem: GridsterItem) {
    // Set data on object based on index
    // console.log('objDataChanged event', event, this.reportTemplate.reportObjects[index].data.slideRight);
    this.reportTemplate.reportObjects[index].data = event;

    // Watch for slideRight - expand cols to stage width
    if (this.reportTemplate.reportObjects[index].data.slideRight) {
      // console.log('objDataChanged event slideRight is true');
      this.openSlideRight(index);
    } else {
      // console.log('objDataChanged event slideRight is false');
      this.closeSlideRight(index);
    }
  }

  closeSlideAllOtherObjects(index) {
    this.reportTemplate.reportObjects.forEach((obj, targetIndex) => {
      if (targetIndex != index) {
        if (this.reportTemplate.reportObjects[targetIndex].data.slideRight) {
          this.reportTemplate.reportObjects[targetIndex].data.slideRight = false;
          // this.reportTemplate.reportObjects[targetIndex].triggerChange = !this.reportTemplate.reportObjects[targetIndex]
          //   .triggerChange;

          this.closeSlideRight(targetIndex);
        }
      }
    });
  }

  closeSlideRight(index) {
    if (this.reportTemplate.reportObjects[index].data.orgCols) {
      this.reportTemplate.reportObjects[index].cols = this.reportTemplate.reportObjects[index].data.orgCols;
      this.reportTemplate.reportObjects[index].rows = this.reportTemplate.reportObjects[index].data.orgRows;

      // Remove once reset
      delete this.reportTemplate.reportObjects[index].data.orgCols;
      delete this.reportTemplate.reportObjects[index].data.orgRows;

      // Is there a better/proper way to do this or just fake it put like this
      this.options.compactType = CompactType.CompactUp;
      this.options.api.optionsChanged();

      this.options.compactType = CompactType.None;
      this.options.api.optionsChanged();
    }
  }

  openSlideRight(index) {
    // Only expand if not already expanded
    if (!this.reportTemplate.reportObjects[index].data.orgCols) {
      this.closeSlideAllOtherObjects(index);
      this.reportTemplate.reportObjects[index].data.orgCols = this.reportTemplate.reportObjects[index].cols;
      this.reportTemplate.reportObjects[index].data.orgRows = this.reportTemplate.reportObjects[index].rows;

      this.reportTemplate.reportObjects[index].cols = this.options.maxCols;
      this.reportTemplate.reportObjects[index].rows =
        this.reportTemplate.reportObjects[index].rows >= 8 ? this.reportTemplate.reportObjects[index].rows : 8;

      this.options.api.optionsChanged();

      this.pushItem(
        this.options.api.getItemComponent(
          this.reportTemplate.reportObjects[index] as GridsterItem
        ) as GridsterItemComponent
      );
    }
  }

  pushItem(itemToPush: GridsterItemComponent): void {
    console.log('pushItem', itemToPush);
    const push = new GridsterPush(itemToPush); // init the service
    // itemToPush.$item.rows += 4; // move/resize your item
    if (push.pushItems(push.fromNorth)) {
      console.log('pushItem fromNorth');
      // push items from a direction
      push.checkPushBack(); // check for items can restore to original position
      push.setPushedItems(); // save the items pushed
      itemToPush.setSize();
      itemToPush.checkItemChanges(itemToPush.$item, itemToPush.item);
    } else {
      console.log('pushItem return');
      // this.itemToPush.$item.rows -= 4;
      push.restoreItems(); // restore to initial state the pushed items
    }
    push.destroy(); // destroy push instance
    // similar for GridsterPushResize and GridsterSwap
    // this.options.api.optionsChanged();
  }

  objDeleted(event: any, index: number) {
    this.reportTemplate.reportObjects.splice(index, 1);
  }

  objLoaded(event: any, index: number) {
    // console.log('objLoaded index event', index, event);

    this.reportTemplate.reportObjects[index].loaded = true;

    this.reportTemplate.reportObjects[index].data.raw = event;

    // Check is all objects have been loaded
    if (this.reportTemplate.reportObjects.findIndex((element) => !element.loaded) < 0) {
      this.allObjsLoaded = true;
    }
  }

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
  }

  clearAll() {
    // Are you sure picker...
    this.reportTemplate.reportObjects = [];
    this.reportTemplate.id = '';
    this.reportTemplate.title = 'New Template';

    let url = `reporting/analytics/${this.reportTemplate.id}`;
    this.location.replaceState(url);
  }

  saveAsNewReportingTemplate(withData: boolean = false) {
    this.savingReportingTemplate = true;

    let reportTemplateBuff = JSON.parse(JSON.stringify(this.reportTemplate));

    if (!withData) {
      reportTemplateBuff.reportObjects.forEach((reportObj) => delete reportObj.data.raw);
    }

    // since it is save as new - remove the current .id
    reportTemplateBuff.id = '';

    this.reportingService
      .createReportingTemplate(reportTemplateBuff, null)
      .pipe(take(1))
      .subscribe(
        (result) => {
          // console.log("getReportingCounts User :: result ", result);

          // Create new needs to return new template so we can get Id

          this.savingReportingTemplate = false;

          // Reload remote templates on every change so picker keeps up to date
          // Do reload instead of local update to help keep in sync with other possible remote changes
          this.preLoadReportTemplates();

          console.log('from saveAsNewReportingTemplate: result', result);

          let parseResult = JSON.parse(result.data.createReportingTemplate);
          let url = `reporting/analytics/${parseResult.id}`;
          this.reportTemplate.id = parseResult.id;
          this.location.replaceState(url);
        },
        (error) => {
          console.log('saveReportingTemplate User :: Error ', error);

          this.savingReportingTemplate = false;
        }
      );
  }

  saveReportingTemplate(withData: boolean = false) {
    // If this is a new template - create new
    if (!this.reportTemplate.id) {
      this.saveAsNewReportingTemplate(withData);
      return;
    }

    this.savingReportingTemplate = true;

    let reportTemplateBuff = JSON.parse(JSON.stringify(this.reportTemplate));

    if (!withData) {
      reportTemplateBuff.reportObjects.forEach((reportObj) => delete reportObj.data.raw);
    }

    // ****Todo Add option to keep or ignore various values on save
    reportTemplateBuff.reportTemplateSettings.startDate = this.startDate;
    reportTemplateBuff.reportTemplateSettings.endDate = this.endDate;
    reportTemplateBuff.reportTemplateSettings.selectedUserIds = this.selectedUserIds;
    reportTemplateBuff.reportTemplateSettings.selectedUsers = this.selectedUsers;
    reportTemplateBuff.reportTemplateSettings.selectedReqIds = this.selectedReqIds;
    reportTemplateBuff.reportTemplateSettings.selectedReqs = this.selectedReqs;

    this.reportingService
      .updateReportingTemplate(JSON.stringify(reportTemplateBuff), null)
      .pipe(take(1))
      .subscribe(
        (result) => {
          // console.log("getReportingCounts User :: result ", result);

          this.savingReportingTemplate = false;

          // Reload remote templates on every change so picker keeps up to date
          // Do reload instead of local update to help keep in sync with other possible remote changes
          this.preLoadReportTemplates();
        },
        (error) => {
          console.log('saveReportingTemplate User :: Error  ', error);

          this.savingReportingTemplate = false;
        }
      );
  }

  preLoadReportTemplates(initFlag: boolean = false) {
    this.reportingService
      .listReportingTemplates(JSON.stringify({ active: true }), null)
      .pipe(take(1))
      .subscribe(
        (result) => {
          // console.log('getReportingCounts User :: result ', result);

          this.reportTemplates = JSON.parse(result.data.listReportingTemplates);
          if (initFlag) {
            this.userService
              .readUserSettingKey('REPORTING_THEME')
              .pipe(take(1))
              .subscribe(
                (result) => {
                  // Only change default if setting found
                  if (result.data.readUserSettingKey?.settingValue)
                    this.reportingTheme = result.data.readUserSettingKey.settingValue;

                  // Dont kick off loading of templates until settings checked
                  this.loadDefaultTemplate();
                },
                (error) => {
                  console.log('AStageComponent: There was an error reading key - using default theme ', error);

                  // Dont kick off loading of templates until settings checked
                  this.loadDefaultTemplate();
                }
              );
          }
        },
        (error) => {
          console.log('loadReportTemplates User :: Error  ', error);
        }
      );
  }

  onChooseReportTemplate() {
    this.openReportTemplatePickerDialog().subscribe(
      (openReportTemplatePickerResult: any) => {
        // console.log(
        //   'openReportTemplatePickerDialog ',
        //   openReportTemplatePickerResult.data.reportTemplate,
        //   openReportTemplatePickerResult
        // );

        if (openReportTemplatePickerResult.data.reportTemplate) {
          // Full deep copy
          let copyTemplateData = JSON.parse(JSON.stringify(openReportTemplatePickerResult.data.reportTemplate));

          this.reportTemplate = copyTemplateData.template;

          // Load date range

          // Load selectedUserIds
          if (copyTemplateData.template.reportTemplateSettings.selectedUserIds) {
            this.selectedUserIds = copyTemplateData.template.reportTemplateSettings.selectedUserIds;
            this.selectedUsers = copyTemplateData.template.reportTemplateSettings.selectedUsers;
          } else {
            // this.selectedUserIds = [];
          }

          // Load selectedReqIds
          if (copyTemplateData.template.reportTemplateSettings.selectedReqIds) {
            this.selectedReqIds = copyTemplateData.template.reportTemplateSettings.selectedReqIds;
            this.selectedReqs = copyTemplateData.template.reportTemplateSettings.selectedReqs;
            this.getReqFiltersUsers(this.selectedReqIds);
          } else {
            // this.selectedReqIds = [];
          }

          // console.log('openReportTemplatePickerDialog after copy', this.reportTemplate);

          // Add to user settings storage for recent history
          let templateHistoryObj = {
            id: this.reportTemplate.id,
            title: this.reportTemplate.title,
            desc: this.reportTemplate.desc,
          };
          // Add to top of history array
          this.templateHistory.unshift(templateHistoryObj);

          // Remove duplicate if in list already - only show once at top
          const result: ReportTemplateHistory[] = [];
          const map = new Map();
          for (const item of this.templateHistory) {
            if (!map.has(item.id)) {
              map.set(item.id, true); // set any value to Map
              result.push({
                id: item.id,
                title: item.title,
                desc: item.desc,
              });
            }
          }

          // Store last ten items in array
          this.templateHistory = result.slice(0, 10);
          this.userService
            .setUserSettingKey('TEMPLATE_HISTORY', JSON.stringify(this.templateHistory))
            .pipe(take(1))
            .subscribe(
              (result) => {},
              (error) => {
                console.log('template history: There was an error setting key ', error);
              }
            );

          // Are you sure? Before overwrite?
        } else if (openReportTemplatePickerResult.data.clear) {
          this.reportTemplate.reportObjects = [];
        } else {
        }
      },

      (error) => {
        console.log('AStageComponent - openReportTemplatePickerDialog error: ', error);
      }
    );
  }

  openReportTemplatePickerDialog(): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      maxWidth: '1000px',
      width: '100%',
      maxHeight: '80vh',
      autoFocus: false,
      panelClass: 'add-report-object-dialog',
    };

    dialogConfig.data = { reportTemplates: this.reportTemplates };

    const dialogRef = this.reportPickerDialog.open<ReportTemplatePickerDialogComponent, MatDialogConfig, boolean>(
      ReportTemplatePickerDialogComponent,
      dialogConfig
    );

    return dialogRef.afterClosed();
  }

  changeReportingTheme(event: Event, reportingTheme: reportingThemes) {
    if (reportingTheme) {
      this.reportingTheme = reportingTheme;

      this.userService
        .setUserSettingKey('REPORTING_THEME', this.reportingTheme)
        .pipe(take(1))
        .subscribe(
          (result) => {},
          (error) => {
            console.log('changeReportingTheme: There was an error setting key ', error);
          }
        );
    }
  }

  // Look for default - if not found look for historical - if not found then just load blank
  loadDefaultTemplate() {
    let defaultTemplate: ReportTemplateHistory = {
      id: '0',
      title: '',
      desc: '',
    };

    this.userService
      .readUserSettingKey('TEMPLATE_DEFAULT')
      .pipe(take(1))
      .subscribe(
        (result) => {
          // Only change default if setting found
          if (result.data.readUserSettingKey?.settingValue)
            defaultTemplate = JSON.parse(result.data.readUserSettingKey.settingValue);

          this.loadTemplateHistory(defaultTemplate);

          console.log('from loadDefaultTemplate: defaultTemplate', defaultTemplate);
        },
        (error) => {
          console.log('template default: There was an error reading setting key ', error);

          this.loadTemplateHistory(defaultTemplate);
        }
      );

    // console.log('this.reportTemplate.reportObjects  ', this.reportTemplate.reportObjects);
  }

  loadTemplateHistory(defaultTemplate: ReportTemplateHistory) {
    // Read user settings for recent history no matter what - used for menu
    this.userService
      .readUserSettingKey('TEMPLATE_HISTORY')
      .pipe(take(1))
      .subscribe(
        (result) => {
          // Only change default if setting found
          if (result.data.readUserSettingKey?.settingValue)
            this.templateHistory = JSON.parse(result.data.readUserSettingKey.settingValue)
              ? JSON.parse(result.data.readUserSettingKey.settingValue)
              : [];

          let templateToLoad;

          // If the user clicked on link from shared report, go to that report
          if (this.reportTemplateId) {
            templateToLoad = this.reportTemplates.filter((item) => this.reportTemplateId === item.id);
          } else {
            // Find item by id in list of templates
            templateToLoad = this.reportTemplates.filter((item) => defaultTemplate.id === item.id);
          }

          if (templateToLoad.length) {
            // console.log("loadDefaultTemplate this.TEMPLATE_DEFAULT", this.templateHistory);

            // Full deep copy
            let copyTemplateData = JSON.parse(JSON.stringify(templateToLoad[0]));

            // Load selectedUserIds
            if (copyTemplateData.template.reportTemplateSettings.selectedUserIds) {
              this.selectedUserIds = copyTemplateData.template.reportTemplateSettings.selectedUserIds;
              this.selectedUsers = copyTemplateData.template.reportTemplateSettings.selectedUsers;
            } else {
              // this.selectedUserIds = [];
            }

            // Load selectedReqIds
            if (copyTemplateData.template.reportTemplateSettings.selectedReqIds) {
              this.selectedReqIds = copyTemplateData.template.reportTemplateSettings.selectedReqIds;
              this.selectedReqs = copyTemplateData.template.reportTemplateSettings.selectedReqs;
              this.getReqFiltersUsers(this.selectedReqIds);
            } else {
              // this.selectedReqIds = [];
            }

            this.reportTemplate = copyTemplateData.template;

            console.log('from loadTemplateHistory (defaultTemplate): this.reportTemplate', this.reportTemplate);
          } else {
            // Search history next
            if (this.templateHistory.length > 0) {
              const recent = this.templateHistory[0];

              // If the user clicked on link from shared report, go to that report
              if (this.reportTemplateId) {
                templateToLoad = this.reportTemplates.filter((item) => this.reportTemplateId === item.id);
              } else {
                // Find item by id in list of templates
                templateToLoad = this.reportTemplates.filter((item) => recent.id === item.id);
              }

              if (templateToLoad.length) {
                // console.log("loadDefaultTemplate this.templateHistory", this.templateHistory);

                // Full deep copy
                let copyTemplateData = JSON.parse(JSON.stringify(templateToLoad[0]));

                // Load selectedUserIds
                if (copyTemplateData.template.reportTemplateSettings.selectedUserIds) {
                  this.selectedUserIds = copyTemplateData.template.reportTemplateSettings.selectedUserIds;
                  this.selectedUsers = copyTemplateData.template.reportTemplateSettings.selectedUsers;
                } else {
                  // this.selectedUserIds = [];
                }

                // Load selectedReqIds
                if (copyTemplateData.template.reportTemplateSettings.selectedReqIds) {
                  this.selectedReqIds = copyTemplateData.template.reportTemplateSettings.selectedReqIds;
                  this.selectedReqs = copyTemplateData.template.reportTemplateSettings.selectedReqs;
                  this.getReqFiltersUsers(this.selectedReqIds);
                } else {
                  // this.selectedReqIds = [];
                }

                this.reportTemplate = copyTemplateData.template;

                // update url
                let url = `reporting/analytics/${this.reportTemplate.id}`;
                this.location.replaceState(url);
              }
            }
          }

          // Let UI know stage is loaded
          this.stageLoading = false;
        },
        (error) => {
          console.log('Template History: There was an error reading key - using default theme ', error);

          // Let UI know stage is loaded
          this.stageLoading = false;
        }
      );
  }

  onNewReportObjectSettings(reportObj) {
    this.openReportObjectSettingsDialog(reportObj).subscribe(
      (openReportObjectSettingsResult: any) => {
        // console.log('openUsersPickerDialog ', openReportObjectSettingsResult);

        if (openReportObjectSettingsResult?.data.objRef) {
          // Change selectedUsers
          // console.log(
          //   'Added Filtered selectedUsers openReportObjectSettingsResult.data.selectedUsers',
          //   openReportObjectSettingsResult
          // );

          var objRef = JSON.parse(JSON.stringify(openReportObjectSettingsResult.data.objRef));

          this.onClickAdd(objRef);

          // console.log('Added Filtered selectedUsers this.selectedUsers', this.selectedUsers);
          // console.log('Added Filtered selectedUsers this.selectedUserIds', this.selectedUserIds);
        } else if (openReportObjectSettingsResult?.data.clear) {
          // Do nothing
        } else {
        }
      },

      (error) => {
        console.log('AStageComponent - openUsersPickerDialog error: ', error);
      }
    );
  }

  openReportObjectSettingsDialog(reportObj): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      maxWidth: '1000px',
      width: '100%',
      // maxHeight: '80vh',
      autoFocus: false,
      panelClass: 'add-object-dialog',
    };

    // console.log('Settings ', this);
    // JSON.parse(JSON.stringify(this));

    var copyObj: any = JSON.parse(JSON.stringify(reportObj));

    copyObj.contactIds = JSON.parse(JSON.stringify(reportObj.contactIds ? reportObj.contactIds : []));
    copyObj.reqIds = JSON.parse(JSON.stringify(reportObj.reqIds ? reportObj.reqIds : []));
    copyObj.reqIds = reportObj.reqIds;
    copyObj.startDate = this.startDate;
    copyObj.endDate = this.endDate;
    copyObj.userIds = JSON.parse(JSON.stringify(reportObj.userIds ? reportObj.userIds : []));
    copyObj.esFilters = JSON.parse(JSON.stringify(reportObj.esFilters ? reportObj.esFilters : []));
    copyObj.useStoredData = reportObj.useStoredData;
    copyObj.reqs = JSON.parse(JSON.stringify(this.reqs ? this.reqs : []));
    copyObj.tenants = JSON.parse(JSON.stringify(this.tenants ? this.tenants : []));
    copyObj.reportingTheme = this.reportingTheme;
    copyObj.rType = reportObj.rType;
    copyObj.preview = true;
    copyObj.data = JSON.parse(JSON.stringify(reportObj.data ? reportObj.data : {}));

    // console.log(copyObj);

    dialogConfig.data = {
      target: `${copyObj.data.name}`,
      objRef: copyObj,
      reportingTheme: this.reportingTheme,
      isAdd: true,
      contactTags: this.contactTags,
      sequenceItems: this.sequenceItems,
    };

    const dialogRef = this.reportPickerDialog.open<ReportObjectSettingsDialogComponent, MatDialogConfig, boolean>(
      ReportObjectSettingsDialogComponent,
      dialogConfig
    );

    return dialogRef.afterClosed();
  }

  onModelChange(event: Event) {
    // Auto Save
  }

  refreshTemplate() {
    this.reportTemplate.reportObjects = JSON.parse(JSON.stringify(this.reportTemplate.reportObjects));
  }

  loadRecent(event: Event, recent: ReportTemplateHistory) {
    // Load selection from master list of templates

    // Find item by id in list of templates
    let templateToLoad = this.reportTemplates.filter((item) => recent.id === item.id);

    if (templateToLoad.length) {
      // Full deep copy
      let copyTemplateData = JSON.parse(JSON.stringify(templateToLoad[0]));

      // Load selectedUserIds
      if (copyTemplateData.template.reportTemplateSettings.selectedUserIds) {
        this.selectedUserIds = copyTemplateData.template.reportTemplateSettings.selectedUserIds;
        this.selectedUsers = copyTemplateData.template.reportTemplateSettings.selectedUsers;
      } else {
        // this.selectedUserIds = [];
      }

      // Load selectedReqIds
      if (copyTemplateData.template.reportTemplateSettings.selectedReqIds) {
        this.selectedReqIds = copyTemplateData.template.reportTemplateSettings.selectedReqIds;
        this.selectedReqs = copyTemplateData.template.reportTemplateSettings.selectedReqs;
        this.getReqFiltersUsers(this.selectedReqIds);
      } else {
        // this.selectedReqIds = [];
      }

      this.reportTemplate = copyTemplateData.template;

      // console.log('openReportTemplatePickerDialog after copy', this.reportTemplate);

      // Add to local storage for recent history
      let templateHistoryObj = {
        id: this.reportTemplate.id,
        title: this.reportTemplate.title,
        desc: this.reportTemplate.desc,
      };
      // Add to top of history array
      this.templateHistory.unshift(templateHistoryObj);

      // Remove duplicate if in list already - only show once at top
      const result: ReportTemplateHistory[] = [];
      const map = new Map();
      for (const item of this.templateHistory) {
        if (!map.has(item.id)) {
          map.set(item.id, true); // set any value to Map
          result.push({
            id: item.id,
            title: item.title,
            desc: item.desc,
          });
        }
      }

      // Store last ten items in array
      this.templateHistory = result.slice(0, 10);
      this.userService
        .setUserSettingKey('TEMPLATE_HISTORY', JSON.stringify(this.templateHistory))
        .pipe(take(1))
        .subscribe(
          (result) => {},
          (error) => {
            console.log('template history: There was an error setting key ', error);
          }
        );
    }
  }

  toggleLinkShare() {
    // About to cancel link share mode
    if (this.linkShareMode) {
      this.stageLoading = true;
      this.options.draggable.enabled = true;
      this.options.resizable.enabled = true;
      this.options.api.optionsChanged();

      this.preLoadReqs();
      this.preLoadTenants();
      this.preLoadReportTemplates(true);
    }

    this.linkShareMode = !this.linkShareMode;
  }

  shareLink() {
    // Temp for debug
    // this.linkShareMode = !this.linkShareMode;

    // Save dat to S3
    // Generate a link and display it....

    let reportTemplateBuff = JSON.parse(JSON.stringify(this.reportTemplate));

    // ****Todo Add option to keep or ignore various values on save
    reportTemplateBuff.reportTemplateSettings.startDate = this.startDate;
    reportTemplateBuff.reportTemplateSettings.endDate = this.endDate;
    reportTemplateBuff.reportTemplateSettings.selectedUserIds = this.selectedUserIds;
    reportTemplateBuff.reportTemplateSettings.selectedUsers = this.selectedUsers;
    reportTemplateBuff.reportTemplateSettings.selectedReqIds = this.selectedReqIds;
    reportTemplateBuff.reportTemplateSettings.selectedReqs = this.selectedReqs;

    console.log('shareLink this.reportTemplateBuff', reportTemplateBuff);

    this.reportingService.createReportingSnapshot(reportTemplateBuff).subscribe((reportingSnapshot) => {
      console.log(reportingSnapshot);

      this.snackBarRef = this.snackBar.openFromComponent(ReportLinkShareComponent, {
        data: {
          path: reportingSnapshot.sharedWithPublic.path,
        },
        horizontalPosition: 'end',
        verticalPosition: 'top',
        panelClass: ['blue-snackbar'],
      });
    });
  }

  setupUserFilterOption() {
    if (
      this.currentUserInfo.groups.includes('mmAdmin') ||
      this.currentUserInfo.groups.includes('tenantAdmin') ||
      this.currentUserInfo.groups.includes('tenantLeader')
    ) {
      this.filterUsersOption = true;
    }
  }

  getReqFiltersUsers(reqIds) {
    this.reportingService
      .getReqFiltersUsers(reqIds)
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.reqFiltersUsersGlobal = result.data.getReqFiltersUsers;
        },
        (error) => {
          console.log('getReqFiltersUsers : Error fetching requisition reqUsers : ', error);
        }
      );
  }

  // This is only here for example training use
  myClickLocal(event: Event) {
    console.log(event);
  }

  objCloned(event: any, index: number) {
    // Deep copy all new
    let RO = JSON.parse(JSON.stringify(this.reportTemplate.reportObjects[index]));
    RO.id = uuid;

    this.reportTemplate.reportObjects.push(RO);
  }

  loadContactTagsList() {
    this.contactService
      .listContactTags(null, '{}', null)
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log('listContactTags result', result);

          const clone = JSON.parse(JSON.stringify(result.data.listContactTags));

          clone.sort((a, b) => (a.order > b.order ? 1 : -1));

          this.contactTags = clone;

          this.contactService
            .listContactTags(null, '{}', 'req')
            .pipe(take(1))
            .subscribe(
              (resultReq) => {
                console.log(' listContactTags resultReq', resultReq);

                const clone = JSON.parse(JSON.stringify(resultReq.data.listContactTags));

                this.contactReqTags = clone;

                // Append all to main tag list

                clone.forEach((ele) => {
                  if (this.contactTags.findIndex((arr) => arr.name === ele.name) < 0) {
                    this.contactTags.push(ele);
                  }
                });

                this.contactTags.sort((a, b) => (a.name > b.name ? 1 : -1));
              },
              (error) => {
                console.log('There was an error loading contactTags ', error);
              }
            );
        },
        (error) => {
          console.log('There was an error loading contactTags ', error);
        }
      );
  }

  loadSequences() {
    this.smsServiceNeo
      .listSequences(JSON.stringify({ active: true }), '')
      .then((result: any) => {
        this.sequenceItems = JSON.parse(result.data.listSequences);
      })
      .catch((error) => {});
  }

  shareReport() {
    let dialogRef = this.dialog.open(ReportSharePickerDialogComponent, {
      panelClass: 'report-share-picker-dialog',
      data: {
        reportTemplate: this.reportTemplate,
        tenantUsers: this.tenantUsers,
        currentUser: this.currentUser,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && !result.error && result != 'cancelled') {
        if (this.snackBarRef) {
          this.snackBarRef.dismiss();
          this.snackBarRef = null;
        }

        this.snackBarRef = this.snackBar.open('Report Shared Successfully ...', '', {
          duration: 2000,
        });
      }
    });
  }

  ngOnDestroy() {}
}
