import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { PlaybookService } from '../../../services/playbook.service';
import {
  PlaybookTemplate,
  PlaybookTask,
  PlaybookCategory,
  newTaskTemplate,
  PlaybooksReqContact,
} from 'app/shared/model/playbook';
import { ThemePalette } from '@angular/material/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'mm-playbook-cat-select-tabs',
  templateUrl: './cat-select-tabs.component.html',
  styleUrls: ['./cat-select-tabs.component.scss'],
})
export class PlaybookCatSelectTabsComponent implements OnInit {
  @Output() catChanged: EventEmitter<any> = new EventEmitter();
  @Input() reqId: string;
  @Input() contactId: string;
  @Input() playbookTemplateId: string;
  @Input() reqTenantId: string = '';
  @Input() userId: string;
  @Input() stage: string;

  // used to signal a change in a task - flipping the boolean in parent
  // causes ngOnChanges to fire on any children with this input
  // this.inpTaskChanged = !this.inpTaskChanged;
  @Input() inpTaskChanged: boolean;

  playbookReqContact: PlaybooksReqContact = null;
  playbookTemplate: PlaybookTemplate = null;
  background: ThemePalette = undefined;
  activeLink = 0;
  stageIndex: number = -1;
  dataLoaded: boolean = false;
  dataLinksLoaded: boolean = false;

  constructor(private playbookService: PlaybookService) {}

  ngOnInit() {
    this.dataLoaded = false;
    this.playbookService
      .readPlaybookTemplate(this.reqTenantId, this.playbookTemplateId)
      .pipe(take(1))
      .subscribe(
        (result) => {
          // console.log("PlaybookCategoriesEditorComponent: playbookTemplate ", result.data.readPlaybookTemplate);

          this.playbookTemplate = result.data.readPlaybookTemplate;

          // Let UI know data loading is complete
          this.dataLoaded = true;

          this.playbookService
            .readSinglePlaybookReqContact(this.reqTenantId, this.reqId, this.contactId, this.playbookTemplateId)
            .pipe(take(1))
            .subscribe(
              (result) => {
                // console.log("PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact result ", result.data);

                this.playbookReqContact = result.data.readSinglePlaybookReqContact;

                this.dataLinksLoaded = true;
              },
              (error) => {
                console.log('PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact error ', error);

                this.dataLinksLoaded = true;
              }
            );
        },
        (error) => {
          console.log('PlaybookCategoriesEditorComponent: There was an error loading playbookTemplate ', error);
          // Even though error still let ui data is finished loading
          this.dataLoaded = true;
        }
      );
  }

  ngOnDestroy() {
    // do NOT forget to unsubscribe from subscriptions
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.dataLoaded = false;
    // this.dataLinksLoaded = false;

    this.playbookService
      .readPlaybookTemplate(this.reqTenantId, this.playbookTemplateId)
      .pipe(take(1))
      .subscribe(
        (result) => {
          // console.log("PlaybookCategoriesEditorComponent: playbookTemplate ", result.data.readPlaybookTemplate);

          this.playbookTemplate = result.data.readPlaybookTemplate;

          // Let UI know data loading is complete
          this.dataLoaded = true;

          this.playbookService
            .readSinglePlaybookReqContact(this.reqTenantId, this.reqId, this.contactId, this.playbookTemplateId)
            .pipe(take(1))
            .subscribe(
              (result) => {
                // console.log("PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact result ", result.data);

                this.playbookReqContact = result.data.readSinglePlaybookReqContact;

                this.dataLinksLoaded = true;
              },
              (error) => {
                console.log('PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact error ', error);

                this.dataLinksLoaded = true;
              }
            );
        },
        (error) => {
          console.log('PlaybookCategoriesEditorComponent: There was an error loading playbookTemplate ', error);
          // Even though error still let ui data is finished loading
          this.dataLoaded = true;
        }
      );
  }

  toggleBackground() {
    this.background = this.background ? undefined : 'primary';
  }

  onSelectedTabChange(tabChangeEvent: MatTabChangeEvent) {}

  onSelectedNavTabChange(event: any, catIndex: number) {
    // Only change this here - dont change in ngOnChanges trigger
    this.activeLink = catIndex;

    this.catChanged.emit({
      catIndex: catIndex,
      catId: this.playbookTemplate.categories[catIndex].id,
    });
  }

  onTaskChanged() {}

  getCountIncomplete(catIndex, catId): number {
    //if (this.playbookReqContact) {
    // Get editable Category matched to Template
    const currCat = this.playbookReqContact.playbookObj.categories.filter((cat) => cat.id === catId)[0];

    // Verify / Add Stage if not there
    this.stageIndex = currCat.stages.findIndex((stage) => stage.id === this.stage);

    // add filter logic here
    return currCat.stages[this.stageIndex]?.tasks.filter((item) => item.status !== 'complete').length;
    //}
  }
}
