import { Contact, ContactName } from './contacts';
import { User } from './user';
import { ActivityCriteria, Page, SortOption } from './filter';
import { Subscription } from 'rxjs';

export interface ActivitySection {
  type: string;
  title: string;
  emptyMessage: string;
  mainHeaderHidden?: boolean;
  activities: Activity[];
  pageTodo?: Page;
  todoFilters?: ActivityCriteria;
  loadMyTodosSub?: Subscription;
  loadAllTodosSub?: Subscription;
  sortOption: SortOption;
}

export interface Activity {
  id: string;
  type: ActivityType;
  customType?: string;
  iconColor?: string;
  title?: string;
  description?: string;
  stageChange?: StageChange;
  meetingType?: string;
  users?: User[];
  contacts?: Contact[];
  completedBy?: ContactName;
  requisition?: string;
  reqId?: string;
  dueOn?: string;
  completedOn?: Date;
  disposition?: string;
  recurring?: RecurringTodo;
}

export interface History {
  id: string;
  type: ActivityType;
  iconColor?: string;
  title?: string;
  description?: string;
  users?: User[];
  contacts?: Contact[];
  completedBy?: ContactName;
  requisition?: string;
  reqId?: string;
  dueOn?: string;
  completedDate?: Date;
  isPublic?: boolean;
  disposition?: string;
  mentionChecked?: boolean;
  userId?: string;
  noteId?: string;
  previousEdit?: string;
}

export interface HistoryResponse {
  histories?: History[];
  filters?: ActivityCriteria;
  page?: Page;
}

export interface ActivityResponse {
  todos?: Activity[];
  filters?: ActivityCriteria;
  page?: Page;
  count: number;
}

export enum ActivityType {
  Note = 'NOTE',
  EditedNote = 'EDITEDNOTE',
  Add = 'ADD',
  Call = 'CALL',
  Stage = 'STAGE',
  Meeting = 'MEETING',
  Task = 'TASK',
  Other = 'OTHER',
  Created = 'CREATED',
  Updated = 'UPDATED',
  document = 'DOCUMENT',
  BombBomb = 'BOMBBOMB',
  Bonzo = 'BONZO',
  Email = 'EMAIL',
  Assigned = 'ASSIGNED',
  Mention = 'MENTION',
  AssignedPhoneNumber = 'PHONEASSIGNED',
  UnassignedPhoneNumber = 'PHONEUNASSIGNED',
  MMSMedia = 'MMSMEDIA',
  SMS = 'SMS',
  Pipeline = 'PIPELINE',
  TotalExpert = 'TOTALEXPERT',
  HubSpot = 'HUBSPOT',
}

export interface NoteInput {
  contactId?: string;
  reqId?: string;
  note: string;
  isPublic?: boolean;
}

export interface EditNoteInput {
  contactId?: string;
  reqId?: string;
  note: string;
  historyId?: string;
  isPublic?: boolean;
}

export interface HistoryInput {
  type?: string;
  contactId?: string;
  userId?: string;
  reqId?: string;
  note: string;
  isPublic?: boolean;
  analytics?: string;
}

export const ActivityTypeOption = [
  { title: '- None -', value: '' },
  { title: 'Task', value: ActivityType.Task },
  { title: 'Call', value: ActivityType.Call },
  { title: 'Other', value: ActivityType.Other },
  { title: 'Meeting', value: ActivityType.Meeting },
  { title: 'Updated', value: ActivityType.Updated },
];

export const ActivityDurationOption = [
  { title: '30 Minutes', value: 30 },
  { title: '1 Hour', value: 60 },
  { title: '1 Hour 30 Minutes', value: 90 },
  { title: '2 Hours', value: 120 },
  { title: '2 Hours 30 Minutes', value: 150 },
  { title: '3 Hours', value: 180 },
];

export const ActivityTimeOption = [
  { title: '12:00am', value: '00:00' },
  { title: '12:30am', value: '00:30' },
  { title: '01:00am', value: '01:00' },
  { title: '01:30am', value: '01:30' },
  { title: '02:00am', value: '02:00' },
  { title: '02:30am', value: '02:30' },
  { title: '03:00am', value: '03:00' },
  { title: '03:30am', value: '03:30' },
  { title: '04:00am', value: '04:00' },
  { title: '04:30am', value: '04:30' },
  { title: '05:00am', value: '05:00' },
  { title: '05:30am', value: '05:30' },
  { title: '06:00am', value: '06:00' },
  { title: '06:30am', value: '06:30' },
  { title: '07:00am', value: '07:00' },
  { title: '07:30am', value: '07:30' },
  { title: '08:00am', value: '08:00' },
  { title: '08:30am', value: '08:30' },
  { title: '09:00am', value: '09:00' },
  { title: '09:30am', value: '09:30' },
  { title: '10:00am', value: '10:00' },
  { title: '10:30am', value: '10:30' },
  { title: '11:00am', value: '11:00' },
  { title: '11:30am', value: '11:30' },
  { title: '12:00pm', value: '12:00' },
  { title: '12:30pm', value: '12:30' },
  { title: '01:00pm', value: '13:00' },
  { title: '01:30pm', value: '13:30' },
  { title: '02:00pm', value: '14:00' },
  { title: '02:30pm', value: '14:30' },
  { title: '03:00pm', value: '15:00' },
  { title: '03:30pm', value: '15:30' },
  { title: '04:00pm', value: '16:00' },
  { title: '04:30pm', value: '16:30' },
  { title: '05:00pm', value: '17:00' },
  { title: '05:30pm', value: '17:30' },
  { title: '06:00pm', value: '18:00' },
  { title: '06:30pm', value: '18:30' },
  { title: '07:00pm', value: '19:00' },
  { title: '07:30pm', value: '19:30' },
  { title: '08:00pm', value: '20:00' },
  { title: '08:30pm', value: '20:30' },
  { title: '09:00pm', value: '21:00' },
  { title: '09:30pm', value: '21:30' },
  { title: '10:00pm', value: '22:00' },
  { title: '10:30pm', value: '22:30' },
  { title: '11:00pm', value: '23:00' },
  { title: '11:30pm', value: '23:30' },
];

interface StageChange {
  former: string;
  current: string;
}

interface RecurringTodo {
  frequency: string;
  customRecurringNum: number;
  customRecurringFrequency: string;
}

export interface Participant {
  name?: string;
}

export interface UpdateTodoInput {
  id: string;
  title?: string;
  description?: string;
  dueOn?: string;
  contacts?: Contact[];
  users?: User[];
  type?: string;
  location?: string;
  phone?: string;
  duration?: string;
  sendInvite?: boolean;
  recurring?: RecurringTodo;
}

export enum NotificationType {
  mention = 'Mention',
  assignment = 'Assignment',
  updates = 'Updates',
}
