/** @type {import('tailwindcss').Config} */
var plugin = require('tailwindcss/plugin');
var defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  mode: 'jit',
  separator: '_',
  content: ['./src/**/*.{html,ts,scss,css}'],
  darkMode: 'class', // or 'media' or 'class'
  safelist: [
    {
      // Better Toggle dynamic colors
      pattern: /(bg|text)-(red|gray|blue|indigo|slate)-(200|400|600)/,
    },
  ],
  theme: {
    fontFamily: {
      ...defaultTheme.fontFamily,
      sans: ['"Open Sans"', 'sans-serif'],
    },
    extend: {
      height: {
        108: '32rem',
      },
      transitionProperty: {
        height: 'height',
      },
      spacing: {
        1.75: '7.5px',
      },
      padding: {
        4.5: '18px',
        5.5: '22px',
        1.5: '6px',
        2.5: '10px',
      },
      animation: {
        mmPulse: 'mmPulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
      },
      keyframes: {
        mmPulse: {
          '0%, 100%': { opacity: 1 },
          '50%': { opacity: 0.5 },
        },
      },
    },
  },
  corePlugins: {
    aspectRation: false,
  },
  plugins: [
    plugin(function (p) {
      p.addVariant('popper-top', function (j) {
        j.modifySelectors(function (k) {
          return "[x-placement^='top'] ." + p.e('popper-top' + j.separator + k.className);
        });
      });
    }),
    plugin(function (p) {
      p.addVariant('popper-bottom', function (j) {
        j.modifySelectors(function (k) {
          return "[x-placement^='bottom'] ." + p.e('popper-bottom' + j.separator + k.className);
        });
      });
    }),
    plugin(function (p) {
      p.addVariant('popper-left', function (j) {
        j.modifySelectors(function (k) {
          return "[x-placement^='left'] ." + p.e('popper-left' + j.separator + k.className);
        });
      });
    }),
    plugin(function (p) {
      p.addVariant('popper-right', function (j) {
        j.modifySelectors(function (k) {
          return "[x-placement^='right'] ." + p.e('popper-right' + j.separator + k.className);
        });
      });
    }),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/typography'),
  ],
};
