import { Injectable, OnDestroy, OnInit, inject } from '@angular/core';
import { Permissions, PermissionsService } from './permissions.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { take } from 'rxjs/operators';
import { getUserDefaultRoutePreferenceQuery } from 'app/graphql/queries';
import { setUserDefaultRoutePreferenceMutation } from 'app/graphql/mutations';
import { User } from 'app/shared/model/user';

export type RoutePreference = {
  id: string;
  name: string;
  route: string;
  condition?: (permissions: Permissions) => boolean;
};

export const routePreferenceOptions: { [keyof: string]: RoutePreference } = {
  default: {
    id: 'default',
    name: 'Pipelines',
    route: '/pipelines',
    condition: (permissions) => permissions.cms.enabled,
  },
  activity: {
    id: 'activity',
    name: 'Activity',
    route: '/activity',
    condition: (permissions) => permissions.cms.todos,
  },
  marketInsights: {
    id: 'marketInsights',
    name: 'Market Insights',
    route: '/marketinsights',
    condition: (permissions) => permissions.market_insights.enabled,
  },
  pulse: {
    id: 'pulse',
    name: 'Pulse',
    route: '/pulse-ai',
    condition: (permissions) => permissions.pulse.enabled,
  },
  compass: {
    id: 'compass',
    name: 'Compass Alerts',
    route: '/settings/compass',
    condition: (permissions) => permissions.compass.enabled,
  },
  // compliance: {
  //   id: 'compliance',
  //   name: 'Compliance',
  //   route: '/settings/compliance',
  //   condition: (permissions) => permissions.compass.enabled,
  // },
  profile: {
    id: 'profile',
    name: 'Your Profile',
    route: '/settings/profile',
  },
  settings: {
    id: 'settings',
    name: 'Settings',
    route: '/settings',
  },
};

@Injectable()
export class NavigationService implements OnDestroy {
  private _userRoutePreference: BehaviorSubject<RoutePreference> = new BehaviorSubject(
    routePreferenceOptions['default']
  );
  private permissionsSubscription: Subscription;
  private userInfoSubscription: Subscription;

  constructor(
    private client: Apollo,
    private permissionsService: PermissionsService
  ) {}

  /**Initialize this service to be able to calculate the user's default route on the fly. */
  async initialize(permissions?: Permissions) {
    console.log('🧭 Navigation service initialized.');
    let preferredUserRoute = await this.calculateUserRoutePreference(permissions);
    // console.log('🪵 Setting preferredUserRoute to ', preferredUserRoute);
    this._userRoutePreference.next(preferredUserRoute);

    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
    this.permissionsSubscription = this.permissionsService.permissions$.subscribe(async (permissions) => {
      let preferredUserRoute = await this.calculateUserRoutePreference(permissions);
      this._userRoutePreference.next(preferredUserRoute);
    });
  }

  ngOnDestroy(): void {
    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }

    if (this.userInfoSubscription) {
      this.userInfoSubscription.unsubscribe();
    }
  }

  public getDefaultRoute(): string {
    let defaultRoute = this._userRoutePreference.getValue().route;
    return defaultRoute;
  }

  /**Combine user preferences and permissions to get the default route a user should have. */
  async calculateUserRoutePreference(
    permissions: Permissions = this.permissionsService.getCurrentPermissions()
  ): Promise<RoutePreference> {
    // console.trace('🪵 Calculating User Route Preference', userInfo, permissions);
    // Set up the base case for a default route.
    let userDefaultRoute = new BehaviorSubject(routePreferenceOptions['default']);

    // Core routing
    if (permissions.cms.enabled === false) {
      userDefaultRoute.next(routePreferenceOptions['profile']);
    }

    // Now that we have the permissions filter, let's see if the user has a preference.
    let userPreferenceResponse = await this.getUserRoutePreference().pipe(take(1)).toPromise();
    let userPreferenceId = userPreferenceResponse.data.getUserDefaultRoutePreference;

    if (userPreferenceId) {
      // Get route from options
      let foundRouteOption = routePreferenceOptions[userPreferenceId];

      // Handle error case
      if (foundRouteOption === undefined) {
        // No route option found in list
        console.warn('User has a preferred route selected but it was not found in options.', userPreferenceId);
      } else {
        if (foundRouteOption.condition) {
          if (foundRouteOption.condition(permissions)) {
            // Permissions condition passed.
            userDefaultRoute.next(foundRouteOption);
          }
        } else {
          // No condition to check, just use it.
          userDefaultRoute.next(foundRouteOption);
        }
      }
    }

    let finalValue = userDefaultRoute.getValue();
    userDefaultRoute.complete();

    return finalValue;
  }

  // Kind of working on getting the whole route preference object.
  findUserRoutePreference(): RoutePreference | undefined {
    return Object.values(routePreferenceOptions).find(
      (option) => option.id === this._userRoutePreference.getValue().id
    );
  }

  getUserDefaultRoutePreference(): RoutePreference {
    return this._userRoutePreference.getValue();
  }

  /** Get the id of the route preference the user has. */
  getUserRoutePreference() {
    return this.client.query<{ getUserDefaultRoutePreference: string | null }>({
      query: gql(getUserDefaultRoutePreferenceQuery),
      fetchPolicy: 'network-only',
    });
  }

  setUserRoutePreference(value: string) {
    return this.client.mutate({
      mutation: gql(setUserDefaultRoutePreferenceMutation),
      variables: {
        value,
      },
      fetchPolicy: 'network-only',
    });
  }

  public selectUserRoutePreference(route: string) {
    this.setUserRoutePreference(route)
      .pipe(take(1))
      .subscribe((_) => {
        this._userRoutePreference.next(routePreferenceOptions[route]);
      });
  }
}
