import { Component, Inject, OnInit, ElementRef, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { debounceTime, map, startWith, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { Option, Page } from '../model/filter';
import { User } from '../model/user';
import {
  ActivityDurationOption,
  ActivityTimeOption,
  ActivityType,
  ActivityTypeOption,
  Participant,
} from '../model/activity';
import { Tenant } from '../model/tenant';
import { TodoService } from '../../services/todo.service';
import { UserService } from '../../services/user.service';
import { Contact, UserContact, ReqContact } from '../model/contacts';
import { ContactService } from '../../services/contact.service';
import { TenantService } from '../../services/tenant.service';
import { RequisitionService } from '../../services/requisition.service';
import { ImageCachingService } from '../../services/imagecaching.service';
import { UserReq, Requisition } from '../model/requisitions';
import { filterMyContactsForSelect, filterReqContactsForSelect } from 'app/graphql/queries';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import { OutlookService } from 'app/services/outlook.service';
import moment from 'moment';
import { GoogleService } from 'app/services/google.service';
import { PermissionsService } from 'app/services/permissions.service';

@Component({
  selector: 'mm-new-activity-dialog',
  templateUrl: './new-activity-dialog.component.html',
  styleUrls: ['./new-activity-dialog.component.scss'],
})
export class NewActivityDialogComponent implements OnInit, OnDestroy {
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  readonly: boolean;
  type: ActivityType;
  customType: string;
  title: string;
  description: string;
  dueDate: Date;
  participants: Participant[];
  requisition: string;
  completedDate: Date;

  startDate = new Date();

  readonly typeOptions: Option[] = ActivityTypeOption;
  readonly durationOptions: Option[] = ActivityDurationOption;
  readonly timeOptions: Option[] = ActivityTimeOption;

  createActivityForm: FormGroup;
  typeFormControl = new FormControl('', Validators.required);
  titleFormControl = new FormControl('', Validators.required);
  descriptionFormControl = new FormControl('', Validators.required);
  tenantFormControl = new FormControl();
  reqFormControl = new FormControl();
  assigneesFormControl = new FormControl('', Validators.required);
  contactsFormControl = new FormControl();
  dueOnFormControl = new FormControl('', Validators.required);
  timeFormControl = new FormControl();
  durationFormControl = new FormControl();
  phoneFormControl = new FormControl();
  locationFormControl = new FormControl();
  sendInviteFormControl = new FormControl(false);

  tenants: Tenant[];
  filteredTenants: Observable<Tenant[]>;

  usersReqs: UserReq[];
  reqOptions: Option[] = [{ value: '', title: '' }];

  assignees: User[] = [];
  filteredAssignees: Observable<User[]>;
  allAssignees: User[] = [];

  contacts: UserContact[] = [];
  filteredContactsBatch = new BehaviorSubject<{ id: string; firstName: string; lastName: string }[]>([]);
  filteredContacts = this.filteredContactsBatch.asObservable();
  allContacts: UserContact[] & ReqContact[] = [];

  authenticatedUserInfo: any = null;
  userInfoSub: Subscription;
  routeSub: Subscription;
  currentUserInfo: any;
  currentUser: any;
  getMyReqsSub: Subscription;
  getMyTenantsSub: Subscription;
  contactFromSubscription: Subscription;

  userTenant: Tenant;
  reqId: string;
  contactId: string;
  currentContact: Contact;
  avatarUrls: any = {};
  filteredContactsInput: string;
  filteredContactsPageSize = 50;

  isOutlookAuthorized: boolean = false;
  isGoogleAuthorized: boolean = false;
  limitContactsList: boolean = null;
  userReqIds: string[] = null;

  @ViewChild('calendar') calendar: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialView: 'listDay',
    listDaySideFormat: {
      month: 'long',
      year: 'numeric',
      day: 'numeric',
    },
    height: 300,
    weekends: false,
    handleWindowResize: true,
    headerToolbar: false,
  };

  @ViewChild('assigneeInput', { static: false })
  assigneeInput: ElementRef<HTMLInputElement>;
  @ViewChild('contactInput', { static: false })
  contactInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoAssignee', { static: false })
  matAutocomplete: MatAutocomplete;
  @ViewChild('autoContact') associatedContactsAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger;

  private filterMyContactsSub: Subscription;
  private filterReqContactsSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<NewActivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private todoService: TodoService,
    private userService: UserService,
    private contactService: ContactService,
    private tenantService: TenantService,
    private route: ActivatedRoute,
    private requisitionService: RequisitionService,
    private imageCachingService: ImageCachingService,
    private outlookService: OutlookService,
    private googleService: GoogleService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    console.log('ngOnInit NewActivityDialogComponent data', this.data);

    let limit = this.permissionsService.getCurrentPermissions().cms.contacts.limit_contacts;
    if (limit === true) {
      this.requisitionService.getMyReqsForDropdown().subscribe((result) => {
        this.userReqIds = result.data.getMyReqs.map((r) => r.reqId);
      });
    }

    this.limitContactsList = limit;

    // grab value passed into dialog and use to pre-fill activity type formControl
    this.type = this.data.type;
    this.typeFormControl.setValue(this.data.type);

    if (this.data.contactId) {
      this.contactId = this.data.contactId;
    }

    if (this.data.reqId) {
      this.reqId = this.data.reqId;
    }

    this.routeSub = this.route.params.subscribe((params) => {
      console.log('params', params);

      // if from a req view query parameters should contain the reqId and contactId
      // Use passed in data values first if any
      if (!this.reqId) this.reqId = params.reqId;
      if (!this.contactId) this.contactId = params.contactId;

      console.log('init reqId contactId', this.reqId, this.contactId);

      this.contactId &&
        this.contactService
          .getContact(this.contactId)
          .valueChanges.pipe(take(1))
          .subscribe((data: any) => {
            console.log('init getContact', data);

            let contact = data.data.getContact;
            this.contacts = [
              {
                id: contact.id,
                firstName: contact.firstName,
                lastName: contact.lastName,
              },
            ];
          });
    });

    this.createActivityForm = this.formBuilder.group({
      type: this.typeFormControl,
      title: this.titleFormControl,
      description: this.descriptionFormControl,
      tenant: this.tenantFormControl,
      req: this.reqFormControl,
      assignees: this.assigneesFormControl,
      contacts: this.contactsFormControl,
      dueOn: this.dueOnFormControl,
      time: this.timeFormControl,
      duration: this.durationFormControl,
      phone: this.phoneFormControl,
      location: this.locationFormControl,
      sendInvite: this.sendInviteFormControl,
    });

    this.userInfoSub = this.userService.getAuthenticatedUserInfo().subscribe(
      (result) => {
        this.currentUserInfo = result;
      },
      (error) => {
        console.log('Error getting current user info.', error);
      }
    );

    // load data for selection lists
    this.getAuthenticatedUserInfo();
    this.getReqs();
    // this.getContacts();

    // enable type ahead chip selection elements
    this.filteredAssignees = this.assigneesFormControl.valueChanges.pipe(
      startWith(null),
      map((assignee: string | null) => (assignee ? this._filterAssignee(assignee) : this.allAssignees.slice()))
    );

    // We subscribe to the forms changes, and everytime there is a change,
    // go fetch 10 contacts who name starts with the value of the input
    // TODO: When you scroll down get 10 more contacts?
    this.contactFromSubscription = this.contactsFormControl.valueChanges
      .pipe(
        debounceTime(1000),
        map((input: string | null) => {
          this.filteredContactsInput = input;
          this.filteredContactsPageSize = 50;

          if (this.limitContactsList) {
            // console.log('🛠 userRedIds', this.userReqIds);
            this.requisitionService
              .filterReqContacts(
                this.userReqIds,
                { page: 1, pageSize: this.filteredContactsPageSize },
                {
                  name: input,
                },
                {},
                filterReqContactsForSelect
              )
              .valueChanges.pipe(
                take(1),
                map((result) => {
                  // console.log('🛠 filterReqs Result', result);
                  return result.data.filterReqContacts.contacts.map(({ contactId: id, firstName, lastName }) => {
                    return { id, firstName, lastName };
                  });
                })
              )
              .subscribe((result) => this.filteredContactsBatch.next(result));
          } else {
            if (this.reqId) {
              this.requisitionService
                .filterReqContacts(
                  [this.reqId],
                  { page: 1, pageSize: this.filteredContactsPageSize },
                  {
                    name: input,
                  },
                  {},
                  filterReqContactsForSelect
                )
                .valueChanges.pipe(
                  take(1),
                  map((result) => {
                    return result.data.filterReqContacts.contacts.map(({ contactId: id, firstName, lastName }) => {
                      return { id, firstName, lastName };
                    });
                  })
                )
                .subscribe((result) => this.filteredContactsBatch.next(result));
            } else {
              //TODO: Make this into a limited search

              if (typeof input === 'string') {
                this.contactService
                  .filterMyContacts(
                    { page: 1, pageSize: this.filteredContactsPageSize },
                    {
                      name: input,
                    },
                    {},
                    filterMyContactsForSelect
                  )
                  .valueChanges.pipe(
                    take(1),
                    map((result) => {
                      return result.data.filterMyContacts.contacts.map(({ contactId: id, firstName, lastName }) => {
                        return { id, firstName, lastName };
                      });
                    })
                  )
                  .subscribe((result) => this.filteredContactsBatch.next(result));
              }
            }
          }
        })
      )
      .subscribe(); // We have to subscribe here for the valueChanges to be fired

    // this.getIsOutlookAuthorized();
    // this.getIsGoogleAuthorized();
  }

  private getAuthenticatedUserInfo(): void {
    this.userService.getAuthenticatedUserInfo().subscribe(
      (userInfo: any) => {
        this.authenticatedUserInfo = userInfo;
        console.log('AddTodoComponent: authenticatedUserInfo ', userInfo);

        // now that we have the authenticatedUserInfo and know the user's group, make request for his tenants he can access
        this.getTenants();
      },
      (error) => {
        console.log('AddTodoComponent: Error getting authenticated user groups -> ' + error.message);
      }
    );
  }

  private getTenants(): void {
    // Within req on fresh load this subscription getting called twice - results in duplicate user
    // added .pipe(take(1))
    this.getMyTenantsSub = this.tenantService
      .getMyTenants()
      .pipe(take(1))
      .subscribe(
        (res) => {
          let result = structuredClone(res);
          console.log('AddTodoComponent: tenant data ', result.data.getMyTenants);

          result.data.getMyTenants.forEach((item) => {
            item.user = item.user.filter((user) => user['active'] == true);
          });

          this.tenants = result.data.getMyTenants;
          let assignedTenant = this.tenants.filter(
            (t) => t.id == this.authenticatedUserInfo.attributes['custom:tenantId']
          );
          if (assignedTenant && assignedTenant.length > 0) {
            this.allAssignees = [...assignedTenant[0].user].sort((userA, userB) => {
              return userA.firstName.localeCompare(userB.firstName);
            });
          }
          this.filteredTenants = this.tenantFormControl.valueChanges.pipe(
            startWith<string | Tenant>(''),
            map((value) => (typeof value === 'string' ? value : value.title)),
            map((name) => (name ? this._filterTenant(this.tenants, name) : this.tenants))
          );
          if (this.reqId) {
            this.addReqUsersToAllAssignees();
          }
          this.assignCurrentUser();
        },
        (error) => {
          console.log('AddTodoComponent: There was an error loading tenant data ', error);
        }
      );
  }

  // private getContacts(): void {
  //   this.allContacts = [];
  //   const page: Page = { page: 1, pageSize: 10 };

  //   if (this.reqId) {
  //     const filterReqContactsQueryRef = this.requisitionService.filterReqContacts(
  //       [this.reqId],
  //       page,
  //       {},
  //       {},
  //       filterReqContactsForSelect
  //     );
  //     this.filterReqContactsSub = filterReqContactsQueryRef.valueChanges.subscribe(
  //       (result) => {
  //         // console.log(result.data);
  //         // console.log('AddTodoComponent: req contacts ', result.data.filterReqContacts.contacts);
  //         this.allContacts = result.data.filterReqContacts.contacts.map(({ contactId: id, firstName, lastName }) => ({
  //           id,
  //           firstName,
  //           lastName,
  //         }));
  //         this.contacts = this.allContacts.filter((contact) => contact.id == this.contactId);
  //         const pageReturned = result.data.filterReqContacts.page;
  //         if (pageReturned.page * pageReturned.pageSize < pageReturned.totalSize) {
  //           filterReqContactsQueryRef.fetchMore({
  //             variables: { page: { page: pageReturned.page + 1, pageSize: pageReturned.pageSize } },
  //             updateQuery: (prev, { fetchMoreResult }) => {
  //               if (!fetchMoreResult) return prev;
  //               return {
  //                 filterReqContacts: {
  //                   contacts: [...prev.filterReqContacts.contacts, ...fetchMoreResult.filterReqContacts.contacts],
  //                   page: fetchMoreResult.filterReqContacts.page,
  //                   __typename: prev.filterReqContacts.__typename,
  //                 },
  //               };
  //             },
  //           });
  //         }
  //       },
  //       (error) => {
  //         console.log('AddTodoComponent: There was an error loading contacts ', error);
  //       }
  //     );
  //   } else {
  //     const filterMyContactsQueryRef = this.contactService.filterMyContacts(page, {}, {}, filterMyContactsForSelect);
  //     this.filterMyContactsSub = filterMyContactsQueryRef.valueChanges.subscribe(
  //       (result) => {
  //         console.log(result.data);
  //         console.log('AddTodoComponent: contacts ', result.data.filterMyContacts.contacts);
  //         this.allContacts = result.data.filterMyContacts.contacts.map(({ contactId: id, firstName, lastName }) => ({
  //           id,
  //           firstName,
  //           lastName,
  //         }));
  //         this.contacts = this.allContacts.filter((contact) => contact.id === this.contactId);
  //         const pageReturned = result.data.filterMyContacts.page;
  //         if (pageReturned.page * pageReturned.pageSize < pageReturned.totalSize) {
  //           filterMyContactsQueryRef.fetchMore({
  //             variables: { page: { page: pageReturned.page + 1, pageSize: pageReturned.pageSize } },
  //             updateQuery: (prev, { fetchMoreResult }) => {
  //               if (!fetchMoreResult) return prev;
  //               return {
  //                 filterMyContacts: {
  //                   contacts: [...prev.filterMyContacts.contacts, ...fetchMoreResult.filterMyContacts.contacts],
  //                   page: fetchMoreResult.filterMyContacts.page,
  //                   __typename: prev.filterMyContacts.__typename,
  //                 },
  //               };
  //             },
  //           });
  //         }
  //       },
  //       (error) => {
  //         console.log('AddTodoComponent: There was an error loading contacts ', error);
  //       }
  //     );
  //   }
  // }

  autoCompleteScroll() {
    setTimeout(() => {
      if (
        this.associatedContactsAutocomplete &&
        this.autocompleteTrigger &&
        this.associatedContactsAutocomplete.panel
      ) {
        fromEvent(this.associatedContactsAutocomplete.panel.nativeElement, 'scroll')
          .pipe(
            map((x) => this.associatedContactsAutocomplete.panel.nativeElement.scrollTop),
            takeUntil(this.autocompleteTrigger.panelClosingActions)
          )
          .subscribe((x) => {
            const scrollTop = this.associatedContactsAutocomplete.panel.nativeElement.scrollTop;
            const scrollHeight = this.associatedContactsAutocomplete.panel.nativeElement.scrollHeight;
            const elementHeight = this.associatedContactsAutocomplete.panel.nativeElement.clientHeight;
            const atBottom = scrollHeight === scrollTop + elementHeight;

            // If the scrollbar is at the top, atBottom will be true because all the scroll values = 0.
            // So we ignore changing anything if the scroll values are 0.
            if (atBottom && scrollTop != 0) {
              // fetch more data
              this.filteredContactsPageSize += 50;
              if (this.limitContactsList) {
                this.requisitionService
                  .getMyReqs()
                  .pipe(take(1))
                  .toPromise()
                  .then((reqs) => {
                    this.requisitionService
                      .filterReqContacts(
                        this.userReqIds,
                        { page: 1, pageSize: this.filteredContactsPageSize },
                        { name: this.filteredContactsInput },
                        {},
                        filterReqContactsForSelect
                      )
                      .valueChanges.pipe(
                        take(1),
                        map((result) => {
                          return result.data.filterReqContacts.contacts.map(
                            ({ contactId: id, firstName, lastName }) => {
                              return { id, firstName, lastName };
                            }
                          );
                        })
                      )
                      .subscribe((result) => this.filteredContactsBatch.next(result));
                  });
              } else {
                if (this.reqId) {
                  this.requisitionService
                    .filterReqContacts(
                      [this.reqId],
                      { page: 1, pageSize: this.filteredContactsPageSize },
                      { name: this.filteredContactsInput },
                      {},
                      filterReqContactsForSelect
                    )
                    .valueChanges.pipe(
                      take(1),
                      map((result) => {
                        return result.data.filterReqContacts.contacts.map(({ contactId: id, firstName, lastName }) => {
                          return { id, firstName, lastName };
                        });
                      })
                    )
                    .subscribe((result) => this.filteredContactsBatch.next(result));
                } else {
                  this.contactService
                    .filterMyContacts(
                      { page: 1, pageSize: this.filteredContactsPageSize },
                      { name: this.filteredContactsInput },
                      {},
                      filterMyContactsForSelect
                    )
                    .valueChanges.pipe(
                      take(1),
                      map((result) => {
                        return result.data.filterMyContacts.contacts.map(({ contactId: id, firstName, lastName }) => {
                          return { id, firstName, lastName };
                        });
                      })
                    )
                    .subscribe((result) => this.filteredContactsBatch.next(result));
                }
              }
            }
          });
      }
    });
  }

  private assignCurrentUser(): void {
    console.log('assignCurrentUser this.assignees', JSON.stringify(this.assignees));

    const currentUser = this.allAssignees.filter(
      (assignee) => assignee.id === this.authenticatedUserInfo.attributes['custom:userId']
    )[0];
    this.createActivityForm.controls['assignees'].setValue(currentUser);
    this.assignees.push(currentUser);
    this.currentUser = currentUser;
    let tenantId = this.authenticatedUserInfo.attributes['custom:tenantId'];
    if (this.reqId) {
      this.requisitionService.loadRequisition(this.reqId, true).subscribe((result) => {
        console.log('SingleRequisitionComponent: Current Req == ', result.data.getReq);
        let r = Object.assign({}, result.data.getReq, { contacts: [] });
        let currentReq: Requisition = r;
        tenantId = currentReq.tenantId;
        this.userTenant = this.tenants.find((tenant) => {
          return tenant.id === tenantId;
        });
      });
    } else {
      this.userTenant = this.tenants.find((tenant) => {
        return tenant.id === tenantId;
      });
    }
  }

  private addReqUsersToAllAssignees(): void {
    this.requisitionService.getReqUsers(this.reqId).subscribe(
      (result) => {
        console.log('AddTodoComponent: my req users ', result.data.getReqUsers);
        const reqUsers: User[] = result.data.getReqUsers;
        const filteredReqUsers: User[] = reqUsers.filter(
          (user) => user.id !== this.authenticatedUserInfo.attributes['custom:userId']
        );
        this.allAssignees = [this.currentUser, ...filteredReqUsers];
        this.getAvatarUrls();
      },
      (error) => {
        console.log('AddTodoComponent: There was an error loading req users', error);
      }
    );
  }

  selectReq($event) {
    this.addReqUsersToAllAssignees();
  }

  private _filterTenant(data: any, name: string): Tenant[] {
    const filterValue = name.toLowerCase();
    return data.filter((tenant) => tenant.title.toLowerCase().includes(filterValue));
  }

  displayTenant(tenant?: Tenant): string | undefined {
    return tenant ? tenant.title : undefined;
  }

  tenantSelected(event) {
    if (this.currentUserInfo.groups.includes('mmAdmin') || this.currentUserInfo.groups.includes('mmUser')) {
      let mmTenant = this.tenants.filter((t) => t.id == this.currentUserInfo.attributes['custom:tenantId']);
      let mmInternalUsers = [];
      if (mmTenant.length > 0) {
        if (event.option.value.id != mmTenant[0].id) {
          mmInternalUsers = mmTenant[0].user;
        }
      }

      this.allAssignees = [].concat(mmInternalUsers, event.option.value.user);
    } else {
      this.allAssignees = event.option.value.user;
    }

    this.allAssignees.sort((userA, userB) => userA.firstName.localeCompare(userB.firstName));
    console.log('AddTodoComponent: allAssignees data ', this.allAssignees);

    //new tenant selected so clear out owner and assignee
    this.createActivityForm.controls['assignees'].reset();
    this.assignees = [];

    let selectTenantId = event.option.value.id;
    this.reqOptions = this.usersReqs
      .filter((req) => {
        console.log(req);
        return req.tenant === selectTenantId && req.status === 'active';
      })
      .map((req) => ({ value: req.reqId, title: req.title }))
      .sort((roA, roB) => roA.title.localeCompare(roB.title));
  }

  getAvatarUrls() {
    this.allAssignees.forEach((user) => {
      if (!this.avatarUrls[user.id]) {
        this.userService
          .getUserAvatarUrl(user.id)
          .pipe(take(1))
          .subscribe(
            ({ data }) => {
              const url = data.getUserAvatarUrl?.url || data.getContactAvatarUrl?.url;
              this.avatarUrls[user.id] = url;
              if (url) this.imageCachingService.cacheImageFromUrl(url, user.id);
            },
            (error) => console.log(error)
          );
      }
    });

    this.allContacts.forEach((contact) => {
      if (!this.avatarUrls[contact.id]) {
        this.contactService
          .getContactAvatarUrl(contact.id)
          .pipe(take(1))
          .subscribe(
            ({ data }) => {
              const url = data.getContactAvatarUrl.url;
              this.avatarUrls[contact.id] = data.getContactAvatarUrl?.url || '';
              if (url) this.imageCachingService.cacheImageFromUrl(url, contact.id);
            },
            (error) => console.log(error)
          );
      }
    });
  }

  verifyTenantSelected() {
    //verify selection is a valid selection from list
    if (!this.tenants.includes(this.createActivityForm.value.tenant)) {
      this.createActivityForm.controls['tenant'].setValue('');
      this.reqOptions = [];
    }
  }

  getReqs() {
    this.getMyReqsSub = this.requisitionService.getMyReqsForDropdown().subscribe(
      (result) => {
        console.log('AddTodoComponent: my reqs ', result.data.getMyReqs);
        this.usersReqs = result.data.getMyReqs;
        this.reqOptions = this.usersReqs
          .filter((req) => {
            console.log(req);
            return req.status === 'active';
          })
          .map((req) => ({ value: req.reqId, title: req.title }))
          .sort((reqA, reqB) => reqA.title.localeCompare(reqB.title));
      },
      (error) => {
        console.log('AddTodoComponent: There was an error loading reqs ', error);
      }
    );
  }

  removeAssignee(assignee): void {
    this.assignees = this.assignees.filter((item) => item.id !== assignee.id);

    console.log('this.assignees', this.assignees);
  }

  selectedAssignee(event: MatAutocompleteSelectedEvent): void {
    if (!this.assignees.includes(event.option.value)) {
      this.assignees.push(event.option.value);
      this.assigneeInput.nativeElement.value = '';
    }
    console.log('this.assignees', this.assignees);
  }

  private _filterAssignee(value: string): any[] {
    if (typeof value == 'string') {
      return this.allAssignees.filter((assignee) =>
        [assignee.firstName, assignee.lastName].join(' ').toLowerCase().includes(value.toLowerCase())
      );
    } else {
      return this.allAssignees;
    }
  }

  removeContact(contact): void {
    this.contacts = this.contacts.filter((item) => item.id !== contact.id);

    console.log('this.contacts', this.contacts);
  }

  selectedContact(event: MatAutocompleteSelectedEvent): void {
    if (!this.contacts.includes(event.option.value)) {
      this.contacts.push(event.option.value);
      this.contactInput.nativeElement.value = '';
    }
    console.log('this.contacts', this.contacts);
  }

  private _filterContact(value: string): any[] {
    if (typeof value == 'string') {
      return this.allContacts
        .filter(({ id }) => !this.contacts.map(({ id }) => id).includes(id))
        .filter((contact) =>
          [contact.firstName, contact.lastName].join(' ').toLowerCase().includes(value.toLowerCase())
        );
    } else {
      return this.allContacts;
    }
  }

  createTodo() {
    // stop here if form is invalid
    if (this.createActivityForm.invalid) {
      console.log('AddTodoComponent: form is invalid', this.createActivityForm.invalid);

      //mark each field as touched so required fields display error state
      for (let i in this.createActivityForm.controls) {
        this.createActivityForm.controls[i].markAsTouched();
      }
      return;
    }

    let form = this.createActivityForm.value;
    console.log('form', form);

    // if(form.type === ActivityType.Meeting && form.sendInvite) {
    //   this.createCalendarEvent(form);
    //   return;
    // }

    let assignees = this.assignees.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
    }));
    let contacts = this.contacts.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
    }));
    let dueTime = form.time ? form.time.split(':') : null;
    let dueOn = dueTime
      ? new Date(form.dueOn.setHours(dueTime[0], dueTime[1])).toISOString()
      : form.dueOn.toISOString();
    let todoInfo = {
      title: form.title,
      type: form.type,
      description: form.description,
      users: assignees,
      contacts: contacts,
      reqId: form.req,
      location: form.location,
      phone: form.phone,
      duration: form.duration,
      createdBy: this.authenticatedUserInfo.attributes['custom:userId'],
      dueOn: dueOn,
      sendInvite: form.type === ActivityType.Meeting && form.sendInvite,
    };
    console.log('AddTodoComponent: adding todo', todoInfo);

    this.todoService.createTodo(todoInfo).subscribe(
      (result) => {
        console.log('AddTodoComponent: todo created ', result);
        this.dialogRef.close('success');
      },
      (error) => {
        console.log('AddTodoComponent: Error creating todo ', error);
        this.dialogRef.close({ error: error });
      }
    );
    this.dialogRef.close('success');
  }

  selectTypeDrop(typeDrop: MatSelectChange) {
    console.log('typeDrop :', typeDrop.value);
    if (typeDrop.value === 'OTHER') {
      this.createActivityForm = this.formBuilder.group({
        type: this.typeFormControl,
        assignees: this.assigneesFormControl,
        dueOn: this.dueOnFormControl,
        title: this.titleFormControl,
        description: this.descriptionFormControl,
      });
    } else {
      this.createActivityForm = this.formBuilder.group({
        type: this.typeFormControl,
        title: this.titleFormControl,
        description: this.descriptionFormControl,
        tenant: this.tenantFormControl,
        req: this.reqFormControl,
        assignees: this.assigneesFormControl,
        contacts: this.contactsFormControl,
        dueOn: this.dueOnFormControl,
        time: this.timeFormControl,
        duration: this.durationFormControl,
        phone: this.phoneFormControl,
        location: this.locationFormControl,
        sendInvite: this.sendInviteFormControl,
      });
    }
  }

  setOutlookCalendar(date) {
    this.outlookService
      .getOutlookEventsOnDate(date, Intl.DateTimeFormat().resolvedOptions().timeZone)
      .then((integrationsServiceObservable) => {
        integrationsServiceObservable.pipe(take(1)).subscribe((proxyGet) => {
          let parsedSuccess = JSON.parse(proxyGet.data.proxyGet);
          if (parsedSuccess.name != 'Error') {
            let eventsList = parsedSuccess.value;
            let eventsObj = [];
            eventsList.map((e) => {
              eventsObj.push({
                title: e.subject,
                start: e.start.dateTime,
                end: e.end.dateTime,
              });
            });
            if (eventsObj.length < 1) {
              this.calendarOptions.height = 50;
            } else {
              this.calendarOptions.height = 300;
            }
            this.calendarOptions.events = eventsObj;
            let calendarApi = this.calendar.getApi();
            calendarApi.gotoDate(date); //Needed bc fullcalendar only shows events that the date is set to when on listDay mode
          } else {
            console.error(
              'OutlookService: There was an error getting Outlook events during the get request',
              parsedSuccess
            );
          }
        });
      });
  }

  setGoogleCalendar(date) {
    this.googleService.getGoogleEvents(date).then((eventsList) => {
      let eventsObj = [];
      eventsList.map((e) => {
        eventsObj.push({
          title: e.summary,
          start: e.start.dateTime,
          end: e.end.dateTime,
        });
      });
      if (eventsObj.length < 1) {
        this.calendarOptions.height = 50;
      } else {
        this.calendarOptions.height = 300;
      }
      this.calendarOptions.events = eventsObj;
      let calendarApi = this.calendar.getApi();
      calendarApi.gotoDate(date); //Needed bc fullcalendar only shows events that the date is set to when on listDay mode
    });
  }

  getCalendar(date, type) {
    if (this.isOutlookAuthorized && type == ActivityType.Meeting) {
      this.setOutlookCalendar(date);
    } else if (this.isGoogleAuthorized && type == ActivityType.Meeting) {
      this.setGoogleCalendar(date);
    }
  }

  createCalendarEvent(form) {
    let startEndDates = this.getStartEndDates(form.dueOn, form.time, this.durationFormControl.value);

    if (this.isOutlookAuthorized) {
      return this.getAssigneeEmails(this.assignees).then((assigneeEmails) => {
        return this.outlookService
          .createOutlookEvent(form, startEndDates, assigneeEmails)
          .then((integrationsServiceObservable) => {
            integrationsServiceObservable.pipe(take(1)).subscribe((proxyPost) => {
              let parsedSuccess = JSON.parse(proxyPost.data.proxyPost);
              if (parsedSuccess.name != 'Error') {
                this.dialogRef.close('success');
              } else {
                console.error(
                  'OutlookService: There was an error creating the calendar event during the POST request',
                  parsedSuccess
                );
              }
            });
          });
      });
    } else if (this.isGoogleAuthorized) {
      return this.getAssigneeEmails(this.assignees).then((assigneeEmails) => {
        return this.googleService.createGoogleEvent(form, startEndDates, assigneeEmails).then((eventCreated) => {
          if (eventCreated) {
            this.dialogRef.close('success');
          } else {
            console.error('GoogleService: There was an error creating the calendar event');
          }
        });
      });
    }
  }

  getAssigneeEmails(assignees): Promise<any> {
    return new Promise(async (resolve) => {
      var attendees = [];
      for (let a = 0; a < assignees.length; ++a) {
        await this.userService
          .getUserInfo(assignees[a].id)
          .pipe(take(1))
          .toPromise()
          .then((result) => {
            attendees.push(result.data.getUserInfo.emails[0].address);
            console.log('pushing ', result.data.getUserInfo.emails[0].address);
          });
      }
      console.log('stringified attendees', JSON.stringify(attendees));
      resolve(attendees);
    });
  }

  getIsOutlookAuthorized() {
    this.isOutlookAuthorized = this.outlookService.isOutlookAuthorized();
  }

  getIsGoogleAuthorized() {
    this.googleService.isSignedIn().then((signedIn) => {
      this.isGoogleAuthorized = signedIn;
    });
  }

  getStartEndDates(date, time, duration) {
    let startDate = new Date(date);
    let endDate = new Date(date);
    let hours = time.split(':')[0];
    let minutes = time.split(':')[1];

    startDate.setHours(hours);
    startDate.setMinutes(minutes);
    endDate.setHours(hours + 1);
    endDate.setMinutes(minutes);

    return [startDate.toISOString(), endDate.toISOString()];
  }

  ngOnDestroy() {
    // this.filterMyContactsSub?.unsubscribe();
    this.filterReqContactsSub?.unsubscribe();
    this.getMyReqsSub?.unsubscribe();
    this.getMyTenantsSub?.unsubscribe();
  }
}
