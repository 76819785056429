import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent, AlertDialogConfig } from './alert-dialog.component';

@Component({
  selector: 'mm-login-dialog-component',
  templateUrl: './login-dialog-component.component.html',
  styleUrls: ['./login-dialog-component.component.scss'],
})
export class LoginDialogComponentComponent implements OnInit, OnDestroy {
  @Input() isResetRequired = false;
  @Input() isForgotPasswordEnabled = false;
  @Input() isNotifyMultipleSessionsEnabled = false;
  @Output() loginSubmitted = new EventEmitter<{
    username: string;
    password: string;
  }>();
  @Output() passwordResetSubmitted = new EventEmitter<{
    username: string;
    oldPassword: string;
    newPassword: string;
  }>();
  @Output() forgotPasswordSubmitted = new EventEmitter<{ email: string }>();
  @Output() enableSessionResponded = new EventEmitter<boolean>();
  @Input() loggingIn: boolean = false;

  data = inject(MAT_DIALOG_DATA);
  private loginForm: FormGroup;
  private resetPasswordForm: FormGroup;
  private forgotPasswordForm: FormGroup;
  private isSessionEnabled: boolean = false;

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  getUsername = () => this.loginForm.get('username');
  getPassword = () => this.loginForm.get('password');
  getNewPassword = () => this.resetPasswordForm.get('newPassword');
  getConfirmPassword = () => this.resetPasswordForm.get('confirmPassword');
  getForgotPasswordEmail = () => this.forgotPasswordForm.get('email');

  ngOnInit() {
    this.loginForm = new FormGroup({
      username: new FormControl(this.data?.username || '', Validators.required),
      password: new FormControl(this.data?.tempPW || '', Validators.required),
    });

    if (this.data?.username && this.data?.tempPW) {
      this.onLogin();
    }

    this.resetPasswordForm = new FormGroup({
      newPassword: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
    });

    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', Validators.required),
    });
  }

  ngOnDestroy() {
    this.snackBar.dismiss();
  }

  onLogin() {
    if (this.getUsername().hasError('required') || this.getPassword().hasError('required')) {
      this.setErrorMessage('Please fill in the required fields');
      return;
    }

    this.loginSubmitted.emit({
      username: this.getUsername().value.toLowerCase(),
      password: this.getPassword().value,
    });
  }

  onResetPassword() {
    if (this.getNewPassword().hasError('required') || this.getConfirmPassword().hasError('required')) {
      this.setErrorMessage('Please fill in required fields');
      return;
    }

    if (this.getNewPassword().value !== this.getConfirmPassword().value) {
      this.setErrorMessage('Passwords do not match');
      return;
    }

    this.passwordResetSubmitted.emit({
      username: this.getUsername().value.toLowerCase(),
      oldPassword: this.getPassword().value,
      newPassword: this.getNewPassword().value,
    });
  }

  onForgotPassword() {
    if (this.getForgotPasswordEmail().hasError('required')) {
      this.setErrorMessage('Please fill in the required fields');
      return;
    }

    this.forgotPasswordSubmitted.emit({
      email: this.getForgotPasswordEmail().value,
    });
  }

  enableForgotPassword() {
    this.isForgotPasswordEnabled = true;
  }

  disableForgotPassword() {
    this.isForgotPasswordEnabled = false;
  }

  enableNotifyingMultipleSessions() {
    this.isNotifyMultipleSessionsEnabled = true;
  }

  disableNotifyingMultipleSessions() {
    this.isNotifyMultipleSessionsEnabled = false;
  }

  enableSession(userInput) {
    if (userInput) {
      this.isSessionEnabled = true;
    } else {
      this.isSessionEnabled = false;
    }

    this.enableSessionResponded.emit(this.isSessionEnabled);
  }

  setErrorMessage(msg = '') {
    this.snackBar.open(msg);
  }

  showUnpaidSubscriptionDialog(unpaidInvoiceUrl) {
    let dialogConfig: AlertDialogConfig = {
      title: 'Your Model Match account has been temporarily suspended due to a past due balance.',
      message: 'Click below to view additional details and reactivate your account.',
      confirmButton: {
        label: 'Pay my invoice',
        color: 'primary',
      },
    };

    let dialogRef = this.dialog.open<AlertDialogComponent, AlertDialogConfig, boolean>(AlertDialogComponent, {
      panelClass: 'alert-dialog-component',
      data: dialogConfig,
      autoFocus: false,
      width: '38%',
    });

    return dialogRef.afterClosed().subscribe((confirmation) => {
      if (confirmation) {
        window.open(unpaidInvoiceUrl);
      }
    });
  }
}
