import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, Observer } from 'rxjs';

import {
  addTodoMutation,
  completeTodoMutation,
  addNote,
  editNote,
  updateTodoMutation,
  reAssignTodos,
  addHistory,
  sendEmail,
  checkUserMentionMutation,
} from '../graphql/mutations';
import {
  GetMyTodosQuery,
  GetReqQuery,
  GetMyReqsQuery,
  GetReqActivityHistory,
  FilterMyTodosQuery,
  FilterAllTodosQuery,
  FilterMyActivityHistory,
  FilterAllActivityHistory,
  GetTodo,
  GetUserTodosQuery,
  ReAssignTodos,
  AddHistory,
  GetUserMentionsQuery,
} from '../graphql/types';
import {
  getMyTodosQuery,
  getReqQuery,
  getMyReqsQuery,
  getMyReqTodosQuery,
  getReqActivityHistory,
  filterMyTodosQuery,
  filterAllTodosQuery,
  filterMyActivityHistory,
  getTodo,
  getUserTodos,
  getUserMentionsQuery,
} from '../graphql/queries';
import {
  Activity,
  ActivitySection,
  NoteInput,
  EditNoteInput,
  HistoryInput,
  UpdateTodoInput,
  ActivityType,
} from '../shared/model/activity';
import { ActivityCriteria, Page, SortOption } from 'app/shared/model/filter';
import { filterAllActivityHistory } from './../graphql/queries';
import { esFilter } from '../shared/model/reporting';
import { UserService } from './user.service';
import { AlertDialogConfig } from 'app/shared/dialogs/alert-dialog.component';
import { nanoid } from 'nanoid';

@Injectable()
export class TodoService {
  todoFilters: ActivityCriteria;
  historyFilters: ActivityCriteria;
  todo: any;
  private createTodoObservers: Observer<any>[] = [];
  private completeTodoObservers: Observer<any>[] = [];
  private mentionObservers: Observer<any>[] = [];

  constructor(
    private apollo: Apollo,
    private userService: UserService
  ) {}

  private subscribeToCreatedTodo() {
    return (observer: Observer<any>) => {
      this.createTodoObservers.push(observer);
      observer.next({ subcribed: 'subscribed' });

      return {
        unsubcribe() {
          this.createTodoObservers.splice(this.createTodoObservers.indexOf(observer), 1);
        },
      };
    };
  }

  private subscribeToCompletedTodo() {
    return (observer: Observer<any>) => {
      this.completeTodoObservers.push(observer);
      observer.next({ subcribed: 'subscribed' });

      return {
        unsubscribe(): void {
          if (this.completeTodoObservers) {
            this.completeTodoObservers.splice(this.completeTodoObservers.indexOf(observer), 1);
          }
        },
      };
    };
  }

  private subscribeToMention() {
    return (observer: Observer<any>) => {
      this.mentionObservers.push(observer);
      observer.next({ subcribed: 'subscribed' });

      return {
        unsubcribe() {
          this.mentionObservers.splice(this.mentionObservers.indexOf(observer), 1);
        },
      };
    };
  }

  private doNotifyTodoCreated(addTodo) {
    if (this.createTodoObservers.length > 0) {
      setTimeout(() => {
        this.createTodoObservers.forEach((obs) => obs.next(addTodo));
      }, 3000);
    }
  }

  private doNotifyTodoCompleted(todo) {
    if (this.completeTodoObservers.length > 0) {
      setTimeout(() => {
        this.completeTodoObservers.forEach((obs) => obs.next(todo));
      }, 3000);
    }
  }

  private doNotifyMentioned(mention) {
    if (this.mentionObservers.length > 0) {
      this.mentionObservers.forEach((obs) => obs.next(mention));
    }
  }

  public notifiyTodoCreated(): Observable<any> {
    return Observable.create(this.subscribeToCreatedTodo());
  }

  public notifyTodoCompleted(): Observable<any> {
    return Observable.create(this.subscribeToCompletedTodo());
  }

  public notifyMentionCreated(): Observable<any> {
    return Observable.create(this.subscribeToMention());
  }

  public getMyTodos(): Observable<any> {
    return this.apollo.watchQuery<GetMyTodosQuery>({
      query: gql(getMyTodosQuery),
      pollInterval: 30000,
    }).valueChanges;
  }

  public filterMyTodos(page: Page, filters: ActivityCriteria, sortOption: SortOption): Observable<any> {
    return this.apollo.watchQuery<FilterMyTodosQuery>({
      query: gql(filterMyTodosQuery),
      variables: { page: page, filters: filters, sortOption: sortOption },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public filterAllTodos(page: Page, filters: ActivityCriteria, sortOption: SortOption): Observable<any> {
    return this.apollo.watchQuery<FilterAllTodosQuery>({
      query: gql(filterAllTodosQuery),
      variables: { page: page, filters: filters, sortOption: sortOption },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getUserMentions(userId): Observable<any> {
    return this.apollo.watchQuery<GetUserMentionsQuery>({
      query: gql(getUserMentionsQuery),
      variables: { userId },
      pollInterval: 30000,
    }).valueChanges;
  }

  public checkUserMention(historyId): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(checkUserMentionMutation),
      variables: { historyId },
      optimisticResponse: {
        __typename: 'Mutation',
        checkUserMention: true,
      },
      update: (proxy, { data: { checkUserMention } }: any) => {
        const data: any = proxy.readQuery({
          query: gql(getUserMentionsQuery),
          variables: { userId: '' },
        });

        console.log('data', data);
        proxy.writeQuery({
          query: gql(getUserMentionsQuery),
          variables: { userId: '' },
          data: {
            ...data,
            getUserMentions: data.getUserMentions.map((mention) =>
              mention.id === historyId ? { ...mention, mentionChecked: true } : mention
            ),
          },
        });
      },
    });
  }

  public completeTodo(todo: Activity, createNextAction: boolean = false): Observable<any> {
    if (!todo.id) {
      return;
    }

    return this.apollo.mutate({
      mutation: gql(completeTodoMutation),
      variables: {
        todoId: todo.id,
        disposition: todo.disposition,
        createNextAction,
      },
      update: (store, { data: { completeTodo } }: any) => {
        this.doNotifyTodoCompleted(todo);
      },
    });
  }

  /**
     * 
     * @param todoInfo {
            title: form.title,
            type: form.type,
            description: form.description,
            users: assignees,
            contacts: contacts,
            reqId: form.req,
            location: form.location,
            phone: form.phone,
            duration: form.duration,
            createdBy: this.authenticatedUserInfo.attributes['custom:userId'],
            dueOn: dueOn
        }
     */
  public createTodo(todoInfo: any): Observable<any> {
    if (!todoInfo.title || !todoInfo.type) {
      return;
    }
    return this.apollo.mutate({
      mutation: gql(addTodoMutation),
      variables: { input: todoInfo },
      update: (store, { data: { addTodo } }: any) => {
        this.doNotifyTodoCreated(addTodo);
      },
    });
  }

  public getTodo(todoId: string): Observable<any> {
    if (!todoId) {
      return;
    }

    return this.apollo.watchQuery<GetTodo>({
      query: gql(getTodo),
      variables: { todoId: todoId },
    }).valueChanges;
  }

  public updateTodo(input: UpdateTodoInput): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(updateTodoMutation),
      variables: { updateInput: input },
      update: (store, { data: { updateTodo } }: any) => {
        this.doNotifyTodoCreated(updateTodo);
      },
    });
  }

  public filterMyActivityHistories(page: Page, filters: ActivityCriteria, sortOption: SortOption): Observable<any> {
    return this.apollo.watchQuery<FilterMyActivityHistory>({
      query: gql(filterMyActivityHistory),
      variables: { page: page, filters: filters, sortOption: sortOption },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public filterAllActivityHistories(page: Page, filters: ActivityCriteria, sortOption: SortOption): Observable<any> {
    return this.apollo.watchQuery<FilterAllActivityHistory>({
      query: gql(filterAllActivityHistory),
      variables: { page: page, filters: filters, sortOption: sortOption },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public addNote(input: NoteInput): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(addNote),
      variables: { noteInput: input },

      optimisticResponse: {
        __typename: 'Mutation',
        addNote: {
          __typename: 'History',
          id: nanoid(),
          type: ActivityType.Note,
          title: input.note,
          description: input.note,
          reqId: input.reqId,
          dueOn: new Date().toISOString(),
          completedDate: new Date().toISOString(),
          completedBy: {
            id: this.userService.userId,
            firstName: this.userService.firstName,
            lastName: this.userService.lastName,
          },
          users: [],
          contacts: [],
          isPublic: input.isPublic,
        },
      },
      update: (store, { data: { addNote } }: any) => {
        // Adding a note and completing a todo both results in a new history item.
        // Notify history observers to reload their view.
        this.doNotifyTodoCompleted(addNote);
      },
    });
  }
  public editNote(input: EditNoteInput): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(editNote),
      variables: { editNoteInput: input },
      update: (store, { data: { editNote } }: any) => {
        // editing a note results in new history item creation and changing the original note item type to "EDITEDNOTE"
        // Notify history observers to reload their view.
        this.doNotifyTodoCompleted(editNote);
        this.doNotifyTodoCompleted(addNote);
      },
    });
  }

  public addHistory(input: HistoryInput): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(addHistory),
      variables: { historyInput: input },
      update: (store, { data: { addHistory } }: any) => {
        // Adding a note and completing a todo both results in a new history item.
        // Notify history observers to reload their view.
        this.doNotifyTodoCompleted(addHistory);
        if (input.type === 'MENTION') this.doNotifyMentioned(addHistory);
      },
    });
  }

  public createActivitySections(): ActivitySection[] {
    let activitySections: ActivitySection[] = [];

    activitySections.push({
      type: 'PAST_DUE',
      title: 'Past Due',
      emptyMessage: 'Hooray, you have no past due tasks!',
      mainHeaderHidden: false,
      activities: [],
      pageTodo: { page: 1, pageSize: 10 },
      todoFilters: { todoSection: 'PAST_DUE' },
      sortOption: { field: 'completedDate', direction: 'desc' },
    });
    activitySections.push({
      type: 'TODAY',
      title: 'Due Today',
      emptyMessage: 'You are all caught up for today!',
      mainHeaderHidden: false,
      activities: [],
      pageTodo: { page: 1, pageSize: 10 },
      todoFilters: { todoSection: 'TODAY' },
      sortOption: { field: 'completedDate', direction: 'desc' },
    });
    activitySections.push({
      type: 'UPCOMING',
      title: 'Upcoming',
      emptyMessage: 'There are no more tasks scheduled for this week.',
      mainHeaderHidden: false,
      activities: [],
      pageTodo: { page: 1, pageSize: 10 },
      todoFilters: { todoSection: 'UPCOMING' },
      sortOption: { field: 'completedDate', direction: 'desc' },
    });

    return activitySections;
  }

  public setTodoInActivitySections(sections: ActivitySection[], todos: Activity[], itemLimit: number = null): void {
    let now = new Date();
    const today = now.toISOString().substring(0, 10);

    const pastDueActivities = todos ? todos.filter((todo) => todo.dueOn.substring(0, 10) < today) : [];
    const dueTodayActivities = todos ? todos.filter((todo) => todo.dueOn.substring(0, 10) == today) : [];
    const upcomingActivities = todos ? todos.filter((todo) => todo.dueOn.substring(0, 10) > today) : [];

    sections.forEach((section) => {
      if (section.type == 'PAST_DUE') {
        if (itemLimit) {
          section.activities = pastDueActivities.slice(0, itemLimit);
        } else {
          section.activities = pastDueActivities;
        }
      } else if (section.type == 'TODAY') {
        if (itemLimit) {
          section.activities = dueTodayActivities.slice(0, itemLimit);
        } else {
          section.activities = dueTodayActivities;
        }
      } else if (section.type == 'UPCOMING') {
        if (itemLimit) {
          section.activities = upcomingActivities.slice(0, itemLimit);
        } else {
          section.activities = upcomingActivities;
        }
      }
    });
  }

  // LIST users's todos
  public getUserTodos(userId: string): Observable<any> {
    return this.apollo.watchQuery<GetUserTodosQuery>({
      query: gql(getUserTodos),
      variables: {
        userId: userId,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  // Re-Assign Todo
  public reAssignTodos(oldUserId: string, newUserId: string): Observable<any> {
    return this.apollo.mutate<ReAssignTodos>({
      mutation: gql(reAssignTodos),
      variables: { oldUserId: oldUserId, newUserId: newUserId },
    });
  }

  // Same function as in contactActions.ts in the backend.
  calculateNextRecurringTodoDate(todo) {
    let todoDate = new Date(todo.dueOn);
    let todaysDate = new Date();
    let date = todoDate < todaysDate ? todaysDate : todoDate;
    let newDate: Date;
    switch (todo.recurring.frequency) {
      case 'Daily':
        newDate = this.addDays(1, date);
        return newDate;
      case 'Weekly':
        newDate = this.addDays(7, date);
        return newDate;
      case 'Monthly':
        date.setMonth(date.getMonth() + 1);
        newDate = date;
        return newDate;
      case 'Custom':
        let days = 1;
        if (todo.recurring.customRecurringFrequency == 'Days') {
          days = todo.recurring.customRecurringNum;
        } else if (todo.recurring.customRecurringFrequency == 'Weeks') {
          days = 7 * todo.recurring.customRecurringNum;
        } else if (todo.recurring.customRecurringFrequency == 'Months') {
          let addMonths = todo.recurring.customRecurringNum;
          date.setMonth(date.getMonth() + addMonths);
          newDate = date;
          return newDate;
        } else {
          console.error(
            'Error in calculateNextRecurringTodoDate :: customRecurringFrequency is not valid or defined',
            todo.recurring
          );
        }
        newDate = this.addDays(days, date);
        return newDate;
      default:
        return date;
    }
  }

  getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  addDays(days, date) {
    date.setDate(date.getDate() + days);
    return date;
  }

  async getAuthenticatedUserInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.getAuthenticatedUserInfo().subscribe(
        (userInfo: any) => {
          console.log('TodoService: authenticatedUserInfo ', userInfo);
          resolve(userInfo);
        },
        (error) => {
          console.error('TodoService: Error getting authenticated user groups -> ' + error.message);
          reject(null);
        }
      );
    });
  }

  getCompleteOrContinueDialogConfig(tempRecurringDate) {
    let dialogConfig: AlertDialogConfig = {
      title: 'Complete or continue the recurring todo?',
      message: `If you wish to continue the todo, the next due date will be ${tempRecurringDate.toDateString()}`,
      confirmButton: {
        label: 'Continue',
        color: 'primary',
        value: 'continue',
      },
      cancelButton: {
        label: 'Complete',
        color: 'accent',
        value: 'complete',
      },
    };
    return dialogConfig;
  }
}
