import { Component, OnInit, OnDestroy, Inject, Output, EventEmitter, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { headerValues as staticValues, userHeaderValues } from '../shared/model/fuzzy';
import { CustomFieldsService } from 'app/services/custom-fields.service';

@Component({
  selector: 'mm-header-edit-dialog',
  templateUrl: './header-edit-dialog.component.html',
  styleUrls: ['./header-edit-dialog.component.scss'],
})
export class HeaderEditDialog implements OnInit, OnDestroy {
  headerOptions: any[] = [];
  selectedHeader: string;

  constructor(
    public dialogRef: MatDialogRef<HeaderEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customFieldsService: CustomFieldsService
  ) {}

  ngOnInit() {
    this.generateHeaderOptions();
  }

  async generateHeaderOptions() {
    let options;
    if (this.data.headerValuesType == 'user') {
      options = userHeaderValues.map((a) => {
        return {
          value: a,
          available: !this.data.headerData.some((b) => b.matched === a && b.matched !== this.data.column.matched),
        };
      });
    } else {
      let customFields = (await this.customFieldsService.getCustomFields({ reqId: this.data.reqId }).toPromise())
        .customFields;
      let headerValues = [...staticValues, ...customFields.map((field) => field.name)];
      options = headerValues.map((a) => {
        return {
          value: a,
          available: !this.data.headerData.some((b) => b.matched === a && b.matched !== this.data.column.matched),
        };
      });
    }
    if (this.data.column.matched) this.selectedHeader = this.data.column.matched;
    this.headerOptions = options;
  }

  handleSelect(val) {
    this.selectedHeader = val;
  }

  handleSubmitChange() {
    this.dialogRef.close(this.selectedHeader);
  }

  ngOnDestroy() {}
}
