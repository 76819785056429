import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mm-bulk-contact-select-dialog',
  templateUrl: './bulk-contact-select-dialog.component.html',
})
export class ConfirmBulkDialog {
  totalSize: Number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmBulkDialog>
  ) {}

  ngOnInit() {
    this.totalSize = this.data.totalSize;
  }

  onConfirmBulk() {
    this.dialogRef.close(true);
  }

  ngOnDestroy() {}
}
