<h1 mat-dialog-title>Add Note</h1>
<mat-dialog-content>
  <form>
    <mat-form-field appearance="outline" [ngClass]="data ? data.colorClass : null">
      <mat-label>Add a note</mat-label>
      <textarea matInput [formControl]="note" required></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="warn" [mat-dialog-close]="true">Cancel</button>
  <button mat-button color="primary" [mat-dialog-close]="true" [disabled]="!note.valid" (click)="addNote()">
    Submit
  </button>
</mat-dialog-actions>
