<div [ngClass]="class || ''">
  <div
    #anchor
    (click)="toggleDropdown($event, 'click')"
    (mouseleave)="toggleDropdown($event, 'hover')"
    (mouseenter)="toggleDropdown($event, 'hover')"
  >
    <ng-content select="[anchor]"></ng-content>
  </div>

  <div [ngStyle]="{ 'z-index': showing ? 100 : -1 }">
    <div
      #tooltip
      [ngClass]="showing ? 'scale-100 opacity-100' : 'scale-50 opacity-0'"
      class="flex bg-gray-800 rounded-lg p-4 text-gray-100 flex-col relative z-50 space-y-1 transform transition-all duration-150 origin-bottom"
      style="z-index: 100"
    >
      <div *ngIf="showing">
        <div #contentSlot><ng-content select="[content]"></ng-content></div>
        <p
          *ngIf="contentSlot && message && contentSlot.children.length == 0"
          class="text-sm text-gray-200 font-semibold"
        >
          {{ message }}
        </p>
        <div
          class="flex bg-gray-800 h-4 w-4 absolute rounded-sm transform rotate-45"
          [ngClass]="
            popper?.state?.placement === 'bottom' ||
            popper?.state?.placement === 'bottom-start' ||
            popper?.state?.placement === 'bottom-end'
              ? '-top-2 ' + carrotPosition
              : '-bottom-2 ' + carrotPosition
          "
          style="z-index: 100"
        ></div>
      </div>
    </div>
  </div>
</div>
