<div mat-dialog-title>
  <button mat-icon-button class="button-close" (click)="onCloseClick()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="flex-container header-container" fxLayoutGap="1em" fxLayout="row" fxLayout.lt-lg="column">
    <div fxFlex="25%">
      <header class="dialog-header">
        <h3>Add Dashboard Widget</h3>
      </header>
    </div>

    <div fxFlex fxLayoutAlign="start center" class="req-options">
      <mat-form-field appearance="outline" style="width: 100%; margin-right: 2em">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" #searchObjListInput [formControl]="searchReqsControl" />
      </mat-form-field>
    </div>
  </div>
</div>

<section>
  <div class="container">
    <div class="flex-container" fxLayoutGap="1em" fxLayout="row" fxLayout.lt-lg="column">
      <div class="child-1" fxFlex="25%" fxFlex.gt-md="25%" fxFlexOrder="1" fxFlexOrder.lt-lg="1" style="flex-grow: 1">
        <mat-action-list>
          <button mat-list-item (click)="filterBy('')">All</button>
          <button mat-list-item (click)="filterBy('Featured')">Featured</button>
          <button mat-list-item (click)="filterBy('Counter')">Counter</button>
          <button mat-list-item (click)="filterBy('Chart')">Chart</button>
        </mat-action-list>
      </div>

      <div
        class="child-2 integrations-description"
        fxFlex="75%"
        fxFlex.gt-md="75%"
        fxFlexOrder="1"
        fxFlexOrder.lt-lg="1"
        style="flex-grow: 5; height: 60vh; overflow: auto"
      >
        <mat-grid-list cols="2" rowHeight="2:1" gutterSize="1.2em">
          <mat-grid-tile *ngFor="let mItem of filteredMenuItems | async; let index = index" class="reportObjTile">
            <mat-card (click)="onClickAdd(mItem)" class="reportObj">
              <div *ngIf="mItem.ribbon" class="corner-ribbon top-right blue">{{ mItem.ribbon }}</div>

              <div fxLayoutAlign="center center" class="card-header-image cardCenter">
                <mat-icon *ngIf="mItem.icon">{{ mItem.icon }}</mat-icon>

                <mat-icon *ngIf="!mItem.icon">add</mat-icon>
              </div>

              <mat-card-title class="cardCenter">{{ mItem.name }}</mat-card-title>
              <mat-card-content class="cardCenter">
                <i>
                  {{ mItem.desc }}
                </i>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </div>
</section>
