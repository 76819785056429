<div class="playbook-overall-score-container">
  <circle-progress
    class="score-chart"
    responsive="true"
    titleFontSize="75"
    unitsFontSize="35"
    [percent]="scoreOverall"
    [showSubtitle]="false"
    [showBackground]="false"
    [radius]="100"
    [outerStrokeWidth]="16"
    [maxPercent]="100"
    [innerStrokeWidth]="16"
    [outerStrokeColor]="'#6497b3'"
    [innerStrokeColor]="'#e7e8ea'"
    [animation]="true"
    [animationDuration]="300"
    [showZeroOuterStroke]="true"
    [space]="-10"
  >
  </circle-progress>
</div>
