import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'simpleTime' })
export class SimpleTimePipe implements PipeTransform {
  transform(dueDate: string): string {
    const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'narrow' });
    const rtf2 = new Intl.RelativeTimeFormat('en', { style: 'short' });
    const rtf3 = new Intl.RelativeTimeFormat('en', { style: 'long' });

    const dueDateObj = new Date(dueDate);
    const now = new Date();
    const diff = dueDateObj.getTime() - now.getTime();
    const diffInYears = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
    const diffInMonths = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
    const diffInWeeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
    const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));
    const diffInHours = Math.floor(diff / (1000 * 60 * 60));
    const diffInMinutes = Math.floor(diff / (1000 * 60));
    const diffInSeconds = Math.floor(diff / 1000);

    if (diffInYears > 0) {
      // if it is years then just show the date
      return dueDateObj.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    }

    if (diffInMonths > 0) {
      // show the actual date with month and year if the due date is more than 6 months away
      if (diffInMonths > 3) {
        return dueDateObj.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      }

      return rtf3.format(diffInMonths, 'month');
    }

    if (diffInWeeks > 0) {
      return rtf3.format(diffInWeeks, 'week');
    }

    if (diffInDays > 0) {
      return rtf3.format(diffInDays, 'day');
    }

    if (diffInHours > 0) {
      return rtf2.format(diffInHours, 'hour');
    }

    if (diffInMinutes > 0) {
      return rtf1.format(diffInMinutes, 'minute');
    }

    if (diffInSeconds > 0) {
      return rtf1.format(diffInSeconds, 'second');
    }

    // what if it was in the past
    // if it was a over a year ago then we show the date
    if (diffInYears < 0 && diffInYears * -1 > 1) {
      return dueDateObj.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    }

    // if it was less than a year ago but more than 6 months ago we show the months
    if (diffInMonths < 0 && diffInMonths * -1 > 3) {
      return rtf3.format(diffInMonths, 'month');
    }

    // if it was less than 3 months ago but more than a week ago we show the weeks
    if (diffInWeeks < 0 && diffInWeeks * -1 > 1) {
      return rtf3.format(diffInWeeks, 'week');
    }

    // if it was less than a week ago but more than a day ago we show the days
    if (diffInDays < 0 && diffInDays * -1 > 1) {
      return rtf3.format(diffInDays, 'day');
    }

    // if it was less than a day ago but more than an hour ago we show the hours
    if (diffInHours < 0 && diffInHours * -1 > 1) {
      return rtf2.format(diffInHours, 'hour');
    }

    // if it was less than an hour ago but more than a minute ago we show the minutes
    if (diffInMinutes < 0 && diffInMinutes * -1 > 1) {
      return rtf1.format(diffInMinutes, 'minute');
    }

    // if it was less than a minute ago but more than a second ago we show the seconds
    if (diffInSeconds < 0 && diffInSeconds * -1 > 1) {
      return rtf1.format(diffInSeconds, 'second');
    }

    // if it was less than a second ago we show the seconds
    return rtf1.format(diffInSeconds, 'second');
  }
}
