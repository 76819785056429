import { LookUpKey, TenantSelfServeRecord } from './../../../../app/src/model/permissions/userPermissionDefaults/pricingTierTemplates';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { Apollo, gql } from 'apollo-angular';

import { changeUserPermissionTier, creditTierForDisabledUser, getAffiliateCouponCode, getSubscriptionSummary, inviteWithAffiliateCode, setProductTiers, tenantAdminSubscriptionChange, updateSelfServeTenantEmail } from '../graphql/mutations';
import { getCognitoUserStatus, getCurrentUserTier, getProductTiers, getStripeCustomerPortal, getStripeSubscriptionInfo, getTenantSelfServeRecord, isCognitoUser } from '../graphql/queries';
import { map } from 'rxjs/operators';

export type StagedSubscriptionUserDoc = {
  email: string
  firstName: string
  lastName: string 
  newUser: boolean
  selectedTier: LookUpKey
  tenantId: string
  currentTier?: LookUpKey
  userId?: string
  creditedTierId?: string
}

export type PricingTier = {
  features: string[]
  lookup_key: string
  name: string
  paymentLink: string
  price: string
}

export type CreditedTierArgs = {
  type: 'get' | 'put' | 'delete'
  payload: CreditedTierPayload
}

type CreditedTierPayload = {
  lookup_key?: LookUpKey
  tenantId?: string
  creditedTierId?: string
}

@Injectable()
export class SelfSignupService {
  private apollo = inject(Apollo);

  getProductTiers(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: gql(getProductTiers),
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => JSON.parse(result.data.getProductTiers)));
  }

  getStripeSubscriptionInfo(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: gql(getStripeSubscriptionInfo),
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => JSON.parse(result.data.getStripeSubscriptionInfo)));
  }

  isCognitoUser(username: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: gql(isCognitoUser),
        variables: { username },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data.isCognitoUser));
  }
  
  getCognitoUserStatus(email: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: gql(getCognitoUserStatus),
        variables: { email },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => JSON.parse(result.data.getCognitoUserStatus)));
  }

  tenantAdminSubscriptionChange(data: StagedSubscriptionUserDoc): Observable<any> {
    return this.apollo
      .mutate<{ tenantAdminSubscriptionChange: string }>({
        mutation: gql(tenantAdminSubscriptionChange),
        variables: { data: JSON.stringify(data) },
      })
      .pipe(map((result) => JSON.parse(result.data.tenantAdminSubscriptionChange)));
  }

  setProductTiers(data: any): Observable<any> {
    return this.apollo
      .mutate<{ setProductTiers: string }>({
        mutation: gql(setProductTiers),
        variables: { data: JSON.stringify(data) },
      })
      .pipe(map((result) => JSON.parse(result.data.setProductTiers)));
  }

  getStripeCustomerPortal(callbackURL: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: gql(getStripeCustomerPortal),
        variables: { callbackURL },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => JSON.parse(result.data.getStripeCustomerPortal)));
 
  }

  creditTierForDisabledUser(args: CreditedTierArgs): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: gql(creditTierForDisabledUser),
        variables: { args: JSON.stringify(args) },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => JSON.parse(result.data.creditTierForDisabledUser))) 
  }

  getTenantSelfServeRecord(tenantId?: string): Observable<TenantSelfServeRecord> {
    return this.apollo
      .watchQuery<any>({
        query: gql(getTenantSelfServeRecord),
        variables: { tenantId: tenantId || ''},
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => JSON.parse(result.data.getTenantSelfServeRecord)))
  }

  updateSelfServeTenantEmail(tenantId: string, username: string): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: gql(updateSelfServeTenantEmail),
        variables: { tenantId, username },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => JSON.parse(result.data.updateSelfServeTenantEmail)))
  }

  getSubscriptionSummary(tenantId: string): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: gql(getSubscriptionSummary),
        variables: { tenantId },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => JSON.parse(result.data.getSubscriptionSummary)))
  }

  getAffiliateCouponCode(tenantId?: string): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: gql(getAffiliateCouponCode),
        variables: { tenantId: tenantId || ''},
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => JSON.parse(result.data.getAffiliateCouponCode)))
  }
  
  inviteWithAffiliateCode(code: string, email: string): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: gql(inviteWithAffiliateCode),
        variables: { code, email },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => JSON.parse(result.data.inviteWithAffiliateCode)))
  }

  getCurrentUserTier(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: gql(getCurrentUserTier),
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data.getCurrentUserTier));
  }
  
  changeUserPermissionTier(tenantId: string, userId: string, lookup_key?: string, remove?: boolean ): Observable<any> {
    return this.apollo
    .mutate<any>({
      mutation: gql(changeUserPermissionTier),
      variables: { tenantId, userId, ...(lookup_key && { lookup_key }), ...(remove && { remove }) },
      fetchPolicy: 'network-only',
    })
    .pipe(map((result) => result.data.changeUserPermissionTier))
  }

  async checkSelfSignupGuard(email: string) {
    
  }
}
