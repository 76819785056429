import { Component, OnInit, Inject, inject, Input, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertDialogComponent, AlertDialogConfig } from '../../shared/dialogs/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UpdateReqInput } from '../model/requisitions';
import { PlaybookTemplate } from 'app/shared/model/playbook';
import { User } from '../../shared/model/user';
import { Requisition } from '../../shared/model/requisitions';
import { RequisitionService } from '../../services/requisition.service';
import { take } from 'rxjs/operators';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'mm-edit-requisition-dialog',
  templateUrl: './edit-requisition-dialog.component.html',
  styleUrls: ['./edit-requisition-dialog.component.scss', './../../nick_styles/nick.css'],
})
export class EditRequisitionDialogComponent implements OnInit {
  requisitionForm: FormGroup;
  title: string;
  summary: string;
  playbookTemplateId: string;
  playbookTemplates: PlaybookTemplate[];
  reqUsers: User[];
  requisition: Requisition;
  isAdmin: boolean = false;
  isTenantAdmin: boolean = false;
  isTenantLeader: boolean = false;
  isReqOwner: boolean = false;
  authenticatedUserInfo: any;
  currentOwnerId: string = '';
  userId: string;

  requisitionService = inject(RequisitionService)
  @Input() pipeline: any
  assignedTags: string[] = []
  tags: string[]; 
  newTag: string = ''; 
  allExistingTags: string[] = [];
  suggestions: string[] = [];
  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: FormBuilder,
    private confirmDialog: MatDialog,
    private reqService: RequisitionService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.title = this.data.req.title;
    this.summary = this.data.req.summary;
    this.playbookTemplates = this.data.playbookTemplates;
    this.playbookTemplateId = this.data.playbookTemplateId;
    this.reqUsers = JSON.parse(JSON.stringify(this.data.reqUsers));
    this.requisition = this.data.requisition;

    this.currentOwnerId = JSON.parse(JSON.stringify(this.data.requisition.ownerId));

    this.requisitionForm = this.formBuilder.group({
      title: [this.title, Validators.required],
      summary: [this.summary, Validators.required],
      playbookTemplateId: [this.playbookTemplateId],
    });

    this.userService
      .getAuthenticatedUserInfo()
      .pipe(take(1))
      .subscribe(
        (userInfo: any) => {
          console.log('getAuthenticatedUserInfo', userInfo);
          this.authenticatedUserInfo = userInfo;

          this.userId = userInfo.attributes['custom:userId']

          this.isAdmin = userInfo.groups.includes('mmAdmin', 0);

          this.isTenantAdmin = userInfo.groups.includes('tenantAdmin', 0);

          this.isTenantLeader = userInfo.groups.includes('tenantLeader', 0);

          this.isReqOwner = userInfo.attributes['custom:userId'] === this.requisition.ownerId;
        },
        (error) => {
          console.log('UsersPickerDialogComponent: Error getting authenticated user groups -> ' + error.message);
        }
    );
    
    this.loadReqTags()
    console.log('all existing req tags: ', this.allExistingTags)
  }

  confirmChangeTemplate(): Observable<boolean> {
    const dialogConfig: AlertDialogConfig = {
      title: 'Change this template link?',
      message: 'Warning: This change can NOT be undone! ',
      confirmButton: {
        label: 'Change, yes?',
        color: 'warn',
      },
    };

    const dialogRef = this.confirmDialog.open<AlertDialogComponent, AlertDialogConfig, boolean>(AlertDialogComponent, {
      panelClass: 'alert-dialog-component',
      data: dialogConfig,
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  changeDefaultPlaybookTemplate(event: any) {
    console.log('changeDefaultPlaybookTemplate', event);

    if (event.value != this.playbookTemplateId && this.playbookTemplateId != '') {
      // Severe warning here of all the lost information that can happen
      this.confirmChangeTemplate().subscribe(
        (changeTemplateConfirmed) => {
          if (changeTemplateConfirmed) {
          }
        },
        (error: unknown) => {
          console.log('changeTemplateConfirmed error: ', error);
        }
      );
    }
  }

  confirmChangeOwner(): Observable<boolean> {
    const dialogConfig: AlertDialogConfig = {
      title: 'Change this requisition owner?',
      message:
        'Warning: This change will apply right away. If you are not an admin, you will not be able to change this option again.',
      confirmButton: {
        label: 'Change, yes?',
        color: 'warn',
      },
    };

    const dialogRef = this.confirmDialog.open<AlertDialogComponent, AlertDialogConfig, boolean>(AlertDialogComponent, {
      panelClass: 'alert-dialog-component',
      data: dialogConfig,
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  addTag(): void {
    this.newTag = this.toTitleCase(this.newTag).trim()
    if (this.newTag && !this.assignedTags.includes(this.newTag)) {
      console.log(this.newTag, this.assignedTags)
      this.assignedTags = [...this.assignedTags, this.newTag]
      this.newTag = ''; // Reset the input field
      this.updateTags()

    }
  }

 toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
}

  removeTag(event: Event, tag: string): void {
    this.assignedTags = this.assignedTags.filter(t => t !== tag);
    this.updateTags()
  }

  assignTag(tag: string) {
    !this.assignedTags.includes(tag) && this.assignedTags.push(tag) && this.updateTags()
    
  }

  async updateTags() {
    const {data: {updateReqTags}} = await this.requisitionService.updateReqTags(this.requisition.id, this.assignedTags).pipe(take(1)).toPromise()
    console.log('👽  ~ updateReqTags:', updateReqTags);
  }

  loadReqTags() {
    console.log('loadReqTags');
    this.reqService.getReqTags(this.requisition.id).pipe(take(1)).subscribe(
      (res) => {
        this.assignedTags = res.data.getReqTags 
        this.tags = []
      },
      (error) => {
        console.log('👽  ~ error:', error);
      }
    )
    let existingTags = sessionStorage.getItem('assignedPipelineTags')
    this.allExistingTags = existingTags ? JSON.parse(existingTags) : []
  }

  selectSuggestion(suggestion: string) {
    this.newTag = suggestion;
    this.suggestions = [];
  }

  getSuggestions() {
    this.suggestions = this.allExistingTags.filter(tag => tag.toLowerCase().includes(this.newTag.toLowerCase()))
  }

  changeReqOwner(event: any) {
    // console.log('changeReqOwner', event);
    // console.log('reqUsers', this.reqUsers);
    // console.log('currentOwnerId', this.currentOwnerId);
    // console.log('requisition.ownerId', this.requisition.ownerId);

    if (event.value != this.requisition.ownerId) {
      // Severe warning here of all the lost information that can happen
      this.confirmChangeOwner()
        .pipe(take(1))
        .subscribe(
          (confirmChangeOwner) => {
            if (confirmChangeOwner) {
              console.log('gonna change the owner');

              // find user in reqUsers that matches event.value

              let newOwnerInfo = this.reqUsers.find((ele) => ele.id === event.value);

              // console.log('reqUsers', JSON.parse(JSON.stringify(this.reqUsers)));
              // console.log('newOwnerInfo', newOwnerInfo);

              if (newOwnerInfo.id) {
                this.reqService
                  .updateReqOwner(this.requisition.id, event.value, newOwnerInfo.firstName, newOwnerInfo.lastName)
                  .pipe(take(1))
                  .subscribe(
                    (result) => {
                      // console.log('Requisition owner updated == ', result.data.updateReqOwner);

                      this.requisition.ownerId = JSON.parse(JSON.stringify(newOwnerInfo.id));

                      this.currentOwnerId = JSON.parse(JSON.stringify(newOwnerInfo.id));

                      this.reqUsers = JSON.parse(JSON.stringify(this.data.reqUsers));
                    },
                    (error) => {
                      console.log('Error updating requisition owner == ', error);
                    }
                  );
              }
            } else {
              // console.log('reqUsers', JSON.parse(JSON.stringify(this.reqUsers)));
              // console.log('B4', JSON.parse(JSON.stringify(this.currentOwnerId)));

              this.currentOwnerId = JSON.parse(JSON.stringify(this.requisition.ownerId));
              this.reqUsers = JSON.parse(JSON.stringify(this.data.reqUsers));

              //   console.log('reqUsers', JSON.parse(JSON.stringify(this.reqUsers)));
              //   console.log('After', JSON.parse(JSON.stringify(this.currentOwnerId)));
            }
          },
          (error: unknown) => {
            console.log('confirmChangeOwner error: ', error);
          }
        );
    }
  }
}
