import { Injectable } from '@angular/core';
import { Observable, from, Subject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { sendgridTest, sendSendgridTemplateEmail, stripeTest } from 'app/graphql/queries';
import { Personalizations } from 'app/shared/model/sendgrid';

@Injectable()
export class SendgridService {
  constructor(private apollo: Apollo) {}

  sendgridTest(): Observable<any> {
    return this.apollo.query({
      query: gql(sendgridTest),
      variables: {},
    });
  }

  sendSendgridTemplateEmail(template: string, personalizations: Personalizations): Observable<any> {
    return this.apollo.query({
      query: gql(sendSendgridTemplateEmail),
      variables: {
        template,
        personalizations,
      },
    });
  }
}
