import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../services/user.service';
import { TodoService } from 'app/services/todo.service';
import { take } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { RequisitionService } from '../../services/requisition.service';
import { Subscription } from 'rxjs';
import { User } from '../../shared/model/user';
import { ActivityType } from '../../shared/model/activity';
import { Requisition } from '../model/requisitions';
import { IntegrationsService } from 'app/services/integrations.service';
import { ToEmail } from '../model/sendgrid';
import { SendgridService } from 'app/services/sendgrid.service';
import { ContactService } from 'app/services/contact.service';
import { Contact } from '../model/contacts';

@Component({
  selector: 'mm-edit-note-dialog',
  templateUrl: './edit-note-dialog.component.html',
  styleUrls: ['./edit-note-dialog.component.scss'],
})
export class EditNoteDialogComponent implements OnInit {
  description: string;
  userId: string;
  isNoteOwner: boolean;
  mentionList: any[] = [];
  requisition: Requisition;
  contact: any = null;

  mentionedUsers: any[] = [];
  reqUsersSub: Subscription;
  reqUsers: User[] = [];
  currentUser: any;
  validNoteLength: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private todoService: TodoService,
    private snackBar: MatSnackBar,
    private reqService: RequisitionService,
    private integrationsService: IntegrationsService,
    private sendgridService: SendgridService,
    private contactService: ContactService
  ) {}

  ngOnInit() {
    // conditionally render note template based on if user is original author
    this.userService.getAuthenticatedUserId().subscribe((value) => {
      this.userId = value;
      if (this.userId === this.data.user.id) {
        this.isNoteOwner = true;
      } else {
        this.isNoteOwner = false;
      }
    });

    if (this.data.reqId) {
      this.getReqUsers();
    }

    // load the contact
    if (this.data.contactId) {
      this.contactService
        .getContact(this.data.contactId)
        .valueChanges.pipe(take(1))
        .subscribe((result) => {
          this.contact = Object.assign({}, result.data.getContact);
        });
    }

    // load the req
    if (this.data.reqId) {
      this.reqService.loadRequisition(this.data.reqId, true).subscribe((result) => {
        this.requisition = result.data.getReq;
      });
    }
  }

  getReqUsers(startDate = null, endDate = null) {
    this.reqUsersSub = this.reqService.getReqUsers(this.data.reqId, startDate, endDate).subscribe(
      (result) => {
        this.reqUsers = result.data.getReqUsers;
        this.mentionList = this.reqUsers.map((a) => ({
          ...a,
          name: `${a.firstName} ${a.lastName}`,
        }));

        this.reqUsersSub.unsubscribe();
      },
      (err) => {
        console.log(`editNoteDialogComponent : Error fetching req users == ${err}`);
      }
    );
  }

  editNote(noteObject) {
    let input = {
      contactId: noteObject.contactId,
      reqId: noteObject.reqId,
      note: noteObject.description,
      historyId: noteObject.historyId,
      isPublic: noteObject.isPublic,
    };
    this.currentUser = noteObject.user;
    const mentionedUsers = this.cleanupMentionedUsers(input);

    this.todoService
      .editNote(input)
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.createMentionsHistory(mentionedUsers, result.data.editNote, noteObject);
          this.snackBar.open('Note updated successfully', '', {
            duration: 3000,
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }

  createMentionsHistory(mentionedUsers, data, noteObject) {
    let requisitionTitle = document.title;
    mentionedUsers.forEach((user) => {
      const historyInput = {
        type: ActivityType.Mention,
        note: data.title,
        reqId: data.reqId,
        userId: user.id,
        isPublic: data.isPublic,
        analytics: JSON.stringify({
          noteId: data.id,
          requisition: requisitionTitle,
        }),
      };
      this.todoService.addHistory(historyInput).subscribe(
        (data) => {
          this.sendMentionEmailNotification(historyInput, user, noteObject);
        },
        (err) => console.log('Error creating mention history --- ', err)
      );
    });
  }

  sendMentionEmailNotification(mention, user, noteObject) {
    let requisitionTitle = document.title;
    const url = window.location.href;
    let userEmail = '';
    const [emailOne, emailTwo] = user.emails;
    userEmail = emailTwo ? emailTwo.address : emailOne.address;

    let toEmail: ToEmail = {
      email: userEmail,
    };

    let personalizations = {
      to: [toEmail],
      dynamic_template_data: {
        url: url,
        mentionedUser: {
          firstName: this.currentUser.firstName,
          lastName: this.currentUser.lastName,
        },
        contact: this.contact
          ? {
              firstName: this.contact.firstName,
              lastName: this.contact.lastName,
            }
          : null,
        requisition: this.requisition?.title,
        currentUser: {
          firstName: this.currentUser.firstName,
          lastName: this.currentUser.lastName,
        },
        note: mention.note,
      },
    };

    this.sendgridService
      .sendSendgridTemplateEmail('user_mention', personalizations)
      .pipe(take(1))
      .subscribe(
        (result) => {},
        (error) => {
          console.log('onMentionedNote : Error sending notification email ', error);
        }
      );
  }

  handleClosed(user) {
    if (!this.mentionedUsers.some((a) => a.id === user.id)) this.mentionedUsers.push(user);
  }

  checkNoteLength(input) {
    if (input.length >= 5) {
      this.validNoteLength = true;
    } else {
      this.validNoteLength = false;
    }
  }

  //checks to see if a mention has been removed from the note. will remove user from mentioned if so
  cleanupMentionedUsers({ note }) {
    const users = this.mentionedUsers.filter((a) => note.toLowerCase().includes(a.name.toLowerCase()));
    return users;
  }
}
