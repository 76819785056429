export const tenantTypeInfo = {
  healthcare: {
    key: 'healthcare',
    displayName: 'Healthcare',
    icon: 'pharmacy-cross-circle',
  },
  mortgage: {
    key: 'mortgage',
    displayName: 'Mortgage',
    icon: 'home-alt-slim-horiz',
  },
} as const;

export type TenantType = keyof typeof tenantTypeInfo;

export const tenantTypes = Object.keys(tenantTypeInfo);

export const getTenantTypeDisplayNames = () => {
  return Object.values(tenantTypeInfo).map((type) => type.displayName);
};

export function getTenantTypeInfo(typeName: TenantType) {
  return tenantTypeInfo[typeName];
}

export const defaultTenantType = tenantTypeInfo['mortgage'];

export function getTenantTypeFromDisplayName(displayName: string) {
  let foundTenantTypeKey = tenantTypes.find((key) => tenantTypeInfo[key].displayName === displayName);
  return tenantTypeInfo[foundTenantTypeKey];
}
