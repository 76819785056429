<h1 mat-dialog-title>Columns</h1>
<mat-dialog-content>
  <form>
    <div *ngFor="let column of columns">
      <mat-checkbox
        [checked]="column.isDisplayed"
        aria-label="Select Column"
        (change)="toggleColumnSelection(column)"
        >{{ column.colName }}</mat-checkbox
      >
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="warn" (click)="onCloseClick()">Close</button>
  <button mat-button color="primary" (click)="onApplyClick()">Apply</button>
</mat-dialog-actions>
