import { Component, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Apollo, gql } from 'apollo-angular';

import {
  startClick2Call,
  provisionNumber,
  removeNumber,
  sendSMS,
  deleteMMSMedia,
  buildMMSMediaStorageLocation,
  setLiveCounter,
  resizeMMSMediaImages,
} from '../graphql/mutations';
import {
  listAvailableNumbers,
  readNumber,
  listMessageConversationalHistory,
  searchMessageConversationalHistory,
  getMMSMedia,
  getMMSMediaUrl,
  getLiveCounter,
} from '../graphql/queries';

import { ContactInput } from '../shared/model/contacts';
import { DeleteMMSMedia, BuildMMSMediaStorageLocation, GetMMSMediaUrl, GetMMSMedia } from '../graphql/types';
import { MMSMedia } from '../shared/model/user';
export interface IntegrationKey {
  apiKey: string;
  iName: string;
  createdBy?: string;
  createdDate?: string;
  modifiedDate?: string;
}

@Injectable()
export class SMSService {
  constructor(
    private apollo: Apollo,
    private httpClient: HttpClient
  ) {}

  // Validate
  listAvailableNumbers(total: string, npa: string, npx: string): Observable<any> {
    return this.apollo.watchQuery<boolean>({
      query: gql(listAvailableNumbers),
      variables: { total: total, npa: npa, npx: npx },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  readNumber(number: string): Observable<any> {
    return this.apollo.watchQuery<string>({
      query: gql(readNumber),
      variables: { number: number },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public provisionNumber(number: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(provisionNumber),
      variables: {
        number: number,
      },
    });
  }

  public removeNumber(number: string, numberSid: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(removeNumber),
      variables: {
        number: number,
        numberSid: numberSid,
      },
    });
  }

  public sendSMS(
    to: string,
    from: string,
    body: string,
    contact: ContactInput,
    controlPoint: string,
    position: string,
    sessionId: string,
    arrMMSMedia: MMSMedia[],
    reqId: string,
    tenantId: string,
    sequenceId: string
  ): Observable<any> {
    delete contact?.__typename;

    return this.apollo.mutate({
      mutation: gql(sendSMS),
      variables: {
        to: to,
        from: from,
        body: body,
        contact: contact,
        controlPoint: controlPoint,
        position: position,
        sessionId: sessionId,
        arrMMSMedia: arrMMSMedia,
        reqId: reqId,
        tenantId: tenantId,
        sequenceId: sequenceId,
      },
    });
  }

  listMessageConversationalHistory(
    to: string,
    from: string,
    size: string,
    start: string,
    direction: string
  ): Observable<any> {
    console.log(to);
    return this.apollo.watchQuery<string>({
      query: gql(listMessageConversationalHistory),
      variables: {
        to: to,
        from: from,
        size: size,
        start: start,
        direction: direction,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  searchMessageConversationalHistory(
    searchPhrase: string,
    businessAddress: string,
    conversationAddress: string,
    size: string,
    start: string,
    direction: string
  ): Observable<any> {
    return this.apollo.watchQuery<string>({
      query: gql(searchMessageConversationalHistory),
      variables: {
        searchPhrase: searchPhrase,
        businessAddress: businessAddress,
        conversationAddress: conversationAddress,
        size: size,
        start: start,
        direction: direction,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getMMSMedia(): Observable<any> {
    return this.apollo.watchQuery<GetMMSMedia>({
      query: gql(getMMSMedia),
      variables: {},
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public GetMMSMediaUrl(mmsMediaDocId: string, thumb: string = ''): Observable<any> {
    return this.apollo.watchQuery<GetMMSMediaUrl>({
      query: gql(getMMSMediaUrl),
      variables: { mmsMediaDocId: mmsMediaDocId, thumb: thumb },
    }).valueChanges;
  }

  public deleteMMSMedia(mmsMediaDocId: string): Observable<any> {
    return this.apollo.mutate<DeleteMMSMedia>({
      mutation: gql(deleteMMSMedia),
      variables: { mmsMediaDocId: mmsMediaDocId },
      update: (store, { data: { deleteMMSMedia } }) => {
        if (!deleteMMSMedia) {
          return;
        }
      },
    });
  }

  public buildMMSMediaStorageLocation(
    file: File,
    fileName: string,
    fileType: string,
    mmsMediaDocId: string
  ): Observable<any> {
    return this.apollo.mutate<BuildMMSMediaStorageLocation>({
      mutation: gql(buildMMSMediaStorageLocation),
      variables: {
        fileName: fileName,
        fileType: fileType,
        mmsMediaDocId: mmsMediaDocId,
      },
      update: (store, { data: { buildMMSMediaStorageLocation } }) => {
        console.log('begin buildMMSMediaStorageLocation', buildMMSMediaStorageLocation);

        if (!buildMMSMediaStorageLocation) {
          return;
        }
      },
    });
  }

  uploadFileToS3(file: File, fileName: string, fileType: string, mmsMedia: MMSMedia): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers.set('Content-Type', file.type);

    console.log('uploadFileToS3', fileName, fileType, mmsMedia);

    return this.httpClient.put(mmsMedia.uploadUrl, file, {
      headers: headers,
    });
  }

  resizeMMSMediaImages(mmsMediaDoc: MMSMedia): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(resizeMMSMediaImages),
      variables: {
        mmsMediaDoc: mmsMediaDoc,
      },
    });
  }

  getLiveCounter(placeholder: string): Observable<any> {
    return this.apollo.watchQuery<string>({
      query: gql(getLiveCounter),
      variables: { placeholder: placeholder },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  setLiveCounter(newValue: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql(setLiveCounter),
      variables: {
        newValue: newValue,
      },
    });
  }
}
