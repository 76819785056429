import { Component, Inject, EventEmitter, Output, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  PlaybookTask,
  PlaybooksReqContact,
  PlaybookNoteType,
  newPlaybookNoteTemplate,
} from 'app/shared/model/playbook';
import { MatRadioChange } from '@angular/material/radio';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { PlaybookService } from '../../../services/playbook.service';
import { v4 as uuid } from 'uuid';
import { AlertDialogComponent, AlertDialogConfig } from '../../../shared/dialogs/alert-dialog.component';
import { Observable } from 'rxjs';
import { take, startWith, map } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NoteInput, ActivityType } from '../../../shared/model/activity';
import { TodoService } from '../../../services/todo.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../../../shared/model/user';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';

export interface DialogData {
  taskRC: PlaybookTask;
  task: PlaybookTask;
  catId: string;
  playbookReqContactId: string;
  userInfo: any;
  stage: string;
  reqId: string;
  contactId: string;
  stageEnteredDate: string;
  playbookTemplateId: string;
  reqTenantId: string;
  reqUsers: User[];
}

@Component({
  selector: 'task-edit-dialog',
  templateUrl: 'task-edit-dialog.component.html',
  styleUrls: ['./task-edit-dialog.component.scss'],
})
export class TaskEditDialog implements OnInit {
  @Output() taskChanged: EventEmitter<any> = new EventEmitter();

  activityDefaultControl: FormControl;
  playbookReqContact: PlaybooksReqContact = null;
  triggerClose: boolean = false;
  triggerEditOpen: boolean = false;
  catIndex: number = -1;
  stageIndex: number = -1;

  triggerCloseByIndex: boolean[] = [];
  triggerEditOpenByIndex: boolean[] = [];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  // Reactive forms array of inplace editables
  notesInplaceEditControls: FormArray;

  //reqUser selection
  reqUsersControl = new FormControl();
  filteredReqUsers: Observable<User[]>;
  selectedReqUsers: User[] = [];
  @ViewChild('reqUserInput') reqUserInput: ElementRef<HTMLInputElement>;
  selectableReqUser = true;
  removableReqUser = true;

  constructor(
    private playbookService: PlaybookService,
    private confirmDialog: MatDialog,
    private todoService: TodoService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<TaskEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    // edit in place stuff

    // Reset trigger to empty
    this.triggerCloseByIndex = [];
    this.triggerEditOpenByIndex = [];

    // Build an array of formcontrols - 1 for each note in the task
    if (this.data.taskRC.notes?.length) {
      const toNotesFormArray = this.data.taskRC.notes.map((note, arrIndex) => {
        this.triggerCloseByIndex.push(false);
        this.triggerEditOpenByIndex.push(false);

        return new FormGroup({
          content: new FormControl(note.content),

          // Optional other fields to allow edit
          // , other: new FormControl(entity.other)
        });
      });
      this.notesInplaceEditControls = new FormArray(toNotesFormArray);
    }

    // Separate control for the default add only note activity
    this.activityDefaultControl = new FormControl();

    this.dialogRef.updateSize('80%', '80%');

    // Self repair
    if (!this.data.taskRC.assignedTo) this.data.taskRC.assignedTo = [];

    console.log('this.data.reqUsers', this.data.reqUsers);

    this.filteredReqUsers = this.reqUsersControl.valueChanges.pipe(
      startWith<string | User>(''),
      map((value) => {
        console.log('filteredreqUser map value ', value);
        return typeof value === 'string' ? value : value?.firstName + ' ' + value?.lastName;
      }),
      map((name) => (name ? this._filterReqUsers(name) : this.data.reqUsers.slice()))
    );
  }

  // Contact tags display function - used in HTML [displayWith]="displayFnReqUsers"
  displayFnReqUsers(reqUser: User): string {
    return reqUser && reqUser.lastName ? reqUser.lastName : '';
  }

  // Method to filter contact tags autocomplete list based on what has been typed so far
  private _filterReqUsers(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.data.reqUsers.filter(
      (user) => (user.firstName + ' ' + user.lastName).toLowerCase().indexOf(filterValue) === 0
    );
  }

  // Event is fired whenever a new reqUser is selected
  selectedReqUser(event: MatAutocompleteSelectedEvent): void {
    // Only push to list if value exists AND is not already on list

    // Search for value in main list first
    const found = this.data.reqUsers.findIndex((element) => {
      return element.id === event.option.value.id;
    });

    // console.log("selected found", found);
    // console.log("selectedContactTag event.option.value)", event.option.value);
    // console.log("selectedContactTag filteredContactTags", this.filteredReqUsers);

    if (found > -1) {
      // Now Search for value in selected list
      const alreadySelected = this.data.taskRC.assignedTo.findIndex(
        (element) =>
          element.toLocaleLowerCase() ===
          (event.option.value.firstName + ' ' + event.option.value.lastName).toLowerCase()
      );

      if (alreadySelected < 0) {
        this.data.taskRC.assignedTo.push(event.option.value.firstName + ' ' + event.option.value.lastName);

        // Add member

        this.playbookService
          .readSinglePlaybookReqContact(
            this.data.reqTenantId,
            this.data.reqId,
            this.data.contactId,
            this.data.playbookTemplateId
          )
          .pipe(take(1))
          .subscribe(
            (result) => {
              this.playbookReqContact = result.data.readSinglePlaybookReqContact;

              this.catIndex = this.playbookReqContact.playbookObj.categories.findIndex(
                (thisCat) => thisCat.id === this.data.catId
              );

              this.stageIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages.findIndex(
                (thisStage) => thisStage.id === this.data.stage
              );

              const currTaskIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages[
                this.stageIndex
              ].tasks.findIndex((task) => task.id === this.data.taskRC.id);

              const currTask =
                this.playbookReqContact.playbookObj.categories[this.catIndex].stages[this.stageIndex].tasks[
                  currTaskIndex
                ];

              currTask.assignedTo = this.data.taskRC.assignedTo;

              // Save this playbook data
              this.playbookService
                .updatePlaybookReqContact(
                  this.data.reqId,
                  this.data.contactId,
                  this.data.playbookTemplateId,
                  this.playbookReqContact
                )
                .pipe(take(1))
                .subscribe(
                  (result) => {
                    // console.log("updatePlaybookReqContact: my playbookReqContact ", result.data.updatePlaybookTemplate);
                  },
                  (error) => {
                    console.log('updatePlaybookReqContact: There was an error updating playbookReqContact', error);
                  }
                );
            },
            (error) => {
              console.log('PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact error ', error);
            }
          );
      }
    }

    this.reqUserInput.nativeElement.value = '';
    this.reqUsersControl.setValue(null);
  }

  // Edit in place
  getInPlaceEditControl(index: number, field: string): FormControl {
    return this.notesInplaceEditControls.at(index).get(field) as FormControl;
  }

  updateInplaceEditor(index: number, field: string) {
    const control = this.getInPlaceEditControl(index, field);

    if (control.valid) {
      this.data.taskRC.notes = this.data.taskRC.notes.map((e, i) => {
        if (index === i) {
          return {
            ...e,
            [field]: control.value,
          };
        }
        return e;
      });
    }
  }

  // Adds a new notes
  addNote(saveNote: boolean, source: string) {
    if (this.activityDefaultControl.value && saveNote) {
      let now = new Date();

      // Add a new note
      // Define as CONST out here so it is still available even after the control is reset
      const newNote: PlaybookNoteType = JSON.parse(JSON.stringify(newPlaybookNoteTemplate));

      newNote.content = this.activityDefaultControl.value;
      newNote.id = uuid();
      newNote.by = this.data.userInfo.firstName + ' ' + this.data.userInfo.lastName;
      newNote.createdAt = now.toISOString();
      newNote.modifiedAt = now.toISOString();

      //  console.log("TaskEditDialog newNote ", newNote);

      this.playbookService
        .readSinglePlaybookReqContact(
          this.data.reqTenantId,
          this.data.reqId,
          this.data.contactId,
          this.data.playbookTemplateId
        )
        .pipe(take(1))
        .subscribe(
          (result) => {
            console.log('TaskEditDialog :: readSinglePlaybookReqContact result ', result.data);

            this.playbookReqContact = result.data.readSinglePlaybookReqContact;

            this.catIndex = this.playbookReqContact.playbookObj.categories.findIndex(
              (thisCat) => thisCat.id === this.data.catId
            );

            this.stageIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages.findIndex(
              (thisStage) => thisStage.id === this.data.stage
            );

            const currTaskIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages[
              this.stageIndex
            ].tasks.findIndex((task) => task.id === this.data.taskRC.id);

            const currTask =
              this.playbookReqContact.playbookObj.categories[this.catIndex].stages[this.stageIndex].tasks[
                currTaskIndex
              ];

            if (currTask.notes) {
              // Add new note to the array
              currTask.notes.unshift(newNote);
              this.data.taskRC.notes.unshift(newNote);
            } else {
              // Start a new array with new note added to the array
              const newNotes = [];
              newNotes.push(newNote);
              currTask.notes = newNotes;
              this.data.taskRC.notes = newNotes;
            }

            this.dialogNotesArrayChanged();

            //  console.log("TaskEditDialog updatePlaybookReqContact ", this.playbookReqContact);

            // Save this playbook data
            this.playbookService
              .updatePlaybookReqContact(
                this.data.reqId,
                this.data.contactId,
                this.data.playbookTemplateId,
                this.playbookReqContact
              )
              .pipe(take(1))
              .subscribe(
                (result) => {
                  // console.log("updatePlaybookReqContact: my playbookReqContact ", result.data.updatePlaybookTemplate);
                },
                (error) => {
                  console.log('updatePlaybookReqContact: There was an error updating playbookReqContact', error);
                }
              );
          },
          (error) => {
            console.log('PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact error ', error);
          }
        );

      // this.isPublicNote

      let value = this.data.taskRC.title + ': ' + this.activityDefaultControl.value;
      let input: NoteInput = {
        note: value,
        contactId: this.data.contactId,
        reqId: this.data.reqId,
        isPublic: true,
      };

      // console.log("Playbook Item Edit Component :: addNote ", input);
      this.todoService.addNote(input).subscribe(
        (result) => {
          // console.log("Playbook Item Edit Component :: addNote result ", result.data.addNote);

          this.snackBar.open('Added Note.', null, {
            duration: 3000,
          });
        },
        (error) => {
          // console.log("Playbook Item Edit Component :: addNote error ", error);
          this.snackBar.open('An error occurred adding the note.', null, {
            duration: 3000,
          });
        }
      );
    }

    // Reset this.activityDefaultControl.value
    this.activityDefaultControl.setValue(null);

    if (source != 'update') this.triggerClose = !this.triggerClose;
  }

  onDeleteNote(event: any, noteId: string) {
    this.confirmDiscardNote().subscribe(
      (discardNoteConfirmed) => {
        if (discardNoteConfirmed) {
          this.playbookService
            .readSinglePlaybookReqContact(
              this.data.reqTenantId,
              this.data.reqId,
              this.data.contactId,
              this.data.playbookTemplateId
            )
            .pipe(take(1))
            .subscribe(
              (result) => {
                this.playbookReqContact = result.data.readSinglePlaybookReqContact;

                this.catIndex = this.playbookReqContact.playbookObj.categories.findIndex(
                  (thisCat) => thisCat.id === this.data.catId
                );

                this.stageIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages.findIndex(
                  (thisStage) => thisStage.id === this.data.stage
                );

                const currTaskIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages[
                  this.stageIndex
                ].tasks.findIndex((task) => task.id === this.data.taskRC.id);

                const currTask =
                  this.playbookReqContact.playbookObj.categories[this.catIndex].stages[this.stageIndex].tasks[
                    currTaskIndex
                  ];

                let spliceIndex = currTask.notes.findIndex(function (i) {
                  return i.id === noteId;
                });

                if (spliceIndex > -1) currTask.notes.splice(spliceIndex, 1);

                spliceIndex = this.data.taskRC.notes.findIndex(function (i) {
                  return i.id === noteId;
                });

                if (spliceIndex > -1) this.data.taskRC.notes.splice(spliceIndex, 1);

                // Save this playbook data
                this.playbookService
                  .updatePlaybookReqContact(
                    this.data.reqId,
                    this.data.contactId,
                    this.data.playbookTemplateId,
                    this.playbookReqContact
                  )
                  .pipe(take(1))
                  .subscribe(
                    (result) => {
                      this.dialogNotesArrayChanged();

                      this.taskChanged.emit({
                        playbookReqContactId: this.playbookReqContact.id,
                        playbookTemplateId: this.playbookReqContact.playbookObj.id,
                        catId: this.data.catId,
                        taskId: this.data.taskRC.id,
                      });
                    },
                    (error) => {
                      console.log('updatePlaybookReqContact: There was an error updating playbookReqContact', error);
                    }
                  );
              },
              (error) => {
                console.log('PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact error ', error);
              }
            );
        }
      },
      (error) => {
        console.log('TaskEditDialog Component - confirmDiscardNote error: ', error);
      }
    );

    //});

    // this.taskChanged.emit({
    //   playbookReqContactId: this.playbookReqContact.id,
    //   playbookTemplateId: this.playbookReqContact.playbookObj.id,
    //   catId: this.data.catId,
    //   taskId: this.data.taskRC.id
    // });
  }

  getShortName(fullName) {
    const retName = fullName
      ? fullName
          .split(' ')
          .map((n) => n[0])
          .join('')
      : 'Unk';
    return retName;
  }

  getFullName(fullName) {
    const retName = fullName ? fullName : 'Unknown Contributor';

    return retName;
  }

  onNoClick(): void {
    this.dialogRef.close({ data: 'data' });
  }

  editNote(event: any) {
    event.stopPropagation();

    this.triggerEditOpen = !this.triggerEditOpen;

    return true;
  }

  editNoteByIndex(event: any, noteIndex: number, id: string, source: string) {
    event.stopPropagation();

    this.triggerEditOpenByIndex[noteIndex] = !this.triggerEditOpenByIndex[noteIndex];

    return true;
  }

  saveNoteByIndex(event: any, saveNote: boolean, noteIndex: number, noteId: string, source: string) {
    if (event) event.stopPropagation();

    // Get control from passed in index
    const control = this.getInPlaceEditControl(noteIndex, 'content');

    if (control.value && saveNote) {
      // if control.value is nothing - delete? empty place holder?

      let now = new Date();
      const currentContentValue = control.value;

      this.playbookService
        .readSinglePlaybookReqContact(
          this.data.reqTenantId,
          this.data.reqId,
          this.data.contactId,
          this.data.playbookTemplateId
        )
        .pipe(take(1))
        .subscribe(
          (result) => {
            this.playbookReqContact = result.data.readSinglePlaybookReqContact;

            this.catIndex = this.playbookReqContact.playbookObj.categories.findIndex(
              (thisCat) => thisCat.id === this.data.catId
            );

            this.stageIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages.findIndex(
              (thisStage) => thisStage.id === this.data.stage
            );

            const currTaskIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages[
              this.stageIndex
            ].tasks.findIndex((task) => task.id === this.data.taskRC.id);

            const currTask =
              this.playbookReqContact.playbookObj.categories[this.catIndex].stages[this.stageIndex].tasks[
                currTaskIndex
              ];

            let searchIndex = currTask.notes.findIndex(function (i) {
              return i.id === noteId;
            });

            if (searchIndex > -1) {
              currTask.notes[searchIndex].content = currentContentValue;
              currTask.notes[searchIndex].modifiedAt = now.toISOString();
              currTask.notes[searchIndex].by = this.data.userInfo.firstName + ' ' + this.data.userInfo.lastName;
            }

            searchIndex = this.data.taskRC.notes.findIndex(function (i) {
              return i.id === noteId;
            });

            if (searchIndex > -1) {
              this.data.taskRC.notes[searchIndex].content = currentContentValue;
              this.data.taskRC.notes[searchIndex].modifiedAt = now.toISOString();
              this.data.taskRC.notes[searchIndex].by = this.data.userInfo.firstName + ' ' + this.data.userInfo.lastName;
            }

            // Save this playbook data
            this.playbookService
              .updatePlaybookReqContact(
                this.data.reqId,
                this.data.contactId,
                this.data.playbookTemplateId,
                this.playbookReqContact
              )
              .pipe(take(1))
              .subscribe(
                (result) => {
                  // console.log("updatePlaybookReqContact: my playbookReqContact ", result.data.updatePlaybookTemplate);

                  this.taskChanged.emit({
                    playbookReqContactId: this.playbookReqContact.id,
                    playbookTemplateId: this.playbookReqContact.playbookObj.id,
                    catId: this.data.catId,
                    taskId: this.data.taskRC.id,
                  });
                },
                (error) => {
                  console.log('updatePlaybookReqContact: There was an error updating playbookReqContact', error);
                }
              );
          },
          (error) => {
            console.log('PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact error ', error);
          }
        );
    }

    if (source !== 'update')
      // Edit Inplace component will detect clicks outside of editable area - forces close - no need to trigger close too
      this.triggerCloseByIndex[noteIndex] = !this.triggerCloseByIndex[noteIndex];

    return true;
  }

  radioChange(event: MatRadioChange) {
    this.playbookService
      .readSinglePlaybookReqContact(
        this.data.reqTenantId,
        this.data.reqId,
        this.data.contactId,
        this.data.playbookTemplateId
      )
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.playbookReqContact = result.data.readSinglePlaybookReqContact;

          this.catIndex = this.playbookReqContact.playbookObj.categories.findIndex(
            (thisCat) => thisCat.id === this.data.catId
          );

          this.stageIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages.findIndex(
            (thisStage) => thisStage.id === this.data.stage
          );

          const currTaskIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages[
            this.stageIndex
          ].tasks.findIndex((task) => task.id === this.data.taskRC.id);

          const currTask =
            this.playbookReqContact.playbookObj.categories[this.catIndex].stages[this.stageIndex].tasks[currTaskIndex];

          currTask.status = event.value;

          // Save this playbook data
          this.playbookService
            .updatePlaybookReqContact(
              this.data.reqId,
              this.data.contactId,
              this.data.playbookTemplateId,
              this.playbookReqContact
            )
            .pipe(take(1))
            .subscribe(
              (result) => {
                // console.log("updatePlaybookReqContact: my playbookReqContact ", result.data.updatePlaybookTemplate);

                this.taskChanged.emit({
                  playbookReqContactId: this.playbookReqContact.id,
                  playbookTemplateId: this.playbookReqContact.playbookObj.id,
                  catId: this.data.catId,
                  taskId: this.data.taskRC.id,
                });
              },
              (error) => {
                console.log('updatePlaybookReqContact: There was an error updating playbookReqContact', error);
              }
            );
        },
        (error) => {
          console.log('PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact error ', error);
        }
      );
  }

  dialogNotesArrayChanged() {
    // Build an array of formcontrols - 1 for each note in the task
    if (this.data.taskRC.notes?.length) {
      const toNotesFormArray = this.data.taskRC.notes.map((note, arrIndex) => {
        this.triggerCloseByIndex.push(false);
        this.triggerEditOpenByIndex.push(false);

        return new FormGroup({
          content: new FormControl(note.content),

          // Optional other fields to allow edit
          // , other: new FormControl(entity.other)
        });
      });
      this.notesInplaceEditControls = new FormArray(toNotesFormArray);
    }
  }

  confirmDiscardNote(): Observable<boolean> {
    const dialogConfig: AlertDialogConfig = {
      title: 'Delete this comment?',
      message: 'Warning: This change can NOT be undone!',
      confirmButton: {
        label: 'Delete, yes?',
        color: 'warn',
      },
    };

    const dialogRef = this.confirmDialog.open<AlertDialogComponent, AlertDialogConfig, boolean>(AlertDialogComponent, {
      panelClass: 'alert-dialog-component',
      data: dialogConfig,
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  // Only used if allowed to add member who is NOT on the list already
  addMember(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    console.log('TaskEditDialog add Member event ', event);

    // Add member
    if ((value || '').trim()) {
      this.data.taskRC.assignedTo.push(value);

      this.playbookService
        .readSinglePlaybookReqContact(
          this.data.reqTenantId,
          this.data.reqId,
          this.data.contactId,
          this.data.playbookTemplateId
        )
        .pipe(take(1))
        .subscribe(
          (result) => {
            this.playbookReqContact = result.data.readSinglePlaybookReqContact;

            this.catIndex = this.playbookReqContact.playbookObj.categories.findIndex(
              (thisCat) => thisCat.id === this.data.catId
            );

            this.stageIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages.findIndex(
              (thisStage) => thisStage.id === this.data.stage
            );

            const currTaskIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages[
              this.stageIndex
            ].tasks.findIndex((task) => task.id === this.data.taskRC.id);

            const currTask =
              this.playbookReqContact.playbookObj.categories[this.catIndex].stages[this.stageIndex].tasks[
                currTaskIndex
              ];

            currTask.assignedTo = this.data.taskRC.assignedTo;

            // Save this playbook data
            this.playbookService
              .updatePlaybookReqContact(
                this.data.reqId,
                this.data.contactId,
                this.data.playbookTemplateId,
                this.playbookReqContact
              )
              .pipe(take(1))
              .subscribe(
                (result) => {
                  // console.log("updatePlaybookReqContact: my playbookReqContact ", result.data.updatePlaybookTemplate);
                },
                (error) => {
                  console.log('updatePlaybookReqContact: There was an error updating playbookReqContact', error);
                }
              );
          },
          (error) => {
            console.log('PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact error ', error);
          }
        );
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeMember(member: string): void {
    const index = this.data.taskRC.assignedTo.indexOf(member);

    if (index >= 0) {
      this.data.taskRC.assignedTo.splice(index, 1);

      this.playbookService
        .readSinglePlaybookReqContact(
          this.data.reqTenantId,
          this.data.reqId,
          this.data.contactId,
          this.data.playbookTemplateId
        )
        .pipe(take(1))
        .subscribe(
          (result) => {
            this.playbookReqContact = result.data.readSinglePlaybookReqContact;

            this.catIndex = this.playbookReqContact.playbookObj.categories.findIndex(
              (thisCat) => thisCat.id === this.data.catId
            );

            this.stageIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages.findIndex(
              (thisStage) => thisStage.id === this.data.stage
            );

            const currTaskIndex = this.playbookReqContact.playbookObj.categories[this.catIndex].stages[
              this.stageIndex
            ].tasks.findIndex((task) => task.id === this.data.taskRC.id);

            const currTask =
              this.playbookReqContact.playbookObj.categories[this.catIndex].stages[this.stageIndex].tasks[
                currTaskIndex
              ];

            currTask.assignedTo = this.data.taskRC.assignedTo;

            // Save this playbook data
            this.playbookService
              .updatePlaybookReqContact(
                this.data.reqId,
                this.data.contactId,
                this.data.playbookTemplateId,
                this.playbookReqContact
              )
              .pipe(take(1))
              .subscribe(
                (result) => {
                  // console.log("updatePlaybookReqContact: my playbookReqContact ", result.data.updatePlaybookTemplate);
                },
                (error) => {
                  console.log('updatePlaybookReqContact: There was an error updating playbookReqContact', error);
                }
              );
          },
          (error) => {
            console.log('PlaybookCatSelectTabsComponent :: readSinglePlaybookReqContact error ', error);
          }
        );
    }
  }
}
