import { Component, Inject, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ReportObj,
  ReportTemplate,
  ReportTemplateSettings,
  esFilter,
  ReportObjMenuItem,
} from '../../../shared/model/reporting';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ReportingService } from '../../../services/reporting.service';
import { UserService } from '../../../services/user.service';
import { PermissionsService, Permissions } from 'app/services/permissions.service';
import { getPermissionValue } from '../../../../../../app/src/model/permissions/permissions';
@Component({
  selector: 'mm-report-picker-dialog',
  templateUrl: './report-picker-dialog.component.html',
  styleUrls: ['./report-picker-dialog.component.scss'],
})
export class ReportPickerDialogComponent implements OnInit, OnDestroy {
  userIA: Permissions;
  private unsubscribe$ = new Subject<void>();
  menuItems: ReportObjMenuItem[] = [];
  searchReqsControl = new FormControl();
  filteredMenuItems: Observable<ReportObjMenuItem[]>;
  selectedMenuItems: ReportObjMenuItem[];
  @ViewChild('searchObjListInput')
  searchObjListInput: ElementRef<HTMLInputElement>;

  constructor(
    private dialogRef: MatDialogRef<ReportPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private snackBar: MatSnackBar,
    private reportingService: ReportingService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.getPermissions();
  }

  initAfterPermissions() {
    // Build Menu Items - load from external list
    this.reportingService
      .listReportObjects(JSON.stringify({ active: true }), null)
      .pipe(take(1))
      .subscribe(
        (resultList) => {
          console.log('ReportObjectsManagerComponent listReportObjects resultList', resultList);

          const clone = JSON.parse(resultList.data.listReportObjects);
          clone.sort((a, b) => (a.order > b.order ? 1 : -1));

          clone.forEach((obj) => {
            if (obj.permission) {
              console.log('obj.permission', obj.permission);

              let keyReducer = obj.permission.split('.') as string[];
              let permissionValue = getPermissionValue(keyReducer, this.userIA);

              // We have a report that is using the old permissions OR it was mistyped/
              if (permissionValue === undefined) {
                console.error(`Report has a misconfigured permission. ${obj.name} : ${obj.permission}`);
                return false;
              }

              // You don't have access to this report
              if (permissionValue === false) {
                return;
              }
            }

            this.menuItems.push({
              name: obj.name,
              desc: obj.desc,
              rType: obj.rType,
              cols: obj.cols ? parseInt(obj.cols) : 2,
              rows: obj.rows ? parseInt(obj.rows) : 2,
              ribbon: obj.ribbon,
              data: obj.data,
              icon: obj.icon,
              esFilters: obj.esFilters,
            });
          });

          // Don't set filter until data is loaded
          this.filteredMenuItems = this.searchReqsControl.valueChanges.pipe(
            startWith<string | ReportObjMenuItem>(''),
            map((value) => {
              // console.log("filteredMenuItems map value ", value);
              return value;
            }),
            // If name is "" or undefined - then return shallow copy of entire array
            map((name: string) => {
              let retVal = name ? this._filterMenuItems(name) : this.menuItems.slice();
              // console.log("retVal map value ", name, retVal, this.reqs);
              return retVal;
            })
          );

          // Let UI know data loading is complete
          // this.dataLoaded = true;
        },
        (error) => {
          console.log('ReportObjectsManagerComponent: There was an error loading reportObjects ', error);

          // Even though error still let ui data is finished loading
          // this.dataLoaded = true;
        }
      );
  }

  // Method to filter users list based on what has been typed so far in search box
  private _filterMenuItems(name: string): ReportObjMenuItem[] {
    const filterValue = name.toLowerCase();

    let menuItemsBuff = JSON.parse(JSON.stringify(this.menuItems));

    return menuItemsBuff.filter(
      (currObj) =>
        currObj?.name?.toLowerCase().indexOf(filterValue) >= 0 || currObj?.desc?.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  clearAll() {
    let data = { reportObjMenuItem: null, clear: true };

    this.dialogRef.close({ data: data });
  }

  onClickAdd(reportObjMenuItem: ReportObjMenuItem) {
    // Pass back new user to assign todos data
    let data = { reportObjMenuItem: reportObjMenuItem };

    this.dialogRef.close({ data: data });
  }

  onCloseClick() {
    // Pass back new user to assign todos data
    let data = { reportObjMenuItem: null };

    this.dialogRef.close({ data: data });
  }

  filterBy(filterText: string) {
    // this.searchObjListInput.nativeElement.value = filterText;
    this.searchReqsControl.setValue(filterText);
  }

  getPermissions() {
    this.permissionsService.permissions$.pipe(takeUntil(this.unsubscribe$)).subscribe((userIA) => {
      console.log('permissions updated single req', userIA);
      this.userIA = userIA;

      this.initAfterPermissions();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
