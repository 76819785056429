import { Injectable } from '@angular/core';
import { CustomField } from 'graphql.types';

export type FilterType =
  | 'exact'
  | 'is'
  | 'is not'
  | 'advanced'
  | 'after'
  | 'before'
  | 'between'
  | 'contains'
  | 'actions'
  | 'next'
  | 'past'
  | 'upcoming'
  | 'unassigned';

export type Filter = {
  displayName: string;
  key: string;
  type: FilterType;
  value: string | number | string[];
  customField?: CustomField;
};

export type SanitizedFilter = {
  options: any;
  query: string;
  strict: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public filters: Filter[];
  public sanitizedFilters: SanitizedFilter[] = [];
  private debugDisplay: boolean = true;
  private fields: string[] = [
    'firstName',
    'lastName',
    'emails.address',
    'phones.number',
    'company',
    'title',
    'address.city',
    'address.state',
    'stage',
    'reqName',
    'source',
    'tags.name',
  ];

  constructor() {}

  // isFilterTypeInFilter (typeArray: FilterType[], type: FilterType): Boolean {

  // }

  getAvatarIdColor(lastName) {
    if (lastName === 'prospects')
      return {
        bg: 'bg-cyan-600',
        text: 'text-cyan-100',
      };
    if (lastName === 'prospects')
      return {
        bg: 'bg-purple-600',
        text: 'text-purple-100',
      };
    if (lastName === 'hires')
      return {
        bg: 'bg-green-600',
        text: 'text-green-100',
      };
    if (lastName === 'offers')
      return {
        bg: 'bg-red-600',
        text: 'text-red-100',
      };
    if (lastName === 'disqualifed')
      return {
        bg: 'bg-orange-600',
        text: 'text-orange-100',
      };
    if (lastName === 'candidates')
      return {
        bg: 'bg-blue-600',
        text: 'text-blue-100',
      };

    const c = lastName[0].toUpperCase();
    const q = (l) => c === l;
    if (q('A') || q('F') || q('K') || q('P') || q('U') || q('Z')) {
      return {
        bg: 'bg-blue-600',
        text: 'text-blue-100',
      };
    } else if (q('B') || q('G') || q('L') || q('Q') || q('V')) {
      return {
        bg: 'bg-lime-600',
        text: 'text-lime-100',
      };
    } else if (q('C') || q('H') || q('M') || q('R') || q('W')) {
      return {
        bg: 'bg-purple-600',
        text: 'text-purple-100',
      };
    } else if (q('D') || q('I') || q('N') || q('S') || q('X')) {
      return {
        bg: 'bg-red-600',
        text: 'text-red-100',
      };
    } else if (q('E') || q('J') || q('O') || q('T') || q('Z')) {
      return {
        bg: 'bg-green-600',
        text: 'text-green-100',
      };
    } else {
      return {
        bg: 'bg-orange-600',
        text: 'text-orange-100',
      };
    }
  }

  getDaysInStageColor(days) {
    if (days < 5) {
      return {
        bg: 'bg-green-600',
        text: 'text-green-100',
      };
    } else if (days > 4 && days < 10) {
      return {
        bg: 'bg-orange-600',
        text: 'text-orange-100',
      };
    } else {
      return {
        bg: 'bg-red-600',
        text: 'text-red-100',
      };
    }
  }

  private _sanitizeDateRangeFilter(filter: Filter): void {
    const { key, type, value } = filter;
    this.sanitizedFilters.push({
      query: (() => {
        if (type === 'after') return `[${value} TO *]`;
        else if (type === 'before') return `[* TO ${value}]`;
        else if (type === 'between') return `[${value[0]} TO ${value[1]}]`;
        else throw new Error('Invalid date range filter type');
      })(),
      options: { default_field: key },
      strict: true,
    });
  }

  private _sanitizeCustomFieldsFilter(filter: Filter): void {
    const { key, type, value } = filter;

    this.sanitizedFilters.push({
      query: (() => {
        if (type === 'is') {
          return `(customFields.value.keyword:"${value}" AND customFields.fieldId:"${key}")`;
        } else if (type === 'is not') {
          return `-(customFields.value.keyword:"${value}" AND customFields.fieldId:"${key}")`;
        } else if (type === 'contains') {
          return `(customFields.value:*${value}* AND customFields.fieldId:"${key}")`;
        }
      })(),
      options: {
        fields: ['customFields.value', 'customFields.fieldId'],
      },
      strict: true,
    });
  }

  private _sanitizeNameFilter(filter: Filter, prefix?: string): void {
    const { key, type, value } = filter;
    if (typeof value !== 'string') throw new Error('Invalid name filter value');
    if (key === 'name') {
      const name = value.split(' ');
      this.sanitizedFilters.push({
        query: (() => {
          if (type === 'is') {
            return `${
              name.length > 1
                ? `(${prefix ? prefix + '.' : ''}firstName:${name[0]}) 
                AND 
                (${prefix ? prefix + '.' : ''}lastName:${name[1]})`
                : `(${prefix ? prefix + '.' : ''}firstName:${name[0]}) 
                OR 
                (${prefix ? prefix + '.' : ''}lastName:${name[0]})`
            }`;
          } else if (type === 'is not') {
            return `${
              name.length > 1
                ? `-((${prefix ? prefix + '.' : ''}firstName:${name[0]}) 
                AND 
                (${prefix ? prefix + '.' : ''}lastName:${name[1]}))`
                : `-((${prefix ? prefix + '.' : ''}firstName:${name[0]})) 
                OR 
                -((${prefix ? prefix + '.' : ''}lastName:${name[0]}))`
            }`;
          } else if (type === 'contains') {
            return `${
              name.length > 1
                ? `(${prefix ? prefix + '.' : ''}firstName:*${name[0]}*) 
                AND 
                (${prefix ? prefix + '.' : ''}lastName:*${name[1]}*)`
                : `(${prefix ? prefix + '.' : ''}firstName:*${name[0]}*) 
                OR 
                (${prefix ? prefix + '.' : ''}lastName:*${name[0]}*)`
            }`;
          } else throw new Error('Invalid name filter type');
        })(),
        options: {
          fields: [`${prefix ? prefix + '.' : ''}firstName`, `${prefix ? prefix + '.' : ''}lastName`],
        },
        strict: true,
      });
    } else if (key === 'assignedUser' && type !== 'unassigned') {
      const name = value.split(' ');
      this.sanitizedFilters.push({
        query: (() => {
          if (type === 'is') {
            return `(${prefix + '.'}firstName:${name[0]} AND ${prefix + '.'}lastName:${name[1]})`;
          } else if (type === 'is not') {
            return `-((${prefix + '.'}firstName:${name[0]} AND ${prefix + '.'}lastName:${name[1]}))`;
          } else throw new Error('Invalid name filter type');
        })(),
        options: {
          fields: [`${prefix + '.'}firstName`, `${prefix + '.'}lastName`],
        },
        strict: true,
      });
    } else if (key === 'assignedUser' && type === 'unassigned') {
      this.sanitizedFilters.push({
        query: (() => {
          return `-(_exists_:${key})`;
        })(),
        options: {
          fields: `${key}`,
        },
        strict: true,
      });
    }
  }

  private _sanitzedContactedFilter(filter: Filter): void {
    const { key, type, value } = filter;
    this.sanitizedFilters.push({
      query: value ? this._filterToQueryString(value as string, type) : '(-(_exists_:contacted))',
      options: { default_field: key },
      strict: true,
    });
  }

  private _sanitizeAdvancedFilter(filter: Filter): void {
    const advancedCharacters = [
      '+',
      '-',
      '&&',
      '||',
      '!',
      '(',
      ')',
      '{',
      '}',
      '[',
      ']',
      '^',
      '"',
      '~',
      '*',
      '?',
      ':',
      '\\',
    ];
    if (advancedCharacters.some((v) => (filter.value as string).includes(v))) this._sanitizeQueryStringFilters(filter);
    else this._sanitizeSimpleQueryStringFilters(filter);
  }

  private _sanitizeQueryStringFilters(filter: Filter): void {
    this.sanitizedFilters.push({
      query: filter.value as string,
      options: { fields: this.fields },
      strict: true,
    });
  }

  private _sanitizeSimpleQueryStringFilters(filter: Filter): void {
    const { key, type, value } = filter;
    const words = (value as string).split(' ');
    if (words.length === 1) {
      this.sanitizedFilters.push({
        query: `((firstName:${words[0]}*) OR (lastName:${words[0]}*)) OR (${words.join(' ')}*)`,
        options: {
          fields: this.fields,
          default_operator: 'AND',
        },
        strict: true,
      });
    } else if (words.length === 2) {
      this.sanitizedFilters.push({
        query: `((firstName:${words[0]}*) AND (lastName:${words[1]}*)) OR (${words.join(' ')}*)`,
        options: {
          fields: this.fields,
          default_operator: 'AND',
        },
        strict: true,
      });
    } else {
      this.sanitizedFilters.push({
        query: `${words.join(' ')}*`,
        options: {
          fields: this.fields,
          default_operator: 'AND',
        },
        strict: true,
      });
    }
  }

  private _sanitizeExactFilter(filter: Filter): void {
    const { key, value } = filter;
    this.sanitizedFilters.push({ query: value.toString(), options: { default_field: key }, strict: true });
  }

  private _sanitizeActionsFilter(filter: Filter): void {
    const { key, type } = filter;
    switch (type) {
      case 'next':
        this.sanitizedFilters.push({
          query: '(-(_exists_:nextAction))',
          options: { default_field: 'nextAction' },
          strict: true,
        });
        break;
      case 'past':
        this.sanitizedFilters.push({
          query: `(_exists_:nextAction) AND (nextAction.dueOn:[* TO ${new Date().toISOString().split('T')[0]}])`,
          options: { default_field: 'nextAction' },
          strict: true,
        });
        break;
      case 'upcoming':
        this.sanitizedFilters.push({
          query: `(_exists_:nextAction) AND (nextAction.dueOn:[${new Date().toISOString().split('T')[0]} TO *])`,
          options: { default_field: 'nextAction' },
          strict: true,
        });
        break;
    }
  }

  private _sanitizeGeneralFilter(filter: Filter): void {
    const { key, type, value } = filter;
    this.sanitizedFilters.push({
      query: this._filterToQueryString(value as string, type),
      options: { default_field: key },
      strict: true,
    });
  }

  private _filterToQueryString(value: string | number, type: FilterType): string {
    switch (type) {
      case 'contains':
        return `(*${value}*)`;
      case 'is':
        return `(\"${value}\")`;
      case 'is not':
        return `-(\"${value}\")`;
      default:
        throw new Error('Invalid filter type');
    }
  }

  public sanitize(): SanitizedFilter[] {
    if (!this.filters) throw new Error('No filters provided');
    this.debugDisplay && console.log('Sanitizing filters', this.filters);

    this.sanitizedFilters = [];

    for (let i = 0; i < this.filters.length; i++) {
      let filter = this.filters[i],
        { key, type, displayName, customField } = filter;

      if (key === 'lastUpdatedAt' || key === 'createdAt') this._sanitizeDateRangeFilter(filter);
      else if (key === 'name') this._sanitizeNameFilter(filter);
      else if (key === 'assignedUser') this._sanitizeNameFilter(filter, 'assignedUser');
      else if (key === 'contacted') this._sanitzedContactedFilter(filter);
      else if (type === 'advanced') this._sanitizeAdvancedFilter(filter);
      else if (type === 'exact') this._sanitizeExactFilter(filter);
      else if (key === 'actions') this._sanitizeActionsFilter(filter);
      else if (customField) this._sanitizeCustomFieldsFilter(filter);
      else this._sanitizeGeneralFilter(filter);
    }

    const combos: SanitizedFilter[] = [];
    const combinedSanitizedFilters: SanitizedFilter[] = [];
    this.sanitizedFilters.forEach((filter) => {
      if (combos.includes(filter.options?.default_field)) {
        combinedSanitizedFilters.find(
          (f: SanitizedFilter) => f.options.default_field === filter.options.default_field
        ).query += ' OR ' + filter.query;
      } else {
        combos.push(filter.options?.default_field);
        combinedSanitizedFilters.push(filter);
      }
    });
    this.debugDisplay && console.log('Sanitized filters before combination', this.sanitizedFilters);
    this.sanitizedFilters = combinedSanitizedFilters;
    this.debugDisplay && console.log('Sanitized filters', this.sanitizedFilters);
    return this.sanitizedFilters;
  }
}
