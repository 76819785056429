import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarData } from 'app/services/notifications.service';

@Component({
  selector: 'mm-snackbar-with-buttons',
  templateUrl: './snackbar-with-buttons.component.html',
  styleUrls: ['./snackbar-with-buttons.component.scss'],
})
export class SnackbarWithButtonsComponent implements OnInit {
  @Input() message: string;
  @Input() actionLabel: string;
  @Input() actionCallback: Function;
  @Input() dismissCallback: Function;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {
    this.message = data.message;
    this.actionLabel = data.actionLabel;
    this.actionCallback = data.actionCallback;
    this.dismissCallback = data.dismissCallback;
  }

  ngOnInit() {}
}
