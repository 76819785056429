import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { IntegrationsService } from './integrations.service';
import { OutlookService } from './outlook.service';
import { UserService } from './user.service';

declare const gapi: any;
//You can see which version (v3) api you're using by going to developers.google.com
@Injectable()
export class GoogleService {
  constructor(
    private outlookService: OutlookService,
    private userService: UserService
  ) {}

  private manageGoogleComponentSource = new Subject<boolean>();
  manageGoogle$ = this.manageGoogleComponentSource.asObservable();

  gmailAddress: string;
  authorizeBtnContainer: any;
  deauthorizeBtnContainer: any;
  auth2: any;
  gapiApiKey: string = 'AIzaSyD38b3qIIxfruAl4FeaxLcnTfbN2QRpAq0';
  gapiCliendId: string = '1005072901287-d6bqusln3c8fo5rccsftjjtkkhv5iibu.apps.googleusercontent.com';
  scope = ['profile', 'https://www.googleapis.com/auth/gmail.send', 'https://www.googleapis.com/auth/calendar'].join(
    ' '
  );

  // MIME message content-types
  MIME_ATTACHMENT_TYPE: string = 'multipart/mixed';
  MIME_IMAGE_TYPE: string = 'multipart/related';
  MIME_TEXT_TYPE: string = 'multipart/alternative';

  googleInit(authButton) {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        apiKey: this.gapiApiKey,
        client_id: this.gapiCliendId,
        cookiepolicy: 'single_host_origin',
        scope: this.scope,
      });
      if (!this.outlookService.isOutlookAuthorized()) {
        this.attachSignin(authButton);
      }
    });
  }

  attachSignin(element) {
    this.auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        this.manageGoogleComponentSource.next(null);
        this.gmailAddress = googleUser.getBasicProfile().getEmail();
      },
      (error) => {
        alert(JSON.stringify(error, undefined, 2));
      }
    );
  }

  signOut(): Promise<boolean> {
    return new Promise((resolve) => {
      gapi.auth2
        .getAuthInstance()
        .signOut()
        .then(function () {
          gapi.auth2.getAuthInstance().disconnect();
          resolve(true);
        });
    });
  }

  isSignedIn(): Promise<boolean> {
    return new Promise((resolve) => {
      gapi.load('auth2', () => {
        this.auth2 = gapi.auth2.init({
          apiKey: this.gapiApiKey,
          client_id: this.gapiCliendId,
          cookiepolicy: 'single_host_origin',
          scope: this.scope,
        });
        this.auth2.then(function () {
          if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    });
  }

  getGmailAddress(): Promise<string> {
    return new Promise((resolve) => {
      gapi.load('auth2', () => {
        this.auth2 = gapi.auth2.init({
          apiKey: this.gapiApiKey,
          client_id: this.gapiCliendId,
          cookiepolicy: 'single_host_origin',
          scope: this.scope,
        });
        this.auth2.then(function () {
          if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
            resolve(gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail());
          } else {
            resolve('');
          }
        });
      });
    });
  }

  sendGmail(email): Promise<boolean> {
    return new Promise((resolve) => {
      gapi.load('client:auth2', () => {
        // Load the API client and auth2 library
        gapi.client.load('gmail', 'v1', () => {
          // Load the gmail library
          gapi.client
            .init({
              // Initializes object. must call before calling methods
              apiKey: this.gapiApiKey,
              client_id: this.gapiCliendId,
              cookiepolicy: 'single_host_origin',
              scope: this.scope,
            })
            .then(function () {
              if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
                let base64EncodedEmail = window
                  .btoa(unescape(encodeURIComponent(email)))
                  .replace(/\+/g, '-')
                  .replace(/\//g, '_')
                  .replace(/=+$/, '');

                return gapi.client.gmail.users.messages
                  .send({
                    userId: 'me',
                    resource: {
                      raw: base64EncodedEmail,
                    },
                  })
                  .then((sent) => {
                    if (sent) {
                      resolve(true);
                    } else {
                      resolve(false);
                    }
                  });
              } else {
                console.error('User is not signed in to Google.');
              }
            });
        });
      });
    });
  }

  async formatMIMEEmailContents(emailContents, fromEmail): Promise<string> {
    let mimeStr = '';
    let boundary = this.generateMIMEBoundary();
    let attBoundary = this.generateMIMEBoundary();
    let imgBoundary = this.generateMIMEBoundary();

    mimeStr = `MIME-Version: 1.0
Subject: ${emailContents.subject}
From: ${fromEmail}
To: `;

    // Add all the To recipients
    var toArray = emailContents.to.split(/[\s,;]+/);
    toArray.map((to) => {
      mimeStr += to + ', ';
    });
    // Remove the last comma and space and add a newline
    mimeStr = mimeStr.substring(0, mimeStr.length - 2) + '\n';

    // Add the embedded images and attachment headers
    let imgHeader = this.generateMIMEHeader(this.MIME_IMAGE_TYPE, imgBoundary);
    let attHeader = this.generateMIMEHeader(this.MIME_ATTACHMENT_TYPE, attBoundary);
    if (emailContents.embeddedImgs.length > 0 && emailContents.attachments.length == 0) {
      // Email only contains embedded imgs
      mimeStr += imgHeader;
    } else if (emailContents.embeddedImgs.length == 0 && emailContents.attachments.length > 0) {
      // Email only contains attachments.
      mimeStr += attHeader;
    } else if (emailContents.embeddedImgs.length > 0 && emailContents.attachments.length > 0) {
      // Email contains both attachments and embedded imgs
      mimeStr += attHeader + imgHeader;
    }

    // Add the email body
    mimeStr += this.generateMIMEBody(emailContents, boundary);

    // Add the attachments/embedded imgs part to the string
    mimeStr += await this.generateMIMEAttachmentImg(emailContents, attBoundary, imgBoundary);

    return mimeStr;
  }

  generateMIMEHeader(mimeType, boundary): string {
    let header = `Content-Type: ${mimeType}; boundary="${boundary}"\n
--${boundary}\n`;

    return header;
  }

  /**
   * Generates the email body in MIME format.
   * Input the email body in plain text then inputs the email body in its HTML form
   * @returns The email body in MIME format
   */
  generateMIMEBody(emailContents, boundary): string {
    let mimeBody = `Content-Type: ${this.MIME_TEXT_TYPE}; boundary="${boundary}"\n
--${boundary}
Content-Type: text/plain; charset="UTF-8"\n
${emailContents.bodyText}\n
--${boundary}
Content-Type: text/html; charset="UTF-8"\n
${emailContents.bodyHTML}\n
--${boundary}--\n`;

    return mimeBody;
  }

  /**
   * Generates the attachments and/or embedded images MIME string.
   * It loops through each embedded image and creates a MIME string for each one.
   * Then it loops through each attachment and creates a MIME string for each one.
   * @returns the MIME string of all the attachments and embedded images
   */
  async generateMIMEAttachmentImg(emailContents, attBoundary, imgBoundary) {
    let attImgStr = '';
    let attCid = 0;

    if (emailContents.embeddedImgs.length > 0) {
      emailContents.embeddedImgs.map((img) => {
        attImgStr += this.generateOneMIMEAttachmentImg(
          'img',
          img.contentType,
          img.contentId,
          img.contentId,
          imgBoundary,
          img.contentBytes
        );
      });
      attImgStr += `--${imgBoundary}--\n`;
    }

    for (let attIndex = 0; attIndex < emailContents.attachments.length; ++attIndex) {
      let att = emailContents.attachments[attIndex];
      let contentId = 'attCid' + attCid;
      attCid++;

      // Get content bytes of attachment
      let attContentBytes = await this.getAttachmentContentBytes(att);

      attImgStr += this.generateOneMIMEAttachmentImg(
        'att',
        att.type,
        att.name,
        contentId,
        attBoundary,
        attContentBytes
      );
    }
    attImgStr += `--${attBoundary}--\n`;

    return attImgStr;
  }

  /**
   * All attachment and embedded image MIME string look the same.
   * This function takes the contentType, fileName, contentId, and boundary
   * and generates a MIME string for an attachment or embedded image
   * @returns a singular attachment/embedded image MIME text
   */
  generateOneMIMEAttachmentImg(attOrImg, contentType, fileName, contentId, boundary, contentBytes) {
    let oneMIMEStr;
    // If contentBytes was not given, it's an attachment
    if (attOrImg == 'att') {
      oneMIMEStr = `--${boundary}
Content-Type: ${contentType}; name="${fileName}"
Content-Disposition: attachment; filename="${fileName}"
Content-Transfer-Encoding: base64
X-Attachment-Id: ${contentId}
Content-ID: <${contentId}>
${contentBytes}\n\n\n`;
    } else if (attOrImg == 'img') {
      // Else, it is an embedded image
      oneMIMEStr = `--${boundary}
Content-Type: ${contentType}; name="${fileName}"
Content-Disposition: inline
Content-Transfer-Encoding: base64
X-Attachment-Id: ${contentId}
Content-ID: <${contentId}>
${contentBytes}\n\n\n`;
    } else {
      console.log('Error in generateOneMIMEAttachmentImg. Invalid attOrImg argument given');
    }

    return oneMIMEStr;
  }

  getAttachmentContentBytes(att) {
    //Encoding file to base64
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(att);
      reader.onload = () => {
        var startIndex = reader.result.toString().indexOf(',') + 1;
        var endIndex = reader.result.toString().length;
        var contentBytes = reader.result.slice(startIndex, endIndex);

        resolve(contentBytes);
      };
      reader.onerror = (error) => {
        console.error('getAttachmentContentBytes reader error', reader, error);
        reject(error);
      };
    });
  }
  //attImgStr += this.generateOneMIMEAttachmentImg(a.type, a.name, contentId, attBoundary);
  /**
   * @returns a random string of 28 characters containing lower case letters and numbers
   */
  generateMIMEBoundary(): string {
    let boundary = '';
    let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let boundaryLength = 28;
    for (let i = 0; i < boundaryLength; i++) {
      boundary += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return boundary;
  }

  generateEmbeddedImageObject(img, imgCid) {
    let imgObj = {
      contentId: 'imgCid' + imgCid,
      contentType: img.src.split(';')[0].split('data:')[1],
      contentBytes: img.src.split(';')[1].split('base64,')[1],
    };

    // Replaces the img src in the email body with the cid
    img.setAttribute('src', 'cid:' + imgObj.contentId);

    return imgObj;
  }

  getGoogleCalendarApi(): Promise<any> {
    return new Promise((resolve) => {
      gapi.load('client:auth2', () => {
        gapi.client.load('calendar', 'v3', () => {
          gapi.client
            .init({
              // Initializes object. must call before calling methods
              apiKey: this.gapiApiKey,
              client_id: this.gapiCliendId,
              cookiepolicy: 'single_host_origin',
              scope: this.scope,
            })
            .then(() => {
              if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
                resolve(gapi.client);
              } else {
                console.log('YOU ARE NOT GOOGLED UP');
              }
            });
        });
      });
    });
  }

  getGoogleEvents(date): Promise<any> {
    return new Promise((resolve) => {
      gapi.load('client:auth2', () => {
        // Load the API client and auth2 library
        gapi.client.load('calendar', 'v3', () => {
          // Load the google calendar library
          gapi.client
            .init({
              // Initializes object. must call before calling methods
              apiKey: this.gapiApiKey,
              client_id: this.gapiCliendId,
              cookiepolicy: 'single_host_origin',
              scope: this.scope,
            })
            .then(function () {
              if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
                let start_datetime = new Date(date);
                let end_datetime = new Date(start_datetime);
                end_datetime.setDate(end_datetime.getDate() + 1);
                gapi.client.calendar.events
                  .list({
                    calendarId: 'primary',
                    timeMin: start_datetime.toISOString(),
                    timeMax: end_datetime.toISOString(),
                    singleEvents: true,
                    orderBy: 'startTime',
                  })
                  .then((response) => {
                    var events = response.result.items;
                    resolve(events);
                  });
              } else {
                console.error('User is not signed in to Google.');
              }
            });
        });
      });
    });
  }

  createGoogleEvent(eventForm, startEndDates, participants): Promise<boolean> {
    return new Promise((resolve) => {
      gapi.load('client:auth2', () => {
        // Load the API client and auth2 library
        gapi.client.load('calendar', 'v3', () => {
          // Load the google calendar library
          gapi.client
            .init({
              // Initializes object. must call before calling methods
              apiKey: this.gapiApiKey,
              client_id: this.gapiCliendId,
              cookiepolicy: 'single_host_origin',
              scope: this.scope,
            })
            .then(() => {
              if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
                let eventJson = this.generateEventJson(eventForm, startEndDates, participants);
                let request = gapi.client.calendar.events.insert({
                  calendarId: 'primary',
                  resource: eventJson,
                });

                request.execute(function (event) {
                  if (event.htmlLink) {
                    console.log('Google event created', event);
                    resolve(true);
                  } else resolve(false);
                });
              } else {
                console.error('User is not signed in to Google.');
              }
            });
        });
      });
    });
  }

  generateEventJson(eventForm, startEndDates, participants) {
    var event = new Object();

    //message builder
    event['summary'] = eventForm.title;
    event['description'] = eventForm.description;
    event['start'] = {
      dateTime: startEndDates[0],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    event['end'] = {
      dateTime: startEndDates[1],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (eventForm.locaion) {
      event['location'] = eventForm.location;
    }
    event['attendees'] = this.generateAttendeesJson(participants);

    return event;
  }

  generateAttendeesJson(participants) {
    let attendeesObj = [];

    for (let a = 0; a < participants.length; ++a) {
      attendeesObj.push({
        email: participants[a],
      });
    }

    return attendeesObj;
  }
}
