import { ModalModule } from './modal/modal.module';
import {
  HttpRequest,
  HttpHandler,
  HttpClientModule,
  HttpResponse,
  HTTP_INTERCEPTORS,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MentionModule } from 'angular-mentions';
//notifier stuff
// import { NotifierModule } from 'angular-notifier';
import { environment } from '../environments/environment';
import { AdminGuard } from './admin.guard';
import { AppComponent } from './app.component';
import { AuthenticationGuard } from './authentication.guard';
import { AppsyncService } from './graphql/graphql.appsync.service';

import { TenantAdminGuard } from './tenantAdmin.guard';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DevGuard } from './dev.guard';
import { UserAccessGuard } from './userAccess.guard';
import { GraphQLModule } from './graphql.module';
import { ModelMatchTooltipModule } from './components/betterTooltip.component';
import { PreloadAllModules, Router, RouterModule, Routes, UrlSegment } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ErrorCatchingService } from './services/error-catching.service';
import { CognitoService } from './services/cognito.service';
import { UserService } from './services/user.service';
import { NavigationService } from './services/navigation.service';
import { UtilService } from './services/util.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LoginModule } from 'app/login/login.module';
import { ImageCachingService } from './services/imagecaching.service';
import { PermissionsService } from './services/permissions.service';
import { SidebarService } from './services/sidebar.service';
import { ReportingService } from './services/reporting.service';
import { RequisitionService } from './services/requisition.service';
import { ColorSchemeService } from './services/color-scheme.service';
import { TenantService } from './services/tenant.service';
import { SMSServiceNeo } from './services/sms.appsync.service';
import { CommandPalletteService } from './command.service';
import { StripeService } from './services/stripe.service';
import { PermissionsGuard } from './permissions.guard';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { SidenavComponent } from './sidebars/sidenav.component';
import { BootstrapComponent } from './bootstrap.component';
import { SelfSignupService } from './services/self-signup.service';

let routerCache: Router = undefined;
let userServiceCache: UserService = undefined;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'eff22679-61a3-4db6-a08c-4388307eb399',
      redirectUri: window.location.origin + '/assets/redirect.html',
      authority: 'https://login.microsoftonline.com/common',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  });
}

/** ErrorCatchingService _is_ the ErrorHandler. Use the same instance. */
export function errorHandlerFactory(errorSvc: ErrorCatchingService): ErrorHandler {
  return errorSvc;
}

const topLevelRoutes: Routes = [
  // Login routes are hardcoded here to keep them in a non-lazy loaded module.
  {
    path: 'login',
    loadChildren: () => import('app/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'login/:time',
    loadChildren: () => import('app/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('app/login/forgot-password.module').then((m) => m.ForgotPasswordModule),
  },
  {
    path: 'reset-password-old',
    loadChildren: () => import('app/login/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('app/login/reset.module').then((m) => m.ResetModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('app/signup/signup.module').then((m) => m.ModelMatchSignupModule),
  },
  {
    matcher: isNotLoggedInMatcher,
    redirectTo: '/login',
    // loadChildren: () => import('app/login/login.module').then((m) => m.LoginModule),
  },
  {
    matcher: isLoggedInMatcher,
    loadChildren: () => import('./core.module').then((m) => m.CoreModule),
  },
  // Top level redirect to the login page.
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

/** Will trigger a match when logged in. If logged in, it will let the route continue wherever it was going.  */
function isLoggedInMatcher() {
  let isLoggedIn = userServiceCache?.authenticated$.getValue();

  if (isLoggedIn === true) {
    // Super important to note here that we are purely checking login status and passing on the route. By consuming any part of the route, we take away those parts for later down the routing stream. So here, we will consume nothing and pass the whole route down the stream.
    return { consumed: [] };
  }
  return null;
}

/** Will trigger a match when not logged in then will redirect to the login page. */
function isNotLoggedInMatcher(urlSegments: UrlSegment[]) {
  let isLoggedIn = userServiceCache?.authenticated$.getValue();

  if (isLoggedIn === false) {
    if (userServiceCache.urlPath === undefined) {
      console.log('✅ Saving redirect URL');
      userServiceCache.urlPath = window.location.hash.split('#')[1];
    }

    let urlParts = routerCache.url.split('/');
    // We are on the alt login route, go to the normal route.
    if (urlParts[1] === 'login' && urlParts.length > 2) {
      routerCache.navigateByUrl('/login');
      // We are on the normal login route, go to the alt route.
    } else if (urlParts[1] === 'login' && urlParts.length === 2) {
      routerCache.navigateByUrl(`/login/${Date.now()}`);
    }

    // return null;
    // return { consumed: urlSegments };
    return { consumed: [] };
  }
  return null;
}

/**This function is responsible getting the data we need BEFORE Angular latches on. This is important because we need to know who is logged in before we can route them to their homepage. */
function initializeApp(
  userService: UserService,
  permissionsService: PermissionsService,
  navigationService: NavigationService,
  router: Router
) {
  return async () => {
    return await new Promise(async (res) => {
      console.log('🔄 Initializing app...');

      userServiceCache = userService;
      routerCache = router;

      let isLoggedIn = await userService.isAuthenticated().toPromise();
      console.log(isLoggedIn ? '✅ Found a logged in user! Initializing app data...' : '❌ No logged in user found.');

      if (isLoggedIn === true) {
        let userInfo = userService.userInfo$.getValue();
        let tenantId = userInfo.tenantId as string;

        if (!tenantId) {
          // With no tenantId, we can't know who they are or what their permissions should be. Panic!
          throw new Error('❌ Tenant Id is not found on user.');
        }

        // These next two are required to load in order. We need permissions first before we can figure out routing.
        // Start up the permissions for the user once we know who they are.
        let permissions = await permissionsService.initPermissions(tenantId);
        // Calculate their default route preference.
        await navigationService.initialize(permissions);
      }

      // LET 'ER RIP
      res(isLoggedIn);

      console.log('✅ Finished loading data. Starting Angular...');
    });
  };
}

@NgModule({
  declarations: [BootstrapComponent],
  imports: [
    RouterModule.forRoot(topLevelRoutes, {
      useHash: true,
      enableTracing: false,
      // preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
    CommonModule,
    ScrollingModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MentionModule,
    ModelMatchTooltipModule,
    LoginModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.useServiceWorker }),
    GraphQLModule,
  ],
  providers: [
    // Guards
    AuthenticationGuard,
    AdminGuard,
    TenantAdminGuard,
    DevGuard,
    PermissionsGuard,
    CanDeactivateGuard,
    // Angular
    MsalService,
    ErrorCatchingService,
    // Startup Services
    CognitoService,
    NavigationService,
    UserService,
    ImageCachingService,
    UtilService,
    AppsyncService,
    PermissionsService,
    StripeService,
    SelfSignupService,
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
      deps: [ErrorCatchingService],
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    // Startup initializer for checking the user's data before loading the app.
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [UserService, PermissionsService, NavigationService, Router, SelfSignupService],
    },
  ],
  // The bootstrap property is used for a root module
  // and will let Angular know which component or components
  // will be the starting point for the bootstrap process.
  // Basically, the entry point for your app code.
  bootstrap: [BootstrapComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
