import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SMSService } from '../../services/sms.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MMSMedia } from '../../shared/model/user';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { take, map } from 'rxjs/operators';
@Component({
  selector: 'mm-mms-media-manager',
  templateUrl: './mms-media-manager.component.html',
  styleUrls: ['./mms-media-manager.component.scss'],
})
export class MmsMediaManagerComponent implements OnInit {
  constructor(
    private smsService: SMSService,
    private snackBar: MatSnackBar
  ) {}

  @Output() mediaSelected: EventEmitter<any> = new EventEmitter();

  mmsMedia: MMSMedia[];
  uploadingMedia: boolean = false;
  progressMessage: string = '';

  ngOnInit(): void {
    // Load list of media
    this.smsService
      .getMMSMedia()
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log('getMMSMedia result', result);

          this.mmsMedia = result.data.getMMSMedia.map((ele) => {
            delete ele.__typename;
            return ele;
          });
        },
        (error) => {
          console.log('MmsMediaManagerComponent ::getMMSMedia  error getting media items', error);
        }
      );
  }

  uploadDocumentHandler($event) {
    this.progressMessage = 'Upload started - allocating storage';
    this.uploadingMedia = true;

    let file: File = $event.target.files[0];
    // this.uploadDocument.emit(file);

    const MMSMediaDocId = '';

    this.smsService
      .buildMMSMediaStorageLocation(file, file.name, file.type, MMSMediaDocId)
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log(result);

          this.progressMessage = 'Storage Allocated - uploading file';

          delete result.data.buildMMSMediaStorageLocation.__typename;

          this.smsService
            .uploadFileToS3(file, file.name, file.type, result.data.buildMMSMediaStorageLocation)
            .pipe(take(1))
            .subscribe(
              (putResult: any) => {
                //console.log('smsService :: buildMMSMediaStorageLocation - Pit result Event == %s', JSON.stringify(event));

                this.progressMessage = 'File uploaded to storage - processing';

                this.smsService
                  .resizeMMSMediaImages(result.data.buildMMSMediaStorageLocation)
                  .pipe(take(1))
                  .subscribe(
                    (thumbResult: any) => {
                      this.progressMessage = 'Media Processed';

                      this.mmsMedia.push(result.data.buildMMSMediaStorageLocation);

                      //console.log('SingleContactComponent :: contact upload document ', result);

                      let snackBarRef = this.snackBar.open('MMS Media Uploaded Successfully ...', '', {
                        duration: 2000,
                      });
                      snackBarRef.afterDismissed().subscribe(() => {
                        // this.setAllHistories();
                      });

                      this.uploadingMedia = false;

                      this.mediaSelected.emit(this.mmsMedia[this.mmsMedia.length - 1]);
                    },
                    (error: any) => {
                      console.error('smsService :: buildMMSMediaStorageLocation - Error == %s', JSON.stringify(error));
                      this.uploadingMedia = false;
                    }
                  );
              },
              (error: any) => {
                console.error('smsService :: buildMMSMediaStorageLocation - Error == %s', JSON.stringify(error));
                this.uploadingMedia = false;
              }
            );
        },
        (error) => {
          console.log('SingleContactComponent :: error upload contact document ', error);
          this.snackBar.open('An error occurred, please try again later.', '', {
            duration: 5000,
          });
          this.uploadingMedia = false;
        }
      );
  }

  onSelection(event: MatSelectionListChange) {
    if (event.option.value) {
      console.log('event.option.selected', event.option.value);

      this.mediaSelected.emit(event.option.value);
    }
  }

  onDeleteMediaItem(event, index) {
    // Dont select if trying to delete
    event.stopPropagation();

    this.smsService
      .deleteMMSMedia(this.mmsMedia[index].id)
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log('deleteMMSMedia result', result);
          this.mmsMedia.splice(index, 1);
        },
        (error) => {
          console.log('MmsMediaManagerComponent ::deleteMMSMedia  error deleting media items', error);
        }
      );

    console.log();
  }
}
