import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Permissions, PermissionsService } from './services/permissions.service';
import { getPermissionValue } from '../../../app/src/model/permissions/permissions';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate, OnDestroy {
  private permissions: Permissions;
  permissionsPath: string[] | undefined;
  permissionsSubscription: Subscription;
  constructor(
    private permissionsService: PermissionsService,
    private router: Router
  ) {
    // Set up subscription for when permissions change.
    this.permissionsSubscription = this.permissionsService.permissions$.subscribe((permissions) => {
      // Store new permissions
      this.permissions = permissions;

      // If we are on a route that has permissions protection, check the path we are on now.
      if (this.permissionsPath) {
        let hasPermissionToAccess = getPermissionValue(this.permissionsPath, permissions);

        // If it turns out that we no longer have permissions to this path, let's re-route to the home page.
        if (hasPermissionToAccess === false) {
          console.log('🔒 PermissionsGuard: Route Denied', this.permissions.debug.permissions && this.permissionsPath);
          this.permissionsPath = undefined;
          this.router.navigateByUrl('/');
        }
      }
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let keyReducer = next.data.permissionsPath;
    if (keyReducer) {
      this.permissionsPath = keyReducer;
      let permissionsValue = getPermissionValue(keyReducer, this.permissions);
      if (this.permissions.debug.permissions) {
        console.log(
          `🔒 PermissionsGuard: ${permissionsValue ? 'Access Granted' : 'Access Denied'}`,
          state.url,
          keyReducer,
          this.permissions
        );
      }
      return permissionsValue;
    } else {
      console.error(
        "🔒 Permissions Guard: Can't find path to check permissions for. Make sure route data has a permissionsPath array with keys to the permissions included. Ex: { permissionsPath: ['market_insights', 'enabled'] }"
      );
      return false;
    }
  }

  ngOnDestroy(): void {
    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
  }
}
